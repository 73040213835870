import React from 'react';
import tw, { styled } from 'twin.macro';
import Icon from '~icons';

const ButtonText = styled.button`
    ${tw`hover:text-icon-dark flex items-center focus:outline-none`}
    ${({ isFaded }) => isFaded && tw`text-icon`}
    ${({ isPadded }) => isPadded && tw`py-2 px-4`}
`;

const IconWrap = styled.div`
    ${tw`text-icon`}
    ${({ isLarge }) => (isLarge ? tw`text-xl` : tw`text-base`)}
  ${({ isHighlighted }) => isHighlighted && tw`text-primary`}

  ${ButtonText}:hover &,
  ${ButtonText}:focus & {
        ${tw`text-inherit`}
        ${({ isHighlighted }) => isHighlighted && tw`text-primary-dark`}
    }
`;

const ButtonTitle = styled.span`
    ${tw`hidden sm:flex`}
    ${ButtonText}:hover &,
    ${ButtonText}:focus & {
        ${tw`text-body-dark`}
    }
    ${({ isSmall }) => (isSmall ? tw`text-sm ml-2` : tw`ml-3`)}
    ${({ hasConstantTitle }) => hasConstantTitle && tw`flex`}
`;

export default ({
    children,
    icon = {
        name: 'add',
        isHighlighted: true,
        isLarge: true,
    },
    isFaded = false,
    isPadded = false,
    isSmall,
    hasConstantTitle,
    ...rest
}) => (
    <ButtonText {...{ isFaded, isPadded }} {...rest}>
        {icon.name && (
            <IconWrap {...icon}>
                <Icon name={icon.name} iconThick />
            </IconWrap>
        )}
        <ButtonTitle {...{ isSmall, hasConstantTitle }}>{children}</ButtonTitle>
    </ButtonText>
);
