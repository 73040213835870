import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m3 9v-4c0-1.1046 0.89543-2 2-2h22c1.1046 0 2 0.89543 2 2v4m-26 0v7m0-7h8.6667m17.333 0v7m0-7h-8.6667m-17.333 7h26m-26 0v6.5m26-6.5v6.5m-26 0h26m-26 0v4.5c0 1.1046 0.89543 2 2 2h6.6667m17.333-6.5v4.5c0 1.1046-0.8954 2-2 2h-6.6667m-8.6666-20v20m0-20h8.6666m-8.6666 20h8.6666m0-20v20"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
