import { getDefaultPermissionsForLoggedInUser } from '~modules/meeting/meeting.selectors';
import { getStore } from '~common/store';
import { getWorkspaceIdSelector } from '~common/selector.helpers';
import { getCustomerDataKey } from './remoteCRM.reducer.js';
import { INTEGRATION_STATUS_ACTIVE } from '~shared/constants';
import { createSelector } from 'reselect';

/**
 * Get the attendees and permissions for a remote CRM "customer".
 * @param {Object} loggedInUser the user object of the logged in user
 * @param {Object} customer The remote customer record, as retrieved from integration.remoteCRM
 */
export const getAttendeesAndPermissionsForRemoteCRM = (
    loggedInUser,
    customer
) => {
    const newAttendeesAndPermissions = Object.entries(
        customer.permissions || {}
    ).flatMap(([grantLevel, users]) => {
        return users.map((user) => {
            const isAttendee = (customer.attendees || []).some(
                (attendee) => attendee.id === user.id
            );
            return {
                isAttendee,
                isOwner: false,
                grantLevel,
                newUser: user.id !== loggedInUser.id,
                user,
            };
        });
    });

    if (
        !newAttendeesAndPermissions.some((ap) => ap.user.id === loggedInUser.id)
    ) {
        newAttendeesAndPermissions.push(
            getDefaultPermissionsForLoggedInUser(loggedInUser)
        );
    }

    return newAttendeesAndPermissions;
};

/**
 /**
  * Get the CRM customers from the redux store, for a specific workspace.
 * @param {Object} [state=getStore().getState] Redux state object. If ommitted, use the default redux store
 * @param {string | Object} id workspace or workspace.id.
 */
const noCRMLinks = { crmLinks: [], crmData: [], authSources: [] };
const noCRMData = [];
export function getCRMCustomers() {
    let state, props;
    switch (arguments.length) {
        case 2:
            [state, props] = arguments;
            break;
        case 1: {
            [props] = arguments;
            state = getStore().getState();
        }
    }
    const workspaceId = getWorkspaceIdSelector(null, props);

    const crmLinks = state.remoteCRM.links.filter(
        (link) => link.workspaceId === workspaceId && !link.remoteSubscriptionId // exclude subscription links
    );

    if (crmLinks.length === 0) return noCRMLinks;

    const authSources = Array.from(
        new Set(
            crmLinks?.map?.((link) => link.authSource).filter(Boolean) || []
        )
    );

    const crmData = authSources.flatMap((authSource) => {
        const dataKey = getCustomerDataKey({
            workspaceId,
            authSource,
        });

        return state.remoteCRM.customers[dataKey]?.data || [];
    });

    return {
        crmLinks,
        authSources,
        crmData: crmData.length === 0 ? noCRMData : crmData,
    };
}

/**
 * This is a memoized selector of the above.
 * It is important this only be used in a situation where it will not break the memoizing.
 */
export const getCRMCustomersSelector = createSelector(
    (state) => state.remoteCRM.links,
    (state) => state.remoteCRM.customers,
    getWorkspaceIdSelector,
    (links, customers, workspaceId) => {
        const crmLinks = links.filter(
            (link) =>
                link.workspaceId === workspaceId && !link.remoteSubscriptionId // exclude subscription links
        );
        if (crmLinks.length === 0) return noCRMLinks;

        const authSources = Array.from(
            new Set(
                crmLinks?.map?.((link) => link.authSource).filter(Boolean) || []
            )
        );

        const crmData = authSources.flatMap((authSource) => {
            const dataKey = getCustomerDataKey({
                workspaceId,
                authSource,
            });

            return customers[dataKey]?.data || [];
        });

        return {
            crmLinks,
            authSources,
            crmData: crmData.length === 0 ? noCRMData : crmData,
        };
    }
);

/**
 * Determine if a workspace has a CRM link
 * @param {Object|String} workspace workspace object, or workspace.id
 * @returns true if the workspace has an active CRM link, or otherwise false
 */
export const hasCRMLink = (workspace, authSource) => {
    const state = getStore().getState();
    const workspaceId = getWorkspaceIdSelector(null, { workspace });

    // GOTCHA: there is no link.status when the user first connects with the remoteCRM.
    // the link.status is supplementary information that is inserted by the server during the preload request.
    return state.remoteCRM.links.some(
        (link) =>
            link.workspaceId === workspaceId &&
            (!authSource || link.authSource === authSource) &&
            (!link.status || link.status === INTEGRATION_STATUS_ACTIVE)
    );
};

export const getCrmIntegrationLinks = (profile) => {
    const state = getStore().getState();
    return state?.remoteCRM?.links?.filter(
        (link) =>
            link.authSource === profile.authSource &&
            link.remoteId === profile.remoteResources?.accountId
    );
};
