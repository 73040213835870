import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m2 13v-2.3448c0-2.2092 1.7909-4 4-4h24m0 0-4.9778-4.6552m4.9778 4.6552-4.9778 5.1724"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="m30 19v2.3448c0 2.2092-1.7909 4-4 4h-24m0 0 4.9778 4.6552m-4.9778-4.6552 4.9778-5.1724"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
