import React from 'react';
import tw from 'twin.macro';
import { Button } from '~modules/common/components';

const Container = tw(Button)`py-2`;
export default (props) => (
    <Container isFull isSecondary iconDirection="up" {...props}>
        Back to top
    </Container>
);
