import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        viewBox="0 0 40 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m39.113 40.756c-0.5583 0.7555-37.073 1.5828-38.382 0-1.3088-1.5828-0.6181-32.001 0.19079-33.583 0.8089-1.5819 37.304-2.0835 38.191-0.67196 0.8871 1.4115 0.6978 33.31 0 34.255z"
            fill="#E2E8F0"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m28.271 7c-0.4915 0-0.2048-4.6667 0-7h2.4572c0.4915 0 0.2048 4.6667 0 7h-2.4572z"
            fill="#E2E8F0"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m9.2714 7c-0.49146 0-0.20477-4.6667 0-7h2.4572c0.4915 0 0.2048 4.6667 0 7h-2.4572z"
            fill="#E2E8F0"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m3.3092 12.565c0.69582 0 33.382-1.2719 33.382 0s0.695 23.516 0 24.152c-0.695 0.6363-33.382 0-33.382 0s-0.69582-24.152 0-24.152z"
            fill="#fff"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m3.3092 12.588c0.69582 0 33.382-1.3228 33.382 0s0.695 24.456 0 25.118-33.382 0-33.382 0-0.69582-25.118 0-25.118z"
            fill="#fff"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m12.25 23.077c1.3086-4.8098 9.0673-7.668 13.538-1.9139m0 0v-3.6968m0 3.6968-3.7732 1.2188"
            stroke="#2D94D6"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m26.501 27.726c-1.5455 3.126-6.0904 7.0922-12.856 0.6347m0 0-5e-4 4.141m5e-4 -4.141 4.1256-0.2858"
            stroke="#2D94D6"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
