import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M28.497 26H3.503c-.326.003-.65-.06-.952-.18a2.48 2.48 0 0 1-.81-.531c-.232-.23-.418-.5-.545-.8s-.194-.622-.197-.95V8.807c.003-.326.07-.648.197-.95s.312-.572.545-.8.508-.41.8-.53.626-.184.952-.18h24.993c.326-.003.65.06.952.18s.578.303.8.53.418.5.545.8a2.48 2.48 0 0 1 .197.949v14.74c-.008.657-.276 1.283-.745 1.743s-1.1.714-1.758.71zM11.345 12.376c0-.476-.362-.86-.807-.86H4.9c-.445 0-.807.383-.807.86v3.455c0 .476.362.86.807.86h5.628c.445 0 .807-.383.807-.86v-3.455z"
            fill="#dfe1e7"
        />
        <path
            d="M26.862 21.862H23.76c-.672 0-1.035-.3-1.035-1.034s.362-1.035 1.035-1.035h3.103c.672 0 1.034.3 1.034 1.035s-.362 1.034-1.034 1.034zm-6.207 0h-3.103c-.672 0-1.035-.3-1.035-1.034s.362-1.035 1.035-1.035h3.103c.672 0 1.034.3 1.034 1.035s-.362 1.034-1.034 1.034zm-6.207 0h-3.103c-.672 0-1.035-.3-1.035-1.034s.362-1.035 1.035-1.035h3.103c.672 0 1.034.3 1.034 1.035s-.362 1.034-1.034 1.034zm-6.207 0H5.138c-.672 0-1.034-.3-1.034-1.034s.362-1.035 1.034-1.035H8.24c.672 0 1.034.3 1.034 1.035s-.362 1.034-1.034 1.034z"
            fill="#c2c5cf"
        />
    </svg>
);
