import {
    get,
    post,
    postSuppressingWS,
    deleteRequest,
} from '~client/api/helpers';
import {
    minuteItemModel,
    minuteItemModelList,
} from '~modules/minutes/minutes.mappers';
import { occurrenceModel } from '~modules/meeting/meeting.mappers';

export function getMinutes(id) {
    return get(`/api/minutes/occurrence/${id}`).then(minuteItemModelList);
}

export function createMinuteItemsBulk(id, params) {
    return post(`/api/minutes/items/${id}`, params).then(minuteItemModelList);
}

export function applyTemplateToOccurrence(id, templateId, params) {
    const url = templateId
        ? `/api/minutes/items/${id}/replace-template/${templateId}`
        : `/api/minutes/items/${id}/replace-template`;
    return postSuppressingWS(url, params).then((result) => ({
        occurrence: occurrenceModel(result.occurrence),
        minuteItems: minuteItemModelList(result.minuteItems),
    }));
}

export function createMinuteItem(params) {
    return post(`/api/minutes/item`, params).then(minuteItemModel);
}

export function editMinuteItem(id, params) {
    return postSuppressingWS(`/api/minutes/item/${id}`, params).then(
        minuteItemModel
    );
}

export function renumberMinuteItem(id, params) {
    return post(`/api/minutes/item/${id}/renumber`, params).then(
        minuteItemModel
    );
}

export function completeMinuteItem(id) {
    return post(`/api/minutes/item/${id}/complete`).then(minuteItemModel);
}

export function uncompleteMinuteItem(id) {
    return post(`/api/minutes/item/${id}/uncomplete`).then(minuteItemModel);
}

export function deleteMinuteItem(id) {
    return deleteRequest(`/api/minutes/item/${id}`);
}

export function copyMinuteItem(id, params) {
    return post(`/api/minutes/item/${id}/copy`, params).then(minuteItemModel);
}

export function moveMinuteItem(id, params) {
    return post(`/api/minutes/item/${id}/move`, params);
}

export function sendMinutes(id, params) {
    return post(`/api/minutes/occurrence/${id}/send`, params);
}

export function reportMinuteChanges(params) {
    return postSuppressingWS('/api/minutes/report', params);
}
