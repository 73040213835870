import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m8 22c-3.1994 0-6-2.3328-6-5.2105s1.6281-5.2106 4.8276-5.2106c0-4.1857 3.6743-7.5789 8.2069-7.5789 3.4442 0 6.3929 1.9593 7.6103 4.7368 3.9993 0 7.3552 2.9691 7.3552 6.6316s-2.0007 6.6316-6 6.6316"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
        />
        <path
            d="m16 28v-11m0 11 4-3.5m-4 3.5-4-3.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
