import _find from 'lodash/find.js';
import { createSelector } from 'reselect';
import { getStore } from '~common/store';
import { createDeepEqualSelector } from '~common/selector.helpers';
import { getAgendaItemsForMeetingSelector } from '~modules/agenda/agenda.selectors';
import { getOccurrenceInfoSelector } from '~modules/occurrence/occurrenceInfo.selectors';
import { getOccurrenceSelector } from '~modules/occurrence/occurrence.selectors';
import { getActionItemsForReviewSelector } from '~modules/actionItems/actionItem.selectors';
import {
    getDocumentsForOccurrenceSelector,
    getDocumentFromStoreById,
} from '~modules/documents/document.selectors';
import { ACTION_ITEM_REVIEW } from '~common/constants';
import { sortByDateFieldDesc } from '~common/utils';
import { isPrimaryItemNbr, sortByItemNbr } from '~common/list.helpers';
import {
    getMinutesByMeetingSelector,
    getMinutesSelector,
    getMinuteItemsDefaultContent,
    getMinutesForOccurrenceSelector,
} from '~modules/minutes/minutes.pure.selectors';

export const getMinuteItemsFromPreviousOccurrencesSelector =
    createDeepEqualSelector(
        [getOccurrenceSelector, getMinutesByMeetingSelector],
        (occurrence, minutes) => {
            const minutesFromPastOccurrences = minutes.filter(
                (minuteItem) =>
                    !minuteItem?.occurrenceMeta?.archived &&
                    minuteItem?.occurrenceMeta?.startDate < occurrence.startDate
            );
            const sortedMinutes = sortByDateFieldDesc(
                minutesFromPastOccurrences,
                'occurrenceMeta.startDate'
            );
            return sortedMinutes;
        }
    );

/**
 * Get a MinuteItem from the store by a document Id that's attached to a MinuteItem.
 * @param {Object} [state] Redux store. If not provided, the global store will be used.
 * @param {Object|string} document document object (preferred) or document.id
 */
export function getMinuteItemFromStoreByDocumentId(state, document) {
    const _state = arguments.length === 2 ? state : getStore().getState();
    const _documentArg = arguments.length === 2 ? document : arguments[0];
    const _document =
        typeof _documentArg === 'string'
            ? getDocumentFromStoreById(_state, _documentArg)
            : _documentArg;

    const id = _document.id;

    const minutes = getMinutesSelector(_state).filter(
        (m) => m.occurrence === _document.occurrence
    );
    return minutes.find((m) => m.documents.includes(id));
}

export const getDefaultMinutesListSelector = createDeepEqualSelector(
    getOccurrenceSelector,
    getAgendaItemsForMeetingSelector,
    getMinuteItemsFromPreviousOccurrencesSelector,
    (occurrence, agendaItems, previousOccurrencesMinuteItems) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (occurrence && occurrence.useMeetingAgenda) {
            const minuteItems = getMinuteItemsDefaultContent(
                occurrence,
                agendaItems,
                previousOccurrencesMinuteItems
            );

            return minuteItems.sort(sortByItemNbr);
        } else {
            return [];
        }
    }
);

export const getOccurrenceDisplayMinutes = createSelector(
    getMinutesForOccurrenceSelector,
    getDefaultMinutesListSelector,
    getDocumentsForOccurrenceSelector,
    (occurrenceMinutes, defaultMinutes, documents) => {
        const topicsAreSavedToDB = occurrenceMinutes.length > 0;

        const minutes = (
            topicsAreSavedToDB ? occurrenceMinutes : defaultMinutes
        )
            .map((m) => {
                const documentsList = documents.filter((d) =>
                    (m.documents || []).includes(d.id)
                );

                return {
                    ...m,
                    documentsList,
                    moveToSubtopic: !isPrimaryItemNbr(m.itemNbr),
                    isTopicPersisted: topicsAreSavedToDB,
                };
            })
            .sort(sortByItemNbr);

        return {
            minutes,
            topicsAreSavedToDB,
        };
    }
);

export const getMinutesListTopicsSelector = createDeepEqualSelector(
    getOccurrenceDisplayMinutes,
    getOccurrenceSelector,
    getOccurrenceInfoSelector,
    getActionItemsForReviewSelector,
    (minutesDisplay, occurrence, occurrenceInfo, actionItemsForReview) => {
        // We want to insert a topic for the action item review.
        // If action item review is not selected, just return the list of minutes.
        // if action item review is selected, only show the action review if:
        //     there is a previous occurrence,
        //     or there is no previous occurrence but there are action items for review.
        //     NB this may seem odd. It's for users who have only been granted access to one occurrence
        //     but there are open actions for review.
        const { prevStarted } = occurrenceInfo;
        if (
            !occurrence.previousActionItemReview ||
            (!prevStarted && !actionItemsForReview.length)
        ) {
            return minutesDisplay;
        }

        return {
            ...minutesDisplay,
            minutes: [
                {
                    id: ACTION_ITEM_REVIEW,
                    itemNbr: '0',
                    itemTitle: 'Action item review',
                    occurrence: occurrence.id,
                },
                ...minutesDisplay.minutes,
            ],
        };
    }
);

export const getMinutesListStateSelector = createSelector(
    getMinutesListTopicsSelector,
    (_, props) => props.topicId,
    ({ minutes }, id) => {
        // If minutes are not persisted yet, minuteItem.id can be a temporary Id in the browser.
        // Therefore look up the topic by the agendaItem in this case.
        return {
            minuteItem:
                _find(minutes, { id }) || _find(minutes, { agendaItem: id }),
        };
    }
);
