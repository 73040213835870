import React from 'react';

export default ({ iconThick = false, title = 'Insert above', ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="m3 19v-5c0-1.1046 0.89543-2 2-2h6.5m-8.5 7v5c0 1.1046 0.89543 2 2 2h6.5m-8.5-7h26v5c0 1.1046-0.8954 2-2 2h-6.5m8.5-7v-5c0-1.1046-0.8954-2-2-2h-6.5m-9 0h9m-9 0v14h9m0-14v14"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m3 7h26"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
