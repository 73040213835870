/**
 * Avatar helpers
 * Copied from '@electrivity/minuteme-common' on 10.06.21
 */

import _get from 'lodash/get.js';
import { getUserInitials } from './user.helpers.js';

const REG = /^blob:/i;

function getUrl(path) {
    if (!path) {
        return null;
    }

    if (REG.test(path)) return path;

    if (path.startsWith('http')) return path;

    return `https://${window.__minuteme_appConfig.uploadHost}/${path}`;
}

// from https://flatuicolors.com/palette/au
const palette = [
    {
        name: 'Pink Glamour',
        hex: '#ff7979',
    },
    {
        name: 'June Bud',
        hex: '#badc58',
    },
    {
        name: 'Turbo',
        hex: '#f9ca24',
    },
    {
        name: 'Quince Jelly',
        hex: '#f0932b',
    },
    {
        name: 'Carmine Pink',
        hex: '#eb4d4b',
    },
    {
        name: 'Pure Apple',
        hex: '#6ab04c',
    },
    {
        name: 'Middle Blue',
        hex: '#7ed6df',
    },
    {
        name: 'Heliotrope',
        hex: '#e056fd',
    },
    {
        name: 'Exodus Fruit',
        hex: '#686de0',
    },
    {
        name: 'Deep Koamaru',
        hex: '#30336b',
    },
    {
        name: 'Greenland Green',
        hex: '#22a6b3',
    },
    {
        name: 'Steel Pink',
        hex: '#be2edd',
    },
    {
        name: 'Blurple',
        hex: '#4834d4',
    },
    {
        name: 'Deep Cove',
        hex: '#130f40',
    },
];

const defaultColours = palette.map((p) => p.hex);

const fontSize = {
    xsmall: '0.5rem',
    small: '0.7rem',
    medium: '.85rem',
    large: '1.1rem',
    xlarge: '2.8rem',
    xxlarge: '4rem',
};

function getAvatarText(user, size) {
    if (size === 'xsmall') {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line unicorn/prefer-string-slice
        return getUserInitials(user, 1, '').substring(0, 1);
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-string-slice
    return getUserInitials(user, 2, '').substring(0, 2);
}

function getAvatarColor(user) {
    const i = sumChars(user.email || user.id) % defaultColours.length;
    const background = defaultColours[i];
    return background;
}

function sumChars(str) {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i);
    }

    return sum;
}

function getAvatarImageUrl(user) {
    return getUrl(_get(user, 'avatar.path'));
}

function getAvatarImage({ user, size, isDim = false }) {
    let src = getAvatarImageUrl(user);

    if (!src && (user.email || user.id)) {
        const initials = getAvatarText(user, size);
        const background = isDim ? '#A0AEC0' : getAvatarColor(user);

        src = `data:image/svg+xml;charset=utf8,${encodeURIComponent(
            `<svg xmlns="http://www.w3.org/2000/svg" role="img" version="1.1" style="border-radius: 50%;height: 100%;width: 100%;">
                    <style>
                        .avatar-initials {
                            font-size: ${fontSize[size || 'medium']};
                            font-weight: bold;
                            font-family: Lato, sans-serif;
                            fill: white;
                        }
                    </style>
                    <circle cx="50%" cy="50%" r="100%" fill="${background}" />
                    <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" class="avatar-initials">${initials}</text>
                </svg>`
        )}`;
    }

    return src;
}

const iconSrc = {
    declined: `<svg aria-hidden="true" data-prefix="fas" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg>`,
    approved: `<svg aria-hidden="true" data-prefix="fas" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>`,
};

function getAvatarStatusImage(status) {
    return iconSrc[status];
}

export {
    getAvatarImage,
    getAvatarStatusImage,
    getAvatarImageUrl,
    getAvatarColor,
    getAvatarText,
    getUrl,
};
