import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { Dimmer as SemanticDimmer } from 'semantic-ui-react';
import { Heading } from '~modules/common/components';

const Wrap = styled.div`
    height: 100%;
    ${({ active }) =>
        active &&
        css`
            .dimmable:not(body) {
                height: inherit;
            }
            .dimmed.dimmable > .ui.visible.dimmer {
                ${tw`border border-line border-dashed bg-black opacity-50`}
            }
        `}
`;

export default ({ dimmed, active, inverted, text, children }) => {
    return (
        <Wrap {...{ active }}>
            <SemanticDimmer.Dimmable {...{ dimmed }}>
                <SemanticDimmer {...{ active, inverted }}>
                    <Heading xxl isLight>
                        {text}
                    </Heading>
                </SemanticDimmer>
                {children}
            </SemanticDimmer.Dimmable>
        </Wrap>
    );
};
