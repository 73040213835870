import _isString from 'lodash/isString.js';
import { createSelector } from 'reselect';
import {
    INTEGRATION_TYPE_AUTHENTICATION,
    INTEGRATION_TYPE_CALENDARS,
    INTEGRATION_STATUS_ACTIVE,
    INTEGRATION_STATUS_RECONNECT,
    INTEGRATION_TYPE_CRM,
} from '~common/constants';
import { getUserSelector } from '~modules/user/user.selectors';

const integrationProfilesSelector = (state) => state.integration.profiles;

export const getIntegrationProfileErrors = createSelector(
    (state) => state.integration.profiles,
    (profiles) =>
        profiles.filter((p) => p.status === INTEGRATION_STATUS_RECONNECT)
);

export const getActiveRemoteCalendarIntegrationProfiles = createSelector(
    integrationProfilesSelector,
    (integrationProfiles) => {
        return integrationProfiles.filter(
            (profile) =>
                profile.integrationType === INTEGRATION_TYPE_CALENDARS &&
                profile.status === INTEGRATION_STATUS_ACTIVE
        );
    }
);

export const getRemoteCalendarIntegrationProfiles = createSelector(
    integrationProfilesSelector,
    (integrationProfiles) => {
        return integrationProfiles.filter(
            (profile) => profile.integrationType === INTEGRATION_TYPE_CALENDARS
        );
    }
);

export const getIntegrationProfileByAuthSource = (state, props) => {
    const authSource = props?.authSource || (_isString(props) ? props : null);
    return integrationProfilesSelector(state).find(
        (profile) => profile.authSource === authSource
    );
};

export const getAuthIntegrationProfile = (state) => {
    const loggedInUser = getUserSelector(state);
    const authSource = loggedInUser.loginAuthSource;
    return integrationProfilesSelector(state).find(
        (profile) =>
            profile.integrationType === INTEGRATION_TYPE_AUTHENTICATION &&
            profile.authSource === authSource
    );
};

export const getActiveCRMIntegrationProfiles = createSelector(
    integrationProfilesSelector,
    (integrationProfiles) =>
        integrationProfiles.filter(
            (profile) =>
                profile.integrationType === INTEGRATION_TYPE_CRM &&
                profile.status === INTEGRATION_STATUS_ACTIVE
        )
);
