import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import {
    commonClasses,
    primaryClasses,
    secondaryClasses,
} from '~modules/common/components/Button';

const StyledLink = styled(Link, {
    shouldForwardProp: (prop) =>
        ![
            'isPrimary',
            'isSecondary',
            'isSmall',
            'isDisabled',
            'isFull',
        ].includes(prop),
})`
    ${({ isDisabled }) => ({
        ...commonClasses.base,
        ...tw`inline`,
        ...commonClasses.hover,
        ...commonClasses.focus,
        ...commonClasses.active,
        ...(isDisabled && commonClasses.disabled),
    })}

    ${({ isSmall }) => isSmall && tw`text-sm`}

    ${({ isPrimary, isDisabled }) =>
        isPrimary && {
            ...primaryClasses.base,
            ...primaryClasses.hover,
            ...primaryClasses.active,
            ...(isDisabled && primaryClasses.disabled),
        }}
    ${({ isPrimary, isSmall }) =>
        isSmall && isPrimary && tw`px-5 py-2 hover:shadow-sm`}


    ${({ isSecondary, disabled }) =>
        isSecondary && {
            ...secondaryClasses.base,
            ...secondaryClasses.hover,
            ...secondaryClasses.active,
            ...(disabled && secondaryClasses.disabled),
        }}

    ${({ isPrimary, isSecondary }) =>
        (isPrimary || isSecondary) && tw`inline-block`}

    ${({ isSecondary, isSmall }) =>
        isSmall && isSecondary && tw`px-5 py-2 hover:shadow-sm`}

    // anchor tag should be block to enable full width
    ${({ isFull }) => isFull && tw`block text-center w-full`}
`;

const StyledAnchor = styled('a', {
    shouldForwardProp: (prop) =>
        ![
            'isPrimary',
            'isSecondary',
            'isSmall',
            'isDisabled',
            'isFull',
        ].includes(prop),
})`
    ${tw`text-center`}
    ${({ isDisabled }) => ({
        ...commonClasses.base,
        ...commonClasses.hover,
        ...commonClasses.focus,
        ...commonClasses.active,
        ...(isDisabled && commonClasses.disabled),
    })}

    ${({ isSmall }) => isSmall && tw`text-sm`}

    ${({ isPrimary, isDisabled }) =>
        isPrimary && {
            ...primaryClasses.base,
            ...primaryClasses.hover,
            ...primaryClasses.active,
            ...(isDisabled && primaryClasses.disabled),
        }}
        ${({ isPrimary, isSmall }) =>
        isSmall && isPrimary && tw`px-5 py-2 hover:shadow-sm`}

    ${({ isSecondary, disabled }) =>
        isSecondary && {
            ...secondaryClasses.base,
            ...secondaryClasses.hover,
            ...secondaryClasses.active,
            ...(disabled && secondaryClasses.disabled),
        }}
    ${({ isSecondary, isSmall }) =>
        isSmall && isSecondary && tw`px-5 py-2 hover:shadow-sm`}

  ${({ isFull }) => isFull && tw`w-full`}
`;

const NavLink = ({
    children,
    isPrimary,
    isDisabled,
    isSecondary,
    isSmall,
    isExternalLink,
    to,
    target,
    isFull,
    ...rest
}) =>
    isExternalLink ? (
        <StyledAnchor
            href={!isDisabled ? to : null}
            disabled={isDisabled}
            {...{ isPrimary, isSecondary, isDisabled, isSmall, target, isFull }}
            {...rest}
        >
            {children}
        </StyledAnchor>
    ) : (
        <StyledLink
            disabled={isDisabled}
            to={!isDisabled ? to : null}
            {...{ isPrimary, isSecondary, isDisabled, isSmall, target, isFull }}
            {...rest}
        >
            {children}
        </StyledLink>
    );

export default NavLink;
