import React from 'react';

export default (props) => (
    <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" {...props}>
        <polygon
            points="195.37 60.632 60.632 60.632 60.632 195.37 195.37 195.37"
            fill="#fff"
        />
        <polygon
            points="195.37 256 256 195.37 225.68 190.2 195.37 195.37 189.84 223.1"
            fill="#EA4335"
        />
        <path
            d="m1.4211e-14 195.37v40.421c0 11.166 9.0442 20.211 20.211 20.211h40.421l6.2253-30.316-6.2253-30.316-33.032-5.1724-27.599 5.1724z"
            fill="#188038"
        />
        <path
            d="m256 60.632v-40.421c0-11.166-9.0442-20.211-20.211-20.211h-40.421c-3.6888 15.036-5.5333 26.101-5.5333 33.196s1.8444 16.24 5.5333 27.436c13.41 3.8399 23.515 5.7599 30.316 5.7599 6.801 0 16.906-1.92 30.316-5.7599z"
            fill="#1967D2"
        />
        <polygon
            points="256 60.632 195.37 60.632 195.37 195.37 256 195.37"
            fill="#FBBC04"
        />
        <polygon
            points="195.37 195.37 60.632 195.37 60.632 256 195.37 256"
            fill="#34A853"
        />
        <path
            d="m195.37 0h-175.16c-11.166 0-20.211 9.0442-20.211 20.211v175.16h60.632v-134.74h134.74v-60.632z"
            fill="#4285F4"
        />
        <path
            d="m88.269 165.15c-5.0358-3.4021-8.5221-8.3705-10.425-14.939l11.688-4.8168c1.0611 4.0421 2.9137 7.1747 5.5579 9.3979 2.6274 2.2232 5.8274 3.3179 9.5663 3.3179 3.8232 0 7.1074-1.1621 9.8526-3.4863 2.7453-2.3242 4.1263-5.2884 4.1263-8.8758 0-3.6716-1.4484-6.6695-4.3453-8.9937-2.8968-2.3242-6.5347-3.4863-10.88-3.4863h-6.7537v-11.571h6.0632c3.7389 0 6.8884-1.0105 9.4484-3.0316 2.56-2.0211 3.84-4.7832 3.84-8.3032 0-3.1326-1.1453-5.6253-3.4358-7.4947-2.2905-1.8695-5.1874-2.8126-8.7074-2.8126-3.4358 0-6.1642 0.90947-8.1853 2.7453-2.0197 1.8407-3.5382 4.1647-4.4126 6.7537l-11.571-4.8168c1.5326-4.3453 4.3453-8.1853 8.4716-11.503 4.1263-3.3179 9.3979-4.9853 15.798-4.9853 4.7326 0 8.9937 0.90947 12.766 2.7453 3.7726 1.8358 6.7368 4.3789 8.8758 7.6126 2.1389 3.2505 3.2 6.8884 3.2 10.931 0 4.1263-0.99368 7.6126-2.9811 10.476-1.9874 2.8632-4.4295 5.0526-7.3263 6.5853v0.69053c3.7405 1.5422 6.9897 4.0751 9.3979 7.3263 2.4421 3.2842 3.6716 7.2084 3.6716 11.789s-1.1621 8.6737-3.4863 12.261c-2.3242 3.5874-5.5411 6.4168-9.6168 8.4716-4.0926 2.0547-8.6905 3.0991-13.794 3.0991-5.9116 0.016718-11.368-1.6843-16.404-5.0864zm71.798-58.004-12.834 9.28-6.4168-9.7347 23.023-16.606h8.8253v78.333h-12.598v-61.272z"
            fill="#4285F4"
        />
    </svg>
);
