import _omit from 'lodash/omit.js';
import {
    MODAL_AGENDA_TEMPLATE_OPEN,
    MODAL_AGENDA_TEMPLATE_CLOSE,
} from '~common/action.types';

const initialState = {
    visible: false,
};

const agendaTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_AGENDA_TEMPLATE_OPEN:
            return {
                ...state,
                ..._omit(action, 'type'),
                visible: true,
            };

        case MODAL_AGENDA_TEMPLATE_CLOSE:
            return initialState;

        default:
            return state;
    }
};

export default agendaTemplateReducer;
