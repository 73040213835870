import {
    INTEGRATION_SCOPES_CALENDAR,
    INTEGRATION_SCOPES_CONTACTS,
    INTEGRATION_SCOPES_FILES,
    INTEGRATION_SCOPES_TASKS,
    INTEGRATION_SCOPES_AUTH,
    INTEGRATION_SCOPES_CRM,
    INTEGRATION_AUTHSOURCE_TO_CONTACTS_MAP,
    INTEGRATION_AUTHSOURCE_TO_FILES_MAP,
    INTEGRATION_STATUS_ACTIVE,
    INTEGRATION_STATUS_INACTIVE,
    INTEGRATION_STATUS_RECONNECT,
    INTEGRATION_STATUS_HIDDEN,
    INTEGRATION_TYPE_CALENDARS,
    INTEGRATION_TYPE_CRM,
    INTEGRATION_TYPE_AUTHENTICATION,
    AUTHSOURCE_CRM,
} from '../constants/index.js';

export const isCalendarIntegration = (profile) =>
    !!(profile?.integrationType === INTEGRATION_TYPE_CALENDARS);

export const isCRMIntegration = (profile) =>
    !!(
        profile?.integrationType === INTEGRATION_TYPE_CRM ||
        AUTHSOURCE_CRM.includes(profile?.authSource)
    );

export const isAuthenticationIntegration = (profile) =>
    !!(profile?.integrationType === INTEGRATION_TYPE_AUTHENTICATION);

export const getContactScopes = (authSource) =>
    INTEGRATION_SCOPES_CONTACTS[
        INTEGRATION_AUTHSOURCE_TO_CONTACTS_MAP[authSource]
    ] || [];

export const getFilesScopes = (authSource) =>
    INTEGRATION_SCOPES_FILES[INTEGRATION_AUTHSOURCE_TO_FILES_MAP[authSource]] ||
    [];

export const getScopesByAuthSource = (authSource) =>
    INTEGRATION_SCOPES_CALENDAR[authSource] ||
    INTEGRATION_SCOPES_CONTACTS[authSource] ||
    INTEGRATION_SCOPES_TASKS[authSource] ||
    INTEGRATION_SCOPES_AUTH[authSource] ||
    INTEGRATION_SCOPES_CRM[authSource] ||
    [];

export const hasAllScopesByAuthSource = (authSource, scopes = []) =>
    getScopesByAuthSource(authSource).every((scope) => scopes.includes(scope));

export const hasIntegrationProfileGotScopes = (profile, scopes) =>
    (scopes || []).every((scope) => (profile?.scopes || []).includes(scope));

export const isActiveIntegrationProfile = (profile) =>
    profile?.status === INTEGRATION_STATUS_ACTIVE;

export const integrationProfileStatusMeta = (profile, prev) => ({
    wasActive: isActiveIntegrationProfile(prev),
    isActive: isActiveIntegrationProfile(profile),
    isHidden: profile.status === INTEGRATION_STATUS_HIDDEN,
    wasDisconnected: prev?.status === INTEGRATION_STATUS_INACTIVE,
    isDisconnected: profile.status === INTEGRATION_STATUS_INACTIVE,
    wasReconnect: prev?.status === INTEGRATION_STATUS_RECONNECT,
    isReconnect: profile.status === INTEGRATION_STATUS_RECONNECT,

    // Data for subscriber use
    statusDisconnected:
        prev?.status &&
        prev?.status !== INTEGRATION_STATUS_INACTIVE &&
        profile.status === INTEGRATION_STATUS_INACTIVE,
    statusConnected:
        !isActiveIntegrationProfile(prev) &&
        isActiveIntegrationProfile(profile),
    statusReconnected:
        prev?.status &&
        !isActiveIntegrationProfile(prev) &&
        isActiveIntegrationProfile(profile),
    statusReconnect:
        prev?.status &&
        prev?.status !== INTEGRATION_STATUS_RECONNECT &&
        profile.status === INTEGRATION_STATUS_RECONNECT,

    remoteResourceCustomerChanged:
        profile.remoteResources?.accountId !== prev?.remoteResources?.accountId,
});
