import React from 'react';

export default ({ title = 'Slack', ...rest }): JSX.Element => (
    <svg viewBox="0 0 127 127" xmlns="http://www.w3.org/2000/svg" {...rest}>
        {title && <title>{title}</title>}
        <path
            d="m27.2 80c0 7.3-5.9 13.2-13.2 13.2s-13.2-5.9-13.2-13.2 5.9-13.2 13.2-13.2h13.2v13.2zm6.6 0c0-7.3 5.9-13.2 13.2-13.2s13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2s-13.2-5.9-13.2-13.2v-33z"
            fill="#E01E5A"
        />
        <path
            d="m47 27c-7.3 0-13.2-5.9-13.2-13.2s5.9-13.2 13.2-13.2 13.2 5.9 13.2 13.2v13.2h-13.2zm0 6.7c7.3 0 13.2 5.9 13.2 13.2s-5.9 13.2-13.2 13.2h-33.1c-7.3 0-13.2-5.9-13.2-13.2s5.9-13.2 13.2-13.2h33.1z"
            fill="#36C5F0"
        />
        <path
            d="m99.9 46.9c0-7.3 5.9-13.2 13.2-13.2s13.2 5.9 13.2 13.2-5.9 13.2-13.2 13.2h-13.2v-13.2zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2s-13.2-5.9-13.2-13.2v-33.1c0-7.3 5.9-13.2 13.2-13.2s13.2 5.9 13.2 13.2v33.1z"
            fill="#2EB67D"
        />
        <path
            d="m80.1 99.8c7.3 0 13.2 5.9 13.2 13.2s-5.9 13.2-13.2 13.2-13.2-5.9-13.2-13.2v-13.2h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2s5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2s-5.9 13.2-13.2 13.2h-33.1z"
            fill="#ECB22E"
        />
    </svg>
);
