import {
    get,
    patchSuppressingWS,
    deleteRequest,
    post,
} from '~client/api/helpers';

const fromOccurrence = (occurrenceId) =>
    occurrenceId ? `/start-from/${occurrenceId}` : '';

export const getWorkspaceTemplatesAndItems = (workspaceId) =>
    get(`/api/template/workspace/${workspaceId}`);

export const addTemplate = (type, libraryId, payload) =>
    post(`/api/template/${type}/${libraryId}`, payload);

export const updateTemplate = (templateId, payload) =>
    post(`/api/template/${templateId}`, payload);

export const deleteTemplate = (templateId, occurrenceId) =>
    deleteRequest(`/api/template/${templateId}${fromOccurrence(occurrenceId)}`);

export const setTemplateEditing = (templateId) =>
    patchSuppressingWS(`/api/template/${templateId}/edit`);

export const setTemplateCancelEditing = (templateId) =>
    patchSuppressingWS(`/api/template/${templateId}/canceledit`);

export const setTemplatePublished = (templateId) =>
    patchSuppressingWS(`/api/template/${templateId}/publish`);
