import _remove from 'lodash/remove.js';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser } from '~modules/user/user.selectors';
import { useWorkspaceDashboardState } from '~hooks';
import {
    isAdmin as isAdminPermission,
    isInactiveAdmin as isInactiveAdminPermission,
} from '~modules/permission/permission.helpers';
import {
    loadMemberMeetingsAndPermissions,
    replaceThenDeleteWorkspaceAccess,
} from '~modules/workspace/workspace.actions';

const emptyList = [];

/**
 * Grab workspace member from url
 */
const useMemberCurrent = () => {
    const { workspaceId, memberId } = useParams();
    if (!memberId) throw new Error(`No memberId found in url params`);

    // There are more details for workspace members (such as their workspace permissions). So look for the workspace member record first.
    // if not found, fallback to the userList lookup
    const { members } = useWorkspaceDashboardState(workspaceId);

    const member = members.find((m) => m.id === memberId);
    if (member) {
        return member;
    }

    return getUser(memberId);
};

/**
 * Grab members from a given workspace
 */
const useMembersByWorkspace = (workspace) => {
    const { members } = useWorkspaceDashboardState(workspace.id);
    return members;
};

/**
 * Load (from the API) the list of meetings, that a user attends in the workspace.
 * Along with this, the API call returns a list of all the other users that have permissions to the same meeting.
 * With this information, identify the user objects,
 * and whether this person is an Admin of the meeting, and if they are the only Admin.
 * @param {*} workspace
 * @param {*} member
 */
const useMemberMeetingsAndPermissionsByWorkspace = (
    workspace,
    member,
    excludeArchived = true
) => {
    const [meetings, setMeetings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const members = useMembersByWorkspace(workspace) || emptyList;

    useEffect(() => {
        async function getUserMeetings() {
            setIsLoading(true);
            setMeetings([]);

            const userMeetings = (
                (await loadMemberMeetingsAndPermissions(
                    workspace.id,
                    member.id
                )) || []
            ).filter((meeting) => !excludeArchived || !meeting.archived);

            if (!userMeetings.length) return setIsLoading(false);

            setMeetings(
                userMeetings.map((meeting) => {
                    const { permissions: meetingPermissions } = meeting;
                    const permissions = meetingPermissions
                        .map((permission) => {
                            const user = members.find(
                                (member) => member.id === permission.user
                            );

                            // only allow members with workspace update access to be assigned
                            if (!user?.accessLevel?.canUpdate) {
                                return;
                            }

                            return {
                                ...permission,
                                user,
                            };
                        })
                        .filter(Boolean);

                    // the person is an Admin if they have an accepted permission, or have left the meeting/workspace
                    // and there is no other active admin

                    const activeMeetingAdmins =
                        meetingPermissions.filter(isAdminPermission);
                    const myActiveAdminPermission = _remove(
                        activeMeetingAdmins,
                        ({ user }) => user === member.id
                    );

                    const inactiveMeetingAdmins = meetingPermissions.filter(
                        isInactiveAdminPermission
                    );

                    const isAdmin =
                        Boolean(myActiveAdminPermission.length) ||
                        inactiveMeetingAdmins.some(
                            ({ user }) => user === member.id
                        );

                    return {
                        ...meeting,
                        permissions,
                        isAdmin,
                        isOnlyAdmin: isAdmin && !activeMeetingAdmins.length,
                    };
                })
            );
            setIsLoading(false);
        }

        // ensure the workspace members have loaded before we kick off the load for this user.
        // This is if browser is opened/refreshed on a specific workspace member detail page.
        if (!members.length) return;

        getUserMeetings();
    }, [workspace.id, member.id, members, member.email, excludeArchived]);

    return { meetings, isLoading };
};

const useReplaceThenDeleteWorkspaceAccess = () => {
    const dispatch = useDispatch();
    return (workspace, member, meetingReassignments) =>
        dispatch(
            replaceThenDeleteWorkspaceAccess(
                workspace,
                member,
                meetingReassignments
            )
        );
};

export {
    useMemberCurrent,
    useMembersByWorkspace,
    useMemberMeetingsAndPermissionsByWorkspace,
    useReplaceThenDeleteWorkspaceAccess,
};
