import _isEmpty from 'lodash/isEmpty.js';
import _get from 'lodash/get.js';
import _pickBy from 'lodash/pickBy.js';
import * as rules from './formvalidation.helper.js';

import {
    RECURRENCE_NEVER,
    RECURRENCE_NOT_SCHEDULED,
    RECURRENCE_DAILY,
    RECURRENCE_WEEKLY,
    RECURRENCE_MONTHLY_ABSOLUTE,
    RECURRENCE_MONTHLY_RELATIVE,
    RECURRENCE_YEARLY_ABSOLUTE,
    RECURRENCE_YEARLY_RELATIVE,
} from '../../common/constants.js';

export const validateOccurrenceAddEdit = (values) => {
    const errors = {};
    errors.title = rules.required(values.title);
    errors.endDateActual = validateActualStartEndDateTime(values);
    return _pickBy(errors, (x) => x !== undefined);
};

export const validateMeetingAddEdit = (values, { group } = {}) => {
    const errors = {};
    errors.title = rules.required(values.title);
    !_isEmpty(group) && (errors.groupId = rules.required(values.groupId));
    errors.recurrenceDetail = validateMeetingRecurrence({
        recurrence: values.recurrence,
        ..._get(values, 'recurrenceDetail', {}),
    });
    return _pickBy(errors, (x) => x !== undefined);
};

export const validateMeetingRecurrence = (values) => {
    const errors = {
        ...validateOccurs(values),
    };
    return Object.keys(errors).length > 0 ? errors : undefined;
};

export const validateOccurrenceDates = (values) => {
    return validateStartEndDateTime(values);
};

export const validateStartEndDateTime = (values) => {
    const errors = {};
    const { startDate, startTime, endDate, endTime } = values;
    errors.endDate = rules.required(endDate);
    if (endDate && startDate && endDate.diff(startDate) < 0) {
        errors.endDate = 'End date must be after Start date';
    }
    if (endDate && startDate && endDate.diff(startDate, 'minutes') > 1440) {
        errors.endDate = 'Duration cannot be longer than 1 day';
    }
    errors.startDate = rules.required(startDate);
    errors.startTime = rules.required(startTime);
    errors.endTime = rules.required(endTime);
    const result = _pickBy(errors, (x) => x !== undefined);
    return Object.keys(result).length > 0 ? result : undefined;
};

export const validateActualStartEndDateTime = (values) => {
    const errors = {};
    const { startDateActual, endDateActual } = values;
    if (
        endDateActual &&
        startDateActual &&
        endDateActual.diff(startDateActual) < 0
    ) {
        errors.endDateActual = 'End time must be after Start time';
    }
    const result = _pickBy(errors, (x) => x !== undefined);
    return Object.keys(result).length > 0 ? result : undefined;
};

export const validateOccurs = (values) => {
    const errors = {};
    const {
        occurs,
        interval,
        index,
        dayOfMonth,
        firstDayOfWeek,
        month,
        day0,
        day1,
        day2,
        day3,
        day4,
        day5,
        day6,
    } = values;
    switch (occurs) {
        case RECURRENCE_NEVER:
        case RECURRENCE_NOT_SCHEDULED: {
            break;
        }
        case RECURRENCE_DAILY: {
            errors.interval = rules.required(interval);
            break;
        }
        case RECURRENCE_WEEKLY: {
            errors.interval = rules.required(interval);
            const atLeastOneDay = [
                day0,
                day1,
                day2,
                day3,
                day4,
                day5,
                day6,
            ].some((d) => d);
            if (!atLeastOneDay) {
                errors.interval = 'At least one day must be selected';
            }
            break;
        }
        case RECURRENCE_MONTHLY_ABSOLUTE: {
            errors.interval = rules.required(interval);
            errors.dayOfMonth = rules.required(dayOfMonth);
            break;
        }
        case RECURRENCE_MONTHLY_RELATIVE: {
            errors.interval = rules.required(interval);
            errors.index = rules.required(index);
            errors.firstDayOfWeek = rules.required(firstDayOfWeek);
            break;
        }
        case RECURRENCE_YEARLY_ABSOLUTE: {
            errors.month = rules.required(month);
            errors.dayOfMonth = rules.required(dayOfMonth);
            break;
        }
        case RECURRENCE_YEARLY_RELATIVE: {
            errors.index = rules.required(index);
            errors.firstDayOfWeek = rules.required(firstDayOfWeek);
            errors.month = rules.required(month);
            break;
        }
        default: {
            errors.occurs = 'Required';
        }
    }
    return _pickBy(errors, (x) => x !== undefined);
};

export const processBodyErrors = (errors) => {
    let errorFields = {};
    if (Object.keys(errors).length) {
        errorFields = rules.getTopLevelErrors(errors);

        const recurrenceDetail = {};
        const patternOrRangeErrors = new RegExp(/(pattern|range)\./);
        Object.keys(errors)
            .filter((field) => field.match(patternOrRangeErrors))
            .forEach((field) => {
                recurrenceDetail[field.replace(patternOrRangeErrors, '')] =
                    errors[field];
            });
        if (Object.keys(recurrenceDetail).length > 0) {
            errorFields.recurrenceDetail = recurrenceDetail;
        }

        const newUsers = [];
        const newUsersErrors = new RegExp(/(newUsers.)/);
        Object.keys(errors)
            .filter((field) => field.match(newUsersErrors))
            .forEach((field) => {
                const path = field.split('.');
                newUsers[path[1]] = {};
                newUsers[path[1]][path[2]] = errors[field];
            });
        if (newUsers.length > 0) {
            errorFields.newUsers = newUsers;
        }
    }
    return errorFields;
};

export const validateTitle = (value) => {
    return !value && 'Required';
};
