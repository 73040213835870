import React from 'react';
import tw, { styled, css } from 'twin.macro';
import Icon from '~modules/common/icons';
import {
    AUTHSOURCE_OUTLOOK_CALENDAR,
    AUTHSOURCE_GOOGLE_CALENDAR,
    HELP_SITE_URL,
} from '~common/constants';
import {
    isIntegrationCalendarSettingsPage,
    pageViewDetails,
} from '~modules/navigation';

// Size conversion for sizing Icons from component they're called from
const SIZES = {
    xl: '1.25rem',
    xxl: '1.5rem',
    xxxl: '1.875rem',
};

const LinkWrap = styled.a(({ hasHover, hasIcon, iconSize }) => [
    tw`whitespace-nowrap`,
    hasHover && tw`text-link`,
    hasIcon &&
        tw`inline-flex items-center  svg:(ml-1 text-icon) hocus:svg:text-icon-dark`,
    css`
        svg {
            ${iconSize && `font-size: ${iconSize};`}
        }
    `,
]);

const HELP_LINKS = {
    'calendar-integration':
        '/Features/02-08%20Integration%20with%20calendar%20apps',
    'outlook-calendar-integration':
        '/Features/02-08%20Integration%20with%20calendar%20apps/#Microsoft-365-Outlook-Calendar-Outlook',
    'outlook-calendar-event-retrieval':
        '/Features/02-08%20Integration%20with%20calendar%20apps/#Integration-Settings-Outlook-Calendar-Event-Retrieval',
    'outlook-calendar-add-event':
        '/Features/02-08%20Integration%20with%20calendar%20apps/#Add-Outlook-Calendar-event-to-MinuteMe',
    'outlook-calendar-reconnect-event':
        '/Features/02-08%20Integration%20with%20calendar%20apps/#Reconnecting-detached-Outlook-events',
    'google-calendar-add-event':
        '/Features/02-08%20Integration%20with%20calendar%20apps/#Add-Google-Calendar-event-to-MinuteMe',
    'google-calendar-integration':
        '/Features/02-08%20Integration%20with%20calendar%20apps/#Google-Calendar',
    'google-calendar-event-retrieval':
        '/Features/02-08%20Integration%20with%20calendar%20apps/#Integration-Settings-Google-Calendar-Event-Retrieval',
    'google-calendar-reconnect-event':
        '/Features/02-08%20Integration%20with%20calendar%20apps/#Reconnecting-detached-Google-Calendar-events',
    'task-integration': '/Features/02-07%20Integration%20with%20task%20apps/',
    smokeball:
        '/Features/02-09-Integration-with-advanced-business-systems/#Smokeball',
    asana: '/Features/02-07%20Integration%20with%20task%20apps/#Asana',
    'microsoft-to-do':
        '/Features/02-07%20Integration%20with%20task%20apps/#Microsoft-To-Do',
    'google-tasks':
        '/Features/02-07%20Integration%20with%20task%20apps/#Google-Tasks',
};

const HelpLink = ({
    hasHover,
    iconSize,
    wrapped,
    page,
    handleClick,
    iconName,
    asButton,
    linkText = <Icon name="help" />,
}) => {
    if (!HELP_SITE_URL) return null;

    iconSize = SIZES[iconSize];

    const isIconOnly = typeof linkText !== 'string';

    const hasIcon = !!iconName;

    const href = (
        <LinkWrap
            as={asButton && 'button'}
            type={asButton && 'button'}
            href={!asButton ? `${HELP_SITE_URL}${page}` : null}
            target={!asButton ? '_blank' : null}
            onClick={handleClick}
            {...{
                hasHover,
                wrapped,
                iconSize,
                isIconOnly,
                hasIcon,
            }}
        >
            {linkText}
            {hasIcon && <Icon name={iconName} />}
        </LinkWrap>
    );

    return wrapped ? <div>{href}</div> : href;
};

const getHelpLinkByPathMapping = (location) => {
    const { pathname: path, state } = location;
    const {
        isDashboardPage,
        dashboardPage,
        isWorkspacePage,
        workspacePage,
        detailPage,
        isTemplatePage,
        templatePage,
    } = pageViewDetails(path);

    if (isWorkspacePage) {
        if (workspacePage[3] === 'upgrade') {
            return '/Manage/03-04%20Billing%20and%20payment/#Upgrade-or-downgrade-plan';
        }
        if (workspacePage[3] === 'update') {
            return '/Manage/03-04%20Billing%20and%20payment/#Payments';
        }
        return '/Manage/03-03%20Workspaces/';
    }

    if (isTemplatePage) {
        // templatePage[3] is the templateId when viewing a detail page for a template
        if (templatePage[3]) {
            return '/Manage/03-08%20Templates/#Editing-an-existing-library-template';
        }
        // template list: either All templates or template category page
        return '/Manage/03-08%20Templates/#Library-templates';
    }

    if (isDashboardPage) {
        const {
            dashName: dashboard1,
            dashId: category,
            dashNameAlt: dashboard2,
        } = dashboardPage;
        switch (category) {
            case AUTHSOURCE_OUTLOOK_CALENDAR: {
                // Outlook calendar dashboard
                return HELP_LINKS['outlook-calendar-integration'];
            }
            case AUTHSOURCE_GOOGLE_CALENDAR: {
                // Google calendar dashboard
                return HELP_LINKS['google-calendar-integration'];
            }
        }
        switch (dashboard1) {
            // this could be a category dashboard
            case 'meetings': {
                return '/Get-started/01-06%20Arranging%20meetings/#In-the-calendar-dashboard';
            }
            case 'series': {
                return '/Get-started/01-06%20Arranging%20meetings/#In-the-series-dashboard';
            }
            case 'action-items': {
                return '/Manage/03-05%20My%20action%20items';
            }
        }
        switch (dashboard2) {
            case 'meetings': {
                // this is the All meetings group on the Calendar dashboard
                return '/Manage/03-01%20Dashboards/#Calendar-dashboard';
            }
            case 'series': {
                // this is the All meetings group on the Series dashboard
                return '/Manage/03-01%20Dashboards/#Series-dashboard';
            }
            case 'action-items': {
                return '/Manage/03-05%20My%20action%20items';
            }
            case 'documents': {
                return '/Manage/03-06%20My%20documents';
            }
            case 'search': {
                return '';
            }
            case 'settings': {
                if (/notification-preferences/.test(path)) {
                    return '';
                }
                if (isIntegrationCalendarSettingsPage(path)) {
                    return HELP_LINKS['calendar-integration'];
                }
                if (/integration/.test(path)) {
                    return HELP_LINKS['task-integration'];
                }
                return '';
            }
        }
    }

    if (detailPage) {
        if (state?.isSeriesTemplateOpen) {
            return '/Features/02-03%20Series/#Agenda-Template';
        }
        if (/^\/minutes/.test(detailPage.path)) {
            return '/Features/02-02%20Meeting%20Details/';
        }
        if (/^\/action-items/.test(detailPage.path)) {
            return '/Features/02-02%20Meeting%20Details/#Action-items';
        }
        if (/^\/documents/.test(detailPage.path)) {
            return '/Features/02-02%20Meeting%20Details/#Documents';
        }
    }

    return '';
};

const HelpLinkDefault = ({ modal, ...rest }) => {
    let page;

    switch (modal) {
        //Workspace
        case 'workspace-permissions': {
            page = '/Features/02-05%20Permissions/#workspace';
            break;
        }
        case 'cant-delete-workspace': {
            page = '/Manage/03-03%20Workspaces/#Deleting-a-workspace';
            break;
        }
        case 'move-to': {
            page = '/Manage/moving-series-and-meetings';
            break;
        }
        case 'move-meeting': {
            page =
                '/Manage/moving-series-and-meetings/#Move-meeting-to-another-series';
            break;
        }
        case 'move-series': {
            page =
                '/Manage/moving-series-and-meetings/#Move-series-to-another-workspace';
            break;
        }
        case 'billing-payments': {
            page = '/Manage/03-04%20Billing%20and%20payment/';
            break;
        }
        case 'trusted-email-domains': {
            page = '/Manage/03-03%20Workspaces/#Trusted-email-domains';
            break;
        }
        case 'managing-team-members': {
            page = '/Manage/03-03%20Workspaces/#Manage-team-members';
            break;
        }
        case 'When-should-I-create-a-new-Workspace': {
            page =
                '/Manage/03-03%20Workspaces/#When-should-I-create-a-new-Workspace';
            break;
        }
        case 'upload-workspace-logo': {
            page = '/Manage/03-03%20Workspaces/#Upload-a-workspace-logo';
            break;
        }
        // Meeting choice modal
        case 'Meetings': {
            page = '/Get-started/01-05%20Start%20guide/#Step-1-Add-the-meeting';
            break;
        }
        // Series/Single meetings
        case 'meeting-add-single':
        case 'meeting-add-series': {
            page =
                '/Get-started/01-05%20Start%20guide/#Create-a-series-or-meeting';
            break;
        }
        case 'meeting-edit-series': {
            page = '/Features/02-03%20Series/#Editing-a-Series';
            break;
        }
        case 'meeting-edit-single': {
            page =
                '/Features/02-02%20Meeting%20Details/#Editing-meeting-details';
            break;
        }
        case 'meeting-edit-series-attendees': {
            page =
                '/Features/02-03%20Series/#Regular-attendees-and-meeting-permissions';
            break;
        }
        case 'meeting-edit-single-attendees': {
            page =
                '/Features/02-02%20Meeting%20Details/#Attendees-and-meeting-permissions';
            break;
        }
        case 'meeting-edit-single-schedule': {
            page = '/Features/02-02%20Meeting%20Details/#Date-x2F-Time';
            break;
        }
        case 'meeting-edit-series-schedule': {
            page = '/Features/02-03%20Series/#Schedule';
            break;
        }
        case 'meeting-edit-single-location': {
            page = '/Features/02-02%20Meeting%20Details/#Location';
            break;
        }
        case 'meeting-edit-series-location': {
            page = '/Features/02-03%20Series/#Regular-Location';
            break;
        }
        case 'send-email-to-attendees': {
            page =
                '/Features/02-02%20Meeting%20Details/#send-email-to-attendees';
            break;
        }
        case 'send-email-to-recipients': {
            // TODO: Update once helpsite url is available
            page =
                '/Features/02-02%20Meeting%20Details/#send-email-to-recipients';
            break;
        }
        case 'view-sent-agenda-email-detail': {
            // TODO: Update once helpsite url is available
            page =
                '/Features/02-02%20Meeting%20Details/#viewing-sent-agenda-detail';
            break;
        }
        case 'view-sent-minutes-email-detail': {
            // TODO: Update once helpsite url is available
            page =
                '/Features/02-02%20Meeting%20Details/#viewing-sent-minutes-detail';

            break;
        }
        case 'view-meeting-email-activity': {
            page = '/Features/02-02%20Meeting%20Details/#Activity';

            break;
        }
        // Send minutes/agenda
        case 'Send minutes':
        case 'send-minutes-modal-send': {
            page = '/Features/02-02%20Meeting%20Details/#Sending-the-minutes';

            break;
        }
        case 'send-minutes-modal-agenda': {
            page = '/Features/02-02%20Meeting%20Details/#Emailing-the-Agenda';
            break;
        }
        case 'print-preview-agenda': {
            page =
                '/Features/02-02%20Meeting%20Details/#Previewing-or-printing-the-agenda';
            break;
        }
        // Archive / Delete
        case 'archive-meeting-or-series': {
            page = '/Manage/03-07%20Archive/#Archiving-series-and-meetings';
            break;
        }
        case 'Archive meeting': {
            page = '/Manage/03-07%20Archive/#Archiving-meetings';
            break;
        }
        case 'Archive series': {
            page = '/Manage/03-07%20Archive/#Archiving-a-Series';
            break;
        }
        case 'Delete meeting': {
            page = '/Features/02-02%20Meeting%20Details/#Delete-a-meeting';
            break;
        }
        case 'Delete series': {
            page = '/Features/02-03%20Series/#Delete-a-series';
            break;
        }
        // Leave
        case 'Leave series': {
            page = '/Features/02-03%20Series/#Leave-the-series-x2F-meeting';
            break;
        }
        // Action items
        case 'Show previous action items': {
            page =
                '/Features/02-01%20Action%20items/#In-the-minutes-of-a-discussion-topic';
            break;
        }
        case 'Link action item': {
            page =
                '/Features/02-01%20Action%20items/#Linking-action-items-for-discussion-across-meetings';
            break;
        }
        case 'Unlink action item': {
            page =
                '/Features/02-01%20Action%20items/#Remove-linked-action-items';
            break;
        }
        case 'Delete Action item': {
            page = '/Features/02-01%20Action%20items/#Delete-an-action-item';
            break;
        }
        case 'Action item comments': {
            page =
                '/Features/02-01%20Action%20items/#Add-comments-to-an-action-item';
            break;
        }
        // Documents
        case 'Delete document': {
            page = '/Features/02-06%20Documents/#Deleting-documents';
            break;
        }
        case 'Document comments': {
            page = '/Features/02-06%20Documents/#Add-comments-to-a-document';
            break;
        }
        // Topics
        case 'Move topic':
        case 'Copy topic': {
            page =
                '/Features/02-02%20Meeting%20Details/#Moving-or-Copying-a-minutes-topic-to-another-meeting';
            break;
        }
        case 'Delete Topic': {
            page = '/Features/02-02%20Meeting%20Details/#Deleting-a-topic';
            break;
        }
        case `Link topic to matter`: {
            page = '/Features/02-09-Integration-with-advanced-business-systems';
            break;
        }
        // Integration
        case 'outlook-calendar-integration':
        case 'outlook-calendar-event-retrieval':
        case 'outlook-calendar-add-event':
        case 'outlook-calendar-reconnect-event':
        case 'google-calendar-integration':
        case 'google-calendar-event-retrieval':
        case 'google-calendar-add-event':
        case 'google-calendar-reconnect-event': {
            page = HELP_LINKS[modal];
            break;
        }
        // Categories
        case 'Delete category': {
            page = '/Manage/03-02%20Categories/#Deleting-categories';
            break;
        }
        // Other
        case 'get-started': {
            page = '/Get-started/01-05%20Start%20guide/';
            break;
        }
        case 'what-is-minuteme': {
            page = '/Get-started/01-01%20What%20is%20MinuteMe/';
            break;
        }
        case 'sign-in-help': {
            page = '/Get-started/01-08-Accessing-MinuteMe/';
            break;
        }
        case 'sign-in-help-microsoft': {
            page =
                '/Get-started/01-08-Accessing-MinuteMe/#Sign-up-x2F-in-with-Microsoft';
            break;
        }
        case 'sign-in-help-google': {
            page =
                '/Get-started/01-08-Accessing-MinuteMe/#Sign-up-x2F-in-with-Google';
            break;
        }
        case 'sign-in-again': {
            page =
                '/Manage/profile-and-settings/#Understanding-Why-You-Were-Signed-Out-of-Your-Account';
            break;
        }
        case 'integration-with-onedrive': {
            page =
                '/Features/integration-with-cloud-storage/#Microsoft-OneDrive';
            break;
        }
        case 'integration-with-google drive': {
            page = '/Features/integration-with-cloud-storage/#Google-Drive';
            break;
        }
        case 'google-services-usage-disclosure': {
            page =
                '/Get-started/01-08-Accessing-MinuteMe/#Google-API-Services-Usage-Disclosure';
            break;
        }
        case 'new-features': {
            page = '/Updates/04-01%20New%20feature/';
            break;
        }
        case 'reinvite-rejected': {
            page = '/Features/02-02%20Meeting%20Details/#status-rejected';
            break;
        }
        case 'reinvite-revoked': {
            page = '/Features/02-02%20Meeting%20Details/#status-revoked';
            break;
        }
        case 'reinvite-left': {
            page = '/Features/02-02%20Meeting%20Details/#status-left';
            break;
        }
        // Agenda template / Template library
        case 'clear-template': {
            page =
                '/Features/02-03%20Series/#Remove-the-link-to-a-Library-template';
            break;
        }
        case 'delete-template': {
            page =
                '/Manage/03-08%20Templates/#Editing-an-existing-library-template';
            break;
        }
        case 'agenda-template-modal': {
            page = '/Features/02-03%20Series/#Agenda-Template';
            break;
        }
        case 'choose-workspace-for-template': {
            page = '/Manage/03-08%20Templates/#Library-templates';
            break;
        }
        case 'edit-agenda-topics': {
            page = '/Features/02-03%20Series/#Editing-the-Agenda-template';
            break;
        }
        case 'edit-agenda-template': {
            page = '/Features/02-03%20Series/#Editing-the-Agenda-template';
            break;
        }
        case 'edit-library-template': {
            page =
                '/Manage/03-08%20Templates/#Editing-an-existing-library-template';
            break;
        }
        case 'create-library-template': {
            page = '/Manage/03-08%20Templates/#From-a-blank-template';
            break;
        }
    }

    const pathname = window?.location?.pathname;

    return (
        <HelpLink
            page={page || getHelpLinkByPathMapping({ pathname }) || ''}
            {...rest}
        />
    );
};

export {
    HelpLinkDefault as default,
    HelpLink,
    getHelpLinkByPathMapping,
    HELP_LINKS,
};
