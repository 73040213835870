import tw from 'twin.macro';
import { useUI, useSearchParams } from '~hooks';
import FilterIcon from '~icons/filter';

const FiltersToggle = () => {
    const { setIsFiltersOpen } = useUI();
    const searchParams = useSearchParams();
    const hasActiveFilters = Boolean(Object.keys(searchParams).length);

    return (
        <button
            type="button"
            onClick={() => setIsFiltersOpen((o) => !o)}
            css={[
                tw`px-1.5 focus:outline-none text-icon-dark hocus:text-black`,
                hasActiveFilters &&
                    tw`relative after:(w-3 h-3 bg-secondary rounded-full absolute top-0 mt-1 ml-1 border-2 border-white)`,
            ]}
        >
            <FilterIcon tw="text-lg sm:text-xl" />
        </button>
    );
};

export default FiltersToggle;
