import React from 'react';
import tw, { styled } from 'twin.macro';
import CheckRadioBase from '~modules/common/form-elements/CheckRadioBase';

const Radio = styled(CheckRadioBase)`
    &:checked + div {
        ${tw`text-primary border-primary hover:text-primary`}
        ${({ solid }) => solid && tw`text-white bg-primary hover:text-white`}
        ${({ disabled }) =>
            disabled &&
            tw`hover:text-icon-disabled text-icon-disabled border-icon-disabled`}
    }
`;

/* requires: value, checked */
export default ({ handleOnChange, ...rest }) => (
    <Radio
        round
        type="radio"
        name="radioGroup"
        onChange={({ target }) => {
            handleOnChange(target.value);
        }}
        {...rest}
    />
);
