import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <rect
            x="2"
            y="1.9956"
            width="5.6"
            height="5.6009"
            rx="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <rect
            x="2"
            y="13.198"
            width="5.6"
            height="5.6009"
            rx="2"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m12.267 4.7961h17.733"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m12.267 15.998h17.733"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
        <rect
            x="2"
            y="24.399"
            width="5.6"
            height="5.6009"
            rx="2"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m12.267 27.146h17.733"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
