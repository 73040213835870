import React from 'react';

const ARCHIVE_ID = 'shape-archive';

const ArchiveIconInner = ({
    iconThick = false,
}: {
    iconThick?: boolean;
}): JSX.Element => (
    <>
        <path
            d="M12 15H20M4 10V24C4 26.2091 5.79086 28 8 28H24C26.2091 28 28 26.2091 28 24V10H4ZM2 10V8C2 5.79086 3.79086 4 6 4H26C28.2091 4 30 5.79086 30 8V10H2Z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
        />
    </>
);

const ArchiveIcon = ({
    iconThick = false,
    isSymbolRef = false,
    title = '',
    ...rest
}): JSX.Element => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        {isSymbolRef ? (
            <use href={`#${ARCHIVE_ID}`} xlinkHref={`#${ARCHIVE_ID}`} />
        ) : (
            <ArchiveIconInner iconThick={iconThick} />
        )}
    </svg>
);

export { ArchiveIcon as default, ArchiveIconInner, ARCHIVE_ID };
