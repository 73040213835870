import { AUTHSOURCE_AZUREAD, AUTHSOURCE_GOOGLE } from '~common/constants';
import { getContactScopes } from '~shared/integration/integration.helper';

export const getContactsConfig = ({ authSource }) => {
    switch (authSource) {
        case AUTHSOURCE_AZUREAD: {
            return {
                shortName: 'Microsoft',
                longName: 'Microsoft',
                scopes: getContactScopes(authSource),
            };
        }
        case AUTHSOURCE_GOOGLE: {
            return {
                shortName: 'Google',
                longName: 'Google',
                scopes: getContactScopes(authSource),
            };
        }
        default: {
            return {};
        }
    }
};
