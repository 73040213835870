import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M12 8H28M12 16H28M12 24H28"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M6.77461 13.075V14H2.77461V13.075H4.23961V8.86C4.23961 8.69333 4.24461 8.52167 4.25461 8.345L3.21461 9.215C3.15461 9.26167 3.09461 9.29167 3.03461 9.305C2.97794 9.315 2.92294 9.315 2.86961 9.305C2.81961 9.295 2.77461 9.27833 2.73461 9.255C2.69461 9.22833 2.66461 9.2 2.64461 9.17L2.25461 8.635L4.45961 6.76H5.47461V13.075H6.77461Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.7"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M6.50461 23.85C6.63794 23.85 6.74294 23.8883 6.81961 23.965C6.89961 24.0383 6.93961 24.1367 6.93961 24.26V25H1.97961V24.59C1.97961 24.5067 1.99628 24.42 2.02961 24.33C2.06628 24.2367 2.12294 24.1533 2.19961 24.08L4.39461 21.88C4.57794 21.6933 4.74128 21.515 4.88461 21.345C5.03128 21.175 5.15294 21.0067 5.24961 20.84C5.34628 20.6733 5.41961 20.505 5.46961 20.335C5.52294 20.1617 5.54961 19.98 5.54961 19.79C5.54961 19.6167 5.52461 19.465 5.47461 19.335C5.42461 19.2017 5.35294 19.09 5.25961 19C5.16961 18.91 5.05961 18.8433 4.92961 18.8C4.80294 18.7533 4.65961 18.73 4.49961 18.73C4.35294 18.73 4.21628 18.7517 4.08961 18.795C3.96628 18.835 3.85628 18.8933 3.75961 18.97C3.66294 19.0433 3.58128 19.13 3.51461 19.23C3.44794 19.33 3.39794 19.44 3.36461 19.56C3.30794 19.7133 3.23461 19.815 3.14461 19.865C3.05461 19.915 2.92461 19.9267 2.75461 19.9L2.10461 19.785C2.15461 19.4383 2.25128 19.135 2.39461 18.875C2.53794 18.6117 2.71628 18.3933 2.92961 18.22C3.14628 18.0433 3.39294 17.9117 3.66961 17.825C3.94961 17.735 4.24961 17.69 4.56961 17.69C4.90294 17.69 5.20794 17.74 5.48461 17.84C5.76128 17.9367 5.99794 18.075 6.19461 18.255C6.39128 18.435 6.54461 18.6517 6.65461 18.905C6.76461 19.1583 6.81961 19.44 6.81961 19.75C6.81961 20.0167 6.77961 20.2633 6.69961 20.49C6.62294 20.7167 6.51794 20.9333 6.38461 21.14C6.25461 21.3467 6.10128 21.5467 5.92461 21.74C5.74794 21.9333 5.56294 22.13 5.36961 22.33L3.73961 23.995C3.89628 23.9483 4.05128 23.9133 4.20461 23.89C4.36128 23.8633 4.50961 23.85 4.64961 23.85H6.50461Z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
            stroke="currentColor"
            strokeWidth="0.7"
        />
    </svg>
);
