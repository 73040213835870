import {
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_ERROR,
    UPLOAD_DOCUMENTS_SUCCESS,
    EDIT_DOCUMENT,
    RENAME_DOCUMENT_REQUEST,
    RENAME_DOCUMENT_ERROR,
} from '~common/action.types';
import {
    deleteDocument as deleteDocumentXHR,
    renameDocument,
} from './document.api.js';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import { getDocumentFromStoreById } from './document.selectors.js';

function deleteDocumentRequest() {
    return {
        type: DELETE_DOCUMENT_REQUEST,
    };
}

export function deleteDocumentSuccess(doc) {
    return {
        type: DELETE_DOCUMENT_SUCCESS,
        doc,
    };
}

export function editDocument(doc) {
    return {
        type: EDIT_DOCUMENT,
        doc,
    };
}

function deleteDocumentError() {
    return {
        type: DELETE_DOCUMENT_ERROR,
    };
}

export function documentUploadSuccess(docs) {
    return {
        type: UPLOAD_DOCUMENTS_SUCCESS,
        docs,
    };
}

function renameDocumentRequest(id, values) {
    return {
        type: RENAME_DOCUMENT_REQUEST,
        docId: id,
        values,
    };
}

function renameDocumentError(doc, error) {
    return {
        type: RENAME_DOCUMENT_ERROR,
        doc,
        error,
    };
}

export function deleteDocument(doc, content) {
    return (dispatch) => {
        const promise = () => {
            dispatch(deleteDocumentRequest());
            return deleteDocumentXHR(doc.id)
                .then(() => dispatch(deleteDocumentSuccess(doc)))
                .catch((err) => {
                    dispatch(deleteDocumentError(err));
                });
        };

        dispatch(
            openConfirm({
                header: 'Delete document',
                content,
                promise,
            })
        );
    };
}

export function onRenameDocument({ id, ...rest }) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    return async (dispatch, getState) => {
        const prevDocument = getDocumentFromStoreById(getState(), id);

        dispatch(renameDocumentRequest(id, rest));

        renameDocument(id, rest).catch((error) => {
            dispatch(renameDocumentError(prevDocument, error));
        });
    };
}
