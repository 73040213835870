import _isEqual from 'lodash/isEqual.js';
import {
    LOGOUT_SUCCESS,
    USER_CLEAR_ACTIVE,
    USER_JOIN_WEBSOCKET,
    USER_LEAVE_WEBSOCKET,
    USER_TYPING_WEBSOCKET,
    USER_TOPIC_CHANGE_WEBSOCKET,
    WEBSOCKET_DISCONNECTED,
    USER_ACTIVELOCATION_WEBSOCKET,
} from '../../common/action.types.js';

const initialState = [];

const activeUsersReducer = (state = initialState, action) => {
    const { type, ...params } = action;
    switch (type) {
        case USER_JOIN_WEBSOCKET:
            return [...state, params];

        case USER_LEAVE_WEBSOCKET: {
            return state.filter(({ socketId }) => socketId !== params.socketId);
        }
        case USER_ACTIVELOCATION_WEBSOCKET: {
            const activeUserEntry = state.find(
                (activeUser) =>
                    (activeUser.socketId === params.socketId ||
                        (!params.socketId &&
                            activeUser.userId === params.userId)) &&
                    activeUser.activePage.state.minuteItemIds?.some?.((mi) =>
                        action.minuteItemIds.includes(mi)
                    )
            );

            if (!activeUserEntry) return state;

            const activeUserEntryIndex = state.indexOf(activeUserEntry);

            const updatedState = state.map((activeUser, idx) =>
                idx === activeUserEntryIndex
                    ? {
                          ...activeUser,
                          location: params.location,
                          user: { ...activeUser.user, ...params.user },
                      }
                    : activeUser
            );

            return updatedState;
        }

        case USER_TYPING_WEBSOCKET:
        case USER_TOPIC_CHANGE_WEBSOCKET: {
            // typing event gets triggered when a minutes update event comes through.
            // But this event doesn't have the socket id.
            // So we use the socket id by default, and if that's not there fallback to the userId.

            const activeUserEntry = state.find(
                (activeUser) =>
                    activeUser.socketId === params.socketId ||
                    (!params.socketId && activeUser.userId === params.userId)
            );

            if (activeUserEntry && _isEqual(activeUserEntry, params))
                return state;

            const updatedState = state.map((activeUser) =>
                activeUser.socketId === params.socketId ||
                (!params.socketId && activeUser.userId === params.userId)
                    ? {
                          ...activeUser,
                          ...params,
                          user: { ...activeUser.user, ...params.user },
                      }
                    : activeUser
            );

            return _isEqual(state, updatedState) ? state : updatedState;
        }

        case WEBSOCKET_DISCONNECTED:
        case USER_CLEAR_ACTIVE:
        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default activeUsersReducer;
