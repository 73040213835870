import _find from 'lodash/find.js';
import _forEach from 'lodash/forEach.js';
import _get from 'lodash/get.js';
import _last from 'lodash/last.js';
import React from 'react';
import ObjectID from 'bson-objectid';
import { replace } from 'connected-react-router';
import {
    MODAL_GROUP_OPEN,
    MODAL_GROUP_CLOSE,
    STAR_MEETING_TOGGLE_REQUEST,
    STAR_MEETING_TOGGLE_SUCCESS,
    STAR_MEETING_TOGGLE_FAILURE,
    MEETING_CHANGE_GROUP,
    MEETING_CHANGE_GROUP_ERROR,
    OCCURRENCE_GROUP_ADD,
    OCCURRENCE_GROUP_ADD_ERROR,
    OCCURRENCE_GROUP_REMOVE,
    OCCURRENCE_GROUP_REMOVE_ERROR,
    ADD_GROUP_REQUEST,
    ADD_GROUP_SUCCESS,
    ADD_GROUP_ERROR,
    EDIT_GROUP_REQUEST,
    EDIT_GROUP_SUCCESS,
    EDIT_GROUP_ERROR,
    DELETE_GROUP_REQUEST,
    DELETE_GROUP_ERROR,
} from '~common/action.types';
import {
    editGroup,
    addGroup,
    deleteGroup,
    unstarGroupMeeting,
    starGroupMeeting,
    addOccurrenceToGroup,
    removeOccurrenceFromGroup,
    moveMeetingToAnotherGroup as moveMeetingToAnotherGroupApi,
} from './group.api.js';
import {
    formSubmissionServerError,
    processBodyErrors,
} from '~components/formvalidation/formvalidation.helper';
import {
    getMeetingGroupsForWorkspace,
    getTagGroupsSelector,
    getGroupFromStoreById,
    getWorkspaceGroupByMeetingId,
    getWorkspaceFromStoreById,
} from './group.selectors.js';
import {
    GROUP_TYPE_MEETING,
    GROUP_TYPE_TAG,
    MODAL_TYPE_OK_NO_ACTION,
} from '~client/common/constants';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import { alertError } from '~modules/alert/alert.actions';
import history from '~modules/navigation';
import { replaceUrlToDashboardOnGroupDelete } from '~modules/navigation/navigation.actions';

export const openEditGroupModal = (group) => ({
    type: MODAL_GROUP_OPEN,
    group,
});

export const closeGroupModal = () => ({
    type: MODAL_GROUP_CLOSE,
});

const toggleStarredRequest = (group) => ({
    type: STAR_MEETING_TOGGLE_REQUEST,
    group,
});

const toggleStarredSuccess = (group) => ({
    type: STAR_MEETING_TOGGLE_SUCCESS,
    group,
});

const toggleStarredFailure = (group) => ({
    type: STAR_MEETING_TOGGLE_FAILURE,
    group,
});

export const addGroupRequest = (group) => ({
    type: ADD_GROUP_REQUEST,
    group,
});

export const addGroupSuccess = (group) => ({
    type: ADD_GROUP_SUCCESS,
    group,
});

export const addGroupError = (err, group) => ({
    type: ADD_GROUP_ERROR,
    err,
    group,
});

export const editGroupRequest = (group) => ({
    type: EDIT_GROUP_REQUEST,
    group,
});

const editGroupSuccess = (group) => ({
    type: EDIT_GROUP_SUCCESS,
    group,
});

export const editGroupError = (group, err) => ({
    type: EDIT_GROUP_ERROR,
    group,
    err,
});

export const moveMeetingToAnotherGroupRequest = (
    meetingId,
    fromGroup,
    toGroup
) => ({
    type: MEETING_CHANGE_GROUP,
    meetingId,
    fromGroup,
    toGroup,
});

export const moveMeetingToAnotherGroupError = (
    meetingId,
    fromGroup,
    toGroup
) => ({
    type: MEETING_CHANGE_GROUP_ERROR,
    meetingId,
    fromGroup,
    toGroup,
});

const addOccurrenceToGroupRequest = (occurrenceId, group) => ({
    type: OCCURRENCE_GROUP_ADD,
    occurrenceId,
    group,
});
const addOccurrenceToGroupError = (err, occurrenceId, group) => ({
    type: OCCURRENCE_GROUP_ADD_ERROR,
    err,
    occurrenceId,
    group,
});

const removeOccurrenceFromGroupRequest = (occurrenceId, group) => ({
    type: OCCURRENCE_GROUP_REMOVE,
    occurrenceId,
    group,
});
const removeOccurrenceFromGroupError = (err, occurrenceId, group) => ({
    type: OCCURRENCE_GROUP_REMOVE_ERROR,
    err,
    occurrenceId,
    group,
});

export const onGroupSave = (values, workspace) => (dispatch) =>
    values.id
        ? dispatch(onEditGroup(values))
        : dispatch(onAddGroup(values, workspace));

const onEditGroup = (group) => async (dispatch) => {
    dispatch(editGroupRequest(group));

    try {
        const updatedGroup = await editGroup(group);
        dispatch(editGroupSuccess(updatedGroup));
    } catch (err) {
        const newError = await formSubmissionServerError(
            err,
            processBodyErrors
        );
        dispatch(editGroupError(null, newError));
        throw newError;
    }
};

export const onDeleteGroup =
    (
        group,
        options = { type: 'category', navigateOnDelete: true } // TODO: Fix this the next time the file is edited.
    ) =>
    // eslint-disable-next-line require-await
    async (dispatch, getState) => {
        if (!group.hasMeetings) {
            const { pathname } = history.location;
            const groupPreDelete = getGroupFromStoreById(getState(), group.id);
            const workspace = groupPreDelete.workspaceId;

            dispatch({ type: DELETE_GROUP_REQUEST, group });
            // TODO: Sniff for a workspace parent and replaceUrlToThat
            // rather than heading back to the dashboard
            options.navigateOnDelete &&
                dispatch(
                    replaceUrlToDashboardOnGroupDelete(pathname, workspace)
                );
            dispatch(deleteGroupBackground(group, groupPreDelete, pathname));
        } else {
            dispatch(
                openConfirm({
                    header: 'Delete category',
                    content: `It seems like you still have meetings in this category. Please move the meetings to other categories and try again.`,
                    modalType: MODAL_TYPE_OK_NO_ACTION,
                })
            );
        }
    };

const deleteGroupBackground =
    (group, groupPreDelete, pathname) => async (dispatch) => {
        try {
            await deleteGroup(group);
        } catch (error) {
            dispatch({
                type: DELETE_GROUP_ERROR,
                group: groupPreDelete,
                error,
            });
            dispatch(
                alertError({
                    id: `onDeleteGroup-${group.id}`,
                    title: 'Category could not be deleted',
                    error,
                })
            );
            dispatch(replace(pathname));
        }
    };

const onAddGroup = (group, workspace) => async (dispatch, getState) => {
    const state = getState();
    const params = {
        id: String(new ObjectID()),
        type: GROUP_TYPE_MEETING,
        meetings: [],
        ...group,
    };
    if (!params.displayOrder) {
        const workspaceObj = getWorkspaceFromStoreById(state, { workspace });
        const groups = (
            params.type === GROUP_TYPE_MEETING
                ? getMeetingGroupsForWorkspace(state, {
                      workspace: workspaceObj,
                  })
                : getTagGroupsSelector(state)
        ).sort((a, b) => a.displayOrder - b.displayOrder);
        params.displayOrder = _get(_last(groups), 'displayOrder', 0) + 1;
    }
    dispatch(addGroupRequest(params));
    try {
        const updatedGroup = await addGroup(params);
        dispatch(addGroupSuccess(updatedGroup));
        return updatedGroup;
    } catch (err) {
        const newError = await formSubmissionServerError(
            err,
            processBodyErrors
        );
        dispatch(addGroupError(newError, params));
        throw newError;
    }
};

export const onToggleStarred = (group, meeting) => (dispatch, getState) => {
    dispatch(toggleStarredRequest(group));

    let groupToToggle = group;
    if (group.id === 'starred') {
        // lookup the correct group for this starred meeting, so it can be removed from the group
        _forEach(getState().groups.groups, (g) => {
            if (_find(g.meetings, { meetingId: meeting.id })) {
                groupToToggle = g;
                return false;
            }
        });
    }

    const act = meeting.starred ? unstarGroupMeeting : starGroupMeeting;

    act(groupToToggle, meeting)
        .then((updatedGroup) => {
            dispatch(toggleStarredSuccess(updatedGroup));
        })
        .catch(() => {
            dispatch(toggleStarredFailure(groupToToggle));
        });
};

export const moveMeetingToAnotherGroup =
    (meetingId, payload) => async (dispatch, getState) => {
        const { fromGroup, toGroup } = payload;
        const isNewGroup = !toGroup.id;
        try {
            if (isNewGroup) {
                // set workspace for the new group, based on the meeting
                const workspace = getWorkspaceGroupByMeetingId({
                    meeting: meetingId,
                });

                const groups = getMeetingGroupsForWorkspace(getState(), {
                    workspace,
                }).sort((a, b) => a.displayOrder - b.displayOrder);
                toGroup.id = String(new ObjectID());
                toGroup.type = GROUP_TYPE_MEETING;
                toGroup.meetings = [];
                toGroup.displayOrder =
                    _get(_last(groups), 'displayOrder', 0) + 1;

                toGroup.workspaceId = workspace.id;

                // optimistically update the redux store with the new group
                dispatch(addGroupRequest(toGroup));
            }

            dispatch(
                moveMeetingToAnotherGroupRequest(meetingId, fromGroup, toGroup)
            );
            await moveMeetingToAnotherGroupApi(meetingId, toGroup);
        } catch (error) {
            isNewGroup && dispatch(addGroupError(error, toGroup));
            dispatch(
                moveMeetingToAnotherGroupError(meetingId, fromGroup, toGroup)
            );
            dispatch(
                alertError({
                    id: `moveMeetingToAnotherGroup-${meetingId}`,
                    title: (
                        <>
                            Meeting could not be moved to &quot;{toGroup?.title}
                            &quot;
                        </>
                    ),
                    error,
                })
            );
        }
    };

export const addOccurrenceToTagGroup =
    (id, group) => async (dispatch, getState) => {
        const isNewGroup = !group.id;
        try {
            if (isNewGroup) {
                const groups = getTagGroupsSelector(getState());
                group.id = String(new ObjectID());
                group.type = GROUP_TYPE_TAG;
                group.occurrences = [];
                group.displayOrder = _get(_last(groups), 'displayOrder', 0) + 1;
                // optimistically update the redux store with the new group
                dispatch(addGroupRequest(group));
            }

            dispatch(addOccurrenceToGroupRequest(id, group));
            await addOccurrenceToGroup(group, id);
        } catch (err) {
            isNewGroup && dispatch(addGroupError(err, group));
            dispatch(addOccurrenceToGroupError(err, id, group));
            throw err;
        }
    };

export const removeOccurrenceFromTagGroup = (id, group) => async (dispatch) => {
    try {
        dispatch(removeOccurrenceFromGroupRequest(id, group));
        await removeOccurrenceFromGroup(group, id);
    } catch (err) {
        dispatch(removeOccurrenceFromGroupError(err, id, group));
        throw err;
    }
};
