import { delayedValueResolver } from '../../common/utils.js';
import {
    PERMISSION_CREATE,
    PERMISSION_DELETE,
    PERMISSION_UPDATE,
    WORKSPACE_ADD,
    WORKSPACE_TEMPLATE_ACCESS_REMOVED,
    WORKSPACE_CRM_ACCESS_REMOVED,
} from '~common/action.types';
import {
    PERMISSION_TYPE_MEETING,
    PERMISSION_TYPE_OCCURRENCE,
    PERMISSION_TYPE_WORKSPACE,
    PERMISSION_STATUS_LEFT,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_ACCEPTED,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING,
    PERMISSION_LEVEL_NONE,
    MODAL_TYPE_LEAVE_CANCEL,
    MODAL_TYPE_OK_NO_ACTION,
    PERMISSION_TYPE_TEMPLATE_LIBRARY,
} from '~common/constants';
import { getOccurrenceAccessLevelNonMemoized } from './permission.selectors.js';
import {
    getAccessLevelByPermission,
    getMeetingAccessLevel,
    getWorkspaceAccessLevel,
    isAcceptedPermission,
    isRequestedWorkspacePermission,
    isRequestRejectedWorkspacePermission,
} from './permission.helpers.js';
import { alertError, alertSuccess } from '~modules/alert/alert.actions';
import {
    preloadMeetingData,
    ensureUsers,
} from '~modules/preload/preload.actions';
import {
    onOccurrencesDelete,
    loadOccurrence,
    loadOccurrencePermissions,
} from '~modules/occurrence/occurrence.actions';
import {
    loadMeetingPermissions,
    onMeetingDelete,
    loadMeeting,
} from '~modules/meeting/meeting.actions';
import { clearMyHomepageIfContainsId } from '~modules/pageView/pageView.actions';
import {
    getNonModifiedOccurrencesFromStoreByMeeting,
    getOccurrenceFromStoreById,
} from '~modules/occurrence/occurrence.selectors';
import { getWorkspaceGroupByMeetingId } from '~modules/group/group.selectors';
import {
    leaveMeeting,
    acceptPermission as apiAccept,
    rejectPermission as apiReject,
    resendPermission as apiResend,
} from './permission.api.js';
import { getGroup } from '~modules/group/group.api';
import { getMemberWorkspacePermission } from '~modules/workspace/workspace.api';
import {
    getMeetingSelector,
    getMeetingFromStoreById,
} from '~modules/meeting/meeting.selectors';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import { isDetailPage, replaceTo } from '~modules/navigation';
import {
    getPageState,
    getPageStateForMeeting,
} from '~modules/pageView/pageView.selectors';
import { pauseUpdates, resumeUpdates } from '~common/store';
import {
    getWorkspaceIdSelector,
    getUserIdSelector,
} from '~common/selector.helpers';
import { loadWorkspaceTemplateAndItems } from '~modules/template/template.actions';
import {
    loadWorkspacePermissions,
    workspaceAccessRemoved,
} from '~modules/workspace/workspace.actions';
import { loadCRMLinks } from '~modules/remoteCRM/remoteCRM.actions';

export function openLeaveModal(
    meeting,
    content,
    canLeave,
    pathToNavigateOnSuccess
) {
    return (dispatch) => {
        const promise = async () => {
            pauseUpdates();

            const locationBeforeLeaving = location;
            try {
                // optimistically redirect the user
                pathToNavigateOnSuccess && replaceTo(pathToNavigateOnSuccess);
                const permissions = await leaveMeeting(meeting.id);

                // Permission updates will be received and processed via websockets.
                // However in case there is an issue with websockets, process the permission updates directly.
                for (const permission of permissions) {
                    dispatch(onPermissionUpdate(permission));
                }

                dispatch(
                    alertSuccess({
                        id: `openLeaveModal-${meeting.id}`,
                        title: `You sucessfully left the “${meeting.title}” series`,
                    })
                );
            } catch (error) {
                replaceTo(locationBeforeLeaving);
                dispatch(
                    alertError({
                        id: `openLeaveModal-${meeting.id}`,
                        title: `There was an issue leaving the “${meeting.title}” series`,
                        error,
                    })
                );
            } finally {
                resumeUpdates();
            }
        };

        const modalType = canLeave
            ? MODAL_TYPE_LEAVE_CANCEL
            : MODAL_TYPE_OK_NO_ACTION;

        dispatch(
            openConfirm({
                header: 'Leave series',
                content,
                promise,
                modalType,
            })
        );
    };
}

export function onPermissionCreateSuccess(permission) {
    return {
        type: PERMISSION_CREATE,
        permission,
    };
}

export function onPermissionDeleteSuccess(permission) {
    return {
        type: PERMISSION_DELETE,
        permission,
    };
}

export function onPermissionUpdateSuccess(permission) {
    return {
        type: PERMISSION_UPDATE,
        permission,
    };
}

export function permissionSanitizeOnCreate(permission, createMeta) {
    return async (dispatch, getState) => {
        const state = getState();

        switch (permission.targetType) {
            case PERMISSION_TYPE_MEETING: {
                if (!createMeta?.forceReload) {
                    // if this permission create is because you just created a meeting, don't load anything
                    // GOTCHA don't pass the state in here, as then it will use the "old state" each time the resolver runs
                    const existing = await delayedValueResolver(
                        () => getMeetingFromStoreById(permission.target),
                        { maxTimeout: 1000, intervalTimeout: 100 }
                    ).catch(() => {
                        // noop, item still not found in the store
                    });
                    if (existing) return;
                }

                const meeting = await dispatch(loadMeeting(permission.target));
                await dispatch(ensureUsers(meeting));
                await dispatch(preloadMeetingData(meeting));
                break;
            }
            case PERMISSION_TYPE_OCCURRENCE: {
                if (!createMeta?.forceReload) {
                    // if this permission create is because you just created an occurrence, don't load anything
                    // GOTCHA don't pass the state in here, as then it will use the "old state" each time the resolver runs
                    const existing = await delayedValueResolver(
                        () => getOccurrenceFromStoreById(permission.target),
                        { maxTimeout: 1000, intervalTimeout: 100 }
                    ).catch(() => {
                        // noop, item still not found in the store
                    });
                    if (existing) return;
                }

                const occurrence = await dispatch(
                    loadOccurrence(permission.target)
                );
                let meeting = getMeetingSelector(state, occurrence);

                if (!meeting.id) {
                    meeting = await dispatch(loadMeeting(occurrence.meeting));
                    await dispatch(ensureUsers(meeting));
                }

                await dispatch(preloadMeetingData(meeting));
                break;
            }

            case PERMISSION_TYPE_WORKSPACE: {
                const { canUpdate } = getAccessLevelByPermission(permission);
                if (canUpdate && permission.createdBy !== state.user.id) {
                    dispatch(loadWorkspaceTemplateAndItems(permission.target));
                }
                const workspace = await getGroup(permission.target);
                canUpdate &&
                    dispatch(loadWorkspacePermissions(permission.target));
                dispatch({
                    type: WORKSPACE_ADD,
                    workspace,
                });
                canUpdate && dispatch(loadCRMLinks(permission.target));
                break;
            }

            case PERMISSION_TYPE_TEMPLATE_LIBRARY: {
                const { canView } = getAccessLevelByPermission(permission);
                if (canView) {
                    dispatch(loadWorkspaceTemplateAndItems(permission.target));
                }
                const workspace = await getGroup(permission.target);
                dispatch({
                    type: WORKSPACE_ADD,
                    workspace,
                });
            }
        }
    };
}

const permissionSanitizeOnDelete =
    (permission, { isUpdateEvent } = {}) =>
    (dispatch, getState) => {
        const state = getState();
        const { user } = state;

        switch (permission.targetType) {
            case PERMISSION_TYPE_MEETING: {
                if (!permission.scope) {
                    const occurrences =
                        getNonModifiedOccurrencesFromStoreByMeeting(
                            state,
                            permission.target
                        );

                    dispatch(onOccurrencesDelete(occurrences, isUpdateEvent));
                }

                const { canView } = getMeetingAccessLevel(
                    state,
                    permission.target,
                    user
                );

                if (canView) {
                    return;
                }

                const meeting = getMeetingSelector(state, {
                    meeting: permission.target,
                });

                meeting.id &&
                    dispatch(onMeetingDelete(meeting, { isUpdateEvent }));

                break;
            }
            case PERMISSION_TYPE_OCCURRENCE: {
                const occurrence = getOccurrenceFromStoreById(
                    state,
                    permission.target
                );

                if (!occurrence) return;

                // if they are viewing the occurrence that their permission is deleted for,
                // but the occurrence has useMeetingAttendees: true, and they have meeting access...
                // don't delete the occurrence
                const { nonScopedView: canView } = getMeetingAccessLevel(
                    state,
                    occurrence.meeting,
                    user
                );

                !(occurrence.useMeetingAttendees && canView) &&
                    dispatch(onOccurrencesDelete([occurrence], isUpdateEvent));
                break;
            }
            case PERMISSION_TYPE_WORKSPACE: {
                dispatch(workspaceAccessRemoved(permission.target));
                dispatch(clearMyHomepageIfContainsId(permission.target));
            }
        }
    };

export function permissionSanitizeOnUpdate(permission, prevState) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    return async (dispatch, getState) => {
        const state = getState();
        const { user } = state;

        let prevCanView,
            prevNonScopedView,
            canView,
            nonScopedView,
            prevCanUpdate,
            canUpdate;

        switch (permission.targetType) {
            case PERMISSION_TYPE_MEETING: {
                ({ canView: prevCanView, nonScopedView: prevNonScopedView } =
                    getMeetingAccessLevel(prevState, permission.target, user));

                ({ canView, nonScopedView } = getMeetingAccessLevel(
                    state,
                    permission.target,
                    user
                ));

                break;
            }

            case PERMISSION_TYPE_OCCURRENCE: {
                ({ canView: prevCanView } = getOccurrenceAccessLevelNonMemoized(
                    prevState,
                    {
                        occurrence: permission.target,
                        bypassOccurrenceExistenceCheck: true,
                    }
                ));

                ({ canView } = getOccurrenceAccessLevelNonMemoized(state, {
                    occurrence: permission.target,
                    bypassOccurrenceExistenceCheck: true,
                }));

                break;
            }

            case PERMISSION_TYPE_WORKSPACE: {
                ({ canView: prevCanView, canUpdate: prevCanUpdate } =
                    getWorkspaceAccessLevel(
                        prevState.permission,
                        permission.target,
                        user
                    ));

                ({ canView, canUpdate } = getWorkspaceAccessLevel(
                    state.permission,
                    permission.target,
                    user
                ));

                // users who are a Member of the workspace can use the templates in that workspace
                if (!prevCanUpdate && canUpdate) {
                    dispatch(loadWorkspaceTemplateAndItems(permission.target));
                    dispatch(loadCRMLinks(permission.target));
                }
                if (prevCanUpdate && !canUpdate) {
                    dispatch(
                        workspaceAccessRemoved(
                            permission.target,
                            WORKSPACE_TEMPLATE_ACCESS_REMOVED
                        )
                    );
                    dispatch(
                        workspaceAccessRemoved(
                            permission.target,
                            WORKSPACE_CRM_ACCESS_REMOVED
                        )
                    );
                }
                if (isRequestRejectedWorkspacePermission(permission)) {
                    dispatch(workspaceAccessRemoved(permission.target));
                }

                break;
            }
        }

        if (
            (!prevCanView && canView) ||
            (!prevNonScopedView && nonScopedView)
        ) {
            dispatch(
                permissionSanitizeOnCreate(permission, { forceReload: true })
            );
        } else if (prevCanView && !canView) {
            dispatch(
                permissionSanitizeOnDelete(permission, { isUpdateEvent: true })
            );
        }
    };
}

export function onPermissionCreate(permission, createMeta) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    return async (dispatch, getState) => {
        const state = getState();

        if (permission.user !== state.user.id) {
            dispatch(updatePermissionsInPageState(permission));
            return;
        }

        dispatch(onPermissionCreateSuccess(permission));
        (isAcceptedPermission(permission) ||
            isRequestedWorkspacePermission(permission)) &&
            !permission.grants.includes(PERMISSION_LEVEL_NONE) &&
            dispatch(permissionSanitizeOnCreate(permission, createMeta));
    };
}

/**
 * When a permission update is received, if the user is on an open meeting/occurrence,
 * and the permission being updated is for the meeting/occurrence being viewed,
 * update the permissions stored in the pageState.
 * If it's for a different meeting/occurrence, that permission will get reloaded when user re-opens that page.
 * Updates can be received for a different user if this user is Admin for a meeting.
 * @param {Object} permission permission object
 */
export function onPermissionUpdate(permission) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    return async (dispatch, getState) => {
        const state = getState();

        if (permission.user !== state.user.id) {
            dispatch(updatePermissionsInPageState(permission));
            return;
        }

        ![
            PERMISSION_STATUS_LEFT,
            PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_ACCEPTED,
            PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING,
        ].includes(permission.status) &&
            dispatch(updatePermissionsInPageState(permission));
        dispatch(onPermissionUpdateSuccess(permission));
        dispatch(permissionSanitizeOnUpdate(permission, state));
    };
}

export function onPermissionDelete(permission) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    return async (dispatch, getState) => {
        const state = getState();

        if (permission.user !== state.user.id) {
            dispatch(updatePermissionsInPageState(permission));
            return;
        }

        dispatch(onPermissionDeleteSuccess(permission));
        dispatch(permissionSanitizeOnDelete(permission));
    };
}

function updatePermissionsInPageState(permission) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    return async (dispatch, getState) => {
        const loggedInUser = getState().user;

        // if it's your permission being updated, and it gives you none access or not "accepted",
        // don't reload permissions, as you don't have access to load the permissions.
        if (
            !permission?.targetType ||
            (permission.user === loggedInUser.id &&
                (permission.grants.includes(PERMISSION_LEVEL_NONE) ||
                    !isAcceptedPermission(permission)))
        ) {
            return;
        }
        const state = getState();
        const pageState = getPageState(state, permission.target);
        if (
            !pageState ||
            !(
                pageState.occurrence === permission.target ||
                pageState.meeting === permission.target
            )
        )
            return;

        if (pageState.occurrence) {
            const occurrence = getOccurrenceFromStoreById(state, pageState.id);
            if (!occurrence) {
                return;
            }
            dispatch(loadOccurrencePermissions(occurrence));
            dispatch(loadMeetingPermissions({ id: occurrence.meeting }));
        } else {
            dispatch(loadMeetingPermissions({ id: pageState.id }));
        }
    };
}

export const rejectPermission = (id) => apiReject(id);

export const acceptPermission = (id) => apiAccept(id);

export const resendPermission = (id) => {
    const detailPage = isDetailPage(location.pathname);
    const options = {
        activeOccurrenceId: detailPage?.id,
    };
    return apiResend(id, options);
};

export const getUserMeetingPermission =
    (user, meeting) => (dispatch, getState) => {
        const userId = getUserIdSelector(null, { user });

        const meetingPermissions = getPageStateForMeeting(getState(), {
            meeting,
        })?.permissions;

        if (!userId || !meetingPermissions) return;

        return meetingPermissions.find(
            (p) => !p.scope && p?.user?.id === userId
        );
    };

export const getUserWorkspacePermissionByMeeting =
    (user, meeting) => (dispatch) => {
        const workspace = getWorkspaceGroupByMeetingId({ meeting });
        return dispatch(getUserWorkspacePermission(user, workspace));
    };

export const getUserWorkspacePermission =
    (user, workspace) => async (dispatch) => {
        const userId = getUserIdSelector(null, { user });
        const workspaceId = getWorkspaceIdSelector(null, { workspace });

        // no workspace permissions in pageView.dashboardState, go to the api to get the user's workspace access
        try {
            const workspacePermission = await getMemberWorkspacePermission(
                workspaceId,
                userId
            );
            return workspacePermission;
        } catch (error) {
            dispatch(
                alertError({
                    id: `getUserWorkspacePermission-${userId}-${workspaceId}`,
                    title: `Workspace permissions could not be loaded for ${user.displayName}`,
                    error,
                })
            );
        }
    };
