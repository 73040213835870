import React from 'react';

export default ({ iconThick = false, title = 'Filter', ...rest }) => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="m26.632 2h-21.263c-1.712 0-2.6326 2.0109-1.514 3.3068l8.3138 9.6316c0.0552 0.0639 0.0818 0.1469 0.0818 0.2313v12.897c0 1.4768 1.5444 2.4069 2.7942 1.6829l3.75-2.1722c0.5904-0.342 0.9558-0.9853 0.9558-1.6829v-10.724c0-0.0844 0.0266-0.1674 0.0818-0.2313l8.3138-9.6316c1.1186-1.296 0.198-3.3068-1.514-3.3068z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
