import { createStore as reduxCreateStore } from 'redux';
import { RESUME_REDUX_UPDATES } from '~common/action.types';
let store;

export function createStore(...args) {
    store = reduxCreateStore(...args);
    return store;
}

export function getStore() {
    return store;
}

// Paused is an array, so that there can be multiple in-progress calls to pauseUpdates/resumeUpdates.
// This is particularly important where pause/resume is used in the websocket/actions.js file,
// so we've got websocket events coming in while other processing is occurring (e.g. saving something to the database via a modal) that is using pause/resume.
const paused = [];
const isPaused = () => paused.length > 0;

export function canApplyUpdates() {
    return !isPaused();
}

export function pauseUpdates() {
    paused.push(true);
}

export function resumeUpdates() {
    paused.pop();

    // force an update to the store, so it "publishes" a change to all components
    const { dispatch } = store;
    dispatch({ type: RESUME_REDUX_UPDATES });
}

export function resumeUpdatesQuiet() {
    // don't force an update to the store. It means any changes made to the store since pauseUpdates() won't be available
    // to subscribing components until the next change is made to [any part of] the redux store.
    paused.pop();
}
