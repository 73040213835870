import _take from 'lodash/take.js';
import React, { Component } from 'react';
import tw, { styled, css, theme } from 'twin.macro';
import {
    getAvatarImage as getImage,
    getAvatarStatusImage,
} from '~shared/user/avatar.helpers';
import { getUserDisplayName } from '~shared/user/user.helpers';
import Chevron from '~icons/chevron';
import { Tooltip, User } from '~modules/common/components';

const UserAvatarWrap = tw.span`flex flex-nowrap lg:flex-wrap content-start`;

const AvatarItem = styled.div`
    & + & {
        ${tw`ml-1`}
    }
`;

const AdminIndicator = styled.div(({ size }) => [
    tw`text-xs absolute bottom-[-1px] right-[-1px] mt-1`,
    size === 'smaller' && tw`bottom-[-2px] right-[-2px]`,
]);

const dimensions = {
    xxlarge: 132,
    xlarge: 100,
    large: 56,
    medium: 32,
    small: 28,
    smaller: 24,
    xsmall: 20,
    xxsmall: 18,
};

const avatarImageDimensionsMap = {
    ...Object.keys(dimensions).map((dimension) => ({
        [dimension]: dimension,
    })),
    smaller: 'small',
    xxsmall: 'xsmall',
};

const StatusIcon = styled.span`
    ${tw`text-white bg-white border border-white rounded-full absolute z-10 p-0`};
    top: -5px;
    right: -5px;
    box-sizing: content-box;

    ${({ status }) =>
        status === 'approved'
            ? tw`text-success-icon`
            : status === 'declined'
            ? tw`text-icon-dark`
            : tw`text-icon-disabled`}
`;

export function StatusIconWeb({ status }) {
    if (!status) {
        return null;
    }

    return (
        <StatusIcon
            status={status || ''}
            dangerouslySetInnerHTML={{
                __html: getAvatarStatusImage(status),
            }}
        />
    );
}

export function UserAvatar({
    className,
    user,
    title,
    onClick,
    status,
    hasHover,
    hasWhiteOutline,
    hasBorder,
    size = 'medium',
    isDim,
    hasAdminIndicator,
    enableTooltip,
    hasFullUserDetailTooltip,
    position,
    offset,
}) {
    if (!user) {
        return null;
    }

    const name = title ?? getUserDisplayName(user);
    const src = getImage({
        size: avatarImageDimensionsMap[size],
        user,
        isDim,
    });
    const hasUploadedImage = Boolean(user.avatar?.path);

    const AvatarItemContainer = styled.span`
        ${({ hasHover }) =>
            hasHover &&
            css`
                ${tw`relative`}
                &:before,
                &:after {
                    content: '';
                    z-index: -1;
                    ${tw`absolute opacity-0 block top-0 left-0 right-0 bottom-0 rounded-full`}
                }
                &:before {
                    transform: scale(1.2);
                    ${tw`bg-icon-dark`}
                }
                &:after {
                    transform: scale(1.1);
                    ${tw`bg-white`}
                }
                &:hover,
                &:focus {
                    &:before,
                    &:after {
                        ${tw`opacity-100`}
                    }
                }
            `}
        ${({ hasWhiteOutline }) =>
            hasWhiteOutline &&
            css`
                box-shadow: 0 0 0 1px white; /* Avoids uneven border at larger sizes */
                &:hover,
                &:focus {
                    box-shadow: 0 0 0 1px ${theme`colors.primary.highlight`};
                    ${tw`bg-primary-highlight`}
                }
            `}
        ${({ hasBorder }) => hasBorder && tw`border border-white`}
        cursor: ${onClick ? 'pointer' : 'inherit'};
        height: ${dimensions[size]}px;
        width: ${dimensions[size]}px;
        transform: translateZ(0px);
        box-sizing: content-box;
        ${tw`flex content-center items-stretch flex-col bg-white relative rounded-full
            outline-none pointer-events-auto flex-shrink-0`};

        .avatar-item-container > span[role='img'] {
            ${tw`bg-no-repeat rounded-full bg-contain`};
        }
    `;

    const AvatarImage = styled.span`
        background-image: url('${src}');
        height: ${dimensions[size]}px;
        width: ${dimensions[size]}px;
        border-radius: 50%;
        background-position: center;
        background-size: contain;
        ${isDim && hasUploadedImage && `filter: grayscale(100%); opacity: .65;`}
    `;

    return (
        <Tooltip
            content={hasFullUserDetailTooltip ? <User {...{ user }} /> : name}
            wide
            {...{ enableTooltip, position, offset }}
        >
            <AvatarItemContainer
                className={`avatar-item-container avatar-${size}${
                    onClick ? ' clickable' : ''
                } ${className || ''}`}
                onClick={onClick}
                {...{ hasWhiteOutline, hasBorder, hasHover }}
            >
                <AvatarImage role="img" aria-label={name} />
                <StatusIconWeb status={status} />
                {hasAdminIndicator && (
                    <AdminIndicator {...{ size }}>
                        <Chevron iconThick />
                    </AdminIndicator>
                )}
            </AvatarItemContainer>
        </Tooltip>
    );
}

export class AvatarGroup extends Component {
    render() {
        const {
            maxCount,
            data = [],
            size,
            appearance = 'grid',
            avatar = UserAvatar,
        } = this.props;

        const Comp = avatar;

        const list = _take(data, maxCount || data.length);

        return (
            <UserAvatarWrap className={`avatar-group ${appearance}`}>
                {list.map(({ ...avatarProps }, idx) => (
                    <AvatarItem
                        className={`avatar-group-item ${appearance}`}
                        key={idx}
                        style={{
                            zIndex: list.length - idx,
                        }}
                    >
                        <Comp size={size} hasHover {...avatarProps} />
                    </AvatarItem>
                ))}
            </UserAvatarWrap>
        );
    }
}
