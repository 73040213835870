export default () => (
    <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)" fill="#F06A6A">
            <circle cx="16.552" cy="8.2414" r="7.2414" />
            <circle cx="7.2414" cy="23.759" r="7.2414" />
            <circle cx="24.828" cy="23.759" r="7.2414" />
        </g>
        <defs>
            <clipPath id="a">
                <rect width="32" height="32" fill="#fff" />
            </clipPath>
        </defs>
    </svg>
);
