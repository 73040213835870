import React from 'react';
import tw, { css } from 'twin.macro';
import { zIndex } from '~common/style.helpers';
import { GeneralError } from '~client/components/pure/ErrorPages';

const cssPreloaderMessage = css`
    margin: 45vh 20px 0 20px;
    border: none;
`;

const cssPreloaderWrapper = css`
    position: fixed;
    z-index: ${zIndex('PRELOADER')};
    width: 100vw;
    height: 100vh;
    padding: 2rem;
    text-align: center;
    ${tw`grid justify-center items-center`}
`;

// SPINNER SQUARES START
// via https://codepen.io/nikhil8krishnan/pen/dMEzGx/
const cssCube = css`
    .cube-folding {
        margin-bottom: 1.75rem;
        width: 50px;
        height: 50px;
        display: inline-block;
        transform: rotate(45deg);
        font-size: 0;
        line-height: 0;

        span {
            width: calc(50px / 2);
            height: calc(50px / 2);
            transform: scale(1.1);
            display: inline-block;

            &::before {
                content: '';
                background-color: white;
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: calc(50px / 2);
                height: calc(50px / 2);
                transform-origin: 100% 100%;
                animation: folding 2.5s infinite linear both;
            }
        }

        .leaf2 {
            transform: rotateZ(90deg) scale(1.1);

            &::before {
                animation-delay: 0.3s;
                background-color: #f2f2f2;
            }
        }

        .leaf3 {
            transform: rotateZ(270deg) scale(1.1);

            &::before {
                animation-delay: 0.9s;
                background-color: #f2f2f2;
            }
        }

        .leaf4 {
            transform: rotateZ(180deg) scale(1.1);

            &::before {
                animation-delay: 0.6s;
                background-color: #e6e6e6;
            }
        }
    }
    @keyframes folding {
        0%,
        10% {
            transform: perspective(140px) rotateX(-180deg);
            opacity: 0;
        }

        25%,
        75% {
            transform: perspective(140px) rotateX(0deg);
            opacity: 1;
        }

        90%,
        100% {
            transform: perspective(140px) rotateY(180deg);
            opacity: 0;
        }
    }
`;

const Text = tw.div`text-white`;

const Cube = () => (
    <div css={cssCube}>
        <div className="cube-folding">
            <span className="leaf1" />
            <span className="leaf2" />
            <span className="leaf3" />
            <span className="leaf4" />
        </div>
    </div>
);

export default ({ percent, error, retry, timedOut, pastDelay }) => {
    if (typeof percent !== 'undefined') {
        return (
            <div css={cssPreloaderWrapper}>
                <div tw="relative">
                    <Cube />
                    <Text>Loading your meetings&hellip;</Text>
                </div>
            </div>
        );
    } else {
        if (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            return (
                <GeneralError>
                    <button onClick={retry}>Try again</button>
                </GeneralError>
            );
        } else if (timedOut) {
            return (
                <div css={cssPreloaderMessage}>
                    Taking a long time&hellip;{' '}
                    <button onClick={retry}>Retry</button>
                </div>
            );
        } else if (pastDelay) {
            return (
                <div css={cssPreloaderWrapper}>
                    <div tw="relative">
                        <Cube />
                        <Text>Loading your meetings&hellip;</Text>
                    </div>
                </div>
            );
        }
        return null;
    }
};
