import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M4.8 28H4.6087C3.16795 28 2 26.832 2 25.3913V5.6087C2 4.16795 3.16795 3 4.6087 3H27.3913C28.832 3 30 4.16795 30 5.6087V25.3913C30 26.832 28.832 28 27.3913 28H27.2"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m1 10.375h30"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m7 15.671c0-0.3362 0.27252-0.6087 0.6087-0.6087h4.8696c0.3361 0 0.6087 0.2725 0.6087 0.6087v4.4891c0 0.3362-0.2726 0.6087-0.6087 0.6087h-4.8696c-0.33618 0-0.6087-0.2725-0.6087-0.6087v-4.4891z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m19 15.671c0-0.3362 0.2725-0.6087 0.6087-0.6087h4.8696c0.3361 0 0.6087 0.2725 0.6087 0.6087v4.4891c0 0.3362-0.2726 0.6087-0.6087 0.6087h-4.8696c-0.3362 0-0.6087-0.2725-0.6087-0.6087v-4.4891z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m7 25.046c0-0.3362 0.27252-0.6087 0.6087-0.6087h4.8696c0.3361 0 0.6087 0.2725 0.6087 0.6087v4.4891c0 0.3362-0.2726 0.6087-0.6087 0.6087h-4.8696c-0.33618 0-0.6087-0.2725-0.6087-0.6087v-4.4891z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <rect
            x="19"
            y="24.438"
            width="6.087"
            height="5.7065"
            rx=".6087"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m10 1.9375v4.5652"
            strokeLinecap="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m22 1.9375v4.5652"
            strokeLinecap="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
