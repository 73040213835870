import React from 'react';

export default ({ title = 'Google Meet' }) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        {title && <title>{title}</title>}
        <g clipPath="url(#clip0)">
            <path
                d="M17.9702 15.4298L20.895 18.7953L24.8281 21.3251L25.5142 15.4519L24.8281 9.70654L20.8193 11.929L17.9702 15.4298Z"
                fill="#188038"
            />
            <path
                d="M1 20.7794V25.7847C1 26.9304 1.92041 27.8572 3.05811 27.8572H8.02881L9.05664 24.0737L8.02881 20.7794L4.61816 19.7444L1 20.7794H1Z"
                fill="#1967D2"
            />
            <path
                d="M8.02881 3L1 10.0778L4.61816 11.1128L8.02881 10.0778L9.03955 6.82777L8.02881 3Z"
                fill="#EA4335"
            />
            <path d="M1 20.7793H8.02881V10.0778H1V20.7793Z" fill="#4285F4" />
            <path
                d="M29.3179 5.99681L24.8281 9.70657V21.3251L29.3374 25.0496C30.0137 25.5806 31 25.0963 31 24.2334V6.79826C31 5.92306 29.9893 5.44366 29.3179 5.99681V5.99681ZM17.9702 15.4298V20.7818H8.02881V27.8596H22.7725C23.9102 27.8596 24.8306 26.9328 24.8306 25.7871V21.3251L17.9702 15.4298V15.4298Z"
                fill="#34A853"
            />
            <path
                d="M22.7725 3H8.02881V10.0778H17.9727V15.4298L24.8281 9.70659V5.07245C24.8281 3.92683 23.9077 3 22.7725 3Z"
                fill="#FBBC04"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="30"
                    height="24.8571"
                    fill="white"
                    transform="translate(1 3)"
                />
            </clipPath>
        </defs>
    </svg>
);
