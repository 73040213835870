import _omit from 'lodash/omit';
import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {..._omit(rest, 'isSymbolRef')}
    >
        <g clipPath="url(#a)" stroke="currentColor" strokeWidth="2.5">
            <circle cx="16.552" cy="8.2414" r="5.9914" />
            <circle cx="7.2414" cy="23.759" r="5.9914" />
            <circle cx="24.828" cy="23.759" r="5.9914" />
        </g>
        <defs>
            <clipPath id="a">
                <rect width="32" height="32" fill="none" />
            </clipPath>
        </defs>
    </svg>
);
