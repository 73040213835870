import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <circle
            cx="16"
            cy="16"
            r="6"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m10.687 26.763c0.7829 0.3872 1.614 0.6915 2.482 0.9014l0.831 3.3359h5l0.9151-3.6532c0.633-0.2183 1.2419-0.4882 1.8219-0.8044l3.1833 1.9134 3.5355-3.5355-1.9097-3.1901c0.315-0.5786 0.5839-1.186 0.8015-1.8173l3.6524-0.9129v-5l-3.3369-0.8353c-0.2267-0.9359-0.5632-1.8289-0.9949-2.6647l1.7062-2.8388-3.5356-3.5355-2.646 1.5937c-0.7122-0.42997-1.4728-0.78802-2.2716-1.0641l-0.9212-3.6553h-5l-0.8357 3.337c-1.042 0.25245-2.0308 0.64091-2.9467 1.1455l-2.975-1.7754-3.5355 3.5355 1.7796 2.9675c-0.50862 0.9215-0.89959 1.9171-1.1526 2.9665l-3.334 0.8234v5l3.6536 0.9162c0.2546 0.7378 0.57913 1.443 0.96567 2.1077l-1.9936 3.3149 3.5355 3.5356 3.5355-2.1214"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
