import _reject from 'lodash/reject.js';
import _uniqBy from 'lodash/uniqBy.js';
import {
    DELETE_MEETING_SUCCESS,
    FETCH_ACTION_ITEMS_SUCCESS,
    FETCH_LINKED_ACTION_ITEMS_SUCCESS,
    UPDATE_ACTION_ITEM_SUCCESS,
    ADD_ACTION_ITEM_SUCCESS,
    DELETE_ACTION_ITEM_REQUEST,
    DELETE_ACTION_ITEM_SUCCESS,
    DELETE_ACTION_ITEM_ERROR,
    ADD_ACTION_ITEM_REQUEST,
    UPDATE_ACTION_ITEM_REQUEST,
    UPDATE_ACTION_ITEM_ERROR,
    ADD_ACTION_ITEM_ERROR,
    MODAL_LINKACTIONITEM_OPEN,
    MODAL_LINKACTIONITEM_CLOSE,
    LINK_ACTION_ITEM_SUCCESS,
    UNLINK_ACTION_ITEM_SUCCESS,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
    LOGOUT_SUCCESS,
} from '~common/action.types';
import { updateMeetingMeta } from '~client/reducers/helpers';

const initialState = {
    actionItems: [],
    linkActionItemModal: {
        isOpen: false,
    },
};

const actionItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_MEETING_SUCCESS: {
            return {
                ...state,
                actionItems: state.actionItems.filter(
                    (o) => o.meeting !== action.meeting.id
                ),
            };
        }

        case FETCH_LINKED_ACTION_ITEMS_SUCCESS: {
            return {
                ...state,
                actionItems: _uniqBy(
                    [...state.actionItems, ...action.actionItems],
                    'id'
                ),
            };
        }

        case FETCH_ACTION_ITEMS_SUCCESS: {
            return {
                ...state,
                actionItems: _uniqBy(
                    [...state.actionItems, ...action.page.docs],
                    'id'
                ),
            };
        }

        case UPDATE_ACTION_ITEM_REQUEST: {
            return {
                ...state,
                actionItems: state.actionItems.map((a) =>
                    a.id === action.actionItem.id
                        ? {
                              ...a,
                              isLastUpdatedByOtherUser: false,
                              ...action.actionItem,
                          }
                        : a
                ),
            };
        }
        case ADD_ACTION_ITEM_REQUEST:
        case ADD_ACTION_ITEM_SUCCESS: {
            //GOTCHA: when add an action item, there might be a subsequent update in Client
            //        before server returns the add event via websocket.
            //        In this case, want to keep what Client already has and supplement new
            //        values from the websocket (such as occurrenceMeta).
            const existingItem = state.actionItems.findIndex(
                (actionItem) => actionItem.id === action.actionItem.id
            );

            const actionItems = [...state.actionItems];
            if (existingItem === -1) {
                actionItems.push({
                    isLastUpdatedByOtherUser: false,
                    ...action.actionItem,
                });
            } else {
                actionItems[existingItem] = {
                    isLastUpdatedByOtherUser: false,
                    ...action.actionItem,
                    ...actionItems[existingItem],
                    occurrenceMeta: {
                        ...action.actionItem?.occurrenceMeta,
                        ...actionItems[existingItem]?.occurrenceMeta,
                    },
                };
            }

            return {
                ...state,
                actionItems,
            };
        }
        case DELETE_ACTION_ITEM_ERROR:
        case UPDATE_ACTION_ITEM_SUCCESS:
        case UPDATE_ACTION_ITEM_ERROR: {
            return {
                ...state,
                actionItems: _uniqBy(
                    [action.actionItem, ...state.actionItems],
                    'id'
                ),
            };
        }

        case ADD_ACTION_ITEM_ERROR:
        case DELETE_ACTION_ITEM_REQUEST:
        case DELETE_ACTION_ITEM_SUCCESS: {
            return {
                ...state,
                actionItems: _reject(state.actionItems, {
                    id: action.actionItem.id,
                }),
            };
        }

        case MODAL_LINKACTIONITEM_OPEN: {
            return {
                ...state,
                linkActionItemModal: {
                    isOpen: true,
                    actionItem: action.actionItem,
                },
            };
        }

        case UNLINK_ACTION_ITEM_SUCCESS:
        case LINK_ACTION_ITEM_SUCCESS:
        case MODAL_LINKACTIONITEM_CLOSE: {
            return {
                ...state,
                linkActionItemModal: {
                    isOpen: false,
                },
            };
        }

        case MOVE_OCCURRENCE_DELETE: {
            if ((action.actionItemIds?.length || 0) === 0) return state;
            return {
                ...state,
                actionItems: state.actionItems.filter(
                    (actionItem) =>
                        !action.actionItemIds.includes(actionItem.id)
                ),
            };
        }

        case MOVE_OCCURRENCE_UPDATE: {
            if ((action.actionItemIds?.length || 0) === 0) return state;
            const actionItems = state.actionItems.map((actionItem) => {
                if (!action.actionItemIds.includes(actionItem.id))
                    return actionItem;

                return updateMeetingMeta(actionItem, action.meeting);
            });

            return {
                ...state,
                actionItems,
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default actionItemReducer;
