import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <rect
            x="5"
            y="12.889"
            width="22"
            height="17.111"
            rx="4"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m8.6668 12.889v-6c0-2.7614 2.2386-5 5-5h4.6667c2.7614 0 5 2.2386 5 5v6"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m16 21.444v3.6666"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <circle
            cx="16"
            cy="20.222"
            r="1.9444"
            fill="currentColor"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
