import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { UploadField } from '@navjobs/upload';
import { useDispatch } from 'react-redux';
import { openImageUploadModal } from '~modules/modals/imageUploadModal.actions';

const UploadWrap = styled.div(({ isAvatar, hasPadding, hasHover }) => [
    tw`w-full h-full flex items-center justify-center`,
    /* Ensure entire label is clickable to fire file picker*/

    hasPadding &&
        css`
            & div {
                ${tw`p-2 rounded w-full h-full`}
                ${isAvatar && tw`w-full h-full`}
            }
        `,
    hasHover &&
        css`
            & div {
                ${tw`text-body-dark hocus:(text-primary)`}
            }
        `,
    /* Ensure cursor-pointer covers entire upload field */
    css`
        input[type='file' i]::-webkit-file-upload-button {
            ${tw`cursor-pointer`}
        }
    `,
]);

const ImageUpload = ({ label, args }) => {
    const dispatch = useDispatch();
    const { isAvatar, hasPadding = true, hasHover = false } = args;
    return (
        <UploadWrap {...{ isAvatar, hasPadding, hasHover }}>
            <UploadField
                onFiles={([image]) =>
                    dispatch(
                        openImageUploadModal({
                            ...args,
                            image,
                        })
                    )
                }
                uploadProps={{
                    accept: '.png,.gif,.bpm,.jpg,.jpeg,.svg',
                }}
            >
                {label}
            </UploadField>
        </UploadWrap>
    );
};

export default ImageUpload;
