import { getStore } from '~common/store';
import { alertError, alertSuccess } from './alert.actions.js';

export const displayError = (...props) => {
    const store = getStore();
    store.dispatch(alertError(...props));
};

export const displaySuccess = (...props) => {
    const store = getStore();
    store.dispatch(alertSuccess(...props));
};
