import { Button } from '~modules/common/components';

const ButtonExternal = ({ href, children, ...rest }) => (
    <Button
        as="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="button-external"
        {...rest}
    >
        {children}
    </Button>
);

export default ButtonExternal;
