import React from 'react';
import tw, { styled } from 'twin.macro';
import { UserAvatar } from '~modules/user/UserAvatar';
import { CheckRadioBase } from '~modules/common/form-elements';
import { getUserDisplayName } from '~shared/user/user.helpers';

const AssigneeMenuOption = styled.div(({ hasPadding, isDisabled }) => [
    tw`flex items-center justify-between select-none overflow-hidden`,
    hasPadding && !isDisabled && tw`px-4 py-3`,
    hasPadding && isDisabled && tw`px-4 py-2`,
    !isDisabled && tw`cursor-pointer hocus:(bg-solid text-body-dark)`,
    isDisabled && tw`pr-6`,
]);
const Checkbox = tw(CheckRadioBase)`[& + div]:(border-none text-primary)`;

const Username = styled.span`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 0.5rem;
    line-height: normal;
`;

const AvatarNameWrap = styled.div(({ isDisabled, hasMultiSelect }) => [
    tw`flex items-center space-x-1 w-full`,
    !isDisabled && hasMultiSelect && tw`pr-2 max-w-[calc(100% - 20px)]`, // Allow the tick to be seen
]);
const AssigneeDropdownOption = ({ user, isSelected, onClick }) => (
    <AssigneeMenuOption
        label={getUserDisplayName(user)}
        hasPadding
        {...{ onClick }}
    >
        <AvatarNameWrap>
            <UserAvatar user={user} size="small" enableTooltip={false} />
            <Username>{getUserDisplayName(user)}</Username>
        </AvatarNameWrap>

        <Checkbox
            tw="px-2"
            type="checkbox"
            checked={isSelected}
            onChange={() => {}}
        />
    </AssigneeMenuOption>
);

export default AssigneeDropdownOption;
