import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isLockedDueToPastMeetingCheck } from '~modules/occurrence/occurrence.helpers';
import { getPageStateForOccurrence } from '~modules/pageView/pageView.selectors';
import { onUpdateOccurrence } from '~modules/occurrence/occurrence.actions';
import {
    occurrencesSelector,
    getOccurrenceSelector,
    getAttendeesAndPermissionsSelector,
} from '~modules/occurrence/occurrence.selectors';
import {
    getGroupByMeetingId,
    getWorkspaceGroupByMeetingId,
} from '~modules/group/group.selectors';
import { getMeetingSelector } from '~modules/meeting/meeting.selectors';
import { getUserFirstName } from '~shared/user/user.helpers';
import { getMeetingPathParams } from '~modules/navigation';
import { useUserList } from '~hooks';
import { getOccurrenceInfoSelector } from './occurrenceInfo.selectors.js';

/**
 * Check if occurrence edit should be prevented for a meeting in the past,
 * and where the user hasn't told us they'd like to edit it.
 * As a hook, this will automatically trigger component updates if the value of the check changes.
 * @param {Object} occurrence Occurrence object
 */
const useIsLockedDueToPastMeeting = (occurrence, isIntro) => {
    const pageState = useSelector((state) =>
        getPageStateForOccurrence(state, { occurrence })
    );
    if (!occurrence) return false;

    return isLockedDueToPastMeetingCheck(occurrence, { isIntro, pageState });
};

export const useOccurrencePermissionData = (occurrence, meeting) => {
    const attendeesAndPermissions = useSelector((state) =>
        getAttendeesAndPermissionsSelector(state, { occurrence, meeting })
    );

    const result = useMemo(
        () => attendeesAndPermissions.filter(({ isAttendee }) => isAttendee),
        [attendeesAndPermissions]
    );

    return result;
};

const useOccurrences = () => useSelector(occurrencesSelector);

const useOccurrenceById = (id) => {
    const occurrences = useOccurrences();
    const occurrence = occurrences.find((o) => o.id === id);
    return occurrence;
};

export const useOccurrenceDataCurrent = () => {
    const { id } = getMeetingPathParams(location.pathname);

    const occurrence = useSelector((state) =>
        getOccurrenceSelector(state, { occurrence: id })
    );
    const meeting = useSelector((state) =>
        getMeetingSelector(state, occurrence)
    );
    const group = useSelector((state) => getGroupByMeetingId(state, meeting));

    const workspace = getWorkspaceGroupByMeetingId({ meeting });

    const occurrenceData = useMemo(
        () => ({
            occurrence,
            meeting,
            group,
            workspace,
        }),
        [occurrence, meeting, group, workspace]
    );

    return occurrenceData;
};

export const useSetOccurrenceToUseAgendaTemplate = () => {
    const dispatch = useDispatch();
    return (occurrence, useMeetingAgenda) =>
        dispatch(
            onUpdateOccurrence({
                id: occurrence.id,
                meeting: occurrence.meeting,
                useMeetingAgenda,
            })
        );
};

const useOccurrenceCalendarLinks = (id) => {
    const occurrence = useOccurrenceById(id);
    const userList = useUserList();

    return () => {
        const eventCalendarLinks = occurrence?.calendarLinks
            ?.map((l) => {
                const user = userList.find((u) => u.id === l.userId);
                if (!user) return;
                return {
                    ...l,
                    // Supplement data with the users name
                    givenName: getUserFirstName(user),
                };
            })
            .filter(Boolean);
        return eventCalendarLinks || [];
    };
};

const useToggleOccurrenceActionItemReview = () => {
    const dispatch = useDispatch();

    return (occurrence) => {
        dispatch(
            onUpdateOccurrence({
                id: occurrence.id,
                meeting: occurrence.meeting,
                previousActionItemReview: !occurrence.previousActionItemReview,
            })
        );
    };
};

const useHasPreviousStartedOccurrences = (occurrence) => {
    const occurrenceInfo = useSelector((state) =>
        getOccurrenceInfoSelector(state, { occurrence })
    );
    return occurrenceInfo.hasPreviousStartedOccurrences;
};

export {
    useIsLockedDueToPastMeeting,
    useOccurrences,
    useOccurrenceById,
    useOccurrenceCalendarLinks,
    useToggleOccurrenceActionItemReview,
    useHasPreviousStartedOccurrences,
};
