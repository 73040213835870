import React from 'react';
import { NOTIFICATION_CREATE, NOTIFICATION_UPDATE } from '~common/action.types';
import {
    NOTIFICATION_OUTCOME_ACCEPTED,
    NOTIFICATION_STATUS_READ,
    NOTIFICATION_STATUS_UNREAD,
    NOTIFICATION_OUTCOME_REJECTED,
} from '~common/constants';
import {
    acceptPermission,
    rejectPermission,
} from '~modules/permission/permission.actions';
import {
    markAsAccepted,
    markAsRejected,
    markAsRead as apiMarkAsRead,
    markMultipleRead as apiMarkMultipleRead,
} from './notification.api.js';
import {
    alertError,
    alertPersistedInfoWithClose,
    removeAlert,
} from '~modules/alert/alert.actions';
import NotificationToastButton from './NotificationToastButton.js';
import moment from 'moment-timezone';

export const onNotificationCreateSuccess = (notification) => ({
    type: NOTIFICATION_CREATE,
    notification,
});

export const onNotificationUpdateSuccess = (notification) => ({
    type: NOTIFICATION_UPDATE,
    notification,
});

export const removeNewNotificationToast = () =>
    removeAlert({ id: 'you-have-mail' });

export const showNewNotificationToast = () => async (dispatch) => {
    await dispatch(removeNewNotificationToast());
    dispatch(
        alertPersistedInfoWithClose(
            {
                id: 'you-have-mail',
                title: 'You have pending notifications',
                message: <NotificationToastButton />,
            },
            {
                closeOnToastrClick: false,
            }
        )
    );
};

export const markAsRead = (notification) => async (dispatch) => {
    if (!notification) return;

    const {
        meta: { permissionId, rid },
        status,
    } = notification;

    if ((!permissionId && !rid) || status !== NOTIFICATION_STATUS_UNREAD) {
        return;
    }

    try {
        // optimistically update
        dispatch({
            type: NOTIFICATION_UPDATE,
            notification: {
                ...notification,
                status: NOTIFICATION_STATUS_READ,
                updatedAt: moment(),
            },
        });

        await apiMarkAsRead(notification.id);
    } catch (error) {
        dispatch({
            type: NOTIFICATION_UPDATE,
            notification,
        });

        dispatch(
            alertError({
                id: 'markAsRead',
                title: 'Notification could not be marked read',
                error,
            })
        );
    }
};

export const markAllAsRead = (notifications) => async (dispatch) => {
    const notificationsToRead = notifications.filter(
        ({ status, meta: { permissionId, rid } }) =>
            status === NOTIFICATION_STATUS_UNREAD && (permissionId || rid)
    );

    try {
        // optimistically update
        dispatch({
            type: NOTIFICATION_UPDATE,
            notifications: notificationsToRead.map((notification) => ({
                ...notification,
                status: NOTIFICATION_STATUS_READ,
            })),
        });

        await apiMarkMultipleRead(
            notificationsToRead.map((notification) => notification.id)
        );
    } catch (error) {
        dispatch({
            type: NOTIFICATION_UPDATE,
            notifications,
        });

        dispatch(
            alertError({
                id: 'markAllAsRead',
                title: 'Notifications could not be marked read',
                error,
            })
        );
    }
};

export const confirm = (notification) => async (dispatch) => {
    const {
        meta: { permissionId },
        status,
    } = notification;

    if (!permissionId || status !== NOTIFICATION_STATUS_UNREAD) {
        return;
    }

    try {
        // optimistically update
        dispatch({
            type: NOTIFICATION_UPDATE,
            notification: {
                ...notification,
                status: NOTIFICATION_STATUS_READ,
                outcome: NOTIFICATION_OUTCOME_ACCEPTED,
            },
        });

        await acceptPermission(permissionId);
        await markAsAccepted(notification.id);
    } catch (error) {
        dispatch({
            type: NOTIFICATION_UPDATE,
            notification,
        });

        dispatch(
            alertError({
                id: 'confirm',
                title: 'Notification could not be accepted',
                error,
            })
        );
    }
};

export const reject = (notification) => async (dispatch) => {
    const {
        meta: { permissionId },
        status,
    } = notification;

    if (!permissionId || status !== NOTIFICATION_STATUS_UNREAD) {
        return;
    }

    try {
        // optimistically update
        dispatch({
            type: NOTIFICATION_UPDATE,
            notification: {
                ...notification,
                status: NOTIFICATION_STATUS_READ,
                outcome: NOTIFICATION_OUTCOME_REJECTED,
            },
        });

        await rejectPermission(permissionId);
        await markAsRejected(notification.id);
    } catch (error) {
        dispatch({
            type: NOTIFICATION_UPDATE,
            notification,
        });

        dispatch(
            alertError({
                id: 'reject',
                title: 'Notification could not be rejected',
                error,
            })
        );
    }
};
