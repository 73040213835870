import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { NavLink } from 'react-router-dom';
import Icon from '~modules/common/icons';

const List = styled.ul(({ hasNoPadding }) => [
    tw`flex flex-col select-none`,
    !hasNoPadding && tw`px-2 py-2`,
]);

const WrapComponent = styled('div', {
    shouldForwardProp: (prop) => !['hasLightText', 'asLabel'].includes(prop),
})`
    ${tw`px-2 py-2 w-full`}
    ${({ hasLightText }) => hasLightText && tw`text-body-light`}
    ${({ asLabel }) =>
        asLabel &&
        tw`w-full text-left rounded block p-0 text-body cursor-pointer hocus:(bg-solid text-body-dark)`}
`;

const wrapStyles = [
    tw`w-full text-left rounded block text-body pl-2 py-2 cursor-pointer whitespace-nowrap`,
    ({ isHeaderButtonDrop }) =>
        isHeaderButtonDrop ? tw`pr-4 text-sm` : tw`pr-8`,
    ({ isActive }) => isActive && tw`text-primary`,
    ({ hasActiveTick }) =>
        hasActiveTick ? tw`flex justify-between items-center pr-2` : `pr-8`,
    ({ isDisabled }) =>
        isDisabled
            ? tw`text-body-disabled pointer-events-none cursor-not-allowed`
            : css`
                  text-shadow: 0 1px 0 white;
                  ${tw`focus:outline-none hocus:(bg-solid text-body-dark)`}
                  ${tw`active:text-primary`}
              `,
];

const Wrap = styled('a', {
    shouldForwardProp: (prop) =>
        ![
            'isDisabled',
            'isLarge',
            'isShort',
            'isHeaderButtonDrop',
            'isAnchorLink',
            'isPrimaryColor',
            'isActive',
            'hasActiveTick',
            'labelSingular',
            'as',
            'hasNoPadding',
        ].includes(prop),
})(wrapStyles);

const WrapNavLink = styled(NavLink, {
    shouldForwardProp: (prop) =>
        !['isDisabled', 'isActive', 'hasActiveTick'].includes(prop),
})(wrapStyles);

const LabelWrap = styled.span`
    ${tw`flex items-center text-sm whitespace-nowrap`}
    svg {
        ${tw`text-icon text-lg mr-2`}
    }
    ${Wrap}:hover, ${Wrap}:focus {
        svg {
            ${tw`text-icon-dark`}
        }
    }
    ${Wrap}:active {
        svg {
            ${tw`text-primary`}
        }
    }
`;

const IconWrap = styled.div(({ isActive, isDisabled }) => [
    tw`ml-3 text-sm`,
    isDisabled
        ? tw`text-icon-disabled`
        : tw`text-primary group-hocus:text-icon-dark`,
    // Keep the svg bug remain hidden to keep layout and avoid jumpy menu width
    !isActive && tw`invisible`,
]);

const ListItem = styled.li`
    ${({ role }) => role === 'separator' && tw`border-t mx-2 border-line my-2`}
`;

const Menu = ({ options, setIsOpen, isHeaderButtonDrop, hasNoPadding }) => {
    const filteredOptions = options.filter(Boolean);
    if (!filteredOptions.length) return null;

    return (
        <List {...{ hasNoPadding }}>
            {filteredOptions.map((item, i) => {
                const {
                    label,
                    icon,
                    onClick,
                    href,
                    download,
                    longDescription,
                    isComponent,
                    isActive,
                    hasActiveTick,
                    isDisabled,
                    hasLightText,
                    asLabel,
                    ...rest
                } = item;

                if (item === 'divider')
                    return (
                        <ListItem
                            className="group"
                            key={`${label}${i}`}
                            role="separator"
                        />
                    );

                const shouldBeDisabled =
                    (!href && !onClick && !isComponent) || isDisabled;

                const wrapProps = {
                    isDisabled: shouldBeDisabled,
                    onClick: (evt) => {
                        setIsOpen(false);
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                        onClick && onClick(evt, item);
                    },
                    title: longDescription,
                    isActive,
                    hasActiveTick,
                    ...rest,
                };

                const displayLabel =
                    typeof label === 'function' ? label({ setIsOpen }) : label;

                const inner = icon ? (
                    <LabelWrap>
                        <Icon name={icon} />
                        {displayLabel}
                    </LabelWrap>
                ) : (
                    displayLabel
                );

                const tickIcon = hasActiveTick && (
                    <IconWrap {...{ isActive, isDisabled }}>
                        <Icon name="tick" />
                    </IconWrap>
                );

                const innerMenu = href ? (
                    rest.target === '_blank' || download ? (
                        <Wrap href={href} {...wrapProps} className="group">
                            {inner}
                            {tickIcon}
                        </Wrap>
                    ) : (
                        <WrapNavLink to={href} {...wrapProps} className="group">
                            {inner}
                            {tickIcon}
                        </WrapNavLink>
                    )
                ) : isComponent ? (
                    <WrapComponent {...{ hasLightText, asLabel }}>
                        {inner}
                    </WrapComponent>
                ) : (
                    <Wrap
                        className="group"
                        as="button"
                        type="button"
                        {...{ isHeaderButtonDrop }}
                        {...wrapProps}
                    >
                        {inner}
                        {tickIcon}
                    </Wrap>
                );

                return <ListItem key={`${label}${i}`}>{innerMenu}</ListItem>;
            })}
        </List>
    );
};

export default Menu;
