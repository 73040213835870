import React from 'react';
import tw, { styled, css } from 'twin.macro';

const sizes = {
    wrap: {
        small: tw`w-7 h-3.5`,
        large: tw`w-10 h-5`,
    },
    knob: {
        small: tw`w-3.5`,
        large: tw`w-5`,
    },
    slider: {
        small: tw`h-3.5`,
        large: tw`h-5`,
    },
};

const Wrap = styled.div([
    tw`block relative cursor-pointer`,
    ({ isSmall }) => (isSmall ? sizes.wrap.small : sizes.wrap.large),
    ({ isWhite }) => (isWhite ? tw`text-white` : tw`text-current`),
    ({ hasLabel }) =>
        hasLabel &&
        css`
            &:before {
                ${tw`absolute cursor-pointer`}
                content: '';
                top: -0.5em;
                right: -0.5em;
                bottom: -0.5em;
                left: -0.5em;
            }
        `,
    ({ disabled }) => disabled && tw`cursor-not-allowed`,
]);

const HiddenCheckbox = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
`;

const Slider = styled.div`
    &:before,
    &:after {
        content: '';
        ${tw`block left-0 top-0 absolute rounded-full`}
        ${({ isSmall }) => (isSmall ? sizes.slider.small : sizes.slider.large)}
    }

    // Knob track
    &:before {
        ${tw`w-full`}
        ${({ isWhite }) => (isWhite ? tw`bg-white` : tw`bg-icon`)}
        ${({ disabled }) =>
            disabled && tw`opacity-75 border-body-disabled bg-icon-disabled`}
    }
    ${Wrap}:hover &:before {
        ${({ isWhite }) => (isWhite ? tw`` : tw`bg-icon-dark`)}
    }
    ${HiddenCheckbox}:checked + &:before {
        ${({ isWhite }) => (isWhite ? tw`bg-white` : tw`bg-primary`)}
    }
    .group:hover ${HiddenCheckbox}:checked + &:before,
    ${Wrap}:hover ${HiddenCheckbox}:checked + &:before {
        ${({ isWhite }) => (isWhite ? tw`` : tw`bg-primary-dark`)}
        ${({ disabled }) =>
            disabled && tw`opacity-75 border-body-disabled bg-icon-disabled`}
    }
    &:before,
    ${HiddenCheckbox}:checked + &:before {
        ${({ disabled }) => disabled && tw`bg-body-disabled`}
    }

    // Knob
    &:after {
        ${tw`border-2`}
        ${({ isSmall }) => (isSmall ? sizes.knob.small : sizes.knob.large)}
        transition: transform .25s ease, background-color .25s ease;
        ${({ isWhite }) =>
            isWhite ? tw`bg-icon border-white` : tw`bg-white border-icon`}
        ${({ disabled }) => disabled && tw`border-icon-disabled`}
    }
    ${Wrap}:hover &:after {
        ${({ isWhite }) =>
            isWhite ? tw`bg-icon-dark border-white` : tw`border-icon-dark`}
    }
    ${HiddenCheckbox}:checked + &:after {
        transform: translateX(100%);
        ${({ isWhite }) =>
            isWhite ? tw`bg-primary border-white` : tw`bg-white border-primary`}
    }
    ${Wrap}:hover ${HiddenCheckbox}:checked + &:after {
        ${({ isWhite }) =>
            isWhite ? tw`bg-primary-dark` : tw`border-primary-dark`}
        ${({ disabled }) => disabled && tw`border-body-disabled`}
    }
    &:after,
    ${HiddenCheckbox}:checked + &:after {
        ${({ isWhite, disabled }) =>
            isWhite && disabled && tw`opacity-75 border-body-disabled`}
        ${({ isWhite, disabled }) =>
            !isWhite && disabled && tw`opacity-75 border-body-disabled`}
    }
`;

export default ({ hasLabel, disabled, isWhite, isSmall, ...rest }) => (
    <Wrap {...{ hasLabel, disabled, isSmall }} as={hasLabel ? 'label' : null}>
        <HiddenCheckbox
            type="checkbox"
            {...(disabled && { disabled: true })}
            {...rest}
        />
        <Slider {...{ disabled, isWhite, isSmall }} />
    </Wrap>
);
