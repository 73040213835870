import React, { createContext, useContext } from 'react';

/**
 * isModal provider and hook
 */

const ModalContext = createContext(false);

const IsModalProvider = ({ children }) => (
    <ModalContext.Provider value={true}>{children}</ModalContext.Provider>
);

const useIsModal = () => useContext(ModalContext);

export { IsModalProvider, useIsModal };
