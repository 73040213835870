import crypto from 'crypto';

export const createHashForObject = (obj) =>
    crypto.createHash('sha256').update(JSON.stringify(obj)).digest('hex');

export const createHashForString = (str) =>
    str ? crypto.createHash('sha256').update(str).digest('hex') : undefined;

export const createHmacForString = (str, key) =>
    [str, key].every(Boolean)
        ? crypto.createHmac('sha256', key).update(str).digest('hex')
        : undefined;
