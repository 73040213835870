import React from 'react';
import { Search as SemanticSearch } from 'semantic-ui-react';
import tw, { theme, styled, css } from 'twin.macro';
import { customScrollbar } from '~common/ui';
import 'semantic-ui-css/components/search.css';

const Wrap = styled.label`
    ${tw`w-full`};
    ${({ disabled }) => disabled && tw`pointer-events-none`}
    .ui.loading.loading.search .input > i.icon {
        // Add branding color to semantic loading state
        &:after {
            border-color: ${theme`colors.primary.DEFAULT`} transparent
                transparent;
        }
    }
    .ui.icon.input {
        ${({ isBordered }) => isBordered && tw`w-full`};
    }
    .ui.input > input {
        border-radius: 0;
        ${tw`px-0 py-3 bg-transparent w-full border-0 text-body-dark`};
        ${({ isLined }) =>
            isLined &&
            tw`border-line focus:border-line-dark border-b rounded-none!`};
        ${({ isBordered }) =>
            isBordered &&
            tw`w-full bg-white border border-line focus:border-line-dark rounded`};
    }
    .ui.icon.input > input {
        ${tw`pl-10`};
        ${({ isBordered }) => isBordered && tw`px-4 w-full`};
    }

    .ui.input:not(.disabled) > input::placeholder {
        ${tw`text-body-light`}
    }

    .ui.input.disabled > input::placeholder {
        ${tw`text-black`}
    }

    .ui.input:not(.disabled) > input:hover::placeholder,
    .ui.input:not(.disabled) > input:focus::placeholder {
        ${tw`text-body`}
    }

    .ui.input > svg {
        ${tw`text-primary absolute right-auto left-[0.5rem] top-[0.7rem]`};
        .user-add-form & {
            ${tw`top-[0.6rem]`};
        }
        ${({ isGrey }) =>
            isGrey &&
            css`
                ${tw`text-icon`}
            `}
    }

    .ui.input:not(.disabled) > svg {
        ${tw`cursor-pointer`};
    }

    .ui.input.disabled {
        ${tw`cursor-not-allowed select-none`}
    }

    .ui.search > .results {
        ${tw`max-h-[18rem] overflow-y-auto shadow`};
        ${customScrollbar({ showWhenScrollable: true })};
        ${({ hasAbsolutePopup }) =>
            !hasAbsolutePopup && tw`fixed top-[unset] left-[unset]`};
    }
`;

const Search = ({
    isGrey,
    isLined,
    isBordered,
    hasAbsolutePopup,
    disabled,
    ...inputProps
}) => (
    <Wrap {...{ isGrey, isLined, isBordered, hasAbsolutePopup, disabled }}>
        <SemanticSearch {...inputProps} />
    </Wrap>
);

export default Search;
