import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M1 26.25h6c1.365 0 3-1.587 3-2.833V5H4C2.635 5 1 6.587 1 9.25v17z"
            fill="#047ab1"
        />
        <path
            d="M4.405 19.364c-1.152 0-2.298-.152-3.405-.453v-1.558a6.1 6.1 0 0 0 2.85.822c1.155 0 1.8-.765 1.8-1.813v-4.278H8.5v4.278c0 1.672-.93 3.003-4.095 3.003z"
            fill="#fff"
        />
        <path
            d="M11.5 26.25h6c1.365 0 3-1.587 3-2.833V5h-6c-1.365 0-3 1.587-3 4.25v17z"
            fill="#d42d06"
        />
        <path
            d="M11.5 13.075c.87-.793 2.37-1.29 4.8-1.176 1.32.057 2.7.425 2.7.425v1.572c-.79-.45-1.677-.727-2.595-.807-1.845-.156-2.97.822-2.97 2.536s1.125 2.692 2.97 2.55c.92-.088 1.806-.368 2.595-.822v1.558s-1.38.382-2.7.44c-2.43.113-3.93-.383-4.8-1.176v-5.1z"
            fill="#fff"
        />
        <path
            d="M22 26.25h6c1.365 0 3-1.587 3-2.833V5h-6c-1.365 0-3 1.587-3 4.25v17z"
            fill="#67b637"
        />
        <path
            d="M29.5 17.324c0 1.133-.93 1.842-2.175 1.842H22v-7.083h4.845l.345.014c1.095.057 1.905.666 1.905 1.714 0 .822-.555 1.53-1.56 1.7v.042c1.1.07 1.965.737 1.965 1.77zm-3.825-4.122c-.065-.01-.13-.014-.195-.014h-1.8v1.785h1.995c.375-.085.7-.41.7-.893s-.315-.807-.7-.878zm.225 2.9c-.08-.012-.16-.016-.24-.014h-1.98v1.94h1.98L25.9 18c.375-.085.7-.453.7-.95s-.3-.85-.7-.95z"
            fill="#fff"
        />
    </svg>
);
