import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M28.5 6h-25A2.5 2.5 0 0 0 1 8.5v15A2.5 2.5 0 0 0 3.5 26h25a2.5 2.5 0 0 0 2.5-2.5v-15A2.5 2.5 0 0 0 28.5 6z"
            fill="#016fd0"
        />
        <g fillRule="evenodd">
            <path
                d="M18.205 22.742v-7.127l12.684.01v1.97l-1.466 1.567 1.466 1.58v2h-2.34l-1.244-1.373-1.235 1.378-7.864-.017z"
                fill="#fffffe"
            />
            <g fill="#016fd0">
                <path d="M19.052 21.96V16.4h4.715v1.28h-3.19v.87h3.113v1.26H20.58v.854h3.19v1.296h-4.715z" />
                <path d="M23.744 21.96l2.61-2.784-2.61-2.777h2.02l1.594 1.763 1.6-1.763h1.933v.044l-2.554 2.733 2.554 2.705v.08h-1.952l-1.623-1.78-1.606 1.78h-1.965z" />
            </g>
            <path
                d="M18.797 9.3h3.058l1.074 2.44V9.3h3.775l.65 1.827.653-1.827h2.882v7.127H15.656l3.14-7.127z"
                fill="#fffffe"
            />
            <g fill="#016fd0">
                <path d="M19.376 10.064l-2.467 5.556H18.6l.465-1.113h2.522l.465 1.113h1.735l-2.457-5.556h-1.955zm.212 3.197l.74-1.77.74 1.77h-1.48z" />
                <path d="M23.765 15.62v-5.556l2.38.008 1.224 3.416 1.232-3.424h2.3v5.556l-1.473.013v-3.817l-1.39 3.804H26.68l-1.42-3.817v3.817h-1.496z" />
            </g>
        </g>
    </svg>
);
