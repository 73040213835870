import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m17.469 3.3317 10.487 6.7055c0.1571 0.1042 0.2763 0.2564 0.3396 0.434 0.0634 0.1776 0.0676 0.3709 0.0119 0.551-0.0557 0.1802-0.1682 0.3374-0.3207 0.4483-0.1525 0.1108-0.3369 0.1693-0.5254 0.1667h-22.924c-0.18852 0.0026-0.37283-0.0559-0.52535-0.1667-0.15252-0.1109-0.26503-0.2681-0.32071-0.4483-0.05568-0.1801-0.05152-0.3734 0.01184-0.551 0.06337-0.1776 0.18254-0.3298 0.33968-0.434l10.487-6.7055c0.4396-0.27793 0.949-0.42546 1.4691-0.42546s1.0295 0.14753 1.4691 0.42546zm-12.262 20.625-1.2073 2.9091c-0.17454 0.4072 0 0.829 0.40727 0.829h22.778c0.3927 0 0.5963-0.4218 0.4218-0.829l-1.2218-2.9091c-0.0727-0.1917-0.1992-0.3582-0.3644-0.4795s-0.362-0.1922-0.5666-0.2042h-19.302c-0.20663 0.011-0.40571 0.0814-0.57339 0.2026-0.16768 0.1213-0.29686 0.2883-0.37207 0.4811z"
            fill="currentColor"
            opacity=".4"
        />
        <path
            d="m6.5455 13.094h3.6363v8.7272h-3.6363v-8.7272zm15.273 0h3.6363v8.7272h-3.6363v-8.7272zm-7.6364 0h3.6364v8.7272h-3.6364v-8.7272z"
            fill="currentColor"
            opacity=".2"
        />
    </svg>
);
