import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { NavLink } from 'react-router-dom';
import { UserAvatar } from '~modules/user/UserAvatar';
import { getUserDisplayName } from '~shared/user/user.helpers';
import Icon from '~icons';
import { NewUserTooltip, OverflowToolTip } from '~modules/common/components';

export const Container = tw.div`flex items-center min-w-0 pr-7 sm:pr-0`;
export const Avatar = tw(UserAvatar)`mr-2.5 col-start-1 row-start-1`;
const Link = tw.div`flex items-center`;
export const DisplayName = styled.div(({ allowDisplayNameUpdate, isLarge }) => [
    tw`group-hocus:text-body-dark svg:shrink-0`,
    allowDisplayNameUpdate && tw`flex items-center space-x-1.5 svg:text-icon`,
    tw`text-body-dark`,
    isLarge &&
        tw`text-body-dark text-2xl font-bold truncate flex items-center space-x-1 [@media (max-width: 550px)]:max-w-[230px]`,
    css`
        span {
            ${tw`truncate`}
        }
        ${Link}:hover &,
            ${Link}:focus & {
            ${tw`text-body-dark`}
        }
    `,
]);
export const Content = tw.div`truncate`;
export const ContentMini = tw.div`truncate w-full [@media (min-width: 550px)]:(flex items-center gap-x-2)`;

export const Email = tw.div`truncate text-body font-normal`;

const UserLink = ({ slug, children }) =>
    slug ? (
        <Link as={NavLink} to={slug}>
            {children}
        </Link>
    ) : (
        children
    );

const User = ({
    user,
    allowDisplayNameUpdate,
    handleAddDisplayName,
    isInactive,
    isLarge,
    slug,
}) => (
    <UserLink {...{ slug }}>
        <NewUserTooltip
            hasDisplayName={Boolean(user?.displayName)}
            displayName={user?.displayName}
            {...{ allowDisplayNameUpdate, handleAddDisplayName }}
        >
            <Container {...{ allowDisplayNameUpdate }}>
                <Avatar
                    user={user}
                    size={isLarge ? 'large' : 'medium'}
                    isDim={isInactive}
                    enableTooltip={false}
                />
                <Content>
                    <OverflowToolTip>
                        <DisplayName {...{ isLarge, allowDisplayNameUpdate }}>
                            <span tw="truncate">
                                {getUserDisplayName(user)}
                            </span>
                            {allowDisplayNameUpdate && (
                                <Icon name="information" tw="text-sm" />
                            )}
                        </DisplayName>
                    </OverflowToolTip>
                    <OverflowToolTip>{user?.email}</OverflowToolTip>
                </Content>
            </Container>
        </NewUserTooltip>
    </UserLink>
);

const UserMini = ({
    user,
    allowDisplayNameUpdate,
    handleAddDisplayName,
    isInactive,
    isLarge,
}) => (
    <Container {...{ allowDisplayNameUpdate }}>
        <Avatar
            user={user}
            size="small"
            isDim={isInactive}
            enableTooltip={false}
        />
        <ContentMini>
            <NewUserTooltip
                hasDisplayName={Boolean(user.displayName)}
                displayName={user.displayName}
                {...{ allowDisplayNameUpdate, handleAddDisplayName }}
            >
                <DisplayName {...{ isLarge, allowDisplayNameUpdate }}>
                    <div tw="truncate [@media (min-width: 550px)]:max-w-[135px]">
                        <OverflowToolTip
                            enableTooltip={!allowDisplayNameUpdate}
                        >
                            {getUserDisplayName(user)}
                        </OverflowToolTip>
                    </div>
                    {allowDisplayNameUpdate && (
                        <Icon name="information" tw="text-sm" />
                    )}
                </DisplayName>
            </NewUserTooltip>

            <OverflowToolTip>{user.email}</OverflowToolTip>
        </ContentMini>
    </Container>
);

export { User as default, UserMini };
