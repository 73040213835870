// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-nz';

import locale2 from 'locale2';

const localeList = [
    { code: 'en-au', text: 'English (Australia), en-au' },
    { code: 'en-ca', text: 'English (Canada), en-ca' },
    { code: 'en-gb', text: 'English (United Kingdom), en-gb' },
    { code: 'en-ie', text: 'English (Ireland), en-ie' },
    { code: 'en-nz', text: 'English (New Zealand), en-nz' },
    { code: 'en-us', text: 'English (United States), en-us' },
    { code: 'en', text: 'English (United States), en' },
];

export const localeOptions = localeList.map((l) => ({
    key: l.code,
    text: l.text,
    value: l.code,
}));

export function guessLocale(timezone) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/better-regex
    if (/^Australia/i.test(timezone)) {
        return 'en-au';
    }

    const code = String(locale2).toLowerCase();

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-array-some
    if (_.find(localeList, { code })) {
        return code;
    }

    return 'en';
}

export const DayMonthFormatString = (moment) => {
    const monthNumeralDayOfMonthString = moment
        .localeData()
        .longDateFormat('L')
        .replace(/Y/g, '');

    return monthNumeralDayOfMonthString.replace(/^\W|\W$/gm, '');
};

window.guessLocale = guessLocale;
