import React, { useEffect, useRef } from 'react';
import tw, { styled, css } from 'twin.macro';
import { Input } from '~modules/common/form-elements';
import Icon from '~modules/common/icons';

const SearchWrap = styled.div([
    tw`relative
    svg:(absolute text-icon hocus:text-icon-dark select-none z-20 right-5 top-1/2 transform -translate-y-1/2 -mt-px)`, // Close icon
    css({
        '.ui.fluid.input': tw`pr-8`,
        'input[type="search" i]::-webkit-search-cancel-button': tw`hidden`, // Remove browser close icon to replace with ours
    }),
]);

const SearchInput = ({
    setSearchText,
    placeholder = 'Search',
    hasBorder = true,
    focusOnMount,
    onEnter,
    defaultValue,
}) => {
    const ref = useRef();

    // Clear search text onMount only if input does not have a defaultValue
    useEffect(() => {
        !defaultValue && setSearchText('');
    }, [defaultValue, setSearchText]);

    const handleOnChange = (evt) => {
        setSearchText(evt.target.value);
    };
    // onClick for clearable input
    const handleOnClearClick = () => {
        setSearchText('');
        ref.current.inputRef.current.value = '';
    };

    return (
        <SearchWrap>
            {ref.current?.inputRef?.current.value.length > 0 && (
                <Icon
                    tw="text-xs hover:text-black focus:text-black cursor-pointer"
                    name="close"
                    onClick={handleOnClearClick}
                />
            )}
            <Input
                ref={ref}
                type="search"
                placeholder={placeholder}
                isGrey
                spellCheck="false"
                icon={<Icon name="search" />}
                onChange={handleOnChange}
                {...{ hasBorder, focusOnMount, onEnter, defaultValue }}
            />
        </SearchWrap>
    );
};

export default SearchInput;
