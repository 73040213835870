import linkifyIt from 'linkify-it';
import { stripHtml } from '~shared/utils/html-sanitizer';

const linkify = linkifyIt();

export const getOnlineMeetingUrl = (lookupText = '') => {
    const sanitized = stripHtml(lookupText);
    if (!linkify.test(sanitized)) return;
    const matches = linkify.match(sanitized) || [];
    const match = matches
        .map(({ url }) => url)
        .filter((url) => url.match(/http(s)?:\/\/.+\..+$/));

    if (lookupText.length > 0 && !match.length) return;

    return match[0].replace(/^http:/, 'https:');
};
