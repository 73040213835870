import _findIndex from 'lodash/findIndex.js';
import { getStore } from '~common/store';
import { createSelector } from 'reselect';
import {
    getNextOccurrenceFromStoreByDate,
    getPreviousOccurrenceFromStoreByDate,
    getOccurrencesFromStoreByMeeting,
    getOccurrenceSelector,
    getOccurrencesSelector,
} from './occurrence.selectors';
import { getOccurrenceAccessLevelNonMemoized } from '../permission/permission.selectors.js';
import { createDeepEqualSelector } from '../../common/selector.helpers.js';
import { dateStartOfDay, dateEndOfDay } from '~common/time.utils';
import { getMinutesStatus } from '~modules/meeting/meeting.selectors';

/**
 * Get the in-progress or next occurrence from the store.
 * this could be next occurrence from a specified occurrence, or from now for a specified meeting.
 * @param {Object} [state = getStore().getState()] Redux store.
 * @param {Object} props
 * @param {{Object|string}} [props.meeting = {}] Meeting object or meeting.id, to identify the next occurrence for this meeting.
 * @param {string} [occurrenceId] Identify the next occurrence from this occurrence
 */
export function getCurrentOrNextOccurrenceInfoFromStoreByMeeting(state, props) {
    const _state = arguments.length === 2 ? state : getStore().getState();
    const _props = arguments.length === 2 ? props : arguments[0];

    const { meeting = {}, occurrenceId } = _props;
    let prev, next, occurrence;

    if (occurrenceId) {
        ({ occurrence, prev, next } = getOccurrenceInfoFromStoreByOccurrence(
            _state,
            meeting,
            occurrenceId
        ));
    } else {
        const time = new Date();
        const startOfDay = dateStartOfDay(time);
        const endOfDay = dateEndOfDay(time);

        prev = getPreviousOccurrenceFromStoreByDate(_state, meeting, time);
        next = getNextOccurrenceFromStoreByDate(_state, meeting, time);

        if (prev && prev.endDate.valueOf() > time.valueOf()) {
            occurrence = prev;
            prev = getPreviousOccurrenceFromStoreByDate(
                _state,
                meeting,
                prev.startDate.toDate()
            );
        } else if (
            prev &&
            prev.endDate.valueOf() >= startOfDay.valueOf() &&
            (!next || (next && next.startDate.valueOf() > endOfDay.valueOf()))
        ) {
            // Occurrence is today, and the next occurrence is not today (or there is no next occurrence)
            occurrence = prev;
            prev = getPreviousOccurrenceFromStoreByDate(
                _state,
                meeting,
                prev.startDate.toDate()
            );
        } else {
            occurrence = next;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line unicorn/prefer-ternary
            if (next) {
                next = getNextOccurrenceFromStoreByDate(
                    _state,
                    meeting,
                    next.startDate.toDate()
                );
            } else {
                next = undefined;
            }
        }
    }

    return {
        meetingId: meeting.id || meeting,
        occurrence,
        myAccessLevel: getOccurrenceAccessLevelNonMemoized(_state, {
            occurrence,
        }),
        prev,
        next,
    };
}

//TODO see if this can be replaced by using the getOccurrenceInfoSelector
export const getOccurrenceInfoFromStoreByOccurrence = (
    state,
    meetingId,
    occurrenceId
) => {
    const occurrences = getOccurrencesFromStoreByMeeting(state, meetingId).sort(
        (a, b) => a.startDate.valueOf() - b.startDate.valueOf()
    );
    const occurrenceIndex = _findIndex(occurrences, { id: occurrenceId });
    const occurrence = occurrences[occurrenceIndex];
    let prev, next;
    if (occurrenceIndex > 0) {
        prev = occurrences[occurrenceIndex - 1];
    }
    if (occurrenceIndex < occurrences.length) {
        next = occurrences[occurrenceIndex + 1];
    }
    return {
        meetingId,
        occurrence,
        prev,
        next,
    };
};

export const getOccurrencesForMeetingSelector = createSelector(
    getOccurrencesSelector,
    (_, props) => !!props.includeArchivedOccurrences,
    (occurrences, includeArchivedOccurrences) =>
        occurrences
            .filter((o) =>
                !includeArchivedOccurrences ? o.archived === false : true
            )
            .sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf())
);

export const getOccurrenceInfoSelector = createDeepEqualSelector(
    [getOccurrenceSelector, getOccurrencesForMeetingSelector],
    (occurrence, occurrences) => {
        const occurrenceIndex = _findIndex(occurrences, { id: occurrence.id });

        let prev, prevStarted, next;
        if (occurrenceIndex > 0) {
            prev = occurrences[occurrenceIndex - 1];
            // find the first "previous" occurrence where the minutes are started
            for (let idx = occurrenceIndex - 1; idx >= 0; idx--) {
                if (!getMinutesStatus(occurrences[idx].status).isNotStarted) {
                    prevStarted = occurrences[idx];
                    break;
                }
            }
        }
        if (occurrenceIndex < occurrences.length) {
            next = occurrences[occurrenceIndex + 1];
        }
        return {
            meetingId: occurrence.meeting,
            occurrence,
            prev,
            prevStarted,
            next,
            hasPreviousOccurrences: occurrenceIndex === 0,
            hasPreviousStartedOccurrences: Boolean(prevStarted),
        };
    }
);
