import React from 'react';
import { Form } from 'semantic-ui-react';
import tw, { styled } from 'twin.macro';

const SemanticField = styled(Form.Group, {
    shouldForwardProp: (prop) => !['hasNoTopMargin'].includes(prop),
})`
    // Group fields
    .ui.form &.fields,
    .ui.form &.inline.fields {
        ${tw`mt-5`}
        ${({ hasNoTopMargin }) => hasNoTopMargin && tw`mt-0`}
        ${({ disabled }) => disabled && tw`cursor-not-allowed`}
    }
`;

const FormGroup = (props) => <SemanticField {...props} />;

export default FormGroup;
