import moment from 'moment-timezone';

export function notificationModel(notification) {
    if (!notification) {
        return;
    }

    //INFO: dates in an occurrence are stored in UTC
    const createdAt = moment(notification.createdAt);
    const updatedAt = moment(notification.updatedAt);

    return {
        ...notification,
        createdAt,
        updatedAt,
    };
}

export function notificationModelList(notifications) {
    return (notifications || []).map(notificationModel);
}
