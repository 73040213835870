import moment from 'moment-timezone';

export function commentModel(comment) {
    if (!comment) {
        return;
    }

    const createdAt = moment(comment.createdAt);
    const updatedAt = moment(comment.updatedAt);

    return {
        ...comment,
        createdAt,
        updatedAt,
    };
}

export function commentModelList(comments = []) {
    return comments.map(commentModel);
}
