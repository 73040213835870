import _capitalize from 'lodash/capitalize.js';
import {
    WORKSPACE_PLAN_BASIC,
    WORKSPACE_PLAN_CURRENCY_CONFIG,
    WORKSPACE_PLAN_PER_USER_COST,
    WORKSPACE_PLAN_PLUS,
} from '../constants/index.js';
import { getEmailAddressComponents } from '../utils/index.js';

export const isOnPlusPlan = ({ plan }) => plan === WORKSPACE_PLAN_PLUS;
export const isOnBasicPlan = ({ plan }) => plan === WORKSPACE_PLAN_BASIC;

export const getWorkspacePlanPrice = (code) =>
    WORKSPACE_PLAN_CURRENCY_CONFIG[code][WORKSPACE_PLAN_PER_USER_COST];

export const getWorkspaceMeetingLimitHit = (workspace) =>
    workspace?.limit && workspace.activeMeetingCount >= workspace.limit;

export const workspaceUpgradeDescriptionCapitalized =
    _capitalize(WORKSPACE_PLAN_PLUS);

/**
 * Check if an email address is trusted by a workspace
 * @param {String} email An email address
 * @param {Object} workspace a Workspace object or model
 * @param {[Object]} workspace.workspaceProps.domains list of workspace domains
 */
export const isUserEmailTrustedByWorkspaceDomains = (email, workspace) => {
    const userEmailDomain = getEmailAddressComponents(email)?.domain;
    if (!userEmailDomain) return false;

    const workspaceDomains = (
        workspace?.workspaceProps?.domains ||
        workspace?.domains ||
        []
    )
        .map((domain) => domain.isVerified && domain.domain)
        .filter(Boolean);
    return workspaceDomains.includes(userEmailDomain);
};
