import React, { useCallback } from 'react';
import tw from 'twin.macro';

const getSpaceX = (size) =>
    ({
        xs: tw`spacer-space-x-xs`,
        sm: tw`spacer-space-x-sm`,
        md: tw`spacer-space-x-md`,
        lg: tw`spacer-space-x-lg`,
        xl: tw`spacer-space-x-xl`,
        xxl: tw`spacer-space-x-xxl`,
    }[size]);

const getSpaceY = (size) =>
    ({
        xs: tw`spacer-space-y-xs`,
        sm: tw`spacer-space-y-sm`,
        md: tw`spacer-space-y-md`,
        lg: tw`spacer-space-y-lg`,
        xl: tw`spacer-space-y-xl`,
        xxl: tw`spacer-space-y-xxl`,
    }[size]);

const getPadding = (size) =>
    ({
        xs: tw`spacer-p-xs`,
        sm: tw`spacer-p-sm`,
        md: tw`spacer-p-md`,
        lg: tw`spacer-p-lg`,
        xl: tw`spacer-p-xl`,
        xxl: tw`spacer-p-xxl`,
    }[size]);

const getPaddingTop = (size) =>
    ({
        xs: tw`spacer-pt-xs`,
        sm: tw`spacer-pt-sm`,
        md: tw`spacer-pt-md`,
        lg: tw`spacer-pt-lg`,
        xl: tw`spacer-pt-xl`,
        xxl: tw`spacer-pt-xxl`,
    }[size]);

const getPaddingRight = (size) =>
    ({
        xs: tw`spacer-pr-xs`,
        sm: tw`spacer-pr-sm`,
        md: tw`spacer-pr-md`,
        lg: tw`spacer-pr-lg`,
        xl: tw`spacer-pr-xl`,
        xxl: tw`spacer-pr-xxl`,
    }[size]);

const getPaddingBottom = (size) =>
    ({
        xs: tw`spacer-pb-xs`,
        sm: tw`spacer-pb-sm`,
        md: tw`spacer-pb-md`,
        lg: tw`spacer-pb-lg`,
        xl: tw`spacer-pb-xl`,
        xxl: tw`spacer-pb-xxl`,
    }[size]);

const getPaddingLeft = (size) =>
    ({
        xs: tw`spacer-pl-xs`,
        sm: tw`spacer-pl-sm`,
        md: tw`spacer-pl-md`,
        lg: tw`spacer-pl-lg`,
        xl: tw`spacer-pl-xl`,
        xxl: tw`spacer-pl-xxl`,
    }[size]);

const getPaddingX = (size) =>
    ({
        xs: tw`spacer-px-xs`,
        sm: tw`spacer-px-sm`,
        md: tw`spacer-px-md`,
        lg: tw`spacer-px-lg`,
        xl: tw`spacer-px-xl`,
        xxl: tw`spacer-px-xxl`,
    }[size]);

const getPaddingY = (size) =>
    ({
        xs: tw`spacer-py-xs`,
        sm: tw`spacer-py-sm`,
        md: tw`spacer-py-md`,
        lg: tw`spacer-py-lg`,
        xl: tw`spacer-py-xl`,
        xxl: tw`spacer-py-xxl`,
    }[size]);
const getAlignment = (variant) =>
    ({
        center: tw`flex items-center justify-center w-full`,
        centerCol: tw`flex flex-col items-center justify-center w-full`,
        spaceBetween: tw`flex items-center justify-between w-full`,
        start: tw`flex items-center justify-start w-full`,
        startCol: tw`flex flex-col items-start justify-start w-full`,
    }[variant]);

const styleFunctionResolver = (fnName) =>
    ({
        spaceX: getSpaceX,
        spaceY: getSpaceY,
        p: getPadding,
        pt: getPaddingTop,
        pr: getPaddingRight,
        pb: getPaddingBottom,
        pl: getPaddingLeft,
        px: getPaddingX,
        py: getPaddingY,
        align: getAlignment,
    }[fnName] || (() => {}));

const Spacer = ({ children, className = null, ...rest }) => {
    const classes = useCallback(
        () =>
            Object.entries(rest)
                .map(([fnName, propValue]) =>
                    styleFunctionResolver(fnName)(propValue)
                )
                .filter(Boolean),
        [rest]
    );
    return (
        <div css={classes} {...{ className }}>
            {children}
        </div>
    );
};

export default Spacer;
