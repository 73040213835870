import tw, { styled } from 'twin.macro';

const Label = styled.div(({ error, touched, submitError }) => [
    tw`text-body text-sm mb-1 font-normal cursor-default select-none`,
    touched &&
        error &&
        tw`after:(font-normal text-error-icon vertical-align[baseline])`,
    touched && error === 'Required' && tw`after:(content[' (Required)'])`,
    touched &&
        error === 'Online location must be a valid url' &&
        tw`after:(content[' (Must be a valid url)'])`,
    submitError && tw`text-error`,
]);

export default Label;
