import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { pageViewDetails } from '~modules/navigation/navigation.helpers';
import {
    userViewingDashboardPage,
    userViewingOccurrencePage,
    userViewingWorkspacePage,
} from './pageView.actions.js';
import { usePageVisibility, usePrevious, useUser } from '~hooks';
import { sessionStorageGet, sessionStorageSet } from '~common/utils';

const INTERCOM_SHOW_WIDTH = 700;

function resize() {
    window.Intercom('update', {
        hide_default_launcher: window.innerWidth < INTERCOM_SHOW_WIDTH,
    });
}

const updateIntercomOnLocationChange = () =>
    window.Intercom('update', {
        changed_page_at: Number.parseInt(Date.now() / 1000),
        hide_default_launcher: window.innerWidth < INTERCOM_SHOW_WIDTH,
    });

export default function usePageViews() {
    const dispatch = useDispatch();
    const loggedInUser = useUser();
    const isVisible = usePageVisibility();
    const location = useLocation();

    const prevPathname = usePrevious(location.pathname);
    const prevSearch = usePrevious(location.search);

    useEffect(() => {
        // for this effect, we use the previous value from this component.
        // So when browser loads (or reloads), we want to ensure this logic gets triggered even if the pathname and search are the same (i.e. user has hit Reload in browser)
        if (
            prevPathname === location.pathname &&
            prevSearch === location.pathname.search
        )
            return;

        const pathname = location.pathname;
        const search = location.search;
        const locationState = location.state;

        const {
            dashboardPage,
            workspacePage,
            detailPage,
            previousDetailPage,
            isDashboardPage,
            isMeetingDetailPage,
            isWorkspacePage,
        } = pageViewDetails({ pathname }, { pathname: prevPathname });

        if (isDashboardPage) {
            dispatch(
                userViewingDashboardPage({
                    dashboardPage,
                    pathname,
                    search,
                    previousDetailPage,
                })
            );
        } else if (isMeetingDetailPage) {
            dispatch(
                userViewingOccurrencePage({
                    detailPage,
                    locationState,
                    previousDetailPage,
                })
            );
        } else if (isWorkspacePage) {
            dispatch(
                userViewingWorkspacePage({
                    workspacePage,
                    pathname,
                    search,
                    previousDetailPage,
                })
            );
        }
    }, [
        dispatch,
        location.pathname,
        location.search,
        location.state,
        prevPathname,
        prevSearch,
    ]);

    useEffect(() => {
        if (!isVisible || !window.Intercom || !loggedInUser.id) return;

        // for this effect, we want to use the last location so use sessionStorage.
        // This means it survives browser page reload - sessionStorage remains when user reloads browser page.
        // However sessionStorage gets cleared when browser is closed. So when a user opens their browser:
        //    - if MinuteMe is the active tab, trigger a location update
        //    - if MinuteMe is in the background, don't trigger an update. If the user then switches back to that tab, don't trigger an update
        // If MinuteMe has any changes to location (for whatever reason, like meeting deleted say) while it's in the background, don't trigger an update
        const prevLocation =
            sessionStorageGet('lastViewedLocation')?.location || {};

        if (
            prevLocation.pathname === location.pathname &&
            prevLocation.search === location.search
        )
            return;

        //INFO this is recommended by Intercom for a single page app to trigger
        //       message matching by creating a 'ping'.
        updateIntercomOnLocationChange();

        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
        });
    }, [location.pathname, location.search, isVisible, loggedInUser.id]);

    useEffect(() => {
        // event if a tab is in the background (say on reopening the browser), populate the sessionStorage with the page the user is on,
        // to ensure it doesn't trigger a page change immediately when they click back to that tab (in the above useEffect)
        if (!loggedInUser.id) return; // on load of MinuteMe before preload has finished
        sessionStorageSet('lastViewedLocation', {
            lastViewedAt: new Date(),
            location,
        });
    }, [location, isVisible, loggedInUser.id]);

    useEffect(() => {
        window.addEventListener('resize', resize, false);

        return () => {
            window.removeEventListener('resize', resize, false);
        };
    }, []);
}
