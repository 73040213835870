import React, { useState, useRef, forwardRef } from 'react';
import tw, { styled, css } from 'twin.macro';
import Icon from '~modules/common/icons';
import { H2 as Heading } from '~modules/common/components';
import Spacer from '~modules/common/components/Spacer';

const IconWrap = styled.div(({ isLined }) => [!isLined && tw`ml-3`]);

const Header = tw.div`text-lg w-full text-icon`;

const Toggle = styled.button(
    ({ isOpen, hasBackgroundColor, hasIndentedContent }) => [
        tw`text-left w-full focus:outline-none`,
        !hasBackgroundColor && tw`text-icon hover:text-icon-dark`,
        isOpen &&
            css`
                ${tw`text-icon-dark`}
                ${IconWrap} {
                    ${tw`text-icon-dark transform -scale-y-100`}
                }
            `,
        hasBackgroundColor &&
            tw`text-black bg-solid border rounded px-6 py-3 hover:bg-line`,
        hasIndentedContent && tw`ml-6 width[-webkit-fill-available]`,
    ]
);

const Line = styled.div(() => [
    tw`w-full h-px mt-px bg-line rounded-sm`,
    css`
        ${Toggle}:hover & {
            ${tw`bg-line-dark`}
        }
    `,
]);

const Container = styled.div(({ isOpen, dropdownHeight }) => [
    tw`block overflow-hidden relative w-full`,
    isOpen
        ? css`
              max-height: ${dropdownHeight}px;
              transition: all 0.4s ease;
          `
        : css`
              max-height: 0;
              transition: all 0.4s ease;
          `,
]);

const Accordion = (
    {
        title,
        children,
        isLined,
        isFull,
        hasBackgroundColor,
        hasHeadingAsText,
        isOpenOnLoad = false,
        hasIndentedContent,
        index,
        ...rest
    },
    ref
) => {
    const [isOpen, setIsOpen] = useState(isOpenOnLoad);
    const [dropdownHeight, setDropdownHeight] = useState(null);
    const toggleRef = useRef();

    const handleClick = () => {
        const toggleHeight = toggleRef?.current?.offsetHeight;
        const contentHeight = ref?.current[index]?.offsetHeight;

        setDropdownHeight(contentHeight + toggleHeight);
        setIsOpen((prevState) => !prevState);
    };

    return (
        <div>
            <Toggle
                ref={toggleRef}
                type="button"
                onClick={handleClick}
                {...{ isOpen, hasBackgroundColor, hasIndentedContent }}
            >
                <Spacer
                    align={isLined || isFull ? 'spaceBetween' : 'start'}
                    spaceX="md"
                >
                    {hasHeadingAsText ? title : <Heading>{title}</Heading>}
                    {isLined && <Line />}
                    <IconWrap {...{ isLined, isOpen }}>
                        <Icon name="downArrow" />
                    </IconWrap>
                </Spacer>
            </Toggle>

            <Container {...{ isOpen, dropdownHeight }}>
                <div
                    key={index}
                    ref={(r) => (ref.current[index] = r)}
                    {...{ rest }}
                >
                    {children}
                </div>
            </Container>
        </div>
    );
};

const AccordionDisabled = ({
    title,
    children,
    isLined,
    hasHeadingAsText,
    ...rest
}) => (
    <div>
        <Header>
            <Spacer align={isLined ? 'spaceBetween' : 'start'} spaceX="md">
                {hasHeadingAsText ? title : <Heading>{title}</Heading>}
                {isLined && <Line />}
            </Spacer>
        </Header>
        <div {...rest}>{children}</div>
    </div>
);

export default forwardRef(Accordion);
export { AccordionDisabled };
