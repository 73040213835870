import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import {
    normalizeWorkspaceMembers,
    orderByGrantLevel,
    normalizeWorkspace,
    getWorkspaceAddedMessage,
    normalizeRecommendedWorkspace,
} from './workspace.helpers.js';
import {
    getWorkspacesSelector,
    getTemplateLibraryWorkspacesSelector,
    getWorkspacesAndTemplateLibrariesSelector,
    getWorkspacesAndUpdateableTemplateLibrariesSelector,
    getWorkspacesCanUpdate,
    getWorkspacesIsAdmin,
    getWorkspacesAndTemplateLibrariesIsAdmin,
} from '~modules/workspace/workspace.selectors';
import {
    addUserToWorkspace,
    addEmailDomainToWorkspace,
    createWorkspace,
    deleteEmailDomainFromWorkspace,
    renameWorkspace,
    deleteWorkspace,
    leaveWorkspace,
    cancelWorkspaceRequest,
    upgradeWorkspace,
    downgradeWorkspace,
    getSecretForWorkspace,
    getCouponDetails,
    updatePaymentWorkspace,
    updateMemberDisplayName,
    sendWorkspaceAddMeetingAttemptEmail,
    startWorkspaceFeature,
    sendJoinWorkspaceRequest,
} from '~modules/workspace/workspace.actions';
import { getRecommendations } from '~modules/workspace/workspace.api';
import {
    getGroupFromStoreById,
    getWorkspaceGroupByMeetingId,
} from '~modules/group/group.selectors';
import { getDashboardState } from '~modules/pageView/pageView.selectors';
import { useShowSuccessToast, useRemoveToast, useUser } from '~hooks';
import { useOpenSimpleAddMeetingModal } from '~modules/modals/simpleAddMeeting/simpleAddMeeting.hooks';
import { MEETING_CREATED_SOURCE_NEW_WORKSPACE_TOAST } from '~shared/constants';

const emptyList = [];

/**
 * Get a single workspace from a supplied id
 */
const useWorkspaceById = (id) => {
    const group = useSelector((state) => getGroupFromStoreById(state, id));
    const user = useUser();
    const { data } = useWorkspaceRecommendations(user.id);

    const normalizedWorkspace = useMemo(
        () => group && normalizeWorkspace(group),
        [group]
    );
    if (!group) {
        const recommendedWorkspace = (data || []).find((d) => d.id === id);
        return normalizeRecommendedWorkspace(recommendedWorkspace || {});
    }

    return normalizedWorkspace;
};

/**
 * Get a single workspace from a supplied meeting id
 */
const useWorkspaceByMeetingId = (id) => {
    const group = getWorkspaceGroupByMeetingId({ meeting: id });

    const normalizedWorkspace = useMemo(
        () => group && normalizeWorkspace(group),
        [group]
    );

    return normalizedWorkspace;
};

/**
 * Grab current workspace from url param (for the workspace detail page)
 */
const useWorkspaceCurrent = () => {
    const { workspaceId } = useParams();
    if (!workspaceId) throw new Error(`No workspaceId found in url params`);
    const workspace = useWorkspaceById(workspaceId);
    return workspace;
};

/**
 * Grab all workspaces for the current user
 */
const useWorkspaces = () => useSelector(getWorkspacesSelector);

/**
 * Grab all workspaces and template libraries for the current user
 */
const useWorkspacesAndTemplateLibraries = () =>
    useSelector(getWorkspacesAndTemplateLibrariesSelector);

/**
 * Grab all workspaces that are template libraries
 */
const useTemplateLibraryWorkspaces = () =>
    useSelector(getTemplateLibraryWorkspacesSelector);

/**
 * Grab all workspaces and updateable (with minimum Admin access level) template libraries for the current user
 */
const useWorkspacesAndUpdateableTemplateLibraries = () =>
    useSelector(getWorkspacesAndUpdateableTemplateLibrariesSelector);

/**
 * Grab all workspaces for the current user that they can update
 */
const useWorkspacesIcanUpdate = () => useSelector(getWorkspacesCanUpdate);

/**
 * Grab all workspaces where current user is an admin
 */
const useWorkspacesIsAdmin = () => useSelector(getWorkspacesIsAdmin);

/**
 * Grab all workspaces and workspace template libraries where current user is an admin
 */
const useWorkspacesAndTemplateLibrariesIsAdmin = () =>
    useSelector(getWorkspacesAndTemplateLibrariesIsAdmin);

/**
 * Add an email domain to a workspace
 * @param {Object} workspace workspace object. must contain id.
 * @param {String} domain email domain (domain part only), e.g. minuteme.com
 */
const useAddEmailDomainToWorkspace = () => {
    const dispatch = useDispatch();
    return (workspace, domain) =>
        dispatch(addEmailDomainToWorkspace(workspace, domain));
};

/**
 * Delete an email domain from a workspace
 * @param {Object} workspace workspace object. must contain id.
 * @param {String} domain email domain (domain part only), e.g. minuteme.com
 */
const useDeleteEmailDomainFromWorkspace = () => {
    const dispatch = useDispatch();
    return (workspace, domain) =>
        dispatch(deleteEmailDomainFromWorkspace(workspace, domain));
};

/**
 * Create a new workspace
 */
const useCreateWorkspace = () => {
    const dispatch = useDispatch();
    return (title) => dispatch(createWorkspace(title));
};

/**
 * Add a user to a workspace
 */
const useAddWorkspaceUser = () => {
    const dispatch = useDispatch();
    return (user, workspace) => dispatch(addUserToWorkspace(user, workspace));
};

/**
 * Get the workspace State from the store, and normalized, sorted workspace members in the workspace
 * Returns a spread selector so setting values is possible
 */
const useWorkspaceDashboardState = (workspaceId) => {
    const workspaceState = useSelector((state) =>
        getDashboardState(state, `workspaces-${workspaceId}`)
    );
    const workspaceStateMembers = workspaceState?.members || emptyList;

    const members = useMemo(
        () =>
            workspaceStateMembers
                .map(normalizeWorkspaceMembers)
                .sort(orderByGrantLevel),
        [workspaceStateMembers]
    );

    const workspaceDashboardState = useMemo(
        () => ({
            ...workspaceState,
            members,
        }),
        [workspaceState, members]
    );

    return workspaceDashboardState;
};

/**
 * Get the invoices belonging to a workspace
 */
const useWorkspaceInvoicesCurrent = () => {
    const { workspaceId } = useParams();
    const workspaceState = useSelector((state) =>
        getDashboardState(state, `workspaces-${workspaceId}`)
    );
    return (
        workspaceState?.invoices || {
            isLoading: true,
            error: null,
            data: [],
        }
    );
};

/**
 * Rename a workspace's title
 */
const useRenameWorkspace = () => {
    const dispatch = useDispatch();
    return (id, title) => dispatch(renameWorkspace(id, title));
};

/**
 * Start the feature for a Workspace
 */
const useStartWorkspaceFeature = (workspaceId) => {
    const dispatch = useDispatch();
    return (featureName) =>
        dispatch(startWorkspaceFeature(workspaceId, featureName));
};

/**
 * Delete a workspace
 */
const useDeleteWorkspace = () => {
    const dispatch = useDispatch();
    return (id) => dispatch(deleteWorkspace(id));
};

/**
 * Leave a workspace
 */
const useLeaveWorkspace = () => {
    const dispatch = useDispatch();
    return (id, workspaceTitle, canLeave) =>
        dispatch(leaveWorkspace(id, workspaceTitle, canLeave));
};

/**
 * Grab a list of meetings with a workspace
 */
const useWorkspaceState = (workspace) =>
    useSelector((state) =>
        getDashboardState(state, `workspaces-${workspace.id}`)
    ) || [];

/**
 * Upgrade a workspace
 */
const useUpgradeWorkspace = () => {
    const { workspaceId } = useParams();
    if (!workspaceId) throw new Error('No workspaceId found in url params');
    const dispatch = useDispatch();
    return (payload) => dispatch(upgradeWorkspace(workspaceId, payload));
};

/**
 * Downgrade a workspace
 */
const useDowngradeWorkspace = () => {
    const { workspaceId } = useParams();
    if (!workspaceId) throw new Error('No workspaceId found in url params');
    const dispatch = useDispatch();
    return (payload) => dispatch(downgradeWorkspace(workspaceId, payload));
};

/**
 * Get the workspace payment secret
 */
const useSecretForWorkspace = () => {
    const { workspaceId } = useParams();
    if (!workspaceId) throw new Error('No workspaceId found in url params');
    const dispatch = useDispatch();
    return () => dispatch(getSecretForWorkspace(workspaceId));
};

/**
 * Get a coupon
 */
const useGetCouponDetails = () => {
    const { workspaceId } = useParams();
    if (!workspaceId) throw new Error('No workspaceId found in url params');
    const dispatch = useDispatch();
    return (couponId, currency) =>
        dispatch(getCouponDetails(workspaceId, couponId, currency));
};

const useCurrencyFormatter = ({ loggedInUser, min, max, currency }) => {
    const formatter = new Intl.NumberFormat(loggedInUser.locale, {
        style: 'currency',
        currency: currency || 'USD',
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });
    return formatter;
};

/**
 * Update a payment method on a workspace
 */
const useUpdatePaymentWorkspace = () => {
    const { workspaceId } = useParams();
    if (!workspaceId) throw new Error('No workspaceId found in url params');
    const dispatch = useDispatch();
    return (payload) => dispatch(updatePaymentWorkspace(workspaceId, payload));
};

/**
 * Request access to workspace
 */
const useWorkspaceJoinRequest = () => {
    const dispatch = useDispatch();
    const { data } = useWorkspaceRecommendations();

    return (workspaceIds) => {
        const workspaces = data.filter((d) => workspaceIds.includes(d.id));
        dispatch(sendJoinWorkspaceRequest(workspaces));
    };
};

/**
 * Cancel a workspace access request
 */
const useCancelWorkspaceRequest = () => {
    const dispatch = useDispatch();
    return (workspaceId) => dispatch(cancelWorkspaceRequest(workspaceId));
};

/**
 * Update a workspace members displayName
 */
const useUpdateMemberDisplayName = () => {
    const dispatch = useDispatch();
    return (workspaceId, userId, displayName) =>
        dispatch(updateMemberDisplayName(workspaceId, userId, displayName));
};

/**
 * Show a toast after a workspace is created
 */
const useCreateWorkspaceToast = () => {
    const showSuccessToast = useShowSuccessToast();
    const removeToast = useRemoveToast();

    const openSimpleAddMeetingModal = useOpenSimpleAddMeetingModal();
    const addMeeting = () =>
        openSimpleAddMeetingModal({
            createdSourceLocation: MEETING_CREATED_SOURCE_NEW_WORKSPACE_TOAST,
        });

    return (group) => {
        if (!group) return;
        const id = `new-workspace-${group.id}`;
        const remove = () => removeToast(id);
        const data = {
            id,
            title: 'Workspace created',
            message: getWorkspaceAddedMessage({ addMeeting, remove, group }),
        };
        const options = {
            closeOnToastrClick: false,
            timeOut: 0,
            progressBar: false,
        };
        showSuccessToast(data, options);
    };
};

/**
 * Send an email when a workspace tries to be created in a limited workspace
 */
const useSendWorkspaceAddMeetingAttemptEmail = () => {
    const dispatch = useDispatch();
    return useCallback(
        (workspaceId) => {
            dispatch(sendWorkspaceAddMeetingAttemptEmail(workspaceId));
        },
        [dispatch]
    );
};

/**
 * Get workspace recommendations based on the users email domain
 */
const useWorkspaceRecommendations = () =>
    useQuery('recommendations', getRecommendations, {
        refetchOnWindowFocus: false,
    });

export {
    useWorkspaceById,
    useWorkspaceByMeetingId,
    useWorkspaceCurrent,
    useWorkspaces,
    useTemplateLibraryWorkspaces,
    useWorkspacesAndTemplateLibraries,
    useWorkspacesAndUpdateableTemplateLibraries,
    useWorkspacesIcanUpdate,
    useWorkspacesIsAdmin,
    useWorkspacesAndTemplateLibrariesIsAdmin,
    useAddEmailDomainToWorkspace,
    useDeleteEmailDomainFromWorkspace,
    useAddWorkspaceUser,
    useWorkspaceDashboardState,
    useCreateWorkspace,
    useRenameWorkspace,
    useStartWorkspaceFeature,
    useDeleteWorkspace,
    useLeaveWorkspace,
    useCancelWorkspaceRequest,
    useWorkspaceState,
    useUpgradeWorkspace,
    useDowngradeWorkspace,
    useCurrencyFormatter,
    useUpdatePaymentWorkspace,
    useWorkspaceInvoicesCurrent,
    useSecretForWorkspace,
    useGetCouponDetails,
    useWorkspaceJoinRequest,
    useUpdateMemberDisplayName,
    useCreateWorkspaceToast,
    useSendWorkspaceAddMeetingAttemptEmail,
    useWorkspaceRecommendations,
};
