import React from 'react';

export default ({ iconThick = false, title = 'Snooze', ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="m28 17c0 6.6274-5.3726 12-12 12-6.6274 0-12-5.3726-12-12s5.3726-12 12-12c6.6274 0 12 5.3726 12 12z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M3 7.5L7.5 3"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M29 7.5L24.5 3"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m12 13h8l-8 9h8"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
