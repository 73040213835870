import React from 'react';

export default ({ iconThick = false, title = '', ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="m10 21v7h-8v-13.5m8 6.5 6 5m-6-5-8-6.5m14 11.5 6-5m-6 5v-11.5m6 6.5v7h8v-13.5m-8 6.5 8-6.5m-14 0 14-10.5v10.5m-14 0-14-10.5v10.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
