import { addMeetingByTemplate as apiAddMeetingByTemplate } from '~modules/meetingTemplate/meetingTemplate.api';
import type {
    MeetingTemplateRequest,
    MeetingTemplateResponse,
} from '~modules/meetingTemplate/meetingTemplate.types';

export const addMeetingByTemplate = (
    templateId: string,
    params?: MeetingTemplateRequest
): Promise<MeetingTemplateResponse> =>
    apiAddMeetingByTemplate(templateId, params);
