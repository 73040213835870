export {
    ATTENDEE_ROLE_OWNER,
    ATTENDEE_ROLE_OWNER_ABSENT,
    ATTENDEE_ROLE_ATTENDEE,
    ATTENDEE_ROLE_ATTENDEE_ABSENT,
    ATTENDEE_ROLE_ABSENT_SUFFIX,
    STATUS_MINUTES_STARTED,
    STATUS_MINUTES_COMPLETED,
    STATUS_MINUTES_SENT,
    STATUS_AGENDA_SENT,
    MINUTE_ITEM_STATUS_COMPLETE,
    MINUTE_ITEM_STATUS_INCOMPLETE,
    INCOMPLETE_TOPICS_ADD_TO_NEXT,
    INCOMPLETE_TOPICS_MOVE_TO_NEXT,
    INCOMPLETE_TOPICS_MARK_COMPLETE,
    INCOMPLETE_TOPICS_DO_NOTHING,
    DATE_FORMAT,
    DATE_FORMAT_WITH_OFFSET,
    DATETIME_FORMAT_FOR_DISPLAY,
    DATETIME_FORMAT_FOR_DISPLAY_WITH_AT,
    DATETIME_FORMAT_FOR_DISPLAY_NO_PERIOD,
    TIME_FORMAT_FOR_DISPLAY,
    TIME_FORMAT_FOR_DISPLAY_NO_PERIOD,
    RECURRENCE_SCHEDULED,
    RECURRENCE_NOT_SCHEDULED,
    RECURRENCE_ONE_OFF,
    RECURRENCE_NEVER,
    RECURRENCE_DAILY,
    RECURRENCE_WEEKLY,
    RECURRENCE_MONTHLY_ABSOLUTE,
    RECURRENCE_MONTHLY_RELATIVE,
    RECURRENCE_YEARLY_ABSOLUTE,
    RECURRENCE_YEARLY_RELATIVE,
    LOGIN_SOURCE_WEBAPP,
    NOTIFICATION_STATUS_READ,
    NOTIFICATION_STATUS_UNREAD,
    NOTIFICATION_CREATED,
    NOTIFICATION_TYPE_PROMPT,
    NOTIFICATION_TYPE_MESSAGE,
    NOTIFICATION_TYPE_CONFIRM,
    NOTIFICATION_TYPE_MESSAGEMODAL,
    NOTIFICATION_MODAL_PAYMENT_FAILED,
    NOTIFICATION_MODAL_FEATURE_TRIAL_EXPIRY,
    NOTIFICATION_MODAL_UPGRADED_PRIMARY,
    NOTIFICATION_MODAL_UPGRADED_SECONDARY,
    NOTIFICATION_MODAL_TERMS_PRIVACY_UPDATED,
    NOTIFICATION_OUTCOME_ACCEPTED,
    NOTIFICATION_OUTCOME_REJECTED,
    ACTION_ITEM_CREATED_MINUTES_INLINE,
    ACTION_ITEM_CREATED_MINUTES,
    ACTION_ITEM_CREATED_OCCURRENCE,
    ACTION_ITEM_CREATED_ACTION_ITEM_REVIEW,
    ACTION_ITEM_REVIEW_BY_ASSIGNEE,
    ACTION_ITEM_REVIEW_BY_STATUS,
    ACTION_ITEM_PRIORITY_HIGH,
    ACTION_ITEM_PRIORITY_LOW,
    ACTION_ITEM_PRIORITY_MEDIUM,
    ACTION_ITEM_PRIORITY_NONE,
    AGENDA_CONTENT_SOURCE_BLANK,
    AGENDA_CONTENT_SOURCE_AGENDAITEM,
    RTE_SLATEJS,
    RTE_TINYMCE,
    DEFAULT_GROUP_NAME,
    GROUP_TYPE_TAG,
    GROUP_TYPE_MEETING,
    GROUP_TYPE_WORKSPACE,
    CATEGORY_PALETTE,
    HIGHLIGHT_COLORS,
    FONT_COLORS,
    NO_CATEGORY_COLOR,
    PAYMENT_STATUS_ERROR,
    PAYMENT_INVOICE_STATUS_OPEN,
    PAYMENT_STATUS_ERRORTYPE_CARD_ERROR,
    EMAIL_EVENT_STATUS_NOT_SENT,
} from '../../server/common/constants.js';

export {
    GRANT_LEVEL_NONE,
    GRANT_LEVEL_GUEST,
    GRANT_LEVEL_MEMBER,
    GRANT_LEVEL_ADMIN,
    GRANT_LEVEL_OWNER,
    GRANT_LEVEL_READ,
    GRANT_LEVEL_WRITE,
    INTEGRATION_TYPE_TASKS,
    INTEGRATION_TYPE_CALENDARS,
    INTEGRATION_TYPE_AUTHENTICATION,
    INTEGRATION_TYPE_CRM,
    AUTHSOURCE_ASANA,
    AUTHSOURCE_AZUREAD,
    AUTHSOURCE_AZUREAD_TASKS,
    AUTHSOURCE_GOOGLE,
    AUTHSOURCE_GOOGLE_TASKS,
    AUTHSOURCE_OUTLOOK_CALENDAR,
    AUTHSOURCE_GOOGLE_CALENDAR,
    AUTHSOURCE_SMOKEBALL_AU,
    AUTHSOURCE_CALENDARS,
    AUTHSOURCE_CRM,
    AUTHSOURCE_TASKS,
    INTEGRATION_STATUS_ACTIVE,
    INTEGRATION_STATUS_INACTIVE,
    INTEGRATION_STATUS_RECONNECT,
    INTEGRATION_STATUS_HIDDEN,
    PERMISSION_TYPE_OCCURRENCE,
    PERMISSION_TYPE_MEETING,
    PERMISSION_TYPE_WORKSPACE,
    PERMISSION_TYPE_TEMPLATE_LIBRARY,
    PERMISSION_LEVEL_OWNER,
    PERMISSION_LEVEL_ADMIN,
    PERMISSION_LEVEL_VIEW,
    PERMISSION_LEVEL_UPDATE,
    PERMISSION_LEVEL_NONE,
    PERMISSION_STATUS_REJECTED,
    PERMISSION_STATUS_PENDING,
    PERMISSION_STATUS_ACCEPTED,
    PERMISSION_STATUS_REQUESTED,
    PERMISSION_STATUS_LEFT,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_ACCEPTED,
    PERMISSION_STATUS_WORKSPACE_LEFT_WAS_ACCEPTED,
    PERMISSION_STATUS_WORKSPACE_LEFT_WAS_PENDING,
    PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED,
    WORKSPACE_PLAN_PLUS,
    WORKSPACE_PLAN_BASIC,
    WORKSPACE_PLAN_PER_USER_COST,
    WORKSPACE_PLAN_CONFIG,
    WORKSPACE_PLAN_CURRENCY_CONFIG,
    WORKSPACE_DEFAULT_MEETING_LIMIT,
    PLUS_FEATURE_TRIAL_PERIOD_DAYS,
    NOTIFICATION_PREFERENCE_DEFAULTS,
    DOCUMENT_VISIBILITY_VISIBLE,
    DOCUMENT_VISIBILITY_HIDDEN,
    // createdSourceLocation
    MEETING_CREATED_SOURCE_WORKSPACE,
    MEETING_CREATED_SOURCE_NEW_WORKSPACE_TOAST,
    MEETING_CREATED_SOURCE_ADD_PATH,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_DAY,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_PLACEHOLDER,
    MEETING_CREATED_SOURCE_SERIES_DASHBOARD,
    MEETING_CREATED_SOURCE_SERIES_DASHBOARD_PLACEHOLDER,
    MEETING_CREATED_SOURCE_SERIES_SIDEBAR,
    // createdModal
    MEETING_CREATED_MODAL_SIMPLE,
    MEETING_CREATED_MODAL_ADVANCED,
    MEETING_CREATED_MODAL_EVENT_SIMPLE,
    MEETING_CREATED_MODAL_EVENT_MATCH,
    // meetingType
    MEETING_TYPE_MAP,
} from '~shared/constants';

// These are imported as they are used in the definition of other constants in this file
import {
    GRANT_LEVEL_NONE,
    GRANT_LEVEL_GUEST,
    GRANT_LEVEL_MEMBER,
    GRANT_LEVEL_ADMIN,
    GRANT_LEVEL_OWNER,
    GRANT_LEVEL_READ,
    GRANT_LEVEL_WRITE,
} from '~shared/constants';

export const BASE_APP_PATH = '/';

export const BASE_APP_HOMEPAGE = '/meetings';

export const ACTION_GROUP_BY_NONE = 'none';
export const ACTION_GROUP_BY_OCCURRENCE = 'occurrence';

export const PAGINATION_DEFAULT_PAGE_SIZE = 100;

export const ITEM_TYPE_MINUTEITEM = 'minute-item';
export const ITEM_TYPE_AGENDAITEM = 'agenda-item';
export const ITEM_TYPE_SERIESITEM = 'series-item';

export const SEND_MINUTES_NOW = 'now';
export const SEND_MINUTES_LATER = 'later';
export const MAX_TOTAL_ATTACHMENTS_SIZE_BYTES = 20000000;

export const ACTION_ITEM_REVIEW = 'action-item-review';
export const ALL_ACTION_ITEMS = 'all';
export const MY_ACTION_ITEMS = 'action-items';
export const NO_DUE_DATE_ACTION_ITEMS = 'no-due-date';
export const OVERDUE_ACTION_ITEMS = 'overdue';
export const UPCOMING_ACTION_ITEMS = 'upcoming';
export const ACTION_ITEM_PRIORITY_LABEL_HIGH = 'High';
export const ACTION_ITEM_PRIORITY_LABEL_MEDIUM = 'Medium';
export const ACTION_ITEM_PRIORITY_LABEL_LOW = 'Low';
export const ACTION_ITEM_PRIORITY_LABEL_NONE = 'Not set';

export const STATUS_TYPE_NEW_USER = 'new-user';
export const STATUS_TYPE_NEW_USER_NO_ACCESS = 'new-user-no-access';
export const STATUS_TYPE_WORKSPACE_REJECTED = 'rejected-workspace';
export const STATUS_TYPE_WORKSPACE_LEFT = 'left-workspace';
export const STATUS_TYPE_REVOKED = 'revoked';

export const SHOULD_OPEN_HELP_CENTER = 'should-open-help-center';
export const QUICKSWITCHER_DISPLAY_PREFERENCE =
    'quickswitcher-display-preference';
export const DISPLAY_HELP_CENTER_SEARCH = 'display-help-center-search';
export const AGENDA_TOPIC_CONTENT_FOR_MEETING =
    'agenda-topic-content-for-meeting';
export const AGENDA_WORKSPACE_LOGO_FOR_MEETING =
    'agenda-workspace-logo-for-meeting';
export const AGENDA_ATTACHMENTS_INLINE_FOR_MEETING =
    'agenda-attachments-inline-for-meeting';

export const grantOptions = [
    {
        label: 'None',
        key: GRANT_LEVEL_NONE,
        value: GRANT_LEVEL_NONE,
        longDescription:
            'Member will receive minutes and action items via email only',
    },
    {
        label: 'Read',
        key: GRANT_LEVEL_READ,
        value: GRANT_LEVEL_READ,
        longDescription:
            'Member will view the minutes and action items in MinuteMe',
    },
    {
        label: 'Write',
        key: GRANT_LEVEL_WRITE,
        value: GRANT_LEVEL_WRITE,
        longDescription: 'Member will add topics and update minutes',
    },
    {
        label: 'Admin',
        key: GRANT_LEVEL_ADMIN,
        value: GRANT_LEVEL_ADMIN,
        longDescription: 'Member will administer the meeting',
    },
];

export const workspaceGrantOptions = [
    {
        label: 'Owner',
        key: GRANT_LEVEL_OWNER,
        value: GRANT_LEVEL_OWNER,
        longDescription: 'User owns and will administer the workspace',
    },
    {
        label: 'Admin',
        key: GRANT_LEVEL_ADMIN,
        value: GRANT_LEVEL_ADMIN,
        longDescription: 'User will administer the meeting',
    },
    {
        label: 'Member',
        key: GRANT_LEVEL_MEMBER,
        value: GRANT_LEVEL_MEMBER,
        longDescription: 'User will add meetings to the workspace',
    },
    {
        label: 'Guest',
        key: GRANT_LEVEL_GUEST,
        value: GRANT_LEVEL_GUEST,
        longDescription: 'User will access existing meetings in the workspace',
    },
];

export const VIEW_LAYOUT_ROWS = 'rows';
export const VIEW_LAYOUT_GRID = 'grid';

export const MODAL_TYPE_YESNO = 'yesno';
export const MODAL_TYPE_ADD_CANCEL = 'addcancel';
export const MODAL_TYPE_DELETE_CANCEL = 'deletecancel';
export const MODAL_TYPE_LEAVE_CANCEL = 'leavecancel';
export const MODAL_TYPE_ARCHIVE_CANCEL = 'archivecancel';
export const MODAL_TYPE_VIEW_CANCEL = 'viewcancel';
export const MODAL_TYPE_MOVE_CANCEL = 'movecancel';
export const MODAL_TYPE_CANCEL = 'cancel';
export const MODAL_TYPE_OK = 'ok';
export const MODAL_TYPE_RELOAD = 'reload';
export const MODAL_TYPE_OK_NO_ACTION = 'oknoaction';
export const MODAL_TYPE_UNLINK_CANCEL = 'unlinkcancel';
export const MODAL_TYPE_NONE = 'none';

export const MEETING_OCCURRENCES_CATEGORY_TODAY = `today`;
export const MEETING_OCCURRENCES_CATEGORY_UPCOMING = 'next-meeting';
export const MEETING_OCCURRENCES_CATEGORY_PAST = 'past-meetings';

export const MEETING_OCCURRENCES_CATEGORY_TITLE = {
    occurrencesToday: `Today`,
    occurrencesUpNext: `Up next`,
    occurrencesUpcoming: 'In the future',
    occurrencesPast: 'In the past',
};
export const OCCURRENCE_ROW_COUNT_INITIAL = {
    occurrencesToday: -1,
    occurrencesUpcoming: 0,
    occurrencesUpNext: 1,
    occurrencesPast: 5,
};
export const OCCURRENCE_ROW_COUNT_INCREMENT = {
    occurrencesUpcoming: 2,
    occurrencesPast: 5,
    dashboard: 20,
};
export const OCCURRENCE_UPCOMING_ROW_COUNT_INITIAL = 1;
export const OCCURRENCE_UPCOMING_ROW_COUNT_INCREMENT = 5;
export const OCCURRENCE_PAST_ROW_COUNT_INITIAL = 50;
export const OCCURRENCE_PAST_ROW_COUNT_INCREMENT = 50;
export const OCCURRENCE_HAS_MORE_BUTTON_POSITION = {
    occurrencesToday: 'top',
    occurrencesUpNext: 'top',
    occurrencesUpcoming: 'top',
    occurrencesPast: 'bottom',
};

export const MEETING_TYPE_ARCHIVED = 'archived';
export const MEETING_TYPE_SINGLE = 'single';
export const MEETING_TYPE_REPEATING = 'repeating';

export const MEETING_EDIT_KEY_ATTENDEES = 'attendees';
export const MEETING_EDIT_KEY_LOCATION = 'location';
export const MEETING_EDIT_KEY_SCHEDULE = 'schedule';
export const MEETING_EDIT_KEY_ACTUAL = 'actual';

export const EVENT_TYPE_HIDDEN = 'hidden';
export const EVENT_TYPE_LINKED = 'linked';

export const SEARCH_PARAM_FILTER_ACTIONS = 'a';
export const SEARCH_PARAM_FILTER_ACTIONS_BY_PRIORITY = 'p';
export const SEARCH_PARAM_FILTER_MEETINGS = 'm';
export const SEARCH_PARAM_FILTER_TAGS = 't';
export const SEARCH_PARAM_FILTER_EVENTS = 'e';
export const SEARCH_PARAM_FILTER_ACTIONS_BY_ASSIGNEE = 'u';
export const SEARCH_PARAM_FILTER_ACTIONS_BY_TERM = 'term';
export const SEARCH_PARAM_FILTER_MEETING_TYPES = 'y';
export const SEARCH_PARAM_PREV_MEETINGS_COUNT = 'p';
export const SEARCH_PARAM_MORE_MEETINGS_COUNT = 'n';
export const SEARCH_PARAM_MUST_INCLUDE_DATE = 'd';

export const TODAY_PLACEHOLDER = 'today-placeholder';
export const TODAY_ARCHIVED_PLACEHOLDER = 'today-archived-placeholder';

export const HELP_SITE_URL = window?.__minuteme_appConfig?.helpHost;
export const API_URL = window?.__minuteme_appConfig?.apiHost;

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_PRODUCTION_ENVIRONMENT =
    window?.__minuteme_appConfig?.ENVIRONMENT?.startsWith('production');
export const IS_TEST = process.env.NODE_ENV === 'test';
