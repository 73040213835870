import React from 'react';

export default ({ ...rest }) => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m16.593 23.69c0.83 0 1.545-0.095 2.145-0.285s1.09-0.455 1.47-0.795c0.39-0.35 0.675-0.76 0.855-1.23 0.18-0.48 0.27-1.005 0.27-1.575 0-1.11-0.395-1.985-1.185-2.625-0.79-0.65-1.975-0.975-3.555-0.975h-4.65v7.485h4.65zm-4.65-16.89v7.335h3.84c0.82 0 1.53-0.09 2.13-0.27 0.61-0.18 1.11-0.43 1.5-0.75 0.4-0.32 0.695-0.705 0.885-1.155 0.19-0.46 0.285-0.96 0.285-1.5 0-1.27-0.38-2.195-1.14-2.775-0.76-0.59-1.94-0.885-3.54-0.885h-3.96zm3.96-2.295c1.32 0 2.455 0.13 3.405 0.39 0.96 0.26 1.745 0.63 2.355 1.11 0.62 0.48 1.075 1.07 1.365 1.77 0.29 0.69 0.435 1.47 0.435 2.34 0 0.53-0.085 1.04-0.255 1.53-0.16 0.48-0.405 0.93-0.735 1.35s-0.75 0.8-1.26 1.14c-0.5 0.33-1.09 0.6-1.77 0.81 1.58 0.3 2.765 0.865 3.555 1.695 0.8 0.82 1.2 1.9 1.2 3.24 0 0.91-0.17 1.74-0.51 2.49-0.33 0.75-0.82 1.395-1.47 1.935-0.64 0.54-1.43 0.96-2.37 1.26-0.94 0.29-2.01 0.435-3.21 0.435h-7.59v-21.495h6.855z"
            fill="currentColor"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
            strokeWidth=".5"
        />
    </svg>
);
