import tw, { css, styled } from 'twin.macro';
import { TopBarMenu } from './TopBarMenu.js';
import { zIndex } from '~common/style.helpers';
import { useUI, useDashboardMeta } from '~hooks';

const Container = styled.div(({ isTopBarOpen, isModal }) => [
    tw`z-40 w-full h-full top-0 inset-x-0 absolute`,
    !isTopBarOpen && tw`pointer-events-none`, // Make content unselectable while closed,
    isModal && tw`overflow-hidden`,
    css({ zIndex: zIndex('TOPBAR') }),
]);

const Label = tw.div`truncate pr-2`;

const TopBar = ({ pageTitle, children, isModal }) => {
    const { isTopBarOpen, setIsTopBarOpen } = useUI();
    const meta = useDashboardMeta();
    const label = <Label>{pageTitle || meta?.title || 'Sections'}</Label>;

    return (
        <Container {...{ isTopBarOpen, isModal }}>
            <TopBarMenu
                {...{ label, isModal }}
                isOpen={isTopBarOpen}
                onClick={() => setIsTopBarOpen((o) => !o)}
            >
                {children}
            </TopBarMenu>
        </Container>
    );
};

export { TopBar };
