import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m16 5.7307v3.7693h4.0833c0.8076 0 1.5971-0.22106 2.2686-0.63523 0.6715-0.41418 1.1949-1.0028 1.5039-1.6916 0.3091-0.68874 0.39-1.4466 0.2324-2.1778-0.1576-0.73116-0.5465-1.4028-1.1175-1.9299-0.5711-0.52714-1.2987-0.88613-2.0908-1.0316-0.792-0.14544-1.6131-0.0708-2.3592 0.21449-0.7461 0.28528-1.3839 0.7684-1.8325 1.3882-0.4487 0.61985-0.6882 1.3486-0.6882 2.0941z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="m16 5.7307v3.7693h-4.0834c-0.8076 0-1.597-0.22112-2.2686-0.63529-0.6715-0.41417-1.1949-1.0028-1.5039-1.6916-0.30905-0.68874-0.38992-1.4466-0.23236-2.1778 0.15755-0.73116 0.54645-1.4028 1.1175-1.9299 0.57106-0.52714 1.2986-0.88613 2.0907-1.0316 0.7921-0.14543 1.6131-0.07079 2.3593 0.2145 0.7461 0.28528 1.3838 0.7684 1.8325 1.3882s0.6882 1.3486 0.6882 2.0941z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
            strokeMiterlimit="10"
        />
        <path
            d="m16.004 9.0005v21"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m2 11.5c0-1.1046 0.89543-2 2-2h24c1.1046 0 2 0.8954 2 2v3.5c0 1.1046-0.8954 2-2 2h-24c-1.1046 0-2-0.8954-2-2v-3.5z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m4.5 17v11c0 1.1046 0.89543 2 2 2h19c1.1046 0 2-0.8954 2-2v-11"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
