import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m7 25h-1c-2.2091 0-4-1.7909-4-4v-8c0-2.2091 1.7909-4 4-4h20c2.2091 0 4 1.7909 4 4v8c0 2.2091-1.7909 4-4 4h-1m-3-11h3m-18 5.9333v6.0667c0 2.2091 1.7909 4 4 4h10c2.2091 0 4-1.7909 4-4v-6.0667h-18zm18-10.933v-3c0-2.2091-1.7909-4-4-4h-10c-2.2091 0-4 1.7909-4 4v3h18z"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
