import React from 'react';

const PRIVATE_ID = 'shape-private';

const PrivateIconInner = ({
    iconThick = false,
}: {
    iconThick?: boolean;
}): JSX.Element => (
    <>
        <path
            d="m15.833 7.5998c-6.3333 0-10.694 5.4888-12.083 8.2334 1.3889 2.7444 5.75 8.2333 12.083 8.2333 7.0834 0 10.833-5.2 12.5-8.2333-1.6666-3.0333-5.4166-8.2334-12.5-8.2334z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m21.334 15.9c0 2.7614-2.2386 5-5 5s-5-2.2386-5-5 2.2386-5 5-5 5 2.2386 5 5z"
            fill="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <line
            transform="matrix(.77104 .63679 -.60688 .79479 .8335 5)"
            x1="1"
            x2="35.747"
            y1="-1"
            y2="-1"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </>
);

const PrivateIcon = ({
    iconThick = false,
    isSymbolRef = false,
    title = '',
    ...rest
}): JSX.Element => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        {isSymbolRef ? (
            <use href={`#${PRIVATE_ID}`} xlinkHref={`#${PRIVATE_ID}`} />
        ) : (
            <PrivateIconInner iconThick={iconThick} />
        )}
    </svg>
);

export { PrivateIcon as default, PrivateIconInner, PRIVATE_ID };
