import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 15 11"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M1 5.73684L4.71429 10L14 1"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
