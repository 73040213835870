import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <circle
            cx="16"
            cy="16"
            r="14.059"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <line
            x1="11.296"
            x2="21.714"
            y1="10.824"
            y2="21.241"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
        />
        <line
            x1="10.446"
            x2="20.863"
            y1="21.241"
            y2="10.823"
            strokeLinecap="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
