import React from 'react';

export default ({ iconThick = false }) => (
    <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="6.5" cy="5.5" r="1.5" fill="white" />
        <path
            d="M11 5.85715L6.5 2L2 5.85715"
            stroke="white"
            strokeWidth={iconThick ? '4' : '3'}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11 5.85715L6.5 2L2 5.85715"
            stroke="#2D94D6"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11 11L6.5 7.14286L2 11"
            stroke="white"
            strokeWidth={iconThick ? '4' : '3'}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11 11L6.5 7.14286L2 11"
            stroke="#2D94D6"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
