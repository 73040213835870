import React from 'react';
import tw, { css, GlobalStyles } from 'twin.macro';
import { Global } from '@emotion/react';
import './base.scss';
import { zIndex } from '~common/style.helpers';

const Globals = css`
    html {
        ${tw`bg-black`}
        scroll-behavior: smooth; /* Enable smooth scrolling */
        -webkit-tap-highlight-color: rgba(
            45,
            148,
            214,
            0.1
        ); /* Set tap color in chrome/edge */
    }

    body {
        ${tw`text-body p-0`}
    }

    ::selection {
        ${tw`bg-black text-white`}
    }

    /* Required for iOS */
    [contenteditable] {
        -webkit-user-select: text;
        user-select: text;
    }

    svg {
        width: 1em;
        height: 1em;
    }

    #app {
        min-height: 100vh;
    }

    /* Sync placeholder visuals in Firefox */
    input::-moz-placeholder,
    input::-moz-placeholder {
        opacity: 1;
    }
    /* Visually truncate placeholder text */
    input:placeholder-shown {
        text-overflow: ellipsis;
    }

    /* For Google File Picker */
    .picker.picker-dialog,
    .picker.picker-dialog-bg {
        z-index: ${zIndex('FILE_PICKER')};
    }
    .picker.picker-dialog-bg {
        ${tw`bg-black opacity-50`}
    }
`;

export default () => (
    <>
        <Global styles={Globals} />
        <GlobalStyles />
    </>
);
