import _omit from 'lodash/omit.js';
import _uniqBy from 'lodash/uniqBy.js';
import {
    FETCH_USERPROFILE_SUCCESS,
    UPDATE_USERPROFILE_SUCCESS,
    UPDATE_USERPROFILE_WEBSOCKET,
    UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
    UPDATE_USERPROFILE_PREFERENCE_APP,
    UPDATE_USERPROFILE_PREFERENCE_NOTIFICATION,
    LOGOUT_SUCCESS,
    FETCH_ALL_MY_MEETING_USERS_SUCCESS,
    CSRF_RETRIEVED,
} from '../../common/action.types.js';

const initalState = {
    email: '',
    displayName: '',
    givenName: '',
    surname: '',
    id: '',
    signinCount: 0,
    timezone: '',
    loginAuthSource: '',
    emailDomainMeta: {},
    csrf: '',
};

const userReducer = (state = initalState, action) => {
    switch (action.type) {
        case UPDATE_USERPROFILE_WEBSOCKET:
        case UPDATE_USERPROFILE_SUCCESS:
            return {
                ...state,
                ...action.userProfile,
            };

        case FETCH_USERPROFILE_SUCCESS:
            return {
                ...state,
                ...action.userProfile,
                loginAuthSource: action?.userProfile?.authSource,
            };

        case UPDATE_USERPROFILE_PREFERENCE_APP:
            return {
                ...state,
                appPreferences: {
                    ...state.appPreferences,
                    ..._omit(action, ['type']),
                },
            };

        case UPDATE_USERPROFILE_PREFERENCE_NOTIFICATION:
            return {
                ...state,
                notificationPreferences: {
                    ...state.notificationPreferences,
                    ..._omit(action, ['type']),
                },
            };

        case CSRF_RETRIEVED: {
            return {
                ...state,
                csrf: action.token,
            };
        }
        case LOGOUT_SUCCESS:
            return initalState;

        default:
            return state;
    }
};

const initalUserListState = {
    users: [],
};

const userListReducer = (state = initalUserListState, action) => {
    switch (action.type) {
        case FETCH_ALL_MY_MEETING_USERS_SUCCESS:
            return {
                ...state,
                users: _uniqBy([...state.users, ...action.page.docs], 'id'),
            };

        case UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET:
        case UPDATE_USERPROFILE_WEBSOCKET:
            return {
                ...state,
                users: state.users.map((u) =>
                    u.id !== action.userProfile.id
                        ? u
                        : { ...u, ...action.userProfile }
                ),
            };

        case LOGOUT_SUCCESS:
            return initalUserListState;

        default:
            return state;
    }
};

export default userReducer;
export { userListReducer };
