import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isJPEGOrPNG, isPdf } from '~common/document.helpers';
import { getDocumentsForOccurrenceSelector } from '~modules/documents/document.selectors';
import { getMinuteItemFromStoreByDocumentId } from '~modules/minutes/minutes.selectors';

const useHasSupportedInlineDocuments = (occurrence) => {
    const documentsForOccurrence = useSelector((state) =>
        getDocumentsForOccurrenceSelector(state, {
            occurrence,
        })
    );

    const hasSupportedInlineDocuments = useMemo(
        () =>
            documentsForOccurrence
                .filter(
                    (d) =>
                        !d.file.minutes &&
                        !d.file.agenda &&
                        ((isPdf(d.file.filename) && !d.file.isEncrypted) ||
                            isJPEGOrPNG(d.file.filename))
                )
                .map((d) => {
                    const minuteItem = getMinuteItemFromStoreByDocumentId(d.id);
                    return {
                        ...d,
                        minuteItem: minuteItem?.id,
                    };
                })
                .some((d) => !!d.minuteItem),
        [documentsForOccurrence]
    );

    return hasSupportedInlineDocuments;
};

export { useHasSupportedInlineDocuments };
