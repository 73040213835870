import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M2 24H12.1951M2 6H21M2 15H21"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M16 21L20.2 27L30 16"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
