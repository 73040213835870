import { AUTHSOURCE_AZUREAD, AUTHSOURCE_GOOGLE } from '~common/constants';
import { getFilesScopes } from '~shared/integration/integration.helper';
import { INTEGRATION_AUTHSOURCE_TO_FILES_FEATURE_MAP } from '~shared/constants';

export const getFilesConfig = ({ authSource }) => {
    const featureName = INTEGRATION_AUTHSOURCE_TO_FILES_FEATURE_MAP[authSource];
    switch (authSource) {
        case AUTHSOURCE_AZUREAD: {
            return {
                shortName: 'OneDrive',
                longName: 'OneDrive',
                source: 'Microsoft',
                scopes: getFilesScopes(authSource),
                featureName,
                allowPersonalAccounts: false,
            };
        }
        case AUTHSOURCE_GOOGLE: {
            return {
                shortName: 'Google Drive',
                longName: 'Google Drive',
                source: 'Google',
                scopes: getFilesScopes(authSource),
                featureName,
                allowPersonalAccounts: true, // TBC
            };
        }
        default: {
            return {};
        }
    }
};
