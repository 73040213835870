import React from 'react';
import tw, { styled } from 'twin.macro';
import { PERMISSION_LEVEL_ADMIN } from '~common/constants';
import { CheckRadioBase } from '~modules/common/form-elements';
import { UserAvatar } from '~modules/user/UserAvatar';

const Container = tw.div`flex items-center justify-between select-none overflow-hidden pl-4 pr-9 py-2 cursor-pointer hocus:(bg-solid text-body-dark)`;
const Checkbox = styled(CheckRadioBase)`
    & + div {
        ${tw`border-none text-primary`}
        ${({ hasUncheckedStatus }) => hasUncheckedStatus && tw`text-icon`}
    }
`;
const UserText = tw.div`pl-2 pr-4 flex flex-col truncate`;
const Username = tw.div`truncate text-left`;
const Status = tw.span`text-left capitalize text-body-light text-sm`;
const AvatarNameWrap = tw.div`flex items-center space-x-1 w-full max-width[calc(100% - 20px)]`; // MaxWidth prevents checkbox from being pushed off screen

const AttendeeDropdownOption = ({ attendee, showGrantLevel }) => (
    <Container label={attendee.derivedDisplayName}>
        <AvatarNameWrap>
            <UserAvatar
                user={attendee.user}
                size="small"
                hasAdminIndicator={
                    attendee?.grantLevel === PERMISSION_LEVEL_ADMIN
                }
                enableTooltip={false}
            />
            <UserText>
                <Username>{attendee.derivedDisplayName}</Username>
                {showGrantLevel && <Status>{attendee.grantLevel}</Status>}
            </UserText>
        </AvatarNameWrap>
        <Checkbox
            tw="px-2"
            type="checkbox"
            checked={attendee.isAttendee}
            onChange={() => {}}
            hasUncheckedStatus={attendee.isAbsent}
        />
    </Container>
);

export default AttendeeDropdownOption;
