import React from 'react';

export default ({ iconThick = false, title = '', ...rest }) => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="M20 2V7.46666C20 8.57123 20.8954 9.46667 22 9.46667H27M20 2H7C5.89543 2 5 2.89543 5 4V28C5 29.1046 5.89543 30 7 30H25C26.1046 30 27 29.1046 27 28V9.46667M20 2L27 9.46667"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 10L12.5714 10L15.1429 10M10 15.5L22 15.5M10 21L22 21"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
