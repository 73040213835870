import _uniq from 'lodash/uniq.js';
import {
    PAGEVIEW_PAGESTATE_UPDATE,
    PAGEVIEW_PAGESTATE_UPDATE_ROWCOUNT,
    PAGEVIEW_DASHBOARDSTATE_UPDATE,
    UPDATE_USERPROFILE_PREFERENCE_APP,
    USER_CLEAR_ACTIVE,
} from '~common/action.types';
import { publish as wsPublish } from '~client/websocket/subscriptions';
import { viewOccurrence } from './pageView.api.js';
import { loadMeetingPermissions } from '~modules/meeting/meeting.actions';
import {
    loadActivityCount,
    loadOccurrencePermissions,
} from '~modules/occurrence/occurrence.actions';
import {
    loadWorkspacePermissions,
    loadWorkspaceInvoices,
} from '~modules/workspace/workspace.actions';
import { getOccurrenceSelector } from '~modules/occurrence/occurrence.selectors';
import { getDefaultLockedStatus } from '~modules/occurrence/occurrence.helpers';
import { getWorkspaceAccessLevel } from '~modules/permission/permission.helpers';
import {
    OCCURRENCE_ROW_COUNT_INCREMENT,
    DOCUMENT_VISIBILITY_VISIBLE,
    DOCUMENT_VISIBILITY_HIDDEN,
} from '~common/constants';
import { getPageState, getDashboardState } from './pageView.selectors.js';
import { getMeetingSelector } from '../meeting/meeting.selectors.js';
import {
    getMinuteItemFromStoreById,
    getMinuteItemFromStoreByOccurrenceAndAgendaItemId,
} from '~modules/minutes/minutes.pure.selectors';
import { getIsDocumentVisible } from '~common/utils';
import { verifyFileLinkForMeeting } from '~modules/remoteFiles/remoteFiles.actions.js';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line unicorn/consistent-function-scoping
const leaveDetailPage = () => (dispatch) => {
    // person has left a meeting page
    wsPublish(`client:user:page:leave`);

    dispatch({
        type: USER_CLEAR_ACTIVE,
    });
};

export const updatePageViewState = (pageState) => ({
    type: PAGEVIEW_PAGESTATE_UPDATE,
    pageState,
});

export const updateDashboardState = (dashboard, dashboardState) => ({
    type: PAGEVIEW_DASHBOARDSTATE_UPDATE,
    dashboard,
    dashboardState,
});

export const clearMyHomepageIfContainsId = (id) => (dispatch, getState) => {
    if (!id) return;
    const state = getState();
    const {
        user,
        pageView: { dashboardState },
    } = state;

    for (const [key, value] of Object.entries(dashboardState)) {
        if (!value?.lastViewedPath?.includes(id)) continue;

        dispatch(
            updateDashboardState(key, {
                ...value,
                lastViewedPath: undefined,
                search: undefined,
            })
        );
    }

    if (user?.appPreferences?.myHomepage?.includes(id)) {
        dispatch({
            type: UPDATE_USERPROFILE_PREFERENCE_APP,
            myHomepage: null,
        });
    }
};

/**
 * Show more rows in the occurrence sidebar list. This will update the redux store
 * with the number of rows to display.
 * Row count is stored against the meeting pageState, so regardless of which occurrence you are
 * viewing the number of rows displayed remains the same.
 * @param {Object} params Object containing items to update.
 * @param {Object} params.occurrence Occurrence object currently being viewed.
 * @param {string} params.groupType whether today, upcoming or past.
 * @param {number} params.displayCount current number of rows displayed.
 */
export function showMoreOccurrenceSidebarRows({
    occurrence,
    groupType,
    displayCount,
}) {
    return (dispatch) =>
        dispatch({
            type: PAGEVIEW_PAGESTATE_UPDATE_ROWCOUNT,
            id: occurrence.meeting,
            groupType,
            displayCount,
            addRows: OCCURRENCE_ROW_COUNT_INCREMENT[groupType],
        });
}

/**
 * Show less rows in the occurrence sidebar list. This will update the redux store
 * with the number of rows to display.
 * Row count is stored against the meeting pageState, so regardless of which occurrence you are
 * viewing the number of rows displayed remains the same.
 * @param {Object} params Object containing items to update.
 * @param {Object} params.occurrence Occurrence object currently being viewed.
 * @param {string} params.groupType whether today, upcoming or past.
 * @param {number} params.displayCount current number of rows displayed.
 */
export function showLessOccurrenceSidebarRows({
    occurrence,
    groupType,
    displayCount,
}) {
    return (dispatch) =>
        dispatch({
            type: PAGEVIEW_PAGESTATE_UPDATE_ROWCOUNT,
            id: occurrence.meeting,
            groupType,
            displayCount,
            addRows: OCCURRENCE_ROW_COUNT_INCREMENT[groupType] * -1,
        });
}

export function userViewingDashboardPage({
    dashboardPage,
    pathname,
    search,
    previousDetailPage,
}) {
    return (dispatch) => {
        const {
            dashName: secondLevelDashboard,
            dashId: groupId,
            dashNameAlt: firstLevelDashboard,
        } = dashboardPage;
        if (secondLevelDashboard) {
            // this is a Category page
            dispatch(
                updateDashboardState(secondLevelDashboard, {
                    lastViewedPath: pathname,
                    search,
                })
            );
            dispatch(
                updateDashboardState(`${secondLevelDashboard}-${groupId}`, {
                    pathname,
                    search,
                })
            );
        } else {
            dispatch(
                updateDashboardState(firstLevelDashboard, {
                    pathname,
                    search,
                    lastViewedPath: pathname,
                })
            );
        }
        previousDetailPage && dispatch(leaveDetailPage());
    };
}

export const userViewingOccurrencePage =
    ({ detailPage, locationState, previousDetailPage }) =>
    (dispatch, getState) => {
        const loadPage = () => {
            const state = getState();

            // pageState is populated if user has visited this page before, in this session.
            const pageState = getPageState(state, detailPage.id);
            const occurrence = getOccurrenceSelector(state, {
                occurrence: detailPage.id,
            });

            const meeting = occurrence.id
                ? { id: occurrence.meeting }
                : getMeetingSelector(state, { meeting: detailPage.id });

            const isDisabledDueToPastMeeting = meeting.isIntro
                ? false
                : pageState?.isDisabledDueToPastMeeting ??
                  getDefaultLockedStatus(occurrence);

            dispatch({
                type: PAGEVIEW_PAGESTATE_UPDATE,
                pageState: {
                    ...detailPage,
                    occurrence: occurrence.id,
                    meeting: meeting.id,
                    isDisabledDueToPastMeeting,
                    ...locationState,
                },
            });

            const { minuteItemId, actionItems, documents } = detailPage;
            let minuteItemIds;
            if (minuteItemId) {
                // send through the minuteItemId and agendaItemId... in case the minutes have not been persisted yet
                const minuteItem =
                    getMinuteItemFromStoreById(minuteItemId) ||
                    getMinuteItemFromStoreByOccurrenceAndAgendaItemId(
                        occurrence,
                        minuteItemId
                    );
                minuteItemIds = _uniq(
                    [
                        minuteItemId,
                        minuteItem?.id,
                        minuteItem?.agendaItem,
                    ].filter(Boolean)
                );
            }

            let hasUserJoinedaMeeting = false;
            if (previousDetailPage) {
                // previous page was a detail page
                if (previousDetailPage.id !== detailPage.id) {
                    // has changed to a different meeting, so send leave for previous and join for this meeting.
                    hasUserJoinedaMeeting = true;
                } else {
                    // on a detail page, previous page was a detail page, but it's same occurrence.
                    // no further action required other than send a websocket event to let other users know you've changed to a different page.
                    const {
                        minuteItemId: previousMinuteItemId,
                        actionItems: previousActionItems,
                        documents: previousDocuments,
                    } = previousDetailPage;

                    if (
                        [
                            minuteItemId !== previousMinuteItemId,
                            !previousActionItems && actionItems,
                            !previousDocuments && documents,
                        ].some(Boolean)
                    ) {
                        wsPublish(`client:user:page:topic:change`, {
                            activePage: {
                                objectType: 'occurrence',
                                state: {
                                    occurrence: occurrence.id,
                                    meeting: occurrence.meeting,
                                    minuteItemIds,
                                },
                            },
                        });
                    }
                }
            } else {
                // previous page was not a meeting page. user has joined meeting
                hasUserJoinedaMeeting = true;
            }

            if (hasUserJoinedaMeeting) {
                const meetingPageState = getPageState(state, meeting.id);
                !meetingPageState?.permissions?.length &&
                    dispatch(loadMeetingPermissions(meeting));

                // Verify user access to the meeting.fileLink when the user has changed to a different meeting
                (!previousDetailPage ||
                    getPageState(state, previousDetailPage.id)?.meeting !==
                        meeting.id) &&
                    dispatch(verifyFileLinkForMeeting(meeting));

                dispatch({
                    type: USER_CLEAR_ACTIVE,
                });
                const isVisible = getIsDocumentVisible();
                if (occurrence.id) {
                    dispatch(loadOccurrencePermissions(occurrence));
                    dispatch(loadActivityCount(occurrence));
                    wsPublish(`client:user:page:join`, {
                        activePage: {
                            objectType: 'occurrence',
                            state: {
                                occurrence: occurrence.id,
                                meeting: occurrence.meeting,
                                minuteItemIds,
                                visibility: isVisible
                                    ? DOCUMENT_VISIBILITY_VISIBLE
                                    : DOCUMENT_VISIBILITY_HIDDEN,
                            },
                        },
                    });
                    isVisible &&
                        viewOccurrence(detailPage.id).catch(() => {
                            // noop
                        });
                }
            }
        };

        // GOTCHA. If user loads MinuteMe on a detail page, data needs to be loaded before the pageView can be triggered.
        const triggerLoadPage = () => {
            const preloadFinished = getState().preload.percent === 100;
            if (preloadFinished) {
                loadPage();
            } else {
                setTimeout(() => triggerLoadPage(), 500);
            }
        };

        triggerLoadPage();
    };

export const userViewingWorkspacePage =
    ({ workspacePage, pathname, search, previousDetailPage }) =>
    (dispatch, getState) => {
        const loadPage = () => {
            if (workspacePage[1]) {
                const workspaceId = workspacePage[2];
                const dashboard = `${workspacePage[1]}-${workspaceId}`;
                const dashboardState = getDashboardState(dashboard);
                const workspaceAccessLevel =
                    getWorkspaceAccessLevel(workspaceId);

                // Avoid going any further when user has no permission for this workspace
                if (!workspaceAccessLevel) return;

                dispatch(
                    updateDashboardState(dashboard, {
                        pathname,
                        search,
                        isLoading: !dashboardState?.members,
                    })
                );

                // Load the invoices if they haven't been loaded yet for this workspace
                workspaceAccessLevel.isOwner &&
                    !dashboardState?.invoices &&
                    dispatch(loadWorkspaceInvoices(workspaceId));

                // this is a workspace detail page
                // load the permissions if they've not previously been loaded, and if the user is a workspace member
                !dashboardState?.members &&
                    workspaceAccessLevel.canUpdate &&
                    dispatch(loadWorkspacePermissions(workspaceId));

                // dispatch that we've joined the workspace, to get websocket events for it
                wsPublish(`client:user:workspace:join`, { workspaceId });
            } else {
                // this is /workspaces dashboard
                dispatch(
                    updateDashboardState(workspacePage[3], {
                        pathname,
                        search,
                    })
                );
            }
        };

        // GOTCHA. If user loads MinuteMe on a detail page, data needs to be loaded before the pageView can be triggered.
        const triggerLoadPage = () => {
            const preloadFinished = getState().preload.percent === 100;
            if (preloadFinished) {
                previousDetailPage && dispatch(leaveDetailPage());
                loadPage();
            } else {
                setTimeout(() => triggerLoadPage(), 500);
            }
        };

        triggerLoadPage();
    };
