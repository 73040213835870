import React from 'react';
import tw, { styled } from 'twin.macro';
import { Dimmer } from 'semantic-ui-react';
import 'semantic-ui-css/components/dimmer.css';

const StyledDimmer = styled(Dimmer)`
    ${tw`overflow-hidden`}
    &.ui.dimmer.bgtrans {
        ${tw`bg-transparent`}
    }
`;

const DimmerSimple = ({ children, hasTransparentBg, ...rest }) => (
    <StyledDimmer
        className={hasTransparentBg ? 'bgtrans' : null}
        {...rest}
        inverted
    >
        {children}
    </StyledDimmer>
);

export default DimmerSimple;
