// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import * as rules from './formvalidation.helper.js';

const validate = (values) => {
    const errors = {};
    errors.displayName = rules.required(values.displayName);
    errors.givenName = rules.required(values.givenName);
    errors.surname = rules.required(values.surname);
    errors.timezone = rules.required(values.timezone);

    return _.pickBy(errors, (x) => x !== undefined);
};

export const processBodyErrors = (errors) => {
    let errorFields = {};
    if (errors && Object.keys(errors).length) {
        errorFields = rules.getTopLevelErrors(errors);

        const overrides = [];
        const overridesErrors = new RegExp(/(overrides.)/);
        Object.keys(errors)
            .filter((field) => field.match(overridesErrors))
            .forEach((field) => {
                const path = field.split('.');
                overrides[path[1]] = {};
                overrides[path[1]][path[2]] = errors[field];
            });
        if (overrides.length > 0) {
            errorFields.overrides = overrides;
        }
    }
    return errorFields;
};

export default validate;
