import React from 'react';
import tw, { styled } from 'twin.macro';
import { useSelector } from 'react-redux';
import _upperFirst from 'lodash/upperFirst.js';
import {
    useEventFromRemote,
    useRemoteCalendarMeetingLink,
    useMeetingById,
    useOccurrenceCalendarLinks,
    useIntegrationProfileByAuthSource,
} from '~hooks';
import { Tooltip } from '~modules/common/components';
import { getCRMCustomers } from '~modules/remoteCRM/remoteCRM.selectors';
import { getWorkspaceGroupByMeetingId } from '~modules/group/group.selectors';
import { getIntegrationMeta } from '~modules/integration/integration.helpers';
import RecurringIcon from '~icons/recurring';
import ArchiveIcon from '~icons/archive';
import PrivateMeetingIcon from '~icons/private';
import ExternalLinkIcon from '~icons/externalLink';
import type { IntegrationMeta } from '~modules/integration/integration.helpers';
import { labelDisplay } from '~modules/common/components/MeetingDetail/Header/utils';

const IconContainer = tw.div`flex ml-2 space-x-1.5`;

type IconWrapProps = { isActive?: boolean };

const IconWrap = styled.span(({ isActive }: IconWrapProps) => [
    tw`text-icon hover:text-icon-dark`,
    isActive && tw`text-primary-highlight hover:text-primary-highlight/70`,
]);

type LayoutProps = {
    tooltipProps: {
        content: string | JSX.Element;
        position?: string;
        hoverable?: boolean;
        wide?: boolean;
        offset?: [number, number];
    };
    children: React.ReactNode;
} & IconWrapProps;

const Layout = ({
    tooltipProps,
    isActive,
    children,
}: LayoutProps): JSX.Element => (
    <Tooltip {...tooltipProps} tw="select-none">
        <IconWrap {...{ isActive }}>{children}</IconWrap>
    </Tooltip>
);

const RepeatingIcon = ({
    recurrenceText,
}: {
    recurrenceText?: string;
}): JSX.Element => (
    <Layout
        tooltipProps={{
            content:
                (recurrenceText && _upperFirst(recurrenceText)) ||
                'Repeating meeting',
        }}
    >
        <RecurringIcon isSymbolRef />
    </Layout>
);

const ArchivedIcon = ({ isSeries }: any): JSX.Element => (
    <Layout
        tooltipProps={{
            content: `Archived ${isSeries ? 'series' : 'meeting'}`,
        }}
    >
        <ArchiveIcon isSymbolRef />
    </Layout>
);

const PrivateIcon = ({ isSeries = false }): JSX.Element => (
    <Layout
        tooltipProps={{
            content: isSeries
                ? 'By default, meetings in this series are only visible to you. Individual meetings in this series may have their own permissions that provide access to others.'
                : 'This meeting is only visible to you',
            position: 'bottom left',
            offset: [-16, 0],
        }}
    >
        <PrivateMeetingIcon isSymbolRef />
    </Layout>
);

const RemoteCalendarIconForCreator = ({
    remoteLink,
    event,
}: {
    remoteLink: { authSource: IntegrationMeta['authSource']; link: string };
    event: { calendarType: IntegrationMeta['authSource']; webLink: string };
}): JSX.Element | null => {
    const authSource = remoteLink?.authSource || event?.calendarType;
    const hrefLink = remoteLink?.link || event?.webLink;
    const calendarProfile = useIntegrationProfileByAuthSource(authSource);

    const {
        IconMuted: CalendarIcon,
        title,
        linkUrl,
    } = getIntegrationMeta(authSource) || {
        linkUrl: () => '',
    };
    if (!CalendarIcon) return null;

    const eventStatus = remoteLink ? 'linked' : 'unlinked';
    const content = (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            tw="w-44 block"
            onClick={(e): void => {
                // Avoid triggering the underlying meeting link
                e.stopPropagation();
            }}
        >
            View {eventStatus} event in{' '}
            <a
                href={linkUrl(calendarProfile, hrefLink) || ''}
                target="_blank"
                rel="noreferrer"
                className="group"
                tw="(text-body-dark hocus:text-primary)"
            >
                <span tw="whitespace-nowrap">
                    {title}
                    <ExternalLinkIcon tw="(text-icon group-hocus:text-primary) inline-block -mt-1 ml-1 text-xs" />
                </span>
            </a>
        </div>
    );

    return (
        <Layout tooltipProps={{ content, hoverable: true }}>
            <CalendarIcon isSymbolRef />
        </Layout>
    );
};

const RemoteCalendarIconForNonCreator = ({
    id,
}: {
    id: string;
}): JSX.Element | null => {
    const eventCalendarLinks = useOccurrenceCalendarLinks(id);

    const links = eventCalendarLinks();
    if (links.length === 0) return null;

    const usersLinked = labelDisplay({
        data: links,
        fieldName: 'givenName',
        maxDisplay: 2,
        hasDividerAlternative: true,
    });

    const { IconMuted: CalendarIcon, title } =
        getIntegrationMeta(links[0].authSource) || {};
    if (!CalendarIcon) return null;

    const content = `${usersLinked} linked this ${title} event`;

    return (
        <Layout tooltipProps={{ content, wide: true }}>
            <CalendarIcon isSymbolRef />
        </Layout>
    );
};

const RemoteCalendarIcon = ({ id }: { id: string }): JSX.Element | null => {
    const remoteLink = useRemoteCalendarMeetingLink(id);
    const event = useEventFromRemote(remoteLink?.remoteId || id);

    if (!event && !remoteLink)
        return <RemoteCalendarIconForNonCreator {...{ id }} />;

    return <RemoteCalendarIconForCreator {...{ event, remoteLink }} />;
};

type RemoteCRMIconProps = {
    meetingId: string;
    children: React.ReactNode;
    topic?: { crmLink?: { remoteId?: string; authSource?: string } };
    isActive?: boolean;
    isActionItem?: boolean;
};

const RemoteCRMIcon = ({
    isActive,
    isActionItem,
    topic,
    meetingId,
    children,
}: RemoteCRMIconProps): JSX.Element | null => {
    const meeting = useMeetingById(meetingId);

    const {
        authSources,
        crmData,
    }: {
        authSources: string[];
        crmData: { displayTitle?: string; remoteId: string }[];
    } = useSelector((state) =>
        getCRMCustomers(state, {
            workspace: getWorkspaceGroupByMeetingId({
                meeting: meetingId,
            }),
        })
    );

    const hasLinkedCRMseries = Boolean(meeting?.crmLinks?.remoteId);
    const hasLinkedCRMtopic = Boolean(topic?.crmLink?.remoteId);
    const isMeetingTopic = Boolean(topic);

    if (
        // Workspace is not linked with a CRM
        authSources.length === 0 ||
        // Never render for a component with no linked series or topic
        (!hasLinkedCRMseries && !hasLinkedCRMtopic) ||
        // Never render on topic component for a linked series
        (!isActionItem && isMeetingTopic && hasLinkedCRMseries)
    )
        return null;

    const { IconMuted: CrmIcon, title } =
        getIntegrationMeta(
            topic?.crmLink?.authSource || meeting?.crmLinks?.authSource
        ) || {};
    if (!CrmIcon) return null;

    const tooltipTitle = crmData.find(
        (item) =>
            item.remoteId ===
            (topic?.crmLink?.remoteId || meeting?.crmLinks?.remoteId)
    )?.displayTitle;

    const content = (
        <>
            Linked with{' '}
            {isActionItem || isMeetingTopic ? (
                <strong>“{tooltipTitle}”</strong>
            ) : (
                title
            )}
        </>
    );

    return (
        <>
            {children}
            <Layout tooltipProps={{ content }} isActive={isActive}>
                <CrmIcon isSymbolRef />
            </Layout>
        </>
    );
};

export {
    IconContainer,
    RepeatingIcon,
    ArchivedIcon,
    PrivateIcon,
    RemoteCalendarIcon,
    RemoteCRMIcon,
};
