import { Notifier } from '@airbrake/browser';
import airbrakeMiddleware from 'redux-airbrake';
import 'regenerator-runtime/runtime'; // Prevents async transformation error

import { getStore } from './store.js';

const airbrake = new Notifier({
    projectId: window.__minuteme_appConfig?.AIRBRAKE_PROJECT_ID,
    projectKey: window.__minuteme_appConfig?.AIRBRAKE_PROJECT_KEY,
    environment: window.__minuteme_appConfig?.ENVIRONMENT,
});

airbrake.addFilter((notice) => {
    if (!window.__minuteme_appConfig?.AIRBRAKE_ENABLED) {
        return null;
    }

    notice.context.version = window.__minuteme_appConfig.VERSION;
    notice.context.revision = window.__minuteme_appConfig.REVISION;

    notice.session = getStore().getState().user;

    return notice;
});

export default airbrake;
export const errorMiddleware = airbrakeMiddleware(airbrake, {
    sendLastAction: true,
});
