import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <g
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '3.5' : '1.5'}
        >
            <path d="m5.6808 5.4922-3.7627 8.8775 8.8775 3.7627" />
            <path d="m29.32 25.002c0.1484-2.4386-0.3233-4.8751-1.3712-7.0821-1.048-2.207-2.6379-4.1125-4.6214-5.5388-1.9836-1.4263-4.2961-2.3269-6.7219-2.6178-2.4258-0.29084-4.8857 0.03755-7.1502 0.95449-2.2645 0.917-6.368 3.107-6.368 3.107" />
        </g>
    </svg>
);
