import _debounce from 'lodash/debounce.js';
import _find from 'lodash/find.js';
import _get from 'lodash/get.js';
import _range from 'lodash/range.js';
import moment from 'moment-timezone';
import ReactGA from 'react-ga4';
import {
    PRELOAD_REQUEST,
    PRELOAD_SUCCESS,
    PRELOAD_ERROR,
    PRELOAD_COMPLETE,
    PRELOAD_SWAP_LOAD_STATUS_MEETING,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_USERPERMISSIONS_SUCCESS,
    FETCH_USERPROFILE_SUCCESS,
    FETCH_MEETINGS_SUCCESS,
    FETCH_GROUPS_SUCCESS,
    FETCH_ALL_MY_MEETING_USERS_SUCCESS,
    FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS,
    FETCH_ACTION_ITEMS_SUCCESS,
    FETCH_LINKED_ACTION_ITEMS_SUCCESS,
    FETCH_DOCUMENT_LIST_SUCCESS,
    FETCH_AGENDA_ITEMS_SUCCESS,
    FETCH_MINUTES_SUCCESS,
    FETCH_PRIVATE_NOTES_SUCCESS,
    FETCH_SCHEDULED_EVENTS_SUCCESS,
    FETCH_INTEGRATION_PROFILES_SUCCESS,
    FETCH_PAGEVIEWS_SUCCESS,
    FETCH_TEMPLATES_SUCCESS,
} from '~common/action.types';
import * as apiClient from './preload.api.js';
import { updateUserProfile } from '~modules/user/user.actions';
import { onPreloadError } from '~client/actions/session';
import { guessLocale } from '~common/locale.utils';
import { getPageState } from '~modules/pageView/pageView.selectors';
import {
    loadMeetingPermissions,
    loadMeeting,
} from '~modules/meeting/meeting.actions';
import { loadOccurrencePermissions } from '~modules/occurrence/occurrence.actions';
import {
    loadRemoteCalendarEvents,
    loadRemoteCalendarLinks,
} from '~modules/remoteCalendar/remoteCalendar.actions';
import { loadCRMLinks } from '~modules/remoteCRM/remoteCRM.actions';
import history, { isDetailPage, pageViewDetails } from '~modules/navigation';
import {
    getOccurrenceFromStoreById,
    getOccurrenceSelector,
} from '../occurrence/occurrence.selectors';
import airbrake from '~common/airbrake';
import { getStore } from '../../common/store';
import { retrieveToken } from '~common/csrf.actions.js';

function fetchUserProfile(queryStringParams) {
    return function fetchUserProfile(dispatch) {
        const update = {};
        return apiClient
            .getUserProfile(queryStringParams)
            .then(({ requestId, userProfile }) => {
                ReactGA.set({ userId: userProfile.id });

                if (!userProfile.timezone) {
                    update.timezone = moment.tz.guess();
                    userProfile.timezone = update.timezone;
                }

                if (!userProfile.locale) {
                    update.locale = guessLocale(userProfile.timezone);
                    userProfile.locale = update.locale;
                }

                if (Object.keys(update).length) {
                    dispatch(updateUserProfile(userProfile.id, update));
                }

                const newSettings = {
                    ...window.intercomSettings,
                    name: userProfile.displayName,
                    user_id: userProfile.id,
                    user_hash: userProfile.intercom_user_hash,
                    email: userProfile.email,
                    timezone: userProfile.timezone,
                    locale: userProfile.locale,
                };

                if (window.Intercom) {
                    window.Intercom('boot', newSettings);
                }

                dispatch({
                    type: FETCH_USERPROFILE_SUCCESS,
                    userProfile,
                    requestId,
                });
            });
    };
}

function getFetchNotifications() {
    return function fetchNotifications(params) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line require-await
        return async () => {
            return apiClient.getNotifications(params).then((page) => {
                return {
                    type: FETCH_NOTIFICATIONS_SUCCESS,
                    page,
                };
            });
        };
    };
}

function fetchUserPermissions() {
    return function fetchUserPermissions() {
        return apiClient.getUserPermissions().then((permissions) => ({
            type: FETCH_USERPERMISSIONS_SUCCESS,
            permissions,
        }));
    };
}

function fetchScheduledEvents() {
    return function fetchScheduledEvents() {
        return apiClient.getScheduledEvents().then((events) => ({
            type: FETCH_SCHEDULED_EVENTS_SUCCESS,
            events,
        }));
    };
}

function fetchGroupsList() {
    return function fetchGroupsList() {
        return apiClient.getGroups().then((groups) => ({
            type: FETCH_GROUPS_SUCCESS,
            groups,
        }));
    };
}

function fetchMeetingsList(page) {
    return function fetchMeetingsList() {
        return apiClient.getMeetings(page).then((page) => ({
            type: FETCH_MEETINGS_SUCCESS,
            page,
        }));
    };
}
export { fetchMeetingsList as __TEST__fetchMeetingsList };

function loadUsersListFromApi(dispatch) {
    dispatch(reloadUserListData());
}

const loadUsersListFromApiDebounced = _debounce(loadUsersListFromApi, 500);

/**
 * Make sure the list of users in the logged in user's state.userList is up to date.
 * This could change if you receive a permission update for a user who is not currently
 * in your userList. Then we retrieve the latest list from the api.
 * @param {Object} model could be meeting or occurrence
 * @param {Object} prev previous model if meeting or occurrence has changed.
 */
export function ensureUsers(model, prev) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    return async function ensureUsers(dispatch, getState) {
        const state = getState();
        const { attendees } = model;
        const { users } = state.userList;
        const userNotFound = _find(
            attendees,
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line unicorn/prefer-array-some
            (att) => !users.find((u) => u.id === att.userId)
        );
        userNotFound && loadUsersListFromApiDebounced(dispatch);

        if (prev) {
            // previous meeting/occurrence provided. If it's been updated (timestamp),
            // and user is currently viewing this meeting or an occurrence of this meeting,
            // make sure to update permissions.
            if (_get(model, 'updatedAt') !== _get(prev, 'updatedAt')) {
                const { pathname } = history.location;
                const detailPage = isDetailPage(pathname);
                if (detailPage) {
                    const pageState = getPageState(state, detailPage.id);
                    if (
                        !pageState ||
                        !(
                            pageState.occurrence === model.id ||
                            pageState.meeting === model.id
                        )
                    )
                        return;

                    if (model.meeting) {
                        // model is an occurrence
                        dispatch(loadOccurrencePermissions(model));
                    } else {
                        // is a meeting
                        dispatch(loadMeetingPermissions(model));
                        // user is viewing an occurrence, so update the permissions for that occurrence too
                        if (pageState.occurrence) {
                            const occurrence = getOccurrenceFromStoreById(
                                state,
                                pageState.occurrence
                            );
                            dispatch(loadOccurrencePermissions(occurrence));
                        }
                    }
                }
            }
        }
    };
}

export function fetchMeetingUsers(params) {
    return function fetchMeetingUsers() {
        return apiClient.getUserList(params).then((page) => ({
            type: FETCH_ALL_MY_MEETING_USERS_SUCCESS,
            page,
        }));
    };
}

function getFetchOccurrences(extraParams = {}) {
    return function fetchOccurrences(params) {
        return () =>
            apiClient
                .getOccurrences({ ...params, ...extraParams })
                .then((page) => ({
                    type: FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS,
                    page,
                    extraParams,
                }));
    };
}

function getFetchActionItems(extraParams = {}) {
    return function fetchActionItems(params) {
        return () => {
            return apiClient
                .getActionItems({ ...params, ...extraParams })
                .then((page) => ({
                    type: FETCH_ACTION_ITEMS_SUCCESS,
                    page,
                    extraParams,
                }));
        };
    };
}
export { getFetchActionItems as __TEST__getFetchActionItems };

function getFetchLinkedActionItems(extraParams = {}) {
    return function fetchLinkedActionItems(params) {
        return () => {
            return apiClient
                .getLinkedActionItems({ ...params, ...extraParams })
                .then((actionItems) => ({
                    type: FETCH_LINKED_ACTION_ITEMS_SUCCESS,
                    actionItems,
                    extraParams,
                }));
        };
    };
}

function getFetchDocuments(extraParams = {}) {
    return function fetchDocuments(params) {
        return () => {
            return apiClient
                .getDocumentList({ ...params, ...extraParams })
                .then((page) => ({
                    type: FETCH_DOCUMENT_LIST_SUCCESS,
                    page,
                    extraParams,
                }));
        };
    };
}

const fetchTemplates = () =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/consistent-function-scoping
    function fetchTemplates() {
        return apiClient.getTemplates().then((templates) => ({
            type: FETCH_TEMPLATES_SUCCESS,
            templates,
        }));
    };

function getFetchAgendaItems(extraParams = {}) {
    return function fetchAgendaItems() {
        return () => {
            return apiClient
                .getAgendaItems(extraParams)
                .then((agendaItems) => ({
                    type: FETCH_AGENDA_ITEMS_SUCCESS,
                    agendaItems,
                    extraParams,
                }));
        };
    };
}

function getFetchMinutes(extraParams = {}) {
    return function fetchMinutes(params) {
        return () => {
            return apiClient
                .getMinutes({ ...params, ...extraParams })
                .then((page) => ({
                    type: FETCH_MINUTES_SUCCESS,
                    page,
                    extraParams,
                }));
        };
    };
}

function fetchPrivateNotes() {
    return function fetchPrivateNotes() {
        return apiClient.getPrivateNotes().then((privateNotesList) => ({
            type: FETCH_PRIVATE_NOTES_SUCCESS,
            privateNotesList,
        }));
    };
}

function fetchIntegrationProfiles() {
    return function fetchIntegrationProfiles() {
        return apiClient
            .getIntegrationProfiles()
            .then((integrationProfilesList) => ({
                type: FETCH_INTEGRATION_PROFILES_SUCCESS,
                integrationProfilesList,
            }));
    };
}

function fetchPageViews() {
    return function fetchPageViews() {
        // INFO: only select most recent 5 rows.
        return apiClient
            .getPageViews({ limit: 15, sort: '-lastOpenedAt' })
            .then((pageViewsPage) => ({
                type: FETCH_PAGEVIEWS_SUCCESS,
                pageViews: pageViewsPage.docs,
            }));
    };
}

function getFetchMeeting(id) {
    return function fetchMeeting() {
        return () => {
            return loadMeeting(id, { dispatchImmediately: false });
        };
    };
}

const dispatchToRedux = (dispatch, results) =>
    results.forEach((result) =>
        Array.isArray(result)
            ? result.forEach((subResult) => dispatch(subResult))
            : dispatch(result)
    );

async function makePromiseLoader(
    functionName,
    dispatch,
    fn,
    chunkPercent,
    silent = false
) {
    try {
        const result = await dispatch(fn());

        if (!result?.page) {
            silent ||
                dispatch({
                    type: PRELOAD_SUCCESS,
                    percent: chunkPercent,
                    functionName,
                });
            return result;
        }

        const { page } = result.page;
        const pageCount = page.pages;

        silent ||
            dispatch({
                type: PRELOAD_SUCCESS,
                percent: chunkPercent / pageCount,
                functionName: `${functionName}-${page.page}/${page.pages}`,
            });

        const pageResults = await Promise.all(
            _range(2, page.pages + 1).map(async (page) => {
                const result = await dispatch(fn({ page }));

                silent ||
                    dispatch({
                        type: PRELOAD_SUCCESS,
                        percent: chunkPercent / pageCount,
                        functionName: `${functionName}-${page}/${page.pages}`,
                    });
                return result;
            })
        );
        pageResults.unshift(result);
        return pageResults;
    } catch (error) {
        silent ||
            dispatch({
                type: PRELOAD_ERROR,
                errored: {
                    error,
                    functionName,
                },
            });

        throw error;
    }
}

function reloadUserListData() {
    return async function reloadUserListData(dispatch) {
        const results = await makePromiseLoader(
            'fetchMeetingUsers',
            dispatch,
            fetchMeetingUsers,
            100,
            true
        );
        dispatchToRedux(dispatch, results);
    };
}

export function preloadMeetingData(meeting) {
    return async (dispatch) => {
        const chunkPercent = 100 / 6;
        const extraParams = { id: meeting.id };

        const results = await Promise.all([
            makePromiseLoader(
                'getFetchOccurrences',
                dispatch,
                getFetchOccurrences(extraParams),
                chunkPercent,
                true
            ),
            makePromiseLoader(
                'getFetchActionItems',
                dispatch,
                getFetchActionItems(extraParams),
                chunkPercent,
                true
            ),
            makePromiseLoader(
                'getFetchLinkedActionItems',
                dispatch,
                getFetchLinkedActionItems(extraParams),
                chunkPercent,
                true
            ),
            makePromiseLoader(
                'getFetchDocuments',
                dispatch,
                getFetchDocuments(extraParams),
                chunkPercent,
                true
            ),
            makePromiseLoader(
                'getFetchMinutes',
                dispatch,
                getFetchMinutes(extraParams),
                chunkPercent,
                true
            ),
            makePromiseLoader(
                'getFetchAgendaItems',
                dispatch,
                getFetchAgendaItems(extraParams),
                chunkPercent,
                true
            ),
        ]);
        dispatchToRedux(dispatch, results);
    };
}

const load =
    (promises, { loadType = 'preload' } = {}) =>
    async (dispatch) => {
        // NOTE: first 10% is used for loading source components in App.js
        const silent = loadType === 'postload';
        !silent &&
            dispatch({
                type: PRELOAD_REQUEST,
                percent: 10,
            });

        const chunkPercent = 89 / promises.length; // leave 1% for the PRELOAD_COMPLETE, to make sure that all data has been added to redux before marked 100%

        const results = await Promise.all(
            promises.map(([functionName, p]) =>
                makePromiseLoader(
                    functionName,
                    dispatch,
                    p,
                    chunkPercent,
                    silent
                )
            )
        );
        dispatchToRedux(dispatch, results);

        !silent &&
            dispatch({
                type: PRELOAD_COMPLETE,
            });
    };

export function preloadAppData() {
    return async (dispatch, getState) => {
        const {
            isMeetingDetailPage,
            detailPage,
            isActionsDashboardPage,
            isWorkspacePage,
        } = pageViewDetails(location);

        try {
            //eslint-disable-next-line no-console
            console.log(
                {
                    isMeetingDetailPage,
                    detailPage,
                    isActionsDashboardPage,
                    isWorkspacePage,
                },
                'preload commencing',
                new Date()
            );
            const [locationData] = await Promise.all([
                apiClient.getLocationData(),
                dispatch(retrieveToken), // csrf token is required before fetching user profile
            ]);
            await dispatch(fetchUserProfile(locationData));

            // This request should be kicked off at the start, but don't need to hold up the preload process.
            dispatch(loadCRMLinks());

            // preload are the minimum needed to stop the "Loading..." spinner
            // after these are all completed, the page will be rendered and needs to take care of rendering a loading state
            const preloadPromises = [];
            // items in postload will be kicked off after the preloadPromises have all completed and the page is rendered
            const postloadPromises = [];

            if (isMeetingDetailPage) {
                const extraParams = { id: detailPage.id };
                preloadPromises.push(
                    ['getFetchMeeting', getFetchMeeting(detailPage.id)()],
                    ['fetchIntegrationProfiles', fetchIntegrationProfiles],
                    ['fetchUserPermissions', fetchUserPermissions],
                    ['getFetchOccurrences', getFetchOccurrences(extraParams)],
                    ['fetchGroupsList', fetchGroupsList]
                );
                postloadPromises.push(
                    ['getFetchAgendaItems', getFetchAgendaItems(extraParams)],
                    ['getFetchActionItems', getFetchActionItems(extraParams)],
                    [
                        'getFetchLinkedActionItems',
                        getFetchLinkedActionItems(extraParams),
                    ],
                    ['getFetchDocuments', getFetchDocuments(extraParams)],
                    ['getFetchMinutes', getFetchMinutes(extraParams)],
                    ['fetchTemplates', fetchTemplates],
                    ['fetchMeetingUsers', fetchMeetingUsers],
                    ['getFetchNotifications', getFetchNotifications()],
                    ['fetchPrivateNotes', fetchPrivateNotes],
                    ['fetchMeetingsList', fetchMeetingsList],
                    ['getFetchAgendaItems', getFetchAgendaItems()],
                    ['getFetchOccurrences', getFetchOccurrences()],
                    ['getFetchActionItems', getFetchActionItems()],
                    ['getFetchLinkedActionItems', getFetchLinkedActionItems()],
                    ['getFetchDocuments', getFetchDocuments()],
                    ['getFetchMinutes', getFetchMinutes()],
                    ['fetchScheduledEvents', fetchScheduledEvents],
                    ['fetchPageViews', fetchPageViews],
                    ['loadRemoteCalendarEvents', loadRemoteCalendarEvents],
                    ['loadRemoteCalendarLinks', loadRemoteCalendarLinks]
                );
            } else if (isActionsDashboardPage) {
                preloadPromises.push(
                    ['fetchGroupsList', fetchGroupsList],
                    ['fetchIntegrationProfiles', fetchIntegrationProfiles]
                );

                postloadPromises.push(
                    ['fetchPageViews', fetchPageViews],
                    ['getFetchActionItems', getFetchActionItems()],
                    ['getFetchNotifications', getFetchNotifications()],
                    ['fetchMeetingUsers', fetchMeetingUsers],
                    ['getFetchOccurrences', getFetchOccurrences()],
                    ['fetchMeetingsList', fetchMeetingsList],
                    ['fetchUserPermissions', fetchUserPermissions],
                    ['getFetchMinutes', getFetchMinutes()],
                    ['getFetchAgendaItems', getFetchAgendaItems()],
                    ['getFetchLinkedActionItems', getFetchLinkedActionItems()],
                    ['getFetchDocuments', getFetchDocuments()],
                    ['fetchScheduledEvents', fetchScheduledEvents],
                    ['fetchPrivateNotes', fetchPrivateNotes],
                    ['fetchTemplates', fetchTemplates],
                    ['loadRemoteCalendarEvents', loadRemoteCalendarEvents],
                    ['loadRemoteCalendarLinks', loadRemoteCalendarLinks]
                );
            } else if (isWorkspacePage) {
                preloadPromises.push(
                    ['fetchGroupsList', fetchGroupsList],
                    ['fetchIntegrationProfiles', fetchIntegrationProfiles],
                    ['fetchUserPermissions', fetchUserPermissions]
                );

                postloadPromises.push(
                    ['fetchPageViews', fetchPageViews],
                    ['getFetchNotifications', getFetchNotifications()],
                    ['getFetchActionItems', getFetchActionItems()],
                    ['getFetchOccurrences', getFetchOccurrences()],
                    ['fetchMeetingsList', fetchMeetingsList],
                    ['getFetchMinutes', getFetchMinutes()],
                    ['fetchMeetingUsers', fetchMeetingUsers],
                    ['getFetchAgendaItems', getFetchAgendaItems()],
                    ['getFetchLinkedActionItems', getFetchLinkedActionItems()],
                    ['getFetchDocuments', getFetchDocuments()],
                    ['fetchScheduledEvents', fetchScheduledEvents],
                    ['fetchPrivateNotes', fetchPrivateNotes],
                    ['fetchTemplates', fetchTemplates],
                    ['loadRemoteCalendarEvents', loadRemoteCalendarEvents],
                    ['loadRemoteCalendarLinks', loadRemoteCalendarLinks]
                );
            } else {
                preloadPromises.push(
                    ['fetchGroupsList', fetchGroupsList],
                    ['fetchIntegrationProfiles', fetchIntegrationProfiles]
                );

                postloadPromises.push(
                    ['loadRemoteCalendarEvents', loadRemoteCalendarEvents],
                    ['loadRemoteCalendarLinks', loadRemoteCalendarLinks],
                    ['getFetchNotifications', getFetchNotifications()],
                    ['fetchMeetingUsers', fetchMeetingUsers],
                    ['getFetchOccurrences', getFetchOccurrences()],
                    ['fetchMeetingsList', fetchMeetingsList],
                    ['fetchUserPermissions', fetchUserPermissions],
                    ['fetchPageViews', fetchPageViews],
                    ['getFetchAgendaItems', getFetchAgendaItems()],
                    ['getFetchActionItems', getFetchActionItems()],
                    ['getFetchLinkedActionItems', getFetchLinkedActionItems()],
                    ['getFetchDocuments', getFetchDocuments()],
                    ['getFetchMinutes', getFetchMinutes()],
                    ['fetchScheduledEvents', fetchScheduledEvents],
                    ['fetchPrivateNotes', fetchPrivateNotes],
                    ['fetchTemplates', fetchTemplates]
                );
            }

            await dispatch(load(preloadPromises));
            //eslint-disable-next-line no-console
            console.info('Preload completed', new Date());

            // on preload on the meetings page, the user preloads on an occurrence.id.
            // and if the user then swaps to any other page in that same series, we want them to see preload complete.
            // so we swap the information in the state.preload.fullyLoaded so it is now by meetingId rather than occurrenceId.
            if (isMeetingDetailPage) {
                const occurrence = getOccurrenceSelector(getState(), {
                    occurrence: detailPage.id,
                });
                occurrence?.meeting &&
                    dispatch({
                        type: PRELOAD_SWAP_LOAD_STATUS_MEETING,
                        fromId: detailPage.id,
                        toId: occurrence.meeting,
                    });
            }

            dispatch(
                load(postloadPromises, {
                    loadType: 'postload',
                })
            )
                .catch(processLoadError)
                .finally(() => {
                    //eslint-disable-next-line no-console
                    console.info('Postload completed', new Date());
                });
        } catch (error) {
            processLoadError(error);
        }
    };
}

const processLoadError = (loadError) => {
    const { getState, dispatch } = getStore();
    const user = getState().user;
    const preloadData = getState().preload;

    console.error('caught error in preload: ', loadError); //eslint-disable-line no-console
    console.error('preload state: ', { preloadData }); //eslint-disable-line no-console

    // Trigger the airbrake notify, so we can see how, why and how often this is happening.
    airbrake.notify({
        error: loadError,
        session: user,
        params: { preloadData },
    });

    dispatch(onPreloadError());
};
