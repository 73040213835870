import React from 'react';

const RECURRING_ID = 'shape-recurring';

const RecurringIconInner = ({
    iconThick = false,
}: {
    iconThick?: boolean;
}): JSX.Element => (
    <>
        <path
            d="m18.5 7.9228-1.5-4.4557 4.6315-1.7138"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="m10.5 24.26 1.5 4.4557-4.6315 1.7138"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="m17 3.4672c7.1251 0.86863 12.73 7.5411 11.847 14.543-0.6419 5.085-4.2417 9.0993-8.8935 10.584m-8.5418-24.133c-4.3951 1.6111-7.7495 5.5119-8.3669 10.403-0.75678 5.9951 3.4321 11.837 8.9551 13.852"
            vectorEffect="non-scaling-stroke"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </>
);

const RecurringIcon = ({
    iconThick = false,
    isSymbolRef = false,
    title = '',
    ...rest
}): JSX.Element => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        {isSymbolRef ? (
            <use href={`#${RECURRING_ID}`} xlinkHref={`#${RECURRING_ID}`} />
        ) : (
            <RecurringIconInner iconThick={iconThick} />
        )}
    </svg>
);

export { RecurringIcon as default, RecurringIconInner, RECURRING_ID };
