import _capitalize from 'lodash/capitalize.js';
import React from 'react';
import 'twin.macro';
import { getStore } from '~common/store';
import { MESSAGE_MODAL_OPEN, MESSAGE_MODAL_CLOSE } from '~common/action.types';
import { markAsRead } from '~modules/notification/notification.actions';
import { pushTo } from '~modules/navigation';
import {
    getWorkspaceUpdateSlug,
    getWorkspaceUpgradeSlug,
} from '~modules/navigation/navigation.helpers';
import { LinkExternal } from '~modules/common/components';
import { getWorkspaceFromStoreById } from '~modules/group/group.selectors';
import { normalizeWorkspace } from '~modules/workspace/workspace.helpers';
import { getFeatureExpiryMessage } from '~shared/utils/feature.helpers';
import { dateFull } from '~common/time.utils';
import { HELP_SITE_URL } from '~common/constants';

const upgradeHeading = ({ limit }) => (
    <>
        Workspace has reached{' '}
        <span tw="text-primary">
            {limit}/{limit}
        </span>{' '}
        active meetings
    </>
);

const paymentSuccessDesc = (
    <>
        We were unable to process your credit card payment.
        <br /> Please update your billing information to continue.
    </>
);

const upgradePlanMemberDesc = ({ owners, title }) =>
    owners?.length > 0 ? (
        <>
            Ask {owners.length > 1 ? 'an' : 'the'} owner of “{title}” to
            consider upgrading: <br />
            {owners.length > 1 ? (
                <>
                    {owners[0].displayName} ({owners[0].email}) or
                    <br />
                    {owners[1].displayName} ({owners[1].email})
                </>
            ) : (
                <>
                    {owners[0].displayName} ({owners[0].email})
                </>
            )}
        </>
    ) : null;

const footerHeading = 'Not ready to upgrade?';
const footerDesc =
    'Archive or delete an existing series to free up an active meeting slot';

export const closeMessageModal = () => ({
    type: MESSAGE_MODAL_CLOSE,
});

export const openTrialExpiryModal = (notification) => {
    const dispatch = getStore().dispatch;
    const hasExpired = notification.meta.trialHasExpired;

    const expiryMessage = getFeatureExpiryMessage({
        featureName: notification.meta.trialFeatureName,
        trialExpiryDate: dateFull(notification.meta.trialExpiryDate),
        title: notification.meta.targetTitle,
        helpSiteUrl: HELP_SITE_URL,
        utmTrackingLink: `?utm_source=message-modal&utm_medium=app&utm_campaign=feature-trial-${
            hasExpired ? 'expired' : 'approaching-expiry'
        }`,
        hasExpired,
    });
    return {
        type: MESSAGE_MODAL_OPEN,
        image: {
            name: 'upgradeToPlus',
            alt: 'A rocket ship taking off illustration',
            width: '200',
        },
        heading: `Plus plan feature trial ${
            hasExpired ? 'has expired' : 'expires soon'
        }`,
        description: expiryMessage,
        cta: 'Updgrade to Plus',
        isPrimary: true,
        hasConfetti: false,
        onClick: () => (
            dispatch(closeMessageModal()),
            notification && dispatch(markAsRead(notification)),
            pushTo(getWorkspaceUpgradeSlug(notification.meta.target))
        ),
        onClose: notification ? () => dispatch(markAsRead(notification)) : null,
    };
};

export const openPaymentErrorModal = (notification) => {
    const dispatch = getStore().dispatch;
    return {
        type: MESSAGE_MODAL_OPEN,
        image: {
            name: 'errorPayment',
            alt: 'A broken credit card illustration',
            width: '200',
        },
        heading: 'We couldn’t process your payment',
        description: paymentSuccessDesc,
        cta: 'Update payment details',
        isPrimary: false,
        isWarning: true,
        hasConfetti: false,
        onClick: () => (
            dispatch(closeMessageModal()),
            notification && dispatch(markAsRead(notification)),
            pushTo(getWorkspaceUpdateSlug(notification?.meta?.target))
        ),
        onClose: notification ? () => dispatch(markAsRead(notification)) : null,
    };
};

export const openUpgradeSuccessPrimaryModal = (notification) => {
    const dispatch = getStore().dispatch;
    const workspace = normalizeWorkspace(
        getWorkspaceFromStoreById(notification.meta.target)
    );
    if (!workspace) return;
    const { plan } = workspace;
    return {
        type: MESSAGE_MODAL_OPEN,
        image: {
            name: plan.image,
            alt: 'A rocket ship flying through space illustration',
            width: '150',
        },
        heading: (
            <>
                Yay! You&rsquo;re now{' '}
                <span tw="whitespace-nowrap">on the {plan.label}</span>
            </>
        ),
        description: `Your workspace has been successfully upgraded to ${_capitalize(
            plan.id
        )}.`,
        cta: null,
        isPrimary: true,
        isWarning: false,
        hasConfetti: true,
        onClose: notification ? () => dispatch(markAsRead(notification)) : null,
    };
};

export const openUpgradeSuccessSecondaryModal = (notification) => {
    const dispatch = getStore().dispatch;
    const workspace = normalizeWorkspace(
        getWorkspaceFromStoreById(notification.meta.target)
    );
    if (!workspace) return;
    const { plan } = workspace;

    return {
        type: MESSAGE_MODAL_OPEN,
        image: {
            name: plan.image,
            alt: 'A rocket ship flying through space illustration',
            width: '150',
        },
        heading: (
            <>
                Yay! “{notification.meta.targetTitle}” is{' '}
                <span tw="whitespace-nowrap">now on the {plan.label}</span>
            </>
        ),
        description: 'Now you can create as many meetings as you like.',
        cta: null,
        isPrimary: true,
        isWarning: false,
        hasConfetti: true,
        onClose: notification ? () => dispatch(markAsRead(notification)) : null,
    };
};

export const openUpgradePlanModal = ({ workspace, isArchived }) => {
    const dispatch = getStore().dispatch;
    return {
        type: MESSAGE_MODAL_OPEN,
        image: {
            name: 'upgradeToPlus',
            alt: 'A rocket ship taking off illustration',
            width: '200',
        },
        heading: upgradeHeading(workspace),
        description: isArchived
            ? 'Upgrade to Plus to pull this series from the archive.'
            : 'It’s time to upgrade to Plus to create more meetings',
        cta: 'Upgrade to Plus',
        isPrimary: true,
        isWarning: false,
        footerHeading,
        footerDesc,
        hasConfetti: false,
        onClick: () => (
            dispatch(closeMessageModal()),
            pushTo(getWorkspaceUpgradeSlug(workspace))
        ),
    };
};

export const openUpgradePlanMemberModal = ({ workspace }) => ({
    type: MESSAGE_MODAL_OPEN,
    image: {
        name: 'upgradeToPlus',
        alt: 'A rocket ship taking off illustration',
        width: '200',
    },
    heading: upgradeHeading(workspace),
    description: upgradePlanMemberDesc(workspace),
    cta: null,
    isPrimary: true,
    isWarning: false,
    footerHeading,
    footerDesc,
    hasConfetti: false,
    onClick: null,
});

export const openUpdatedTermsAndPrivacy = (notification) => {
    const dispatch = getStore().dispatch;

    const isTermsUpdate = Boolean(notification?.meta?.termsOfUseVersion);
    const isPrivacyUpdate = Boolean(notification?.meta?.privacyPolicyVersion);
    const isTermsAndPrivacyUpdate = isTermsUpdate && isPrivacyUpdate;

    const heading = [];
    const content = [];
    const termsHeading = 'Terms of Use';
    const terms = (
        <LinkExternal
            href="https://minuteme.com/terms-of-use/"
            key={'termsLink'}
        >
            Terms of Use
            {notification?.meta?.termsOfUseVersion
                ? ` (version ${notification.meta.termsOfUseVersion})`
                : ''}
        </LinkExternal>
    );
    const privacyHeading = 'Privacy Policy';
    const privacy = (
        <LinkExternal href="https://minuteme.com/privacy/" key={'privacyLink'}>
            Privacy Policy
            {notification?.meta?.privacyPolicyVersion
                ? ` (version ${notification.meta.privacyPolicyVersion})`
                : ''}
        </LinkExternal>
    );

    if (isTermsAndPrivacyUpdate) {
        heading.push(termsHeading, ' and ', privacyHeading, <br />, 'have ');
        content.push(
            terms,
            <>
                {' '}
                and
                <br />
            </>,
            privacy
        );
    } else if (isTermsUpdate) {
        heading.push(termsHeading, ' have');
        content.push(terms);
    } else if (isPrivacyUpdate) {
        heading.push(privacyHeading, ' has');
        content.push(privacy);
    }

    return {
        type: MESSAGE_MODAL_OPEN,
        image: {
            name: 'logoIcon',
            alt: 'MinuteMe logo',
            width: '60',
        },
        heading: <>Our {heading} been updated</>,
        description: (
            <div>
                By continuing to use MinuteMe, you agree to our updated
                <br />
                {content}
            </div>
        ),
        cta: 'Continue using MinuteMe',
        onClick: () => (
            dispatch(closeMessageModal()),
            notification && dispatch(markAsRead(notification))
        ),
        isPrimary: true,
        isWarning: false,
        hasConfetti: false,
        onClose: notification ? () => dispatch(markAsRead(notification)) : null,
    };
};
