import { get } from '../../api/helpers.js';
import { activityModel, activityModelList } from './activity.mappers.js';

export function getActivitiesForOccurrence(id) {
    const url = `/api/activity/occurrence/${id}`;
    return get(url).then(activityModelList);
}

export function getActivity(activityId) {
    const url = `/api/activity/${activityId}`;
    return get(url).then(activityModel);
}

export function getActivityCountForOccurrence(id) {
    const url = `/api/activity/occurrence/${id}/count`;
    return get(url);
}
