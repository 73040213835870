/**
 * Determine if a template is an Agenda Template. If it's not an agenda template, it's a Library Template.
 * Not all Agenda Templates have a 'template' document. They only have a template document if they're linked with a library template.
 * So it's either the existence of the template, or if the template has a meetingId associated with it
 * @param {Object} template
 * @returns Boolean
 */
export const isAgendaTemplate = (template) =>
    !template || !!template?.meetingId;

/**
 * Determine if a template is a Library Template. If it's not a library template, it's an Agenda (series) template.
 * A Library Template is for a user or a workspace.
 * Not all Agenda Templates have a 'template' document. They only have a template document if they're linked with a library template.
 * @param {Object} template
 * @returns Boolean
 */
export const isLibraryTemplate = (template) =>
    isWorkspaceTemplate(template) || isPrivateTemplate(template);

/**
 * Determine if a template is a Private Template.
 * A Private Template is for a user.
 * @param {Object} template
 * @returns Boolean
 */
export const isPrivateTemplate = (template) => !!template?.userId;

/**
 * Determine if a template is a Workspace Template.
 * @param {Object} template
 * @returns Boolean
 */
export const isWorkspaceTemplate = (template) => !!template?.workspaceId;
