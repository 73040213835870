import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m26.982 19.734 0.587 0.6463c0.1762 0.2068 0.3131 0.362 0.411 0.4654 0.0978 0.1034 0.2201 0.2412 0.3669 0.4136 0.1468 0.1723 0.2495 0.3102 0.3082 0.4136s0.1272 0.2284 0.2055 0.3749c0.0782 0.1465 0.1223 0.2714 0.1321 0.3748 0.0097 0.1034 0.0097 0.2198 0 0.349-0.0098 0.1293-0.0539 0.2542-0.1321 0.3749-0.2936 0.5687-1.0861 0.8531-2.3776 0.8531h-20.899c-1.2915 0-2.1036-0.2844-2.4363-0.8531 0 0-0.13577-0.3249-0.14677-0.5429-0.01-0.1984 0.07339-0.5041 0.07339-0.5041l0.3082-0.5688 0.41094-0.5687 0.52835-0.6592c0.0587-0.0517 0.27395-0.293 0.64576-0.7239 0.3718-0.4308 0.62618-0.7368 0.76316-0.9177 0.13698-0.181 0.33267-0.4567 0.58706-0.8273 0.25439-0.3705 0.43051-0.7152 0.52835-1.0341 0.09784-0.3188 0.14676-0.6333 0.14676-0.9436v-5.403c0-1.5339 0.39137-2.9471 1.1741-4.2397 0.78274-1.2926 1.8639-2.3181 3.2435-3.0764 1.3796-0.75833 2.8912-1.1375 4.535-1.1375 1.2524 0 2.4363 0.22405 3.5517 0.67215s2.0693 1.047 2.8618 1.7967c0.7926 0.74971 1.4237 1.6459 1.8933 2.6886 0.4696 1.0427 0.7045 2.1414 0.7045 3.2961v5.403l0.1174 0.8144 0.3375 0.8531 0.4403 0.7755 0.5577 0.7627 0.5724 0.6721z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m21 25c0 0.6566-0.1293 1.3068-0.3806 1.9134s-0.6196 1.1578-1.0839 1.6221-1.0155 0.8326-1.6221 1.0839-1.2568 0.3806-1.9134 0.3806-1.3068-0.1293-1.9134-0.3806-1.1578-0.6196-1.6221-1.0839-0.8326-1.0155-1.0839-1.6221-0.3806-1.2568-0.3806-1.9134"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
