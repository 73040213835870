__webpack_public_path__ = window.__minuteme_base; // eslint-disable-line no-undef

import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import 'core-js/stable/index.js';
import _get from 'lodash/get.js';
import 'mdn-polyfills/CustomEvent';
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { applyMiddleware, compose } from 'redux';
import { batchedSubscribe } from 'redux-batched-subscribe';
import thunkMiddleware from 'redux-thunk';
import 'regenerator-runtime/runtime';
// TODO: Check if core-js polyfills urls adequately
// https://github.com/zloirock/core-js#url-and-urlsearchparams
import 'url-polyfill';
import { errorMiddleware } from '~common/airbrake';
import history, { scrollMiddleware } from './modules/navigation/index.js';
import './../../bootstraps/production/index.js';
import { canApplyUpdates, createStore } from './common/store.js';
import App from './components/container/AppContainer.js';
import './favicon.ico';
import createRootReducer from './reducers/index.js';
import { getSessionId, setSessionId } from '~client/actions/session';
import { LoadCSRF } from '~common/csrf.js';

ReactGA.initialize(window.__minuteme_appConfig.GA_TRACKING_ID);

const SetSessionId = () => {
    if (!getSessionId()) {
        // Set the session Id used to suppress websocket events triggered by the logged in user
        // This can get unset by hotreloading, so we assess it each render
        setSessionId();
    }

    return null;
};

if (module.hot) {
    module.hot.accept();
}

const container = document.querySelector('#app');
const root = createRoot(container);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line unicorn/prefer-add-event-listener
window.onpageshow = function (event) {
    const navType = _get(window, 'performance.navigation.type');

    if (event.persisted || navType === 2) {
        window.location.reload();
    } else {
        // thunkMiddleware is to allow async actions
        // routerMiddleware for intercepting and dispatching navigation actions
        const middleware = [
            thunkMiddleware,
            scrollMiddleware,
            routerMiddleware(history),
            errorMiddleware,
        ];

        const composeEnhancer =
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

        const debounceNotify = (notify) => canApplyUpdates() && notify();

        const store = createStore(
            createRootReducer(history),
            composeEnhancer(
                applyMiddleware(...middleware),
                batchedSubscribe(debounceNotify)
            )
        );

        root.render(
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                    <SetSessionId />
                    <LoadCSRF />
                </ConnectedRouter>
            </Provider>
        );
    }
};
