import React from 'react';
export default ({ iconThick = false, ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        {...rest}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
            d="M22 25v5H3V2h19v5M16 16h13m0 0l-4-5m4 5l-4 5"
        />
    </svg>
);
