// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    RECURRENCE_NEVER,
    RECURRENCE_NOT_SCHEDULED,
    RECURRENCE_DAILY,
    RECURRENCE_WEEKLY,
    RECURRENCE_MONTHLY_ABSOLUTE,
    RECURRENCE_MONTHLY_RELATIVE,
    RECURRENCE_YEARLY_ABSOLUTE,
    RECURRENCE_YEARLY_RELATIVE,
    DATETIME_FORMAT_FOR_DISPLAY,
    DATETIME_FORMAT_FOR_DISPLAY_WITH_AT,
    DATETIME_FORMAT_FOR_DISPLAY_NO_PERIOD,
    TIME_FORMAT_FOR_DISPLAY,
    TIME_FORMAT_FOR_DISPLAY_NO_PERIOD,
} from '~common/constants';

import { getFormattedMeetingDuration } from '~modules/meeting/meeting.helpers';

const exclusionRegex = [
    /^CET/,
    /^CST/,
    /^EET/,
    /^EST/,
    /^Etc/,
    /^HST/,
    /^MET/,
    /^MST/,
    /^PST/,
    /^WET/,
    /^ROK/,
    /^PRC/,
    /^ROC/,
    /^GMT/,
    /^UCT/,
    /^UTC/,
    /^Universal/,
];

const includeTimezone = (value) =>
    !exclusionRegex.some((exp) => value.match(exp));

export const timezoneList = _.memoize(
    () =>
        Object.values(moment.tz._names)
            .filter(includeTimezone)
            .map((value, key) => ({
                key,
                value,
                text: value.replace(/_/g, ' '),
            })),
    () => 'timezonelist'
)();

// components/pure/Recurrence/RecurrenceDetail.js
// From https://msdn.microsoft.com/office/office365/api/complex-types-for-mail-contacts-calendar#enumerations
export const recurrencePatternTypeList = [
    {
        key: 0,
        value: RECURRENCE_NEVER,
        text: 'One-off',
    },
    {
        key: 1,
        value: RECURRENCE_NOT_SCHEDULED,
        text: 'Does not repeat regularly',
    },
    {
        key: 2,
        value: RECURRENCE_DAILY,
        text: 'Daily',
    },
    {
        key: 3,
        value: RECURRENCE_WEEKLY,
        text: 'Weekly',
    },
    {
        key: 4,
        value: RECURRENCE_MONTHLY_ABSOLUTE,
        text: 'Monthly on the same day',
    },
    {
        key: 5,
        value: RECURRENCE_MONTHLY_RELATIVE,
        text: 'Monthly in the same week',
    },
    {
        key: 6,
        value: RECURRENCE_YEARLY_ABSOLUTE,
        text: 'Yearly on the same day',
    },
    {
        key: 7,
        value: RECURRENCE_YEARLY_RELATIVE,
        text: 'Yearly in the same week',
    },
];

export const weekIndex = [
    {
        key: 0,
        value: 1,
        text: 'first',
    },
    {
        key: 1,
        value: 2,
        text: 'second',
    },
    {
        key: 2,
        value: 3,
        text: 'third',
    },
    {
        key: 3,
        value: 4,
        text: 'fourth',
    },
    {
        key: 4,
        value: 5,
        text: 'last',
    },
];

export const makeDateTime = (momentDate, time = '00:00') => {
    if (!momentDate) {
        return null;
    }

    const date = momentDate.clone();
    const splitTime = time.split(':');
    return date.startOf('day').add(splitTime[0], 'h').add(splitTime[1], 'm');
};

export const setOrReturnDateTime = (momentDate, time, originalDateTime) => {
    // As dates are moments, they are an object. So an equality test fails if we clone and generate a new date.
    // Therefore if the date hasn't changed, let's reuse the same object so it passes equality checks.
    const newDateTime = makeDateTime(momentDate, time);

    if (!newDateTime) {
        return null;
    }

    const same = originalDateTime.toString() === newDateTime.toString();
    return same ? originalDateTime : newDateTime;
};

export const formatTimeListItem = (key, time, meetingDuration = '') => ({
    key,
    value: time.format('HH:mm'),
    text: `${time.format('h:mm A')} ${meetingDuration}`.trim(),
});

const minutesOfDay = 24 * 60;
const timeSlotMinutes = 15;

export const fnMakeTimeList = (startDate, endDate) => {
    // Create a drop-down list of meeting start/end times in 15 min increments.
    let isSameDay = true,
        startTime;
    if (startDate && endDate) {
        isSameDay = startDate.format('YYYYMMDD') === endDate.format('YYYYMMDD');
        startTime = startDate.format('HH:mm');
    }

    const timeList = [];

    for (var i = 0; i < minutesOfDay / timeSlotMinutes; i++) {
        const t = moment()
            .startOf('day')
            .add(i * timeSlotMinutes, 'm');
        if (!isSameDay || !startTime || startTime < t.format('HH:mm')) {
            timeList.push(formatTimeListItem(i, t));
        }
    }

    return timeList;
};

export const fnMakeEndTimeList = (startDate) => {
    // Create a drop-down list of meeting end times with meeting duration display.
    const timeList = [];

    for (var i = 0; i < minutesOfDay / timeSlotMinutes; i++) {
        const t = moment(startDate).add(i * timeSlotMinutes, 'm');

        const meetingDuration = getFormattedMeetingDuration(
            moment(startDate),
            t
        );

        if (startDate?.format('HH:mm') !== t.format('HH:mm')) {
            timeList.push(formatTimeListItem(i, t, `(${meetingDuration})`));
        }
    }

    return timeList;
};

export var getDropdownTimeList = _.memoize(
    fnMakeTimeList,
    (input) => input || 'timeList'
);

export var getDropdownTimeListFromStartTime = _.memoize((startDate) =>
    fnMakeEndTimeList(startDate)
);

const fnMakeDaysOfWeekList = (locale) => {
    moment.locale(locale);
    return moment.weekdays().map((d, i) => {
        return {
            key: i,
            value: i,
            text: d,
        };
    });
};

export var getDropdownDaysOfWeek = _.memoize((locale) =>
    fnMakeDaysOfWeekList(locale)
);

const fnMakeMonthsOfYearList = (locale) => {
    moment.locale(locale);
    return moment.months().map((d, i) => {
        return {
            key: i + 1,
            value: i + 1,
            text: d,
        };
    });
};
export var getDropdownMonthsOfYear = _.memoize((locale) =>
    fnMakeMonthsOfYearList(locale)
);

export const getWeekOfMonthIndex = (startDate) => {
    // TODO: is there a better way to do this????
    //
    // determine the occurrence of this day of the month.
    // Identify the first date of this day in the month,
    // then iterate through each other date of this day in the month until we find the date.
    const dateOfDay = startDate.clone();
    dateOfDay.startOf('month');
    dateOfDay.day(startDate.format('dddd'));
    if (dateOfDay.month() < startDate.month()) {
        dateOfDay.add(7, 'd');
    }
    const monthOfDay = dateOfDay.month();
    let weekOfMonthIndex = 0;

    while (monthOfDay === dateOfDay.month()) {
        weekOfMonthIndex++;
        if (dateOfDay.date() === startDate.date()) break;
        dateOfDay.add(7, 'd');
    }
    return weekOfMonthIndex;
};

export const stringOrMomentToDate = (stringOrMoment) => {
    //stringOrMoment can be:
    //   a JavaScript Date object
    //   a Moment
    //   a string. It is only a valid date if in ISO8601 format, which is the format when JSON dates are serialized.
    //             The following formats are known to work:
    //             e.g. 2020-05-25T07:00:00.000Z
    //             e.g. 2018-02-20 14:00

    try {
        if (moment.isMoment(stringOrMoment)) {
            return stringOrMoment.formatForUser('L');
        }

        const isDate =
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line unicorn/prefer-number-properties
            stringOrMoment instanceof Date && !isNaN(stringOrMoment.valueOf());
        if (isDate || stringOrMoment === null) {
            return stringOrMoment;
        }

        // is now a string. Try to convert it to a date
        const asMoment = moment(stringOrMoment, moment.ISO_8601);
        if (asMoment.isValid()) {
            return asMoment.toDate();
        }
        return null;
    } catch {
        return null;
    }
};

export const dateToMoment = (dateVal, timezone) => {
    try {
        return dateVal ? moment(dateVal).tz(timezone) : '';
    } catch {
        return '';
    }
};

/**
 * Return date/time range formatted like:
 *    14/05/2019 4:00 - 5:00pm or
 *    14/05/2019 11:00am - 12:00pm or
 *    14/05/2019 11:30pm - 15/05/2019 1:30am
 * @param {moment} fromDate start of the range
 * @param {moment} toDate end of the range
 */
export const formatAsDateTimeRange = (fromDate, toDate) => {
    const isSamePeriod =
        fromDate.formatForUser('A') === toDate.formatForUser('A');
    const isSameDay =
        fromDate.formatForUser('YYYYMMDD') === toDate.formatForUser('YYYYMMDD');

    const from = fromDate.formatForUser(
        isSamePeriod
            ? DATETIME_FORMAT_FOR_DISPLAY_NO_PERIOD
            : DATETIME_FORMAT_FOR_DISPLAY
    );
    const to = toDate.formatForUser(
        isSameDay ? TIME_FORMAT_FOR_DISPLAY : DATETIME_FORMAT_FOR_DISPLAY
    );

    return `${from} - ${to}`;
};

/**
 * Return time range formatted like:
 *    4:00 - 5:00pm or
 *    11:00am - 12:00pm or
 *    11:30pm - 1:30am
 * @param {moment} fromDate start of the range
 * @param {moment} toDate end of the range
 */
export const formatAsTimeRange = (fromDate, toDate) => {
    const isSamePeriod =
        fromDate.formatForUser('A') === toDate.formatForUser('A');

    const from = fromDate.formatForUser(
        isSamePeriod
            ? TIME_FORMAT_FOR_DISPLAY_NO_PERIOD
            : TIME_FORMAT_FOR_DISPLAY
    );

    return `${from} - ${toDate.formatForUser(TIME_FORMAT_FOR_DISPLAY)}`;
};

export const dateStartOfDay = (day) =>
    (day ? new Date(day.getTime()) : new Date()).setHours(0, 0, 0, 0);

export const dateEndOfDay = (day) =>
    (day ? new Date(day.getTime()) : new Date()).setHours(23, 59, 59, 999);

export const dateIsToday = (date) =>
    moment(date).formatForUser('LL') === moment().formatForUser('LL');

export const dateHeadingFull = (date) =>
    moment(date).formatForUser('dddd D MMMM');

export const dateDay = (date) => moment(date).formatForUser('dddd');

export const dateFull = (date) =>
    moment(date).formatForUser('dddd D MMMM YYYY');

export const dateFullShort = (date) =>
    moment(date).formatForUser('ddd D MMM YYYY');

export const fullDateTime = (date) =>
    moment(date).formatForUser(DATETIME_FORMAT_FOR_DISPLAY_WITH_AT);

export const fullDateTimeShort = (date) =>
    moment(date).formatForUser(DATETIME_FORMAT_FOR_DISPLAY);

export const isBeforeStartOfToday = (date) =>
    (moment.isMoment(date) ? date.toDate() : date) < dateStartOfDay();
