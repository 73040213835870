import React from 'react';

export default ({ title = 'Teams meeting', ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="M27.0156 10.8359C28.6984 10.8359 30.0625 9.47181 30.0625 7.78906C30.0625 6.10632 28.6984 4.74219 27.0156 4.74219C25.3329 4.74219 23.9688 6.10632 23.9688 7.78906C23.9688 9.47181 25.3329 10.8359 27.0156 10.8359Z"
            fill="#4F59CA"
        />
        <path
            d="M26.3594 25.2734H25.8906C23.3828 25.2734 21.3672 23.2344 21.3672 20.75V13.5781C21.3672 12.8516 21.9531 12.2891 22.6563 12.2891H29.5938C30.3203 12.2891 30.8828 12.875 30.8828 13.5781V20.7266C30.9063 23.2344 28.8672 25.2734 26.3594 25.2734Z"
            fill="#4F59CA"
        />
        <path
            d="M17.4531 10.7656C19.8737 10.7656 21.8359 8.80337 21.8359 6.38281C21.8359 3.96225 19.8737 2 17.4531 2C15.0326 2 13.0703 3.96225 13.0703 6.38281C13.0703 8.80337 15.0326 10.7656 17.4531 10.7656Z"
            fill="#7B82EA"
        />
        <path
            d="M17.4766 29.3281H16.7266C12.7891 29.3281 9.57812 26.1172 9.57812 22.1797V13.3906C9.57812 12.8047 10.0703 12.3125 10.6562 12.3125H23.5469C24.1328 12.3125 24.625 12.8047 24.625 13.3906V22.1797C24.625 26.1172 21.4375 29.3281 17.4766 29.3281Z"
            fill="#7B82EA"
        />
        <path
            d="M14.9219 23.4922H2.5C1.67969 23.4922 1 22.8125 1 21.9922V9.75781C1 8.9375 1.67969 8.25781 2.5 8.25781H14.9219C15.7422 8.25781 16.4219 8.9375 16.4219 9.75781V21.9922C16.4219 22.8125 15.7656 23.4922 14.9219 23.4922Z"
            fill="#4C53BB"
        />
        <path
            d="M7.86719 13.0625H5.42969V11.7734H11.875V13.0625H9.4375V20.1641H7.89062V13.0625H7.86719Z"
            fill="white"
        />
        <path
            opacity="0.2"
            d="M16.4453 12.3125V21.9687C16.4453 22.7891 15.7656 23.4687 14.9453 23.4687H9.69531L10.0469 24.8047H16.2109C17.0313 24.8047 17.7109 24.125 17.7109 23.3047V12.3125H16.4453Z"
            fill="black"
        />
    </svg>
);
