import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { retrieveToken } from './csrf.actions.js';

const useGetCSRFTokenForSession = () => {
    const dispatch = useDispatch();
    return () => dispatch(retrieveToken);
};

export const LoadCSRF = () => {
    const getToken = useGetCSRFTokenForSession();
    useEffect(() => {
        getToken();
    });
};
