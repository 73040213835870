import React from 'react';
import tw, { css, theme } from 'twin.macro';
import { Popover } from '@headlessui/react';
import { useDefaultFloat } from '~modules/common/hooks/useFloat';

const popupStyles = css`
    ${tw`bg-white rounded px-3 py-2 -ml-1 mt-1.5 max-w-[300px] border border-line`}
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    // POPUP ARROW
    &:before {
        ${tw`z-[10] absolute content-[''] w-0 h-0 ml-0 top-[15px] bottom-0 left-[15px] border-[5] border-line bg-white transform`}
        box-shadow: 1px 1px 0px 0px ${theme`colors.line.DEFAULT`};
        border: 5px solid ${theme`colors.white`};
        border-color: ${theme`colors.white`} transparent transparent
            ${theme`colors.white`};
        transform-origin: 0 0;
        transform: rotate(-135deg);
    }
`;

const handlePopoverEnter = (event) => {
    const buttonRef = event.target;
    if (buttonRef) buttonRef.click();
};

const handlePopoverOut = (event) => {
    const buttonRef = event.target;
    const isActive = buttonRef?.getAttribute('data-active') === 'true';
    isActive && buttonRef?.click();
};

const handlePopoverClick = (event) => {
    const buttonRef = event.currentTarget;
    if (buttonRef) {
        const isActive = buttonRef.getAttribute('data-active') === 'true';
        buttonRef.setAttribute('data-active', isActive ? 'false' : 'true');
    }
};

const PrintPreviewFeatureLabel = ({ children, content }) => {
    const { triggerProps, floatProps } = useDefaultFloat();
    return (
        <Popover>
            <>
                <div tw="flex justify-self-auto" {...triggerProps}>
                    <Popover.Button
                        onClick={(event) => handlePopoverClick(event)}
                        onMouseEnter={(ref) => handlePopoverEnter(ref)}
                        onMouseOut={(ref) => handlePopoverOut(ref)}
                        data-active="false"
                    >
                        {children}
                    </Popover.Button>
                </div>

                <div {...floatProps}>
                    <Popover.Panel css={popupStyles}>{content}</Popover.Panel>
                </div>
            </>
        </Popover>
    );
};

export default PrintPreviewFeatureLabel;
