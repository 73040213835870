import { FORM_ERROR } from 'final-form';

import isEmail from 'validator/lib/isEmail';
import { getOnlineMeetingUrl } from '~modules/meeting/location.helpers';

export const required = (value) => (value ? undefined : 'Required');

export const maxLength = (max) => (value) =>
    value && value.length > max
        ? `Must be ${max} characters or less`
        : undefined;

export const maxLength15 = maxLength(15);

export const minLength = (min) => (value) =>
    value && value.length < min
        ? `Must be ${min} characters or more`
        : undefined;

export const minLength2 = minLength(2);

export const number = (value) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-number-properties
    value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = (min) => (value) =>
    value && value < min ? `Must be at least ${min}` : undefined;

export const minValue18 = minValue(18);

export const email = (value) =>
    value && !isEmail(value) ? 'Invalid email address' : undefined;

export const alphaNumeric = (value) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/better-regex
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined;

export const phoneNumber = (value) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/better-regex
    value && !/^(0|[1-9][0-9]{9})$/i.test(value)
        ? 'Invalid phone number, must be 10 digits'
        : undefined;

export async function formSubmissionServerError(err, bodySpecificValidation) {
    if (!err.response) {
        const message =
            err.message === 'Failed to fetch'
                ? 'Failed to communicate with server'
                : err.message;
        return { [FORM_ERROR]: `${message}. Please try again.` };
    }

    try {
        const body = await err.response.json();

        if (body.name !== 'ValidationError') {
            return {
                [FORM_ERROR]: `${
                    body.message || err.message
                }. Please try again.`,
            };
        }

        const bodyErrors = bodySpecificValidation(body.errors);

        return {
            [FORM_ERROR]:
                body.message ||
                `Error during save. Please correct errors and save again.`,
            ...bodyErrors,
        };
    } catch (err) {
        return { [FORM_ERROR]: err.message };
    }
}

export const getTopLevelErrors = (errors) => {
    const errorFields = {};

    if (Object.keys(errors).length) {
        //error is caused by schema validation on the server.
        //schema validation returns array of fields in error
        const topLevelErrors = new RegExp(/^((?!\.).)+$/);
        Object.keys(errors)
            .filter((field) => field.match(topLevelErrors))
            .forEach((field) => {
                errorFields[field] = errors[field];
            });
    }

    return errorFields;
};

export const processBodyErrors = (errors) => {
    const errorFields = getTopLevelErrors(errors);

    return errorFields;
};

export const validateLocationOnline = (value) =>
    value?.length > 2
        ? getOnlineMeetingUrl(value)
            ? undefined
            : 'Online location must be a valid url'
        : undefined;
