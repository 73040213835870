const scrollToRefTop = (ref) => {
    const top = ref?.current?.offsetTop;
    if (!Number.isInteger(top)) return;
    if (top <= 0) return;
    // setTimout pushes this action to the next cycle
    // without it there's inconsistent scrolling behaviour
    setTimeout(() => window.scrollTo(0, top), 0);
    return;
};

/**
 * zIndex generator
 * Usage: zIndex('NAV')
 *
 * @param {String} element The element to get the index of
 * @param {Array} elements The list of elements
 */
const zIndex = (
    element,
    elements = [
        'TOASTS',
        'FILE_PICKER',
        'TOP_LEVEL_POPUPS',
        'MODALS',
        'PRELOADER',
        'SLIDE_PANEL_SMALL',
        'NAV_SMALL_HEADER',
        'DRAWER',
        'TOPBAR',
        'SIDEBAR_SMALL',
        'POPUPS',
        'NAV_SMALL_FOOTER',
        'NAV_LARGE',
        'NOTES_BUTTON',
        'NOTES',
        'SLIDE_PANEL_LARGE',
        'SERIES_PANEL',
        'RTE_HOVER_MENU',
        'STICKY_TAB_BAR',
    ]
) => {
    const index = elements.reverse().indexOf(element);
    if (index < 0) {
        // eslint-disable-next-line no-console
        console.error(`zIndex: ${element} is not in the elements list.`);
    }
    return (index + 1) * 1000;
};

const hasOnlyTouchScreen =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    window.matchMedia && window.matchMedia('(pointer:coarse)').matches;

export { scrollToRefTop, hasOnlyTouchScreen, zIndex };
