import { get, post, deleteRequest } from '../../api/helpers.js';
import { eventModelList, eventModel } from './remoteCalendar.mappers.js';
import {
    meetingModel,
    occurrenceModel,
} from '~modules/meeting/meeting.mappers';

export const getRemoteCalendarMeetings = (profileId) =>
    get(`/api/integration/${profileId}/calendar/events`).then(
        ({ events, users }) => ({ events: eventModelList(events), users })
    );
export const getEventWithSeriesFromRemote = (
    profileId,
    eventId,
    seriesMasterId,
    calendarId
) =>
    get(
        `/api/integration/${profileId}/calendar/event/${encodeURIComponent(
            eventId
        )}?seriesMasterId=${encodeURIComponent(
            seriesMasterId
        )}&calendarId=${encodeURIComponent(calendarId)}`
    ).then(eventModel);
export const getRemoteCalendarLinks = () =>
    get(`/api/integration/calendar/links`);

export const linkNewMeeting = (profileId, workspaceId, payload) =>
    post(
        `/api/integration/${profileId}/calendar/link/${workspaceId}/meeting`,
        payload
    ).then(meetingModel);

export const linkExistingMeeting = (profileId, meetingId, payload) =>
    post(
        `/api/integration/${profileId}/calendar/link/meeting/${meetingId}`,
        payload
    ).then(meetingModel);

export const linkNewOccurrence = (profileId, meetingId, payload) =>
    post(
        `/api/integration/${profileId}/calendar/link/meeting/${meetingId}/occurrence`,
        payload
    ).then(occurrenceModel);

export const linkExistingOccurrence = (profileId, occurrenceId, payload) =>
    post(
        `/api/integration/${profileId}/calendar/link/occurrence/${occurrenceId}`,
        payload
    ).then(occurrenceModel);

export const removeRemoteCalendarLink = (profileId, occurrenceId) =>
    deleteRequest(
        `/api/integration/${profileId}/calendar/link/occurrence/${occurrenceId}`
    );

export const hideRemoteCalendar = (authSource) =>
    post(`/api/integration/calendar/${authSource}/hide`);
