const getOnlineMeetingConfig = (location) => {
    if (!location) return {};
    // Zoom
    // Example: https://zoom.us/j/1234567890
    // Website: https://zoom.us/
    if (location?.includes('zoom.us'))
        return {
            iconName: 'zoom',
            displayName: 'Zoom',
        };
    // Microsoft Teams
    // Example: https://teams.microsoft.com/l/meetup-join/xxx
    // Website: https://www.microsoft.com/en-au/microsoft-teams
    if (location?.includes('teams.microsoft'))
        return {
            iconName: 'teams',
            displayName: 'Teams',
            alternateDisplayNames: ['Microsoft Teams Meeting'],
        };
    // Google Meet
    // Example: https://meet.google.com/lookup/xxxxxxx / https://tel.meet/aaa-aaaa-aaa?pin=
    // Website: https://meet.google.com
    if (location?.includes('meet.google') || location?.includes('tel.meet'))
        return {
            iconName: 'googleMeet',
            displayName: 'Google Meet',
        };
    // Google hangouts
    // Example: https://hangouts.google.com/call/xxxxxxx
    // Website: https://hangouts.google.com
    if (location?.includes('hangouts.google'))
        return {
            iconName: 'googleHangouts',
            displayName: 'Google Hangouts',
        };
    // Slack (could be a huddle or channel)
    // Example: https://app.slack.com/huddle/T00000000/D00000000
    if (location?.includes('app.slack'))
        return {
            iconName: 'slack',
            displayName: 'Slack',
        };
    // Amazon Chime
    // Example: https://chime.aws/1111111111
    // Website: https://aws.amazon.com/chime/
    if (location?.includes('chime.aws'))
        return {
            iconName: 'amazonChime',
            displayName: 'Amazon Chime',
        };
    // Amazon Events
    // Example: https://event-name.virtual.awsevents.com/media/t/1_0abc0ab0/000
    // Website: https://awsevents.com
    if (location?.includes('awsevents.com'))
        return {
            iconName: 'amazonEvents',
            displayName: 'Amazon Events',
        };
    // Whereby
    // Example: https://whereby.com/name // https://company.whereby.com/name
    // Website: https://whereby.com
    if (location?.includes('whereby.com'))
        return {
            iconName: 'whereby',
            displayName: 'Whereby',
        };
    // GoTo Webinar
    // Example: https://global.gotowebinar.com/join/0000000000000000000
    // Website: https://www.goto.com/webinar
    if (location?.includes('gotowebinar.com'))
        return {
            iconName: 'goToWebinar',
            displayName: 'GoTo Webinar',
        };
    // Fallback to default "online" meeting data
    return {
        iconName: 'video',
        displayName: 'Online',
        isDefault: true,
    };
};

export { getOnlineMeetingConfig };
