// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import {
    USER_SEARCH_CANCEL,
    USER_SEARCH_SUCCESS,
    USER_SEARCH_REQUEST,
    USER_SEARCH_ERROR,
    LOGOUT_SUCCESS,
} from '../../common/action.types.js';

const initialState = {
    visible: false,
    results: [],
    loading: false,
};

const userSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_SEARCH_REQUEST:
            return {
                ...initialState,
                term: action.term,
                visible: true,
                loading: true,
            };

        case USER_SEARCH_SUCCESS:
            return {
                ...state,
                results: _([...state.results, ...(action.results || [])])
                    .uniqBy('id')
                    .value(),
                loading: false,
            };

        case USER_SEARCH_CANCEL:
            return initialState;

        case USER_SEARCH_ERROR:
            return {
                ...state,
                loading: false,
            };

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default userSearchReducer;
