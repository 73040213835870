import { useDispatch } from 'react-redux';
import { addMeetingByTemplate } from '~modules/meetingTemplate/meetingTemplate.actions';
import { getOccurrenceSlug, pushTo, replaceTo } from '~modules/navigation';
import { getIntroMeetingOccurrenceFromStore } from '~modules/occurrence/occurrence.selectors';
import { TEMPLATE_INTRO_MEETING } from '~shared/constants';
import { processAddedMeeting } from '~modules/meeting/meeting.actions';
import { loadingModal } from '~modules/modals/loadingModal.actions';
import { pauseUpdates, resumeUpdates } from '~client/common/store';
import { displayError } from '~modules/alert/alert.helpers';
import { useLocation } from 'react-router';

const useViewIntroMeeting = (): ((keepHistory?: boolean) => Promise<void>) => {
    const dispatch = useDispatch();

    return async (keepHistory = true): Promise<void> => {
        const existingIntroMeeting = getIntroMeetingOccurrenceFromStore();

        const goTo = keepHistory ? pushTo : replaceTo;

        if (!existingIntroMeeting) {
            loadingModal.open('Adding introductory meeting…');

            pauseUpdates();

            let navigateToOccurrenceId = '';
            try {
                const meetingData = await addMeetingByTemplate(
                    TEMPLATE_INTRO_MEETING
                );

                const processed = dispatch(
                    // @ts-expect-error
                    // TODO: Investigate error
                    processAddedMeeting(undefined, meetingData)
                ) as unknown as { navigateToOccurrenceId: string };

                navigateToOccurrenceId = processed.navigateToOccurrenceId;
            } catch (error) {
                displayError({
                    title: 'There was an error creating the introductory meeting',
                    error,
                });
            }

            resumeUpdates();

            loadingModal.close();

            navigateToOccurrenceId &&
                goTo(
                    getOccurrenceSlug(
                        { id: navigateToOccurrenceId },
                        { subpage: 'minutes' }
                    )
                );
            return;
        }

        goTo(getOccurrenceSlug(existingIntroMeeting, { subpage: 'minutes' }));
    };
};

const useIsViewingIntroMeeting = (): boolean => {
    const { pathname } = useLocation();
    const existingIntroMeeting = getIntroMeetingOccurrenceFromStore();

    if (!existingIntroMeeting) return false;

    return getOccurrenceSlug(existingIntroMeeting).pathname === pathname;
};

export { useViewIntroMeeting, useIsViewingIntroMeeting };
