import React from 'react';

const GOOGLE_CALENDAR_ID = 'shape-google-calendar';

const GoogleCalendarIconInner = (): JSX.Element => (
    <>
        <path
            d="M2 4C2 2.89543 2.89543 2 4 2H28C29.1046 2 30 2.89543 30 4V8.58824H2V4Z"
            fill="currentColor"
            fillOpacity="0.45"
        />
        <path
            d="M4 30C2.89543 30 2 29.1046 2 28L2 8.58824L8.58824 8.58824L8.58824 30L4 30Z"
            fill="currentColor"
            fillOpacity="0.45"
        />
        <path
            d="M23.4118 30L4 30C2.89543 30 2 29.1046 2 28L2 23.4118L30 23.4118L23.4118 30Z"
            fill="currentColor"
            fillOpacity="0.75"
        />
        <path
            d="M30 23.4118L30 4C30 2.89543 29.1046 2 28 2L23.4118 2L23.4118 30L30 23.4118Z"
            fill="currentColor"
            fillOpacity="0.75"
        />
    </>
);

const GoogleCalendarIcon = ({
    title = '',
    isSymbolRef = false,
    ...rest
}): JSX.Element => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        {isSymbolRef ? (
            <use
                href={`#${GOOGLE_CALENDAR_ID}`}
                xlinkHref={`#${GOOGLE_CALENDAR_ID}`}
            />
        ) : (
            <GoogleCalendarIconInner />
        )}
    </svg>
);

export {
    GoogleCalendarIcon as default,
    GoogleCalendarIconInner,
    GOOGLE_CALENDAR_ID,
};
