import React from 'react';

export default ({ title = 'Google hangouts', ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="M29.131 13.9838C29.131 6.81349 23.2727 1 16.0602 1C8.84771 1 3 6.81349 3 13.9838C3 20.7765 9.22756 26.2845 16.0602 26.2845V31.0685C23.9962 27.0673 29.131 20.6285 29.131 13.9838Z"
            fill="#0F9D58"
        />
        <path
            opacity="0.2"
            d="M22.59 10.5671L18.8096 14.3255L15.0291 10.5671L9.52979 16.0343L21.3149 27.7505C25.2815 24.6355 27.9672 20.8012 28.829 16.7697L22.59 10.5671Z"
            fill="url(#paint0_linear)"
        />
        <path
            d="M17.0923 10.5671V16.0343H20.1855V19.1095C21.5341 19.1095 22.5916 17.4006 22.5916 15.6927V10.5671H17.0923Z"
            fill="#EEEEEE"
        />
        <path
            d="M9.52979 10.5671V16.0343H12.623V19.1095C13.9716 19.1095 15.0291 17.4006 15.0291 15.6927V10.5671H9.52979Z"
            fill="#EEEEEE"
        />
        <path
            opacity="0.4"
            d="M9.52979 10.5671H15.0291V10.7379H9.52979V10.5671ZM17.0916 10.5671H22.591V10.7379H17.0916V10.5671V10.5671Z"
            fill="white"
        />
        <path
            opacity="0.2"
            d="M20.1849 19.1094V19.2802C21.5335 19.2802 22.591 17.5713 22.591 15.8634V15.6926C22.59 17.4005 21.5335 19.1094 20.1849 19.1094ZM20.1849 16.0342H17.0916V16.205H20.1849V16.0342ZM12.623 19.1094L12.7863 19.2716C14.0566 19.1416 15.0291 17.5021 15.0291 15.8634V15.6926C15.0291 17.4005 13.9726 19.1094 12.623 19.1094ZM12.623 16.0342H9.52979V16.205H12.623V16.0342Z"
            fill="#263238"
        />
        <path
            opacity="0.2"
            d="M16.0602 1.17079C23.2727 1.17079 29.1205 6.98428 29.1205 14.1546H29.1271C29.1271 14.0986 29.131 14.0398 29.131 13.9838H29.1205C29.1205 6.81349 23.2727 1 16.0602 1C8.84771 1 3 6.81349 3 13.9838C3 14.0113 3.00191 14.037 3.00191 14.0645C3.05154 6.93494 8.87729 1.17079 16.0602 1.17079Z"
            fill="white"
        />
        <path
            opacity="0.2"
            d="M16.0602 26.2845V26.1137C9.25333 26.1137 3.05154 20.6503 3.00191 13.8937C3.00191 13.924 3 13.9535 3 13.9838C3 20.7765 9.22756 26.2845 16.0602 26.2845ZM29.1205 13.9838C29.0326 20.5668 23.9275 26.9306 16.0602 30.8968V31.0675C23.9962 27.0673 29.1205 20.6285 29.1205 13.9838Z"
            fill="#263238"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="16.2209"
                y1="13.4608"
                x2="25.1195"
                y2="22.4117"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#263238" />
                <stop offset="1" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);
