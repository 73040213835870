import _each from 'lodash/each.js';
import _get from 'lodash/get.js';
import _omit from 'lodash/omit.js';
import ObjectID from 'bson-objectid';
import { push } from 'connected-react-router';
import { FORM_ERROR } from 'final-form';
import {
    ADD_MEETING_SUCCESS,
    ADD_MEETING_WEBSOCKET,
    ADD_MEETING_ERROR,
    EDIT_MEETING_REQUEST,
    EDIT_MEETING_SUCCESS,
    EDIT_MEETING_WEBSOCKET,
    EDIT_MEETING_ERROR,
    MODAL_MEETING_OPEN,
    MODAL_MEETING_CLOSE,
    MEETING_ARCHIVE_STATUS,
    DELETE_MEETING_SUCCESS,
    MEETING_PERMISSIONS_LOADED,
    ADD_OCCURRENCES_WEBSOCKET,
    ADD_OCCURRENCES_ON_MEETING_ADD,
    UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
    CREATE_OCCURRENCE_MINUTES_SUCCESS,
    FETCH_LINKED_ACTION_ITEMS_SUCCESS,
} from '~common/action.types';
import {
    MODAL_TYPE_ARCHIVE_CANCEL,
    MODAL_TYPE_DELETE_CANCEL,
} from '~common/constants';
import { fetchMeetingUsers } from '~modules/preload/preload.actions';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import * as apiClient from './meeting.api.js';
import {
    transformMeetingAddFormForPOST,
    transformMeetingEditFormForPOST,
} from './meeting.mappers.js';
import {
    clearCacheMeetingAddEditFormInitialValues,
    getMeetingFromStoreById,
} from './meeting.selectors.js';
import { formSubmissionServerError } from '~components/formvalidation/formvalidation.helper';
import { processBodyErrors } from '~components/formvalidation/meeting.formvalidation';
import { getPermissionsForMeeting } from '~modules/permission/permission.api';
import { getMeetingAccessLevel } from '~modules/permission/permission.helpers';
import { onPermissionCreateSuccess } from '~modules/permission/permission.actions';
import { addGroupSuccess } from '~modules/group/group.actions';
import {
    getOccurrenceSlug,
    isViewingMeeting,
    replaceTo,
    isDetailPage,
    getMeetingSlug,
} from '~modules/navigation';
import { replaceUrlToDashboard } from '~modules/navigation/navigation.actions';
import { alertError } from '~modules/alert/alert.actions';
import { displaySuccess } from '~modules/alert/alert.helpers';
import { pauseUpdates, resumeUpdates } from '~client/common/store';
import { getUser } from '~modules/user/user.selectors';
import airbrake from '~common/airbrake';
import { updatePageViewState } from '~modules/pageView/pageView.actions.js';

const addMeetingError = (error) => ({
    type: ADD_MEETING_ERROR,
    error,
});

const editMeetingSuccess = (meet) => ({
    type: EDIT_MEETING_SUCCESS,
    meeting: meet,
    meetingId: meet.id,
});

export const openAddMeetingModal = ({ group, date = new Date() }) => ({
    type: MODAL_MEETING_OPEN,
    group,
    date,
});

export const closeMeetingModal = () => ({
    type: MODAL_MEETING_CLOSE,
});

export const openEditModal = ({
    group,
    meeting,
    editKey,
    remoteLink,
    workspaceId,
}) => ({
    type: MODAL_MEETING_OPEN,
    mode: 'meeting-series',
    group,
    meeting,
    editKey,
    remoteLink,
    workspaceId,
});

const loadingPermissions = new Set();

/*
// TODO: This needs some tweaks. We might find there are issues if we memoize this, with data being stale.
// So we probably do just want to throttle it, but I'm not sure the throttling was working.
export const loadMeetingPermissionsThrottled = _memoize(
    (meeting) => (
        console.log('triggering...'),
        _throttle(loadMeetingPermissions, 10000, {
            leading: true,
            trailing: true,
        })(meeting)
    ),
    (meeting) => {
        console.log({ meeting }, 'memoize triggered');
        return meeting.id;
    }
);
*/

export function loadMeetingPermissions(meeting = {}) {
    return (dispatch, getState) => {
        // may be that user is trying to view a meeting they don't have access to, or permissions have been removed.
        // therefore don't load permissions.
        if (!meeting.id) return;

        const state = getState();
        const { user } = state;

        const { isAdmin } = getMeetingAccessLevel(state, meeting, user);

        if (loadingPermissions.has(meeting.id)) return; // permissions are already loading for this meeting.
        loadingPermissions.add(meeting.id);
        return getPermissionsForMeeting(meeting.id)
            .then((permissionsFromAPI) => {
                //GOTCHA user email is only returned if user is Admin. Therefore
                //       merge with already known list of users to get the proper user profile.
                const permissions = isAdmin
                    ? permissionsFromAPI
                    : permissionsFromAPI.map((permission) => {
                          const user =
                              state.userList.users.find(
                                  (user) => user.id === permission.user.id
                              ) || permission.user;
                          return {
                              ...permission,
                              user,
                          };
                      });

                return dispatch({
                    type: MEETING_PERMISSIONS_LOADED,
                    meetingId: meeting.id,
                    permissions,
                });
            })
            .catch((error) => {
                // May be if the authorisation fails, server is unavailable, version expired
                // errors are handled in the get function
                dispatch(
                    alertError({
                        id: 'loadMeetingPermissions',
                        title: 'Series permissions could not be loaded',
                        error,
                    })
                );
            })
            .finally(() => loadingPermissions.delete(meeting.id));
    };
}

const archiveStatusSuccess = (meet) => ({
    type: MEETING_ARCHIVE_STATUS,
    meeting: meet,
    meetingId: meet.id,
});

export const onMeetingAddSuccess = (meeting) => ({
    type: ADD_MEETING_WEBSOCKET,
    meeting,
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line require-await
export const onMeetingUpdateSuccess = (meeting) => async (dispatch) => {
    dispatch({
        type: EDIT_MEETING_WEBSOCKET,
        meeting,
    });
    clearCacheMeetingAddEditFormInitialValues();
};

export const openArchiveModal = (group, meeting, content) => (dispatch) => {
    const promise = () => dispatch(archiveMeeting(meeting));

    dispatch(
        openConfirm({
            header: 'Archive series',
            modalType: MODAL_TYPE_ARCHIVE_CANCEL,
            content,
            promise,
        })
    );
};

export const openDeleteSeriesModal = (meeting, content) => (dispatch) => {
    const promise = () => dispatch(deleteMeeting(meeting));

    dispatch(
        openConfirm({
            header: 'Delete series',
            modalType: MODAL_TYPE_DELETE_CANCEL,
            content,
            promise,
        })
    );
};

export const archiveMeeting = (meeting) => {
    return async (dispatch) => {
        try {
            const newMeeting = await apiClient.archiveMeeting(meeting);
            return dispatch(archiveStatusSuccess(newMeeting));
        } catch (error) {
            dispatch(
                alertError({
                    id: 'archiveMeeting',
                    title: 'Series could not be archived',
                    error,
                })
            );
        }
    };
};

export const deleteMeeting = (meeting) => {
    return async (dispatch) => {
        const locationBeforeDelete = location;
        try {
            // optimistically redirect
            dispatch(replaceUrlToDashboard());
            // because there are lots of awaits on the server, we will get websocket events before the api returns the delete operation.
            // Therefore pause websocket updates so user doesn't get a jerky screen experience while topics etc... disappear off the screen
            pauseUpdates();
            const deletedMeeting = await apiClient.deleteMeeting(meeting);
            dispatch(
                onMeetingDelete(deletedMeeting, { wasViewingMeeting: true })
            );
        } catch (error) {
            replaceTo(locationBeforeDelete);
            dispatch(
                alertError({
                    id: `deleteMeeting-${meeting.id}`,
                    title: 'Series could not be deleted',
                    error,
                })
            );
        } finally {
            resumeUpdates();
        }
    };
};

export const updateUserDisplayName = (meetingId, userId, displayName) => {
    return async (dispatch) => {
        const userBeforeUpdate = getUser(userId);
        try {
            dispatch({
                type: UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
                userProfile: {
                    id: userId,
                    email: userBeforeUpdate.email,
                    displayName,
                },
            });
            const updated = await apiClient.updateUserDisplayName(
                meetingId,
                userId,
                displayName
            );
            return updated;
        } catch (error) {
            dispatch({
                type: UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
                userProfile: {
                    id: userId,
                    email: userBeforeUpdate.email,
                    displayName: userBeforeUpdate.displayName,
                },
            });
            dispatch(
                alertError({
                    id: 'updateUserDisplayName',
                    title: 'Display name could not be updated',
                    error,
                })
            );
            throw error;
        }
    };
};

export const updateUserEmail = (meetingId, userId, email) => {
    return async (dispatch) => {
        const userBeforeUpdate = getUser(userId);
        try {
            dispatch({
                type: UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
                userProfile: {
                    id: userId,
                    email: userBeforeUpdate.email,
                },
            });
            const updated = await apiClient.updateUserEmail(
                meetingId,
                userId,
                email
            );
            return updated;
        } catch (error) {
            dispatch({
                type: UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
                userProfile: {
                    id: userId,
                    email: userBeforeUpdate.email,
                    displayName: userBeforeUpdate.displayName,
                },
            });
            dispatch(
                alertError({
                    id: 'updateUserEmail',
                    title: 'Email could not be updated',
                    error,
                })
            );
            throw error;
        }
    };
};

export const restoreMeeting = (meeting) => async (dispatch) => {
    try {
        const newMeeting = await apiClient.restoreMeeting(meeting);
        return dispatch(archiveStatusSuccess(newMeeting));
    } catch (error) {
        dispatch(
            alertError({
                id: 'restoreMeeting',
                title: 'Series could not be restored',
                error,
            })
        );
    }
};

/**
 * When the Meeting modal or Occurrence modal is used to add a new meeting,
 * The group may come back in a variety of formats, depending on whether it's an existing group,
 * Whether it's linked with an existing meeting that's in a group.
 * This function will extract the correct fields so they can be submitted to the server in the right format.
 * @param {Object} state Redux state object.
 * @param {Object} values Final-form values object, from the onSubmit event of the form.
 */
const getGroupFields = (state, values) => {
    if (values.groupId) {
        return {
            groupId: values.groupId,
            groupName: values.groupName,
        };
    }

    if (values.group && ObjectID.isValid(values.group.id)) {
        return {
            groupId: values.group.id,
            groupName: values.group.title,
        };
    }

    // if no group found, no group will be sent to server, and default group will be used.
};

export const onMeetingSave =
    (workspace, values, formApi) => async (dispatch, getState) => {
        try {
            if (typeof values.id === 'undefined') {
                // Create a new meeting.
                // Meeting must be added to a group. If group has come from the form, use that,
                // else look for the default meeting group.
                // If that's not found (because user has renamed the default group) then add it again.
                const state = getState();

                const { meeting, navigateToOccurrenceId } = await dispatch(
                    onAddMeeting(workspace, {
                        ...values,
                        ...getGroupFields(state, values),
                    })
                );

                if (navigateToOccurrenceId) {
                    return dispatch(
                        push(getOccurrenceSlug({ id: navigateToOccurrenceId }))
                    );
                }
                return dispatch(push(getMeetingSlug(meeting.id)));
            } else {
                await dispatch(onEditMeeting(values, formApi));
            }
        } catch (error) {
            try {
                const formData = {
                    ...values,
                    recurrenceDetail: {
                        ...values.recurrenceDetail,
                        endDate: values.recurrenceDetail?.endDate?.toDate?.(),
                        rangeStartDate:
                            values.recurrenceDetail?.rangeStartDate?.toDate?.(),
                        startDate:
                            values.recurrenceDetail?.startDate?.toDate?.(),
                    },
                };
                airbrake.notify({
                    error: new Error(
                        error?.[FORM_ERROR] || 'Error saving meeting'
                    ),
                    params: { workspace, formData, errorDetail: error },
                });
            } catch (error2) {
                airbrake.notify({
                    error: error2,
                    params: { workspace, errorDetail: error },
                });
            }
            return error;
        }
    };

export const processAddedMeeting =
    (createParams, addedMeeting) => (dispatch) => {
        const group = addedMeeting.__group;
        const permissions = addedMeeting.__permissions;
        const occurrences = addedMeeting.__occurrences;
        const minuteItems = addedMeeting.__minuteItems;
        const actionItems = addedMeeting.__actionItems;

        group && dispatch(addGroupSuccess(group));
        _each(permissions, (p) => dispatch(onPermissionCreateSuccess(p)));

        // determine which occurrenceId to navigate to
        const navigateToOccurrenceId =
            addedMeeting.mostRecentPastOccurrenceId ||
            addedMeeting.mostRecentFutureOccurrenceId;

        const meeting = _omit(addedMeeting, [
            '__group',
            '__permissions',
            '__occurrences',
            '__minuteItems',
            '__actionItems',
            'mostRecentPastOccurrenceId',
            'mostRecentFutureOccurrenceId',
        ]);

        dispatch({ type: ADD_MEETING_SUCCESS, meeting });
        clearCacheMeetingAddEditFormInitialValues();

        occurrences?.length > 0 &&
            dispatch({ type: ADD_OCCURRENCES_ON_MEETING_ADD, occurrences });

        minuteItems?.length > 0 &&
            dispatch({ type: CREATE_OCCURRENCE_MINUTES_SUCCESS, minuteItems });

        actionItems?.length > 0 &&
            dispatch({ type: FETCH_LINKED_ACTION_ITEMS_SUCCESS, actionItems });

        createParams?.newUsers?.length > 0 && dispatch(fetchMeetingUsers());

        return {
            meeting,
            occurrences,
            permissions,
            group,
            navigateToOccurrenceId,
        };
    };

export const processUpdatedMeeting = (updatedMeeting) => (dispatch) => {
    const occurrences = updatedMeeting.__occurrences;

    const meeting = _omit(updatedMeeting, ['__occurrences']);

    dispatch({
        type: EDIT_MEETING_WEBSOCKET,
        meeting,
    });

    occurrences?.length &&
        dispatch({
            type: ADD_OCCURRENCES_WEBSOCKET,
            occurrences,
        });

    return {
        meeting,
        occurrences,
    };
};

const onAddMeeting = (workspace, values) => async (dispatch) => {
    const createParams = transformMeetingAddFormForPOST(values);

    try {
        // pause is before the api call, as during the api call we may receive multiple websocket events that update redux
        pauseUpdates();

        const addedMeeting = await apiClient.addMeeting(
            createParams,
            workspace.id || workspace
        );

        const { meeting, group, permissions, navigateToOccurrenceId } =
            dispatch(processAddedMeeting(createParams, addedMeeting));

        resumeUpdates();
        return { meeting, group, permissions, navigateToOccurrenceId };
    } catch (err) {
        resumeUpdates();
        const newError = await formSubmissionServerError(
            err,
            processBodyErrors
        );
        dispatch(addMeetingError(newError));
        throw newError;
    }
};

/**
 * Updating a meeting using final-form
 * @param {object} values the values from the final-form form
 * @param {object} formApi the final form api so you can get the initial form state to identify changes
 */
const onEditMeeting = (values, formApi) => async (dispatch) => {
    const { initialValues } = formApi.getState();
    const { id } = values;
    const meet = transformMeetingEditFormForPOST(values, initialValues);

    try {
        const detailPage = isDetailPage(location.pathname);
        const options = {
            activeOccurrenceId: detailPage?.id,
        };
        const updatedMeeting = await apiClient.editMeeting(id, meet, options);

        if ('fileLink' in meet) {
            // file link has updated. Update pageState.It could be removed (set to null)
            dispatch(
                updatePageViewState({
                    id,
                    hasAccessToFileLink: Boolean(meet.fileLink),
                })
            );
        }

        dispatch(editMeetingSuccess(updatedMeeting));
        clearCacheMeetingAddEditFormInitialValues();

        if (_get(meet, 'newUsers.length', 0) > 0) {
            dispatch(fetchMeetingUsers());
        }

        return updatedMeeting;
    } catch (err) {
        const newError = await formSubmissionServerError(
            err,
            processBodyErrors
        );
        throw newError;
    }
};

/**
 * A function to update a meeting without using final-form
 */
export const onEditMeetingDirect =
    (meetingId, values) => (dispatch, getState) => {
        const state = getState();
        const meetingBeforeUpdate = getMeetingFromStoreById(state, meetingId);

        // optimistic update
        dispatch({
            type: EDIT_MEETING_REQUEST,
            meeting: { id: meetingId, ...values },
        });

        dispatch(
            editMeetingDirectBackground(meetingId, values, meetingBeforeUpdate)
        );
    };

const editMeetingDirectBackground =
    (meetingId, values, meetingBeforeUpdate) => async (dispatch) => {
        try {
            const detailPage = isDetailPage(location.pathname);
            const options = {
                activeOccurrenceId: detailPage?.id,
            };
            const updatedMeeting = await apiClient.editMeeting(
                meetingId,
                values,
                options
            );

            dispatch(editMeetingSuccess(updatedMeeting));

            return updatedMeeting;
        } catch (error) {
            dispatch({
                type: EDIT_MEETING_ERROR,
                meeting: meetingBeforeUpdate,
            });
            dispatch(
                alertError({
                    id: 'updateMeetingDirectBackground',
                    title: 'Series could not be updated',
                    error,
                })
            );
        }
    };

export const onMeetingDelete =
    (deletedMeeting, { isUpdateEvent, wasViewingMeeting } = {}) =>
    (dispatch) => {
        const isViewingDeletedMeeting = isViewingMeeting(deletedMeeting);

        // GOTCHA moving the user to the dashboard must be done before redux updates, or the user gets the flash of a "meeting not found"
        isViewingDeletedMeeting && dispatch(replaceUrlToDashboard());

        deletedMeeting?.id &&
            dispatch({
                type: DELETE_MEETING_SUCCESS,
                meeting: deletedMeeting,
            });

        if (!isViewingDeletedMeeting && !wasViewingMeeting) return;

        !isUpdateEvent &&
            displaySuccess({
                id: `deleted-meeting-success-${deletedMeeting.id}`,
                title: `The “${deletedMeeting.title}” series was deleted`,
            });
    };

/**
 * Load a meeting from the server. May dispatch to redux immediately, or return a redux action for later dispatch.
 * @param {String} id meeting.id to load
 * @param {Object} [options]
 * @param  {Boolean} [options.dispatchImmediately = true] determine whether to dispatch the api result immediately, or instead return a redux action that can be dispatched later
 * @returns {Object} meeting returned from the api, or a redux action that can be dispatched
 */
export const loadMeeting =
    (id, { dispatchImmediately = true } = {}) =>
    async (dispatch) => {
        const payload = await apiClient.getMeeting(id);
        if (!dispatchImmediately) {
            return onMeetingAddSuccess(payload);
        }

        dispatch(onMeetingAddSuccess(payload));
        return payload;
    };
