import moment from 'moment';
import { pluralize } from '~common/utils';
import {
    RECURRENCE_SCHEDULED,
    RECURRENCE_ONE_OFF,
    RECURRENCE_NEVER,
    RECURRENCE_NOT_SCHEDULED,
} from '~common/constants';

import { getOnlineMeetingConfig } from '~shared/meeting/meeting.helpers';

const isRepeatingMeeting = (meetingOrOccurrence) =>
    meetingOrOccurrence?.recurrence === RECURRENCE_SCHEDULED;

const isNonRepeatingMeeting = (meetingOrOccurrence) =>
    !isRepeatingMeeting(meetingOrOccurrence);

const isRecurringMeeting = (meeting) =>
    isRepeatingMeeting(meeting) &&
    ![RECURRENCE_NEVER, RECURRENCE_NOT_SCHEDULED].includes(
        meeting.pattern.occurs
    );

const isOneOffMeeting = (meetingOrOccurrence) =>
    meetingOrOccurrence?.recurrence === RECURRENCE_ONE_OFF;

const isArchivedMeeting = (meetingOrOccurrence) => meetingOrOccurrence.archived;

const getMeetingLocationLabel = (location, locationOnline) => {
    const onlineLocationDisplayName =
        getOnlineMeetingConfig(locationOnline).displayName;
    return [location, onlineLocationDisplayName].filter(Boolean).join(' / ');
};

const getFormattedMeetingDuration = (startDate, endDate) => {
    const duration = Math.round(
        moment.duration(endDate.diff(startDate)).asMinutes()
    );
    const durationHours =
        Math.round(
            (moment.duration(endDate.diff(startDate)).asHours() +
                Number.EPSILON) *
                100
        ) / 100;
    return duration >= 60
        ? `${durationHours} ${pluralize('hour', 'hours', durationHours)}`
        : `${duration} min`;
};

export {
    isRepeatingMeeting,
    isNonRepeatingMeeting,
    isRecurringMeeting,
    isOneOffMeeting,
    isArchivedMeeting,
    getOnlineMeetingConfig,
    getMeetingLocationLabel,
    getFormattedMeetingDuration,
};
