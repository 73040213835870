import React, { useCallback, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ObjectID from 'bson-objectid';
import {
    useMeetings,
    useUser,
    useIntegrationProfileByAuthSource,
} from '~hooks';
import { getActiveRemoteCalendarIntegrationProfiles } from '~modules/integration/integration.selectors';
import { toggleHideRemoteSeries } from '~modules/integration/integration.actions';
import { getCRMCustomers } from '~modules/remoteCRM/remoteCRM.selectors';
import { integrationProfileStatusMeta } from '~shared/integration/integration.helper';
import { mapAttendeesAndPermissionsSettings } from '~modules/meeting/meeting.mappers';
import {
    RECURRENCE_ONE_OFF,
    RECURRENCE_SCHEDULED,
    RECURRENCE_NOT_SCHEDULED,
    MODAL_TYPE_UNLINK_CANCEL,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD,
    MEETING_CREATED_MODAL_EVENT_MATCH,
} from '~common/constants';
import {
    mapSeriesToMeetingForCreate,
    mapSeriesToMeetingForUpdate,
    mapEventToOccurrenceForCreate,
    mapEventToOccurrenceForUpdate,
    mapSeriesForApi,
} from './remoteCalendar.mappers.js';
import {
    linkNewMeeting,
    linkExistingMeeting,
    linkNewOccurrence,
    linkExistingOccurrence,
    removeRemoteCalendarLink,
    hideRemoteCalendarCta,
} from './remoteCalendar.actions.js';
import {
    getEventsFromRemoteCalendars,
    getLinksForRemoteCalendarEvents,
} from './remoteCalendar.selectors.js';
import {
    openConfirm,
    cancelConfirm,
} from '~modules/modals/confirmModal.actions';
import { ConnectionModalLoader } from '~modules/remoteCalendar/components/ConnectionModalHelpers';
import { getIntegrationMeta } from '~modules/integration/integration.helpers';
import { getCRMConfig } from '~shared/integration/integration.mapper.remoteCRM.js';

/**
 * Find an existing integrationLink by using either a remote calendar event id, or an occurrenceId
 * @param {String} id a remoteId or an occurrenceId
 * @returns Object an IntegrationLink which is a connection between a remoteId and an occurrence
 */
const useRemoteCalendarMeetingLink = (id) => {
    const links = useSelector(getLinksForRemoteCalendarEvents);
    const link = links[id];
    return link;
};

/**
 * Using a remote calendar event Id, get that event in an occurrence-mapped format
 * @param {String} id this is the id of a remote event... i.e. a remoteId
 * @returns Object which is a remote calendar event mapped as an occurrence
 */
const useEventFromRemote = (id) => {
    const eventList = useSelector(getEventsFromRemoteCalendars);
    // NOTE: events in the eventList are keyed by both the remoteId and occurrenceId
    const event = eventList.find((e) => e.id === id);
    return event;
};

const useRemoteCalendarSubmit = () => {
    const dispatch = useDispatch();
    const meetings = useMeetings();

    return useCallback(
        ({ profile, values, calendarMatches, event, series }) => {
            const { meeting: matchedMeeting, occurrence: matchedOccurrence } =
                calendarMatches.find(
                    (match) => match.meeting.id === values.selectedMeeting
                ) || {};
            const selectedMeeting = values.selectedMeeting
                ? matchedMeeting ||
                  meetings.find((m) => m.id === values.selectedMeeting)
                : null;

            const { attendees, permissions, isPrivate } =
                mapAttendeesAndPermissionsSettings(
                    values?.attendeesAndPermissions
                );
            const {
                groupId,
                groupName,
                keepSynchronised,
                selectedWorkspace: workspace,
                createdSourceLocation = MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD,
                createdModal = MEETING_CREATED_MODAL_EVENT_MATCH,
            } = values;

            const newUsers = permissions
                .filter((permission) => !ObjectID.isValid(permission.user))
                .map((permission) => ({
                    email: permission.user,
                    displayName: permission.displayName,
                }));

            const isRemoteEventRecurring =
                event.recurrence === RECURRENCE_SCHEDULED;
            const isRemoteEventSingleInstance =
                event.recurrence === RECURRENCE_ONE_OFF;

            let action;
            if (isRemoteEventSingleInstance) {
                if (matchedOccurrence) {
                    // TBC...
                    // selectedOccurrence may be a one-off, in which case we need to link an occurrence
                    // selectedOccurrence may be an ad-hoc/recurring, in which case we need to link the meeting
                    // linkExistingOccurrence or linkExistingMeeting... if meeting is one-off?
                    action = 'linkExistingOccurrence';
                } else if (selectedMeeting) {
                    action = 'linkNewOccurrence';
                } else {
                    action = 'linkNewMeeting';
                }
            } else if (isRemoteEventRecurring) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line unicorn/prefer-ternary
                if (selectedMeeting) {
                    action = 'linkExistingMeeting';
                } else {
                    action = 'linkNewMeeting';
                }
            }

            switch (action) {
                case 'linkExistingOccurrence': {
                    const data = mapEventToOccurrenceForUpdate({
                        event,
                        occurrence: matchedOccurrence,
                        attendees,
                        permissions,
                        isPrivate,
                        newUsers,
                        createdSourceLocation,
                        createdModal,
                    });
                    return dispatch(
                        linkExistingOccurrence({
                            profile,
                            occurrence: data,
                            event,
                            keepSynchronised,
                        })
                    );

                    // break;
                }
                case 'linkExistingMeeting': {
                    const data = mapSeriesToMeetingForUpdate({
                        series,
                        event,
                        meeting: selectedMeeting,
                        attendees,
                        permissions,
                        isPrivate,
                        newUsers,
                        createdSourceLocation,
                        createdModal,
                    });
                    return dispatch(
                        linkExistingMeeting({
                            profile,
                            meeting: data,
                            series: mapSeriesForApi(series),
                            occurrence: matchedOccurrence,
                            event,
                            keepSynchronised,
                        })
                    );
                }
                case 'linkNewOccurrence': {
                    const data = mapEventToOccurrenceForCreate({
                        title: values.title,
                        meeting: selectedMeeting,
                        event,
                        attendees,
                        permissions,
                        isPrivate,
                        newUsers,
                        createdSourceLocation,
                        createdModal,
                    });
                    return dispatch(
                        linkNewOccurrence({
                            title: values.title,
                            profile,
                            meeting: selectedMeeting,
                            occurrence: data,
                            event,
                            keepSynchronised,
                        })
                    );
                }
                case 'linkNewMeeting': {
                    let crmLinks, title, firstMeetingTitle, recurrence;

                    if (values.title) {
                        title = values.title;
                        firstMeetingTitle = values.title;
                    }
                    if (values.selectedCRMData) {
                        // user has chosen to link with CRM
                        const { crmData } = getCRMCustomers({ workspace });
                        const crmMeeting = crmData.find(
                            (data) => data.remoteId === values.selectedCRMData
                        );

                        if (crmMeeting) {
                            crmLinks = {
                                authSource: crmMeeting.authSource,
                                remoteId: crmMeeting.remoteId,
                                link: crmMeeting.link,
                            };
                            const crmConfig = getCRMConfig(crmLinks);
                            if (crmConfig.customerNameIsDefaultMeetingTitle) {
                                title = crmConfig.getMeetingTitle(
                                    crmMeeting.displayTitle
                                );
                            }
                            recurrence =
                                series.recurrence === RECURRENCE_ONE_OFF
                                    ? RECURRENCE_NOT_SCHEDULED
                                    : undefined;
                        }
                    }

                    const data = mapSeriesToMeetingForCreate({
                        series,
                        attendees,
                        permissions,
                        isPrivate,
                        newUsers,
                        groupId,
                        groupName,
                        event,
                        crmLinks,
                        title,
                        firstMeetingTitle,
                        recurrence,
                        createdSourceLocation,
                        createdModal,
                    });

                    return dispatch(
                        linkNewMeeting({
                            profile,
                            workspace,
                            meeting: data,
                            series: mapSeriesForApi(series),
                            event,
                            keepSynchronised,
                        })
                    );
                }
            }
        },
        [dispatch, meetings]
    );
};

const useUnlinkFromRemoteCalendar = () => {
    const dispatch = useDispatch();

    return useCallback(
        ({ remoteLink, occurrence, meeting }) => {
            const promise = () =>
                dispatch(
                    removeRemoteCalendarLink({
                        remoteLink,
                        occurrence,
                        meeting,
                    })
                );
            // if you are unlinking an occurrence that is scheduled, you are unlinking all scheduled occurrences in the meeting.
            // if you are unlinking an ad-hoc you are unlinking only the single occurrence
            // if you are unlinking a one-off, you are unlinking both the meeting/occurrence but we will indicate to user as though it's only this meeting.
            const unlinkTarget =
                occurrence.recurrence === RECURRENCE_SCHEDULED ? (
                    <>
                        {' '}
                        this meeting series “<strong>{meeting.title}</strong>”
                    </>
                ) : (
                    <>
                        {' '}
                        this meeting “<strong>{occurrence.title}</strong>”
                    </>
                );

            dispatch(
                openConfirm({
                    header: `Unlink from ${
                        getIntegrationMeta(remoteLink.authSource).shortTitle
                    }`,
                    content: (
                        <>
                            Please confirm that you would like to unlink
                            {unlinkTarget} from your remote calendar. The
                            existing content will remain unchanged in MinuteMe.
                            <br /> <br /> Do you want to continue?
                        </>
                    ),
                    promise,
                    modalType: MODAL_TYPE_UNLINK_CANCEL,
                })
            );
        },
        [dispatch]
    );
};

const useRemoteCalendarLinksByProfile = (profile) => {
    const linksData = useSelector(
        ({ remoteCalendars }) => remoteCalendars.links.data
    );
    return Object.values(linksData).filter(
        ({ profileId }) => profileId === profile.id
    );
};

const useRemoteCalendarHideCta = (authSource) => {
    const dispatch = useDispatch();

    return () => {
        dispatch(hideRemoteCalendarCta(authSource));
    };
};

const useRemoteCalendarToggleHideEvent = (integrationId, event) => {
    const dispatch = useDispatch();

    return () => {
        dispatch(toggleHideRemoteSeries(integrationId, event));
    };
};

const ConnectionModal = React.lazy(() =>
    import('~modules/remoteCalendar/components/ConnectionModal.js')
);

const useOpenCalendarConnectionModal = () => {
    const dispatch = useDispatch();
    const closeModal = () => dispatch(cancelConfirm());

    return (calendarType) => {
        const content = (
            <Suspense fallback={<ConnectionModalLoader />}>
                <ConnectionModal {...{ closeModal, calendarType }} />
            </Suspense>
        );

        dispatch(
            openConfirm({
                header: 'Connect calendar',
                helpLink: `${calendarType}-integration`,
                modalType: null,
                hasNoPadding: true,
                size: 'small',
                content,
            })
        );
    };
};

const useRemoteCalendarsActive = () => {
    const activeCalendarProfiles = useSelector(
        getActiveRemoteCalendarIntegrationProfiles
    );
    return activeCalendarProfiles;
};

const useRemoteCalendarAvailable = () => {
    const loggedInUser = useUser();
    const validCalendarType =
        loggedInUser?.emailDomainMeta?.validRemoteCalendarAuthSources?.[0];
    const calendar = useIntegrationProfileByAuthSource(validCalendarType);

    // No integration for this calendar yet so make it available
    if (!calendar) return validCalendarType;

    const { isHidden, isActive } = integrationProfileStatusMeta(calendar);
    if (isHidden || isActive) return;

    return validCalendarType;
};

const useRemoteCalendarAdvertise = () => {
    const activeCalendars = useRemoteCalendarsActive();
    const availableRemoteCalendar = useRemoteCalendarAvailable();

    if (activeCalendars.length > 0) return;
    if (!availableRemoteCalendar) return;

    return availableRemoteCalendar;
};

export {
    useRemoteCalendarSubmit,
    useRemoteCalendarMeetingLink,
    useRemoteCalendarLinksByProfile,
    useRemoteCalendarHideCta,
    useRemoteCalendarToggleHideEvent,
    useEventFromRemote,
    useUnlinkFromRemoteCalendar,
    useOpenCalendarConnectionModal,
    useRemoteCalendarsActive,
    useRemoteCalendarAdvertise,
    useRemoteCalendarAvailable,
};
