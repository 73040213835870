import _omit from 'lodash/omit.js';
import {
    MODAL_ARCHIVE_CHOICE_OPEN,
    MODAL_ARCHIVE_CHOICE_CLOSE,
} from '~common/action.types';

const initialState = { visible: false };

const archiveReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_ARCHIVE_CHOICE_OPEN:
            return {
                ...state,
                ..._omit(action, 'type'),
                visible: true,
            };

        case MODAL_ARCHIVE_CHOICE_CLOSE:
            return initialState;

        default:
            return state;
    }
};

export default archiveReducer;
