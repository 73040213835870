import {
    AUTHSOURCE_AZUREAD_TASKS,
    AUTHSOURCE_GOOGLE_TASKS,
    AUTHSOURCE_OUTLOOK_CALENDAR,
    AUTHSOURCE_GOOGLE_CALENDAR,
    AUTHSOURCE_SMOKEBALL_AU,
    AUTHSOURCE_ASANA,
} from '~shared/constants';
import GoogleTasksIcon from '~components/icons/GoogleTasksIcon';
import MicrosoftToDoIcon from '~components/icons/MicrosoftToDoIcon';
import AsanaIcon from '~client/components/icons/AsanaIcon';
import AsanaMutedIcon from '~icons/asana';
import OutlookCalendarIcon from '~components/icons/OutlookCalendarIcon';
import SmokeballIcon from '~components/icons/SmokeballIcon';
import GoogleCalendarIcon from '~components/icons/GoogleCalendarIcon';
import GoogleCalendarMutedIcon from '~icons/googleCalendar';
import OutlookCalendarMutedIcon from '~icons/outlook';
import SmokeballMutedIcon from '~icons/smokeball';
import { HELP_LINKS } from '~modules/help/HelpLink';
import { HELP_SITE_URL } from '~common/constants';

export type IntegrationMeta = {
    authSource:
        | typeof AUTHSOURCE_OUTLOOK_CALENDAR
        | typeof AUTHSOURCE_GOOGLE_CALENDAR
        | typeof AUTHSOURCE_SMOKEBALL_AU
        | typeof AUTHSOURCE_AZUREAD_TASKS
        | typeof AUTHSOURCE_GOOGLE_TASKS
        | typeof AUTHSOURCE_ASANA;
    title: string;
    shortTitle: string;
    byline: string;
    description: string;
    helpUrl: string;
    webUrl: (profile: { email: string }) => string;
    linkUrl: (profile: { email: string }, link: string) => string;
    accentColor: string;
    accentColorMuted: string;
    Icon: (props: any) => JSX.Element;
    IconMuted: (props: any) => JSX.Element | null;
};

const toHelpUrl = (url: string): string => `${HELP_SITE_URL}${url}`;

const outlookWebURL = (profile: { email: string }): string =>
    `https://outlook.office.com/calendar/${profile.email}/`;
const googleCalendarWebURL = (profile: { email: string }): string =>
    `https://calendar.google.com/calendar?authuser=${profile.email}`;
const outlookLinkURL = (_profile: { email: string }, link: string): string =>
    link;
const googleCalendarLinkURL = (
    profile: { email: string },
    link: string
): string => `${link}&authuser=${profile.email}`;
const emptyURL = (): string => '';

const integrationMeta = {
    [AUTHSOURCE_OUTLOOK_CALENDAR]: (): IntegrationMeta => ({
        authSource: AUTHSOURCE_OUTLOOK_CALENDAR,
        title: 'Outlook Calendar',
        shortTitle: 'Outlook',
        byline: 'The perfect integration for your Outlook Calendar',
        description: `
        <p>Use meeting events from your Outlook Calendar</p>
        <p>Link up your calendar with MinuteMe and make managing your meetings so much easier:</p>
        <ul>
        <li>Select which Outlook Calendar events to apply to MinuteMe</li>
        <li>Select recurring events as a bulk transfer to MinuteMe</li>
        <li>Select one-off events to add to an existing or new group of meetings in MinuteMe</li>
        <li>Observe the automated transfer of meeting details to&nbsp;MinuteMe</li>
        </ul>
        `,
        helpUrl: toHelpUrl(HELP_LINKS['outlook-calendar-integration']),
        webUrl: outlookWebURL,
        linkUrl: outlookLinkURL,
        accentColor: '#026AC3',
        accentColorMuted: '#A4D6FF',
        Icon: OutlookCalendarIcon,
        IconMuted: OutlookCalendarMutedIcon,
    }),
    [AUTHSOURCE_GOOGLE_CALENDAR]: (): IntegrationMeta => ({
        authSource: AUTHSOURCE_GOOGLE_CALENDAR,
        title: 'Google Calendar',
        shortTitle: 'Google Calendar',
        byline: 'The perfect integration for your Google Calendar',
        description: `
        <p>Use meeting events from your Google Calendar</p>
        <p>Link up your calendar with MinuteMe and make managing your meetings so much easier:</p>
            <ul>
            <li>Select which Google Calendar events to apply to MinuteMe</li>
                <li>Select recurring events as a bulk transfer to MinuteMe</li>
                <li>Select one-off events to add to an existing or new group of meetings in MinuteMe</li>
                <li>Observe the automated transfer of meeting details to&nbsp;MinuteMe</li>
                </ul>
                `,
        helpUrl: toHelpUrl(HELP_LINKS['google-calendar-integration']),
        webUrl: googleCalendarWebURL,
        linkUrl: googleCalendarLinkURL,
        accentColor: '#1967D2',
        accentColorMuted: '#ADCAFA',
        Icon: GoogleCalendarIcon,
        IconMuted: GoogleCalendarMutedIcon,
    }),

    // TODO: Complete integration meta as required
    [AUTHSOURCE_SMOKEBALL_AU]: (): IntegrationMeta => ({
        authSource: AUTHSOURCE_SMOKEBALL_AU,
        title: 'Smokeball Australia',
        shortTitle: 'Smokeball',
        byline: '',
        description: '',
        helpUrl: toHelpUrl(HELP_LINKS['smokeball']),
        webUrl: emptyURL,
        linkUrl: emptyURL,
        accentColor: '#EB7324',
        accentColorMuted: '#EEE',
        Icon: SmokeballIcon,
        IconMuted: SmokeballMutedIcon,
    }),
    [AUTHSOURCE_ASANA]: (): IntegrationMeta => ({
        authSource: AUTHSOURCE_ASANA,
        title: 'Asana',
        shortTitle: 'Asana',
        byline: '',
        description: '',
        helpUrl: toHelpUrl(HELP_LINKS['asana']),
        webUrl: emptyURL,
        linkUrl: emptyURL,
        accentColor: '#EEE',
        accentColorMuted: '#EEE',
        Icon: AsanaIcon,
        IconMuted: AsanaMutedIcon,
    }),
    [AUTHSOURCE_AZUREAD_TASKS]: (): IntegrationMeta => ({
        authSource: AUTHSOURCE_AZUREAD_TASKS,
        title: 'Microsoft To Do',
        shortTitle: 'Microsoft To Do',
        byline: '',
        description: '',
        helpUrl: toHelpUrl(HELP_LINKS['microsoft-to-do']),
        webUrl: emptyURL,
        linkUrl: emptyURL,
        accentColor: '#EEE',
        accentColorMuted: '#EEE',
        Icon: MicrosoftToDoIcon,
        IconMuted: () => null,
    }),
    [AUTHSOURCE_GOOGLE_TASKS]: (): IntegrationMeta => ({
        authSource: AUTHSOURCE_GOOGLE_TASKS,
        title: 'Google Tasks',
        shortTitle: 'Google Tasks',
        byline: '',
        description: '',
        helpUrl: toHelpUrl(HELP_LINKS['google-tasks']),
        webUrl: emptyURL,
        linkUrl: emptyURL,
        accentColor: '#EEE',
        accentColorMuted: '#EEE',
        Icon: GoogleTasksIcon,
        IconMuted: () => null,
    }),
};

const getIntegrationMeta = (
    type: keyof typeof integrationMeta
): IntegrationMeta | undefined => {
    const handler = integrationMeta[type] || '';
    if (!handler) return;
    return handler();
};

export { getIntegrationMeta, integrationMeta };
