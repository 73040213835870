import { useSelector } from 'react-redux';
import {
    FETCH_ACTION_ITEMS_SUCCESS,
    FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS,
    FETCH_ALL_MY_MEETING_USERS_SUCCESS,
    FETCH_USERPERMISSIONS_SUCCESS,
    FETCH_MEETINGS_SUCCESS,
    FETCH_GROUPS_SUCCESS,
    FETCH_SCHEDULED_EVENTS_SUCCESS,
} from '~common/action.types.js';

const preLoadPercent = (state) => state.preload.percent;

const useIsPreloading = () => {
    // Using percent here because `isLoading` is `false` for a couple of renders before it kicks off
    const percent = useSelector(preLoadPercent);
    return percent !== 100;
};

const preloadFullyLoadedItems = (state) => state.preload.fullyLoaded;

const useHasActionItemsFullyLoaded = () => {
    const loadedItems = useSelector(preloadFullyLoadedItems);

    return [
        loadedItems.has(FETCH_ACTION_ITEMS_SUCCESS),
        loadedItems.has(FETCH_ALL_MY_MEETING_USERS_SUCCESS),
        loadedItems.has(FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS),
        loadedItems.has(FETCH_MEETINGS_SUCCESS),
        loadedItems.has(FETCH_USERPERMISSIONS_SUCCESS),
        loadedItems.has(FETCH_GROUPS_SUCCESS),
    ].every(Boolean);
};

/**
 * This is when meeting-related information for all meetings has been loaded - e.g. so the meetings dashboard can be displayed
 * So this doesn't include items that are not required for display on the dashboard - such as private notes, action items etc...
 * @returns {boolean}
 */
const useHasMeetingsFullyLoaded = () => {
    const loadedItems = useSelector(preloadFullyLoadedItems);

    return [
        loadedItems.has(FETCH_ALL_MY_MEETING_USERS_SUCCESS),
        loadedItems.has(FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS),
        loadedItems.has(FETCH_MEETINGS_SUCCESS),
        loadedItems.has(FETCH_USERPERMISSIONS_SUCCESS),
        loadedItems.has(FETCH_GROUPS_SUCCESS),
        loadedItems.has(FETCH_SCHEDULED_EVENTS_SUCCESS),
    ].every(Boolean);
};

const useHasSeriesFullyLoaded = () => {
    const loadedItems = useSelector(preloadFullyLoadedItems);

    return [
        loadedItems.has(FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS),
        loadedItems.has(FETCH_MEETINGS_SUCCESS),
        loadedItems.has(FETCH_USERPERMISSIONS_SUCCESS),
        loadedItems.has(FETCH_GROUPS_SUCCESS),
    ].every(Boolean);
};

const useHasWorkspacesFullyLoaded = () => {
    const loadedItems = useSelector(preloadFullyLoadedItems);

    return [
        loadedItems.has(FETCH_USERPERMISSIONS_SUCCESS),
        loadedItems.has(FETCH_GROUPS_SUCCESS),
    ].every(Boolean);
};

export {
    useIsPreloading,
    useHasActionItemsFullyLoaded,
    useHasMeetingsFullyLoaded,
    useHasSeriesFullyLoaded,
    useHasWorkspacesFullyLoaded,
};
