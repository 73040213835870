import React from 'react';
import {
    AUTHSOURCE_SMOKEBALL_AU,
    AUTHSOURCE_ASANA,
    WORKSPACE_FEATURE_TRIAL_ASANA,
} from '../constants/index.js';

export const getCRMConfig = ({ authSource } = {}) => {
    switch (authSource) {
        case AUTHSOURCE_SMOKEBALL_AU: {
            return {
                title: 'Smokeball Australia',
                trialFeatureName: null,
                trialFeatureDescription: '',
                allowConnectAsAnyUser: true, // allow the use of any connected-user's profile to connect to Smokeball. i.e. each user is not required to link up to Smokeball in order to leverage the integration.
                hasCachedCustomerData: true,
                hasCachedCustomerDetailData: true,
                hasCustomerListPerUser: false, // single customer list used for all users
                processingCustomerCacheKey: ({ link, customerId }) =>
                    `${link.remoteSubscriptionId}.matter.${customerId}.webhookEvent`,
                iconName: 'smokeball',
                shortName: 'Smokeball',
                longName: 'Smokeball Australia',
                customerNameIsDefaultMeetingTitle: true, // whether the meeting (series) title in the simple modal defaults from the getMeetingTitle below
                getMeetingTitle: (matterTitle) => matterTitle,
                remoteDataAlias: 'matter',
                remoteDataAliasSecondary: 'memo',
                companyNoun: 'firm',
                canSearchContactsAndStaff: true,
                canSaveDocuments: true,
                canSyncTasks: true,
                canSaveTopicContent: true,
            };
        }
        case AUTHSOURCE_ASANA: {
            return {
                title: 'Asana',
                trialFeatureName: WORKSPACE_FEATURE_TRIAL_ASANA,
                trialFeatureDescription: (
                    <>
                        Synchronise Action Items created in your meetings as
                        Tasks in Asana.
                        <br />
                    </>
                ),
                allowConnectAsAnyUser: true, //allow the use of any connected-user's profile to connect. i.e. each user is not required to link up in order to leverage the integration.
                hasCachedCustomerData: true,
                hasCachedCustomerDetailData: false,
                hasCustomerListPerUser: true,
                processingCustomerCacheKey: ({ link, customerId }) =>
                    `${link.remoteSubscriptionId}.project.${customerId}.webhookEvent`,
                iconName: 'asana',
                shortName: 'Asana',
                longName: 'Asana',
                customerNameIsDefaultMeetingTitle: false, // whether the meeting (series) title in the simple modal defaults from the getMeetingTitle below
                getMeetingTitle: (teamAndProjectTitle = '') => {
                    const [, ...project] = teamAndProjectTitle.split(' > ');
                    return project.join(' > ').trim();
                },
                remoteDataAlias: 'project',
                remoteDataAliasSecondary: 'TBC??',
                companyNoun: 'workspace',
                canSearchContactsAndStaff: false,
                canSaveDocuments: false,
                canSyncTasks: true,
                canSaveTopicContent: false,
            };
        }
        default: {
            return {};
        }
    }
};
