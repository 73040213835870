import React from 'react';
import tw, { styled, css } from 'twin.macro';

const Container = styled.button`
    /* Magic number to improve container width consistency */
    width: 3.2rem;
    ${tw`flex flex-col items-center leading-snug cursor-default`}
    ${({ disabled }) =>
        !disabled && tw`hover:text-body-dark cursor-pointer focus:outline-none`}
`;
const Day = styled.div`
    ${tw`uppercase -mb-1 tracking-wide`}
    ${({ size }) => size === 'small' && tw`text-xs`}
    ${({ size }) => size === 'medium' && tw`text-sm`}
    ${({ size }) => size === 'large' && tw`text-lg`}
    ${({ isActive, hasActive, accentColor }) =>
        isActive && hasActive ? `color: ${accentColor};` : tw`text-inherit`}
`;
const Date = styled.div`
    ${({ size }) => size === 'small' && tw`text-xl`}
    ${({ size }) =>
        size === 'medium' &&
        css`
            ${tw`text-xl`}
            margin-top: .1em;
        `}
    ${({ size }) => size === 'large' && tw`text-3xl`}
    ${({ isActive, hasActive }) =>
        isActive && hasActive ? tw`text-body-dark` : tw`text-inherit`}
`;
const Label = tw.div`text-xs pt-1 border-t w-full tracking-wider text-center`;

export default ({
    date,
    isActive,
    hasActive,
    size,
    accentColor,
    hasLabel,
    onClick,
    disabled,
}) => (
    <Container
        {...{ disabled }}
        onClick={disabled ? null : onClick}
        as={disabled ? 'div' : null}
    >
        <Day {...{ isActive, hasActive, size, accentColor }}>
            {date.formatForUser('ddd')}
        </Day>
        <Date {...{ isActive, hasActive, size, hasLabel }}>
            {date.formatForUser('D')}
        </Date>
        {hasLabel && <Label>{date.formatDateTense()}</Label>}
    </Container>
);
