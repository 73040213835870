// TODO: Next time this file is changed, move out of generic api folder and
// into ~modules/actionItems/

import { get, post, deleteRequest, postSuppressingWS } from './helpers.js';

import { actionItemModel, actionItemModelList } from './actionItem.mappers.js';

export function addActionItem(actionItem) {
    const url = '/api/action-item';
    return post(url, actionItem).then(actionItemModel);
}

export function updateActionItem(actionItemId, actionItem) {
    const url = `/api/action-item/${actionItemId}`;
    return post(url, actionItem).then(actionItemModel);
}

export function updateActionItemNoWS(actionItemId, actionItem) {
    const url = `/api/action-item/${actionItemId}`;
    return postSuppressingWS(url, actionItem).then(actionItemModel);
}

export function deleteActionItem(actionItem) {
    const url = `/api/action-item/${actionItem.id}`;
    return deleteRequest(url).then(actionItemModel);
}

export function getActionItemForOccurrence(occurrenceId) {
    const url = `/api/action-item/occurrence/${occurrenceId}`;
    return get(url).then(actionItemModelList);
}

export function getActionItemForMeeting(meetingId) {
    const url = `/api/action-item/meeting/${meetingId}`;
    return get(url).then(actionItemModelList);
}

export function linkActionItem(actionItem, params) {
    const url = `/api/action-item/${actionItem.id}/link`;
    return post(url, params).then(actionItemModel);
}

export function unlinkActionItem(actionItem) {
    const url = `/api/action-item/${actionItem.id}/link`;
    return deleteRequest(url).then(actionItemModel);
}
