import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import {
    getIntegrationProfileErrors,
    getAuthIntegrationProfile,
    getIntegrationProfileByAuthSource,
} from '~modules/integration/integration.selectors';
import { disconnectIntegrationWithFeedback } from '~modules/integration/integration.actions';
import { getToken } from './integration.api';

const useHasIntegrationErrors = () => {
    const integrationProfileErrors = useSelector(getIntegrationProfileErrors);
    return integrationProfileErrors.length > 0;
};

const useAuthIntegrationProfile = () => useSelector(getAuthIntegrationProfile);

const useGetToken = (profile) =>
    useQuery('token', () => getToken(profile), {
        refetchOnWindowFocus: false,
    });

const useIntegrationProfileByAuthSource = (authSource) => {
    const profile = useSelector((state) =>
        getIntegrationProfileByAuthSource(state, { authSource })
    );
    return profile;
};

const useDisconnectIntegration = (profile) => {
    const dispatch = useDispatch();

    return () => {
        dispatch(disconnectIntegrationWithFeedback(profile));
    };
};

export {
    useHasIntegrationErrors,
    useAuthIntegrationProfile,
    useGetToken,
    useIntegrationProfileByAuthSource,
    useDisconnectIntegration,
};
