import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updatePageViewState } from '~modules/pageView/pageView.actions';
import {
    getDashboardState,
    getPageState,
} from '~modules/pageView/pageView.selectors';
import { getSpecialDashboard } from '~modules/dashboard/dashboard.selectors';
import { getDashboardPathParams, isDetailPage } from '~modules/navigation';

const usePageStateUpdate = () => {
    const dispatch = useDispatch();

    return (pageState) => dispatch(updatePageViewState(pageState));
};

// used to prevent unnecessary re-renders
const emptyObject = {};

const useCurrentPageState = () => {
    const location = useLocation();
    const [pathname, dashboard, groupId] = getDashboardPathParams(
        location.pathname
    );

    if (pathname) {
        const dashboardType = dashboard || pathname.replace('/', '');
        const { id } = getSpecialDashboard(dashboardType);
        const dashboardId = dashboard ? `${dashboardType}-${groupId}` : id;

        return getDashboardState(dashboardId);
    }

    const detailPage = isDetailPage(location.pathname);
    if (detailPage) {
        const pageState = getPageState(detailPage.id) || {};
        return pageState;
    }

    // return an empty object so it can be destructured
    return emptyObject;
};

// returns the pageState for an id, which could be a meeting or occurrence for example.
// this can be used as a hook, that will re-run each time the state changes,
// so it can trigger component updates
const usePageState = (id) => {
    const pageStates = useSelector((state) => state.pageView.pageStates);

    if (!id) return emptyObject;
    return pageStates.find((pageState) => pageState.id === id) || emptyObject;
};

export { usePageStateUpdate, useCurrentPageState, usePageState };
