import React, { useEffect } from 'react';
import tw, { styled, css } from 'twin.macro';
import Icon from '~icons';
import { useUI, useIsSidebarPinned, useKeyPress } from '~hooks';
import { hasOnlyTouchScreen } from '~common/style.helpers';

const MenuWrap = styled.div(({ hasCloseIcon }) => [
    tw`opacity-100 block transition-opacity ease-in-out duration-200`,
    hasCloseIcon && tw`md:text-xs`,
]);

const ArrowWrap = styled.div(
    ({ isOpen, hasOnlyTouchScreen, isFloating, hasCloseIcon }) => [
        tw`text-sm opacity-0 invisible transition-all ease-in-out duration-200 [touch-action: ] absolute text-white`,
        !hasOnlyTouchScreen &&
            !hasCloseIcon &&
            tw`group-hocus:(opacity-100 visible)`,
        hasOnlyTouchScreen && !hasCloseIcon && tw`opacity-100 visible`,
        isOpen && !isFloating && !hasCloseIcon
            ? css({ transform: 'translateX(-.1em)' })
            : css({ transform: 'rotate(180deg)' }),
        hasCloseIcon && tw`md:text-xs`,
    ]
);

const Container = styled.button(
    ({ hasOnlyTouchScreen, hasCloseIcon, isSize }) => [
        tw`border bg-solid-light border-line flex rounded-full items-center justify-center text-icon-dark hocus:outline-none w-6 h-6 transition-colors duration-200 shadow-sm
    hocus:(border-primary bg-primary text-primary shadow) active:(border-primary-dark bg-primary-dark text-primary-dark)
    after:(w-10 h-10 block absolute)`,
        // Slide panel close icons
        hasCloseIcon && tw`hocus:text-white`,
        // Always display arrow on tablets to resolve hover state triggering on touch
        hasOnlyTouchScreen && tw`border-primary bg-primary text-primary shadow`,
        // Only display white close icon on touchScreen devices
        (hasOnlyTouchScreen || !isSize?.large) &&
            hasCloseIcon &&
            tw`border-none bg-transparent text-white hocus:(border-none bg-none text-white shadow) active:(border-none bg-none text-primary-dark)`,
        hasOnlyTouchScreen &&
            hasCloseIcon &&
            isSize?.large &&
            tw`bg-solid-light border-line svg:text-icon-dark active:(bg-solid-light border-line svg:text-icon-dark)`,
    ]
);

const IconWrap = tw.div`flex items-center justify-center`;

const SidePanelToggle = ({
    isPinnable,
    isOpen,
    isFloating,
    hasCloseIcon,
    isSize,
    ...rest
}) => {
    const {
        setIsDetailSidebarOpen,
        isDetailSidebarDocked,
        setIsDetailSidebarDocked,
        setIsHelpCenterOpen,
        setIsQuickSwitcherOpen,
        setIsNotificationsOpen,
    } = useUI();
    const { isSidebarPinned, saveIsSidebarPinned } = useIsSidebarPinned();

    const hasPressedEscape = useKeyPress('Escape');

    useEffect(() => {
        // Prevent floating sidebar opening on screen size change
        if (isPinnable && isDetailSidebarDocked) {
            setIsDetailSidebarDocked(false);
            setIsDetailSidebarOpen(false);
        }
        if (!hasPressedEscape || !isOpen || isFloating || isPinnable) return;
        setIsDetailSidebarOpen(false);
        setIsDetailSidebarDocked(false);
    }, [
        hasPressedEscape,
        isOpen,
        isFloating,
        isPinnable,
        hasCloseIcon,
        isDetailSidebarDocked,
        setIsDetailSidebarDocked,
        setIsDetailSidebarOpen,
        setIsHelpCenterOpen,
        setIsQuickSwitcherOpen,
    ]);

    const handleOnClick = () => {
        isPinnable
            ? saveIsSidebarPinned(!isSidebarPinned)
            : hasCloseIcon
            ? (setIsHelpCenterOpen(false),
              setIsQuickSwitcherOpen(false),
              setIsNotificationsOpen(false))
            : isFloating
            ? (setIsDetailSidebarOpen(true), setIsDetailSidebarDocked(true))
            : (setIsDetailSidebarOpen((o) => !o),
              setIsDetailSidebarDocked((o) => !o));
    };

    return (
        <Container
            {...rest}
            onClick={handleOnClick}
            className="group"
            {...{ hasOnlyTouchScreen, hasCloseIcon, isSize }}
        >
            <IconWrap>
                <MenuWrap {...{ hasCloseIcon }}>
                    <Icon
                        name={hasCloseIcon ? 'close' : 'menu'}
                        iconThick={hasCloseIcon}
                    />
                </MenuWrap>
                <ArrowWrap
                    {...{
                        isOpen,
                        hasOnlyTouchScreen,
                        isFloating,
                        hasCloseIcon,
                    }}
                >
                    <Icon
                        name={hasCloseIcon ? 'close' : 'leftArrow'}
                        iconThick={hasCloseIcon}
                    />
                </ArrowWrap>
            </IconWrap>
        </Container>
    );
};

export default SidePanelToggle;
