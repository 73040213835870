/**
 * Label display
 * Show a series of labels from a dataset
 */
const labelDisplay = ({
    data,
    labelPrefix = '',
    defaultLabel = '',
    maxDisplay = 99,
    fieldName = 'label',
    hasDividerAlternative = false,
}) => {
    if (data.length === 0) return defaultLabel;
    const isOverDisplayLimit = data.length > maxDisplay;
    const displayData = isOverDisplayLimit ? data.slice(0, maxDisplay) : data;
    const divider = hasDividerAlternative && data.length === 2 ? ' and ' : ', ';
    return `${displayData
        .map((item) => `${labelPrefix}${item[fieldName]}`)
        .join(divider)}${
        isOverDisplayLimit ? `, and ${data.length - maxDisplay} more` : ''
    }`;
};

export { labelDisplay };
