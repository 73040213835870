import {
    EVENT_REPLAY_DONE,
    EVENT_REPLAY_START,
    LOGOUT_SUCCESS,
} from '../common/action.types.js';

const initialState = {
    inProgress: false,
};

const eventReplayReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENT_REPLAY_START:
            return {
                ...state,
                inProgress: true,
            };

        case EVENT_REPLAY_DONE:
        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default eventReplayReducer;
