import React, { useRef } from 'react';
import tw, { styled, css } from 'twin.macro';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { onDeleteGroup } from '~modules/group/group.actions';
import {
    getWorkspaceSlug,
    isViewingBaseAppHomepage,
    isViewingHomepage,
} from '~modules/navigation';
import { CategoryHeading } from '~modules/dashboard/meetings/components';
import MenuWithIconTrigger from '~modules/common/components/MenuWithIconTrigger';
import { Button, selectElementContents } from '~modules/common/form-elements';
import JumpTo from '~modules/dashboard/meetings/components/JumpTo';
import { updateUserAppParameter } from '~modules/user/user.actions';
import AddIcon from '~icons/add';
import ExternalIcon from '~icons/externalLink';
import TickIcon from '~icons/tick';
import FiltersToggle from '~modules/dashboard/components/FiltersToggle';
import { getIntegrationMeta } from '~modules/integration/integration.helpers';
import { useRemoteCalendarsActive, useUser } from '~hooks';
import { ROUTES } from '~shared/navigation';

const DashboardHeaderContainer = styled.div(({ isSize }) => [
    tw`flex w-full border-b select-none h-full`,
    isSize.small ? tw`items-center` : tw`items-start`,
]);

const DashboardHeaderInner = tw.div`flex items-center justify-between w-full`;

const GUTTER_WIDTH = '2.5rem';

// The content-editable blocks don't position overflowed text properly so this
// workaround ensures it doesn't push the actions off screen.
// It's a heavy-handed approach and I couldn't find a simplier way to do it.
const calcBase = (rest) => `calc(
            100vw - var(--sidebar-width) - var(--nav-vertical-width) -
                var(--content-padding-x) - var(--content-padding-x) - var(--filters-width) - ${GUTTER_WIDTH} - ${rest}
        )`;

const HeadingContainer = styled.div(
    ({ showCalendar, showFilters, isSize, showAdd }) => [
        tw`flex items-center`,
        css({
            maxWidth: calcBase(
                [
                    showCalendar && '2.5rem',
                    showFilters && '2.5rem',
                    showAdd && isSize.small ? '2.5rem' : '9rem',
                ]
                    .filter(Boolean)
                    .join(' - ')
            ),
        }),
    ]
);

const HeadingWrap = tw.div`z-10 w-full overflow-hidden`;
const IconWrapPadding = tw`px-3`;
const ActionWrap = styled.div(({ isSmall }) => [
    tw`flex h-full`,
    !isSmall && tw`space-x-4`,
]);

const AddMeetingButton = ({ isSize, onClick }) =>
    isSize?.small ? (
        <button
            type="button"
            tw="pl-1.5 pr-3 focus:outline-none text-icon-dark hocus:text-black"
            {...{ onClick }}
        >
            <AddIcon tw="text-lg sm:text-xl" />
        </button>
    ) : (
        <Button noWrap isSecondary isFull isSmall {...{ onClick }}>
            Add meeting
        </Button>
    );

const calendarMenuOptionSet = (profile) => {
    const meta = getIntegrationMeta(profile.authSource);
    if (!meta) return;

    const icon = (
        <meta.Icon tw="inline -mt-0.5 mr-1.5 text-sm text-icon group-hocus:text-icon-dark" />
    );

    return [
        {
            label: (
                <>
                    {icon}
                    View calendar
                    <ExternalIcon tw="inline -mt-1 ml-1.5 text-xs text-icon group-hocus:text-icon-dark" />
                </>
            ),
            href: meta.webUrl(profile),
            target: '_blank',
        },
        {
            label: (
                <>
                    {icon}
                    Manage integration
                </>
            ),
            href: `${ROUTES.integration}/calendar/${profile.authSource}`,
        },
        'divider',
    ];
};

const getCalendarMenuOptions = (activeRemoteCalendarProfiles) => {
    if (!isViewingBaseAppHomepage()) return;

    if (activeRemoteCalendarProfiles.length === 0)
        return [{ label: 'View integrations', href: ROUTES.integration }];

    return activeRemoteCalendarProfiles.flatMap((profile) =>
        calendarMenuOptionSet(profile)
    );
};

const DashboardHeader = ({
    id,
    data,
    workspaceCurrent,
    showFilters,
    showCalendar,
    isSize,
    handleAddMeeting,
    canAddMeeting,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const loggedInUser = useUser();
    const activeRemoteCalendarProfiles = useRemoteCalendarsActive();

    const isCategory = data.type === 'meeting';
    const isAllMeetings = data.id === 'meetings';
    const isWorkspace = data.type === 'workspace';
    const canEditTitle = isCategory;
    const canManage = isWorkspace;
    const canSetHomepage = isCategory || isWorkspace || isAllMeetings;
    const canSetPrimaryWorkspace = isWorkspace && canAddMeeting;
    const showAdd = Boolean(handleAddMeeting);
    const dropDownOptions = [];
    const isViewingPrimaryWorkspace =
        workspaceCurrent?.id ===
        loggedInUser?.appPreferences?.primaryWorkspaceId;
    isCategory &&
        dropDownOptions.push(
            canEditTitle && {
                label: 'Rename',
                onClick: () => {
                    selectElementContents(inputRef.current);
                },
            },
            {
                label: 'Delete',
                onClick: () => dispatch(onDeleteGroup(data)),
            }
        );

    dropDownOptions.push(
        ...(getCalendarMenuOptions(activeRemoteCalendarProfiles) || [])
    );

    canManage &&
        dropDownOptions.push({
            label: 'Manage workspace',
            href: getWorkspaceSlug(workspaceCurrent),
        });

    canSetHomepage &&
        dropDownOptions.push({
            label: isViewingHomepage() ? (
                <>
                    <TickIcon tw="inline -mt-0.5 mr-1.5 text-sm text-icon-disabled" />
                    Set as homepage
                </>
            ) : (
                'Set as homepage'
            ),
            ...(!isViewingHomepage() && {
                onClick: () =>
                    dispatch(
                        updateUserAppParameter('myHomepage', location.pathname)
                    ),
            }),
        });
    canSetPrimaryWorkspace &&
        dropDownOptions.push({
            label: isViewingPrimaryWorkspace ? (
                <>
                    <TickIcon tw="inline -mt-0.5 mr-1.5 text-sm text-icon-disabled" />
                    Set as primary workspace
                </>
            ) : (
                'Set as primary workspace'
            ),
            ...(!isViewingPrimaryWorkspace && {
                onClick: () =>
                    dispatch(
                        updateUserAppParameter(
                            'primaryWorkspaceId',
                            workspaceCurrent?.id
                        )
                    ),
            }),
        });

    const inputRef = useRef();

    return (
        <DashboardHeaderContainer {...{ id, isSize }}>
            <DashboardHeaderInner>
                <HeadingContainer
                    {...{ showCalendar, showFilters, isSize, showAdd }}
                >
                    <HeadingWrap>
                        <CategoryHeading
                            ref={inputRef}
                            {...data}
                            hasEditableTitle={canEditTitle}
                            {...{ isWorkspace }}
                        />
                    </HeadingWrap>
                    <MenuWithIconTrigger
                        iconName="dotburger"
                        options={dropDownOptions}
                        position="bottom right"
                        styles={IconWrapPadding}
                    />
                </HeadingContainer>
                <ActionWrap isSmall={isSize.small}>
                    {showCalendar && <JumpTo />}
                    {showFilters && <FiltersToggle />}
                    {showAdd && (
                        <AddMeetingButton
                            onClick={handleAddMeeting}
                            {...{ isSize }}
                        />
                    )}
                </ActionWrap>
            </DashboardHeaderInner>
        </DashboardHeaderContainer>
    );
};

export default DashboardHeader;
