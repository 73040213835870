import React from 'react';
import tw, { styled } from 'twin.macro';
import Icon from '~icons';
import { Tooltip } from '~modules/common/components';
import FeatureLabel from '~modules/feature/FeatureLabel';

const Container = styled.div(({ isDisabled, isRightAligned }) => [
    tw`flex items-center space-x-1.5`,
    isDisabled && tw`cursor-not-allowed`,
    !isRightAligned && tw`w-full`,
]);

// THIS COMPONENT PROVIDES TOOLTIP CONTENT SEPARATE TO
// OUR FIELD LABELS
// Pass this component a tooltipProps object
// const tooltipProps = {
// 	content: TOOLTIP CONTENT,
// 	on: 'click', (OPTIONAL, use if button or link is required in tooltip content)
// 	isNewFeature: true, (DISPLAYS NEW LABEL),
//	icon: ANY ICON COMPONENT,
//	field: FORM COMPONENT
// };

const FieldRowTooltip = ({ tooltipProps }) => {
    const {
        field,
        isNewFeature = false,
        isTrialFeature = false,
        link,
        iconName = 'help',
        enableTooltip = true,
        trialFeatureHasExpired,
        isRightAligned = false,
        isDisabled = false,
        position = 'bottom right',
        offset,
        workspace,
    } = tooltipProps;

    return (
        <Container
            {...{ isDisabled, isRightAligned }}
            onClick={(evt) => evt.stopPropagation()}
        >
            {field}
            {link}
            {isNewFeature || isTrialFeature ? (
                <FeatureLabel
                    {...{
                        isNewFeature,
                        isTrialFeature,
                        trialFeatureHasExpired,
                        tooltipProps,
                        workspace,
                    }}
                />
            ) : (
                <Tooltip {...{ offset, position }} {...tooltipProps}>
                    {enableTooltip ? (
                        <span tw="text-sm text-icon cursor-pointer hocus:text-icon-dark">
                            <Icon
                                name={iconName}
                                title=""
                                tw="text-sm text-icon cursor-pointer hocus:text-icon-dark"
                            />
                        </span>
                    ) : null}
                </Tooltip>
            )}
        </Container>
    );
};

export default FieldRowTooltip;
