import moment from 'moment-timezone';
export function activityModel(activity) {
    if (!activity) {
        return;
    }

    // INFO: dates in an activity are stored in UTC... convert to moments so they can be used in the browser
    const updated = {
        ...activity,
        createdAt: moment(activity.createdAt),
        updatedAt: moment(activity.updatedAt),
    };

    if (activity.emailMeta) {
        updated.emailMeta = {
            ...activity.emailMeta,
            to: activity.emailMeta.to.map((recipient) => ({
                ...recipient,
                sentAt: recipient.sentAt ? moment(recipient.sentAt) : undefined,
                deliveredAt: recipient.deliveredAt
                    ? moment(recipient.deliveredAt)
                    : undefined,
                openedAt: recipient.openedAt
                    ? moment(recipient.openedAt)
                    : undefined,
                rejectedAt: recipient.rejectedAt
                    ? moment(recipient.rejectedAt)
                    : undefined,
                bouncedAt: recipient.bouncedAt
                    ? moment(recipient.bouncedAt)
                    : undefined,
                clickEvents:
                    recipient.clickEvents?.length > 0
                        ? recipient.clickEvents
                              .map((clickEvent) => ({
                                  ...clickEvent,
                                  timestamp: moment(clickEvent.timestamp),
                              }))
                              .sort(
                                  (a, b) =>
                                      moment(b.timestamp).valueOf() -
                                      moment(a.timestamp).valueOf()
                              )
                        : [],
            })),
        };
    }

    return updated;
}

export function activityModelList(activities) {
    return (activities || []).map(activityModel);
}
