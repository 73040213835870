import React from 'react';
import tw, { styled, css } from 'twin.macro';
import ButtonText from '~modules/common/components/ButtonText';
import { MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_DAY } from '~shared/constants';
import ArrowIcon from '~modules/common/illustrations/Arrow';

const Container = styled.div(({ hasUnderline, isPast, isToday }) => [
    tw`w-full flex items-end`,
    hasUnderline && tw`border-0 border-b border-line pb-4`,
    isPast && !isToday && tw`opacity-75`,
    isToday &&
        css`
            ${tw`relative`}
            &:after {
                ${tw`absolute text-white font-bold invisible lg:visible tracking-wide`}
                top: 0.45em; /* Granular alignment with arrow */
                margin-left: 0.375em; /* Granular alignment with arrow */
                content: 'TODAY';
                left: calc(var(--content-padding-x) * -1);
                font-size: 0.625em; /* 10px */
            }
        `,
]);
const Arrow = styled.div(
    tw`absolute text-primary invisible lg:visible`,
    css`
        font-size: 3rem; /* Granular positioning of arrow */
        top: -0.73rem; /* Granular positioning of arrow */
        left: calc(var(--content-padding-x) * -1);
    `
);
const Heading = styled.h2(
    tw`text-lg sm:text-xl inline font-normal leading-tight relative`,
    ({ isDarkened }) => isDarkened && tw`text-body-dark`
);
const Additional = styled.div(({ isToday }) => [
    tw`text-sm inline-flex pl-4 uppercase font-semibold tracking-wider whitespace-nowrap`,
    // Today label for small screens
    isToday &&
        tw`text-white bg-primary visible lg:invisible px-2 leading-5 rounded ml-4 mr-2 text-xs`,
    // Hide on small screens except 'Today' label
    !isToday && tw`invisible sm:visible`,
]);
const AddMeeting = tw.div`ml-auto opacity-100 visible lg:invisible group-hocus:(opacity-100 visible)`;

const MeetingHeading = ({
    as,
    children,
    hasUnderline,
    isDarkened,
    additionalText,
    date,
    handleAddMeeting,
    canAddMeeting,
    id,
    isPast,
    isToday,
    isSize,
}) => (
    <Container {...{ hasUnderline, isPast, isToday }} id={id} className="group">
        {isToday && (
            <Arrow>
                <ArrowIcon />
            </Arrow>
        )}
        <Heading {...{ as, isDarkened }}>{children}</Heading>
        {additionalText && (
            <Additional {...{ isToday }}>{additionalText}</Additional>
        )}
        {handleAddMeeting && canAddMeeting && (
            <AddMeeting>
                <ButtonText
                    isSmall
                    onClick={() =>
                        handleAddMeeting({
                            dateStart: date,
                            createdSourceLocation:
                                MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_DAY,
                        })
                    }
                    icon={{
                        name: 'add',
                        isHighlighted: !isSize.small,
                        isLarge: false,
                    }}
                >
                    Add meeting
                </ButtonText>
            </AddMeeting>
        )}
    </Container>
);

export default MeetingHeading;
