import React from 'react';

export default ({ iconThick = false, title = 'Search', ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <circle
            cx="14"
            cy="14"
            r="11"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <line
            x1="22.414"
            x2="29"
            y1="23"
            y2="29.586"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
