import React, { useState } from 'react';
import { fullDateTime } from '~common/time.utils';
import {
    GRANT_LEVEL_NONE,
    STATUS_TYPE_NEW_USER,
    STATUS_TYPE_NEW_USER_NO_ACCESS,
    PERMISSION_STATUS_PENDING,
    PERMISSION_STATUS_REJECTED,
    PERMISSION_STATUS_REQUESTED,
    STATUS_TYPE_WORKSPACE_REJECTED,
    PERMISSION_STATUS_LEFT,
    STATUS_TYPE_WORKSPACE_LEFT,
    STATUS_TYPE_REVOKED,
    PERMISSION_TYPE_WORKSPACE,
    PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED,
} from '~common/constants';
import { StatusIndicator } from '~modules/common/components';
import { getUserPermissionsByStatus } from '~modules/permission/permission.helpers';
import { resendPermission } from '~modules/permission/permission.actions';
import { useUI } from '~hooks';

const PermissionAction = ({
    status,
    grantLevelText,
    permissionId,
    isNewUser,
    isNewNoAccess,
    invitationDate,
    disabled,
    targetType,
}) => {
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const { setIsNotificationsOpen } = useUI();

    const {
        isInactive,
        isPending,
        isRejected,
        isRevoked,
        isRequested,
        hasRequestRejected,
        hasLeftMeeting,
        hasLeftWorkspace,
    } = getUserPermissionsByStatus(status);

    const handleResendClick = async (evt) => {
        evt.preventDefault();
        setIsSending(true);
        await resendPermission(permissionId).catch(() => null);
        setIsSending(false);
        setIsSent(true);
    };

    if (isNewNoAccess) {
        return (
            <StatusIndicator
                type={STATUS_TYPE_NEW_USER_NO_ACCESS}
                isDisabled={disabled}
            />
        );
    }
    if (isNewUser) {
        return <StatusIndicator type={STATUS_TYPE_NEW_USER} />;
    }
    if (!isInactive) return null;

    const canResend = isPending && grantLevelText !== GRANT_LEVEL_NONE;

    if (canResend) {
        return (
            <StatusIndicator
                type={PERMISSION_STATUS_PENDING}
                onClick={handleResendClick}
                invitationDate={fullDateTime(invitationDate)}
                {...{ permissionId, isSending, isSent }}
            />
        );
    }

    const isWorkspace = targetType === PERMISSION_TYPE_WORKSPACE;

    if (isInactive) {
        return (
            <span>
                {isRequested && (
                    <StatusIndicator
                        type={PERMISSION_STATUS_REQUESTED}
                        onClick={() => setIsNotificationsOpen(true)}
                    />
                )}
                {isRejected && (
                    <StatusIndicator
                        type={
                            isWorkspace
                                ? STATUS_TYPE_WORKSPACE_REJECTED
                                : PERMISSION_STATUS_REJECTED
                        }
                    />
                )}
                {hasRequestRejected && (
                    <StatusIndicator
                        type={
                            PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED
                        }
                    />
                )}

                {isRevoked && <StatusIndicator type={STATUS_TYPE_REVOKED} />}
                {hasLeftMeeting && (
                    <StatusIndicator type={PERMISSION_STATUS_LEFT} />
                )}
                {hasLeftWorkspace && (
                    <StatusIndicator type={STATUS_TYPE_WORKSPACE_LEFT} />
                )}
            </span>
        );
    }
};

export default PermissionAction;
