import React from 'react';

const SMOKEBALL_ID = 'shape-smokeball';

const SmokeballIconInner = (): JSX.Element => (
    <>
        <path
            d="m14.794 14.502c-6.079-2.2779-7.6219-7.0552-6.4898-10.53 0.16821-0.50946 0.38058-1.0022 0.63452-1.4721 0.03898-0.07717 0.05361-0.16503 0.04185-0.25125-0.01176-0.08621-0.04932-0.16646-0.10742-0.22947-0.05788-0.06159-0.13435-0.1009-0.21677-0.11142s-0.16584 0.00838-0.23646 0.05358c-1.7846 1.1105-3.3314 2.5858-4.5459 4.3356s-2.0711 3.7375-2.5176 5.8415c-0.44643 2.1039-0.47325 4.2799-0.07884 6.3949 0.39442 2.1151 1.2018 4.1247 2.3728 5.906 1.6831 2.5056 4.0383 4.4471 6.7753 5.5851 1.416 0.6292 2.939 0.9608 4.4802 0.9754 0.3827-4e-4 0.7651-0.0217 1.1456-0.0638 4.027-0.4488 6.0617-2.8923 6.0617-7.2627 0-3.1276-2.7348-7.4142-7.3192-9.1655v-6e-3zm4.4995 9.1815c0 2.8763-0.9257 3.9894-3.539 4.2906-2.1716 0.2393-3.9923-0.3152-6.7155-2.0546-0.34677-0.2146-0.67519-0.4595-0.98167-0.732-2.1734-1.9689-3.6152-4.6596-4.0774-7.6093-0.46219-2.9496 0.08418-5.9736 1.5451-8.5516 1.055 3.6562 4.1061 6.7141 8.324 8.2899 3.2575 1.2487 5.4445 4.2905 5.4445 6.351"
            fill="currentColor"
        />
        <path
            d="m30.286 10.746c-0.4625-1.5408-1.1541-2.9977-2.0501-4.3184-0.9472-1.3728-2.1465-2.5393-3.5294-3.4328-1.2459-0.79788-2.1871-1.2746-3.6085-1.5958-1.1755-0.26776-2.3758-0.40154-3.5795-0.39893-4.3896 0-6.3549 2.4933-6.3549 4.9687 0 3.1695 2.2218 4.6057 7.1938 6.6343 6.3645 2.5033 9.0646 5.7646 9.0646 10.903 0 0.373-0.0154 0.748-0.0501 1.105-0.0085 0.0863 0.0105 0.1731 0.054 0.2473 0.0435 0.0741 0.1091 0.1316 0.187 0.1636 0.0433 0.0163 0.089 0.0251 0.135 0.026 0.0589-9e-4 0.1169-0.0158 0.1694-0.0435 0.0524-0.0276 0.0981-0.0674 0.1334-0.1161 1.9252-2.7156 2.9587-5.9977 2.9489-9.365-0.0011-1.624-0.2417-3.2383-0.7136-4.7872v0.0099zm-2.106 4.7773c0.0019 0.0396-0.0087 0.0788-0.0303 0.1116s-0.0529 0.0573-0.0893 0.0699c-0.0398 0.0165-0.0838 0.0188-0.1251 0.0065-0.0412-0.0123-0.0773-0.0384-0.1025-0.0743-1.7743-2.3817-4.5477-4.2966-8.486-5.8504-4.6133-1.865-5.3577-2.6549-5.3577-3.8178 0-1.2427 1.3172-1.9807 3.5294-1.9807 1.0037-6.2e-4 2.0047 0.11039 2.9855 0.33112 0.657 0.14549 1.2903 0.388 1.8804 0.72008 1.784 0.96902 3.2579 2.4525 4.243 4.2706 1.0118 1.9038 1.5425 4.0409 1.5429 6.2134h0.0097z"
            fill="currentColor"
        />
    </>
);

const SmokeballIcon = ({
    isSymbolRef = false,
    title = '',
    ...rest
}): JSX.Element => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        {isSymbolRef ? (
            <use href={`#${SMOKEBALL_ID}`} xlinkHref={`#${SMOKEBALL_ID}`} />
        ) : (
            <SmokeballIconInner />
        )}
    </svg>
);

export { SmokeballIcon as default, SmokeballIconInner, SMOKEBALL_ID };
