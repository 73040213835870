import {
    FETCH_SCHEDULED_EVENTS_SUCCESS,
    ADD_SCHEDULED_EVENT_REQUEST,
    ADD_SCHEDULED_EVENT_SUCCESS,
    ADD_SCHEDULED_EVENT_ERROR,
    EDIT_SCHEDULED_EVENT_REQUEST,
    EDIT_SCHEDULED_EVENT_SUCCESS,
    EDIT_SCHEDULED_EVENT_ERROR,
    LOGOUT_SUCCESS,
} from '../../common/action.types.js';

const initialState = {
    isSaving: false,
    events: [],
};

const scheduledEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SCHEDULED_EVENTS_SUCCESS:
            return {
                ...state,
                events: action.events,
            };

        case ADD_SCHEDULED_EVENT_REQUEST:
        case EDIT_SCHEDULED_EVENT_REQUEST:
            return {
                ...state,
                isSaving: true,
            };

        case ADD_SCHEDULED_EVENT_SUCCESS:
            return {
                ...state,
                isSaving: false,
                events: [...state.events, action.event],
            };

        case EDIT_SCHEDULED_EVENT_SUCCESS: {
            const newEvents = state.events.map((acc) =>
                acc.id === action.event.id ? action.event : acc
            );
            return {
                ...state,
                isSaving: false,
                events: newEvents,
            };
        }

        case ADD_SCHEDULED_EVENT_ERROR:
        case EDIT_SCHEDULED_EVENT_ERROR:
            return {
                ...state,
                isSaving: false,
            };

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default scheduledEventsReducer;
