import React from 'react';
import _startCase from 'lodash/startCase.js';
import {
    WORKSPACE_FEATURE_TRIAL_BRANDING,
    WORKSPACE_FEATURE_TRIAL_ACTIVITY,
    WORKSPACE_FEATURE_TRIAL_ASANA,
    WORKSPACE_FEATURE_TRIAL_ONEDRIVE,
    WORKSPACE_FEATURE_TRIAL_GOOGLE_DRIVE,
    WORKSPACE_FEATURE_TRIAL_DISPLAY_DOCUMENTS_INLINE,
} from './../constants';
import { dateFull } from './date.helpers';

export const getFeatureDescription = (featureName) => {
    const feature = (featureName || '').toLowerCase();
    switch (feature) {
        case WORKSPACE_FEATURE_TRIAL_BRANDING:
            return 'Branding';
        case WORKSPACE_FEATURE_TRIAL_DISPLAY_DOCUMENTS_INLINE:
            return 'Display documents inline';
        default:
            return _startCase(featureName);
    }
};

export const getFeatureExpiryMessage = ({
    featureName,
    trialExpiryDate,
    title,
    hasExpired,
    helpSiteUrl,
    utmTrackingLink,
}) => {
    const defaultMessage = (
        <p>
            The{' '}
            <strong>{getFeatureDescription(featureName)} trial feature</strong>{' '}
            for <strong>{title}</strong>{' '}
            {hasExpired ? 'expired' : 'is due to expire'} on{' '}
            <strong>{dateFull(trialExpiryDate)}</strong>.
        </p>
    );
    const upgradeMessage = (
        <p>
            {hasExpired ? 'Upgrade' : 'Consider upgrading'} to{' '}
            <strong>Plus</strong> for ongoing&nbsp;access.
        </p>
    );
    const helpLinks = {
        [WORKSPACE_FEATURE_TRIAL_BRANDING]: `/Manage/03-03%20Workspaces/${utmTrackingLink}#Upload-a-workspace-logo`,
        [WORKSPACE_FEATURE_TRIAL_ACTIVITY]: `/Features/02-02%20Meeting%20Details/${utmTrackingLink}#Activity`,
        [WORKSPACE_FEATURE_TRIAL_ASANA]: `/Features/02-07%20Integration%20with%20task%20apps/${utmTrackingLink}#Asana`,
        [WORKSPACE_FEATURE_TRIAL_ONEDRIVE]: `/Features/integration-with-cloud-storage/${utmTrackingLink}#Microsoft-OneDrive`,
        [WORKSPACE_FEATURE_TRIAL_GOOGLE_DRIVE]: `/Features/integration-with-cloud-storage/${utmTrackingLink}#Google-Drive`,
        [WORKSPACE_FEATURE_TRIAL_DISPLAY_DOCUMENTS_INLINE]: `/Features/02-02%20Meeting%20Details/${utmTrackingLink}#Sending-the-Agenda`,
    };

    const message = {
        [WORKSPACE_FEATURE_TRIAL_BRANDING]: (
            <>
                {' '}
                the branding for <strong>{title}</strong> will no longer display
                in Minutes and Agenda&nbsp;emails.
            </>
        ),
        [WORKSPACE_FEATURE_TRIAL_ACTIVITY]: (
            <>
                , Agenda, Minutes and invitation email history will no longer be
                accessible in any meetings for <strong>{title}</strong>.
            </>
        ),
        [WORKSPACE_FEATURE_TRIAL_ASANA]: (
            <>
                , Action items will stop syncing with any linked Asana projects
                for meetings in <strong>{title}</strong>.
            </>
        ),
        [WORKSPACE_FEATURE_TRIAL_ONEDRIVE]: (
            <>
                , Agenda and Minutes will no longer be uploaded to your selected
                OneDrive folder for any meetings in <strong>{title}</strong>.
            </>
        ),
        [WORKSPACE_FEATURE_TRIAL_GOOGLE_DRIVE]: (
            <>
                , Agenda and Minutes will no longer be uploaded to your selected
                Google Drive folder for any meetings in <strong>{title}</strong>
                .
            </>
        ),
        [WORKSPACE_FEATURE_TRIAL_DISPLAY_DOCUMENTS_INLINE]: (
            <>
                , documents will no longer display inline for any meeting
                Agendas in <strong>{title}</strong>.
            </>
        ),
    };

    const href = `${helpSiteUrl}${helpLinks[featureName.toLowerCase()]}`;

    return (
        <div>
            {defaultMessage}
            <p>
                {hasExpired
                    ? 'You and your team can continue to use MinuteMe, however'
                    : 'Once expired'}
                {message[featureName.toLowerCase()]}{' '}
                <a href={href} target="_blank" rel="noreferrer">
                    Read&nbsp;more
                </a>
            </p>
            {upgradeMessage}
        </div>
    );
};
