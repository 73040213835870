import _debounce from 'lodash/debounce.js';
import {
    COMMENT_MODAL_OPEN,
    COMMENT_MODAL_CLOSE,
    COMMENT_DELETE_REQUEST,
    COMMENT_DELETE_SUCCESS,
    COMMENT_DELETE_ERROR,
    COMMENT_CREATE_REQUEST,
    COMMENT_CREATE_SUCCESS,
    COMMENT_CREATE_ERROR,
} from '../../common/action.types.js';
import {
    getCommentsForActionItem,
    getCommentsForDocument,
    deleteComment as deleteCommentXHR,
    addComment as addCommentXHR,
} from './comment.api.js';
import {
    formSubmissionServerError,
    processBodyErrors,
} from '../../components/formvalidation/formvalidation.helper.js';
import { getStore } from '../../common/store.js';

function addCommentRequest(comment) {
    return {
        type: COMMENT_CREATE_REQUEST,
        comment,
    };
}

function addCommentError(error) {
    return {
        type: COMMENT_CREATE_ERROR,
        error,
    };
}

export function addCommentSuccess(comment) {
    return {
        type: COMMENT_CREATE_SUCCESS,
        comment,
    };
}

function deleteCommentRequest(comment) {
    return {
        type: COMMENT_DELETE_REQUEST,
        comment,
    };
}

function deleteCommentError(error) {
    return {
        type: COMMENT_DELETE_ERROR,
        error,
    };
}

export function deleteCommentSuccess(comment) {
    return {
        type: COMMENT_DELETE_SUCCESS,
        comment,
    };
}

export function addComment(params) {
    return async (dispatch) => {
        dispatch(addCommentRequest(params));

        try {
            const comment = await addCommentXHR(params);
            dispatch(addCommentSuccess(comment));
        } catch (error) {
            dispatch(addCommentError(error));

            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );
            return newError;
        }
    };
}

export function deleteComment(comment) {
    return async (dispatch) => {
        dispatch(deleteCommentRequest(comment));

        try {
            await deleteCommentXHR(comment);
            dispatch(deleteCommentSuccess(comment));
        } catch (error) {
            dispatch(deleteCommentError(error));
        }
    };
}

const openCommentsModalAfterPause = _debounce(
    (commentCount, meta) =>
        getStore().dispatch({
            type: COMMENT_MODAL_OPEN,
            ...meta,
            loading: commentCount > 0,
            comments: [],
        }),
    500
);

export function onOpenActionItemComments(actionItem) {
    return async (dispatch) => {
        try {
            // pause for 500ms before opening the modal, to allow the retrieve to occur, without giving the user a loader
            openCommentsModalAfterPause(actionItem.commentCount, {
                actionItem,
            });
            const comments = await getCommentsForActionItem(actionItem.id);
            openCommentsModalAfterPause?.cancel?.();

            dispatch({
                type: COMMENT_MODAL_OPEN,
                actionItem,
                loading: false,
                comments,
            });
        } catch (err) {
            openCommentsModalAfterPause?.cancel?.();

            dispatch({
                type: COMMENT_MODAL_OPEN,
                actionItem,
                loading: false,
                errorMsg: `Comments could not be loaded: ${
                    err.message || err.description
                }`,
            });
        }
    };
}

export function onOpenDocumentComments(doc) {
    return async (dispatch) => {
        try {
            // pause for 500ms before opening the modal, to allow the retrieve to occur, without giving the user a loader
            openCommentsModalAfterPause(doc.commentCount, {
                doc,
            });
            const comments = await getCommentsForDocument(doc.id);
            openCommentsModalAfterPause?.cancel?.();

            dispatch({
                type: COMMENT_MODAL_OPEN,
                doc,
                loading: false,
                comments,
            });
        } catch (err) {
            openCommentsModalAfterPause?.cancel?.();

            dispatch({
                type: COMMENT_MODAL_OPEN,
                doc,
                loading: false,
                errorMsg: `Comments could not be loaded: ${
                    err.message || err.description
                }`,
            });
        }
    };
}

export function onCloseComments() {
    return {
        type: COMMENT_MODAL_CLOSE,
    };
}
