import { get, postSuppressingWS } from '../../api/helpers.js';

export function getPrivateNotes(id) {
    const url = `/api/notes/occurrence/${id}`;
    return get(url);
}

export function createPrivateNotes(id, params) {
    const url = `/api/notes/occurrence/${id}`;
    return postSuppressingWS(url, params);
}

export function editPrivateNotes(id, params) {
    const url = `/api/notes/${id}`;
    return postSuppressingWS(url, params);
}
