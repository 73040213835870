import React from 'react';
import tw, { styled, css } from 'twin.macro';
import Icon from '~icons';
import { Popup, Menu } from '~modules/common/form-elements';

export const Trigger = styled.button`
    ${tw`text-xl sm:text-2xl lg:text-3xl text-icon cursor-pointer hover:text-icon-dark relative focus:outline-none`}

    ${({ isSmall }) => isSmall && tw`text-lg`}
    ${({ isOpen }) => isOpen && tw`text-icon-dark`}
    ${({ isWhite }) => isWhite && tw`text-white hover:text-white`}
    ${({ isSecondary }) =>
        isSecondary && tw`text-secondary hover:text-secondary`}
    ${({ isPrimary }) => isPrimary && tw`text-primary hover:text-primary`}
    ${({ isPrimaryHighlight }) =>
        isPrimaryHighlight &&
        tw`text-primary-highlight hover:text-primary-highlight`}
    ${({ styles }) =>
        styles
            ? styles
            : css`
                  &:before {
                      ${tw`absolute`}
                      content: '';
                      left: -0.75rem;
                      right: -0.75rem;
                      top: -0.75rem;
                      bottom: -0.75rem;
                  }
              `}
    ${({ disabled }) =>
        disabled &&
        css`
            svg {
                ${tw`text-icon-disabled cursor-not-allowed opacity-50 outline-none`}
            }
        `}
`;

const IconWrap = styled.div(({ hasNoIconPadding }) => [
    `
    margin-left: -0.35em;
    overflow: hidden;
`,
    hasNoIconPadding && tw`mx-0`,
]);

const PopupTrigger = ({ iconName, hasNoIconPadding, title, ...rest }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const handleClick = (evt) => {
        // Use preventDefault instead of stopPropagation to allow
        // trigger to work while nested within a label in iOS/Safari
        evt.preventDefault();
    };
    return (
        <Trigger type="button" {...rest} onClick={handleClick}>
            <IconWrap {...{ hasNoIconPadding }}>
                <Icon name={iconName} {...{ title }} />
            </IconWrap>
        </Trigger>
    );
};

export default function MenuWithIconTrigger({
    options,
    isWhite = false,
    isSecondary = false,
    isPrimary = false,
    isPrimaryHighlight = false,
    isSmall,
    iconName,
    styles,
    triggerProps,
    isHeaderButtonDrop,
    hasNoIconPadding,
    ...rest
}) {
    const filteredOptions = options.filter(Boolean);
    if (!filteredOptions.length) {
        return null;
    }
    return (
        <Popup
            on="click"
            trigger={PopupTrigger}
            triggerProps={{
                ...triggerProps,
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line unicorn/no-useless-spread
                ...{
                    isWhite,
                    iconName,
                    styles,
                    isSmall,
                    hasNoIconPadding,
                    isSecondary,
                    isPrimary,
                    isPrimaryHighlight,
                },
            }}
            position="bottom center"
            {...rest}
        >
            {({ setIsOpen }) => (
                <Menu {...{ options, setIsOpen, isHeaderButtonDrop }} />
            )}
        </Popup>
    );
}
