import React from 'react';

export default ({ title = 'Amazon Chime', ...rest }): JSX.Element => (
    <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" {...rest}>
        {title && <title>{title}</title>}
        <polygon
            points="168.36 123.45 168.36 94.633 157.63 100.03 168.36 123.47"
            fill="#C9EBEC"
        />
        <polygon
            points="194.96 103.45 239.48 58.942 168.36 94.633"
            fill="#C9EBEC"
        />
        <polygon
            points="168.36 129.3 159.37 153.49 159.39 153.46 179.77 133.08 192.35 158.12 255.29 158.12"
            fill="#C9EBEC"
        />
        <polygon
            points="168.36 94.633 168.36 123.45 255.29 123.45 194.96 103.45"
            fill="#00868C"
        />
        <polygon
            points="168.36 123.45 168.36 123.47 168.36 129.3 255.29 158.12 255.29 123.45"
            fill="#00B3BB"
        />
        <polygon
            points="131.95 168.27 160.61 168.27 151.79 194.87 196.35 239.44 155.27 157.58"
            fill="#C9EBEC"
        />
        <polygon
            points="159.39 153.46 220.85 214.93 192.35 158.12 179.77 133.08"
            fill="#00868C"
        />
        <polygon
            points="159.39 153.46 159.37 153.49 155.27 157.58 196.35 239.44 220.85 214.93"
            fill="#00B3BB"
        />
        <polygon
            points="101.75 159.27 101.78 159.31 122.16 179.69 97.118 192.25 97.118 255.19 125.95 168.27"
            fill="#C9EBEC"
        />
        <polygon
            points="131.95 168.27 131.78 168.27 131.78 255.19 151.79 194.87 160.61 168.27"
            fill="#00868C"
        />
        <polygon
            points="131.78 168.27 125.95 168.27 97.118 255.19 131.78 255.19"
            fill="#00B3BB"
        />
        <polygon
            points="60.316 151.74 15.81 196.26 86.915 160.57"
            fill="#C9EBEC"
        />
        <polygon
            points="86.915 131.73 86.915 160.57 97.655 155.17"
            fill="#C9EBEC"
        />
        <polygon
            points="101.78 159.31 40.311 220.76 97.118 192.25 122.16 179.69"
            fill="#00868C"
        />
        <polygon
            points="101.78 159.31 101.75 159.27 97.655 155.17 86.915 160.57 86.915 160.57 86.915 160.57 15.81 196.26 40.311 220.76"
            fill="#00B3BB"
        />
        <polygon
            points="95.919 101.7 95.894 101.74 75.501 122.12 62.939 97.08 0 97.08 86.915 125.9"
            fill="#C9EBEC"
        />
        <polygon
            points="86.915 160.57 86.915 160.57 86.915 131.73 0 131.73 60.316 151.74"
            fill="#00868C"
        />
        <polygon
            points="86.915 131.73 86.915 131.73 86.915 125.9 0 97.08 0 131.73"
            fill="#00B3BB"
        />
        <polygon
            points="123.34 86.928 94.67 86.928 103.5 60.329 58.942 15.76 100.01 97.605"
            fill="#C9EBEC"
        />
        <polygon
            points="95.894 101.74 34.429 40.273 62.939 97.08 75.501 122.12"
            fill="#00868C"
        />
        <polygon
            points="95.894 101.74 95.919 101.7 100.01 97.605 58.942 15.76 34.429 40.273"
            fill="#00B3BB"
        />
        <polygon
            points="153.54 95.919 153.5 95.894 133.12 75.514 158.16 62.951 158.16 0.012488 129.34 86.928"
            fill="#C9EBEC"
        />
        <polygon
            points="123.34 86.928 123.5 86.928 123.5 0.012488 103.5 60.329 94.67 86.928"
            fill="#00868C"
        />
        <polygon
            points="123.5 86.928 129.34 86.928 158.16 0.012488 123.5 0.012488"
            fill="#00B3BB"
        />
        <polygon
            points="153.5 95.894 214.97 34.441 158.16 62.951 133.12 75.514"
            fill="#00868C"
        />
        <polygon
            points="153.5 95.894 153.54 95.919 157.63 100.03 168.36 94.633 168.36 94.633 239.48 58.942 214.97 34.441"
            fill="#00B3BB"
        />
    </svg>
);
