import React from 'react';

const ADD_ID = 'shape-add';

const AddIconInner = ({
    iconThick = false,
}: {
    iconThick?: boolean;
}): JSX.Element => (
    <>
        <line
            x1="16"
            y1="2"
            x2="16"
            y2="30"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
        <line
            x1="2"
            y1="16"
            x2="30"
            y2="16"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
    </>
);

const AddIcon = ({
    iconThick = false,
    isSymbolRef = false,
    title = '',
    ...rest
}): JSX.Element => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        {isSymbolRef ? (
            <use href={`#${ADD_ID}`} xlinkHref={`#${ADD_ID}`} />
        ) : (
            <AddIconInner iconThick={iconThick} />
        )}
    </svg>
);

export { AddIcon as default, AddIconInner, ADD_ID };
