import { useDispatch, useSelector } from 'react-redux';
import { MODAL_ADD_MEETING_SIMPLE_CLOSE } from '~common/action.types';
import type { OpenSimpleAddMeetingModal } from '~modules/forms/addMeeting/addMeeting.types';
import { openSimpleAddMeetingModal } from './simpleAddMeeting.actions';

const useOpenSimpleAddMeetingModal = (): ((
    params: OpenSimpleAddMeetingModal
) => void) => {
    const dispatch = useDispatch();
    return ({
        dateStart,
        workspaceId,
        categoryData,
        isAddingMeetingToIntro,
        includeWithExistingMeetingId,
        eventData,
        createdSourceLocation,
        meetingType,
        isAddingWorkspace,
        isMergingEvent,
        isAddingEventAsNew,
        mergeWithExistingMeetingId,
    }): void => {
        dispatch(
            openSimpleAddMeetingModal({
                dateStart,
                workspaceId,
                categoryData,
                isAddingMeetingToIntro,
                includeWithExistingMeetingId, // groupWith
                eventData,
                createdSourceLocation,
                meetingType,
                isAddingWorkspace,
                isMergingEvent,
                isAddingEventAsNew,
                mergeWithExistingMeetingId, // mergeWith
            })
        );
    };
};

const useCloseSimpleAddMeetingModal = (): (() => void) => {
    const dispatch = useDispatch();
    return (): void => {
        dispatch({ type: MODAL_ADD_MEETING_SIMPLE_CLOSE });
    };
};

const useGetSimpleAddMeetingModalState = (): OpenSimpleAddMeetingModal =>
    useSelector(
        (state: { simpleAddMeetingModal: OpenSimpleAddMeetingModal }) =>
            state.simpleAddMeetingModal
    );

export {
    useOpenSimpleAddMeetingModal,
    useCloseSimpleAddMeetingModal,
    useGetSimpleAddMeetingModalState,
};
