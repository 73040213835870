export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_WITH_OFFSET = 'YYYY-MM-DD HH:mm:ssZ';
export const DATETIME_FORMAT_FOR_DISPLAY = 'll h:mma';
export const DATETIME_FORMAT_FOR_DISPLAY_WITH_AT = 'll [at] h:mma';
export const DATETIME_FORMAT_FOR_DISPLAY_NO_PERIOD = 'l h:mm';
export const TIME_FORMAT_FOR_DISPLAY = 'h:mma';
export const TIME_FORMAT_FOR_DISPLAY_NO_PERIOD = 'h:mm';

export const BACKGROUND_EVENT_PREFIX = 'BACKGROUND_';

// For Recurring Rules... also used on the client.
export const RECURRENCE_SCHEDULED = 'sch';
export const RECURRENCE_NOT_SCHEDULED = 'adh';
export const RECURRENCE_ONE_OFF = 'ooff';

export const RECURRENCE_NEVER = 'Never';
export const RECURRENCE_DAILY = 'Daily';
export const RECURRENCE_WEEKLY = 'Weekly';
export const RECURRENCE_MONTHLY_ABSOLUTE = 'AbsoluteMonthly';
export const RECURRENCE_MONTHLY_RELATIVE = 'RelativeMonthly';
export const RECURRENCE_YEARLY_ABSOLUTE = 'AbsoluteYearly';
export const RECURRENCE_YEARLY_RELATIVE = 'RelativeYearly';

export const ATTENDEE_ROLE_OWNER = 'owner';
export const ATTENDEE_ROLE_OWNER_ABSENT = 'owner-absent';
export const ATTENDEE_ROLE_ATTENDEE = 'attendee';
export const ATTENDEE_ROLE_ATTENDEE_ABSENT = 'attendee-absent';
export const ATTENDEE_ROLE_ABSENT_SUFFIX = '-absent';

export const STATUS_MINUTES_STARTED = 'started';
export const STATUS_MINUTES_COMPLETED = 'completed';
export const STATUS_MINUTES_SENT = 'sent';
export const STATUS_AGENDA_SENT = 'agenda-sent';

export const EMAIL_TYPE_MINUTES = 'minutes-email';
export const EMAIL_TYPE_AGENDA = 'agenda-email';
// AWS event types: https://docs.aws.amazon.com/ses/latest/DeveloperGuide/event-publishing-retrieving-sns-contents.html
export const EMAIL_EVENT_TYPE_SEND = 'Send';
export const EMAIL_EVENT_TYPE_DELIVERY = 'Delivery';
export const EMAIL_EVENT_TYPE_REJECT = 'Reject';
export const EMAIL_EVENT_TYPE_OPENED = 'Open';
export const EMAIL_EVENT_TYPE_CLICK = 'Click';
export const EMAIL_EVENT_TYPE_BOUNCE = 'Bounce';
export const EMAIL_EVENT_TYPE_COMPLAINT = 'Complaint';
export const EMAIL_EVENT_TYPE_RENDER_FAILURE = 'Rendering Failure';
export const EMAIL_EVENT_TYPE_DELIVERY_DELAY = 'DeliveryDelay';
export const EMAIL_EVENT_STATUS_INITIATED = 'Initiated';
export const EMAIL_EVENT_STATUS_NOT_SENT = 'Not sent';
export const EMAIL_EVENT_STATUS_SENT = 'Sent';
export const EMAIL_EVENT_STATUS_DELIVERED = 'Delivered';
export const EMAIL_EVENT_STATUS_REJECTED = 'Rejected';
export const EMAIL_EVENT_STATUS_OPENED = 'Opened';
export const EMAIL_EVENT_STATUS_BOUNCED = 'Bounced';

export const EMAIL_EVENT_TYPE_TO_STATUS_MAP = {
    [EMAIL_EVENT_TYPE_SEND]: EMAIL_EVENT_STATUS_SENT,
    [EMAIL_EVENT_TYPE_DELIVERY]: EMAIL_EVENT_STATUS_DELIVERED,
    [EMAIL_EVENT_TYPE_REJECT]: EMAIL_EVENT_STATUS_REJECTED,
    [EMAIL_EVENT_TYPE_OPENED]: EMAIL_EVENT_STATUS_OPENED,
    [EMAIL_EVENT_TYPE_CLICK]: EMAIL_EVENT_STATUS_OPENED,
    [EMAIL_EVENT_TYPE_BOUNCE]: EMAIL_EVENT_STATUS_BOUNCED,
};

export const MINUTE_ITEM_STATUS_COMPLETE = 'complete';
export const MINUTE_ITEM_STATUS_INCOMPLETE = '';

export const INCOMPLETE_TOPICS_ADD_TO_NEXT = 'add-to-next';
export const INCOMPLETE_TOPICS_MOVE_TO_NEXT = 'move-to-next';
export const INCOMPLETE_TOPICS_MARK_COMPLETE = 'mark-complete';
export const INCOMPLETE_TOPICS_DO_NOTHING = 'do-nothing';

export const PERMISSION_UPDATE_REASON_WORKSPACE_ADMIN =
    'workspace-admin-update';
export const PERMISSION_UPDATE_REASON_LEAVE_WORKSPACE = 'leaveWorkspace';
export const PERMISSION_UPDATE_REASON_MOVE_OCCURRENCE_TO_OTHER_MEETING =
    'moveOccurrence';
export const PERMISSION_CREATE_REASON_REMOVE_WORKSPACE_MEMBER =
    'removeWorkspaceMember';
export const PERMISSION_CREATE_REASON_UNDELETE_MEETING = 'undeleteMeeting';
export const PERMISSION_UPDATE_REASON_REMOVE_WORKSPACE_MEMBER =
    'removeWorkspaceMember';
export const PERMISSION_UPDATE_REASON_MERGE_USER_ACCOUNTS = 'mergeUserAccounts';
export const PERMISSION_CREATE_REASON_WORKSPACE_ADD_ON_MEETING_ADD =
    'workspace-permission-add-on-meeting-add';
export const PERMISSION_UPDATE_REASON_WORKSPACE_UPGRADE_ON_MEETING_ADD =
    'workspace-permission-upgraded-on-meeting-add';
export const PERMISSION_CREATE_REASON_WORKSPACE_ADD_ON_OCCURRENCE_ADD =
    'workspace-permission-add-on-occurrence-add';
export const PERMISSION_CREATE_REASON_WORKSPACE_ADD_ON_OCCURRENCE_MOVE =
    'workspace-permission-add-on-occurrence-move';
export const PERMISSION_UPDATE_REASON_WORKSPACE_UPGRADE_ON_OCCURRENCE_ADD =
    'workspace-permission-upgraded-on-occurrence-add';
export const PERMISSION_UPDATE_MMADMIN = 'minuteme-admin';
export const PERMISSION_DELETE_REASON_WORKSPACE_LAST_PERMISSION =
    'deleteLastUserPermissionInWorkspace';
export const PERMISSION_DELETE_REASON_WORKSPACE_ACCESS_REQUEST_CANCELLED =
    'deleteRequestAccessPermissionInWorkspace';
export const PERMISSION_DELETE_REASON_WORKSPACE_DELETED = 'workspace-deleted';
export const PERMISSION_EVENT_TYPE_CREATE = 'permission:create';
export const PERMISSION_EVENT_TYPE_UPDATE = 'permission:update';
export const PERMISSION_EVENT_TYPE_DELETE = 'permission:delete';

export const DEFAULT_GROUP_NAME = 'Meetings';
export const GROUP_TYPE_TAG = 'tag';
export const GROUP_TYPE_MEETING = 'meeting';
export const GROUP_TYPE_WORKSPACE = 'workspace';
export const MEETING_LIMIT_PERCENT_BEFORE_NOTIFICATION = 0.65;
export const WORKSPACE_COUPON_REFERER_COOKIE_NAME = 'couponreferer';

export const SAVE_TO_CLOUD_RETRY_INTERVAL = 5 * 60; // 5 minutes
export const SMOKEBALL_EXTENDED_PROPERTY_MATTERID = `String {8d736f90-8f45-4591-81aa-c85a98f1261b} Name MatterId`;

export const COGNITO_STATUS_CONFIRMED = 'CONFIRMED';

export const LOGIN_SOURCE_WEBAPP = 'webapp';

export const NOTIFICATION_STATUS_READ = 'read';
export const NOTIFICATION_STATUS_UNREAD = 'unread';
export const NOTIFICATION_CREATED = 'created';

export const NOTIFICATION_OUTCOME_ACCEPTED = 'accepted';
export const NOTIFICATION_OUTCOME_REJECTED = 'rejected';

export const NOTIFICATION_TYPE_PROMPT = 'prompt';
export const NOTIFICATION_TYPE_CONFIRM = 'confirm';
export const NOTIFICATION_TYPE_MESSAGE = 'message';
export const NOTIFICATION_TYPE_MESSAGEMODAL = 'messagemodal';

export const NOTIFICATION_MODAL_PAYMENT_FAILED = 'payment-failed';
export const NOTIFICATION_MODAL_UPGRADED_PRIMARY = 'upgraded-primary';
export const NOTIFICATION_MODAL_UPGRADED_SECONDARY = 'upgraded-secondary';
export const NOTIFICATION_MODAL_TERMS_PRIVACY_UPDATED = 'terms-privacy-updated';
export const NOTIFICATION_MODAL_FEATURE_TRIAL_EXPIRY = 'feature-trial-expiry';

export const PAYMENT_STATUS_ERROR = 'payment-error';
export const PAYMENT_INVOICE_STATUS_OPEN = 'open';
export const PAYMENT_STATUS_ERRORTYPE_CARD_ERROR = 'card_error';

export const ACTION_ITEM_CREATED_ACTION_ITEM_REVIEW = 'action-item-review';
export const ACTION_ITEM_CREATED_MINUTES_INLINE = 'minutes-inline';
export const ACTION_ITEM_CREATED_MINUTES = 'minutes';
export const ACTION_ITEM_CREATED_OCCURRENCE = 'occurrence';

export const ACTION_ITEM_PRIORITY_HIGH = 3;
export const ACTION_ITEM_PRIORITY_MEDIUM = 2;
export const ACTION_ITEM_PRIORITY_LOW = 1;
export const ACTION_ITEM_PRIORITY_NONE = 0;

export const ACTION_ITEM_REVIEW_BY_ASSIGNEE = 'assignee';
export const ACTION_ITEM_REVIEW_BY_STATUS = 'status';

export const RETRY_EVENT_TYPE_POSTGRES = 'postgres';

export const AGENDA_CONTENT_SOURCE_BLANK = 'blank';
export const AGENDA_CONTENT_SOURCE_AGENDAITEM = 'agenda-template-topic';

export const RTE_SLATEJS = 'slate';
export const RTE_TINYMCE = 'tiny';

export const DELETE_REASON_MEETING_DELETE = 'meeting-delete';
export const DELETE_REASON_REPLACE_MINUTEITEMS_FROM_AGENDA =
    'replace-minuteitems-from-agenda';

export const DELETE_REASON_OCCURRENCE_DELETE = 'occurrence-delete';

// Must be all lowercase
export const CATEGORY_PALETTE = [
    '#e53e3e',
    '#ff9800',
    '#ffcd00',
    '#cddc39',
    '#2f855a',
    '#00b4ce',
    '#9f7aea',
    '#9c27b0',
    '#ed64a6',
    '#bcc9dc', // line-dark from our config
];

export const HIGHLIGHT_COLORS = [
    '#FBFF00',
    '#1AFE00',
    '#3DFDFF',
    '#FFB43C',
    '#FE19FF',
];

export const FONT_COLORS = [
    '#BF1800',
    '#EC7D31',
    '#70AD47',
    '#8244C4',
    '#A5A5A5',
];

export const NO_CATEGORY_COLOR = '#bcc9dc';
export const PAGE_TYPE_OCCURRENCE = 'occurrence';
export const PAGE_TYPE_VIDEO = 'video';

export {
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_DELETE_MEETING,
    ACTIVITY_TYPE_RESTORE_MEETING,
    ACTIVITY_TYPE_DELETE_OCCURRENCE,
    ACTIVITY_TYPE_MOVE_OCCURRENCE,
    AUTHSOURCE_ASANA,
    AUTHSOURCE_AZUREAD,
    AUTHSOURCE_AZUREAD_TASKS,
    AUTHSOURCE_GOOGLE_TASKS,
    AUTHSOURCE_OUTLOOK_CALENDAR,
    AUTHSOURCE_GOOGLE_CALENDAR,
    AUTHSOURCE_MINUTEME,
    AUTHSOURCE_MINUTEME_MOBILE,
    AUTHSOURCE_AZUREAD_MOBILE,
    AUTHSOURCE_AZUREAD_CONTACTS,
    AUTHSOURCE_AZUREAD_FILES,
    AUTHSOURCE_GOOGLE,
    AUTHSOURCE_GOOGLE_DRIVE,
    AUTHSOURCE_GOOGLE_MOBILE,
    AUTHSOURCE_GOOGLE_CONTACTS,
    AUTHSOURCE_SMOKEBALL_AU,
    AUTH_CONSENT_NOT_PROVIDED_ERROR,
    AUTHSOURCE_TO_HOSTTYPE_MAPPING,
    AUTHSOURCE_CALENDARS,
    AUTHSOURCE_FILES,
    AUTHSOURCE_CRM,
    AUTHSOURCE_CRM_WITH_TASKS,
    AUTHSOURCE_CRM_WITH_CALENDAR_META,
    AUTHSOURCE_TASKS,
    AUTHSOURCE_TASKS_WITH_WEBHOOK,
    INTEGRATION_TYPE_TASKS,
    INTEGRATION_TYPE_CALENDARS,
    INTEGRATION_TYPE_AUTHENTICATION,
    INTEGRATION_TYPE_CONTACTS,
    INTEGRATION_TYPE_FILES,
    INTEGRATION_TYPE_CRM,
    INTEGRATION_SCOPES_AUTH,
    INTEGRATION_SCOPES_AUTH_ALL,
    INTEGRATION_SCOPES_CONTACTS,
    INTEGRATION_AUTHSOURCE_TO_CONTACTS_MAP,
    INTEGRATION_SCOPES_CONTACTS_ALL,
    INTEGRATION_SCOPES_FILES,
    INTEGRATION_AUTHSOURCE_TO_FILES_MAP,
    INTEGRATION_SCOPES_FILES_ALL,
    INTEGRATION_SCOPES_TASKS,
    INTEGRATION_SCOPES_TASKS_ALL,
    INTEGRATION_SCOPES_CALENDAR,
    INTEGRATION_SCOPES_CALENDAR_ALL,
    INTEGRATION_SCOPES_CRM,
    INTEGRATION_SCOPES_CRM_ALL,
    INTEGRATION_STATUS_ACTIVE,
    INTEGRATION_STATUS_INACTIVE,
    INTEGRATION_STATUS_RECONNECT,
    INTEGRATION_STATUS_HIDDEN,
    PERMISSION_TYPE_MEETING,
    PERMISSION_TYPE_OCCURRENCE,
    PERMISSION_TYPE_ACCOUNT,
    PERMISSION_TYPE_WORKSPACE,
    PERMISSION_TYPE_TEMPLATE_LIBRARY,
    PERMISSION_LEVEL_OWNER,
    PERMISSION_LEVEL_ADMIN,
    PERMISSION_LEVEL_VIEW,
    PERMISSION_LEVEL_UPDATE,
    PERMISSION_LEVEL_NONE,
    PERMISSION_STATUS_REJECTED,
    PERMISSION_STATUS_PENDING,
    PERMISSION_STATUS_ACCEPTED,
    PERMISSION_STATUS_REQUESTED,
    PERMISSION_STATUS_LEFT,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_ACCEPTED,
    PERMISSION_STATUS_WORKSPACE_LEFT_WAS_ACCEPTED,
    PERMISSION_STATUS_WORKSPACE_LEFT_WAS_PENDING,
    PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED,
    WORKSPACE_PLAN_PLUS,
    WORKSPACE_PLAN_BASIC,
    WORKSPACE_PLAN_PER_USER_COST,
    WORKSPACE_PLAN_CONFIG,
    WORKSPACE_DEFAULT_MEETING_LIMIT,
    GRANT_LEVEL_NONE,
    GRANT_LEVEL_READ,
    GRANT_LEVEL_WRITE,
    GRANT_LEVEL_ADMIN,
    NOTIFICATION_PREFERENCE_DEFAULTS,
    DOCUMENT_VISIBILITY_VISIBLE,
    DOCUMENT_VISIBILITY_HIDDEN,
    MEETING_CREATED_SOURCE_WORKSPACE,
    MEETING_CREATED_SOURCE_NEW_WORKSPACE_TOAST,
    MEETING_CREATED_SOURCE_ADD_PATH,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_DAY,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_PLACEHOLDER,
    MEETING_CREATED_SOURCE_SERIES_DASHBOARD,
    MEETING_CREATED_SOURCE_SERIES_DASHBOARD_PLACEHOLDER,
    MEETING_CREATED_SOURCE_SERIES_SIDEBAR,
    MEETING_CREATED_SOURCES,
    MEETING_CREATED_MODAL_SIMPLE,
    MEETING_CREATED_MODAL_ADVANCED,
    MEETING_CREATED_MODAL_EVENT_SIMPLE,
    MEETING_CREATED_MODAL_EVENT_MATCH,
    MEETING_TYPE_11_121,
    MEETING_TYPE_TEAM,
    MEETING_TYPE_STAFF,
    MEETING_TYPE_PROJECT,
    MEETING_TYPE_EXECUTIVE,
    MEETING_TYPE_BOARD,
    MEETING_TYPE_COMMITTEE,
    MEETING_TYPE_HR,
    MEETING_TYPE_CLIENT,
    MEETING_TYPE_OTHER,
    MEETING_TYPE_LIST,
} from '../../shared/constants/index.js';
