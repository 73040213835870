import _reject from 'lodash/reject.js';
import ObjectID from 'bson-objectid';
import { pauseUpdates, resumeUpdates } from '~common/store';
import {
    BULK_CREATE_AGENDA_ITEM_REQUEST,
    BULK_CREATE_AGENDA_ITEM_ERROR,
    EDIT_AGENDA_ITEM_REQUEST,
    EDIT_AGENDA_ITEM_SUCCESS,
    DELETE_AGENDA_ITEM_REQUEST,
    DELETE_AGENDA_ITEM_SUCCESS,
    CREATE_AGENDA_ITEM_REQUEST,
    CREATE_AGENDA_ITEM_ERROR,
    CREATE_AGENDA_ITEM_SUCCESS,
    RENUMBER_MEETING_AGENDAITEMS_WEBSOCKET,
    BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS,
    UPDATE_TEMPLATE_SUCCESS,
} from '~common/action.types';
import {
    applyTemplateToMeetingFromOccurrence,
    createAgendaItem,
    createAgendaItemUpdateAfter,
    createAgendaItemsBulk,
    editAgendaItem,
    deleteAgendaItem,
    renumberAgendaItem,
} from './agenda.api.js';
import {
    alertError,
    alertSuccess,
    alertPersistedErrorWithClose,
    removeAlert,
} from '~modules/alert/alert.actions';
import { isDetailPage } from '~modules/navigation';
import { getFirstOccurrenceAfterStartOfToday } from '~modules/occurrence/occurrence.helpers';
// import { getMeetingFromStoreById } from '~modules/meeting/meeting.selectors';
// import { getTemplateFromStoreByMeeting } from '~modules/template/template.selectors';

function onAgendaItemBulkCreateRequest(meetingId, agendaItems) {
    return {
        type: BULK_CREATE_AGENDA_ITEM_REQUEST,
        meetingId,
        agendaItems,
    };
}

function onAgendaItemBulkCreateError(meetingId, error) {
    return {
        type: BULK_CREATE_AGENDA_ITEM_ERROR,
        meetingId,
        error,
    };
}

function onAgendaItemEditRequest(agendaItem) {
    return {
        type: EDIT_AGENDA_ITEM_REQUEST,
        meetingId: agendaItem.meeting,
        agendaItem,
    };
}

export function onAgendaItemEditSuccess(
    meetingId,
    { agendaItem, agendaItems }
) {
    return {
        type: EDIT_AGENDA_ITEM_SUCCESS,
        meetingId,
        agendaItem,
        agendaItems,
    };
}

function onAgendaItemCreateRequest(meetingId, agendaItem, agendaItems) {
    return {
        type: CREATE_AGENDA_ITEM_REQUEST,
        meetingId,
        agendaItem,
        agendaItems,
    };
}

function onAgendaItemCreateError(meetingId, agendaItem, agendaItems, error) {
    return {
        type: CREATE_AGENDA_ITEM_ERROR,
        meetingId,
        agendaItem,
        agendaItems,
        error,
    };
}

export function onAgendaItemCreateSuccess(agendaItem) {
    return {
        type: CREATE_AGENDA_ITEM_SUCCESS,
        agendaItem,
    };
}

export function onAgendaItemRenumberSuccessWebsocket(meetingId, agendaItems) {
    return {
        type: RENUMBER_MEETING_AGENDAITEMS_WEBSOCKET,
        meetingId: meetingId,
        agendaItems,
    };
}

export function onAgendaItemDeleteSuccess(params) {
    let agendaItem, agendaItems;
    if (Array.isArray(params)) {
        agendaItems = params;
    } else {
        agendaItem = params;
    }
    return (dispatch) => {
        dispatch({
            type: DELETE_AGENDA_ITEM_SUCCESS,
            agendaItem,
            agendaItems,
        });
    };
}

export function onAgendaItemBulkSave(meetingId, topics) {
    return async (dispatch) => {
        const topicsToSave = _reject(topics, (t) => !ObjectID.isValid(t.id));

        dispatch(onAgendaItemBulkCreateRequest(meetingId, topicsToSave));

        try {
            await createAgendaItemsBulk(meetingId, topicsToSave);
        } catch (error) {
            dispatch(onAgendaItemBulkCreateError(meetingId, error));
            dispatch(
                alertError({
                    id: `onAgendaItemBulkSave-${meetingId}`,
                    title: 'Template topics could not be saved',
                    error,
                })
            );
        }
    };
}

export function onAgendaItemRenumber(
    meetingId,
    id,
    targetItem,
    allTopics,
    topicsAreSavedToDB = true
) {
    return async (dispatch) => {
        if (topicsAreSavedToDB) {
            try {
                // provide the occurrenceId of the first occurrence after the start of today, so that "this and all future occurrences" that use the agenda template can be updated
                const occurrence = getFirstOccurrenceAfterStartOfToday();
                const occurrenceId = occurrence?.id;

                await renumberAgendaItem(id, { targetItem }, occurrenceId);
            } catch (error) {
                dispatch(
                    alertError({
                        id: `onAgendaItemRenumber-${meetingId}`,
                        title: 'Template topics could not be reordered',
                        error,
                    })
                );
            }
        } else {
            // TODO I don't think this path ever gets executed. It's a "hangover" from copying minuteItem code.
            await dispatch(onAgendaItemBulkSave(meetingId, allTopics));
        }
    };
}

export function onAgendaItemSave(
    meetingId,
    params,
    allTopics,
    topicsAreSavedToDB = true
) {
    return async (dispatch) => {
        if (topicsAreSavedToDB) {
            dispatch(
                onAgendaItemEditRequest({
                    ...params,
                    isLocalUpdate: true,
                })
            );

            try {
                dispatch(
                    removeAlert({
                        id: `onAgendaItemSave-${meetingId}`,
                    })
                );

                // provide the occurrenceId of the first occurrence after the start of today, so that "this and all future occurrences" that use the agenda template can be updated
                const occurrence = getFirstOccurrenceAfterStartOfToday();
                const occurrenceId = occurrence?.id;

                const agendaItem = await editAgendaItem(
                    params.id,
                    params,
                    occurrenceId
                );
                dispatch(
                    onAgendaItemEditSuccess(meetingId, {
                        agendaItem: {
                            ...agendaItem,
                            isLocalUpdate: true,
                        },
                    })
                );
            } catch (error) {
                dispatch(
                    alertPersistedErrorWithClose({
                        id: `onAgendaItemSave-${meetingId}`,
                        title: 'Template topic could not be saved',
                        error,
                    })
                );
            }
        } else {
            // TODO I don't think this path ever gets executed. It's a "hangover" from copying minuteItem code.
            await dispatch(
                onAgendaItemBulkSave(
                    meetingId,
                    allTopics.map((t) => (t.id === params.id ? params : t))
                )
            );
        }
    };
}

export function onAgendaItemDelete(
    meetingId,
    agendaItem,
    pathToNavigate,
    newActiveAgendaItem,
    reorderedAgendaItems,
    topicsAreSavedToDB = true
) {
    return async (dispatch) => {
        if (topicsAreSavedToDB) {
            dispatch({
                type: DELETE_AGENDA_ITEM_REQUEST,
                agendaItem,
                agendaItems: reorderedAgendaItems,
            });

            try {
                dispatch(
                    removeAlert({
                        id: `onAgendaItemDelete-${meetingId}`,
                    })
                );

                // provide the occurrenceId of the first occurrence after the start of today, so that "this and all future occurrences" that use the agenda template can be updated
                const occurrence = getFirstOccurrenceAfterStartOfToday();
                const occurrenceId = occurrence?.id;

                // Push user ahead of deletion for speedier feeling interface
                pathToNavigate?.();

                await deleteAgendaItem(agendaItem.id, occurrenceId);
                dispatch(onAgendaItemDeleteSuccess(agendaItem));
            } catch (error) {
                dispatch(
                    alertError({
                        id: `onAgendaItemDelete-${meetingId}`,
                        title: 'Template topic could not be deleted',
                        error,
                    })
                );
            }
        } else {
            // TODO I don't think this path ever gets executed. It's a "hangover" from copying minuteItem code.
            const topicsToRemain = _reject(
                reorderedAgendaItems,
                (t) => !ObjectID.isValid(t.id)
            );

            await dispatch(onAgendaItemBulkSave(meetingId, topicsToRemain));
        }
    };
}

export function onAgendaItemCreate(
    id,
    newItem,
    allTopics,
    topicsAreSavedToDB = true
) {
    return async (dispatch, getState) => {
        if (topicsAreSavedToDB) {
            const topicIds = _reject(allTopics, newItem).map((m) => m.id);
            const prevTopics = getState().agendaItems.agendaItems.filter(
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line unicorn/prefer-includes
                (m) => topicIds.indexOf(m.id) > -1
            );

            dispatch(onAgendaItemCreateRequest(id, newItem, allTopics));

            // optimistically update the store, and return early so user can be redirected to new topic.
            dispatch(onAgendaItemCreateBackground(newItem, id, prevTopics));

            return newItem;
        } else {
            // TODO I don't think this path ever gets executed. It's a "hangover" from copying minuteItem code.
            await dispatch(onAgendaItemBulkSave(id, allTopics));
        }
    };
}

function onAgendaItemCreateBackground(newItem, id, prevTopics) {
    return async (dispatch) => {
        try {
            const source = isDetailPage(location.pathname)
                ? 'meeting'
                : 'template';

            // provide the occurrenceId of the first occurrence after the start of today, so that "this and all future occurrences" that use the agenda template can be updated
            const occurrence = getFirstOccurrenceAfterStartOfToday();
            const occurrenceId = occurrence?.id;

            const agendaItem = await createAgendaItem(
                source,
                newItem,
                occurrenceId
            );
            dispatch(onAgendaItemCreateSuccess(agendaItem));
        } catch (error) {
            dispatch(onAgendaItemCreateError(id, newItem, prevTopics, error));
            dispatch(
                alertError({
                    id: `onAgendaItemCreate-${id}`,
                    title: 'Topic could not be added',
                    error,
                })
            );
        }
    };
}

export function onAgendaItemCreateFromMinutesTopic(
    minuteItem,
    newItem,
    agendaItems
) {
    return async (dispatch) => {
        const meetingId = minuteItem.meeting;
        dispatch(onAgendaItemCreateRequest(meetingId, newItem));

        try {
            // provide the occurrenceId of the first occurrence after the start of today, so that "this and all future occurrences" that use the agenda template can be updated
            const { id: viewingOccurrenceId } =
                isDetailPage(location.pathname) || {};

            const occurrence = getFirstOccurrenceAfterStartOfToday();
            const occurrenceId = occurrence?.id;

            const agendaItem = await (viewingOccurrenceId === occurrenceId
                ? createAgendaItemUpdateAfter('minutes', newItem, occurrenceId)
                : createAgendaItem('minutes', newItem, occurrenceId));

            dispatch(onAgendaItemCreateSuccess(agendaItem));
        } catch (error) {
            dispatch(
                onAgendaItemCreateError(meetingId, newItem, agendaItems, error)
            );
            dispatch(
                alertError({
                    id: `onAgendaItemCreateFromMinutesTopic-${meetingId}`,
                    title: 'Template topic could not be created',
                    error,
                })
            );
        }
    };
}

/**
 * Apply a template to a meeting (series).
 * If the agenda template already has topics, all existing topics are removed and replaced with those from the new template.
 * Any occurrences (starting from the one passed in) that already have template topics applied, will have existing non-modified template topics removed, and replaced with those from the new template.
 * @param {object} params
 * @param {object} params.template the template to be cloned
 * @param {object} params.meeting the meeting that should have the template applied to it
 * @param {object} params.occurrence the occurrence that this template should also be [re-applied] to, and every occurrence after this one
 */
export const onApplyTemplateToMeeting =
    ({ template, meeting, occurrence }) =>
    async (dispatch) => {
        pauseUpdates();
        try {
            // occurrences is [{ occurrence, minuteItems }]
            const {
                template: updatedTemplate,
                agendaItems,
                occurrences,
            } = await applyTemplateToMeetingFromOccurrence(
                meeting.id,
                template.id,
                occurrence.id
            );

            updatedTemplate &&
                dispatch({
                    type: UPDATE_TEMPLATE_SUCCESS,
                    template: { ...updatedTemplate, agendaItems },
                });

            (occurrences || []).forEach(({ occurrence, minuteItems }) =>
                dispatch({
                    type: BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS,
                    occurrence,
                    minuteItems,
                })
            );

            dispatch(
                alertSuccess({
                    id: `replaceMinuteItemsFromAgendaTemplate-${occurrence.id}-${template?.id}`,
                    title: 'Topics have been applied',
                })
            );
        } catch (error) {
            dispatch(
                alertError({
                    id: `replaceMinuteItemsFromAgendaTemplate-${occurrence.id}-${template?.id}`,
                    title: 'Topics could not be applied',
                    error,
                })
            );
        }
        resumeUpdates();
    };
