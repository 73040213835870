import React, { useRef } from 'react';
import tw from 'twin.macro';
import DashboardIcon from '~icons/dashboard';
import { Popup } from '~modules/common/form-elements';
import Calendar from '~modules/dashboard/meetings/components/Calendar';

const ContentContainer = tw.div`py-4 px-5`;
const DateWrap = tw.div`flex items-center whitespace-nowrap h-full`;

const Trigger = (): JSX.Element => (
    <button tw="appearance-none hover:text-body-dark flex gap-x-2 items-center text-xl px-2.5">
        <DashboardIcon />
    </button>
);

export default function DatePicker(): JSX.Element {
    const popupRef = useRef<{ handleClose: () => void }>();

    const PopupContent = (): JSX.Element => {
        return (
            <ContentContainer>
                <Calendar
                    isDropdown
                    onDayClick={(): void => {
                        popupRef?.current?.handleClose();
                    }}
                />
            </ContentContainer>
        );
    };

    return (
        <DateWrap>
            {/* @ts-expect-error Children not in remote type */}
            <Popup ref={popupRef} position="bottom right" trigger={Trigger}>
                <PopupContent />
            </Popup>
        </DateWrap>
    );
}
