export default () => (
    <svg viewBox="0 0 43 33" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m18.006 16.381-8.8418-8.8589c-0.69336-0.69604-1.8203-0.69604-2.5137 0l-6.1309 6.1421c-0.69336 0.6961-0.69336 1.823 0 2.5191l9.9082 9.9262c-0.52927-0.6731-0.48435-1.6513 0.1367-2.2724l7.4414-7.4561z"
            fill="#3259BB"
        />
        <path
            d="m16.725 32.498-6.2231-6.235c-0.66894-0.67-0.66894-1.756 0-2.426l23.289-23.335c0.669-0.67 1.753-0.67 2.421 0l6.223 6.235c0.669 0.67 0.669 1.756 0 2.426l-23.289 23.335c-0.668 0.669-1.752 0.669-2.421 0z"
            fill="#43ABFB"
        />
    </svg>
);
