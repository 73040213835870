import React from 'react';
import tw, { styled, css, theme } from 'twin.macro';

const screenSm = (css) => `@media (min-width:${theme`screens.sm`}){
        ${css}
    }`;

const Container = styled.h2([
    tw`word-break[break-word]`,
    ({ xxxl }) => xxxl && tw`text-xl sm:text-2xl lg:text-3xl leading-tight`,
    ({ xxl }) => xxl && tw`text-xl sm:text-2xl`,
    ({ xl }) => xl && tw`text-xl`,
    ({ lg }) => lg && tw`text-lg`,
    ({ isDark }) => isDark && tw`text-body-dark`,
    ({ isLight }) => isLight && tw`text-white`,
    ({ isBold }) => isBold && tw`font-semibold`,
    ({ isSelectable }) => !isSelectable && tw`select-none`,
    // Contain heading element size to font height (Custom to xxl headings used in MessageModal)
    ({ isCapsized }) =>
        isCapsized &&
        css`
            font-size: 1.25rem;
            line-height: 1.9375rem;
            padding: 0.003125rem 0;
            &:before {
                content: '';
                margin-top: -0.58287rem;
                display: block;
                height: 0;
            }
            &:after {
                content: '';
                margin-bottom: -0.511rem;
                display: block;
                height: 0;
            }
            ${screenSm(`
                font-size: 1.5rem;
                line-height: 2.2rem;
                &:before {
                    margin-top: -0.5205rem;
                }
                &:after {
                    margin-bottom: -0.43425rem;
                }
            `)}
        `,
]);

const Heading = ({ children, isDark = true, isBold = true, ...rest }) => (
    <Container {...{ isDark, isBold }} {...rest}>
        {children}
    </Container>
);

const H1 = (props) => <Heading xxxl as="h1" {...props} />;
const H2 = (props) => <Heading xxl as="h2" {...props} />;
const H3 = (props) => <Heading xl as="h3" {...props} />;
const H4 = (props) => <Heading lg as="h4" {...props} />;

export { Heading as default, H1, H2, H3, H4 };
