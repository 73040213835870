import {
    MODAL_MOVE_MEETING_OPEN,
    MODAL_MOVE_MEETING_CLOSE,
} from '~common/action.types';

import { moveMeetingToAnotherWorkspace } from '~modules/workspace/workspace.actions';

export const closeMoveMeetingModal = () => ({
    type: MODAL_MOVE_MEETING_CLOSE,
});

export const openMoveMeetingModal = (args) => {
    return (dispatch) => {
        const onProceed = (meetingId, params) =>
            dispatch(moveMeetingToAnotherWorkspace(meetingId, params));

        dispatch({
            type: MODAL_MOVE_MEETING_OPEN,
            // submitButtonLabel: 'Move',
            onProceed,
            ...args,
        });
    };
};
