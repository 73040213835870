import _isEqual from 'lodash/isEqual.js';
import _uniqBy from 'lodash/uniqBy.js';
import {
    FETCH_PAGEVIEWS_SUCCESS,
    PAGEVIEW_CREATED,
    PAGEVIEW_UPDATED,
    PAGEVIEW_META_UPDATED,
    PAGEVIEW_PAGESTATE_UPDATE,
    PAGEVIEW_DASHBOARDSTATE_UPDATE,
    PAGEVIEW_PAGESTATE_UPDATE_ROWCOUNT,
    UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
    UPDATE_USERPROFILE_WEBSOCKET,
    MEETING_PERMISSIONS_LOADED,
    OCCURRENCE_PERMISSIONS_LOADED,
    DELETE_MEETING_SUCCESS,
    DELETE_OCCURRENCES_WEBSOCKET,
    WORKSPACE_MEMBERS_LOADED,
    WORKSPACE_MEMBER_ADDED,
    WORKSPACE_MEMBER_UPDATED,
    WORKSPACE_MEMBER_DELETED,
    WORKSPACE_MEMBER_LOAD_FAILED,
    WORKSPACE_MEMBER_LIST_RESET,
    LOGOUT_SUCCESS,
    WORKSPACE_INVOICES_LOADED,
    WORKSPACE_INVOICES_LOAD_FAILED,
    WORKSPACE_INVOICE_CREATED,
    WORKSPACE_INVOICE_UPDATED,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
    OCCURRENCE_ACTIVITIES_LOADED,
    OCCURRENCE_ACTIVITY_LOADED,
    OCCURRENCE_ACTIVITY_COUNT_LOADED,
} from '../../common/action.types.js';
import { sortByFieldDesc } from '~common/utils';
import { updateMeetingMeta } from '~client/reducers/helpers';

// pageViews is array of objects, e.g.:
// { occurrence: <occurrence.id>, }

// pageStates is array of objects, e.g.:
// { id: <occurrence.id | meeting.id>, occurrence: <occurrence.id>, permissions: [] }

const initialState = {
    pageViews: [],
    pageStates: [],
    dashboardState: {},
};

const sortPageViews = (pageViews) =>
    pageViews.sort(
        (a, b) => b.lastOpenedAt.valueOf() - a.lastOpenedAt.valueOf()
    );

const pageViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAGEVIEW_UPDATED:
        case PAGEVIEW_CREATED: {
            return {
                ...state,
                pageViews: sortPageViews(
                    _uniqBy([action.pageView, ...state.pageViews], 'id')
                ),
            };
        }

        case FETCH_PAGEVIEWS_SUCCESS:
        case PAGEVIEW_META_UPDATED: {
            return {
                ...state,
                pageViews: sortPageViews(
                    _uniqBy([...action.pageViews, ...state.pageViews], 'id')
                ),
            };
        }

        case PAGEVIEW_PAGESTATE_UPDATE: {
            const page =
                state.pageStates.find(
                    (page) => page.id === action.pageState.id
                ) || {};

            return {
                ...state,
                pageStates: _uniqBy(
                    [
                        {
                            ...page,
                            ...action.pageState,
                        },
                        ...state.pageStates,
                    ],
                    'id'
                ),
            };
        }

        case MEETING_PERMISSIONS_LOADED: {
            const id = action?.meeting?.id || action.meetingId;
            const permissions = action.permissions || [];

            const page = state.pageStates.find((page) => page.id === id) || {
                id,
                meeting: id,
            };

            // to prevent unnecessary renders, return the same state if there are no differences
            if (_isEqual(page?.permissions, permissions)) return state;

            return {
                ...state,
                pageStates: _uniqBy(
                    [
                        {
                            ...page,
                            permissions,
                        },
                        ...state.pageStates,
                    ],
                    'id'
                ),
            };
        }

        case DELETE_MEETING_SUCCESS:
        case DELETE_OCCURRENCES_WEBSOCKET: {
            const { occurrences = [], occurrence, meeting } = action;
            const deletedIdList = [...occurrences, occurrence, meeting]
                .filter(Boolean)
                .map(({ id }) => id);

            if (
                !state.pageStates.some(
                    (page) =>
                        deletedIdList.includes(page.occurrence) ||
                        deletedIdList.includes(page.meeting)
                )
            )
                return state;

            return {
                ...state,
                pageStates: state.pageStates.filter(
                    (page) =>
                        !deletedIdList.includes(page.occurrence) &&
                        !deletedIdList.includes(page.meeting)
                ),
            };
        }

        case OCCURRENCE_PERMISSIONS_LOADED: {
            const id = action?.occurrence?.id || action.occurrenceId;
            const permissions = action.permissions || [];

            const page = state.pageStates.find((page) => page.id === id) || {
                id,
                occurrence: id,
            };

            // to prevent unnecessary renders, return the same state if there are no differences
            if (_isEqual(page?.permissions, permissions)) return state;

            return {
                ...state,
                pageStates: _uniqBy(
                    [
                        {
                            ...page,
                            permissions,
                        },
                        ...state.pageStates,
                    ],
                    'id'
                ),
            };
        }

        case PAGEVIEW_PAGESTATE_UPDATE_ROWCOUNT: {
            const { id, groupType, addRows = 0, displayCount = 0 } = action;
            const page = state.pageStates.find((page) => page.id === id) || {};

            return {
                ...state,
                pageStates: _uniqBy(
                    [
                        {
                            ...page,
                            [`${groupType}RowCount`]: Math.max(
                                0,
                                (page[`${groupType}RowCount`] || 0) + addRows,
                                displayCount + addRows
                            ),
                        },
                        ...state.pageStates,
                    ],
                    'id'
                ),
            };
        }

        case PAGEVIEW_DASHBOARDSTATE_UPDATE: {
            const newDashboardState = {
                ...state.dashboardState[action.dashboard],
                ...action.dashboardState,
            };

            // if dashboard state hasn't actually changed, return existing state.
            // This may be due to multiple renders / scrolling on the dashboard that triggers dashboard state updates.
            // This is important for performance of the dashboard.
            if (
                state.dashboardState[action.dashboard] &&
                _isEqual(
                    state.dashboardState[action.dashboard],
                    newDashboardState
                )
            ) {
                return state;
            }

            return {
                ...state,
                dashboardState: {
                    ...state.dashboardState,
                    [action.dashboard]: newDashboardState,
                },
            };
        }

        case UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET:
        case UPDATE_USERPROFILE_WEBSOCKET: {
            return {
                ...state,
                pageStates: state.pageStates.map((pageState) => {
                    if (
                        !pageState?.permissions?.some?.(
                            (permission) =>
                                permission.user.id === action.userProfile.id
                        )
                    )
                        return pageState;

                    return {
                        ...pageState,
                        permissions: pageState.permissions.map((permission) =>
                            permission.user.id !== action.userProfile.id
                                ? permission
                                : {
                                      ...permission,
                                      user: {
                                          ...permission.user,
                                          ...action.userProfile,
                                      },
                                  }
                        ),
                    };
                }),
            };
        }

        case WORKSPACE_MEMBER_LIST_RESET: {
            // reset the member list, to force it to be reloaded when user vists the workspace page
            const dashboard = `workspaces-${action.workspaceId}`;
            return {
                ...state,
                dashboardState: {
                    ...state.dashboardState,
                    [dashboard]: {
                        ...state.dashboardState[dashboard],
                        members: undefined,
                        isLoading: false,
                    },
                },
            };
        }

        case WORKSPACE_MEMBER_ADDED:
        case WORKSPACE_MEMBERS_LOADED: {
            const dashboard = `workspaces-${action.workspaceId}`;
            const members = [...(action.members || []), action.member].filter(
                Boolean
            );
            // if a new (individual) member is added via UI, we don't have their userId yet,
            // so their id is their email address.
            // Therefore, remove this temporary [email address] record if a valid Id comes through.
            const newDashboardState = {
                ...state.dashboardState[dashboard],
                members: _uniqBy(
                    [
                        ...members,
                        ...(
                            state.dashboardState[dashboard]?.members || []
                        ).filter(
                            (member) => member.id !== action.member?.email
                        ),
                    ],
                    'id'
                ),
                isLoading: false,
            };

            return {
                ...state,
                dashboardState: {
                    ...state.dashboardState,
                    [dashboard]: newDashboardState,
                },
            };
        }

        case WORKSPACE_MEMBER_LOAD_FAILED: {
            const dashboard = `workspaces-${action.workspaceId}`;

            const newDashboardState = {
                ...state.dashboardState[dashboard],
                isLoading: false,
            };

            return {
                ...state,
                dashboardState: {
                    ...state.dashboardState,
                    [dashboard]: newDashboardState,
                },
            };
        }

        case WORKSPACE_MEMBER_UPDATED: {
            const dashboard = `workspaces-${action.workspaceId}`;
            const existing = state.dashboardState[dashboard];
            if (
                (existing?.members || []).some(
                    (member) => member.id === action?.member?.id
                )
            ) {
                const newDashboardState = {
                    ...existing,
                    members: existing.members.map((member) =>
                        member.id === action.member.id
                            ? {
                                  ...member,
                                  ...action.member,
                                  permission: {
                                      ...member.permission,
                                      // TODO: Fix this the next time the file is edited.
                                      // eslint-disable-next-line unicorn/no-useless-fallback-in-spread
                                      ...(action?.member?.permission || {}),
                                  },
                              }
                            : member
                    ),
                };

                return {
                    ...state,
                    dashboardState: {
                        ...state.dashboardState,
                        [dashboard]: newDashboardState,
                    },
                };
            } else {
                return state;
            }
        }

        case WORKSPACE_MEMBER_DELETED: {
            const dashboard = `workspaces-${action.workspaceId}`;
            const existing = state.dashboardState[dashboard];
            if (
                (existing?.members || []).some(
                    (member) => member.id === action?.member?.id
                )
            ) {
                const newDashboardState = {
                    ...existing,
                    members: existing.members.filter(
                        (member) => member.id !== action.member.id
                    ),
                };

                return {
                    ...state,
                    dashboardState: {
                        ...state.dashboardState,
                        [dashboard]: newDashboardState,
                    },
                };
            } else {
                return state;
            }
        }

        case WORKSPACE_INVOICES_LOADED: {
            const dashboard = `workspaces-${action.workspaceId}`;

            const invoices = {
                data: action.invoices,
                isLoading: false,
                error: null,
            };

            const dashboardState = {
                ...state.dashboardState,
                [dashboard]: {
                    ...state.dashboardState[dashboard],
                    invoices,
                },
            };

            return { ...state, dashboardState };
        }

        case WORKSPACE_INVOICES_LOAD_FAILED: {
            const dashboard = `workspaces-${action.workspaceId}`;

            const invoices = {
                data: [],
                isLoading: false,
                error: action.error,
            };

            const dashboardState = {
                ...state.dashboardState,
                [dashboard]: {
                    ...state.dashboardState[dashboard],
                    invoices,
                },
            };

            return { ...state, dashboardState };
        }

        case WORKSPACE_INVOICE_CREATED: {
            const dashboard = `workspaces-${action.workspaceId}`;
            const existingDashboardState = state.dashboardState[dashboard];
            if (!existingDashboardState) return state;
            const existingData = existingDashboardState?.invoices?.data || [];

            const data = sortByFieldDesc(
                _uniqBy([action.invoice, ...existingData], 'id').filter(
                    Boolean
                ),
                'sortDate'
            );

            const dashboardState = {
                ...state.dashboardState,
                [dashboard]: {
                    ...existingDashboardState,
                    invoices: {
                        ...existingDashboardState.invoices,
                        data,
                    },
                },
            };

            return { ...state, dashboardState };
        }
        case WORKSPACE_INVOICE_UPDATED: {
            const dashboard = `workspaces-${action.workspaceId}`;
            const existingDashboardState = state.dashboardState[dashboard];
            if (!existingDashboardState) return state;
            const existingData = existingDashboardState?.invoices?.data || [];

            const data = sortByFieldDesc(
                _uniqBy([action.invoice, ...existingData], 'id').filter(
                    Boolean
                ),
                'sortDate'
            );

            const dashboardState = {
                ...state.dashboardState,
                [dashboard]: {
                    ...existingDashboardState,
                    invoices: {
                        ...existingDashboardState.invoices,
                        data,
                    },
                },
            };

            return { ...state, dashboardState };
        }

        case MOVE_OCCURRENCE_DELETE: {
            if ((action.pageViewIds?.length || 0) === 0) return state;
            if (
                !state.pageViews.some((pageView) =>
                    action.pageViewIds.includes(pageView.id)
                )
            )
                return state;

            return {
                ...state,
                pageViews: state.pageViews.filter(
                    (pageView) => !action.pageViewIds.includes(pageView.id)
                ),
            };
        }

        case MOVE_OCCURRENCE_UPDATE: {
            if ((action.pageViewIds?.length || 0) === 0) return state;
            if (
                !state.pageViews.some((pageView) =>
                    action.pageViewIds.includes(pageView.id)
                )
            )
                return state;

            const pageViews = state.pageViews.map((pageView) => {
                if (
                    !action.pageViewIds.includes(pageView.id) ||
                    !pageView.meeting
                )
                    return pageView;

                return updateMeetingMeta(pageView, action.meeting);
            });

            return {
                ...state,
                pageViews,
            };
        }

        case OCCURRENCE_ACTIVITIES_LOADED: {
            const id = action?.occurrence?.id || action.occurrenceId;
            const activities = action.activities || [];

            const page = state.pageStates.find((page) => page.id === id) || {
                id,
                occurrence: id,
            };

            // to prevent unnecessary renders, return the same state if there are no differences
            if (_isEqual(page?.activities, activities)) return state;

            return {
                ...state,
                pageStates: _uniqBy(
                    [
                        {
                            ...page,
                            activities,
                        },
                        ...state.pageStates,
                    ],
                    'id'
                ),
            };
        }

        case OCCURRENCE_ACTIVITY_LOADED: {
            const id = action.occurrenceId;
            const activity = action.activity || {};

            const page = state.pageStates.find((page) => page.id === id) || {
                id,
                occurrence: id,
            };

            // to prevent unnecessary renders, return the same state if there are no differences
            const pageStateActivity = (page.activities || []).find(
                (pageActivity) => pageActivity.id === activity.id
            );
            if (_isEqual(pageStateActivity, activity)) return state;

            return {
                ...state,
                pageStates: state.pageStates.map((pageState) =>
                    pageState.id !== id
                        ? pageState
                        : {
                              ...pageState,
                              activities: _uniqBy(
                                  [activity, ...pageState.activities],
                                  'id'
                              ),
                          }
                ),
            };
        }

        case OCCURRENCE_ACTIVITY_COUNT_LOADED: {
            const id = action?.occurrence?.id || action.occurrenceId;
            const activityCount = action.activityCount || 0;

            const page = state.pageStates.find((page) => page.id === id) || {
                id,
                occurrence: id,
            };

            // to prevent unnecessary renders, return the same state if there are no differences
            if (_isEqual(page?.activityCount, activityCount)) return state;

            return {
                ...state,
                pageStates: _uniqBy(
                    [
                        {
                            ...page,
                            activityCount,
                        },
                        ...state.pageStates,
                    ],
                    'id'
                ),
            };
        }

        case LOGOUT_SUCCESS:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default pageViewReducer;
