import moment from 'moment-timezone';
import { TIME_FORMAT_FOR_DISPLAY } from '~common/constants';
import { getStore } from '~client/common/store';
import { sameElse } from '~shared/utils';

const convertToUserLocaleAndTimezone = (dateAsMoment) => {
    const store = getStore();
    const { user = {} } = store ? store.getState() : {};
    const { timezone, locale } = user;

    const m = dateAsMoment.clone();
    if (timezone) {
        m.tz(timezone);
    }

    if (locale) {
        m.locale(locale);
    }

    return m;
};

moment.prototype.formatForUser = function (...args) {
    const m = convertToUserLocaleAndTimezone(this);
    return m.format(...args);
};

moment.prototype.formatForUserYearShortL = function () {
    const m = convertToUserLocaleAndTimezone(this);

    const formatL = m.localeData().longDateFormat('L');
    const formatYearShortL = formatL.replace(/YYYY/g, 'YY');

    return m.format(formatYearShortL);
};

moment.prototype.formatCalendarTimeForRange = function (toDate) {
    const startTime = this.formatForUser(TIME_FORMAT_FOR_DISPLAY);

    const m = convertToUserLocaleAndTimezone(this);

    const formattedDate = m.calendar(null, {
        lastDay: '[Yesterday at ]',
        sameDay: '[Today at ]',
        nextDay: '[Tomorrow at ]',
        lastWeek: '[Last] dddd [at ]',
        nextWeek: 'dddd [at ]',
        sameElse: sameElse(this),
    });

    if (
        !toDate ||
        this.isAfter(moment(), 'day') ||
        this.isBefore(moment(), 'day')
    ) {
        return formattedDate.concat(startTime);
    }
    const endTime = toDate.formatForUser(TIME_FORMAT_FOR_DISPLAY);

    return formattedDate.concat(startTime, ' - ', endTime);
};

moment.prototype.formatCalendarDateScheduleView = function () {
    const m = convertToUserLocaleAndTimezone(this);

    const fromNow = moment(m).fromNow();
    const formattedDate = m.calendar(null, {
        lastDay: '[Yesterday]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: '[Next] dddd',
        sameElse: function () {
            return '[' + fromNow + ']';
        },
    });

    return formattedDate;
};

moment.prototype.formatCalendarDateFromPastOrDate = function () {
    const m = convertToUserLocaleAndTimezone(this);

    const onDate = `[on] ${sameElse(m)}`;
    const fromNow = `[${m.fromNow()}]`;

    const formattedDate = m.calendar(null, {
        sameDay: '[today]',
        lastDay: '[yesterday]',
        nextDay: onDate,
        lastWeek: fromNow,
        nextWeek: onDate,
        sameElse: function () {
            if (m.isBefore(moment())) {
                return fromNow;
            }

            return onDate;
        },
    });

    return formattedDate;
};

moment.prototype.formatCalendarDateRecent = function () {
    const m = convertToUserLocaleAndTimezone(this);

    const formattedDate = m.calendar(null, {
        sameDay: () =>
            moment().diff(m, 'minutes') <= 30 ? '[Just now]' : '[Today]',
        lastDay: '[Yesterday]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: 'dddd',
        sameElse: sameElse(m),
    });

    return formattedDate;
};

moment.prototype.formatCalendarDate = function () {
    const m = convertToUserLocaleAndTimezone(this);

    const formattedDate = m.calendar(null, {
        sameDay: '[today]',
        lastDay: '[yesterday]',
        nextDay: '[tomorrow]',
        lastWeek: '[last] dddd',
        nextWeek: 'dddd',
        sameElse: sameElse(m),
    });

    return formattedDate.trim();
};

moment.prototype.formatDateTense = function (now) {
    const m = convertToUserLocaleAndTimezone(this);
    if (m.isSame(now, 'day')) {
        return 'TODAY';
    }
    if (m.isBefore(now)) {
        return 'PAST';
    }
    if (m.isAfter(now)) {
        return 'FUTURE';
    }
};

moment.prototype.formatCalendarDateDisplay = function () {
    const m = convertToUserLocaleAndTimezone(this);
    const formattedDate = m.calendar(null, {
        sameElse: sameElse(m),
    });
    return formattedDate;
};
