import _filter from 'lodash/filter.js';
import _find from 'lodash/find.js';
import _get from 'lodash/get.js';
import _isNil from 'lodash/isNil.js';
import _uniqBy from 'lodash/uniqBy.js';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { getStore } from '~common/store';
import {
    getOccurrenceIdSelector,
    getMeetingIdSelector,
    createDeepEqualSelector,
    getGroupIdSelector,
} from '~common/selector.helpers';
import {
    PAGINATION_DEFAULT_PAGE_SIZE,
    ACTION_ITEM_CREATED_ACTION_ITEM_REVIEW,
    SEARCH_PARAM_FILTER_ACTIONS,
    OVERDUE_ACTION_ITEMS,
    ALL_ACTION_ITEMS,
    NO_DUE_DATE_ACTION_ITEMS,
    UPCOMING_ACTION_ITEMS,
    SEARCH_PARAM_FILTER_ACTIONS_BY_ASSIGNEE,
    SEARCH_PARAM_FILTER_ACTIONS_BY_TERM,
    SEARCH_PARAM_FILTER_ACTIONS_BY_PRIORITY,
} from '~common/constants';
import { paginateData } from '~common/utils';
import {
    getGroupFromStoreById,
    getGroupSelector,
} from '~modules/group/group.selectors';
import {
    getMinuteItemFromStoreById,
    getMinutesByMeetingSelector,
} from '~modules/minutes/minutes.pure.selectors';
import { getOccurrenceSelector } from '~modules/occurrence/occurrence.selectors';
import {
    getOccurrencesForMeetingSelector,
    getOccurrenceInfoSelector,
} from '~modules/occurrence/occurrenceInfo.selectors';
import { getUserSelector } from '~modules/user/user.selectors';
import { getSearchParams } from '~modules/navigation/navigation.selectors';
import { meetingsSelector } from '~modules/meeting/meeting.selectors';

export function getActionItemsGroupedByOccurrence({
    actionItems,
    paginate,
    pageLength = PAGINATION_DEFAULT_PAGE_SIZE,
    activePage,
}) {
    const sortedActionItems = actionItems
        .map((ai) => {
            return {
                ...ai,
                startDate: _get(
                    ai,
                    'occurrenceMeta.startDate',
                    moment().add(1, 'day')
                ),
                _groupBy: ai.occurrence,
            };
        })
        .sort(
            (a, b) =>
                b.startDate.valueOf() - a.startDate.valueOf() ||
                b.occurrence < a.occurrence ||
                a.createdAt.valueOf() - b.createdAt.valueOf()
        );

    const actionItemsToView =
        paginate && sortedActionItems.length > pageLength
            ? paginateData(sortedActionItems, pageLength, activePage)
            : sortedActionItems;

    const occurrences = _uniqBy(
        actionItemsToView.map((aiv) => ({
            ...aiv.occurrenceMeta,
            id: aiv.occurrence,
            meeting: aiv.meeting,
        })),
        'id'
    );

    const group = occurrences.sort(
        (a, b) => b.startDate.valueOf() - a.startDate.valueOf()
    );

    return { group, actionItems: actionItemsToView };
}

export function getPageOfActions({
    actionItems,
    paginate,
    pageLength = PAGINATION_DEFAULT_PAGE_SIZE,
    activePage,
}) {
    return paginate && actionItems.length > pageLength
        ? paginateData(actionItems, pageLength, activePage)
        : actionItems;
}

/**
 * Get an action item from the store by it's action item Id.
 * @param {Object} [state] Redux store. If not provided, the global store will be used.
 * @param {Object|string} actionItem actionItem object or actionItem.id
 */
export function getActionItemFromStoreById(state, actionItem) {
    const _state = arguments.length === 2 ? state : getStore().getState();
    const _actionItem = arguments.length === 2 ? actionItem : arguments[0];

    // TODO: Convert next line to use "getId()"
    const id = typeof _actionItem === 'string' ? _actionItem : _actionItem.id;
    return _find(_state.actionItems.actionItems, { id });
}

export const getActionItemsFromStoreByMeeting = (state, meeting) => {
    // TODO: Convert next line to use "getId()"
    const id = typeof meeting === 'string' ? meeting : meeting.id;
    return state.actionItems.actionItems.filter((ai) => ai.meeting === id);
};

export const getActionItemsForOccurrenceSelector = createDeepEqualSelector(
    (state) => state.actionItems.actionItems,
    getOccurrenceIdSelector,
    (actionItems, occurrence) => _filter(actionItems, { occurrence })
);

export const getActionItemsForMeetingSelector = createSelector(
    (state) => state.actionItems.actionItems,
    getMeetingIdSelector,
    (actionItems, meeting) => {
        return actionItems.filter(
            (ai) => ai.meeting === meeting || _find(ai.links || [], { meeting })
        );
    }
);

/**
 * Memoized selector to get action items for a minute item from the Store,
 * including action items from the same topic on previous meetings (if that preference is set by the user).
 * This should only be used for getting the occurrence for the current occurrence.
 * Otherwise you will break the memoizer. In other situations, use getActionItemsFromStoreByMinuteItemId.
 * Get:
 *   - each actionItem has: minuteItem, isCompleted, completedAt, occurrenceMeta.startDate
 *   - for this topic (i.e. by either its name, or its agendaItemId)
 *   - everything that is still open, across all prior occurrences in the series
 *   - everything closed... since the last meeting
 * @param {Object} state Redux store.
 * @param {props} props
 * @param {(Object|string)} props.occurrence Occurrence object or occurrence.id
 */
export const getActionItemsForMinutesTopicSelectorWithPrevious = createSelector(
    getUserSelector,
    getActionItemsForMeetingSelector,
    getOccurrenceInfoSelector,
    getMinutesByMeetingSelector,
    (_, props) => props.topicId,
    (_, props) => props.agendaItem,
    (_, props) => props.itemTitle,
    (
        user,
        actionItemsForMeeting,
        { occurrence, prev },
        minutes,
        topicId,
        agendaItemId,
        itemTitle
    ) => {
        const prevTopics = minutes.filter(
            (minuteItem) =>
                minuteItem?.occurrenceMeta?.startDate?.valueOf() <
                    occurrence.startDate?.valueOf() &&
                (minuteItem.itemTitle?.trim().toLowerCase() ===
                    (itemTitle || '').trim().toLowerCase() ||
                    (agendaItemId && minuteItem.agendaItemId === agendaItemId))
        );
        const prevTopicIds = prevTopics.map(({ id }) => id);
        return actionItemsForMeeting
            .filter(
                (i) =>
                    i.minuteItem === topicId ||
                    (!!user.appPreferences?.showPreviousActionItems &&
                        prevTopicIds.includes(i.minuteItem) &&
                        (!i.isCompleted ||
                            i.completedAt.valueOf() > prev.endDate.valueOf()))
            )
            .sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf());
    }
);

/**
 * Memoized selector to get action items for a minute item from the Store,
 * excluding those carried forward from the previous meetings.
 * This should only be used for getting the occurrence for the current occurrence.
 * Otherwise you will break the memoizer. In other situations, use getActionItemsFromStoreByMinuteItemId.
 * Get:
 *   - each actionItem has: minuteItem, isCompleted, completedAt, occurrenceMeta.startDate
 *   - for this topic (i.e. by either its name, or its agendaItemId)
 * @param {Object} state Redux store.
 * @param {props} props
 * @param {(Object|string)} props.occurrence Occurrence object or occurrence.id
 */
export const getActionItemsForMinutesTopicSelector = createDeepEqualSelector(
    getOccurrenceIdSelector,
    getActionItemsForMinutesTopicSelectorWithPrevious,
    (occurrenceId, actionItemsWithPrevious) => {
        return actionItemsWithPrevious.filter(
            (actionItem) => actionItem.occurrence === occurrenceId
        );
    }
);

/**
 * Returns action items for a minute item from the store, by searching the store.
 * This is the non-memoized version.
 * For the memoized version, use getActionItemsForMinutesTopicSelector
 * @param {Object} [state=getStore().getState()] Redux store. If ommitted, will use the default Redux store.
 * @param {string} id occurrence.id
 */
export function getActionItemsFromStoreByMinuteItemId() {
    let state, id;
    switch (arguments.length) {
        case 2:
            [state, id] = arguments;
            break;
        default:
            [id] = arguments;
            state = getStore().getState();
    }
    return _filter(
        state.actionItems.actionItems,
        (actionItem) => actionItem.minuteItem === id
    );
}

const sortDashboardActionItems = (a, b) => {
    const aSortOrder = a.sortOrder;
    const bSortOrder = b.sortOrder;

    const aDueDate = a?.dueDate?.valueOf();
    const bDueDate = b?.dueDate?.valueOf();

    const aCreatedAt = a.createdAt.valueOf();
    const bCreatedAt = b.createdAt.valueOf();

    return (
        aSortOrder - bSortOrder ||
        aDueDate - bDueDate ||
        bCreatedAt - aCreatedAt
    );
};

export const getOpenOrRecentlyClosedActionItemsSelector = createSelector(
    (state) => state.actionItems.actionItems,
    (actionItems) => {
        const twentyFourHoursAgo = moment().subtract(1, 'days').valueOf();

        return actionItems.filter(
            (ai) =>
                (!ai.isCompleted ||
                    ai.completedAt.valueOf() >= twentyFourHoursAgo) &&
                !ai.deleted &&
                !ai?.meetingMeta?.archived &&
                !ai?.occurrenceMeta?.archived
        );
    }
);

export const getDashboardActionItems = (state, props) => {
    const group = getGroupSelector(state, props);
    const groupId = getGroupIdSelector(state, props);

    if (group || !groupId || groupId === 'all') {
        return getDashboardActionItemsByGroupFiltered(state, props);
    }
    return {};
};

/**
 * Get a list of action items within a group, for use on the Action items dashboard.
 * There may be a "group" applied,
 * @param {Object} state Redux state object.
 * @param {Object} props
 * @param {(Object|string)} [props.group] Group object or group.id. If not provided, will search meetings across all groups.
 * @param {boolean} [props.includeOnlyAccessibleMeetings=true] Only look at the accessible meetings within a Group. Required to ensure the getGroupSelector memoizes.
 * @returns {Object} groupMeetings: List of all meetings in the requested Group (or all groups), unfiltered.
 */
export const getDashboardActionItemsByGroup = createSelector(
    getOpenOrRecentlyClosedActionItemsSelector,
    getGroupIdSelector,
    getGroupSelector,
    getUserSelector,
    (allOpenOrRecentlyClosedActionItems, groupId, group, user) => {
        // If there is a group, then filter by the meetings in that group...
        // unless there are specific meetings selected in dashboardConfig filters.

        // Used to hide sidebar filters for a dashboard with no valid action items
        const hasAnyOpenOrRecentlyClosedActionItems =
            allOpenOrRecentlyClosedActionItems?.length > 0;

        if (groupId === ALL_ACTION_ITEMS)
            return {
                group: 'All',
                category: 'All action items',
                hasAnyOpenOrRecentlyClosedActionItems,
                groupHasAnyOpenOrRecentlyClosedActionItems:
                    allOpenOrRecentlyClosedActionItems?.length > 0,
                groupActionItems: allOpenOrRecentlyClosedActionItems,
            };
        if (!groupId) {
            const groupActionItems = allOpenOrRecentlyClosedActionItems.filter(
                (ai) =>
                    ai.assignees.some((assignee) => assignee.userId === user.id)
            );
            return {
                group: 'My',
                category: 'My action items',
                hasAnyOpenOrRecentlyClosedActionItems,
                groupHasAnyOpenOrRecentlyClosedActionItems:
                    groupActionItems?.length > 0,
                groupActionItems,
            };
        }

        if (!group) return;

        const filterByMeetings = group.meetings.map((g) => g.meetingId);

        const groupActionItems = allOpenOrRecentlyClosedActionItems.filter(
            (ai) => filterByMeetings.includes(ai.meeting)
        );

        return {
            category: group.title,
            group: group.workspaceId
                ? getGroupFromStoreById(group.workspaceId)?.title
                : group.title,
            hasAnyOpenOrRecentlyClosedActionItems,
            groupHasAnyOpenOrRecentlyClosedActionItems:
                groupActionItems?.length > 0,
            groupActionItems,
        };
    }
);

export const getAllAssigneesForOpenOrRecentlyClosedActionItems = createSelector(
    getOpenOrRecentlyClosedActionItemsSelector,
    (allOpenOrRecentlyClosedActionItems) => {
        // create a unique list of all assignees across all open or recently closed action items

        const allAssignees = allOpenOrRecentlyClosedActionItems.flatMap(
            ({ assignees }) => assignees?.map(({ userId }) => userId)
        );

        // The following creates a new array of the collective assignees from all individual action items
        const assigneeIds = Array.from(new Set(allAssignees));

        return assigneeIds;
    }
);

const getDashboardActionItemsByGroupFiltered = createSelector(
    getDashboardActionItemsByGroup,
    meetingsSelector,
    getSearchParams,
    (
        {
            group,
            category,
            hasAnyOpenOrRecentlyClosedActionItems,
            groupHasAnyOpenOrRecentlyClosedActionItems,
            groupActionItems,
        },
        meetings,
        searchParams
    ) => {
        const {
            [SEARCH_PARAM_FILTER_ACTIONS_BY_PRIORITY]: filterByPriorityRaw,
            [SEARCH_PARAM_FILTER_ACTIONS]: filterByStatus,
            [SEARCH_PARAM_FILTER_ACTIONS_BY_ASSIGNEE]: filterByAssignee,
            [SEARCH_PARAM_FILTER_ACTIONS_BY_TERM]: filterByTermRaw,
        } = searchParams;

        const filterByTerm = filterByTermRaw?.toLowerCase();

        const filterByPriority = Array.isArray(filterByPriorityRaw)
            ? filterByPriorityRaw.map(Number)
            : filterByPriorityRaw;

        const isMyActionItems = group === 'My';

        // Priority filter
        const priorityFilterIsActive = (filterByPriority || []).length > 0;

        const filteredByPriorityActionItems = priorityFilterIsActive
            ? groupActionItems.filter((ai) =>
                  (filterByPriority || []).includes(ai.priority ?? 0)
              )
            : groupActionItems;

        // Assignee filter
        const assigneeFilterIsActive = (filterByAssignee || []).length > 0;

        const filteredByAssigneeActionItems =
            // Only perform assignee filter if not 'My action items'
            assigneeFilterIsActive && !isMyActionItems
                ? groupActionItems.filter((ai) =>
                      ai?.assignees?.some(({ userId }) =>
                          (filterByAssignee || []).includes(userId)
                      )
                  )
                : groupActionItems;
        // Search bar filter
        const searchFilterIsActive = (filterByTerm || []).length > 0;
        const filteredBySearchTermActionItems = priorityFilterIsActive
            ? filteredByPriorityActionItems
            : searchFilterIsActive
            ? filteredByAssigneeActionItems.filter((ai) => {
                  const minuteItem = getMinuteItemFromStoreById(ai.minuteItem);
                  return (
                      // Filter by action item title
                      ai.title?.toLowerCase().includes(filterByTerm) ||
                      // Filter by topic title
                      minuteItem?.itemTitle
                          ?.toLowerCase()
                          .includes(filterByTerm) ||
                      // Filter by occurrence title
                      ai.occurrenceMeta?.title
                          ?.toLowerCase()
                          .includes(filterByTerm) ||
                      // Filter by series title
                      meetings.some(
                          (m) =>
                              m.id === ai.meeting &&
                              m.title.toLowerCase().includes(filterByTerm)
                      )
                  );
              })
            : filteredByAssigneeActionItems;
        // Status filters
        const hasNoDueDateFilter = (filterByStatus || []).includes(
            NO_DUE_DATE_ACTION_ITEMS
        );
        const hasOverdueFilter = (filterByStatus || []).includes(
            OVERDUE_ACTION_ITEMS
        );
        const hasUpcomingFilter = (filterByStatus || []).includes(
            UPCOMING_ACTION_ITEMS
        );

        const possibleActionItemsForDashboard =
            filteredBySearchTermActionItems.filter((ai) => {
                return [
                    !hasNoDueDateFilter &&
                        !hasOverdueFilter &&
                        !hasUpcomingFilter,
                    hasNoDueDateFilter && !ai?.dueDate,
                    hasOverdueFilter &&
                        ai.dueDate && // cater for dueDate being reset to ''
                        ai.dueDate?.valueOf() < Date.now(),
                    hasUpcomingFilter &&
                        ai.dueDate && // cater for dueDate being reset to ''
                        ai.dueDate?.valueOf() > Date.now(),
                ].some(Boolean);
            });

        const actionItemList = possibleActionItemsForDashboard
            .map((ai) => ({ ...ai, sortOrder: ai.dueDate ? 0 : 1 }))
            .sort(sortDashboardActionItems);

        return {
            group,
            category,
            items: actionItemList,
            assigneeFilterIsActive,
            hasAnyOpenOrRecentlyClosedActionItems,
            groupHasAnyOpenOrRecentlyClosedActionItems,
        };
    }
);

export const getActionItemsForReviewSelector = createSelector(
    getOccurrenceSelector,
    getOccurrencesForMeetingSelector,
    getActionItemsForMeetingSelector,
    (occurrence, occurrences, actionItems) => {
        const meetingId = occurrence.meeting;
        let actionItemsForReview = [];
        if (_get(occurrence, 'previousActionItemReview')) {
            const priorOccurrences = occurrences
                .filter(
                    (o) =>
                        !o.archived &&
                        o.startDate.valueOf() <
                            occurrence.startDate.valueOf() &&
                        o.previousActionItemReview
                )
                .sort((a, b) => b.startDate.valueOf() - a.startDate.valueOf());

            const allowedOccurrences = priorOccurrences.map((o) => o.id);

            const lastMeetingEndDate =
                allowedOccurrences.length &&
                priorOccurrences.some((occurrence) => occurrence.status)
                    ? priorOccurrences
                          .find((occurrence) => occurrence.status) // only look at occurrences that have a status as anything without a status was probably not held
                          .endDate.toDate()
                    : new Date('1901-01-01');

            actionItemsForReview = actionItems.filter(
                (ai) =>
                    (allowedOccurrences.includes(ai.occurrence) &&
                        (_isNil(ai.completedAt) ||
                            (ai.completedAt.valueOf() >
                                lastMeetingEndDate.valueOf() &&
                                ai.completedAt.valueOf() <
                                    occurrence.endDate.valueOf()))) ||
                    (ai.occurrence === occurrence.id &&
                        ai.source === ACTION_ITEM_CREATED_ACTION_ITEM_REVIEW) ||
                    _find(ai.links || [], { meeting: meetingId })
            );
        }

        return actionItemsForReview;
    }
);
