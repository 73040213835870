import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <circle
            cx="16.5"
            cy="11.5"
            r="3.5"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M16.5 2C10.701 2 6 6.59654 6 12.2667C6 17.9368 16.5 30 16.5 30C16.5 30 27 17.9368 27 12.2667C27 6.59654 22.299 2 16.5 2Z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
