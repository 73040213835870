import React from 'react';
import { NavLink } from 'react-router-dom';
import tw from 'twin.macro';
import {
    useIntegrationProfileByAuthSource,
    useRemoteCalendarToggleHideEvent,
    useEventFromRemote,
} from '~hooks';
import { Tooltip } from '~modules/common/components';
import { isRecurringMeeting } from '~modules/meeting/meeting.helpers';
import { ROUTES } from '~shared/navigation';
import HideIcon from '~icons/hide';

const Container = tw.div`
    md:(
        (invisible pointer-events-none)
        group-hocus:(visible pointer-events-auto)
    )
`;
const Button = tw.button`(inline-flex items-center) (text-icon hover:text-icon-dark) hover:active:text-solid-blue`;
const Label = tw.div`ml-1 (hidden sm:block) (text-body [.group-toggle-hide-event:hover &]:text-body-dark) [.group-toggle-hide-event:hover:active &]:text-solid-blue`;

const ToggleHideEvent = ({
    id,
    calendarType,
}: {
    id: string;
    calendarType: string;
}): JSX.Element | null => {
    const event = useEventFromRemote(id);
    const integration = useIntegrationProfileByAuthSource(calendarType);
    const remoteCalendarHideEvent = useRemoteCalendarToggleHideEvent(
        integration?.id,
        event
    );
    if (!event) return null;

    const buttonProps = {
        onClick: remoteCalendarHideEvent,
        className: 'group-toggle-hide-event',
    };

    const hideType = event.isHidden ? 'Unhide' : 'Hide';

    return (
        <Container>
            <Tooltip
                content={`${hideType} this event${
                    isRecurringMeeting(event.meeting)
                        ? ' and all repeating events in this series'
                        : ''
                }`}
                position="bottom right"
                offset={[0, 4]}
            >
                <Button {...buttonProps} type="button">
                    <HideIcon
                        tw="text-lg"
                        hasSlash={!event.isHidden}
                        isSymbolRef
                    />
                    <Label>{hideType}</Label>
                </Button>
            </Tooltip>
        </Container>
    );
};

const HideEventMessage = (): JSX.Element => (
    <>
        Find the event again using the “Hidden” filter on the calendar
        dashboard.
        <br />
        <NavLink to={ROUTES.hiddenEvents}>
            View all hidden events &rsaquo;
        </NavLink>
    </>
);

const UnhideEventMessage = (): JSX.Element => (
    <>
        Your event was moved back to the calendar dashboard.
        <br />
        <NavLink to={ROUTES.dashboardSchedule}>
            View calendar dashboard &rsaquo;
        </NavLink>
    </>
);

export { ToggleHideEvent as default, HideEventMessage, UnhideEventMessage };
