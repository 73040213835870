import {
    SEARCH_CANCEL,
    SEARCH_SUCCESS,
    SEARCH_REQUEST,
    SEARCH_ERROR,
    LOGOUT_SUCCESS,
} from '../../common/action.types.js';

const initialState = {
    visible: 0,
    results: {},
    loading: false,
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_REQUEST:
            return {
                results: {},
                term: action.term,
                loading: true,
                visible: true,
            };

        case SEARCH_SUCCESS:
            return {
                ...state,
                results: action.results,
                loading: false,
            };

        case SEARCH_ERROR:
            return {
                ...state,
                loading: false,
            };

        case SEARCH_CANCEL:
        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default searchReducer;
