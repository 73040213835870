import moment from 'moment-timezone';
import _last from 'lodash/last.js';
import _reject from 'lodash/reject.js';
import ObjectID from 'bson-objectid';
import {
    BULK_CREATE_OCCURRENCE_MINUTES_REQUEST,
    BULK_CREATE_OCCURRENCE_MINUTES_ERROR,
    BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS,
    EDIT_OCCURRENCE_MINUTES_REQUEST,
    EDIT_OCCURRENCE_MINUTES_SUCCESS,
    DELETE_OCCURRENCE_MINUTES_REQUEST,
    DELETE_OCCURRENCE_MINUTES_SUCCESS,
    CREATE_OCCURRENCE_MINUTES_REQUEST,
    CREATE_OCCURRENCE_MINUTES_ERROR,
    CREATE_OCCURRENCE_MINUTES_SUCCESS,
    TOGGLE_OCCURRENCE_MINUTES_STATUS_REQUEST,
    TOGGLE_OCCURRENCE_MINUTES_STATUS_SUCCESS,
    TOGGLE_OCCURRENCE_MINUTES_STATUS_ERROR,
    RENUMBER_OCCURRENCE_MINUTES_SUCCESS,
    MOVE_OCCURRENCE_MINUTES_REQUEST,
    MOVE_OCCURRENCE_MINUTES_ERROR,
    COPY_OCCURRENCE_MINUTES_REQUEST,
    COPY_OCCURRENCE_MINUTES_ERROR,
} from '~common/action.types';
import { onActionItemCreate } from '~modules/actionItems/actionItem.actions';
import { onAgendaItemCreateFromMinutesTopic } from '~modules/agenda/agenda.actions';
import { openModal as openSelectOccurrenceModal } from '~modules/modals/selectOccurrenceModal.actions';
import { getOccurrenceSlug, pushTo, replaceTo } from '~modules/navigation';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import {
    createMinuteItem,
    createMinuteItemsBulk,
    editMinuteItem,
    deleteMinuteItem,
    renumberMinuteItem,
    uncompleteMinuteItem,
    completeMinuteItem,
    copyMinuteItem,
    moveMinuteItem,
    applyTemplateToOccurrence,
} from '~client/modules/minutes/minutes.api';
import {
    MINUTE_ITEM_STATUS_COMPLETE,
    MINUTE_ITEM_STATUS_INCOMPLETE,
    AGENDA_CONTENT_SOURCE_BLANK,
    RTE_TINYMCE,
} from '~common/constants';
import {
    getMinutesSelector,
    getMinutesForOccurrenceSelector,
    calculateMinuteItemNbr,
    getMinuteItemFromStoreById,
} from '~modules/minutes/minutes.pure.selectors';
import { isPrimaryItemNbr, getItemNbrParts } from '~common/list.helpers';
import { getOccurrenceFromStoreById } from '~modules/occurrence/occurrence.selectors';
import {
    alertSuccess,
    alertError,
    alertPersistedErrorWithClose,
    removeAlert,
    removeAlertWithDispatch,
    alertSuccessWithDispatch,
} from '~modules/alert/alert.actions';
import { logDocumentToConsole } from '~modules/richtext/helpers';
import { pauseUpdates, resumeUpdates } from '~common/store';
import { getAgendaItemsForMeetingSelector } from '~modules/agenda/agenda.selectors';
import { displayError } from '~modules/alert/alert.helpers';
import { CustomNotificationToastButton } from '~modules/notification/NotificationToastButton';
import { dateFullShort } from '~common/time.utils';

function onMinuteItemBulkCreateRequest(occurrenceId, minuteItems) {
    return {
        type: BULK_CREATE_OCCURRENCE_MINUTES_REQUEST,
        occurrenceId,
        minuteItems,
    };
}

function onMinuteItemBulkCreateError(occurrenceId, error) {
    return {
        type: BULK_CREATE_OCCURRENCE_MINUTES_ERROR,
        occurrenceId,
        error,
    };
}

function onMinuteItemEditRequest(occurrenceId, minuteItem) {
    return {
        type: EDIT_OCCURRENCE_MINUTES_REQUEST,
        occurrenceId,
        minuteItem,
    };
}

export function onMinuteItemEditSuccess(occurrenceId, minuteItem) {
    return {
        type: EDIT_OCCURRENCE_MINUTES_SUCCESS,
        occurrenceId,
        minuteItem,
    };
}

function onMinuteItemCreateRequest(occurrenceId, minuteItem, minuteItems) {
    return {
        type: CREATE_OCCURRENCE_MINUTES_REQUEST,
        occurrenceId,
        minuteItem,
        minuteItems,
    };
}

function onMinuteItemCreateError(occurrenceId, minuteItem, minuteItems, error) {
    return {
        type: CREATE_OCCURRENCE_MINUTES_ERROR,
        occurrenceId,
        minuteItem,
        minuteItems,
        error,
    };
}

export function onMinuteItemCreateSuccess(occurrenceId, minuteItem) {
    return {
        type: CREATE_OCCURRENCE_MINUTES_SUCCESS,
        occurrenceId,
        minuteItem,
    };
}

function onMinuteItemDeleteRequest(occurrenceId, minuteItem, minuteItems) {
    return {
        type: DELETE_OCCURRENCE_MINUTES_REQUEST,
        occurrenceId,
        minuteItem,
        minuteItems,
    };
}

function onMinuteItemRenumberSuccess(occurrenceId, minuteItem) {
    return {
        type: RENUMBER_OCCURRENCE_MINUTES_SUCCESS,
        occurrenceId,
        minuteItem,
    };
}

function onToggleMinuteItemStatusRequest(occurrenceId, id, status) {
    return {
        type: TOGGLE_OCCURRENCE_MINUTES_STATUS_REQUEST,
        occurrenceId,
        id,
        status,
    };
}

function onToggleMinuteItemStatusSuccess(occurrenceId, minuteItem) {
    return {
        type: TOGGLE_OCCURRENCE_MINUTES_STATUS_SUCCESS,
        occurrenceId,
        minuteItem,
    };
}

function onToggleMinuteItemStatusError(occurrenceId, id, status, error) {
    return {
        type: TOGGLE_OCCURRENCE_MINUTES_STATUS_ERROR,
        occurrenceId,
        id,
        error,
        status,
    };
}

export function onMinuteItemBulkSave(occurrenceId, topics) {
    return async (dispatch) => {
        const topicsToSave = _reject(topics, (t) => !ObjectID.isValid(t.id));

        dispatch(onMinuteItemBulkCreateRequest(occurrenceId, topicsToSave));

        try {
            await createMinuteItemsBulk(occurrenceId, topicsToSave);
        } catch (error) {
            dispatch(onMinuteItemBulkCreateError(occurrenceId, error));
            dispatch(
                alertError({
                    id: `onMinuteItemBulkSave-${occurrenceId}`,
                    title: 'Minutes could not be saved',
                    error,
                })
            );
        }
    };
}

export const onApplyTemplateToOccurrence =
    (template, occurrence, { suppressSuccessToast = false } = {}) =>
    async (dispatch) => {
        pauseUpdates();
        try {
            const { occurrence: updatedOccurrence, minuteItems } =
                await applyTemplateToOccurrence(
                    occurrence.id,
                    template?.id // template only exists for a meeting (i.e. agenda template) if it's linked with a library template
                );
            dispatch({
                type: BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS,
                occurrence: updatedOccurrence,
                minuteItems,
            });
            !suppressSuccessToast &&
                dispatch(
                    alertSuccess({
                        id: `replaceMinuteItemsFromAgendaTemplate-${occurrence.id}-${template?.id}`,
                        title: 'Topics have been applied',
                    })
                );
        } catch (error) {
            dispatch(
                alertError({
                    id: `replaceMinuteItemsFromAgendaTemplate-${occurrence.id}-${template?.id}`,
                    title: 'Topics could not be applied',
                    error,
                })
            );
        }
        resumeUpdates();
    };

export function onMinuteItemRenumber(
    occurrenceId,
    id,
    targetItem,
    allTopics,
    topicsAreSavedToDB = true
) {
    return async (dispatch) => {
        if (topicsAreSavedToDB) {
            try {
                const minuteItem = await renumberMinuteItem(id, { targetItem });
                dispatch(onMinuteItemRenumberSuccess(occurrenceId, minuteItem));
            } catch (error) {
                dispatch(
                    alertError({
                        id: `onMinuteItemRenumber-${occurrenceId}`,
                        title: 'Topics could not be reordered',
                        error,
                    })
                );
            }
        } else {
            await dispatch(onMinuteItemBulkSave(occurrenceId, allTopics));
        }
    };
}

export function onToggleMinuteItemStatus(
    topic,
    allTopics,
    topicsAreSavedToDB = true
) {
    return async (dispatch) => {
        const { occurrence: occurrenceId, id, status } = topic;

        if (topicsAreSavedToDB) {
            try {
                dispatch(
                    removeAlert({
                        id: `onToggleMinuteItemStatus-${occurrenceId}`,
                    })
                );
                let minuteItem;
                if (status === MINUTE_ITEM_STATUS_COMPLETE) {
                    dispatch(
                        onToggleMinuteItemStatusRequest(
                            occurrenceId,
                            id,
                            MINUTE_ITEM_STATUS_INCOMPLETE
                        )
                    );
                    minuteItem = await uncompleteMinuteItem(id);
                } else {
                    dispatch(
                        onToggleMinuteItemStatusRequest(
                            occurrenceId,
                            id,
                            MINUTE_ITEM_STATUS_COMPLETE
                        )
                    );
                    minuteItem = await completeMinuteItem(id);
                }
                dispatch(
                    onToggleMinuteItemStatusSuccess(occurrenceId, minuteItem)
                );
            } catch (error) {
                dispatch(
                    onToggleMinuteItemStatusError(
                        occurrenceId,
                        id,
                        status,
                        error
                    )
                );
                dispatch(
                    alertError({
                        id: `onToggleMinuteItemStatus-${occurrenceId}`,
                        title: 'Topic status could not be updated',
                        error,
                    })
                );
            }
        } else {
            const newTopic = {
                ...topic,
                status: MINUTE_ITEM_STATUS_COMPLETE,
            };
            await dispatch(
                onMinuteItemBulkSave(
                    occurrenceId,
                    allTopics.map((t) =>
                        t.id === topic.id
                            ? {
                                  ...t,
                                  ...newTopic,
                              }
                            : t
                    )
                )
            );
        }
    };
}

export function onMinuteItemSave(
    occurrenceId,
    params,
    allTopics,
    topicsAreSavedToDB = true
) {
    return async (dispatch) => {
        if (topicsAreSavedToDB) {
            const minuteItemBeforeUpdate = getMinuteItemFromStoreById(
                params.id
            );

            logDocumentToConsole('MinuteItem before update', {
                minuteItemBeforeUpdate,
            });
            dispatch(
                onMinuteItemEditRequest(occurrenceId, {
                    ...params,
                    isLocalUpdate: true,
                })
            );

            try {
                dispatch(
                    removeAlert({
                        id: `onMinuteItemSave-${occurrenceId}`,
                    })
                );
                const minuteItem = await editMinuteItem(params.id, params);
                logDocumentToConsole('MinuteItem after update', {
                    minuteItemAfterUpdate: minuteItem,
                });

                // if before and after save are different, it means the sanitization process on the server stripped out some content (unsupported html).
                // in this case, we will update the user's editor content to make sure they see the latest
                const isLocalUpdate = params.content === minuteItem.content;
                dispatch(
                    onMinuteItemEditSuccess(occurrenceId, {
                        ...minuteItem,
                        isLocalUpdate,
                    })
                );
            } catch (error) {
                dispatch(
                    alertPersistedErrorWithClose({
                        id: `onMinuteItemSave-${occurrenceId}`,
                        title: 'Minutes could not be saved',
                        error,
                    })
                );
            }
        } else {
            await dispatch(
                onMinuteItemBulkSave(
                    occurrenceId,
                    allTopics.map((t) =>
                        t.id === params.id
                            ? {
                                  ...t,
                                  ...params,
                              }
                            : t
                    )
                )
            );
        }
    };
}

export function onMinuteItemDeleteSuccess(occurrenceId, minuteItem) {
    return (dispatch) => {
        dispatch({
            type: DELETE_OCCURRENCE_MINUTES_SUCCESS,
            occurrenceId,
            minuteItem,
        });
    };
}

export function onMinuteItemDelete(
    occurrenceId,
    minuteItem,
    pathToNavigate,
    reorderedMinuteItems,
    hasSubtopics,
    content,
    topicsAreSavedToDB = true
) {
    return async (dispatch) => {
        if (topicsAreSavedToDB) {
            const promise = async () => {
                dispatch(
                    onMinuteItemDeleteRequest(
                        occurrenceId,
                        minuteItem,
                        reorderedMinuteItems
                    )
                );

                try {
                    dispatch(
                        removeAlert({
                            id: `onMinuteItemDelete-${occurrenceId}`,
                        })
                    );

                    // Push user ahead of deletion for speedier feeling interface
                    pathToNavigate && replaceTo(pathToNavigate);

                    await deleteMinuteItem(minuteItem.id);

                    dispatch(
                        onMinuteItemDeleteSuccess(occurrenceId, minuteItem)
                    );
                } catch (error) {
                    dispatch(
                        alertError({
                            id: `onMinuteItemDelete-${occurrenceId}`,
                            title: 'Topic could not be deleted',
                            error,
                        })
                    );
                }
            };

            dispatch(
                openConfirm({
                    header: 'Delete topic',
                    content,
                    promise,
                })
            );
        } else {
            const topicsToRemain = _reject(
                reorderedMinuteItems,
                (t) => !ObjectID.isValid(t.id)
            );
            await dispatch(onMinuteItemBulkSave(occurrenceId, topicsToRemain));
        }
    };
}

export function onMinuteItemCreate(
    occurrenceId,
    newItem,
    allTopics,
    topicsAreSavedToDB = true
) {
    return async (dispatch, getState) => {
        if (topicsAreSavedToDB) {
            const topics = _reject(allTopics, (t) => !ObjectID.isValid(t.id));
            const topicIds = _reject(topics, newItem).map((m) => m.id);
            const prevTopics = getMinutesSelector(getState()).filter((m) =>
                topicIds.includes(m.id)
            );

            dispatch(onMinuteItemCreateRequest(occurrenceId, newItem, topics));

            // optimistically update the store, and return early so user can be redirected to new topic.
            dispatch(
                minuteItemCreateBackground(newItem, occurrenceId, prevTopics)
            );

            return newItem;
        } else {
            await dispatch(onMinuteItemBulkSave(occurrenceId, allTopics));

            return newItem;
        }
    };
}

function minuteItemCreateBackground(newItem, occurrenceId, prevTopics) {
    return async (dispatch) => {
        try {
            const minuteItem = await createMinuteItem(newItem);
            dispatch(onMinuteItemCreateSuccess(occurrenceId, minuteItem));
        } catch (error) {
            dispatch(
                onMinuteItemCreateError(
                    occurrenceId,
                    newItem,
                    prevTopics,
                    error
                )
            );
            dispatch(
                alertError({
                    id: `onMinuteItemCreate-${occurrenceId}`,
                    title: 'Topic could not be added',
                    error,
                })
            );
        }
    };
}

export function onMinuteItemCreateFromActionItem(
    occurrenceId,
    actionItem,
    allTopics,
    topicsAreSavedToDB = true
) {
    // The calling function requires a promise, so wrap this in async because the return here is in fact a promise.
    // eslint-disable-next-line require-await
    return async (dispatch, getState) => {
        const state = getState();

        const topicList = _reject(
            allTopics ||
                getMinutesForOccurrenceSelector(state, {
                    occurrence: occurrenceId,
                }),
            (t) => !ObjectID.isValid(t.id)
        );

        const lastTopic = _last(topicList) || {};
        const index = topicList.length - 1;

        const { id, itemNbr } = lastTopic;
        const above = false,
            toplevel = true;
        let prevItemNbr;

        if (index > 0 && topicList[index].itemNbr > 0) {
            prevItemNbr = topicList[index - 1].itemNbr;
        }

        const newItemNbr = calculateMinuteItemNbr({
            itemNbr,
            above,
            prevItemNbr,
            toplevel,
        });

        const newItem = {
            id: String(new ObjectID()),
            itemNbr: newItemNbr,
            itemTitle: actionItem.title,
            occurrence: occurrenceId,
            meeting: actionItem.meeting,
            assignee: state.user.id,
            documents: [],
            actionItem: actionItem.id,
            targetItem: {
                id,
                above,
                toplevel,
            },
            rte: RTE_TINYMCE,
        };

        return dispatch(
            onMinuteItemCreate(
                occurrenceId,
                newItem,
                [...topicList, newItem],
                topicsAreSavedToDB
            )
        );
    };
}

export function onMinuteItemAddDocument(
    item,
    docs,
    allTopics,
    topicsAreSavedToDB = true
) {
    return async (dispatch) => {
        const ids = docs.map((d) => d.id);

        const params = {
            ...item,
            documents: [...item.documents, ...ids],
        };

        if (topicsAreSavedToDB) {
            try {
                dispatch(
                    onMinuteItemEditRequest(item.occurrence, {
                        ...params,
                        isLocalUpdate: true,
                    })
                );

                const minuteItem = await editMinuteItem(params.id, params);
                dispatch(
                    onMinuteItemEditSuccess(item.occurrence, {
                        ...minuteItem,
                        isLocalUpdate: true,
                    })
                );
            } catch (error) {
                alertError({
                    id: `onMinuteItemAddDocument-${item.occurrence}`,
                    title: 'Document could not be linked with topic',
                    error,
                });
            }
        } else {
            await dispatch(
                onMinuteItemBulkSave(
                    item.occurrence,
                    allTopics.map((t) =>
                        t.id === item.id
                            ? {
                                  ...t,
                                  ...params,
                              }
                            : t
                    )
                )
            );
        }
    };
}

export function onMinuteItemAddAction(
    params,
    allTopics,
    topicsAreSavedToDB = true
) {
    return async (dispatch) => {
        if (!topicsAreSavedToDB) {
            await dispatch(onMinuteItemBulkSave(params.occurrence, allTopics));
        }
        return dispatch(onActionItemCreate(params));
    };
}

export function onMakeAgendaItem(minuteItem) {
    return async (dispatch, getState) => {
        const state = getState();
        const minuteItems = getMinutesForOccurrenceSelector(state, {
            occurrence: minuteItem.occurrence,
        });
        const agendaItemsForMeeting = getAgendaItemsForMeetingSelector(state, {
            meeting: minuteItem.meeting,
        });

        // if minuteItem is an item, just add to the end of the template.
        // if minuteItem.itemNbr is a sub-item, find its immediate parent and see if that is on the agenda template. if it is, insert at the end of that list.

        let newItemNbr = '1';

        // if there are existing agendaItems, find out what itemNbr to allocate to the new item
        if (agendaItemsForMeeting.length) {
            let itemNbr, above, toplevel;

            // if it's a primary item being added to the agenda, it goes at the end
            if (!isPrimaryItemNbr(minuteItem.itemNbr)) {
                // if a subitem...
                //  if the parent primary item is linked with an agendaItem, then put this item below it in the agenda template
                //  otherwise add as a primary item to the end
                const [primaryPart] = getItemNbrParts(minuteItem.itemNbr);
                const parentMinuteItem = minuteItems.find(
                    (minuteItem) => minuteItem.itemNbr == primaryPart
                ); // use == as primaryPart is number, itemNbr is string
                if (parentMinuteItem.agendaItem) {
                    const parentAgendaItem = agendaItemsForMeeting.find(
                        (agendaItem) =>
                            agendaItem.id === parentMinuteItem.agendaItem
                    );
                    const [parentAgendaItemPrimaryPart] = getItemNbrParts(
                        parentAgendaItem.itemNbr
                    );
                    const agendaItemTopicsForThisTopic =
                        agendaItemsForMeeting.filter((agendaItem) => {
                            const [agendaItemPrimaryPart] = getItemNbrParts(
                                agendaItem.itemNbr
                            );
                            return (
                                agendaItemPrimaryPart ===
                                parentAgendaItemPrimaryPart
                            );
                        });

                    if (agendaItemTopicsForThisTopic.length > 0) {
                        ({ itemNbr } = _last(agendaItemTopicsForThisTopic));
                        above = false;
                        toplevel = false;
                    }
                }
            }
            if (!itemNbr) {
                ({ itemNbr } = _last(agendaItemsForMeeting));
                above = false;
                toplevel = true;
            }

            newItemNbr = calculateMinuteItemNbr({
                itemNbr,
                above,
                prevItemNbr: itemNbr,
                toplevel,
            });
        }
        const id = String(new ObjectID());

        const newItem = {
            id,
            itemNbr: newItemNbr,
            itemTitle: minuteItem.itemTitle,
            meeting: minuteItem.meeting,
            assignee: minuteItem.assignee,
            itemAllocatedTime: minuteItem.itemAllocatedTime,
            contentSource: AGENDA_CONTENT_SOURCE_BLANK,
            rte: minuteItem.rte,
        };

        try {
            await dispatch(
                onMinuteItemSave(minuteItem.occurrence, {
                    ...minuteItem,
                    agendaItem: id,
                })
            );
            await dispatch(
                onAgendaItemCreateFromMinutesTopic(
                    minuteItem,
                    newItem,
                    agendaItemsForMeeting
                )
            );
        } catch {
            dispatch(
                onMinuteItemEditSuccess(minuteItem.occurrence, {
                    ...minuteItem,
                    agendaItem: undefined,
                })
            );
        }
    };
}

export const onMinuteItemMoveViaDrop = async (
    minuteItemId,
    targetOccurrenceId,
    { to }
) => {
    try {
        // TODO optimistic move?
        await moveMinuteItem(minuteItemId, {
            occurrenceId: targetOccurrenceId,
        });

        alertSuccessWithDispatch(
            {
                id: `onMinuteItemMoveViaDrop-${minuteItemId}-${targetOccurrenceId}`,
                title: `Topic has been moved to ${to || 'the other meeting'}`,
                message: (
                    <CustomNotificationToastButton
                        label="View topic"
                        handleClick={() => {
                            pushTo(
                                getOccurrenceSlug(
                                    { id: targetOccurrenceId },
                                    {
                                        activeMinuteItem: minuteItemId,
                                    }
                                )
                            );

                            removeAlertWithDispatch({
                                id: `onMinuteItemMoveViaDrop-${minuteItemId}-${targetOccurrenceId}`,
                            });
                        }}
                    />
                ),
            },
            {
                closeOnToastrClick: false,
                timeOut: 10000,
            }
        );
    } catch (error) {
        displayError({
            id: `onMinuteItemMoveViaDrop-${minuteItemId}-${targetOccurrenceId}`,
            title: `Topic could not be moved to ${to || 'the other meeting'}`,
            error,
        });
    }
};

export function onMinuteItemMove(minuteItem, instructionMessageText) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    return async (dispatch, getState) => {
        const promise = ({ meeting, occurrence }) => {
            dispatch({
                type: MOVE_OCCURRENCE_MINUTES_REQUEST,
                minuteItem,
                meeting,
                occurrence,
            });

            const toOccurrence = getOccurrenceFromStoreById(
                getState(),
                occurrence
            );

            const { startDate, title } = toOccurrence;

            return moveMinuteItem(minuteItem.id, {
                occurrenceId: occurrence,
            })
                .then(() =>
                    alertSuccessWithDispatch(
                        {
                            id: `onMinuteItemMove-${minuteItem.id}`,
                            title: `Topic has been moved to ${
                                title || 'the other meeting'
                            } on ${dateFullShort(startDate)}`,
                            message: (
                                <CustomNotificationToastButton
                                    label="View topic"
                                    handleClick={() => {
                                        pushTo(
                                            getOccurrenceSlug(
                                                { id: occurrence },
                                                {
                                                    activeMinuteItem:
                                                        minuteItem.id,
                                                }
                                            )
                                        );
                                        removeAlertWithDispatch({
                                            id: `onMinuteItemMove-${minuteItem.id}`,
                                        });
                                    }}
                                />
                            ),
                        },
                        {
                            closeOnToastrClick: false,
                            timeOut: 10000,
                        }
                    )
                )

                .catch((error) => {
                    dispatch({
                        type: MOVE_OCCURRENCE_MINUTES_ERROR,
                        minuteItem,
                        meeting,
                        occurrence,
                        error,
                    });
                });
        };

        const occurrence = getOccurrenceFromStoreById(
            getState(),
            minuteItem.occurrence
        );

        dispatch(
            openSelectOccurrenceModal({
                header: 'Move topic',
                submitButtonLabel: 'Move',
                promise,
                meeting: minuteItem.meeting,
                excludeOccurrences: [minuteItem.occurrence],
                fromDate: moment
                    .min(
                        moment.tz(getState().user.timezone),
                        occurrence.startDate.clone()
                    )
                    .add(-1.5, 'weeks'),
                instructionMessageText,
            })
        );
    };
}

export function onMinuteItemCopy(minuteItem, instructionMessageText) {
    return (dispatch, getState) => {
        const promise = async ({ meeting, occurrence }) => {
            const { occurrence: fromOccurrence, meeting: fromMeeting } =
                minuteItem;

            dispatch({
                type: COPY_OCCURRENCE_MINUTES_REQUEST,
                minuteItem,
                meeting,
                occurrence,
            });

            pauseUpdates();
            try {
                const newMinuteItem = await copyMinuteItem(minuteItem.id, {
                    occurrenceId: occurrence,
                });
                dispatch(onMinuteItemCreateSuccess(occurrence, newMinuteItem));
                const {
                    occurrenceMeta: { title, startDate },
                } = newMinuteItem;

                resumeUpdates();

                alertSuccessWithDispatch(
                    {
                        id: `onMinuteItemCopy-${minuteItem.id}-${occurrence}`,
                        title: `Topic has been copied to ${
                            title || 'the other meeting'
                        } on ${dateFullShort(startDate)}`,
                        message: (
                            <CustomNotificationToastButton
                                label="View topic"
                                handleClick={() => {
                                    pushTo(
                                        getOccurrenceSlug(
                                            { id: occurrence },
                                            {
                                                activeMinuteItem:
                                                    newMinuteItem.id,
                                            }
                                        )
                                    );

                                    removeAlertWithDispatch({
                                        id: `onMinuteItemCopy-${minuteItem.id}-${occurrence}`,
                                    });
                                }}
                            />
                        ),
                    },
                    {
                        closeOnToastrClick: false,
                        timeOut: 10000,
                    }
                );
            } catch (error) {
                dispatch({
                    type: COPY_OCCURRENCE_MINUTES_ERROR,
                    minuteItem,
                    fromMeeting,
                    fromOccurrence,
                    error,
                });
                resumeUpdates();
            }
        };

        const occurrence = getOccurrenceFromStoreById(
            getState(),
            minuteItem.occurrence
        );

        dispatch(
            openSelectOccurrenceModal({
                header: 'Copy topic',
                submitButtonLabel: 'Copy',
                promise,
                meeting: minuteItem.meeting,
                excludeOccurrences: [minuteItem.occurrence],
                fromDate: moment
                    .min(
                        moment.tz(getState().user.timezone),
                        occurrence.startDate.clone()
                    )
                    .add(-1.5, 'weeks'),
                instructionMessageText,
            })
        );
    };
}

export const onMinuteItemCopyViaDrop = async (
    minuteItemId,
    targetOccurrenceId,
    { to }
) => {
    try {
        const newMinuteItem = await copyMinuteItem(minuteItemId, {
            occurrenceId: targetOccurrenceId,
        });
        // TODO optimistic move?

        alertSuccessWithDispatch(
            {
                id: `onMinuteItemCopyViaDrop-${newMinuteItem.id}-${targetOccurrenceId}`,
                title: `Topic has been copied to ${to || 'the other meeting'}`,
                message: (
                    <CustomNotificationToastButton
                        label="View topic"
                        handleClick={() => {
                            pushTo(
                                getOccurrenceSlug(
                                    { id: targetOccurrenceId },
                                    {
                                        activeMinuteItem: newMinuteItem.id,
                                    }
                                )
                            );

                            removeAlertWithDispatch({
                                id: `onMinuteItemCopyViaDrop-${newMinuteItem.id}-${targetOccurrenceId}`,
                            });
                        }}
                    />
                ),
            },
            {
                closeOnToastrClick: false,
                timeOut: 10000,
            }
        );
        return newMinuteItem;
    } catch (error) {
        displayError({
            id: `onMinuteItemCopyViaDrop-${minuteItemId}-${targetOccurrenceId}`,
            title: `Topic could not be copied to ${to || 'the other meeting'}`,
            error,
        });
    }
};
