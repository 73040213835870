import moment from 'moment-timezone';
import regexifyString from 'regexify-string';
import { createSelector } from 'reselect';
import {
    NOTIFICATION_TYPE_CONFIRM,
    NOTIFICATION_TYPE_MESSAGE,
    NOTIFICATION_STATUS_UNREAD,
    NOTIFICATION_TYPE_MESSAGEMODAL,
    PERMISSION_STATUS_PENDING,
    PERMISSION_TYPE_WORKSPACE,
} from '~common/constants';
import { sortByCreatedAt } from '~common/selector.helpers';
import { getNotificationTitle } from '~modules/notification/notification.helpers';

const unreadFilter = (m) => m.status === NOTIFICATION_STATUS_UNREAD;

export const notificationsSelector = (state) =>
    state.notifications.notifications;

/**
 * Transform the notification.meta.title by substituting any string ':parameter'
 * with a component.
 * If it is a date, display it as full date time.
 * @param {Object} notification notification from the redux store.
 * @returns {Object} notification with updated notification.meta.title.
 */
const substituteTitleParams = (notification) => {
    const title = notification?.meta?.title;
    if (!title) return notification;

    const result = regexifyString({
        pattern: /:(?!:)\S+/g,
        decorator: (match, index) => {
            const placeholder = match.slice(1); // Remove the ':' prefix
            const value = notification.meta[placeholder];
            if (!value) return;

            return getNotificationTitle({
                placeholder,
                notification,
                value,
                index,
            });
        },
        input: title,
    });

    return { ...notification, meta: { ...notification.meta, title: result } };
};

export const getNotifications = createSelector(
    notificationsSelector,
    (notifications) => {
        // get notification actions unread or created in the past 14 days
        const actions = notifications
            .filter(
                (notification) =>
                    notification.type === NOTIFICATION_TYPE_CONFIRM &&
                    (notification.status === NOTIFICATION_STATUS_UNREAD ||
                        notification.createdAt.diff(moment(), 'days', true) >
                            -14)
            )
            .map(substituteTitleParams)
            .sort(sortByCreatedAt);
        const messages = notifications
            .filter(
                (notification) =>
                    notification.type === NOTIFICATION_TYPE_MESSAGE &&
                    (notification.status === NOTIFICATION_STATUS_UNREAD ||
                        notification.createdAt.diff(moment(), 'days', true) >
                            -14)
            )
            .map(substituteTitleParams)
            .sort(sortByCreatedAt);
        const pendingActions = actions.filter(unreadFilter);
        const pendingMessages = messages.filter(unreadFilter);

        return { actions, pendingActions, messages, pendingMessages };
    }
);

export const getUnreadNotifications = createSelector(
    notificationsSelector,
    (notifications) =>
        notifications.filter(
            (notification) =>
                notification.status === NOTIFICATION_STATUS_UNREAD &&
                notification.type !== NOTIFICATION_TYPE_MESSAGEMODAL
        )
);

export const getPendingWorkspaceNotifications = createSelector(
    notificationsSelector,
    (notifications) =>
        notifications.filter(
            (notification) =>
                notification.type === NOTIFICATION_TYPE_CONFIRM &&
                notification.meta.permissionStatus ===
                    PERMISSION_STATUS_PENDING &&
                notification.meta.targetType === PERMISSION_TYPE_WORKSPACE &&
                !notification.outcome
        )
);
