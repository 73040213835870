import React from 'react';
import tw, { styled } from 'twin.macro';

const Container = tw.div`relative`;

const HighlightPath = styled.path`
    button[disabled] & {
        stroke: #aaa;
    }
`;

export default ({ highlightColor = '', ...rest }) => (
    <Container>
        <svg
            fill="none"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <HighlightPath
                d="m3 31h26"
                stroke={highlightColor}
                strokeLinejoin="round"
                strokeLinecap="round"
                vectorEffect="non-scaling-stroke"
                strokeWidth="2.25"
            />
            <path
                d="m20.16 17.02-3.375-8.745c-0.1-0.26-0.205-0.56-0.315-0.9-0.11-0.35-0.215-0.72-0.315-1.11-0.21 0.81-0.425 1.485-0.645 2.025l-3.375 8.73h8.025zm6.06 7.98h-2.25c-0.26 0-0.47-0.065-0.63-0.195s-0.28-0.295-0.36-0.495l-2.01-5.19h-9.645l-2.01 5.19c-0.06 0.18-0.175 0.34-0.345 0.48s-0.38 0.21-0.63 0.21h-2.25l8.595-21.495h2.94l8.595 21.495z"
                fill="currentColor"
                stroke="currentColor"
                vectorEffect="non-scaling-stroke"
                strokeWidth="0.4"
            />
        </svg>
    </Container>
);
