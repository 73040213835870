import React from 'react';

export default ({ iconThick = true, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m3 7h27"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={iconThick ? '2.85' : '1.5'}
        />
        <path
            d="m16 2h4c1.6 0 2 1.3333 2 2v3h5l-1.5 20c-0.5 2.5-1.5 3-3.5 3h-6m1-28h-4c-1.6 0-2 1.3333-2 2v3h-5l1.5 20c0.5 2.5 1.5 3 3.5 3h6"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2.85' : '1.5'}
        />
    </svg>
);
