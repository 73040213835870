import _omit from 'lodash/omit.js';
import {
    MODAL_WORKSPACE_TEMPLATE_CHOICE_OPEN,
    MODAL_WORKSPACE_TEMPLATE_CHOICE_CLOSE,
} from '~common/action.types';

const initialState = {
    visible: false,
};

const workspaceTemplateChoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_WORKSPACE_TEMPLATE_CHOICE_OPEN:
            return {
                ...state,
                ..._omit(action, 'type'),
                visible: true,
            };

        case MODAL_WORKSPACE_TEMPLATE_CHOICE_CLOSE:
            return initialState;

        default:
            return state;
    }
};

export default workspaceTemplateChoiceReducer;
