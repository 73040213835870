import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m7.6286 18.831c-1.9042-0.4636-5.6286-1.217-5.6286-1.217s2.4923-2.4919 3.6964-3.8248h5.6286c0.9801-2.6369 3.0373-7.1968 6.8887-9.823 4.1418-2.8242 11.341-3.4772 12.517-2.6079 0.7193 1.0904-0.0011 7.3877-2.2019 11.287-2.5714 4.5564-7.8077 7.3998-10.396 8.3372l0.083 5.3182c-1.3274 1.2026-3.8155 3.699-3.8155 3.699s-0.6325-3.8502-1.0321-5.8209m-5.7398-5.348 5.7398 5.348m-5.7398-5.348s-3.5616 7.3329-2.5281 8.3814 8.2679-3.0334 8.2679-3.0334"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="m26 9.5c0 1.933-1.567 3.5-3.5 3.5s-3.5-1.567-3.5-3.5 1.567-3.5 3.5-3.5 3.5 1.567 3.5 3.5z"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
