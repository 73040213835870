import React from 'react';
import tw from 'twin.macro';
import { Button } from '~modules/common/components';

const Footer = tw.div`flex items-center justify-between py-2 px-4 bg-solid text-body text-sm select-none border-t`;
const FooterButtonWrap = tw.div`flex items-center space-x-1 pl-2`;
const FooterButton = tw(Button)`py-1.5 px-3!`;

const AttendeeDropdownFooter = ({
    myAccessLevel,
    myMeetingAccessLevel,
    hasSeries,
    handleEditMeetingAttendeeClick,
    handleEditSeriesAttendeeClick,
    setIsOpen,
}) => {
    if (!myAccessLevel?.isAdmin && !myMeetingAccessLevel?.isAdmin) return null;

    return (
        <Footer>
            <div tw="whitespace-nowrap">Edit users for</div>
            <FooterButtonWrap>
                <FooterButton
                    isSecondary
                    isSmall
                    onClick={() => {
                        handleEditMeetingAttendeeClick();
                        setIsOpen(false);
                    }}
                    isDisabled={!myAccessLevel?.isAdmin}
                >
                    Meeting
                </FooterButton>
                {hasSeries && (
                    <FooterButton
                        isSecondary
                        isSmall
                        onClick={() => {
                            handleEditSeriesAttendeeClick();
                            setIsOpen(false);
                        }}
                        isDisabled={!myMeetingAccessLevel?.isAdmin}
                    >
                        Series
                    </FooterButton>
                )}
            </FooterButtonWrap>
        </Footer>
    );
};

export default AttendeeDropdownFooter;
