import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M28.4468 6H3.55319C2.1431 6 1 7.15539 1 8.58065V23.4194C1 24.8446 2.1431 26 3.55319 26H28.4468C29.8569 26 31 24.8446 31 23.4194V8.58065C31 7.15539 29.8569 6 28.4468 6Z"
            fill="white"
            stroke="black"
            strokeOpacity="0.2"
            strokeWidth="0.5"
        />
        <path
            d="M4.23973 13.3091C3.57189 12.9388 2.8097 12.6411 1.95752 12.4344L1.99326 12.2735H5.48728C5.96088 12.2903 6.34517 12.4343 6.47022 12.943L7.22956 16.6022L7.46218 17.7043L9.58897 12.2735H11.8853L8.47184 20.2292H6.17535L4.23973 13.3091ZM13.5745 20.2376H11.4028L12.7612 12.2735H14.9327L13.5745 20.2376ZM21.447 12.4682L21.1517 14.188L20.9553 14.1034C20.5623 13.9424 20.0436 13.7815 19.3376 13.7985C18.4802 13.7985 18.0956 14.1456 18.0867 14.4846C18.0867 14.8574 18.5519 15.1031 19.3115 15.4675C20.5627 16.0352 21.143 16.7299 21.1343 17.6365C21.1168 19.2887 19.6333 20.3563 17.3546 20.3563C16.3803 20.3478 15.4419 20.1525 14.9326 19.9325L15.2365 18.1447L15.5225 18.2719C16.2285 18.5688 16.693 18.6956 17.5601 18.6956C18.1853 18.6956 18.8557 18.4496 18.8642 17.9161C18.8642 17.5687 18.5786 17.3143 17.7384 16.9246C16.9164 16.5432 15.8173 15.9078 15.8351 14.764C15.8444 13.2136 17.3546 12.1292 19.4992 12.1292C20.3393 12.1292 21.0187 12.3071 21.447 12.4682ZM24.3334 17.4162H26.1385C26.0492 17.018 25.6379 15.1117 25.6379 15.1117L25.4861 14.4255C25.3789 14.722 25.1914 15.2049 25.2004 15.1879C25.2004 15.1879 24.5122 16.9587 24.3334 17.4162ZM27.014 12.2735L28.766 20.2375H26.7553C26.7553 20.2375 26.5585 19.3225 26.4961 19.0429H23.7079C23.6273 19.2546 23.2521 20.2375 23.2521 20.2375H20.9734L24.1992 12.9343C24.4227 12.4174 24.8162 12.2735 25.3342 12.2735H27.014Z"
            fill="#171E6C"
        />
    </svg>
);
