import { post, get } from '../../api/helpers.js';

export function disconnectIntegration(id) {
    return post(`/api/integration/${id}/disconnect`);
}

export function getTaskLists({ id, authSource }) {
    return get(`/api/integration/${id}/${authSource}/tasklists`);
}

export function getCalendars({ id }) {
    return get(`/api/integration/${id}/calendars`);
}

export function getLinkCount({ id }) {
    return get(`/api/integration/${id}/link-count`);
}

export function saveIntegrationProfile(id, params) {
    return post(`/api/integration/${id}`, params);
}

export function saveIntegrationLink(id, workspaceId) {
    return post(`/api/integration/${id}/crm/link/${workspaceId}`);
}

export function getCRMIntegrationLinks() {
    return get(`/api/integration/crm/links`);
}

export function toggleHideRemoteSeries(id, params) {
    return post(`/api/integration/${id}/calendar/hide`, params);
}

export const getToken = (profile) =>
    get(`/api/integration/${profile.id}/token`);
