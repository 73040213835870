import _find from 'lodash/find.js';
import { createSelector } from 'reselect';
import { getMeetingIdSelector } from '~common/selector.helpers';
import { getWorkspaceAccessLevel } from '~modules/permission/permission.helpers';
import { sortByItemNbr, isPrimaryItemNbr } from '~common/list.helpers';
import { isPrivateTemplate } from '~modules/template/template.helpers';

const emptyObject = {};

export const agendaItemsSelector = (state) => state.agendaItems.agendaItems;

export const getAgendaItemsForMeetingSelector = createSelector(
    (state) => state.agendaItems.agendaItems,
    getMeetingIdSelector,
    (agendaItems, id) =>
        agendaItems
            .filter((o) => o.meeting === id)
            .sort(sortByItemNbr)
            .map((agendaItem) => {
                return {
                    ...agendaItem,
                    moveToSubtopic: !isPrimaryItemNbr(agendaItem.itemNbr),
                };
            })
);

export const getAgendaItemsForTemplateSelector = createSelector(
    (state) => state.agendaItems.agendaItems,
    (_, { template }) => template || emptyObject,
    getMeetingIdSelector,
    (agendaItems, template, meetingId) => {
        const myAccessLevel = getWorkspaceAccessLevel(template?.workspaceId);
        const canUpdate =
            myAccessLevel?.isOwner ||
            myAccessLevel?.isAdmin ||
            isPrivateTemplate(template);

        return agendaItems
            .filter((ai) =>
                meetingId
                    ? ai.meeting === meetingId
                    : canUpdate
                    ? ai.templateId === template?.id &&
                      ai.isDraft === template?.isEditing
                    : ai.templateId === template?.id && ai.isDraft === false
            )
            .sort(sortByItemNbr)
            .map((agendaItem) => {
                return {
                    ...agendaItem,
                    moveToSubtopic: !isPrimaryItemNbr(agendaItem.itemNbr),
                };
            });
    }
);

export const getAgendaItemsListStateSelector = createSelector(
    getAgendaItemsForTemplateSelector,
    (_, props) => props.topicId,
    (agendaItems, id) => {
        const agendaItem = _find(agendaItems, { id });

        return {
            agendaItem,
        };
    }
);
