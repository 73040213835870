import { post } from '~client/api/helpers';
import type {
    MeetingTemplateRequest,
    MeetingTemplateResponse,
} from '~modules/meetingTemplate/meetingTemplate.types';
import { meetingModel } from '../meeting/meeting.mappers.js';

export const addMeetingByTemplate = (
    templateId: string,
    params?: MeetingTemplateRequest
): Promise<MeetingTemplateResponse> => {
    const url = `/api/meeting-template/${templateId}`;
    return post(url, params).then(meetingModel);
};
