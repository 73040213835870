import { getStore } from '~common/store';
import type { State, Occurrence, Meeting } from '~client/types';

export const occurrencesSelector = (state: State): Occurrence[] =>
    state.occurrences.occurrences;

const getIntroMeetingOccurrenceFromStore = (): Occurrence | undefined => {
    const state = getStore().getState();
    const { meetings } = state.meetings;
    const { occurrences } = state.occurrences;

    const existingIntroMeeting = meetings.find((m: Meeting) => m.isIntro);
    if (!existingIntroMeeting) return;

    // Get all the occurrences that belong to the intro meeting
    const introOccurrences = occurrences.filter(
        (o: Occurrence) => o.meeting === existingIntroMeeting.id
    );
    if (introOccurrences.length === 0) return;

    // Get the first occurrence created
    const mainIntroOccurrence = introOccurrences.sort(
        (a: Occurrence, b: Occurrence) =>
            Date.parse(a.createdAt) - Date.parse(b.createdAt)
    )[0];

    return mainIntroOccurrence;
};

export { getIntroMeetingOccurrenceFromStore };

export {
    getOccurrenceSelector,
    getNextOccurrenceFromStoreByDate,
    getOccurrencesFromStoreByMeeting,
    getNextScheduledOccurrenceFromStoreByDate,
} from '~modules/occurrence/occurrence.pure.selectors';

export {
    makeGetOccurrencesSelector,
    getOccurrencesSelector,
    getOccurrenceAttendees,
    getOccurrenceFromStoreById,
    makeGetOccurrenceSelector,
    getNonModifiedOccurrencesFromStoreByMeeting,
    getPreviousOccurrenceFromStoreByDate,
    mapOccurrenceForListDisplay,
    getOccurrenceSidebarList,
    clearCacheOccurrenceAddEditFormInitialValues,
    getOccurrenceAddEditFormInitialValues,
    getAttendeesAndPermissionsSelector,
    getOccurrencesForQuickSwitcherFromStore,
} from './occurrence.selectors.legacy.js';
