import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M28 14.8214L18.1786 5L10.5893 12.5893L20.4107 22.4107L28 14.8214Z"
            fill="currentColor"
        />
        <path
            d="m10.589 12.589 7.5893-7.5893 9.8214 9.8214-7.5893 7.5893m-9.8214-9.8214-7.5893 7.5893 7 7h5.6429m-5.0536-14.589 9.8214 9.8214m0 0-4.7678 4.7679m9.8571 0h-9.8571"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            strokeWidth="1.5"
        />
    </svg>
);
