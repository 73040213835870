import React from 'react';
import _get from 'lodash/get.js';
import _isEqual from 'lodash/isEqual.js';
import { diff } from 'deep-object-diff';
import { PAGINATION_DEFAULT_PAGE_SIZE } from './constants.js';

export {
    sortByField,
    sortByStringField,
    sortByDateField,
    sortByDateFieldDesc,
    sortByFieldDesc,
    sortWith,
} from '~shared/utils';

/**
 * @deprecated Legacy export, import from '~shared/utils/pluralize' from now on
 */
export { pluralize } from '~shared/utils/pluralize';

export function paginateData(
    array,
    page_size = PAGINATION_DEFAULT_PAGE_SIZE,
    page_number
) {
    --page_number; // because pages logically start with 1, but technically with 0
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
}

export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export function assetUrl(src) {
    return new URL(
        `${window.__minuteme_base.replace(/\/$/, '')}/./assets/${src}`,
        document.location
    ).href;
}

export const locationOrigin = (location = window.location) => {
    const url = new URL(location);
    return url.origin;
};

export const delayedValueResolver = (
    fn,
    { maxTimeout = 5000, intervalTimeout = 200 } = {}
) => {
    let interval;

    return new Promise((resolve, reject) => {
        const startTime = new Date();

        interval = window.setInterval(() => {
            const val = fn();
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line unicorn/prefer-date-now
            const diff = new Date() - startTime;

            if (val) {
                resolve(val);
                interval && window.clearInterval(interval);
                return;
            }

            if (!val && diff >= maxTimeout) {
                reject(new Error(`maxTimeout ${maxTimeout} exceeded`));
                interval && window.clearInterval(interval);
                return;
            }
        }, intervalTimeout);
    });
};

export function asArray(stringOrArray) {
    // if object passed in is a string, return it as a single item inside an array.
    // if object passed in is an array, return it as-is.
    // otherwise (e.g. undefined, null), return as empty array.

    // NOTE: this differs from _.castArray in how it treats undefined/null and empty string ''.
    //       lodash will create it as [undefined] or [null] or ['']
    //       but this will return them as []

    return Array.isArray(stringOrArray)
        ? stringOrArray
        : typeof stringOrArray === 'string' && stringOrArray.length > 0
        ? [stringOrArray]
        : [];
}

export const isPromise = (fn) =>
    typeof fn == 'object' && typeof fn.then == 'function';

export function whyDidComponentUpdate(nextProps, nextState) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-regexp-test
    if (!_get(window, '__minuteme_appConfig.ENVIRONMENT', '').match(/-local$/))
        return;

    const {
        props,
        constructor: { displayName },
        state,
    } = this;
    console.log(`  Differences for ${displayName}`); //eslint-disable-line no-console
    console.log('    props deep difference: ', diff(props, nextProps)); //eslint-disable-line no-console
    console.log('    state deep difference: ', diff(state, nextState)); //eslint-disable-line no-console

    const shallowDiffs = getShallowComponentDiffs(props, nextProps);
    console.log('    shallow equality differences: ', shallowDiffs); //eslint-disable-line no-console
}

/**
 * Return an object indicating which props have changed in a shallow-equal
 * @param {Object} props React component props from last render
 * @param {Object} nextProps React component props from next render
 */
export function getShallowComponentDiffs(props, nextProps) {
    return Object.keys(nextProps)
        .map((key) => ({ key, val: nextProps[key] === props[key] }))
        .filter((o) => !o.val);
}

/**
 * returns True if the only prop changed is the named prop requested,
 * and the only change is a reference change and not a value change.
 * Or... if there are no props changed.
 * @param {String} propName name of prop to check for changes
 * @param {Object} props React component props from last render
 * @param {Object} nextProps React component props from next render
 * @return {Boolean}
 */
export function hasOnlyNamedPropChanged(propName, props, nextProps) {
    const shallowDiffs = getShallowComponentDiffs(props, nextProps);

    if (shallowDiffs.length === 0) {
        return true;
    }

    if (!(shallowDiffs.length === 1 && shallowDiffs[0].key === propName)) {
        return false;
    }

    if (_isEqual(props[propName], nextProps[propName])) {
        return true;
    }

    return false;
}

export const getBrowserVisibilityProp = () => {
    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        return 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        return 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitvisibilitychange';
    }
};
const getBrowserDocumentHiddenProp = () => {
    if (typeof document.hidden !== 'undefined') {
        return 'hidden';
    } else if (typeof document.msHidden !== 'undefined') {
        return 'msHidden';
    } else if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitHidden';
    }
};
export const getIsDocumentVisible = () => {
    if (typeof document.visibilityState !== 'undefined') {
        return document.visibilityState === 'visible';
    }
    !document[getBrowserDocumentHiddenProp()];
};

const insertBetween = (ele, array, isJsx) =>
    array
        .flatMap((x) => [ele, x])
        .slice(1)
        .flatMap((i, idx) =>
            isJsx ? <React.Fragment key={idx}>{i}</React.Fragment> : i
        );

export const conjunctize = (items, isJsx = false) =>
    items.length < 3
        ? insertBetween(' and ', items, isJsx)
        : insertBetween(
              ', and ',
              [insertBetween(', ', items.slice(0, -1), isJsx), items.slice(-1)],
              isJsx
          );

/**
 * Fixes and workarounds
 */

// This fix moves the callback to the next event cycle and can be used to avoid
// the error "Warning: Can't perform a React state update on an unmounted component."
export const reactSetStateWarningFix = (callback) =>
    setTimeout(() => callback());

export const reflect = (p) =>
    p.then(
        (v) => ({ v, status: 'resolved' }),
        (e) => ({ e, status: 'rejected' })
    );

export const localStorageSet = (key, value) => {
    window.localStorage.setItem(`minuteme-state-${key}`, JSON.stringify(value));
};

export const localStorageGet = (key, defaultIfNotFound = '{}') => {
    const valueAsText =
        window.localStorage.getItem(`minuteme-state-${key}`) ??
        defaultIfNotFound;
    let value;
    try {
        value = JSON.parse(valueAsText);
    } catch {
        // eslint-disable-next-line no-console
        console.error(`Could not parse local storage ${key}`, value);
        value = valueAsText;
    }
    return value;
};

export const localStorageRemove = (key) => {
    window.localStorage.removeItem(`minuteme-state-${key}`);
};

export const sessionStorageSet = (key, value) => {
    window.sessionStorage.setItem(
        `minuteme-state-${key}`,
        JSON.stringify(value)
    );
};

export const sessionStorageGet = (key) => {
    const valueAsText =
        window.sessionStorage.getItem(`minuteme-state-${key}`) ?? '{}';
    let value;
    try {
        value = JSON.parse(valueAsText);
    } catch {
        // eslint-disable-next-line no-console
        console.error(`Could not parse local storage ${key}`, valueAsText);
        value = valueAsText;
    }
    return value;
};

export const sessionStorageRemove = (key) => {
    window.sessionStorage.removeItem(`minuteme-state-${key}`);
};
