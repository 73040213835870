import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m6 23c-0.55228 0-1 0.4477-1 1s0.44772 1 1 1v-2zm14.091 2c0.5523 0 1-0.4477 1-1s-0.4477-1-1-1v2zm-14.091-13c-0.55228 0-1 0.4477-1 1s0.44772 1 1 1v-2zm14.091 2c0.5523 0 1-0.4477 1-1s-0.4477-1-1-1v2zm2.7273-2c-0.5523 0-1 0.4477-1 1s0.4477 1 1 1v-2zm3.1818 2c0.5523 0 1-0.4477 1-1s-0.4477-1-1-1v2zm-20 3.5c-0.55228 0-1 0.4477-1 1s0.44772 1 1 1v-2zm3.1818 2c0.55228 0 0.99998-0.4477 0.99998-1s-0.4477-1-0.99998-1v2zm2.7273-2c-0.5523 0-1 0.4477-1 1s0.4477 1 1 1v-2zm14.091 2c0.5523 0 1-0.4477 1-1s-0.4477-1-1-1v2zm-20-12.5c-0.55228 0-1 0.44772-1 1s0.44772 1 1 1v-2zm20 2c0.5523 0 1-0.44772 1-1s-0.4477-1-1-1v2zm-20 16h14.091v-2h-14.091v2zm16.818-11h3.1818v-2h-3.1818v2zm-16.818 5.5h3.1818v-2h-3.1818v2zm0-5.5h7.0455v-2h-7.0455v2zm7.0455 0h7.0454v-2h-7.0454v2zm-1.1364 5.5h7.0454v-2h-7.0454v2zm7.0454 0h7.0455v-2h-7.0455v2zm-12.954-10.5h20v-2h-20v2z"
            fill="currentColor"
        />
    </svg>
);
