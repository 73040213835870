import React from 'react';
import DocumentTitle from 'react-document-title';
import { Route, Switch } from 'react-router-dom';
import PreloadProgress from '~modules/preload/PreloadProgress';
import LoadingLogin from '~modules/authentication/components/Loader';
import Loadable from 'react-loadable';
import tw from 'twin.macro';
import GlobalStyles from '~styles/GlobalStyles';
import usePageViews from '~modules/pageView/PageViews';
import useModalNotifications from '~modules/notification/modalNotifications';
import HideAlerts from '~modules/alert/hideAlerts';

const LoginRoot = Loadable({
    loader: () => import('~modules/authentication/AuthenticationPages'),
    loading: LoadingLogin,
    delay: 300,
    timeout: 10000,
});

const LoggedInContainer = Loadable({
    loader: () => import('../container/LoggedInContainer'),
    loading: PreloadProgress,
    delay: 300,
    timeout: 10000,
});

const Container = tw.div`h-inherit antialiased`;

export default () => {
    // const { pathname } = useLocation();

    usePageViews();
    useModalNotifications();

    return (
        <>
            <DocumentTitle title="MinuteMe" />
            <GlobalStyles />
            <Container>
                <Switch>
                    {/* Remove trailing slash and trailing dot */}
                    {/* <Redirect
                        from="/:url*(/+|\.+)"
                        to={pathname.slice(0, -1)}
                    /> */}
                    <Route
                        path="/(login|signup|resetpassword|logout)"
                        component={LoginRoot}
                    />
                    <Route path="/" component={LoggedInContainer} />
                </Switch>
            </Container>
            <HideAlerts />
        </>
    );
};
