import {
    DOCUMENTPREVIEW_MODAL_OPEN,
    DOCUMENTPREVIEW_MODAL_UPDATE,
    DOCUMENTPREVIEW_MODAL_CANCEL,
    LOGOUT_SUCCESS,
    EDIT_GROUP_WEBSOCKET,
} from '~common/action.types';

const initialState = {
    isVisible: false,
    isLoading: false,
};

const documentPreviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case DOCUMENTPREVIEW_MODAL_OPEN:
            return {
                ...state,
                ...action,
                isVisible: true,
            };

        case DOCUMENTPREVIEW_MODAL_UPDATE:
            return {
                ...state,
                ...action,
            };
        case EDIT_GROUP_WEBSOCKET: {
            if (!state.isVisible) {
                return state;
            }
            return { ...state, groupUpdate: !state.groupUpdate };
        }
        case LOGOUT_SUCCESS:
        case DOCUMENTPREVIEW_MODAL_CANCEL:
            return initialState;

        default:
            return state;
    }
};

export default documentPreviewReducer;
