import _debounce from 'lodash/debounce.js';
import _throttle from 'lodash/throttle.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ObjectID from 'bson-objectid';
import { getActionItemsForMinutesTopicSelector } from '~modules/actionItems/actionItem.selectors';
import { ACTION_ITEM_CREATED_MINUTES_INLINE } from '~common/constants';
import {
    onActionItemCreate,
    onActionItemDelete,
    onActionItemUpdate,
    onActionItemUpdateTitle,
    activesyncInlineActionTitle,
} from '~modules/actionItems/actionItem.actions';
import { getMinuteItemFromStoreById } from '~modules/minutes/minutes.pure.selectors';
import { useUser } from '~hooks';

export const useActionItemsForMinuteItem = (minuteItem) => {
    const actionItems = useSelector((state) =>
        getActionItemsForMinutesTopicSelector(state, {
            ...minuteItem,
            topicId: minuteItem.id,
        })
    );
    return actionItems;
};

export const useCreateActionItem = () => {
    const dispatch = useDispatch();
    const user = useUser();
    return useCallback(
        (
            { id, minuteItem: minuteItemId, assignee, title },
            { sendToApi = true } = {}
        ) => {
            const minuteItem = getMinuteItemFromStoreById(minuteItemId);
            const { occurrence, meeting } = minuteItem;

            const allTopics = undefined;
            const topicsAreSavedToDB = true;
            const actionItem = dispatch(
                onActionItemCreate(
                    {
                        id: id || ObjectID().toString(),
                        meeting,
                        occurrence,
                        minuteItem: minuteItemId,
                        assignees: assignee.split(',').map((userId) => ({
                            userId,
                            assignedAt: new Date(),
                            assignedBy: user.id,
                        })),
                        dueDate: null,
                        title,
                        source: ACTION_ITEM_CREATED_MINUTES_INLINE,
                    },
                    allTopics,
                    topicsAreSavedToDB,
                    { sendToApi }
                )
            );

            return actionItem;
        },
        [dispatch, user.id]
    );
};

export const useDeleteActionItems = () => {
    const dispatch = useDispatch();
    return useCallback(
        (actionItems = []) =>
            actionItems.forEach((actionItem) =>
                dispatch(onActionItemDelete(actionItem, false))
            ),
        [dispatch]
    );
};

/**
 * This function will send an api call to update the action item, on a 1 second debounce.
 * It will also on a 1 second throttle update the action item title in the redux store.
 */
export const useUpdateActionItemDebounced = () => {
    const dispatch = useDispatch();
    const deleteActionItems = useDeleteActionItems();

    const updateTitleThrottled = useMemo(
        () =>
            _throttle(
                (actionItem) =>
                    dispatch(activesyncInlineActionTitle(actionItem)),
                1000,
                {
                    leading: false,
                }
            ),
        [dispatch]
    );

    const updateItemDebounced = useMemo(
        () =>
            _debounce((actionItem) => {
                updateTitleThrottled.cancel();
                dispatch(onActionItemUpdateTitle(actionItem));
            }, 1000),
        [dispatch, updateTitleThrottled]
    );

    useEffect(() => {
        updateItemDebounced.flush();
    }, [updateItemDebounced]);

    const updateActionItemDebounced = useCallback(
        ({ id, title }) => {
            const actionItem = { id, title }; // to be more verbose about which fields this function supports
            updateTitleThrottled(actionItem);
            updateItemDebounced(actionItem);
        },
        [updateItemDebounced, updateTitleThrottled]
    );

    const deleteActionItemsDebounced = useCallback(
        (actionItems = []) => {
            if (actionItems.length === 0) return;

            updateTitleThrottled.cancel();
            updateItemDebounced.flush();

            window.setTimeout(() => {
                // GOTCHA: deleting an action item will cause props to update in ActionItemSync.
                // This is an issue if you deleted a whole line, for example, and overwrites
                // the data in the debounced (pending) saveHandler with the old minuteItem.content.
                // Therefore we hold off the execution of the action item delete until after
                // the minute item debounce.
                deleteActionItems(actionItems);
            }, 550);
        },
        [updateTitleThrottled, updateItemDebounced, deleteActionItems]
    );

    return {
        updateActionItemDebounced,
        deleteActionItemsDebounced,
    };
};

/**
 * This function will send an api call to update the action item immediately.
 * It also does an optimistic update in the redux store
 */
export const useUpdateActionItem = () => {
    const dispatch = useDispatch();
    return useCallback(
        (actionItem) => dispatch(onActionItemUpdate(actionItem)),
        [dispatch]
    );
};
