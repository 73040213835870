import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m22.16 16.933c0.5679-1.0178 0.7028-1.678 0.98-2.8 0.7012-2.8384 1.09-4.7418 0-7.4666-0.3047-0.76163-0.4704-1.2119-0.98-1.8667-1.075-1.3812-3.22-2.8-6.16-2.8s-5.085 1.4188-6.16 2.8c-0.50959 0.65477-0.67535 1.105-0.98 1.8667-1.09 2.7249-0.70125 4.6282 0 7.4666 0.2772 1.122 0.41212 1.7822 0.98 2.8 0.7013 1.257 2.1077 1.4145 2.45 2.8 0.0679 0.275 0.0832 0.6173 0.0765 0.9333-0.0107 0.4994-0.5665 0.9333-0.5665 0.9333s-5.8801 2.3334-9.0919 4.3296c-0.36032 0.2239-0.70815 0.394-0.70814 0.8038v3.2667h14"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="M18 24.0625L21.6522 28L30 19"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
