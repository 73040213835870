import { useDispatch } from 'react-redux';
import { alertSuccess, removeAlert } from '~modules/alert/alert.actions';

const useShowSuccessToast = () => {
    const dispatch = useDispatch();
    return (data, options) => dispatch(alertSuccess(data, options));
};

const useRemoveToast = () => {
    const dispatch = useDispatch();
    return (id) => dispatch(removeAlert({ id }));
};

export { useShowSuccessToast, useRemoveToast };
