import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M28.5 6h-25A2.5 2.5 0 0 0 1 8.5v15A2.5 2.5 0 0 0 3.5 26h25a2.5 2.5 0 0 0 2.5-2.5v-15A2.5 2.5 0 0 0 28.5 6z"
            fill="#252525"
        />
        <path
            d="M12.25 22.25a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 1 0 0 12.5z"
            fill="#eb001b"
        />
        <path
            d="M19.75 22.25a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 1 0 0 12.5z"
            fill="#f79e1b"
        />
        <path
            fillRule="evenodd"
            d="M16 11A6.24 6.24 0 0 1 16 21 6.24 6.24 0 0 1 16 11z"
            fill="#ff5f00"
        />
    </svg>
);
