import React from 'react';
import { CustomPicker } from 'react-color';
import tw, { styled, css } from 'twin.macro';
import { SM_UP } from '~common/media.helpers';
import Icon from '~icons';

const Container = styled.div`
    ${tw`flex flex-wrap p-4`}
    /* Fixed width required based on 10 color palette */
    width: 193px;
    @media (${SM_UP}) {
        width: 233px;
    }
`;

const lightColors = ['#fbff00', '#1afe00', '#3dfdff', '#ffffff'];
const Swatch = styled.button`
    ${tw`w-6 h-6 sm:w-8 sm:h-8 m-1 rounded cursor-pointer flex items-center justify-center text-transparent`}
    ${({ isRound }) => isRound && tw`rounded-full`}
    ${({ backgroundColor }) =>
        backgroundColor === '#ffffff'
            ? `box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.15);`
            : `background-color: ${backgroundColor}; box-shadow: inset 0 0 0 0.05rem rgba(0, 0, 0, 0.02);`}
    ${({ isActive, backgroundColor }) =>
        isActive
            ? lightColors.includes(backgroundColor)
                ? `color: rgba(0,0,0,.45);`
                : tw`text-white`
            : tw`text-transparent`}
    &:hover,
    &:focus,
    &:active {
        ${tw`outline-none`}
        ${({ isRound }) =>
            isRound
                ? `transform: scale(1.08)`
                : css`
                      box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.17);
                  `}
    }
`;

const Button = tw.button`block bg-solid w-full border-t text-body hover:text-body-dark focus:text-body-dark py-2 text-sm sm:text-base rounded-none focus:outline-none`;

const ColorPicker = ({
    color,
    colors,
    isRound,
    onChange,
    hasClearButton,
    type,
}) => {
    const transparent = '#00000000';

    const handleClick = (color) => {
        onChange(color);
    };

    return (
        <>
            <Container>
                {colors.map((swatchColor, i) => (
                    <Swatch
                        backgroundColor={swatchColor.toLowerCase()}
                        key={i}
                        title={swatchColor}
                        onClick={() => handleClick(swatchColor)}
                        aria-label="Change color"
                        isActive={
                            color.toLowerCase() === swatchColor.toLowerCase()
                        }
                        {...{ isRound }}
                    >
                        {isRound && <Icon name="tick" iconThick />}
                    </Swatch>
                ))}
            </Container>
            {hasClearButton && (
                <Button type="button" onClick={() => handleClick(transparent)}>
                    {type === 'textColor'
                        ? 'Clear text color'
                        : 'Clear highlight color'}
                </Button>
            )}
        </>
    );
};

export default CustomPicker(ColorPicker);
