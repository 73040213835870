import _omit from 'lodash/omit.js';
import {
    MODAL_ADD_MEETING_SIMPLE_CLOSE,
    MODAL_ADD_MEETING_SIMPLE_OPEN,
} from '~common/action.types';
import type { OpenSimpleAddMeetingModal } from '~modules/forms/addMeeting/addMeeting.types';

const initialState = {
    visible: false,
    dateStart: undefined,
    categoryData: undefined,
    workspaceId: undefined,
    includeWithExistingMeetingId: undefined,
    createdSourceLocation: '',
};

const simpleMeetingModalReducer = (
    state = initialState,
    action: {
        type:
            | typeof MODAL_ADD_MEETING_SIMPLE_CLOSE
            | typeof MODAL_ADD_MEETING_SIMPLE_OPEN;
    }
): OpenSimpleAddMeetingModal => {
    switch (action.type) {
        case MODAL_ADD_MEETING_SIMPLE_OPEN:
            return { ...state, ..._omit(action, 'type'), visible: true };

        case MODAL_ADD_MEETING_SIMPLE_CLOSE:
            return { ...state, ...initialState };

        default:
            return state;
    }
};

export default simpleMeetingModalReducer;
