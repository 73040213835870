import {
    RESUME_REDUX_UPDATES,
    LOGOUT_SUCCESS,
} from '../common/action.types.js';

const initialState = {
    resumeTrigger: 0,
};

const pauseReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESUME_REDUX_UPDATES:
            return {
                ...state,
                resumeTrigger: state.resumeTrigger + 1,
            };

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default pauseReducer;
