import React from 'react';

const OUTLOOK_ICON_ID = 'shape-outlook-calendar';

const OutlookIconInner = ({
    iconThick = false,
}: {
    iconThick?: boolean;
}): JSX.Element => (
    <>
        <path
            d="m17.5 8.5v-6.5l-15.5 3.5v20l15.5 4v-7m0-14h12.5m-12.5 0v3.5m12.5-3.5v14h-12.5m12.5-14-8.5 6.5-4-3m0 10.5v-10.5"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <ellipse
            cx="9.5"
            cy="16"
            rx="3.5"
            ry="5"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </>
);

const OutlookIcon = ({
    iconThick = false,
    isSymbolRef = false,
    title = '',
    ...rest
}): JSX.Element => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        {isSymbolRef ? (
            <use
                href={`#${OUTLOOK_ICON_ID}`}
                xlinkHref={`#${OUTLOOK_ICON_ID}`}
            />
        ) : (
            <OutlookIconInner iconThick={iconThick} />
        )}
    </svg>
);

export { OutlookIcon as default, OutlookIconInner, OUTLOOK_ICON_ID };
