function isPrimaryItemNbr(n) {
    return Number.isInteger(Number(n));
}

function newItemNbr(prevItemNbr, isPrimaryItem, mutator = 1) {
    if (isPrimaryItem) {
        prevItemNbr = Number(
            Number.parseInt(prevItemNbr) + mutator
        ).toLocaleString();
    } else {
        const [first, second] = getItemNbrParts(prevItemNbr);
        prevItemNbr = `${first}.${second + mutator}`;
    }

    return prevItemNbr;
}

function getItemNbrParts(i) {
    const [top = '0', sub = '0'] = String(i).split('.');
    const res = [Number(top), Number(sub)];
    return res;
}

function sortByItemNbr(a, b) {
    const [aMain, aSub] = getItemNbrParts(a.itemNbr);
    const [bMain, bSub] = getItemNbrParts(b.itemNbr);

    if (aMain > bMain) {
        return 1;
    }

    if (aMain < bMain) {
        return -1;
    }

    if (aSub > bSub) {
        return 1;
    }

    if (aSub < bSub) {
        return -1;
    }

    return 0;
}

export { isPrimaryItemNbr, newItemNbr, getItemNbrParts, sortByItemNbr };
