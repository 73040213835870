import { getStore } from '~common/store';
import { MODAL_LOADING_OPEN, MODAL_LOADING_CLOSE } from '~common/action.types';

export const loadingModal = {
    open: (title: string): (() => void) => {
        const dispatch = getStore().dispatch;
        return dispatch({ type: MODAL_LOADING_OPEN, title });
    },
    close: (): (() => void) => {
        const dispatch = getStore().dispatch;
        return dispatch({ type: MODAL_LOADING_CLOSE });
    },
};
