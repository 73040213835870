import React from 'react';

export default ({ title = 'NZD', ...rest }) => (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...rest}>
        {title && <title>{title}</title>}

        <path d="M1 6H31V28.5H1V6Z" fill="#00247D" />
        <path d="M1 6H16V17.25H1V6Z" fill="#00247D" />
        <path
            d="M2.75781 6L8.47656 10.2422L14.1719 6H16V7.45312L10.375 11.6484L16 15.8203V17.25H14.125L8.5 13.0547L2.89844 17.25H1V15.8438L6.60156 11.6719L1 7.5V6H2.75781Z"
            fill="#fff"
        />
        <path
            d="M10.9375 12.5859L16 16.3125V17.25L9.64844 12.5859H10.9375ZM6.625 13.0547L6.76562 13.875L2.26562 17.25H1L6.625 13.0547ZM16 6V6.07031L10.1641 10.4766L10.2109 9.44531L14.8281 6H16ZM1 6L6.60156 10.125H5.19531L1 6.98438V6Z"
            fill="#C8102E"
        />
        <path
            d="M6.64844 6V17.25H10.3984V6H6.64844ZM1 9.75V13.5H16V9.75H1Z"
            fill="#fff"
        />
        <path
            d="m1 10.523v2.25h15v-2.25h-15zm6.3984-4.5234v11.25h2.25v-11.25h-2.25z"
            fill="#C8102E"
        />
        <path d="m25.233 11.024v0.7111l1.4221-0.7111h-1.4221z" fill="#fff" />
        <path
            d="m25.536 11.815-0.4179-0.5753 1.5684-0.2606-1.1505 0.8359z"
            fill="#fff"
        />
        <path d="m25.233 11.024v0.7111l-1.4222-0.7111h1.4222z" fill="#fff" />
        <path
            d="m24.93 11.815 0.418-0.5753-1.5685-0.2606 1.1505 0.8359z"
            fill="#fff"
        />
        <path
            d="m25.638 11.319-0.6762 0.2197 1.1157 1.1328-0.4395-1.3525z"
            fill="#fff"
        />
        <path
            d="m24.98 11.852 0.4179-0.5752 0.7326 1.4111-1.1505-0.8359z"
            fill="#fff"
        />
        <path
            d="m24.827 11.319 0.6763 0.2197-0.2368-1.5722-0.4395 1.3525z"
            fill="#fff"
        />
        <path
            d="m25.672 11.275-0.6763 0.2197 0.2368-1.5722 0.4395 1.3526z"
            fill="#fff"
        />
        <path
            d="m24.827 11.319 0.6763 0.2197-1.1158 1.1328 0.4395-1.3525z"
            fill="#fff"
        />
        <path
            d="m25.485 11.852-0.4179-0.5752-0.7326 1.4111 1.1505-0.8359z"
            fill="#fff"
        />
        <path d="m25.233 11.169v0.4698l0.9397-0.4698h-0.9397z" fill="#CC142B" />
        <path
            d="m25.433 11.691-0.2762-0.3801 1.0365-0.1723-0.7603 0.5524z"
            fill="#CC142B"
        />
        <path d="m25.233 11.169v0.4698l-0.9398-0.4698h0.9398z" fill="#CC142B" />
        <path
            d="m25.032 11.691 0.2762-0.3801-1.0364-0.1723 0.7602 0.5524z"
            fill="#CC142B"
        />
        <path
            d="m25.501 11.364-0.4468 0.1452 0.7372 0.7485-0.2904-0.8937z"
            fill="#CC142B"
        />
        <path
            d="m25.066 11.716 0.2762-0.3801 0.484 0.9325-0.7602-0.5524z"
            fill="#CC142B"
        />
        <path
            d="m24.965 11.364 0.4469 0.1452-0.1565-1.0389-0.2904 0.8937z"
            fill="#CC142B"
        />
        <path
            d="m25.523 11.335-0.4468 0.1452 0.1564-1.0389 0.2904 0.8937z"
            fill="#CC142B"
        />
        <path
            d="m24.965 11.364 0.4469 0.1452-0.7373 0.7485 0.2904-0.8937z"
            fill="#CC142B"
        />
        <path
            d="m25.399 11.716-0.2761-0.3801-0.4841 0.9325 0.7602-0.5524z"
            fill="#CC142B"
        />
        <path d="m28.961 14.308v0.6328l1.2655-0.6328h-1.2655z" fill="#fff" />
        <path
            d="m29.231 15.011-0.3719-0.5119 1.3957-0.2319-1.0238 0.7438z"
            fill="#fff"
        />
        <path d="m28.961 14.308v0.6328l-1.2655-0.6328h1.2655z" fill="#fff" />
        <path
            d="m28.692 15.011 0.3719-0.5119-1.3958-0.2319 1.0239 0.7438z"
            fill="#fff"
        />
        <path
            d="m29.322 14.57-0.6017 0.1956 0.9928 1.008-0.3911-1.2036z"
            fill="#fff"
        />
        <path
            d="m28.737 15.044 0.3719-0.5119 0.6519 1.2558-1.0238-0.7439z"
            fill="#fff"
        />
        <path
            d="m28.6 14.57 0.6018 0.1956-0.2107-1.3991-0.3911 1.2035z"
            fill="#fff"
        />
        <path
            d="m29.352 14.531-0.6017 0.1955 0.2107-1.3991 0.391 1.2036z"
            fill="#fff"
        />
        <path
            d="m28.6 14.57 0.6018 0.1956-0.9929 1.008 0.3911-1.2036z"
            fill="#fff"
        />
        <path
            d="m29.186 15.044-0.3719-0.5119-0.6519 1.2558 1.0238-0.7439z"
            fill="#fff"
        />
        <path d="m28.961 14.453v0.3915l0.7831-0.3915h-0.7831z" fill="#CC142B" />
        <path
            d="m29.128 14.888-0.2302-0.3168 0.8637-0.1435-0.6335 0.4603z"
            fill="#CC142B"
        />
        <path d="m28.961 14.453v0.3915l-0.7831-0.3915h0.7831z" fill="#CC142B" />
        <path
            d="m28.794 14.888 0.2301-0.3168-0.8637-0.1435 0.6336 0.4603z"
            fill="#CC142B"
        />
        <path
            d="m29.185 14.615-0.3724 0.121 0.6144 0.6238-0.242-0.7448z"
            fill="#CC142B"
        />
        <path
            d="m28.822 14.908 0.2302-0.3167 0.4034 0.777-0.6336-0.4603z"
            fill="#CC142B"
        />
        <path
            d="m28.738 14.615 0.3724 0.121-0.1304-0.8658-0.242 0.7448z"
            fill="#CC142B"
        />
        <path
            d="m29.203 14.591-0.3724 0.121 0.1304-0.8658 0.242 0.7448z"
            fill="#CC142B"
        />
        <path
            d="m28.738 14.615 0.3724 0.121-0.6144 0.6238 0.242-0.7448z"
            fill="#CC142B"
        />
        <path
            d="m29.1 14.908-0.2301-0.3167-0.4034 0.777 0.6335-0.4603z"
            fill="#CC142B"
        />
        <path d="m20.895 15.396v0.7111l1.4221-0.7111h-1.4221z" fill="#fff" />
        <path
            d="m21.198 16.186-0.4179-0.5753 1.5684-0.2606-1.1505 0.8359z"
            fill="#fff"
        />
        <path d="m20.895 15.396v0.7111l-1.4222-0.7111h1.4222z" fill="#fff" />
        <path
            d="m20.592 16.186 0.4179-0.5753-1.5684-0.2606 1.1505 0.8359z"
            fill="#fff"
        />
        <path
            d="m21.3 15.691-0.6762 0.2198 1.1157 1.1328-0.4395-1.3526z"
            fill="#fff"
        />
        <path
            d="m20.642 16.224 0.4179-0.5753 0.7326 1.4112-1.1505-0.8359z"
            fill="#fff"
        />
        <path
            d="m20.489 15.691 0.6762 0.2198-0.2367-1.5723-0.4395 1.3525z"
            fill="#fff"
        />
        <path
            d="m21.334 15.647-0.6763 0.2197 0.2368-1.5722 0.4395 1.3525z"
            fill="#fff"
        />
        <path
            d="m20.489 15.691 0.6762 0.2198-1.1157 1.1328 0.4395-1.3526z"
            fill="#fff"
        />
        <path
            d="m21.147 16.224-0.4179-0.5753-0.7326 1.4112 1.1505-0.8359z"
            fill="#fff"
        />
        <path d="m20.895 15.541v0.4699l0.9397-0.4699h-0.9397z" fill="#CC142B" />
        <path
            d="m21.095 16.063-0.2762-0.3801 1.0365-0.1722-0.7603 0.5523z"
            fill="#CC142B"
        />
        <path d="m20.895 15.541v0.4699l-0.9398-0.4699h0.9398z" fill="#CC142B" />
        <path
            d="m20.694 16.063 0.2762-0.3801-1.0364-0.1722 0.7602 0.5523z"
            fill="#CC142B"
        />
        <path
            d="m21.163 15.736-0.4468 0.1452 0.7372 0.7486-0.2904-0.8938z"
            fill="#CC142B"
        />
        <path
            d="m20.728 16.088 0.2762-0.3801 0.484 0.9325-0.7602-0.5524z"
            fill="#CC142B"
        />
        <path
            d="m20.627 15.736 0.4469 0.1452-0.1565-1.0389-0.2904 0.8937z"
            fill="#CC142B"
        />
        <path
            d="m21.185 15.707-0.4468 0.1452 0.1564-1.0389 0.2904 0.8937z"
            fill="#CC142B"
        />
        <path
            d="m20.627 15.736 0.4469 0.1452-0.7373 0.7486 0.2904-0.8938z"
            fill="#CC142B"
        />
        <path
            d="m21.061 16.088-0.2761-0.3801-0.4841 0.9325 0.7602-0.5524z"
            fill="#CC142B"
        />
        <path d="m25.233 22.254v0.7893l1.5787-0.7893h-1.5787z" fill="#fff" />
        <path
            d="m25.569 23.131-0.464-0.6386 1.7412-0.2893-1.2772 0.9279z"
            fill="#fff"
        />
        <path d="m25.233 22.254v0.7893l-1.5788-0.7893h1.5788z" fill="#fff" />
        <path
            d="m24.896 23.131 0.464-0.6386-1.7413-0.2893 1.2773 0.9279z"
            fill="#fff"
        />
        <path
            d="m25.683 22.581-0.7507 0.2439 1.2386 1.2576-0.4879-1.5015z"
            fill="#fff"
        />
        <path
            d="m24.953 23.172 0.464-0.6386 0.8133 1.5666-1.2773-0.928z"
            fill="#fff"
        />
        <path
            d="m24.782 22.581 0.7508 0.2439-0.2629-1.7454-0.4879 1.5015z"
            fill="#fff"
        />
        <path
            d="m25.721 22.533-0.7508 0.2439 0.2629-1.7454 0.4879 1.5015z"
            fill="#fff"
        />
        <path
            d="m24.782 22.581 0.7508 0.2439-1.2386 1.2576 0.4878-1.5015z"
            fill="#fff"
        />
        <path
            d="m25.513 23.172-0.464-0.6386-0.8132 1.5666 1.2772-0.928z"
            fill="#fff"
        />
        <path d="m25.233 22.399v0.5482l1.0963-0.5482h-1.0963z" fill="#CC142B" />
        <path
            d="m25.466 23.008-0.3223-0.4435 1.2092-0.201-0.8869 0.6445z"
            fill="#CC142B"
        />
        <path d="m25.233 22.399v0.5482l-1.0964-0.5482h1.0964z" fill="#CC142B" />
        <path
            d="m24.999 23.008 0.3222-0.4435-1.2092-0.201 0.887 0.6445z"
            fill="#CC142B"
        />
        <path
            d="m25.546 22.626-0.5213 0.1694 0.8601 0.8733-0.3388-1.0427z"
            fill="#CC142B"
        />
        <path
            d="m25.038 23.036 0.3222-0.4435 0.5647 1.0879-0.8869-0.6444z"
            fill="#CC142B"
        />
        <path
            d="m24.92 22.626 0.5214 0.1694-0.1826-1.2121-0.3388 1.0427z"
            fill="#CC142B"
        />
        <path
            d="m25.572 22.592-0.5213 0.1694 0.1825-1.2121 0.3388 1.0427z"
            fill="#CC142B"
        />
        <path
            d="m24.92 22.626 0.5213 0.1694-0.8601 0.8733 0.3388-1.0427z"
            fill="#CC142B"
        />
        <path
            d="m25.427 23.036-0.3222-0.4435-0.5648 1.0879 0.887-0.6444z"
            fill="#CC142B"
        />
    </svg>
);
