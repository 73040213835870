import _find from 'lodash/find.js';
import _get from 'lodash/get.js';
import _remove from 'lodash/remove.js';
import _uniqBy from 'lodash/uniqBy.js';
import { createSelector } from 'reselect';
import { getStore } from '~common/store';
import {
    getUserDisplayName,
    getUserFirstName,
} from '~shared/user/user.helpers';
import { ATTENDEE_ROLE_ABSENT_SUFFIX } from '~common/constants';

export const getUserSelector = (state) =>
    state?.user || getStore().getState().user;

/**
 * Get list of known users. This includes users from any remote calendars.
 */
export const getUserListSelector = createSelector(
    (state) => state.userList.users,
    (state) => state.remoteCalendars,
    (users, remoteCalendars) => {
        const userList = [];
        for (const value of Object.values(remoteCalendars)) {
            if (value.users) {
                userList.push(
                    ...Object.values(value.users).map((user) => ({
                        ...user,
                        id: user.id || user.email,
                    }))
                );
            }
        }
        if (!userList.length) return users;
        return _uniqBy([...users, ...userList], 'id');
    }
);

export const getExistingUserListForDropdown = createSelector(
    getUserListSelector,
    (userList) => [...userList]
);

export const getUserName = (id) => {
    const state = getStore().getState();
    return getUserDisplayName(getUser(id, state.userList.users));
};

export const getUser = (id) => {
    const state = getStore().getState();
    return id ? findUserInList(state.userList.users, id) : state.user;
};

export const findUserInList = (userList, id) =>
    _find(userList, { id }) || {
        id,
        displayName: 'Unknown',
        givenName: 'Unknown',
        surname: '',
        isUnknownUser: true,
    };

export const getAssigneeOptionsForDropdown = (
    attendeeList = [],
    attendeeOptions
) => {
    /**
     * This function is to populate the list of assignee options for agenda items / actions.
     * The list is in a specific format to pass as a list of options to a dropdown.
     * Look through the list of possible attendeeOptions (which is already in this format),
     * and reduce the list based on those who are selected in the attendees list.
     */
    return attendeeList
        .map((id) => _find(attendeeOptions, { id }))
        .filter(Boolean);
};

export const getUserListFromAttendees = ({
    attendees,
    loggedInUserFirst,
    displayNameForLoggedInUser,
    excludeAbsentees = true,
}) => {
    const idList = attendees
        .map((attendee) =>
            !excludeAbsentees
                ? attendee.userId
                : !attendee.role?.match?.(ATTENDEE_ROLE_ABSENT_SUFFIX) &&
                  attendee.userId
        )
        .filter(Boolean);

    const userList = getUserListFromIds({
        idList,
        loggedInUserFirst,
        displayNameForLoggedInUser,
    });

    return userList.map((user) => {
        const attendeeRecord = attendees.find(
            (attendee) => attendee.userId === user.id
        );
        return {
            ...user,
            role: _get(attendeeRecord, 'role'),
        };
    });
};

export const getUserListFromIds = ({
    idList,
    loggedInUserFirst = true,
    displayNameForLoggedInUser = 'You',
}) => {
    const state = getStore().getState();
    const { user: loggedInUser } = state;
    const userList = getUserListSelector(state);

    const users = idList.map((id) => {
        const user = findUserInList(userList, id);
        if (user.id === loggedInUser.id && displayNameForLoggedInUser) {
            return {
                ...user,
                displayName: displayNameForLoggedInUser,
                givenName: displayNameForLoggedInUser,
                surname: '',
            };
        }
        if (!user.displayName || !user.givenName) {
            //This is a user who has been invited to a meeting, but not yet signed in to MinuteMe
            return {
                ...user,
                displayName: getUserDisplayName(user),
                givenName: getUserFirstName(user),
                surname: '',
            };
        }
        return user;
    });

    // put 'Unknown' users at the end of the list
    const unknownUsers = _remove(users, (user) => !user.surname);
    unknownUsers.length && users.push(...unknownUsers);

    if (loggedInUserFirst) {
        const currentUserOption = _remove(
            users,
            (user) => user.id === loggedInUser.id
        );
        currentUserOption.length && users.unshift(...currentUserOption);
    }

    return users;
};
