import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m16.469 30.062s7.0312-4.6875 7.0312-14.062c0-10.312-7.0312-14.062-7.0312-14.062m13.125 14.062h-27.188m13.125 14.062s-7.0312-4.6875-7.0312-14.062c0-10.312 7.0312-14.062 7.0312-14.062m0.4688 0v28.125"
            strokeLinecap="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <circle
            cx="16"
            cy="16"
            r="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
