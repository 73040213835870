import React, { useRef, forwardRef, useCallback, useEffect } from 'react';
import { default as ReactContentEditable } from 'react-contenteditable';
import { stripHtml } from '~shared/utils/html-sanitizer';

/**
 * This function will select text of a <div>. Which is particularly useful for the ContentEditable.
 * This is because you can't use node.select() on a div, like you can on an input.
 * @param {node} el DOM node
 */
export function selectElementContents(el) {
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
}

function deselectElementContents() {
    const sel = window.getSelection();
    sel.removeAllRanges();
}

const ContentEditable = forwardRef(
    (
        {
            onTitleChange,
            title,
            disabled,
            tagName,
            onClick,
            onKeyUp,
            onFinishEditing,
            placeholder,
            canHaveEmptyValue,
        },
        ref
    ) => {
        const titleRef = ref || useRef(); //eslint-disable-line react-hooks/rules-of-hooks

        const latestTitle = useRef(title);

        useEffect(() => {
            latestTitle.current = title;
        }, [title]);

        const handleKeyDown = useCallback(
            (evt) => {
                switch (evt.key) {
                    case 'Enter':
                    case 'Tab': {
                        evt.preventDefault();
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                        titleRef.current && titleRef.current.blur();
                        break;
                    }
                    case 'Escape': {
                        evt.target.innerText = latestTitle.current;
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                        titleRef.current && titleRef.current.blur();
                    }
                }
            },
            [titleRef]
        );

        const handleBlur = useCallback(
            (evt) => {
                const rawText = evt.target.innerText.trim();
                const text = stripHtml(rawText);
                if (text !== latestTitle.current) {
                    if (canHaveEmptyValue || text.length) {
                        onTitleChange(text);
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                        onFinishEditing && onFinishEditing(evt, true);
                        return;
                    }
                    evt.target.innerText = latestTitle.current;
                }
                if (text !== rawText) {
                    evt.target.innerText = text;
                }
                deselectElementContents();
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                onFinishEditing && onFinishEditing(evt, false);
            },
            [onTitleChange, onFinishEditing, canHaveEmptyValue]
        );

        return (
            <ReactContentEditable
                innerRef={titleRef}
                html={title}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                {...{ disabled, tagName, onClick, onKeyUp, placeholder }}
            />
        );
    }
);

export default ContentEditable;
