import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m15.763 7c-7.2136 0-12.181 6-13.763 9 1.5819 3 6.5492 9 13.763 9 8.0678 0 12.339-5.6842 14.237-9-1.8983-3.3158-6.1695-9-14.237-9z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <ellipse
            cx="16.5"
            cy="16"
            rx="6.5"
            ry="6"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
