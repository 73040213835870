import { io } from 'socket.io-client';
import { getSessionId } from '../actions/session.js';
import { canApplyUpdates } from '~common/store';

let socket, lastEventTime;

export function subscribe(socketHandlers = {}, managerHandlers = {}) {
    socket = io({
        path: '/api/subscriptions',
    });

    Object.keys(socketHandlers).forEach((name) => {
        socket.on(name, (ctx, ...args) => {
            // console.log('websocket... ', {
            //     suppressWebsocketSend: ctx?.suppressWebsocketSend,
            //     session: getSessionId(),
            // });
            const shouldProcessWebsocketEvent =
                ctx?.suppressWebsocketSend !== getSessionId();
            // eslint-disable-next-line no-console
            console.log(
                `Websocket Event${
                    shouldProcessWebsocketEvent ? '' : ' suppressed'
                }${canApplyUpdates() ? '' : ' paused'}`,
                name,
                new Date(),
                ctx,
                ...args
            );
            ctx?.eventTime && setLastEventTime(ctx.eventTime);
            shouldProcessWebsocketEvent &&
                socketHandlers[name].call(socket, ctx, ...args);
        });
    });
    Object.keys(managerHandlers).forEach((name) => {
        socket.io.on(name, (ctx, ...args) => {
            // eslint-disable-next-line no-console
            console.log(
                'Websocket Manager Event',
                name,
                new Date(),
                ctx,
                ...args
            );
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
            ctx && ctx.eventTime && setLastEventTime(ctx.eventTime);
            managerHandlers[name].call(socket, ctx, ...args);
        });
    });
}

export function setLastEventTime(time) {
    if (!lastEventTime || lastEventTime < time) {
        lastEventTime = time;
    }
}

export function getLastEventTime() {
    return lastEventTime;
}

export function unsubscribe() {
    if (socket) {
        console.log('Websocket Unsubscribe'); // eslint-disable-line no-console
        socket.close();
    }
}

export function publish(...args) {
    if (socket) {
        console.log('Websocket Publish', ...args); // eslint-disable-line no-console
        socket.emit(...args);
    }
}
