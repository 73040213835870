import _get from 'lodash/get.js';
import _startCase from 'lodash/startCase.js';
import React from 'react';
import { useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';
import { getUserDisplayName } from '~shared/user/user.helpers';
import DefaultLayout from '~modules/common/layouts/DefaultLayout';
import { Heading } from '~modules/common/components';
import { getUserSelector } from '~modules/user/user.selectors';

const ErrorBox = styled.div`
    ${tw`flex items-center justify-center`}
    ${({ isContained }) => (isContained ? tw`h-full` : tw`min-h-screen`)}
    ${({ hasNoBg }) => !hasNoBg && tw`bg-solid`}
`;

const ErrorMessage = styled.div`
    max-width: 600px;
    ${tw`shadow-sm text-center m-4 px-8 py-6 border border-line rounded bg-white border-t-4`}
    // TODO: Add this color to Global color config
    border-top: 4px solid #ED64A6;
`;
const Code = tw.code`text-xs mt-4 rounded bg-body-dark text-white px-5 py-3 inline-block`;
const Message = tw.div`mt-2`;
const Children = tw.div`mt-2`;

export const NoMatch = ({
    location = location.pathname,
    isContained = false,
}) => (
    <ErrorLayout {...{ isContained }}>
        <Heading as="h1" xxl>
            Sorry, that url was not found
        </Heading>
        <Message>
            The url
            {/* // Prevent url's overflowing container */}
            <span tw="break-all"> &ldquo;{location.pathname}&rdquo; </span> is
            not available.
        </Message>
    </ErrorLayout>
);

export const GeneralError = ({
    heading = 'Something went wrong',
    isContained = false,
    children,
    error,
}) => (
    <ErrorLayout {...{ isContained }}>
        <Heading as="h1" xxl>
            {heading}
        </Heading>
        {error && <Code>{error.message}</Code>}
        {children && <Children>{children}</Children>}
    </ErrorLayout>
);

export const NotFoundPage = ({ type, ...rest }) => {
    const user = useSelector(getUserSelector);

    return (
        <ErrorLayout {...rest}>
            <Heading as="h1" xxl>
                {_startCase(type)} not found
            </Heading>
            <Message>
                Please make sure you are logged in as the correct user, and have
                accepted all invitations in the Notifications Centre.
                {_get(user, 'email') && (
                    <>
                        <br />
                        You are logged in as
                        <strong>{` ${getUserDisplayName(user)} (${
                            user.email
                        })`}</strong>
                        .
                    </>
                )}
            </Message>
        </ErrorLayout>
    );
};

export const AccessDeniedPage = ({ type, isContained }) => {
    const user = useSelector(getUserSelector);

    return (
        <ErrorLayout {...{ isContained }}>
            <Heading as="h1" xxl>
                Access Denied
            </Heading>
            <Message>
                If you think you should be able to see this {type}, please make
                sure you are logged in as the correct user, and that you have
                accepted all invitations in the Notifications Centre.
                {_get(user, 'email') && (
                    <>
                        <br />
                        You are logged in as
                        <strong>{` ${getUserDisplayName(user)} (${
                            user.email
                        })`}</strong>
                        .
                    </>
                )}
            </Message>
        </ErrorLayout>
    );
};

const ErrorLayout = ({ isContained, hasNoBg, children }) =>
    isContained ? (
        <ErrorBox {...{ isContained, hasNoBg }}>
            <ErrorMessage>{children}</ErrorMessage>
        </ErrorBox>
    ) : (
        <DefaultLayout>
            <ErrorBox {...{ isContained, hasNoBg }}>
                <ErrorMessage>{children}</ErrorMessage>
            </ErrorBox>
        </DefaultLayout>
    );
