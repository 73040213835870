import _get from 'lodash/get.js';
import React, { useRef, useEffect, forwardRef } from 'react';
import { Global } from '@emotion/react';
import { Input as SemanticInput } from 'semantic-ui-react';
import tw, { styled, css } from 'twin.macro';
import { useKeyboardEvent } from '~hooks';
import 'semantic-ui-css/components/input.css';

const INPUT_X_PAD = '1em';
const INPUT_X_PAD_ICON_BORDERED = '2.7em';
const INPUT_X_PAD_ICON = '1.75em';
const TITLE_PAD = '1.25rem';

const inputStyles = css`
    /* Overwrite semantic input autofill background color */
    .ui.form .field.field input:-webkit-autofill,
    .ui.form .field.field input:-webkit-autofill:focus,
    .ui.form .field.field input:-webkit-autofill:active {
        -webkit-box-shadow: 0px 0px 0px 100px #ffffff inset !important;
        box-shadow: 0px 0px 0px 100px #ffffff inset !important;
    }
`;

const Wrap = styled.label`
    ${tw`w-full`}

    .ui.input {
        ${tw`flex items-center relative`}
        ${({ hasBorder, isWhite }) =>
            hasBorder && [
                tw`border border-solid rounded`,
                isWhite
                    ? tw`border-solid-blue-lightest`
                    : tw`border-line hover:border-line-dark`,
            ]}
            ${({ isDisabled }) =>
            isDisabled && tw`cursor-not-allowed hover:border-line`}
    }

    .ui.input > input {
        ${tw`border-0 focus:outline-none bg-transparent py-3 leading-normal`}
        ${({ isWhite }) => (isWhite ? tw`text-white` : tw`text-body-dark`)}
        ${({ isBold }) => isBold && tw`font-bold`}
        ${({ isLarge }) => isLarge && tw`md:text-lg`}
    }
    .ui.input.focus > input,
    .ui.input > input:focus,
    .ui.input > input:active {
        ${tw`bg-transparent`}
    }
    .ui.disabled.input,
    .ui.input:not(.disabled) input[disabled] {
        ${tw`!opacity-100 svg:opacity-50 !cursor-not-allowed`}
    }

    ${({ hasBorder }) =>
        hasBorder
            ? css`
                  .ui[class*='left icon'].input > input {
                      ${tw`!pl-[${INPUT_X_PAD_ICON_BORDERED}] !pr-[${INPUT_X_PAD}]`}
                  }
              `
            : css`
                  .ui[class*='left icon'].input > input {
                      ${tw`!pl-[${INPUT_X_PAD_ICON}] !pr-0`}
                  }
              `}

    ${({ hasBorder }) =>
        hasBorder
            ? css`
                  .ui[class*='right icon'].input > input {
                      ${tw`!pr-[${INPUT_X_PAD_ICON_BORDERED}] !pl-[${INPUT_X_PAD}]`}
                  }
              `
            : css`
                  .ui[class*='right icon'].input > input {
                      ${tw`!pr-[${INPUT_X_PAD_ICON}] !pl-0`}
                  }
              `}

              ${({ hasTitlePadding, hasIcon }) =>
        hasTitlePadding &&
        !hasIcon &&
        css`
            .ui[class*='left icon'].input > input,
            .ui[class*='right icon'].input > input {
                ${tw`!pl-[${TITLE_PAD}] truncate`}
            }
        `}

        ${({ hasIcon, hasBorder, hasTitlePadding }) =>
        !hasIcon &&
        !hasBorder &&
        !hasTitlePadding &&
        css`
            .ui[class*='left icon'].input > input,
            .ui[class*='right icon'].input > input {
                ${tw`!pl-0 !pr-0`}
            }
        `}

        ${({ hasIcon, hasBorder }) =>
        !hasIcon &&
        hasBorder &&
        css`
            .ui[class*='left icon'].input > input,
            .ui[class*='right icon'].input > input {
                ${tw`!pl-[${INPUT_X_PAD}] !pr-[${INPUT_X_PAD}]`}
            }
        `}

    ${({ iconPosition, hasItemPadding }) =>
        iconPosition === 'left' &&
        hasItemPadding &&
        css`
            .ui[class*='left icon'].input > input {
                /* Align to checkboxes */
                ${tw`!pl-9 lg:!pl-[2.95rem]`}
            }
        `}

    .ui.input > svg {
        ${tw`absolute`}
        ${({ isGrey }) => (isGrey ? tw`text-icon` : tw`text-primary`)}
        ${({ isWhite }) => isWhite && tw`text-white`}
        ${({ iconPosition }) =>
            iconPosition === 'left' && tw`pointer-events-none`}

        ${({ iconPosition, hasBorder }) =>
            iconPosition === 'left' && hasBorder && tw`left-0 ml-4`}

        ${({ iconPosition, hasBorder }) =>
            iconPosition === 'left' && !hasBorder && tw`left-0`}

        ${({ iconPosition, hasBorder }) =>
            iconPosition === 'right' && hasBorder && tw`right-0 mr-4`}

        ${({ iconPosition, hasBorder }) =>
            iconPosition === 'right' && !hasBorder && tw`right-0`}

        ${({ iconPosition, hasItemPadding }) =>
            iconPosition === 'left' &&
            hasItemPadding &&
            tw`left-[0.2rem] lg:left-[0.85rem]`}
    }

    .ui.input > input::placeholder {
        ${({ isWhite }) =>
            isWhite ? tw`text-solid-blue-lightest` : tw`text-body-light`}
        ${({ isDisabled }) => isDisabled && tw`text-body-disabled`}
    }
    .ui.input > input:hover::placeholder,
    .ui.input > input:focus::placeholder {
        ${({ isDisabled, isWhite }) =>
            !isDisabled &&
            (isWhite ? tw`text-solid-blue-lightest` : tw`text-body`)}
    }

    .ui.input.error > input[type='text'],
    .ui.input.error > input[type='text']:focus {
        ${tw`font-normal`}
        &::placeholder {
            ${tw`text-primary-disabled`}
        }
        .ui.input > input:hover::placeholder,
        .ui.input > input:focus::placeholder {
            ${({ isDisabled }) => !isDisabled && tw`text-body`}
            ${({ isWhite }) => isWhite && tw`text-[#96D7FF]`}
        }

        .ui.input.error > input[type='text'],
        .ui.input.error > input[type='text']:focus {
            ${tw`font-normal`}
            &::placeholder {
                ${tw`text-primary-disabled`}
            }
            input::placeholder {
                ${tw`text-error`}
            }
        }

        .ui.input.focus > input,
        .ui.input > input:focus,
        .ui.input > input:active,
        .ui.input.down input {
            ${({ isWhite }) => isWhite && tw`text-white`}
        }
    }

    .ui.input.focus > input,
    .ui.input > input:focus,
    .ui.input > input:active,
    .ui.input.down input {
        ${({ isWhite }) => isWhite && tw`text-white`}
    }
`;

/**
 * An Input field function component.
 * @prop {function} onEnter callback to trigger when enter key is pressed. Function is called with a ref to the field that had Enter pressed inside it.
 * @prop {boolean} focusOnMount determine whether or not to focus the input
 */
const Input = forwardRef(
    (
        {
            iconPosition = 'left',
            isGrey,
            isWhite,
            isBold,
            isLarge,
            onEnter,
            focusOnMount,
            hasBorder,
            icon,
            hasTitlePadding,
            as,
            hasItemPadding,
            isDisabled,
            ...inputProps
        },
        ref
    ) => {
        const inputField = ref || useRef(); //eslint-disable-line react-hooks/rules-of-hooks
        useEffect(() => {
            focusOnMount && setTimeout(() => inputField.current?.focus?.(), 0);
        }, [focusOnMount, inputField]);

        useKeyboardEvent('Enter', inputField, 'inputRef', () => {
            // GOTCHA SemanticUI <Input> has a parent <div> for the <input>, so inputField is actually a ref to the semanticUI component.
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
            onEnter && onEnter(_get(inputField, 'current.inputRef.current'));
        });

        return (
            <>
                <Global styles={inputStyles} />
                <Wrap
                    hasIcon={!!icon}
                    {...{
                        as,
                        iconPosition,
                        isGrey,
                        isWhite,
                        isBold,
                        isLarge,
                        hasBorder,
                        hasTitlePadding,
                        hasItemPadding,
                        isDisabled,
                    }}
                >
                    <SemanticInput
                        ref={inputField}
                        disabled={isDisabled}
                        {...inputProps}
                        {...{ icon }}
                        iconPosition={iconPosition === 'right' ? null : 'left'}
                        fluid
                    />
                </Wrap>
            </>
        );
    }
);

export default Input;
