import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m16.01 22.69c0.89 0 1.685-0.15 2.385-0.45s1.29-0.72 1.77-1.26c0.49-0.54 0.86-1.185 1.11-1.935 0.26-0.75 0.39-1.575 0.39-2.475v-13.065h2.895v13.065c0 1.24-0.2 2.39-0.6 3.45-0.39 1.06-0.955 1.98-1.695 2.76-0.73 0.77-1.625 1.375-2.685 1.815s-2.25 0.66-3.57 0.66-2.51-0.22-3.57-0.66-1.96-1.045-2.7-1.815c-0.74-0.78-1.31-1.7-1.71-2.76-0.39-1.06-0.585-2.21-0.585-3.45v-13.065h2.895v13.05c0 0.9 0.125 1.725 0.375 2.475 0.26 0.75 0.63 1.395 1.11 1.935 0.49 0.54 1.085 0.965 1.785 1.275 0.71 0.3 1.51 0.45 2.4 0.45z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
            stroke="currentColor"
            strokeWidth="0.6"
        />
        <path
            d="m6 28c-0.55228 0-1 0.4477-1 1s0.44772 1 1 1v-2zm20 2c0.5523 0 1-0.4477 1-1s-0.4477-1-1-1v2zm-20 0h20v-2h-20v2z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
            stroke="currentColor"
            strokeWidth="0.6"
        />
    </svg>
);
