import _throttle from 'lodash/throttle.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RTE_TINYMCE } from '~common/constants';
import { publish as wsPublish } from '~client/websocket/subscriptions';
import { onMinuteItemSave } from '~modules/minutes/minutes.actions';
import { getOccurrenceIdSelector } from '~common/selector.helpers';
import { getMinutesListTopicsSelector } from '~modules/minutes/minutes.selectors';
import { logDocumentToConsole } from '~modules/richtext/helpers';

const emptyMinutesList = [];

export const useUpdateMinuteItem = (minuteItem) => {
    const dispatch = useDispatch();
    const occurrenceId = getOccurrenceIdSelector(null, minuteItem);
    const { minutes, topicsAreSavedToDB } = useSelector((state) =>
        getMinutesListTopicsSelector(state, minuteItem)
    );

    // allTopics are only required to be sent into the onMinuteItemSave if the topics are not yet saved to the DB.
    // Otherwise, in order to prevent the callback below from re-creating, we return an empty array.
    const allTopics = topicsAreSavedToDB ? emptyMinutesList : minutes;

    return useCallback(
        (content) => {
            // in Slate, html content was stored without newline markers.
            // Tiny inserts newline markers. So for legacy comparison, we ignore any newline markers.
            // There are also some issues with < and > inside an inline action item. When this goes through the stringToHTML function (which uses Element.innerHTML - https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML)
            // it gets converted to >. But on the server end this gets converted to &gt;
            // To prevent the mismatch when comparing, we will ignore it here.
            const before = (minuteItem.content || '')
                .replace(/\n/g, '')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/&amp;/g, '&');
            const after = (content || '')
                .replace(/\n/g, '')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/&amp;/g, '&');
            after !== before &&
                (logDocumentToConsole(
                    'useUpdateMinuteItem: ',
                    {
                        before,
                        after,
                    },
                    'debug'
                ),
                dispatch(
                    onMinuteItemSave(
                        occurrenceId,
                        {
                            id: minuteItem.id,
                            content: content || null, // to allow content to be set back to blank
                            rte: RTE_TINYMCE,
                        },
                        allTopics,
                        topicsAreSavedToDB
                    )
                ));
        },
        [
            dispatch,
            occurrenceId,
            minuteItem.id,
            minuteItem.content,
            allTopics,
            topicsAreSavedToDB,
        ]
    );
};

export const useSendTyping = (minuteItem) => {
    const sendIsTyping = useMemo(
        () =>
            _throttle(
                (selection) => {
                    wsPublish(`client:user:page:topic:typing`, {
                        activePage: {
                            objectType: 'occurrence',
                            state: {
                                occurrence: minuteItem.occurrence,
                                meeting: minuteItem.meeting,
                                minuteItemIds: [
                                    minuteItem.id,
                                    minuteItem.agendaItem,
                                ].filter(Boolean),
                                selection,
                            },
                        },
                    });
                },
                2000,
                {
                    leading: true,
                    trailing: false,
                }
            ),
        [
            minuteItem.occurrence,
            minuteItem.meeting,
            minuteItem.id,
            minuteItem.agendaItem,
        ]
    );

    useEffect(() => {
        sendIsTyping.flush();
    }, [sendIsTyping]);

    return {
        sendIsTyping,
    };
};

export const useSendActiveLocation = (minuteItem) => {
    const sendActiveLocation = useMemo(
        () => (location) => {
            wsPublish(`client:user:page:topic:activeLocation`, {
                minuteItemIds: [minuteItem.id, minuteItem.agendaItem].filter(
                    Boolean
                ),
                location,
                occurrenceId: minuteItem.occurrence,
            });
        },

        [minuteItem.agendaItem, minuteItem.id, minuteItem.occurrence]
    );

    return {
        sendActiveLocation,
    };
};
