import { get, deleteRequest, patch } from '../../api/helpers.js';

export function deleteDocument(id) {
    const url = `/api/document/${id}`;
    return deleteRequest(url);
}

export function getDocument(id) {
    const url = `/api/document/${id}`;
    return get(url);
}

export function renameDocument(id, params) {
    const url = `/api/document/${id}/rename`;
    return patch(url, params);
}
