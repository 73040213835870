const emailRE = new RegExp(
    `^(?:"?(?<displayName>[^"]*)"?\\s)?<?(?<email>(?<username>[^<]+)@(?<domain>[^>]+))>?$`
);

export const isFormattedEmailAddressString = (str = '') => emailRE.test(str);

/**
 * Break down the components of an email address into an object containing its components.
 * Requires an email address string such as:
 *   username@domain
 *   displayName <username@domain>
 *   Display Name <username@domain>
 *   "Display Name" <username@domain>
 * @param {String} str formatted email address
 * @returns Undefined | Object { [displayName], username, domain, email }
 */
export const getEmailAddressComponents = (str) => {
    if (!isFormattedEmailAddressString(str || '')) return;

    const { displayName, email, username, domain } =
        (str || '').match(emailRE)?.groups || {};

    return {
        displayName,
        username: username,
        domain: domain.toLowerCase(),
        email: email.toLowerCase(),
    };
};
