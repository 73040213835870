import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m16.119 2.5623 4.2478 9.6376 10.177 0.8427-8.0725 6.4771 2.5626 10.48-8.9149-5.5295-8.9152 5.5295 2.5626-10.48-8.0724-6.4771 10.177-0.8427 4.2479-9.6376z"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
