export default () => (
    <svg viewBox="0 0 155.55 150.37" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="#eb7324"
            d="M71.52,67.69C40,56.27,32,32.32,37.87,14.9a44.81,44.81,0,0,1,3.29-7.38,2.09,2.09,0,0,0-.34-2.41,1.89,1.89,0,0,0-2.35-.29A79.41,79.41,0,0,0,13.74,117.51a78.37,78.37,0,0,0,35.13,28,60.24,60.24,0,0,0,23.23,4.89,56.29,56.29,0,0,0,5.94-.32c20.88-2.25,31.43-14.5,31.43-36.41,0-15.68-14.18-37.17-37.95-45.95m23.33,46c0,14.42-4.8,20-18.35,21.51-11.26,1.2-20.7-1.58-34.82-10.3a36.81,36.81,0,0,1-5.09-3.67A64.41,64.41,0,0,1,23.46,40.24C28.93,58.57,44.75,73.9,66.62,81.8c16.89,6.26,28.23,21.51,28.23,31.84"
        />
        <path
            fill="#eb7324"
            d="M151.85,48.86a77.91,77.91,0,0,0-10.63-21.65A64.4,64.4,0,0,0,122.92,10c-6.46-4-11.34-6.39-18.71-8A85.46,85.46,0,0,0,85.65,0C62.89,0,52.7,12.5,52.7,24.91,52.7,40.8,64.22,48,90,58.17c33,12.55,47,28.9,47,54.66,0,1.87-.08,3.75-.26,5.54a2,2,0,0,0,1.25,2.06,2.16,2.16,0,0,0,.7.13,2,2,0,0,0,1.57-.8,79,79,0,0,0,15.29-46.95,80.06,80.06,0,0,0-3.7-24m-10.92,24a.91.91,0,0,1-.62.91,1,1,0,0,1-1.18-.34c-9.2-11.94-23.58-21.54-44-29.33C71.21,34.7,67.35,30.74,67.35,24.91c0-6.23,6.83-9.93,18.3-9.93a72.6,72.6,0,0,1,15.48,1.66,36.31,36.31,0,0,1,9.75,3.61,54,54,0,0,1,22,21.41,64.7,64.7,0,0,1,8,31.15Z"
        />
    </svg>
);
