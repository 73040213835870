import _reject from 'lodash/reject.js';
import _uniqBy from 'lodash/uniqBy.js';
import _without from 'lodash/without.js';
import {
    isAgendaTemplate,
    isLibraryTemplate,
} from '~modules/template/template.helpers';
import {
    DELETE_MEETING_SUCCESS,
    FETCH_AGENDA_ITEMS_SUCCESS,
    EDIT_AGENDA_ITEM_REQUEST,
    EDIT_AGENDA_ITEM_SUCCESS,
    DELETE_AGENDA_ITEM_REQUEST,
    DELETE_AGENDA_ITEM_SUCCESS,
    CREATE_AGENDA_ITEM_REQUEST,
    ADD_TEMPLATE_REQUEST,
    ADD_TEMPLATE_WEBSOCKET,
    UPDATE_TEMPLATE_SUCCESS,
    BULK_CREATE_AGENDA_ITEM_REQUEST,
    BULK_CREATE_AGENDA_ITEM_ERROR,
    CREATE_AGENDA_ITEM_SUCCESS,
    CREATE_AGENDA_ITEM_ERROR,
    ADD_TEMPLATE_ERROR,
    RENUMBER_MEETING_AGENDAITEMS_WEBSOCKET,
    WORKSPACE_ACCESS_REMOVED,
    WORKSPACE_TEMPLATE_ACCESS_REMOVED,
    LOGOUT_SUCCESS,
} from '~common/action.types';
import { isPrimaryItemNbr } from '~common/list.helpers';

const updateAgendaItems = (state, newItem) => {
    return state.agendaItems.map((m) => {
        let updatedAgendaItemProps;
        if (Array.isArray(newItem)) {
            updatedAgendaItemProps = newItem.find(
                (updated) => updated.id === m.id
            );
        } else if (m.id === newItem.id) {
            updatedAgendaItemProps = newItem;
        }

        if (!updatedAgendaItemProps) return m;
        return {
            ...m,
            isLocalUpdate: false,
            ...updatedAgendaItemProps,
        };
    });
};

const initalState = {
    agendaItems: [],
};

const agendaReducer = (state = initalState, action) => {
    switch (action.type) {
        case DELETE_MEETING_SUCCESS: {
            return {
                ...state,
                agendaItems: state.agendaItems.filter(
                    (o) => o.meeting !== action.meeting.id
                ),
            };
        }

        case FETCH_AGENDA_ITEMS_SUCCESS: {
            if (!action.agendaItems?.length) return state;

            return {
                ...state,
                agendaItems: _uniqBy(
                    [...action.agendaItems, ...state.agendaItems],
                    'id'
                ),
            };
        }

        case CREATE_AGENDA_ITEM_SUCCESS:
        case CREATE_AGENDA_ITEM_REQUEST:
        case BULK_CREATE_AGENDA_ITEM_REQUEST: {
            return {
                ...state,
                agendaItems: _uniqBy(
                    [
                        action.agendaItem,
                        ...(action.agendaItems || []),
                        ...state.agendaItems,
                    ].filter(Boolean),
                    'id'
                ),
            };
        }
        case ADD_TEMPLATE_REQUEST:
        case ADD_TEMPLATE_WEBSOCKET: {
            // template could be for a meetingId, or a templateId/userId.
            // Therefore the pre-existing agendaItems from the template need to be removed,
            // and any new agenda items added

            const {
                template: { agendaItems = [] },
                existingMeetingAgendaItems = [],
            } = action;

            if (
                agendaItems.length === 0 &&
                existingMeetingAgendaItems.length === 0
            )
                return state;

            return {
                ...state,
                agendaItems: [
                    ...state.agendaItems.filter(
                        (agendaItem) =>
                            !existingMeetingAgendaItems.some(
                                (ai) => ai.id === agendaItem.id
                            )
                    ),
                    ...agendaItems,
                ],
            };
        }

        case UPDATE_TEMPLATE_SUCCESS: {
            // remove any existing agendaItems for this template,
            // and add any new ones
            if (!action.template?.agendaItems) return state;

            // when assessing agenda items in the filter below...
            // if the updated template is a library template, keep all agenda items that are not from this template
            // if the updated template is an agenda template, keep all agenda items that not from this meeting

            return {
                ...state,
                agendaItems: _uniqBy([
                    ...action.template.agendaItems,
                    ...state.agendaItems.filter(
                        (ai) =>
                            (isLibraryTemplate(action.template) &&
                                ai.templateId !== action.template.id) ||
                            (isAgendaTemplate(action.template) &&
                                ai.meeting !== action.template.meetingId)
                    ),
                ]),
            };
        }

        case BULK_CREATE_AGENDA_ITEM_ERROR: {
            return {
                ...state,
                agendaItems: _reject(
                    state.agendaItems,
                    (a) => a.meeting === action.meetingId
                ),
            };
        }

        case CREATE_AGENDA_ITEM_ERROR: {
            return {
                ...state,
                agendaItems: _uniqBy(
                    _without(
                        [...(action.agendaItems || []), ...state.agendaItems],
                        action.agendaItem
                    ),
                    'id'
                ),
            };
        }
        case ADD_TEMPLATE_ERROR: {
            // template could be for a meetingId, or a templateId/userId.
            // Therefore the agendaItems from the template need to be removed,
            // and any pre-existing agenda items re-instated
            const {
                template: { agendaItems = [] },
                existingMeetingAgendaItems,
            } = action;
            return {
                ...state,
                agendaItems: [
                    ...state.agendaItems.filter(
                        (agendaItem) =>
                            !agendaItems.some((ai) => ai.id === agendaItem.id)
                    ),
                    ...existingMeetingAgendaItems,
                ],
            };
        }

        case EDIT_AGENDA_ITEM_REQUEST:
        case EDIT_AGENDA_ITEM_SUCCESS:
        case RENUMBER_MEETING_AGENDAITEMS_WEBSOCKET: {
            return {
                ...state,
                agendaItems: updateAgendaItems(
                    state,
                    action.agendaItem || action.agendaItems
                ),
            };
        }

        case DELETE_AGENDA_ITEM_REQUEST: {
            const isPrimary = isPrimaryItemNbr(action?.agendaItem?.itemNbr);
            const isDraft = Boolean(action?.agendaItem?.isDraft); // we use Boolean here as it may not be populated at all

            // it may be a library template item (agendaItem.templateId) or an agenda/meeting template item
            const isMeetingTemplateItem = Boolean(action.agendaItem.meeting);

            const agendaItems = _reject(
                state.agendaItems,
                (ai) =>
                    Boolean(ai.isDraft) === isDraft &&
                    ((isMeetingTemplateItem &&
                        ai.meeting === action.agendaItem.meeting) ||
                        (!isMeetingTemplateItem &&
                            ai.templateId === action.agendaItem.templateId)) &&
                    (isPrimary
                        ? Math.trunc(ai.itemNbr) ===
                          Math.trunc(action.agendaItem.itemNbr)
                        : ai.itemNbr === action.agendaItem.itemNbr)
            );
            return {
                ...state,
                agendaItems: updateAgendaItems(
                    { agendaItems },
                    action.agendaItems
                ),
            };
        }

        case DELETE_AGENDA_ITEM_SUCCESS: {
            const deletedAgendaItems = [
                action.agendaItem?.id,
                ...(action.agendaItems || []).map(({ id }) => id),
            ].filter(Boolean);

            if (!deletedAgendaItems.length) return state;

            return {
                ...state,
                agendaItems: state.agendaItems.filter(
                    ({ id }) => !deletedAgendaItems.includes(id)
                ),
            };
        }

        case WORKSPACE_TEMPLATE_ACCESS_REMOVED:
        case WORKSPACE_ACCESS_REMOVED: {
            // workspace access has been removed, so delete all library template items that belong to this workspace
            const agendaItemIdsToRemove = state.agendaItems
                .filter(
                    (agendaItem) =>
                        agendaItem.workspaceId === action.workspace.id
                )
                .map((agendaItem) => agendaItem.id);

            if (!agendaItemIdsToRemove.length) return state;

            return {
                ...state,
                agendaItems: state.agendaItems.filter(
                    ({ id }) => !agendaItemIdsToRemove.includes(id)
                ),
            };
        }

        case LOGOUT_SUCCESS:
            return initalState;

        default:
            return state;
    }
};
export default agendaReducer;
