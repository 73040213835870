import tw, { css } from 'twin.macro';

const contenteditableStyles = css`
    [contenteditable] {
        ${tw`border-b border-t border-transparent relative`}
    }
    [contenteditable='true']:hover,
    [contenteditable='true']:focus {
        ${tw`outline-none border-line`}
        border-top-color: transparent;
        min-width: 2rem;
    }
`;

export default contenteditableStyles;
