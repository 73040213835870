import _isDate from 'lodash/isDate.js';
import _first from 'lodash/first.js';
import _last from 'lodash/last.js';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import tw, { styled } from 'twin.macro';
import { dateStartOfDay } from '~common/time.utils';
import CalendarStyled, { Nav } from '~modules/common/components/Calendar';
import {
    useUI,
    useUser,
    useDashboardOccurrenceDates,
    useDashboardState,
    useDashboardId,
    useJumpToDate,
} from '~hooks';
import 'react-day-picker/lib/style.css';

const useCalendarProps = ({ onDayClick }) => {
    const user = useUser();
    const jumpToDate = useJumpToDate();
    const { dates } = useDashboardOccurrenceDates();
    const dashboardState = useDashboardState();
    const [dashboardId] = useDashboardId();
    const { closeAllDrawers } = useUI();

    const initialScrollDateValue = () =>
        dashboardState?.lastViewedDate
            ? dashboardState.lastViewedDate.valueOf()
            : dateStartOfDay().valueOf();
    const activeDate = new Date(initialScrollDateValue());
    const today = new Date();
    const date = (_isDate(activeDate) && activeDate) ?? today;
    const disabledDays = (d) => {
        const slug = String(moment(d).startOf('day').toDate());
        const match = dates.find((d) => d.toString() === slug);
        return !match;
    };

    const [month, setMonth] = useState(dashboardState?.jumpToDate);

    useEffect(() => {
        setMonth(dashboardState?.lastViewedDate);
    }, [dashboardState?.lastViewedDate]);

    useEffect(() => {
        if (!dashboardState?.jumpToDate) return;
        setMonth(dashboardState.jumpToDate);
    }, [dashboardState?.jumpToDate]);

    const calendarProps = {
        onDayClick: (date, status) => {
            if (status?.disabled) return;
            jumpToDate(date, dashboardId);
            closeAllDrawers();
            onDayClick?.();
        },
        locale: user.locale,
        formatDate: (d) => moment(d).formatForUser('D MMMM YYYY'),
        disabledDays,
        selectedDays: date,
        defaultMonth: date,
        fromMonth: _first(dates) || today,
        toMonth: _last(dates) || today,
        activeDate: activeDate,
        month,
        showOutsideDays: true,
        navbarElement: (props) =>
            dates.length >= 2 ? <Nav {...props} {...{ dates }} /> : <></>,
        onMonthChange: setMonth,
    };

    return calendarProps;
};

const ButtonWrap = styled.div(({ isDropdown }) => [
    tw`relative flex justify-center mt-2 select-none`,
    !isDropdown &&
        tw`-mb-4 after:(absolute h-px bg-line inset-x-0 top-1/2 content shadow-[0 1px 0 white])`,
]);
const Button = styled.button(({ isDropdown, isActive }) => [
    tw`appearance-none rounded text-sm inline-block px-4 py-1`,
    isDropdown && [
        isActive &&
            tw`text-body-dark hover:bg-line active:(text-solid-blue bg-solid-blue-lightest)`,
        !isActive && tw`text-body-light cursor-not-allowed`,
    ],
    !isDropdown && [
        tw`z-10 bg-solid relative my-1`,
        isActive && tw`text-body-dark hover:text-body-dark active:text-primary`,
        !isActive && tw`text-body-light cursor-not-allowed`,
    ],
]);

const JumpToToday = ({ onDayClick, isDropdown }) => {
    const jumpToDate = useJumpToDate();
    const [dashboardId] = useDashboardId();
    const { closeAllDrawers } = useUI();

    const { dates } = useDashboardOccurrenceDates();
    const isActive = dates.length > 3;

    return (
        <ButtonWrap {...{ isDropdown }}>
            <Button
                type="button"
                onClick={() => {
                    if (!isActive) return;
                    jumpToDate(new Date(), dashboardId);
                    closeAllDrawers();
                    onDayClick?.();
                }}
                {...{ isDropdown, isActive }}
            >
                Go to today
            </Button>
        </ButtonWrap>
    );
};

export default function Calendar({ onDayClick, isDropdown }) {
    const calendarProps = useCalendarProps({ onDayClick, isDropdown });

    return (
        <>
            <div tw="pt-2">
                <CalendarStyled {...calendarProps} />
                <div>
                    <JumpToToday {...{ onDayClick, isDropdown }} />
                </div>
            </div>
        </>
    );
}
