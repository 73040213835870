import React from 'react';

export default ({ title = 'GoTo Webinar', ...rest }): JSX.Element => (
    <svg viewBox="0 0 49 25" xmlns="http://www.w3.org/2000/svg" {...rest}>
        {title && <title>{title}</title>}
        <path
            d="m48.077 14.422-48.077 5.0509 0.57996 5.5156 48.077-5.0509-0.58-5.5156z"
            fill="#FFE900"
        />
        <path d="m23.302 4.0791c-1.0975-1.5e-4 -2.1705 0.32506-3.0831 0.93449-0.9126 0.60944-1.6238 1.4757-2.0438 2.4893s-0.5298 2.1288-0.3155 3.2048c0.2142 1.076 0.7429 2.0642 1.5192 2.8398 0.7762 0.7755 1.7651 1.3036 2.8416 1.5173s2.1922 0.1034 3.2061-0.3168c1.0138-0.4202 1.8801-1.1315 2.4895-2.044 0.6093-0.9124 0.9342-1.985 0.9335-3.0821 6e-4 -0.72823-0.1426-1.4494-0.4212-2.1223-0.2787-0.67287-0.6873-1.2842-1.2026-1.799-0.5153-0.51483-1.127-0.92303-1.8003-1.2013-0.6733-0.27822-1.3949-0.42101-2.1234-0.42018zm0 8.6264c-0.6102 0-1.2066-0.1808-1.714-0.5197-0.5073-0.3388-0.9027-0.8204-1.1362-1.3839s-0.2946-1.1835-0.1756-1.7817 0.4129-1.1476 0.8443-1.5789c0.4315-0.43126 0.9812-0.72496 1.5796-0.84394 0.5985-0.11899 1.2188-0.05792 1.7825 0.17548 0.5637 0.23339 1.0455 0.62864 1.3845 1.1358s0.5199 1.1033 0.5199 1.7132c-8e-4 0.81728-0.3262 1.6009-0.9047 2.1785-0.5784 0.5776-1.3627 0.9021-2.1803 0.9021v0.0031z" />
        <path d="m41.155 4.079c-1.0974 0-2.1701 0.32526-3.0825 0.93466-0.9124 0.60939-1.6236 1.4755-2.0435 2.4889-0.42 1.0134-0.5298 2.1285-0.3158 3.2043 0.2141 1.0758 0.7426 2.064 1.5185 2.8396 0.776 0.7756 1.7646 1.3038 2.8409 1.5178 1.0762 0.214 2.1918 0.1042 3.2056-0.3156 1.0139-0.4197 1.8804-1.1306 2.4901-2.0426 0.6096-0.912 0.935-1.9843 0.935-3.0811 0.0015-0.72871-0.141-1.4505-0.4193-2.124-0.2784-0.67352-0.687-1.2855-1.2025-1.8008-0.5155-0.51528-1.1277-0.92373-1.8015-1.2019s-1.396-0.42065-2.125-0.41919zm0 8.6264c-0.6102 0-1.2066-0.1809-1.714-0.5197-0.5073-0.3388-0.9027-0.8204-1.1362-1.3839s-0.2946-1.1835-0.1756-1.7817 0.4129-1.1476 0.8443-1.5789c0.4315-0.43127 0.9812-0.72496 1.5796-0.84394 0.5985-0.11899 1.2188-0.05792 1.7825 0.17548 0.5637 0.23339 1.0455 0.62864 1.3845 1.1358s0.5199 1.1033 0.5199 1.7132c-8e-4 0.8173-0.3262 1.6008-0.9047 2.1785-0.5784 0.5776-1.3627 0.902-2.1803 0.902v0.0032z" />
        <path d="m37.777 3.7556v-2.4646h-11.096v2.4646h4.3151v11.116h2.4657v-11.116h4.3151z" />
        <path d="m8.3096 6.837v2.4646h3.4078c-0.7871 2.0653-2.2869 3.3999-4.0248 3.3999-0.76025-0.0011-1.5085-0.1897-2.1784-0.5489s-1.241-0.8779-1.6625-1.5104c-0.42149-0.6324-0.68047-1.359-0.75399-2.1153-0.07351-0.75635 0.04069-1.5192 0.33251-2.2209 0.29181-0.7017 0.75223-1.3207 1.3405-1.802s1.2862-0.81037 2.032-0.95779c0.74581-0.14742 1.5165-0.10874 2.2438 0.11261 0.72728 0.22135 1.3887 0.61855 1.9258 1.1564l1.7505-1.7506c-0.991-0.99083-2.2535-1.6658-3.6282-1.9396-1.3746-0.27379-2.7996-0.13413-4.0949 0.40135-1.2953 0.53547-2.4026 1.4427-3.1822 2.6071s-1.1963 2.5336-1.1976 3.9346c-0.003814 0.92955 0.17715 1.8506 0.53239 2.7097 0.35524 0.859 0.87768 1.639 1.537 2.2946 0.65559 0.6608 1.4351 1.186 2.2938 1.5455 0.85878 0.3595 1.78 0.5464 2.7111 0.5498 2.415 0 4.588-1.5545 5.8687-4.0222v3.7257h2.4657v-8.0342h-7.719z" />
    </svg>
);
