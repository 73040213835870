/**
 * User helpers
 * Copied from '@electrivity/minuteme-common' on 10.06.21
 */
import initials from 'initials';
import { getEmailAddressComponents } from '../utils/index.js';

const getUserNameFromEmail = (email = '') =>
    getEmailAddressComponents(email)?.username;

function getUserDisplayName(user = {}, nameIfUnknown = 'Unknown') {
    return (
        user?.personalName ||
        user?.displayName ||
        getUserNameFromEmail(user?.email) ||
        nameIfUnknown
    );
}

const getFirstNameFromDisplayName = (displayName) => {
    // examples:
    //    Briggs, Nicholas (Nick)
    //    Briggs, Nicholas
    //    Nicholas Briggs
    //    Briggs-Whiler, Nicholas
    //    Briggs Whiler, Nicholas
    //    Nicholas Briggs-Whiler
    if (!displayName) return;
    const nameParts = displayName.split(' ');
    if (nameParts.length === 1) return nameParts[0];

    if (displayName.includes(',')) {
        const namePartsByComma = displayName.split(',');
        if (namePartsByComma.length === 1) return namePartsByComma[0];
        const namePartsBySpace = namePartsByComma[1].trim().split(' ');
        return namePartsBySpace[0];
    }
    return nameParts[0];
};

function getUserFirstName(user = {}, nameIfUnknown = 'Unknown') {
    return (
        user?.givenName ||
        getFirstNameFromDisplayName(user?.displayName) ||
        getUserNameFromEmail(user?.email) ||
        nameIfUnknown
    );
}

function getUserInitials(user = {}, length = 2, NameIfUnknown = 'Unknown') {
    return initials(
        user.displayName || getUserDisplayName(user, NameIfUnknown),
        { length }
    );
}

const getEmailDomainPart = (email) => getEmailAddressComponents(email)?.domain;

const __TEST__getFirstNameFromDisplayName = getFirstNameFromDisplayName;

export {
    getUserNameFromEmail,
    getUserDisplayName,
    getUserFirstName,
    getUserInitials,
    getEmailDomainPart,
    __TEST__getFirstNameFromDisplayName,
};
