import React from 'react';
import tw, { styled, css } from 'twin.macro';
import Icon from '~icons';
import { Popup, ColorPicker } from '~modules/common/form-elements';

import { FONT_COLORS } from '~common/constants';

const Trigger = styled.button`
    ${tw`flex text-black bg-transparent self-stretch px-2 text-lg hover:text-primary focus:outline-none`}
    ${({ isSmall }) => isSmall && tw`text-lg`}
    ${({ isOpen }) => isOpen && tw`text-primary`}
    ${({ isWhite }) => isWhite && tw`text-white hover:text-white`}
    ${({ disabled }) =>
        disabled &&
        css`
            svg {
                ${tw`text-icon-disabled cursor-not-allowed opacity-50 outline-none`}
            }
        `}
`;

const PopupTrigger = ({ iconName, highlightColor, ...rest }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const handleClick = (evt) => {
        // Use preventDefault instead of stopPropagation to allow
        // trigger to work while nested within a label in iOS/Safari
        evt.preventDefault();
    };

    return (
        <Trigger type="button" onClick={handleClick} {...rest}>
            <Icon name={iconName} {...{ highlightColor }} />
        </Trigger>
    );
};

const PopupContent = ({
    setIsOpen,
    accentColor,
    onColorChange,
    hasClearButton,
    type,
    colors = FONT_COLORS,
}) => {
    const handleColorChange = ({ hex: accentColor }) => {
        onColorChange({ accentColor });
        setIsOpen(false);
    };

    return (
        <ColorPicker
            color={accentColor}
            onChange={handleColorChange}
            {...{ colors, hasClearButton, type }}
        />
    );
};

const ColorPickerWithIconTrigger = ({
    isWhite = false,
    isSmall,
    iconName,
    styles,
    accentColor,
    isActive,
    onColorChange,
    hasClearButton,
    colors,
    triggerProps,
    type,
    ...rest
}) => (
    <Popup
        trigger={PopupTrigger}
        triggerProps={{
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line unicorn/no-useless-spread
            ...{
                isActive,
                isWhite,
                iconName,
                styles,
                isSmall,
            },
            highlightColor: accentColor,
            ...triggerProps,
        }}
        position="bottom center"
        {...rest}
    >
        {({ setIsOpen }) => (
            <PopupContent
                {...{
                    setIsOpen,
                    accentColor,
                    onColorChange,
                    hasClearButton,
                    iconName,
                    colors,
                    type,
                }}
            />
        )}
    </Popup>
);

export default ColorPickerWithIconTrigger;
