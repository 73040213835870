import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M28.762 25.575h.003c1.22.01 2.22-.986 2.235-2.23V8.03c-.005-.6-.244-1.175-.664-1.595s-.984-.652-1.573-.647H3.235c-.587-.005-1.152.228-1.57.647A2.28 2.28 0 0 0 1 8.027v15.306c.005.6.244 1.175.664 1.595s.984.652 1.573.647h25.525zm-.001.638h0z"
            fill="#fff"
            stroke="#000"
            strokeOpacity=".2"
            strokeWidth=".5"
        />
        <path
            d="M13.45 8.087v-.02h5.106v.02a7.66 7.66 0 0 1 0 15.278v.02H13.45v-.02a7.66 7.66 0 0 1 0-15.278z"
            fill="#0165ac"
        />
        <path
            d="M15.492 20.057c1.832-.65 3.056-2.386 3.056-4.33s-1.224-3.68-3.056-4.33v8.66zm-3.064-8.66c-1.832.65-3.056 2.386-3.056 4.33s1.224 3.68 3.056 4.33v-8.66zm1.532 10.968a6.64 6.64 0 0 1-6.638-6.638 6.64 6.64 0 0 1 6.638-6.638 6.64 6.64 0 0 1 6.638 6.638 6.64 6.64 0 0 1-6.638 6.638z"
            fill="#fff"
        />
    </svg>
);
