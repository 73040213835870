import React from 'react';
import tw, { styled } from 'twin.macro';
import { Checkbox } from '~modules/common/form-elements';

/**
 * Title
 */
const TitleWrap = styled.h3(({ hasNoPaddingBottom, filterIsActive }) => [
    tw`(flex items-center flex-wrap) relative text-sm uppercase font-semibold tracking-wider select-none`,
    !hasNoPaddingBottom && tw`pb-3`,
    filterIsActive &&
        tw`after:(w-2 h-2 rounded-full absolute top-0 -right-2 bg-secondary)`,
]);
export const Title = ({
    children,
    hasNoPaddingBottom = false,
    filterIsActive = false,
}) => (
    <TitleWrap {...{ hasNoPaddingBottom, filterIsActive }}>
        {children}
    </TitleWrap>
);

/**
 * Row: Checkbox
 */
const Label = tw.label`flex cursor-pointer w-full text-body rounded py-2 select-none`;
const Field = styled.span`
    margin-top: 0.255em; // Align to top of text
    ${tw`text-icon`}
    ${Label}:hover &,
    ${Label}:focus & {
        ${tw`text-icon-dark`}
    }
`;
const Name = styled.span`
    ${tw`pl-3`}
    ${Label}:hover &,
    ${Label}:focus & {
        ${tw`pl-3 text-body-dark`}
    }
    ${({ isActive }) => isActive && tw`text-body-dark font-semibold`}
`;
export const RowCheckbox = ({
    id,
    label,
    isChecked,
    handleClick,
    isDisabled = false,
}) => {
    const props = {
        disabled: isDisabled,
        value: id,
        id: id,
        key: id,
        onChange: ({ target }) => handleClick(target),
        // Conditionally add checked prop so we can have an uncontrolled component for Storybook
        // TODO: Improve caching with useCallback
        ...(handleClick && { checked: isChecked }),
    };
    return (
        <Label>
            <Field>
                <Checkbox {...props} small />
            </Field>
            <Name isActive={isChecked}>{label}</Name>
        </Label>
    );
};

const ListItem = styled.li``;

const isIterable = (el) => Symbol.iterator in Object(el);

export const Container = ({ children, ...rest }) => {
    if (!isIterable(children)) return children;

    // Split the title from the children
    const title = children.find((el) => !isIterable(el));
    const listItems = children.filter((el) => el !== title);
    const childMap = (item, index) => <ListItem key={index}>{item}</ListItem>;
    return (
        <div {...rest}>
            {title}
            <ul>{listItems.flat().map(childMap)}</ul>
        </div>
    );
};
