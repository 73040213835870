import React from 'react';

export default ({ title = 'online meeting', iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <rect
            x="1"
            y="8"
            width="21"
            height="17"
            rx="2"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M25 21V12L31 8V25L25 21Z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
