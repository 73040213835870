class NamedError extends Error {
    constructor(message) {
        super(message);
    }

    get name() {
        return this.constructor.name;
    }
}
export class MaxRetriesReachedError extends NamedError {
    constructor(err) {
        super(err);
        this.innerError = err;
    }
}
