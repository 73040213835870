import _filter from 'lodash/filter.js';
import _find from 'lodash/find.js';
import _findIndex from 'lodash/findIndex.js';
import _get from 'lodash/get.js';
import _omit from 'lodash/omit.js';
import _memoize from 'lodash/memoize.js';
import moment from 'moment-timezone';
import ObjectID from 'bson-objectid';
import { theme } from 'twin.macro';
import { createSelector } from 'reselect';
import { getStore } from '~common/store';
import { makeDateTime } from '~common/time.utils';
import {
    getToday,
    getMeetingIdSelector,
    getOccurrenceIdSelector,
} from '~common/selector.helpers';
import {
    getOccurrenceAccessLevel,
    isPendingPermission,
    isAcceptedPermission,
    isRejectedPermission,
} from '~modules/permission/permission.helpers';
import {
    RECURRENCE_SCHEDULED,
    RECURRENCE_NOT_SCHEDULED,
    OCCURRENCE_ROW_COUNT_INITIAL,
    OCCURRENCE_HAS_MORE_BUTTON_POSITION,
} from '~common/constants';
import { getUserSelector } from '~modules/user/user.selectors';
import {
    getMeetingSelector,
    getMinutesStatus,
    getAttendeesAndPermissions,
} from '~modules/meeting/meeting.selectors';
import {
    getMeetingGroupsSelector,
    findGroupForMeeting,
} from '~modules/group/group.selectors';
import {
    getPageStateForMeeting,
    getPageStateForOccurrence,
} from '~modules/pageView/pageView.selectors';
import {
    getOccurrenceSelector,
    getOccurrencesFromStoreByMeeting,
} from '~modules/occurrence/occurrence.pure.selectors';

export const makeGetOccurrencesSelector = () => getOccurrencesSelector;

export const getOccurrencesSelector = createSelector(
    (state) => state.occurrences.occurrences,
    getMeetingIdSelector,
    (occurrences, meeting) =>
        meeting ? _filter(occurrences, { meeting }) : occurrences
);

export function getOccurrenceAttendees(occurrence = {}, meeting = {}) {
    if (occurrence?.attendees?.length) {
        return occurrence.attendees;
    }
    if (!occurrence.userModified || occurrence.useMeetingAttendees) {
        return meeting.attendees;
    }
    if (
        occurrence.recurrence === RECURRENCE_NOT_SCHEDULED &&
        occurrence.useMeetingAttendees
    ) {
        return meeting.attendees;
    }
    return occurrence.attendees;
}

/**
 * Returns an occurrence from the store, by searching the store.
 * @param {Object} [state=getStore().getState()] Redux store. If ommitted, will use the default Redux store.
 * @param {string} id occurrence.id
 */
export function getOccurrenceFromStoreById() {
    let state, id;
    switch (arguments.length) {
        case 2:
            [state, id] = arguments;
            break;
        default:
            [id] = arguments;
            state = getStore().getState();
    }
    return _find(state.occurrences.occurrences, (o) => o.id === id);
}

export const makeGetOccurrenceSelector = () => getOccurrenceSelector;

export const getNonModifiedOccurrencesFromStoreByMeeting = (state, meeting) => {
    // TODO: Convert next line to use "getId()"
    const id = typeof meeting === 'string' ? meeting : meeting.id;
    return _filter(state.occurrences.occurrences, {
        meeting: id,
        userModified: false,
    });
};

/**
 * Find the previous non-archived occurrence of a meeting, from a specific date.
 * @param {Object} state Redux store.
 * @param {(Object|string)} meeting Meeting object or meeting.id.
 * @param {date} [date = new Date()] Base date, from which to find the previous occurrence.
 */
export const getPreviousOccurrenceFromStoreByDate = (
    state,
    meeting = {},
    date = new Date()
) => {
    const occurrences = getOccurrencesFromStoreByMeeting(state, meeting)
        .filter((o) => !o.archived)
        .sort(
            (a, b) =>
                b.startDate.valueOf() - a.startDate.valueOf() ||
                new Date(b.createdAt) - new Date(a.createdAt)
        );

    const previous = _find(
        occurrences,
        (o) => o.startDate.valueOf() < date.valueOf()
    );
    return previous;
};

export const mapOccurrenceForListDisplay = ({
    occurrence,
    meeting,
    group,
    myAccessLevel,
}) => {
    const { isNotStarted } = getMinutesStatus(occurrence.status);

    return {
        ...myAccessLevel,
        ...occurrence,
        meetingTitle: meeting?.title,
        isArchived: occurrence.archived || occurrence.meetingMeta?.archived,
        groupTitle: group?.title,
        isRecurring: occurrence.recurrence === RECURRENCE_SCHEDULED,
        accentColor: meeting?.isIntro
            ? theme`colors.solid.bright`
            : group?.accentColor,
        recurrenceText: meeting?.recurrenceRuleText,
        attendees: getOccurrenceAttendees(occurrence, meeting),
        isNotStarted,
    };
};

const groupOccurrencesByPeriod = createSelector(
    getOccurrencesSelector,
    getMeetingSelector,
    getToday,
    getUserSelector,
    getMeetingGroupsSelector,
    (state) => state.permission,
    (occurrences, meeting, today, user, groups, permissions) => {
        // Using getToday is required to ensure the list of occurrences in each group will
        // be updated appropriately if the day changes - e.g. if the user has left their browser on overnight.
        const occurrencesToday = [],
            occurrencesUpcoming = [],
            occurrencesPast = [];
        const dayStart = moment().tz(user.timezone).startOf('day').toDate();
        const dayEnd = moment().tz(user.timezone).endOf('day').toDate();

        const group = findGroupForMeeting(groups, { meeting });

        [...occurrences]
            .sort(
                (a, b) =>
                    b.startDate.valueOf() - a.startDate.valueOf() ||
                    new Date(b.createdAt) - new Date(a.createdAt)
            )
            .forEach((occurrence) => {
                const myAccessLevel = getOccurrenceAccessLevel(
                    permissions,
                    user,
                    occurrence,
                    occurrence.id
                );
                if (occurrence.startDate.toDate() < dayStart) {
                    occurrencesPast.push(
                        mapOccurrenceForListDisplay({
                            occurrence,
                            meeting,
                            group,
                            myAccessLevel,
                        })
                    );
                } else if (occurrence.startDate.toDate() > dayEnd) {
                    // we want to show the most recent upcoming occurrences first, but to figure out which items to show we need to create the list in reverse and then sort later.
                    occurrencesUpcoming.unshift(
                        mapOccurrenceForListDisplay({
                            occurrence,
                            meeting,
                            group,
                            myAccessLevel,
                        })
                    );
                } else {
                    occurrencesToday.push(
                        mapOccurrenceForListDisplay({
                            occurrence,
                            meeting,
                            group,
                            myAccessLevel,
                        })
                    );
                }
            });

        return { occurrencesUpcoming, occurrencesToday, occurrencesPast };
    }
);

export const getOccurrenceSidebarList = createSelector(
    groupOccurrencesByPeriod,
    getOccurrenceIdSelector,
    getPageStateForMeeting,
    (occurrencesByPeriod, activeOccurrenceId, pageState = {}) => {
        const filtered = {};

        const showArchivedUpcoming =
            pageState[`occurrencesUpcomingShowArchived`];
        const hasOccurrencesUpcoming = showArchivedUpcoming
            ? Boolean(occurrencesByPeriod.occurrencesUpcoming.length)
            : Boolean(
                  occurrencesByPeriod.occurrencesUpcoming.filter(
                      (occurrence) => !occurrence.isArchived
                  ).length
              );

        for (const [groupType, groupOccurrences] of Object.entries(
            occurrencesByPeriod
        )) {
            // filter out archived occurrences if necessary
            const showArchived = pageState[`${groupType}ShowArchived`];
            const occurrences = groupOccurrences.filter(
                (o) => showArchived || !o.archived
            );

            // is the active occurrence (based on query string) in this group
            const indexOfActiveOccurrence = _findIndex(occurrences, {
                id: activeOccurrenceId,
            });

            const initialRowCount =
                OCCURRENCE_ROW_COUNT_INITIAL[groupType] || 0;
            const rowCountFromPageState = Number(
                pageState[`${groupType}RowCount`] || 0
            );
            const isShowingDefaultRowCount =
                rowCountFromPageState === initialRowCount;

            let maxRows,
                occurrencesUpNext,
                startSliceFrom = 0;

            if (groupType === 'occurrencesUpcoming' && hasOccurrencesUpcoming) {
                // take the first one and put it into the Up next category.
                occurrencesUpNext = {
                    hasMoreRows:
                        isShowingDefaultRowCount &&
                        occurrences.length > 1 &&
                        indexOfActiveOccurrence <= 0,
                    items: [occurrences[0]],
                    hasMoreLocation: 'top',
                    showMoreGroupType: 'occurrencesUpcoming',
                };
                startSliceFrom = 1;
            }

            if (initialRowCount >= 0) {
                // if initialRowCount is undefined, show all rows. Otherwise slice the list.

                maxRows = Math.max(
                    initialRowCount,
                    rowCountFromPageState + startSliceFrom
                );
                // find out if the activeOccurrenceId is in the current list.
                // find out if it's "out of range" according to the number of rows displayed.
                // if it's out of range, extend the range so it *is* included.
                // if it's in the upcoming category, this is sorted ascending, so it means it's row number > maxRows.
                // if it's in the past category, this is sorted decending, so its row number > maxRows.
                // NOTE we do not reset the RowCount. Implication of this is that if they click on the previous occurrence, then "this one" will disappear,
                // because it will now be excluded from the list due to being out of bounds. Maybe not a problem, because edge case?

                if (indexOfActiveOccurrence + 1 > maxRows) {
                    maxRows = indexOfActiveOccurrence + 1;
                }
            }

            const items = occurrences.slice(startSliceFrom, maxRows);
            groupType === 'occurrencesUpcoming' && items.reverse();

            filtered[groupType] = {
                hasMoreRows: maxRows < occurrences.length,
                showingMoreRows:
                    indexOfActiveOccurrence + 1 !== maxRows &&
                    maxRows > initialRowCount,
                items,
                hasMoreLocation: OCCURRENCE_HAS_MORE_BUTTON_POSITION[groupType],
                showMoreGroupType: groupType,
            };

            if (occurrencesUpNext) {
                filtered['occurrencesUpNext'] = occurrencesUpNext;
            }
        }

        return filtered;
    }
);

export function clearCacheOccurrenceAddEditFormInitialValues() {
    getOccurrenceAddEditFormInitialValues.cache.clear();
}

export const getOccurrenceAddEditFormInitialValues = _memoize(
    (occurrenceId, user, meeting, occurrence, group, date) => {
        let recurrenceDetail;
        const { startDate, endDate, timezone } = occurrence;

        if (occurrence?.id || occurrence?.startDate) {
            const startDateInMeetingTZ = startDate.clone().tz(timezone);
            const endDateInMeetingTZ = endDate.clone().tz(timezone);

            recurrenceDetail = {
                startDate: startDateInMeetingTZ,
                startTime: startDateInMeetingTZ.format('HH:mm'),
                endDate: endDateInMeetingTZ,
                endTime: endDateInMeetingTZ.format('HH:mm'),
                timezone,
            };
        } else {
            const { timezone } = user;
            const start = makeDateTime(
                moment(date).tz(timezone),
                moment.tz(timezone).format('HH:mm')
            );
            if (start.minutes() < 30) {
                start.startOf('hour').add(30, 'minutes');
            } else {
                start.startOf('hour').add(1, 'hour');
            }

            const end = start.clone().add(1, 'hour');
            recurrenceDetail = {
                startDate: start,
                startTime: start.formatForUser('HH:mm'),
                endDate: end,
                endTime: end.formatForUser('HH:mm'),
                timezone,
            };
        }
        const startDateActual = occurrence.startDateActual
            ? occurrence.startDateActual.clone()
            : occurrence.startDate.clone();

        const endDateActual = occurrence.endDateActual
            ? occurrence.endDateActual.clone()
            : occurrence.endDate.clone();

        // GOTCHA: occurrence location or locationOnline may be blank, so don't overwrite with meeting location
        const initialValues = {
            meeting: _get(meeting, 'id'),
            title: _get(meeting, 'title'),
            useMeetingAttendees: !!meeting.id,
            useMeetingAgenda: !!meeting.id,
            displayActionItemComments:
                meeting?.displayActionItemComments ?? true,
            previousActionItemReview: !!meeting.id,
            location: occurrence.id
                ? occurrence.location
                : _get(meeting, 'location'),
            locationOnline: occurrence.id
                ? occurrence.locationOnline
                : _get(meeting, 'locationOnline'),
            createdSourceLocation: meeting?.createdSourceLocation,
            createdModal: meeting?.createdModal,
            createdAuthSource: meeting?.createdAuthSource,
            meetingType: meeting?.meetingType,
            ..._omit(occurrence, ['timezone, startDate, endDate']),
            ...(ObjectID.isValid(group?.id) ? { groupId: group.id } : {}),
            recurrenceDetail,
            startDateActual,
            endDateActual,
            fileLink: meeting?.fileLink,
            savePDFToCloud: meeting?.savePDFToCloud,
            group: ObjectID.isValid(group?.id) ? group : undefined,
            newUsers: meeting.newUsers || [], // for transfer from the Simple modal when grouping with an existing
        };
        return initialValues;
    },
    (occurrenceId, user, meeting, occurrence, group, date) =>
        `${occurrenceId}.${_get(meeting, 'id')}${
            _get(group, 'id') ? `.${group.id}` : ''
        }${date ? `.${date.toString()}` : ''}`
);

/**
 * Get the attendees and permissions for an occurrence from the store. This is memoized for the occurrence.id.
 * If there is no meeting.id passed in, this is in Add mode for a One-off.
 * If you are looking for the non-memoized version, use getAttendeesAndPermissions.
 * @param {Object} state Redux store.
 * @param {Object} props
 * @param {(Object|string)} props.occurrence Occurrence object or occurrence.id.
 * @param {(Object|string)} props.meeting Meeting object or meeting.id.
 */
export const getAttendeesAndPermissionsSelector = createSelector(
    getOccurrenceSelector,
    getMeetingSelector,
    getPageStateForOccurrence,
    getPageStateForMeeting,
    getUserSelector,
    (
        occurrence,
        meeting,
        occurrencePageState,
        meetingPageState,
        loggedInUser
    ) =>
        !meeting.id || (occurrence.id && !occurrence.useMeetingAttendees)
            ? getAttendeesAndPermissions(
                  loggedInUser,
                  occurrence,
                  occurrencePageState?.permissions || []
              )
            : getAttendeesAndPermissions(
                  loggedInUser,
                  meeting,
                  meetingPageState?.permissions || []
              )
                  .filter(
                      // Copy forward the permissions that are pending, accepted and rejected only. Because these are people most likely to [still] be attending the meeting.
                      // But exclude left and revoked permissions so these users are not copied into a new occurrence, as they are likely no longer attending the meeting.
                      (p) =>
                          [
                              isPendingPermission(p),
                              isAcceptedPermission(p),
                              isRejectedPermission(p),
                          ].some(Boolean)
                  )
                  .map((p) => ({
                      ...p,
                      id: null,
                  }))
);

export const getOccurrencesForQuickSwitcherFromStore = createSelector(
    (state) => state.occurrences.occurrences,
    getMeetingGroupsSelector,
    (occurrences, groups) => {
        const quickSwitchOccurrences = occurrences
            .map((occurrence) => {
                if (occurrence?.archived || occurrence?.meetingMeta?.archived)
                    return;
                const group = findGroupForMeeting(groups, { occurrence });
                return {
                    ...occurrence,
                    accentColor: group.accentColor,
                };
            })
            .filter(Boolean)
            .sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf());
        return quickSwitchOccurrences;
    }
);
