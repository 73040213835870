import { pauseUpdates, resumeUpdates } from '~common/store';
import {
    MODAL_ADD_REMOTE_CALENDAR_MEETING_OPEN,
    MODAL_ADD_REMOTE_CALENDAR_MEETING_CLOSE,
    REMOTE_CALENDAR_EVENTS_LOADING,
    REMOTE_CALENDAR_EVENTS_LOADED,
    REMOTE_CALENDAR_EVENTS_CLEAR,
    INTEGRATION_CALENDAR_LINKS_REQUEST,
    INTEGRATION_CALENDAR_LINKS_LOADED,
    NOOP,
} from '~common/action.types';
import {
    getRemoteCalendarMeetings,
    getRemoteCalendarLinks,
    linkNewMeeting as linkNewMeetingApi,
    linkExistingMeeting as linkExistingMeetingApi,
    linkNewOccurrence as linkNewOccurrenceApi,
    linkExistingOccurrence as linkExistingOccurrenceApi,
    removeRemoteCalendarLink as removeRemoteCalendarLinkApi,
    hideRemoteCalendar as hideRemoteCalendarApi,
    getEventWithSeriesFromRemote as getEventWithSeriesFromRemoteApi,
} from './remoteCalendar.api.js';
import {
    getActiveRemoteCalendarIntegrationProfiles,
    getIntegrationProfileByAuthSource,
} from '~modules/integration/integration.selectors';
import { getIntegrationMeta } from '~modules/integration/integration.helpers';
import { displayError, displaySuccess } from '~modules/alert/alert.helpers';
import { formSubmissionServerError } from '~components/formvalidation/formvalidation.helper';
import { processBodyErrors } from '~components/formvalidation/meeting.formvalidation';
import {
    loadMeetingPermissions,
    processAddedMeeting,
    processUpdatedMeeting,
} from '~modules/meeting/meeting.actions';
import {
    loadOccurrencePermissions,
    processAddedOccurrence,
} from '~modules/occurrence/occurrence.actions';
import { HideRemoteCalendarCtaMessage } from '~modules/remoteCalendar/components/ConnectionModalHelpers';
import {
    getCalendarMatches,
    getEventsFromRemoteCalendars,
    getRemoteCalendarEventForModal,
} from './remoteCalendar.selectors.js';
import { openSimpleAddMeetingModal } from '~modules/modals/simpleAddMeeting/simpleAddMeeting.actions';
import { MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD } from '~shared/constants';
import airbrake from '~common/airbrake';
import { isRepeatingMeeting } from '~modules/meeting/meeting.helpers.js';
import { loadingModal } from '~modules/modals/loadingModal.actions';

export const closeAddRemoteCalendarMeetingModal = () => ({
    type: MODAL_ADD_REMOTE_CALENDAR_MEETING_CLOSE,
});

export const openAddRemoteCalendarMeetingModal =
    ({ eventId, seriesMasterId, recurrence, calendarId, calendarType }) =>
    async (dispatch, getState) => {
        // now we decide if we want to open the Simple modal (no match with existing), or the Event modal (match with existing)
        const state = getState();

        let eventRaw;
        const profile = getIntegrationProfileByAuthSource(state, calendarType);

        // Try/Finally is here to make sure the loadingModal gets closed even if there's an error in here.
        try {
            if (isRepeatingMeeting({ recurrence })) {
                // the detail of the series is not retrieved from the remote calendar when logging in,
                // because this has a huge performance hit. So now the user is trying to link this event,
                // we retrieve the full details of the series.
                loadingModal.open('Retrieving remote calendar event…');

                eventRaw = await getEventWithSeriesFromRemoteApi(
                    profile.id,
                    eventId,
                    seriesMasterId,
                    calendarId
                );
            } else {
                const events = getEventsFromRemoteCalendars(state);
                eventRaw = events.find((event) => event.id === eventId);
            }
            const { event, series } = getRemoteCalendarEventForModal(state, {
                eventRaw,
                profile,
            });
            const calendarMatches = getCalendarMatches(state, {
                calendarData: event,
            });

            if (calendarMatches.length === 0)
                return dispatch(
                    openSimpleAddMeetingModal({
                        // NOTE: profile, event and series are required for the submit, to ensure the remoteCalendar event functionality links up the new meeting/occurrence with the remote calendar.
                        eventData: { profile, event, series },
                        createdSourceLocation:
                            MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD,
                    })
                );

            // if there are any matches, make sure the permissions are loaded into pageState for each match
            const pageStates = state.pageView.pageStates;
            const permissionsToLoad = [];
            calendarMatches.forEach((match) => {
                !pageStates.some(({ id }) => id === match.occurrence.id) &&
                    permissionsToLoad.push(
                        dispatch(loadOccurrencePermissions(match.occurrence))
                    );

                !pageStates.some(({ id }) => id === match.meeting.id) &&
                    permissionsToLoad.push(
                        dispatch(loadMeetingPermissions(match.meeting))
                    );
            });

            if (permissionsToLoad.length > 0)
                loadingModal.open(
                    'Retrieving permissions for existing meeting…'
                );
            await Promise.all(permissionsToLoad);

            dispatch({
                type: MODAL_ADD_REMOTE_CALENDAR_MEETING_OPEN,
                profile,
                event,
                series,
                calendarMatches,
            });
        } finally {
            loadingModal.close();
        }
    };

/**
 *
 * @param {Object} [profile] A calendar integration profile for a user, for which to load calendar events for. If not provided, will load for all active calendar profiles
 * @param {Object} [options]
 * @param  {Boolean} [options.dispatchImmediately = false] determine whether to dispatch the api result immediately, or instead return a redux action that can be dispatched later
 * @returns {undefined|Object[]} an array of redux actions that can be dispatched, or undefined if it was dispatched immediately
 */
export const loadRemoteCalendarEvents =
    (profile, { dispatchImmediately = false } = {}) =>
    async (dispatch, getState) => {
        // if a profile is passed in, load remote calendar events for that profile.
        // If no profile is passed in, load remote calendar events for all active calendar profiles
        const activeRemoteCalendarIntegrationProfiles = profile
            ? [profile]
            : getActiveRemoteCalendarIntegrationProfiles(getState());

        if (!activeRemoteCalendarIntegrationProfiles.length) {
            return dispatchImmediately ? undefined : { type: NOOP }; // send back a dummy action so redux doesn't break
        }

        const resultsList = await Promise.allSettled(
            activeRemoteCalendarIntegrationProfiles.map(
                (integrationProfile) => {
                    dispatch({
                        type: REMOTE_CALENDAR_EVENTS_LOADING,
                        authSource: integrationProfile.authSource,
                    });

                    return getRemoteCalendarMeetings(integrationProfile.id);
                }
            )
        );

        const result = resultsList.map(
            ({ status, reason: error, value: calendarData }, idx) => {
                const authSource =
                    activeRemoteCalendarIntegrationProfiles[idx].authSource;
                if (status === 'rejected') {
                    const title = getIntegrationMeta({
                        authSource,
                    })?.title;
                    if (!title) {
                        airbrake.notify({
                            error,
                            params: {
                                activeRemoteCalendarIntegrationProfiles,
                                idx,
                                authSource,
                            },
                        });
                    }

                    displayError({
                        title: `${
                            title || 'Calendar'
                        } events could not be loaded`,
                        error,
                    });

                    const action = {
                        type: REMOTE_CALENDAR_EVENTS_LOADED,
                        authSource,
                        events: [],
                        users: [],
                    };
                    return dispatchImmediately ? dispatch(action) : action;
                }

                const action = {
                    type: REMOTE_CALENDAR_EVENTS_LOADED,
                    authSource,
                    events: calendarData.events,
                    users: calendarData.users,
                };
                return dispatchImmediately ? dispatch(action) : action;
            }
        );

        return dispatchImmediately ? undefined : result;
    };

/**
 *
 * @param {Object} [options]
 * @param  {Boolean} [options.dispatchImmediately = false] determine whether to dispatch the api result immediately, or instead return a redux action that can be dispatched later
 * @returns {undefined|Object} a redux action that can be dispatched, or undefined if it was dispatched immediately
 */
export const loadRemoteCalendarLinks =
    ({ dispatchImmediately = false } = {}) =>
    async (dispatch, getState) => {
        try {
            const activeRemoteCalendarIntegrationProfiles =
                getActiveRemoteCalendarIntegrationProfiles(getState());
            if (!activeRemoteCalendarIntegrationProfiles.length) {
                return dispatchImmediately ? undefined : { type: NOOP }; // send back a dummy action so redux doesn't break
            }

            dispatch({
                type: INTEGRATION_CALENDAR_LINKS_REQUEST,
            });

            const links = await getRemoteCalendarLinks();

            const action = {
                type: INTEGRATION_CALENDAR_LINKS_LOADED,
                links,
            };

            return dispatchImmediately
                ? links.length && dispatch(action)
                : action;
        } catch {
            return displayError({
                title: `Remote calendar links could not be loaded`,
                description: `Please reload your browser.`,
            });
        }
    };

export const resetRemoteCalendarEvents = (authSource, calendar) => (dispatch) =>
    dispatch({
        type: REMOTE_CALENDAR_EVENTS_CLEAR,
        authSource,
        calendar,
    });

export const removeRemoteCalendarLink =
    ({ remoteLink, occurrence }) =>
    async () => {
        try {
            await removeRemoteCalendarLinkApi(
                remoteLink.profileId,
                occurrence.id
            );

            return displaySuccess({
                title: `“${occurrence.title}” has been unlinked from “${
                    getIntegrationMeta(remoteLink.authSource).shortTitle
                }”`,
            });
        } catch (error) {
            return displayError({
                title: `“${occurrence.title}” could not be unlinked from “${
                    getIntegrationMeta(remoteLink.authSource).shortTitle
                }”`,
                error,
            });
        }
    };

export const linkExistingMeeting =
    ({
        profile,
        meeting: params,
        occurrence,
        series,
        event,
        keepSynchronised,
    }) =>
    async (dispatch) => {
        // there is a match on an occurrence (start date / end date)
        // and user has selected the meeting from the "Link with existing series"
        // we might have some updated permissions and location
        // because there already is a match on start date / end date, when we do the "sync" it will match up.
        try {
            pauseUpdates();

            const updatedMeeting = await linkExistingMeetingApi(
                profile.id,
                params.id,
                {
                    meeting: params,
                    series,
                    event,
                    keepSynchronised,
                }
            );

            const { meeting, occurrences } = dispatch(
                processUpdatedMeeting(updatedMeeting)
            );

            // during linking, the remote schedule will be applied to the existing meeting, which may result
            // in existing occurrences being deleted and recreated - including the one the user clicked on to link up with.
            const occurrenceStillExistsAfterLinking =
                occurrence?.id &&
                occurrences.find(({ id }) => id === occurrence.id);

            // linking with an existing meeting may result in new occurrences being created, if they are being added on to an existing series.
            // occurrences should have been received via websocket before the response above.
            let navigateToOccurrenceId = occurrence?.id;
            if (!navigateToOccurrenceId || !occurrenceStillExistsAfterLinking) {
                const occurrence = occurrences.find(
                    (occurrence) =>
                        occurrence.startDate.valueOf() ===
                            event.startDate.valueOf() &&
                        occurrence.endDate.valueOf() === event.endDate.valueOf()
                );
                navigateToOccurrenceId = occurrence?.id;
            }

            resumeUpdates();

            return {
                meeting,
                navigateToOccurrenceId,
            };
        } catch (err) {
            resumeUpdates();
            const newError = await formSubmissionServerError(
                err,
                processBodyErrors
            );
            return newError;
        }
    };

export const linkExistingOccurrence =
    ({ profile, occurrence: params, event, keepSynchronised }) =>
    async () => {
        // there is a match on an occurrence (start date/ end date)
        // it's a one-off meeting in the remote calendar
        // and user has selected the meeting from the "Link with existing series"
        // we might have some updated permissions and location
        // because there is already a match on start date / end date, when we do the "sync" it will match up.
        try {
            pauseUpdates();
            const updatedOccurrence = await linkExistingOccurrenceApi(
                profile.id,
                params.id,
                {
                    occurrence: params,
                    event,
                    keepSynchronised,
                }
            );

            resumeUpdates();

            return {
                occurrence: updatedOccurrence,
                navigateToOccurrenceId: updatedOccurrence.id,
            };
        } catch (err) {
            resumeUpdates();
            const newError = await formSubmissionServerError(
                err,
                processBodyErrors
            );
            return newError;
        }
    };

export const linkNewOccurrence =
    ({ profile, meeting, occurrence: params, event, keepSynchronised }) =>
    async (dispatch) => {
        // there is a one-off in the remote calendar.
        // create a new occurrence in MinuteMe, based on the data in remote calendar
        // we already have data from remote calendar, so we won't need to get any more data.
        // POST: /api/integration/:profile.id/calendar/link/meeting/:meetingId/occurrence
        try {
            pauseUpdates();

            const addedOccurrence = await linkNewOccurrenceApi(
                profile.id,
                meeting.id,
                {
                    occurrence: params,
                    event,
                    keepSynchronised,
                }
            );

            const occurrence = dispatch(
                processAddedOccurrence(params, addedOccurrence)
            );

            resumeUpdates();

            return {
                occurrence,
                navigateToOccurrenceId: occurrence.id,
            };
        } catch (err) {
            resumeUpdates();
            const newError = await formSubmissionServerError(
                err,
                processBodyErrors
            );
            return newError;
        }
    };

export const linkNewMeeting =
    ({
        profile,
        workspace,
        meeting: params,
        series,
        event,
        keepSynchronised,
    }) =>
    async (dispatch) => {
        // use this for one-off and scheduled??
        // a one-off with a single occurrence, or a new recurring series
        // we have the series from remote... so we could use this to create the series, and then sync-up after.
        // so really, we need to pass series, attendees, permissions, occurrenceId to redirect them to??
        // POST: /api/integration/:profile.id/calendar/link/:workspaceId/meeting
        // this is being called from a final-form submit, so if there is an error it must be returned in final form error format
        try {
            pauseUpdates();
            const addedMeeting = await linkNewMeetingApi(
                profile.id,
                workspace,
                {
                    event,
                    meeting: params,
                    series,
                    keepSynchronised,
                }
            );

            const {
                meeting,
                group,
                occurrences,
                permissions,
                navigateToOccurrenceId,
            } = dispatch(processAddedMeeting(params, addedMeeting));

            // determine which occurrenceId to navigate to
            let navigateToId = navigateToOccurrenceId;
            if (event?.startDate && occurrences?.length) {
                const goToOccurrence = occurrences.find(
                    (occurrence) =>
                        occurrence.startDate.valueOf() ===
                        event.startDate.valueOf()
                );
                navigateToId = goToOccurrence?.id || navigateToOccurrenceId;
            }

            resumeUpdates();

            return {
                meeting,
                group,
                permissions,
                navigateToOccurrenceId: navigateToId,
            };
        } catch (err) {
            resumeUpdates();
            const newError = await formSubmissionServerError(
                err,
                processBodyErrors
            );
            return newError;
        }
    };

export const hideRemoteCalendarCta = (authSource) => async () => {
    const integrationMeta = getIntegrationMeta(authSource);
    if (!integrationMeta) return;

    try {
        await hideRemoteCalendarApi(authSource);

        return displaySuccess({
            title: `${integrationMeta.title} integration has been hidden`,
            message: <HideRemoteCalendarCtaMessage />,
        });
    } catch (error) {
        return displayError({
            title: `${integrationMeta.title} integration could not be hidden`,
            error,
        });
    }
};
