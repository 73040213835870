import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m21.6 30h-19.6v-3.2667c-1e-5 -0.4098 0.34782-0.5799 0.70814-0.8038 3.2118-1.9962 9.0919-4.3296 9.0919-4.3296s0.5558-0.4339 0.5665-0.9333c0.0067-0.316-0.0086-0.6582-0.0765-0.9333-0.3423-1.3855-1.7487-1.543-2.45-2.8-0.56788-1.0178-0.7028-1.678-0.98-2.8-0.70125-2.8384-1.09-4.7418 0-7.4666 0.30465-0.76163 0.47041-1.2119 0.98-1.8667 1.075-1.3812 3.22-2.8 6.16-2.8s5.085 1.4188 6.16 2.8c0.5096 0.65477 0.6753 1.105 0.98 1.8667 1.09 2.7249 0.7012 4.6282 0 7.4666-0.2772 1.122-0.4121 1.7822-0.98 2.8-0.7013 1.257-2.1077 1.4145-2.45 2.8-0.0679 0.2751-0.0832 0.6173-0.0765 0.9333 0.0107 0.4994 0.5665 0.9333 0.5665 0.9333s5.8801 2.3334 9.0919 4.3296c0.3603 0.2239 0.7081 0.394 0.7081 0.8038v3.2667h-8.4z"
            fill="currentColor"
        />
    </svg>
);
