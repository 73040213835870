// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import { ACTION_ITEM_REVIEW } from '../constants.js';
import { isPrimaryItemNbr } from '~shared/utils';
export const getTargetDetails = (monitor) => {
    const startElemPos = monitor.getInitialSourceClientOffset();
    const currentElemOffset = monitor.getSourceClientOffset();

    let toplevel;
    if (currentElemOffset.x < startElemPos.x - 25) {
        toplevel = true;
    } else if (currentElemOffset.x > startElemPos.x + 25) {
        toplevel = false;
    }

    return { toplevel };
};

export function renumberTopicList(topicList, movedItemId, toplevel) {
    const affected = _.cloneDeep(topicList);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-array-find
    const filtered = topicList.filter((ai) => ai.id !== ACTION_ITEM_REVIEW);

    let prevItemNbr = 0;

    for (const ai of affected) {
        if (ai.id !== ACTION_ITEM_REVIEW) {
            const isMovedItem = ai.id === movedItemId;
            const isFirstItem = ai.id === filtered[0].id;

            let isPrimaryItem = isPrimaryItemNbr(ai.itemNbr);

            if (isMovedItem) {
                isPrimaryItem = toplevel;
            }

            if (isFirstItem) {
                isPrimaryItem = true;
            }

            if (isPrimaryItem) {
                prevItemNbr = Number(
                    Number.parseInt(prevItemNbr) + 1
                ).toLocaleString();
            } else {
                const [first = 0, second = 0] = prevItemNbr.split('.');
                prevItemNbr = `${first}.${Number(second || 0) + 1}`;
            }

            ai.itemNbr = prevItemNbr;
        }
    }

    return affected;
}

export {
    isPrimaryItemNbr,
    newItemNbr,
    sortByItemNbr,
    getItemNbrParts,
} from '~shared/utils';
