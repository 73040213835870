import React from 'react';
import tw, { styled } from 'twin.macro';
import Icon from '~icons';

const TickBoxContainer = tw.div`flex items-center justify-center cursor-pointer`;

const HiddenCheckbox = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
`;

const IconWrap = styled.div`
    ${tw`flex items-center justify-center`}
    ${({ hasBorder }) => hasBorder && tw`border-current border-2 rounded`}
  &:hover,
  &:focus {
        ${tw`shadow-sm outline-none`}
    }
    ${({ disabled }) =>
        disabled &&
        tw`border-icon-disabled text-icon-disabled hover:text-icon-disabled cursor-not-allowed`}
    ${({ small, type }) => !small && type === 'checkbox' && tw`w-5 h-5 text-xs`}
  ${({ small, type }) => small && type === 'checkbox' && tw`w-4 h-4 text-xs`}
  ${({ small, type }) => !small && type === 'radio' && tw`w-6 h-6 text-xs`}
  ${({ small, type }) => small && type === 'radio' && tw`w-5 h-5 text-xs`}
  ${({ round }) => round && tw`rounded-full`}
`;

const StyledIcon = styled(Icon)`
    ${HiddenCheckbox}:not(:checked) + ${IconWrap} & {
        ${tw`hidden pointer-events-none`}
    }
`;

export default ({
    disabled,
    small,
    round,
    type,
    hasBorder = true,
    propsIcon,
    hasUncheckedStatus = false,
    ...rest
}) => (
    <TickBoxContainer>
        <HiddenCheckbox
            {...rest}
            {...{ type }}
            {...(disabled && { disabled: true })}
        />
        <IconWrap {...{ disabled, type, small, round, hasBorder }}>
            <StyledIcon
                name={hasUncheckedStatus ? 'close' : 'tick'}
                iconThick
                tw="pointer-events-none"
                {...propsIcon}
            />
        </IconWrap>
    </TickBoxContainer>
);
