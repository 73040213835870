import {
    PRELOAD_REQUEST,
    PRELOAD_SUCCESS,
    PRELOAD_ERROR,
    PRELOAD_COMPLETE,
    FETCH_USERPROFILE_SUCCESS,
    LOGOUT_SUCCESS,
    FETCH_ACTION_ITEMS_SUCCESS,
    FETCH_LINKED_ACTION_ITEMS_SUCCESS,
    FETCH_DOCUMENT_LIST_SUCCESS,
    FETCH_MINUTES_SUCCESS,
    PRELOAD_SWAP_LOAD_STATUS_MEETING,
    FETCH_AGENDA_ITEMS_SUCCESS,
    FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS,
    FETCH_ALL_MY_MEETING_USERS_SUCCESS,
    FETCH_USERPERMISSIONS_SUCCESS,
    FETCH_MEETINGS_SUCCESS,
    FETCH_GROUPS_SUCCESS,
    FETCH_SCHEDULED_EVENTS_SUCCESS,
} from '../../common/action.types.js';

const initialState = {
    isLoading: false,
    percent: 0,
    errored: [],
    succeeded: [],
    fullyLoaded: new Set(),
};

const preloadReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTION_ITEMS_SUCCESS:
        case FETCH_LINKED_ACTION_ITEMS_SUCCESS:
        case FETCH_DOCUMENT_LIST_SUCCESS:
        case FETCH_MINUTES_SUCCESS:
        case FETCH_AGENDA_ITEMS_SUCCESS:
        case FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS:
        case FETCH_ALL_MY_MEETING_USERS_SUCCESS:
        case FETCH_USERPERMISSIONS_SUCCESS:
        case FETCH_MEETINGS_SUCCESS:
        case FETCH_GROUPS_SUCCESS:
        case FETCH_SCHEDULED_EVENTS_SUCCESS: {
            if (action?.page?.page?.page !== action?.page?.page?.pages)
                return state;

            const fullyLoaded = new Set(state.fullyLoaded);
            // if preload is on a specific occurrence, the extraParams will be populated with the occurrenceId
            // we want to capture if the preload for that meeting has completed as opposed to preload for all other meetings.
            // NOTE: after the preload for the meeting has completed, this id gets swapped to the meetingId
            // so that if the user changes to another occurrence in that series, it shows that the data is loaded.
            const type = action?.extraParams?.id
                ? `${action.type}-${action.extraParams.id}`
                : action.type;
            fullyLoaded.add(type);
            return {
                ...state,
                fullyLoaded,
            };
        }
        case FETCH_USERPROFILE_SUCCESS: {
            return {
                ...state,
                preloadRequestId: action.requestId,
            };
        }
        case PRELOAD_REQUEST:
            return {
                ...state,
                isLoading: true,
                percent: action.percent || state.percent,
            };

        case PRELOAD_SUCCESS: {
            return {
                ...state,
                percent: state.percent + action.percent,
                succeeded: [...state.succeeded, action.functionName],
            };
        }
        case PRELOAD_ERROR: {
            const newState = {
                ...state,
                errored: [...state.errored, action.errored],
            };
            delete newState.preloadRequestId;
            return newState;
        }

        case PRELOAD_COMPLETE: {
            const newState = {
                ...state,
                percent: 100,
                isLoading: false,
            };
            delete newState.preloadRequestId;
            return newState;
        }

        case PRELOAD_SWAP_LOAD_STATUS_MEETING: {
            const fullyLoaded = new Set(
                Array.from(state.fullyLoaded).map((loadedAction) =>
                    loadedAction.replace(action.fromId, action.toId)
                )
            );
            return {
                ...state,
                fullyLoaded,
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default preloadReducer;
