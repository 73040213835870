import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M12.5385 16.0632L11 21.7042L16.641 20.1658L30 6.7042C32.451 4.27494 28.6096 0.380191 25.8792 2.72242L12.5385 16.0632Z"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="M14.2346 5H6C3.79086 5 2 6.79086 2 9V25C2 27.2091 3.79086 29 6 29H26C28.2091 29 30 27.2091 30 25V14.7021"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
