import facepaint from 'facepaint';

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const SM = 640;

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const MD = 768;

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const LG = 1024;

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const XL = 1280;

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const XXL = 1536;

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const XXXL = 1792;

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const XXXXL = 2048;

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const SM_UP = `min-width: ${SM}px`;

/**
 * @deprecated Please swap to useMinScreen instead
 */

export const LG_UP = `min-width: ${LG}px`;

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const XL_UP = `min-width: ${XL}px`;

/**
 * @deprecated Please swap to useMinScreen instead
 */
export const mq = facepaint(
    [SM, MD, LG, XL, XXL, XXXL].map((bp) => `@media(min-width: ${bp}px)`)
);
