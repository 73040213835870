import moment from 'moment-timezone';

export function minuteItemModel(item) {
    if (!item) return;

    const createdAt = moment(item.createdAt);
    const startDate = moment(item.occurrenceMeta.startDate);

    return {
        ...item,
        createdAt,
        occurrenceMeta: { ...item.occurrenceMeta, startDate },
        isLocalUpdate: false,
    };
}

export function minuteItemModelList(items = []) {
    return items.map(minuteItemModel);
}
