import React, {
    useState,
    createContext,
    useContext,
    useEffect,
    useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import {
    useMinScreen,
    useCloseSeriesTemplateSidebar,
    useUser,
    useOpenCalendarConnectionModal,
    useRemoteCalendarAdvertise,
} from '~hooks';
import { Global } from '@emotion/react';
import tw, { css } from 'twin.macro';
import { useLocation } from 'react-router-dom';
import { delayedAction } from '~shared/utils/delayedAction';
import { ROUTES } from '~shared/navigation/routes';
import { updateUserAppParameter } from '~modules/user/user.actions';
import { isDashboardSchedulePage, replaceTo } from '~modules/navigation';
import { localStorageGet, localStorageRemove } from '~common/utils';
import { SHOULD_OPEN_HELP_CENTER } from '~common/constants';

/**
 * Constants
 */

export const CONTENT_GUTTER = {
    X_SMALL: '1rem',
    SMALL: '1.25rem',
    LARGE: '2.5rem',
};

/**
 * These variables are overwritten within components using Global body variables
 */
export const defaultCssVariables = {
    '--nav-vertical-width': '0px',
    '--header-horizontal-height': '0px',
    '--sidebar-horizontal-height': '0px',
    '--footer-horizontal-height': '0px',
    '--sidebar-width': '0px',
    '--filters-width': '0px',
    '--slideout-width': '350px',
    '--sidebar-header-height': '0px',
    '--drawer-underlay-gutter': '3rem',
    '--content-padding-x': '1.5rem',
    '--content-padding-y': '1.5rem',
    '--tab-menu-height': '60px',
};

/**
 * UI provider and hook
 */

const UIContext = createContext({});

const UIProvider = ({ children }) => {
    const [isSiteNavSmallOpen, setIsSiteNavSmallOpen] = useState(false);
    const [isTopBarOpen, setIsTopBarOpen] = useState(false);
    const [isQuickSwitcherOpen, setIsQuickSwitcherOpen] = useState(false);
    const [isHelpCenterOpen, setIsHelpCenterOpen] = useState(false);
    const [isSeriesTemplateOpen, setIsSeriesTemplateOpen] = useState(false);
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [isDetailSidebarOpen, setIsDetailSidebarOpen] = useState(false);
    const [isDetailSidebarDocked, setIsDetailSidebarDocked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPrivateNotesOpen, setIsPrivateNotesOpen] = useState(false);
    const closeSeriesTemplateSidebar = useCloseSeriesTemplateSidebar();
    const location = useLocation();
    const openNotificationsViaUrlTimeoutRef = useRef();
    const openHelpCenterViaUrlTimeoutRef = useRef();

    const closeAllDrawers = ({
        closeNotifications = true,
        closeHelpCenter = true,
    } = {}) =>
        delayedAction(
            () => [
                setIsSiteNavSmallOpen(false),
                setIsTopBarOpen(false),
                setIsQuickSwitcherOpen(false),
                closeHelpCenter && setIsHelpCenterOpen(false),
                closeNotifications && setIsNotificationsOpen(false),
                setIsFiltersOpen(false),
                setIsDetailSidebarOpen(false),
                setIsDetailSidebarDocked(false),
            ],
            200
        );

    // Close drawers when location changes
    // but not when opening the notifications panel via url
    useEffect(() => {
        const isOpeningNotifications = [
            location.pathname,
            location.lastViewedPath,
        ].includes(ROUTES.notificationsOpen);
        if (isOpeningNotifications) return;

        // Check if notifications should be closed too
        const notificationsCloseValue = location?.state?.closeNotifications;
        const closeNotifications =
            notificationsCloseValue !== undefined
                ? notificationsCloseValue
                : true;
        // Check if helpCenter should be closed too
        const closeValueHelpCenter = location?.state?.closeHelpCenter;
        const closeHelpCenter =
            closeValueHelpCenter !== undefined ? closeValueHelpCenter : true;
        closeAllDrawers({ closeNotifications, closeHelpCenter });
    }, [
        location.pathname,
        location.lastViewedPath,
        location?.state?.closeNotifications,
        location?.state?.closeHelpCenter,
    ]);

    // Drive the series template open/closed status using the location.state
    useEffect(() => {
        const openValue = location?.state?.isSeriesTemplateOpen;
        setIsSeriesTemplateOpen(openValue);
    }, [location]);

    // ======================
    // Open connect modal when url is hit
    const openCalendarConnectionModal = useOpenCalendarConnectionModal();
    const advertisedCalendar = useRemoteCalendarAdvertise();

    useEffect(() => {
        const shouldOpenConnectModal =
            (Boolean(advertisedCalendar) &&
                location?.state?.defaultAction === 'connect-calendar') ??
            false;
        if (!shouldOpenConnectModal) return;

        openCalendarConnectionModal(advertisedCalendar);
        replaceTo({
            location: location.pathname,
            state: undefined,
        });
    }, [
        openCalendarConnectionModal,
        advertisedCalendar,
        location.state?.defaultAction,
        location.pathname,
    ]);

    // ======================
    // Open notifications drawer when url is hit
    useEffect(() => {
        const shouldOpenNotifications =
            location?.state?.defaultAction === 'notifications' ?? false;
        if (!shouldOpenNotifications) return;

        openNotificationsViaUrlTimeoutRef.current = setTimeout(() => {
            setIsNotificationsOpen(true);
            replaceTo({
                location: location.pathname,
                // Avoid closing the panel in the next render
                state: { closeNotifications: false },
            });
        }, 500);
    }, [location.state?.defaultAction, location.pathname]);

    // ======================

    // Open help center slideover when url is hit
    useEffect(() => {
        const shouldOpenHelpCenter = ['help', 'help/video'].includes(
            location?.state?.defaultAction
        );
        if (!shouldOpenHelpCenter) return;

        openHelpCenterViaUrlTimeoutRef.current = setTimeout(() => {
            setIsHelpCenterOpen(true);
            replaceTo({
                location: location.pathname,
                // Avoid closing the panel in the next render
                state: { closeHelpCenter: false },
            });
        }, 500);
    }, [location.state?.defaultAction, location.pathname]);

    // Open help center slideover for new user on first visit to schedule view
    useEffect(() => {
        const shouldHelpCenterOpen =
            Object.keys(localStorageGet(SHOULD_OPEN_HELP_CENTER)).length > 0;
        // Check for notification center opening
        const willOpenNotifications =
            location?.state?.defaultAction === 'notifications' ||
            location.pathname === ROUTES.notificationsOpen;

        const shouldOpenHelpCenter =
            shouldHelpCenterOpen &&
            isDashboardSchedulePage() &&
            !willOpenNotifications &&
            !isNotificationsOpen;

        if (!shouldOpenHelpCenter) return;

        openHelpCenterViaUrlTimeoutRef.current = setTimeout(() => {
            setIsHelpCenterOpen(true);
            replaceTo({
                location: location.pathname,
                // Avoid closing the panel in the next render
                state: { closeHelpCenter: false },
            });
        }, 1000);
        // Remove local storage value
        localStorageRemove(SHOULD_OPEN_HELP_CENTER);
    }, [location.state?.defaultAction, location.pathname, isNotificationsOpen]);

    // ======================

    // Scroll top when app location changes
    useEffect(() => {
        const avoidScrollTop = location?.state?.avoidScrollTop;
        !avoidScrollTop && window.scrollTo(0, 0);
        // we want to only scroll to the top when the path changes.
        // at that point, we look at the location state to see if avoid scroll top is true.
    }, [location.pathname]); //eslint-disable-line react-hooks/exhaustive-deps

    // TODO: Find a more automated "closer"" solution than below

    // Close filters when site nav opens
    useEffect(() => {
        isSiteNavSmallOpen && setIsFiltersOpen(false);
    }, [isSiteNavSmallOpen]);

    // Close siteNavSmall when Help menu opens
    useEffect(() => {
        isHelpCenterOpen && setIsSiteNavSmallOpen(false);
    }, [isHelpCenterOpen]);

    // Close topbar when notifications open (happens when clicking alert btn)
    useEffect(() => {
        isNotificationsOpen && setIsTopBarOpen(false);
    }, [isNotificationsOpen]);

    // Close sidebars when quickswitcher/notifications opens
    // Only on smaller screens when the detail sidebar is a floated drawer
    useEffect(() => {
        (isQuickSwitcherOpen || isNotificationsOpen || isHelpCenterOpen) &&
            (setIsDetailSidebarOpen(false),
            setIsDetailSidebarDocked(false),
            closeSeriesTemplateSidebar());
    }, [
        isQuickSwitcherOpen,
        isNotificationsOpen,
        isHelpCenterOpen,
        closeSeriesTemplateSidebar,
    ]);

    // Close notifications when quickswitcher is open
    useEffect(() => {
        if (!isQuickSwitcherOpen) return;
        setIsNotificationsOpen(false);
    }, [isQuickSwitcherOpen, setIsNotificationsOpen]);
    // Close notifications when helpCenter is open
    useEffect(() => {
        if (!isHelpCenterOpen) return;
        setIsNotificationsOpen(false);
    }, [isHelpCenterOpen, setIsNotificationsOpen]);

    // Close quickswitcher when notifications is open
    useEffect(() => {
        isNotificationsOpen && setIsQuickSwitcherOpen(false);
    }, [isNotificationsOpen, setIsQuickSwitcherOpen]);

    // Close quickswitcher when helpCenter is open
    useEffect(() => {
        isHelpCenterOpen && setIsQuickSwitcherOpen(false);
    }, [isHelpCenterOpen, setIsQuickSwitcherOpen]);

    // Close help menu when notifications is open
    useEffect(() => {
        isNotificationsOpen && setIsHelpCenterOpen(false);
    }, [isNotificationsOpen, setIsHelpCenterOpen]);

    // Close help menu when quickSwitcher is open
    useEffect(() => {
        if (!isQuickSwitcherOpen) return;
        setIsHelpCenterOpen(false);
    }, [isQuickSwitcherOpen, setIsHelpCenterOpen]);

    // Close nav sidebar when seriesTemplate/notifications open
    useEffect(() => {
        (isSeriesTemplateOpen || isNotificationsOpen) &&
            setIsDetailSidebarOpen(false),
            setIsDetailSidebarDocked(false);
    }, [isSeriesTemplateOpen, isNotificationsOpen]);

    // Close notifications when seriesTemplate is open
    useEffect(() => {
        if (!isSeriesTemplateOpen) return;
        setIsNotificationsOpen(false);
    }, [isSeriesTemplateOpen, setIsNotificationsOpen]);

    // Close detailSidebar when notifications is open
    useEffect(() => {
        isNotificationsOpen && setIsDetailSidebarOpen(false),
            setIsDetailSidebarDocked(false);
    }, [isNotificationsOpen, setIsDetailSidebarOpen, setIsDetailSidebarDocked]);

    // Close privateNotes when notifications is open
    useEffect(() => {
        isNotificationsOpen && setIsPrivateNotesOpen(false);
    }, [isNotificationsOpen, setIsPrivateNotesOpen]);

    // Close privateNotes when quickswitcher is open
    useEffect(() => {
        isQuickSwitcherOpen && setIsPrivateNotesOpen(false);
    }, [isQuickSwitcherOpen, setIsPrivateNotesOpen]);

    // Close privateNotes when helpCenter is open
    useEffect(() => {
        isHelpCenterOpen && setIsPrivateNotesOpen(false);
    }, [isHelpCenterOpen, setIsPrivateNotesOpen]);

    // Close (floating) detailSidebar when a modal is opened from menu options
    useEffect(() => {
        isModalOpen && setIsDetailSidebarOpen(false),
            setIsDetailSidebarDocked(false);
    }, [isModalOpen, setIsDetailSidebarOpen]);

    useEffect(() => {
        return () => {
            clearTimeout(openNotificationsViaUrlTimeoutRef.current);
            clearTimeout(openHelpCenterViaUrlTimeoutRef.current);
        };
    }, []);

    const context = {
        closeAllDrawers,
        //
        isTopBarOpen,
        setIsTopBarOpen,
        //
        isSiteNavSmallOpen,
        setIsSiteNavSmallOpen,
        //
        isQuickSwitcherOpen,
        setIsQuickSwitcherOpen,
        //
        //
        isHelpCenterOpen,
        setIsHelpCenterOpen,
        //
        isSeriesTemplateOpen,
        //
        isNotificationsOpen,
        setIsNotificationsOpen,
        //
        isFiltersOpen,
        setIsFiltersOpen,
        //
        isDetailSidebarOpen,
        setIsDetailSidebarOpen,
        //
        isDetailSidebarDocked,
        setIsDetailSidebarDocked,
        //
        isModalOpen,
        setIsModalOpen,
        //
        isPrivateNotesOpen,
        setIsPrivateNotesOpen,
    };
    return <UIContext.Provider value={context}>{children}</UIContext.Provider>;
};

const useUI = () => useContext(UIContext);

/**
 * Sidebar pinned provider and hook
 */

const SidebarPinnedContext = createContext({});

const SidebarPinnedProvider = ({ children }) => {
    const user = useUser();

    const userInitialPreference = user.appPreferences?.isOccurrenceSidebarOpen;
    const [isSidebarPinned, saveIsSidebarPinned] = useState(
        userInitialPreference ?? true
    );
    const dispatch = useDispatch();
    const isMounted = useRef(false);

    useEffect(() => {
        // avoid executing on initial component mount
        if (!isMounted.current) return;
        // This timeout avoids janky animation
        setTimeout(() => {
            dispatch(
                updateUserAppParameter(
                    'isOccurrenceSidebarOpen',
                    isSidebarPinned
                )
            );
        }, 200);
    }, [dispatch, isSidebarPinned]);

    // store whether the component has finished mounting
    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const context = { isSidebarPinned, saveIsSidebarPinned };

    return (
        <SidebarPinnedContext.Provider value={context}>
            {children}
        </SidebarPinnedContext.Provider>
    );
};

const useIsSidebarPinned = () => useContext(SidebarPinnedContext);

/**
 * Other
 */

const ContentPadding = () => {
    const { min } = useMinScreen();

    const isLg = min('lg');
    const isSm = min('sm');
    const contentPaddingX = isLg ? '3.5rem' : isSm ? '2.65rem' : '1.5rem';
    const contentPaddingY = isLg ? '3.25rem' : isSm ? '2.5rem' : '1.5rem';

    return (
        <Global
            styles={css({
                body: {
                    '--content-padding-x': contentPaddingX,
                    '--content-padding-y': contentPaddingY,
                },
            })}
        />
    );
};

// Custom scrollbars on browsers that support the webkit prefix
// This includes edge, chrome, safari (safari is currently disabled)
const customScrollbar = ({
    hoverColor,
    showWhenScrollable = false,
} = {}) => css`
    ${tw`overflow-y-auto`}
    -webkit-overflow-scrolling: touch; /* Safari iOs momentum scrolling */

    ::-webkit-scrollbar {
        width: 8px;
        height: 4px;
    }
    ::-webkit-scrollbar-track {
        background: none;
        border-width: 0;
    }
    ::-webkit-scrollbar-thumb {
        ${tw`bg-transparent rounded`}
    }
    ${showWhenScrollable
        ? css`
              ::-webkit-scrollbar-thumb {
                  ${tw`bg-line visible`}
              }
          `
        : css`
              &:hover::-webkit-scrollbar-thumb {
                  ${tw`visible`}
                  ${hoverColor || tw`bg-line`}
              }
          `}

    ::-webkit-scrollbar-thumb:active {
        ${hoverColor || tw`bg-line-dark`}
    }

    /* Hidden scroller bits */
    ::-webkit-resizer {
        display: none;
    }
    ::-webkit-scrollbar-button {
        display: none;
    }
    ::-webkit-scrollbar-corner {
        display: none;
    }
    ::-webkit-scrollbar-track-piece {
        display: none;
    }

    /* Hide scroller on IE/Old Edge */
    -ms-overflow-style: none;

    /* Hide scroller on Safari Desktop & Mobile */
    /* This avoids the 1px width bug that turns up after scrolling */
    @media not all and (min-resolution: 0.001dpcm) {
        ::-webkit-scrollbar-thumb {
            ${tw`hidden`}
        }
    }
`;

export {
    UIProvider,
    useUI,
    SidebarPinnedProvider,
    useIsSidebarPinned,
    ContentPadding,
    customScrollbar,
};
