import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m19.5 2c0.319 0 0.6238 0.13204 0.842 0.36477l6.2932 6.7128c0.2344 0.24997 0.3648 0.57977 0.3648 0.92242m-7.5-8h-10.5c-2.2091 0-4 1.7909-4 4v20c0 2.2091 1.7909 4 4 4h14c2.2091 0 4-1.7909 4-4v-16m-7.5-8v4c0 2.2091 1.7909 4 4 4h3.5"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m10.752 16.084c0.376 0 0.6507-0.0907 0.824-0.272 0.176-0.1813 0.264-0.4293 0.264-0.744 0-0.144-0.0227-0.2747-0.068-0.392-0.0427-0.12-0.1093-0.2213-0.2-0.304-0.088-0.0853-0.2-0.1507-0.336-0.196s-0.2973-0.068-0.484-0.068h-0.76001v1.976h0.76001zm0-2.888c0.3973 0 0.74 0.0467 1.028 0.14 0.2907 0.0933 0.5293 0.224 0.716 0.392 0.1867 0.1653 0.324 0.3627 0.412 0.592 0.0907 0.2293 0.136 0.4787 0.136 0.748 0 0.2853-0.0467 0.5467-0.14 0.784s-0.2347 0.4413-0.424 0.612-0.428 0.304-0.716 0.4c-0.288 0.0933-0.6253 0.14-1.012 0.14h-0.76001v1.996h-1.2v-5.804h1.96zm8.2596 2.9c0 0.424-0.072 0.8147-0.216 1.172-0.1413 0.3547-0.3426 0.66-0.604 0.916-0.2586 0.256-0.5706 0.456-0.936 0.6-0.3653 0.144-0.7706 0.216-1.216 0.216h-2.232v-5.804h2.232c0.4454 0 0.8507 0.072 1.216 0.216 0.3654 0.144 0.6774 0.3453 0.936 0.604 0.2614 0.256 0.4627 0.5613 0.604 0.916 0.144 0.3547 0.216 0.7427 0.216 1.164zm-1.232 0c0-0.304-0.04-0.5773-0.12-0.82s-0.1946-0.448-0.344-0.616c-0.1493-0.168-0.332-0.2973-0.548-0.388-0.2133-0.0907-0.456-0.136-0.728-0.136h-1.024v3.924h1.024c0.272 0 0.5147-0.044 0.728-0.132 0.216-0.0907 0.3987-0.22 0.548-0.388 0.1494-0.1707 0.264-0.3773 0.344-0.62s0.12-0.5173 0.12-0.824zm3.322-1.96v1.604h2.088v0.944h-2.088v2.316h-1.208v-5.804h3.696v0.94h-2.488z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
