import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import tw, { styled } from 'twin.macro';
import Icon from '~icons';
import { pluralize } from '~shared/utils';
import {
    onUpdateOccurrence,
    openOccurrenceModal,
} from '~modules/occurrence/occurrence.actions';
import { MEETING_EDIT_KEY_ACTUAL } from '~common/constants';
import moment from 'moment';
import { getOccurrenceAccessLevelNonMemoized } from '~modules/permission/permission.selectors';
import {
    getIsAfterMeetingEndTime,
    getIsOneHourPastScheduledMeetingEnd,
    getIsPriorToMeetingStartTime,
    getIsPriorToMeetingStartTimeMinusThirtyMinutes,
} from '~modules/occurrence/occurrence.helpers';
import { getGroupByMeetingId } from '~modules/group/group.selectors';
import { getStore } from '~common/store';
import { getMeetingFromStoreById } from '~modules/meeting/meeting.selectors';
import { Menu, Popup } from '~modules/common/form-elements';
import { Tooltip } from '~modules/common/components';

const Container = styled.div(
    ({ isApproachingEndTime, isAfterEndTime, isSimple }) => [
        tw`flex items-center px-2.5 py-2 rounded space-x-1 text-sm leading-none bg-success-solid text-success shadow-sm whitespace-nowrap select-none svg:(text-success-icon overflow-visible)`, // overflow-visible fix for windows chrome cutting off stroke
        isApproachingEndTime &&
            tw`bg-secondary-light text-secondary svg:text-secondary`,
        isAfterEndTime && tw`bg-error-solid text-error svg:text-error-icon`,
        isSimple && tw`px-[5px] py-1 text-body-dark`,
    ]
);
const TimeWrap = tw.div`flex items-center`;

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

const OccurrenceCountdownTimer = ({
    occurrence,
    isSimple,
    hasRestrictedWidth,
    displayWhenTimerNotActive,
}) => {
    const dispatch = useDispatch();
    const [, setCountdown] = useState(Date.now());

    const startDate = occurrence.startDate;
    const endDate = occurrence.endDate;
    const startDateActual = occurrence?.startDateActual;
    const endDateActual = occurrence?.endDateActual;

    const fromDate = startDateActual || startDate;
    const toDate = endDateActual || endDate;

    const isPriorToMeetingStartTime = getIsPriorToMeetingStartTime(fromDate);
    const isAfterMeetingEndTime = getIsAfterMeetingEndTime(toDate);
    const isPriorToMeetingStartTimeMinusThirtyMinutes =
        getIsPriorToMeetingStartTimeMinusThirtyMinutes(fromDate);

    const isOneHourPastScheduledMeetingEnd =
        getIsOneHourPastScheduledMeetingEnd(toDate);

    const isMeetingUnderway =
        !isPriorToMeetingStartTime && !isAfterMeetingEndTime;

    const countDownDate = isPriorToMeetingStartTime
        ? fromDate.valueOf()
        : toDate.valueOf();

    const occurrenceIsToday = fromDate.isSame(new Date(), 'day');

    // Use global setInterval to call setCountdown every second. This is to trigger the component to re-render
    useEffect(() => {
        // Prevent counter from running for meetings past their end time
        if (
            endDateActual?.valueOf() < Date.now() ||
            isOneHourPastScheduledMeetingEnd
        )
            return;
        const intervalId = setInterval(() => {
            setCountdown(Date.now());
        }, SECOND);

        return () => {
            clearInterval(intervalId);
        };
    }, [countDownDate, endDateActual, isOneHourPastScheduledMeetingEnd]);

    const meeting = getMeetingFromStoreById(occurrence.meeting);

    // Display logic for meeting sidebar and schedule dash
    if (
        meeting?.isIntro ||
        (isSimple &&
            // Past & future occurrences
            (!occurrenceIsToday ||
                // On today
                (occurrenceIsToday &&
                    // Not half hour prior to start time
                    (isPriorToMeetingStartTimeMinusThirtyMinutes ||
                        // Not one hour past end time
                        isOneHourPastScheduledMeetingEnd))))
    )
        return displayWhenTimerNotActive;

    // Only display component 30mins prior to meeting start
    if (isPriorToMeetingStartTimeMinusThirtyMinutes) return;

    const remaining = countDownDate - Date.now();
    const hours = Math.floor((remaining / HOUR) % 24);
    const minutes = Math.floor((remaining / MINUTE) % 60);
    const seconds = Math.floor((remaining / SECOND) % 60);
    // Format countDown or countUp for before start, after start and after end time
    const hoursForDisplay = isPriorToMeetingStartTime
        ? ''
        : isAfterMeetingEndTime
        ? -hours - 1
        : hours;
    const minutesForDisplay = isPriorToMeetingStartTime
        ? Math.abs(-minutes)
        : isAfterMeetingEndTime
        ? -minutes - 1
        : minutes;
    const secondsForDisplay = isPriorToMeetingStartTime
        ? Math.abs(-seconds)
        : isAfterMeetingEndTime
        ? -seconds - 1
        : seconds;

    const durationMessage = (
        <>
            {isAfterMeetingEndTime ? (
                <div>
                    {endDateActual || isOneHourPastScheduledMeetingEnd
                        ? 'Meeting ended '
                        : `${
                              !startDateActual ? 'Scheduled m' : 'M'
                          }eeting end time has passed `}
                </div>
            ) : (
                <div>
                    Meeting {isPriorToMeetingStartTime ? 'starts' : 'ends'} in{' '}
                    {hoursForDisplay > 0 &&
                        `${hoursForDisplay} ${pluralize(
                            'hour',
                            'hours',
                            hoursForDisplay
                        )} and `}
                    {minutesForDisplay > 0
                        ? `${minutesForDisplay} ${pluralize(
                              'minute',
                              'minutes',
                              minutesForDisplay
                          )}`
                        : `${secondsForDisplay} ${pluralize(
                              'second',
                              'seconds',
                              seconds
                          )}`}{' '}
                </div>
            )}
        </>
    );

    const isApproachingEndTime =
        isMeetingUnderway && hours === 0 && minutes < 5;

    const Timer = () => (
        <Container
            /* Change the container color after the end of the meeting */
            isAfterEndTime={isAfterMeetingEndTime}
            /* Change the container color five minutes from end of the meeting */
            {...{ isApproachingEndTime, isSimple }}
        >
            <Icon name="dueDate" />
            <TimeWrap>
                {isAfterMeetingEndTime &&
                (isSimple ||
                    !!endDateActual ||
                    isOneHourPastScheduledMeetingEnd) ? (
                    <div>{hasRestrictedWidth ? 'Ended' : 'Meeting ended'}</div>
                ) : isSimple && isApproachingEndTime ? (
                    <div>Ends soon</div>
                ) : isSimple && isMeetingUnderway ? (
                    <div>On now</div>
                ) : (
                    /* Only display hours if duration over 1 hour */
                    <>
                        {isPriorToMeetingStartTime &&
                            (!isSimple || !hasRestrictedWidth) && (
                                <div>Starts in&nbsp;</div>
                            )}
                        {hoursForDisplay > 0 && (
                            <>
                                <div>
                                    {String(hoursForDisplay).padStart(2, '0')}
                                </div>
                                <div>:</div>
                            </>
                        )}
                        <div>{String(minutesForDisplay).padStart(2, '0')}</div>
                        <div>:</div>
                        <div>{String(secondsForDisplay).padStart(2, '0')}</div>
                    </>
                )}
            </TimeWrap>
        </Container>
    );

    if (isSimple)
        return (
            <Tooltip
                position="bottom left"
                content={durationMessage}
                offset={[-12, 0]}
                // Don't display tooltip that duplicates the label
                enableTooltip={!isAfterMeetingEndTime}
            >
                <span>
                    <Timer />
                </span>
            </Tooltip>
        );

    const group = getGroupByMeetingId(getStore().getState(), meeting.id);
    const myAccessLevel = getOccurrenceAccessLevelNonMemoized({
        occurrence: occurrence.id,
    });

    const options = [
        (isPriorToMeetingStartTime ||
            isMeetingUnderway ||
            (!endDateActual && !isOneHourPastScheduledMeetingEnd)) &&
            myAccessLevel.canUpdate && {
                label: isPriorToMeetingStartTime
                    ? 'Start meeting now'
                    : 'End meeting now',
                onClick: () =>
                    isPriorToMeetingStartTime
                        ? dispatch(
                              onUpdateOccurrence({
                                  id: occurrence.id,
                                  meeting: occurrence.meeting,
                                  startDateActual: moment().startOf('minute'),
                              })
                          )
                        : dispatch(
                              onUpdateOccurrence({
                                  id: occurrence.id,
                                  meeting: occurrence.meeting,
                                  endDateActual: moment().startOf('minute'),
                              })
                          ),
            },

        myAccessLevel.canUpdate && {
            label: 'Edit start/end times…',
            onClick: () =>
                dispatch(
                    openOccurrenceModal({
                        group,
                        meeting,
                        occurrence,
                        editKey: MEETING_EDIT_KEY_ACTUAL,
                    })
                ),
        },
        myAccessLevel.canUpdate && 'divider',
        {
            label: durationMessage,
            isComponent: true,
            hasLightText: true,
        },
    ];

    return (
        <Popup
            on="hover"
            position="bottom left"
            offset={[-8, 0]}
            trigger={Timer}
            hoverable={myAccessLevel.canUpdate}
        >
            {({ setIsOpen }) => <Menu {...{ options, setIsOpen }} />}
        </Popup>
    );
};

export default OccurrenceCountdownTimer;
