import _isEqual from 'lodash/isEqual.js';
import _pick from 'lodash/pick.js';
import _startCase from 'lodash/startCase.js';
import {
    UPDATE_USERPROFILE_PREFERENCE_APP,
    UPDATE_USERPROFILE_PREFERENCE_NOTIFICATION,
    UPDATE_USERPROFILE_SUCCESS,
} from '~common/action.types';
import {
    formSubmissionServerError,
    processBodyErrors,
} from '~components/formvalidation/formvalidation.helper';
import {
    updateUserProfile as updateUserProfileApi,
    updateUserPreference,
    uploadUserAvatar as uploadUserAvatarApi,
    removeUserAvatar as removeUserAvatarApi,
} from './user.api.js';
import { clearCacheMeetingAddEditFormInitialValues } from '~modules/meeting/meeting.selectors';
import { clearCacheOccurrenceAddEditFormInitialValues } from '~modules/occurrence/occurrence.selectors';
import { alertError } from '~modules/alert/alert.actions';
import { loadRemoteCalendarEvents } from '~modules/remoteCalendar/remoteCalendar.actions';

function updateUserProfileSuccess(userProfile) {
    return {
        type: UPDATE_USERPROFILE_SUCCESS,
        userProfile,
    };
}

export function updateUserProfile(id, values) {
    return async (dispatch, getState) => {
        try {
            const { user } = getState();

            const userProfile = await updateUserProfileApi(id, values);
            dispatch(updateUserProfileSuccess(userProfile));

            const compareFields = ['locale', 'timezone'];
            if (
                user.id === userProfile.id &&
                !_isEqual(
                    _pick(user, compareFields),
                    _pick(userProfile, compareFields)
                )
            ) {
                clearCacheMeetingAddEditFormInitialValues();
                clearCacheOccurrenceAddEditFormInitialValues();
                // if timezone has changed, reload the remote calendar events so they have right timezone information in them
                dispatch(
                    loadRemoteCalendarEvents(undefined, {
                        dispatchImmediately: true,
                    })
                );
            }
        } catch (error) {
            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );
            dispatch(
                alertError({
                    id: `updateUserProfile`,
                    title: 'Profile could not be updated',
                    error,
                })
            );
            throw newError;
        }
    };
}

export const updateUserAppParameter = (parameter, value) => (dispatch) => {
    // optimistic update
    dispatch({
        type: UPDATE_USERPROFILE_PREFERENCE_APP,
        [parameter]: value,
    });
    return dispatch(updateUserParameter('app', parameter, value));
};

export const updateUserNotificationParameter =
    (parameter, value) => (dispatch) => {
        // optimistic update
        dispatch({
            type: UPDATE_USERPROFILE_PREFERENCE_NOTIFICATION,
            [parameter]: value,
        });
        dispatch(updateUserParameter('notification', parameter, value));
    };

function updateUserParameter(type, parameter, value) {
    return async (dispatch) => {
        try {
            const userProfile = await updateUserPreference(type, {
                [parameter]: value,
            });
            dispatch(updateUserProfileSuccess(userProfile));
        } catch (error) {
            dispatch(
                alertError({
                    id: `updateUser${_startCase(type)}Parameter-${parameter}`,
                    title: 'Preference could not be updated',
                    error,
                })
            );
        }
    };
}

export function uploadUserAvatar(file) {
    return async (dispatch) => {
        try {
            const userProfile = await uploadUserAvatarApi(file);
            dispatch(updateUserProfileSuccess(userProfile));
        } catch (error) {
            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );
            dispatch(
                alertError({
                    id: `uploadUserAvatar`,
                    title: 'Avatar could not be updated',
                    error,
                })
            );
            throw newError;
        }
    };
}

export function removeUserAvatar() {
    return async (dispatch) => {
        try {
            const userProfile = await removeUserAvatarApi();
            dispatch(updateUserProfileSuccess(userProfile));
        } catch (error) {
            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );
            dispatch(
                alertError({
                    id: `removeUserAvatar`,
                    title: 'Avatar could not be removed',
                    error,
                })
            );
            throw newError;
        }
    };
}
