import { createHashForString } from '../utils/index.js';
/**
 * Identify if the Rich Text Editor (RTE) content is "empty". If the user enters text in there, and deletes it,
 * it doesn't get returned to an empty string. The following will be considered "empty":
 *   '<p></p>'
 *   '<p><br/></p>'
 *   '<p><br /></p>'
 *   '<p><br></p>'
 *   ''
 *   undefined
 *   '<br/>'
 *   '<br />'
 *   '<br>'
 * @param {string} html editor content as html string
 */
export const isEmptyRTEContent = (html) =>
    !html || /^(<p>)?(<br ?\/?>)?(<\/p>)?$/.test(html);

/**
 * Identify if the content of a minuteItem is empty, or has changed since it was populated from an agendaItem
 * @param {object} minuteItem minuteItem [mongoose] model or object
 * @param {string} [minuteItem.content] editor content as html string
 * @param {string} [minuteItem.agendaItemContentHashKey] hash key of the original content populated from an agendaItem
 */
export const isEmptyOrPristineMinuteItemContent = (minuteItem) =>
    !minuteItem ||
    isEmptyRTEContent(minuteItem.content) ||
    isPristineMinuteItemContent(minuteItem);

/**
 * Identify if the content of a minuteItem has changed since it was populated from an agendaItem.
 * This is on the basis that the minuteItem.agendaItemContentHashKey was populated when the minuteItem was originally created, and populated from an agendaItem.
 * @param {object} minuteItem minuteItem [mongoose] model or object
 * @param {string} [minuteItem.content] editor content as html string
 * @param {string} [minuteItem.agendaItemContentHashKey] hash key of the original content populated from an agendaItem
 */
export const isPristineMinuteItemContent = (minuteItem) =>
    createHashForString(minuteItem?.content) ===
    minuteItem?.agendaItemContentHashKey;
