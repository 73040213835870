import _filter from 'lodash/filter.js';
import _find from 'lodash/find.js';
import { createSelector } from 'reselect';
import { getStore } from '~common/store';
import { getOccurrenceIdSelector } from '~common/selector.helpers';

/**
 * Get a document from the store by it's document Id.
 * @param {Object} [state] Redux store. If not provided, the global store will be used.
 * @param {Object|string} doc document object or document.id
 */
export function getDocumentFromStoreById(state, doc) {
    const _state = arguments.length === 2 ? state : getStore().getState();
    const _doc = arguments.length === 2 ? doc : arguments[0];

    // TODO: Convert next line to use "getId()"
    const id = typeof _doc === 'string' ? _doc : _doc.id;
    return _find(_state.documents.documents, { id });
}

export const getDocumentsForOccurrenceSelector = createSelector(
    (state) => state.documents.documents,
    getOccurrenceIdSelector,
    (documents, occurrence) =>
        _filter(
            documents,
            (d) => d.occurrence === occurrence && d.isEditorImage !== true
        ).sort(
            (a, b) =>
                new Date(b.createdAt).valueOf() -
                new Date(a.createdAt).valueOf()
        )
);
