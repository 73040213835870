import { createSelector } from 'reselect';
import { isLibraryTemplate } from './template.helpers.js';

export const templatesSelector = (state) => state.templates.templates;
export const libraryTemplatesSelector = createSelector(
    templatesSelector,
    (templates) => templates.filter(isLibraryTemplate)
);
export const getTemplateFromStoreByMeeting = (state, meetingId) =>
    state.templates.templates.find(
        (template) => template.meetingId === meetingId
    );

/**
 * Returns a template from the store, by searching the store.
 * @param {Object} [state=getStore().getState] Redux state object. If ommitted, use the default redux store
 * @param {string} templateId template.id.
 */
export const getTemplateFromStoreById = (state, templateId) =>
    state.templates.templates.find(
        (template) => template.templateId === templateId
    );
