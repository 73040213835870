import {
    offset,
    useFloating,
    autoUpdate,
    autoPlacement,
} from '@floating-ui/react-dom';
import type { UseFloatingReturn, Strategy } from '@floating-ui/react-dom';

type CustomReturn = {
    triggerProps: { ref: UseFloatingReturn['reference'] };
    floatProps: {
        ref: UseFloatingReturn['floating'];
        style: {
            position: Strategy;
            top: number;
            left: number;
            zIndex: number;
        };
    };
};

const floatingConfig = {
    whileElementsMounted: autoUpdate, // Stick with the trigger during scroll/resize etc
    strategy: 'fixed' as Strategy, // Avoid clipping by the viewport (require type)
    middleware: [
        autoPlacement({
            alignment: 'start',
            // Avoid strange placements against trigger
            allowedPlacements: [
                'bottom-start',
                'bottom-end',
                'top-start',
                'top-end',
            ],
        }),
        offset(4),
    ],
};

const useDefaultFloat = (): CustomReturn => {
    const props = useFloating(floatingConfig);

    const triggerProps = { ref: props.reference };
    const floatProps = {
        ref: props.floating,
        style: {
            position: props.strategy,
            top: props.y ?? 0,
            left: props.x ?? 0,
            zIndex: 9999,
        },
    };

    return { triggerProps, floatProps };
};

export { useDefaultFloat };
