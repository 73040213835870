export default () => (
    <svg viewBox="0 0 51 49" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m39.71 5.6409-4.025 2.2157-1.9932 3.8122 1.9932 4.6636 3.7735 4.0831 4.083-2.2738 2.506-4.325-2.506-5.3506-3.8315-2.8253z"
            fill="#0066DA"
        />
        <path
            d="m24.121 21.228 9.5692-9.5691c2.8156 2.1383 4.8862 5.2054 5.7666 8.7467l-13.13 13.14c-1.1805 1.1804-3.0962 1.1804-4.2766 0l-8.8532-8.8532c-0.9482-0.9482-0.9482-2.477 0-3.4155l3.7735-3.7735c0.9482-0.9482 2.4769-0.9482 3.4155 0l3.7347 3.7251zm26.172-15.075-3.8412-3.8412c-0.9386-0.93853-2.477-0.93853-3.4155 0l-3.3284 3.3284c2.6608 2.2254 4.8281 5.0119 6.3375 8.1758l4.2476-4.2476c0.9482-0.93853 0.9482-2.477 0-3.4155zm-10.382 18.035c0 8.6886-7.0438 15.723-15.723 15.723s-15.723-7.0342-15.723-15.723c0-8.6887 7.0342-15.723 15.723-15.723 3.5703 0 6.86 1.1901 9.5014 3.2027l6.0182-6.0183c-4.1992-3.5316-9.6079-5.6505-15.52-5.6505-13.362 0-24.189 10.827-24.189 24.189 0 13.362 10.827 24.189 24.189 24.189 13.362 0 24.189-10.827 24.189-24.189 0-3.7058-0.8418-7.2277-2.3318-10.372l-6.5891 6.5988c0.2903 1.2094 0.4548 2.4769 0.4548 3.7735z"
            fill="#2684FC"
        />
    </svg>
);
