import { post, get, deleteRequest } from '../../api/helpers.js';

import { commentModel, commentModelList } from './comment.mappers.js';

export function addComment(params) {
    const url = '/api/comment';
    return post(url, params).then(commentModel);
}

export function deleteComment(comment) {
    const url = `/api/comment/${comment.id}`;
    return deleteRequest(url).then(commentModel);
}

export function getCommentsForActionItem(actionItemId) {
    const url = `/api/comments/action-item/${actionItemId}`;
    return get(url).then(commentModelList);
}

export function getNewCommentCountForMeetingSincePreviousOccurrence(
    occurrenceId
) {
    const url = `/api/comments/since-previous/${occurrenceId}?countOnly=true`;
    return get(url);
}

export function getCommentsForDocument(documentId) {
    const url = `/api/comments/document/${documentId}`;
    return get(url).then(commentModelList);
}
