import _find from 'lodash/find.js';
import _pick from 'lodash/pick.js';
import _uniqBy from 'lodash/uniqBy.js';

import {
    FETCH_DOCUMENT_LIST_SUCCESS,
    DELETE_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENTS_SUCCESS,
    EDIT_DOCUMENT,
    LINK_DOCUMENTS_WEBSOCKET,
    RENAME_DOCUMENT_REQUEST,
    RENAME_DOCUMENT_ERROR,
    LOGOUT_SUCCESS,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
} from '~common/action.types';

const initialState = {
    documents: [],
};

const updateMeetingMeta = (item, meeting) => ({
    ...item,
    meeting: _pick(meeting, Object.keys(item.meeting)),
    meetingMeta: _pick(meeting, Object.keys(item.meetingMeta)),
});

const documentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DOCUMENT_LIST_SUCCESS: {
            const { page } = action;
            const documents = page.docs;

            return {
                ...state,
                documents: _uniqBy([...state.documents, ...documents], 'id'),
            };
        }
        case DELETE_DOCUMENT_SUCCESS: {
            const { doc } = action;

            return {
                ...state,
                documents: _uniqBy(
                    state.documents.filter((d) => d.id !== doc.id),
                    'id'
                ),
            };
        }

        case RENAME_DOCUMENT_REQUEST: {
            const { docId, values } = action;
            const existing = _find(state.documents, { id: docId });

            const newDoc = {
                ...existing,
                ...values,
                filename: {
                    ...existing.filename,
                    ...values.filename,
                },
            };

            return {
                ...state,
                documents: _uniqBy([newDoc, ...state.documents], 'id'),
            };
        }

        case RENAME_DOCUMENT_ERROR:
        case EDIT_DOCUMENT: {
            const { doc } = action;

            return {
                ...state,
                documents: _uniqBy([doc, ...state.documents], 'id'),
            };
        }

        case LINK_DOCUMENTS_WEBSOCKET:
        case UPLOAD_DOCUMENTS_SUCCESS: {
            const { docs } = action;

            return {
                ...state,
                documents: _uniqBy([...state.documents, ...docs], 'id'),
            };
        }

        case MOVE_OCCURRENCE_DELETE: {
            if ((action.documentIds?.length || 0) === 0) return state;
            return {
                ...state,
                documents: state.documents.filter(
                    (document) => !action.documentIds.includes(document.id)
                ),
            };
        }

        case MOVE_OCCURRENCE_UPDATE: {
            if ((action.documentIds?.length || 0) === 0) return state;
            const documents = state.documents.map((document) => {
                if (!action.documentIds.includes(document.id)) return document;

                return updateMeetingMeta(document, action.meeting);
            });

            return {
                ...state,
                documents,
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default documentReducer;
