import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { useMinScreen, useUI } from '~hooks';
import { TopBar } from './components/TopBar.js';
import { Global } from '@emotion/react';

const MainSmall = styled.main(({ hasPadding }) => [
    tw`w-full`,
    hasPadding &&
        css({ padding: 'var(--content-padding-y) var(--content-padding-x)' }),
]);

const MainLarge = styled.main(({ hasPadding }) => [
    tw`w-full min-w-0`, // min-width needed for truncation added on children
    hasPadding &&
        css({ padding: 'var(--content-padding-y) var(--content-padding-x)' }),
]);

const Sidebar = styled('aside', { shouldForwardProp: false })([
    tw`bg-solid z-30 border-r flex-shrink-0 width[var(--sidebar-width)]`,
]);

const LayoutSidebar = ({
    sidebar,
    main,
    children,
    hasPadding,
    isSize,
    pageTitle,
}) => {
    if (isSize.large) {
        return (
            <>
                <Global
                    styles={{
                        body: {
                            '--sidebar-width':
                                'clamp(250px, calc(100vw * .275), 350px)',
                        },
                    }}
                />
                <Sidebar>{sidebar}</Sidebar>
                <MainLarge {...{ hasPadding }}>{main || children}</MainLarge>
            </>
        );
    }

    return (
        <>
            <TopBar {...{ pageTitle }}>{sidebar}</TopBar>
            <MainSmall {...{ hasPadding, isSize }}>
                {main || children}
            </MainSmall>
        </>
    );
};

const Layout = ({ main, children, hasPadding, isSize }) => {
    if (!isSize.large) {
        return <MainSmall {...{ hasPadding }}>{main || children}</MainSmall>;
    }

    return <MainLarge {...{ hasPadding }}>{main || children}</MainLarge>;
};

const LayoutWrapSidebar = styled.div(({ isSize }) => [
    tw`relative pl-nav-vertical-width pb-footer-horizontal-height`,
    !isSize.large && [
        // Account for the header and footer bars so content doesn't
        // disappear underneath
        css({
            minHeight: `calc(
                100vh - var(--header-horizontal-height) -
                    var(--sidebar-horizontal-height) - var(--footer-horizontal-height)
            )`,
            marginTop: `calc(
                var(--header-horizontal-height) + var(--sidebar-horizontal-height)
            )`,
        }),
    ],
    isSize.large && tw`flex flex-row w-full min-h-screen`,
]);

const LayoutWrap = styled.div(({ isSize }) => [
    tw`pl-nav-vertical-width pb-footer-horizontal-height pt-header-horizontal-height min-h-screen`,
    isSize.large && tw`flex flex-row w-full`,
]);

const DefaultLayout = ({ sidebar, pageTitle, ...rest }) => {
    const { navIsOpen, setNavIsOpen } = useUI();
    const { min } = useMinScreen();

    const isSize = { large: min('lg') };
    const layoutProps = { navIsOpen, setNavIsOpen, isSize, ...rest };

    if (sidebar) {
        return (
            <LayoutWrapSidebar {...{ isSize }}>
                <LayoutSidebar {...layoutProps} {...{ sidebar, pageTitle }} />
            </LayoutWrapSidebar>
        );
    }

    return (
        <LayoutWrap {...{ isSize }}>
            <Layout {...layoutProps} />
        </LayoutWrap>
    );
};

export default DefaultLayout;
