import { theme } from 'twin.macro';

/**
 * @deprecated Use the theme import directly
 */
const BORDER_COLOR = theme`borderColor.line.DEFAULT`;
/**
 * @deprecated Use the theme import directly
 */
const PRIMARY_COLOR = theme`textColor.primary.DEFAULT`;
/**
 * @deprecated Use the theme import directly
 */
const WHITE_COLOR = theme`textColor.white`;
/**
 * @deprecated Use the theme import directly
 */
const BODY_DARK_COLOR = theme`textColor.body.dark`;

export { BORDER_COLOR, PRIMARY_COLOR, WHITE_COLOR, BODY_DARK_COLOR };
