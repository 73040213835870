import _omit from 'lodash/omit.js';
import { MESSAGE_MODAL_OPEN, MESSAGE_MODAL_CLOSE } from '~common/action.types';

const initialState = {
    visible: false,
};

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case MESSAGE_MODAL_OPEN:
            return {
                ...state,
                ..._omit(action, 'type'),
                visible: true,
            };

        case MESSAGE_MODAL_CLOSE:
            return initialState;

        default:
            return state;
    }
};

export default messageReducer;
