import React from 'react';

export default ({ ...rest }) => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <circle
            cx="4"
            cy="8"
            r="1.6"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.5"
            vectorEffect="non-scaling-stroke"
        />
        <circle
            cx="4"
            cy="16"
            r="1.6"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.5"
            vectorEffect="non-scaling-stroke"
        />
        <circle
            cx="4"
            cy="24"
            r="1.6"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.5"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m10 8h18m-18 8h18m-18 8h18"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
