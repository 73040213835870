import {
    GRANT_LEVEL_NONE,
    GRANT_LEVEL_READ,
    GRANT_LEVEL_WRITE,
    GRANT_LEVEL_ADMIN,
    GRANT_LEVEL_OWNER,
    GRANT_LEVEL_MEMBER,
    GRANT_LEVEL_GUEST,
    PERMISSION_LEVEL_OWNER,
    PERMISSION_LEVEL_ADMIN,
    PERMISSION_LEVEL_UPDATE,
    PERMISSION_LEVEL_VIEW,
    PERMISSION_LEVEL_NONE,
    PERMISSION_STATUS_ACCEPTED,
    PERMISSION_STATUS_PENDING,
    PERMISSION_STATUS_WORKSPACE_LEFT_WAS_ACCEPTED,
    PERMISSION_STATUS_WORKSPACE_LEFT_WAS_PENDING,
    PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED,
    PERMISSION_STATUS_REJECTED,
    PERMISSION_STATUS_REQUESTED,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_ACCEPTED,
} from '../constants/index.js';

// Use the workspace permission status of a user, to determine the status for a new meeting permission
export const workspaceStatusToMeetingStatusMapping = {
    [PERMISSION_STATUS_REJECTED]: PERMISSION_STATUS_REJECTED,
    [PERMISSION_STATUS_PENDING]: PERMISSION_STATUS_PENDING,
    [PERMISSION_STATUS_ACCEPTED]: PERMISSION_STATUS_ACCEPTED,
    [PERMISSION_STATUS_REQUESTED]: PERMISSION_STATUS_ACCEPTED, //The user has previously requested an invite to the workspace, so we can reliably say that they will trust the workspace
    //[PERMISSION_STATUS_LEFT] - not applicable to a workspace permission
    [PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING]:
        PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING,
    [PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_ACCEPTED]:
        PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING, //User was removed from the workspace, but this meeting permission didn't exist so it wasn't really accepted, so we set to "was pending"
    [PERMISSION_STATUS_WORKSPACE_LEFT_WAS_ACCEPTED]:
        PERMISSION_STATUS_WORKSPACE_LEFT_WAS_PENDING, //User has left the workspace, but this meeting permission didn't exist so it wasn't really accepted, so we set to "was pending"
    //[PERMISSION_STATUS_WORKSPACE_LEFT_WAS_PENDING] - not applicable to a workspace permission. you can only leave a workspace that you can access... i.e. it can't be pending
    [PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED]:
        PERMISSION_STATUS_ACCEPTED, //The user requested access that was denied. But if they were invited to a meeting before requesting access, they would have been granted access automatically.
};

export function grantsToGrantLevel(grants = [], isWorkspace = false) {
    const canView = grants.includes(PERMISSION_LEVEL_VIEW);
    const canUpdate = grants.includes(PERMISSION_LEVEL_UPDATE);
    const hasAdmin = grants.includes(PERMISSION_LEVEL_ADMIN);
    const hasOwner = grants.includes(PERMISSION_LEVEL_OWNER);

    if (hasOwner && hasAdmin && canView && canUpdate) {
        return GRANT_LEVEL_OWNER;
    }

    if (hasAdmin && canView && canUpdate) {
        return GRANT_LEVEL_ADMIN;
    }

    if (canView && canUpdate) {
        return isWorkspace ? GRANT_LEVEL_MEMBER : GRANT_LEVEL_WRITE;
    }

    if (canView) {
        return isWorkspace ? GRANT_LEVEL_GUEST : GRANT_LEVEL_READ;
    }

    return GRANT_LEVEL_NONE;
}

export function grantLevelToGrants(grantlevel) {
    switch (grantlevel) {
        case GRANT_LEVEL_OWNER:
            return [
                PERMISSION_LEVEL_VIEW,
                PERMISSION_LEVEL_UPDATE,
                PERMISSION_LEVEL_ADMIN,
                PERMISSION_LEVEL_OWNER,
            ];

        case GRANT_LEVEL_ADMIN:
            return [
                PERMISSION_LEVEL_VIEW,
                PERMISSION_LEVEL_UPDATE,
                PERMISSION_LEVEL_ADMIN,
            ];

        case GRANT_LEVEL_MEMBER:
        case GRANT_LEVEL_WRITE:
            return [PERMISSION_LEVEL_VIEW, PERMISSION_LEVEL_UPDATE];

        case GRANT_LEVEL_GUEST:
        case GRANT_LEVEL_READ:
            return [PERMISSION_LEVEL_VIEW];

        case GRANT_LEVEL_NONE:
            return [PERMISSION_LEVEL_NONE];

        default:
            return [];
    }
}

export const isHigherGrantLevel = (next, prev) => {
    const levels = [
        GRANT_LEVEL_NONE,
        GRANT_LEVEL_READ,
        GRANT_LEVEL_GUEST,
        GRANT_LEVEL_WRITE,
        GRANT_LEVEL_MEMBER,
        GRANT_LEVEL_ADMIN,
        GRANT_LEVEL_OWNER,
    ];
    return levels.indexOf(next) > levels.indexOf(prev);
};

export const chargeableWorkspaceGrantLevels = [
    GRANT_LEVEL_MEMBER,
    GRANT_LEVEL_ADMIN,
    GRANT_LEVEL_OWNER,
];
export const nonChargeableWorkspaceGrantLevels = [GRANT_LEVEL_GUEST];
