import React from 'react';

export default ({ ...rest }) => (
    <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M9 0H0V9H9V0Z"
            fill="#F25022"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m9 10h-9v9h9v-9z"
            fill="#00A4EF"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m19 0h-9v9h9v-9z"
            fill="#7FBA00"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m19 10h-9v9h9v-9z"
            fill="#FFB900"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
