import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M1 29V11.6207L15 1L29 11.6207V29H1Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="m9.5 29v-9.375c0-3.1066 2.4624-5.625 5.5-5.625s5.5 2.5184 5.5 5.625v9.375"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
