import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import tw, { styled, css } from 'twin.macro';
import {
    Popup,
    ColorPicker,
    ContentEditable,
} from '~modules/common/form-elements';
import { CATEGORY_PALETTE, NO_CATEGORY_COLOR } from '~common/constants';
import { onGroupSave } from '~modules/group/group.actions';
import contenteditableStyles from '~modules/common/styles/contenteditable.styles';
import { Heading } from '~modules/common/components';
import { SM_UP } from '~common/media.helpers';

const Container = tw.div`flex`;

const TriggerWrap = styled.div`
    ${tw`relative px-6 -ml-6 -mt-1`}
    ${({ hasColorPicker }) =>
        hasColorPicker
            ? css`
                  cursor: pointer;
                  transition: transform 0.2s ease;
                  &:hover,
                  &:focus {
                      transform: scale(1.1);
                      transform-origin: center;
                  }
              `
            : tw`cursor-default`}
`;
const Trigger = styled.div`
    // Fine tuned size
    width: 5px;
    top: 0.35rem;
    height: 1.4rem;
    //
    ${tw`rounded absolute`}
    background: ${({ accentColor }) => accentColor || NO_CATEGORY_COLOR};
    @media (${SM_UP}) {
        width: 6px;
        top: 0.5rem;
        height: 2rem;
    }
`;
export const PlainTitle = tw.div`border-t border-b border-transparent cursor-default truncate`;
const HeadingWrap = styled.div(({ isEditable }) => [
    tw`truncate`,
    isEditable && contenteditableStyles,
    css`
        [contenteditable='true'] {
            ${tw`truncate`}
            &:focus {
                overflow: visible;
                text-overflow: initial;
            }
        }
    `,
]);

const PopupTrigger = ({ accentColor, hasColorPicker }) => (
    <TriggerWrap {...{ hasColorPicker }}>
        <Trigger {...{ accentColor }} />
    </TriggerWrap>
);

const PopupContent = ({ accentColor, onColorChange }) => (
    <ColorPicker
        color={accentColor}
        colors={CATEGORY_PALETTE}
        circleSpacing={12}
        onChange={onColorChange}
        width="209px"
        isRound
    />
);

const CategoryHeading = forwardRef(
    (
        {
            id,
            title,
            accentColor,
            hasColorPicker,
            hasEditableTitle,
            isWorkspace,
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const handleColorChange = ({ hex: accentColor }) =>
            dispatch(onGroupSave({ id, accentColor }));
        const handleTitleChange = (title) =>
            dispatch(onGroupSave({ id, title }));

        return (
            <Container>
                {accentColor && !isWorkspace && (
                    <Popup
                        trigger={PopupTrigger}
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line unicorn/no-useless-spread
                        triggerProps={{ ...{ accentColor, hasColorPicker } }}
                        position="top left"
                        disabled={!hasColorPicker}
                    >
                        <PopupContent
                            {...{ accentColor }}
                            onColorChange={handleColorChange}
                        />
                    </Popup>
                )}
                <HeadingWrap isEditable={hasEditableTitle}>
                    <Heading as="h1" xxxl>
                        {hasEditableTitle ? (
                            <ContentEditable
                                {...{ title, ref }}
                                onTitleChange={handleTitleChange}
                            />
                        ) : (
                            <PlainTitle>{title}</PlainTitle>
                        )}
                    </Heading>
                </HeadingWrap>
            </Container>
        );
    }
);

export default CategoryHeading;
