import {
    MODAL_WORKSPACE_TEMPLATE_CHOICE_OPEN,
    MODAL_WORKSPACE_TEMPLATE_CHOICE_CLOSE,
} from '~common/action.types';

export const closeWorkspaceTemplateChoiceModal = () => ({
    type: MODAL_WORKSPACE_TEMPLATE_CHOICE_CLOSE,
});

export const openWorkspaceTemplateChoiceModal = (args) => ({
    type: MODAL_WORKSPACE_TEMPLATE_CHOICE_OPEN,
    ...args,
});
