import React, { useRef } from 'react';
import tw, { styled, css } from 'twin.macro';
import MenuWithIconTrigger from '~modules/common/components/MenuWithIconTrigger';
import { Button } from '~modules/common/form-elements';
import Icon from '~modules/common/icons';
import NavLink from './NavLink.js';

export const DROP_WIDTH = {
    SMALL: '11rem',
    BASE: '12rem',
    LARGE: '12.6rem', // To accommodate re-send minutes button
};

const ButtonDrop = styled('div', {
    shouldForwardProp: (prop) =>
        ![
            'isSmallButtonDrop',
            'isLargeButtonDrop',
            'isPrimaryColor',
            'isSize',
        ].includes(prop),
})(({ isSize, isSmallButtonDrop, isLargeButtonDrop, disabled, full }) => [
    css({
        width: isSmallButtonDrop
            ? DROP_WIDTH.SMALL
            : isLargeButtonDrop
            ? DROP_WIDTH.LARGE
            : DROP_WIDTH.BASE,
    }),
    isSize.tiny && tw`w-full`,
    tw`text-sm rounded outline-none select-none bg-transparent border border-line flex overflow-hidden`,
    tw`hover:border-line-dark`,
    tw`active:shadow-none`,
    disabled &&
        tw`shadow-none hover:shadow-none cursor-not-allowed hover:border-line`,
    full && tw`w-full`,
]);

export const LabelWrap = styled.span`
    ${tw`flex items-center justify-start p-0 whitespace-nowrap`}
    ${({ isPrimaryColor }) => isPrimaryColor && tw`text-primary`}
    svg {
        ${tw`text-icon text-lg mr-2 hover:text-icon-dark`}
        ${({ isPrimaryColor }) => isPrimaryColor && tw`text-primary`}
    }
`;

const SelectedOption = styled.button`
    ${tw`px-4 py-2 border-r w-full`}
    ${tw`hover:text-body-dark`}
    ${tw`focus:outline-none`}
    ${tw`active:bg-solid-light`}
    ${({ disabled }) =>
        disabled &&
        tw`text-body-disabled hover:text-body-disabled cursor-not-allowed`}
    &:hover {
        ${LabelWrap} {
            & svg {
                ${tw`text-icon-dark`}
            }
        }
    }
`;
const SelectedOptionAsAnchor = styled(NavLink)`
    ${tw`px-4 py-2 border-r w-full`}
    ${LabelWrap} {
        ${tw`text-primary hover:text-primary-dark focus:outline-none active:bg-solid-light`}
        & svg {
            ${tw`text-primary-dark`}
        }
    }
    ${({ disabled }) =>
        disabled &&
        tw`text-body-disabled hover:text-body-disabled cursor-not-allowed`}
`;

const IconButtonWrap = styled.div`
    ${tw`text-primary px-3 py-3 pt-1 flex items-center justify-center`}
    ${tw`hover:text-icon-dark hover:bg-solid`}
    ${tw`focus:outline-none focus:bg-solid`}
    ${tw`active:bg-solid`}
    ${({ disabled }) =>
        disabled &&
        tw`text-icon-disabled hover:text-icon-disabled cursor-not-allowed`}
    & button {
        ${tw`text-sm mt-2`}
        // Resolve icon sizing issue in safari
        > div {
            margin-left: 0em;
            margin-right: 0em;
        }
    }
`;

export default ({
    options = [],
    disabled,
    full,
    isHeaderButtonDrop,
    isSize,
}) => {
    if (!options.length) return null;
    const contextRef = useRef();
    const filteredOptions = options.filter(Boolean);

    if (filteredOptions.length === 1) {
        return (
            <Button isSecondary onClick={filteredOptions[0].onClick}>
                <LabelWrap>
                    <Icon name={filteredOptions[0].icon} />{' '}
                    {filteredOptions[0].label}
                </LabelWrap>
            </Button>
        );
    }
    const isAnchorLink = filteredOptions[0].isAnchorLink;

    return (
        <ButtonDrop
            ref={contextRef}
            {...{ disabled, full, isSize }}
            isSmallButtonDrop={filteredOptions[0].isShort}
            isLargeButtonDrop={filteredOptions[0].isLarge}
        >
            {isAnchorLink ? (
                <SelectedOptionAsAnchor
                    to={filteredOptions[0].href}
                    target="_blank"
                    isExternalLink
                    {...{ disabled }}
                >
                    <LabelWrap>
                        <Icon name={filteredOptions[0].icon} />{' '}
                        {filteredOptions[0].label}
                    </LabelWrap>
                </SelectedOptionAsAnchor>
            ) : (
                <SelectedOption
                    {...{ disabled }}
                    onClick={filteredOptions[0].onClick}
                >
                    <LabelWrap>
                        <Icon name={filteredOptions[0].icon} />{' '}
                        {filteredOptions[0].label}
                    </LabelWrap>
                </SelectedOption>
            )}
            <IconButtonWrap>
                <MenuWithIconTrigger
                    iconName="downArrow"
                    isSmall
                    options={filteredOptions.slice(1)}
                    context={contextRef}
                    className={`button-drop-menu ${
                        filteredOptions[0].isShort ? 'small' : ''
                    }`}
                    basic
                    isSmallButtonDrop={filteredOptions[0].isShort}
                    isLargeButtonDrop={filteredOptions[0].isLarge}
                    {...{ isHeaderButtonDrop, isSize }}
                />
            </IconButtonWrap>
        </ButtonDrop>
    );
};
