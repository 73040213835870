import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m19.5 2c0.319 0 0.6238 0.13204 0.842 0.36477l6.2932 6.7128c0.2344 0.24997 0.3648 0.57977 0.3648 0.92242m-7.5-8h-10.5c-2.2091 0-4 1.7909-4 4v20c0 2.2091 1.7909 4 4 4h14c2.2091 0 4-1.7909 4-4v-16m-7.5-8v4c0 2.2091 1.7909 4 4 4h3.5"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m21.696 14.792-2.608 8.208h-1.584c-0.1813 0-0.3067-0.1173-0.376-0.352l-1.472-4.744c-0.048-0.16-0.0933-0.3173-0.136-0.472-0.0373-0.16-0.0693-0.32-0.096-0.48-0.032 0.16-0.0693 0.3227-0.112 0.488-0.0373 0.16-0.08 0.32-0.128 0.48l-1.496 4.728c-0.064 0.2347-0.2053 0.352-0.424 0.352h-1.52l-2.6-8.208h1.576c0.144 0 0.2667 0.0347 0.368 0.104s0.168 0.1573 0.2 0.264l1.176 4.384c0.0587 0.24 0.1093 0.4747 0.152 0.704 0.048 0.2293 0.088 0.4587 0.12 0.688 0.0587-0.2293 0.12-0.4587 0.184-0.688 0.0693-0.2293 0.1413-0.464 0.216-0.704l1.36-4.4c0.032-0.1067 0.0933-0.1947 0.184-0.264 0.096-0.0693 0.2107-0.104 0.344-0.104h0.872c0.1387 0 0.256 0.0347 0.352 0.104 0.1013 0.0693 0.168 0.1573 0.2 0.264l1.32 4.4c0.0693 0.24 0.1333 0.4773 0.192 0.712 0.064 0.2293 0.1253 0.4587 0.184 0.688 0.0693-0.4533 0.168-0.92 0.296-1.4l1.208-4.384c0.0373-0.1067 0.104-0.1947 0.2-0.264s0.2107-0.104 0.344-0.104h1.504z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
