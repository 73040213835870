import { get, post, patchSuppressingWS } from '../../api/helpers.js';
import { meetingModel, occurrenceModel } from './meeting.mappers.js';

export function getMeeting(id) {
    const url = `/api/meeting/${id}`;
    return get(url).then(meetingModel);
}

export function getOccurrence(id) {
    const url = `/api/occurrence/${id}`;
    return get(url).then(occurrenceModel);
}

export function getOccurrenceDocuments(id) {
    const url = `/api/occurrence/${id}/documents`;
    return get(url);
}

export function archiveMeeting(meet) {
    const url = `/api/meeting/${meet.id}/archive`;
    return post(url).then(meetingModel);
}

export function restoreMeeting(meet) {
    const url = `/api/meeting/${meet.id}/restore`;
    return post(url).then(meetingModel);
}

export function deleteMeeting(meet) {
    const url = `/api/meeting/${meet.id}/delete`;
    return post(url);
}

export function deleteOccurrence(occurrence) {
    const url = `/api/occurrence/${occurrence.id}/delete`;
    return post(url);
}

export function leaveMeeting(meet) {
    const url = `/api/meeting/${meet.id}/leave`;
    return post(url).then(meetingModel);
}

export function moveOccurrence(occurrenceId, payload) {
    const url = `/api/occurrence/${occurrenceId}/move`;
    return post(url, payload);
}

export function archiveOccurrence(occurrence) {
    const url = `/api/occurrence/${occurrence.id}/archive`;
    return post(url).then(occurrenceModel);
}

export function restoreOccurrence(occurrence) {
    const url = `/api/occurrence/${occurrence.id}/restore`;
    return post(url).then(occurrenceModel);
}

export function addMeeting(meet, workspaceId) {
    const url = workspaceId
        ? `/api/meeting/add/${workspaceId}`
        : `/api/meeting`;
    return post(url, meet).then(meetingModel);
}

export function editMeeting(id, meet, { activeOccurrenceId } = {}) {
    const url = `/api/meeting/${id}${
        activeOccurrenceId ? `/occurrence/${activeOccurrenceId}` : ''
    }`;
    return post(url, meet).then(meetingModel);
}

export function addOccurrence(occurrence) {
    const url = '/api/occurrence';
    return post(url, occurrence).then(occurrenceModel);
}

export function editOccurrence(id, occurrence) {
    const url = `/api/occurrence/${id}`;
    return post(url, occurrence).then(occurrenceModel);
}

export function updateOccurrenceAttendance(id, userId, action) {
    const url = `/api/occurrence/${id}/${action}/${userId}`;
    return post(url);
}

export function completeOccurrence(id, data) {
    const url = `/api/occurrence/${id}/complete`;
    return post(url, data);
}

export function updateUserDisplayName(meetingId, userId, displayName) {
    const url = `/api/meeting/${meetingId}/attendee/${userId}/update-name`;
    return patchSuppressingWS(url, { displayName });
}

export function updateUserEmail(meetingId, userId, email) {
    const url = `/api/meeting/${meetingId}/attendee/${userId}/update-email`;
    return patchSuppressingWS(url, { email });
}
