import _isFunction from 'lodash/isFunction.js';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

const WhenFieldChanges = ({ field, becomes, set, to, form }) => (
    <Field name={set} subscription={{}}>
        {(
            // No subscription. We only use Field to get to the change function
            { input: { onChange } }
        ) => (
            <OnChange name={field}>
                {(value, previous) => {
                    if (typeof becomes === 'undefined' || value === becomes) {
                        const setTo = _isFunction(to)
                            ? to(value, previous, form)
                            : to;
                        onChange(setTo);
                    }
                }}
            </OnChange>
        )}
    </Field>
);

export default WhenFieldChanges;
