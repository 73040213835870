import React from 'react';
import tw, { styled } from 'twin.macro';

const Button = styled.button`
    ${tw`flex items-center w-full p-3 border border-line relative bg-white leading-tight`}
    margin-bottom: -1px;
    &:hover,
    &:focus {
        ${tw`text-body-dark outline-none`}
    }
    &:active {
        ${tw`bg-solid border-line-dark z-10`}
    }
    ${({ isActive }) => isActive && tw`bg-solid border-line-dark z-10`}
    ${({ disabled }) => disabled && tw`shadow-none cursor-not-allowed`}
  ${({ isFirst }) => isFirst && tw`rounded-t`}
  ${({ isLast }) => isLast && tw`rounded-b`}
`;
const Text = styled.span`
    ${tw`w-full text-left`}
`;
const Label = styled.span`
    ${tw`text-xs text-icon ml-2 rounded-sm h-full flex-shrink-0 leading-tight`}
`;

export default ({ text, number, isLast, isFirst, isActive }) => (
    <Button {...{ isLast, isFirst, isActive }}>
        <Text>{text}</Text>
        <Label>{number}</Label>
    </Button>
);
