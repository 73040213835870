import {
    MODAL_SELECTOCCURRENCE_OPEN,
    MODAL_SELECTOCCURRENCE_CLOSE,
    MODAL_SELECTOCCURRENCE_LOADING,
    MODAL_SELECTOCCURRENCE_PROCEED_SUCCESS,
    MODAL_SELECTOCCURRENCE_PROCEED_ERROR,
} from '~common/action.types';

export function proceedModal(promise, values) {
    return (dispatch) => {
        dispatch({
            type: MODAL_SELECTOCCURRENCE_LOADING,
        });

        promise(values)
            .then(() => {
                dispatch({
                    type: MODAL_SELECTOCCURRENCE_PROCEED_SUCCESS,
                });
            })
            .catch((error) => {
                dispatch({
                    type: MODAL_SELECTOCCURRENCE_PROCEED_ERROR,
                    error,
                });
            });
    };
}

export function cancelModal() {
    return (dispatch) => {
        dispatch({
            type: MODAL_SELECTOCCURRENCE_CLOSE,
        });
    };
}

/**
 * Open the Select Occurrence modal. Calls promise on successful submit in the modal.
 * @param {Object} props list of props to pass through to the modal.
 * @param {string} [props.header] header for the modal. Default is "Select occurrence".
 * @param {string} [props.subtitle] subtitle for the modal. If ommitted, no subtitle is displayed.
 * @param {string} [props.submitButtonLabel] text to display on the Submit button. Default is "Save".
 * @param {Promise} props.promise The promise to execute on successful submit.
 * @param {string|Object} [props.meeting] The meeting.id or meeting to display as the initial selected option.
 * @param {string|Object} [props.occurrence] The occurrence.id or occurrence to display as the initial selected option.
 * @param {string[]} [props.excludeOccurrences] List of occurrence.id to be excluded from the Occurrences selection.
 * @param {moment} [props.fromDate] Occurrences with a startDate prior to this date will be excluded from the list of options. If ommitted, only occurrences after the start of "today" will be displayed.
 * @param {string|React.JSX} props.instructionMessageText Instructional message text to display in the modal. Can be multi-line if sent as JSX. Default is "Select the target meeting".
 */
export function openModal(props) {
    return (dispatch) => {
        dispatch({
            type: MODAL_SELECTOCCURRENCE_OPEN,
            data: { ...props },
        });
    };
}
