import { useSelector, useDispatch } from 'react-redux';
import { onGroupSave } from '~modules/group/group.actions';
import {
    getMeetingGroupsSelector,
    getGroupSelector,
    getMeetingGroupsForWorkspace,
} from '~modules/group/group.selectors';
import { getDashboardPathParams } from '~modules/navigation';

const useMeetingGroups = () => useSelector(getMeetingGroupsSelector);

const useMeetingGroupsForWorkspace = (workspace) =>
    useSelector((state) => getMeetingGroupsForWorkspace(state, { workspace }));

const useGroupCurrent = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
    const [, , groupId] = getDashboardPathParams(location.pathname);
    const group = useSelector((state) =>
        getGroupSelector(state, { group: groupId })
    );
    return group || {};
};

/**
 * Usage:
 * const addGroup = useAddGroup();
 * addGroup({ title: e.value });
 */
const useAddGroup = () => {
    const dispatch = useDispatch();
    return (values, workspace) =>
        dispatch(onGroupSave({ ...values }, workspace));
};

export {
    useMeetingGroups,
    useMeetingGroupsForWorkspace,
    useGroupCurrent,
    useAddGroup,
};
