import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ObjectID from 'bson-objectid';
import { RTE_TINYMCE } from '~common/constants';
import {
    onPrivateNotesSave,
    onPrivateNotesCreate,
} from '~modules/privatenotes/privateNotes.actions';
import { getOccurrenceIdSelector } from '~common/selector.helpers';
import { logDocumentToConsole } from '~modules/richtext/helpers';

export const useUpdatePrivateNote = ({ occurrence, note }) => {
    const dispatch = useDispatch();
    const occurrenceId = getOccurrenceIdSelector(null, { occurrence });

    return useCallback(
        (content) => {
            // in Slate, html content was stored without newline markers.
            // Tiny inserts newline markers. So for legacy comparison, we ignore any newline markers.
            const before = (note?.content || '').replace(/\n/g, '');
            const after = (content || '').replace(/\n/g, '');
            if (after !== before) {
                logDocumentToConsole(
                    'useUpdatePrivateNote: ',
                    {
                        before,
                        after,
                    },
                    'debug'
                );

                if (note?.id) {
                    dispatch(
                        onPrivateNotesSave(occurrenceId, {
                            id: note.id,
                            content: content || null, // to allow content to be set back to blank
                            rte: RTE_TINYMCE,
                        })
                    );
                } else {
                    dispatch(
                        onPrivateNotesCreate(occurrenceId, {
                            id: ObjectID().toString(),
                            content: content || null, // to allow content to be set back to blank
                            rte: RTE_TINYMCE,
                        })
                    );
                }
            }
        },
        [dispatch, occurrenceId, note?.id, note?.content]
    );
};
