import { MODAL_ADD_MEETING_SIMPLE_OPEN } from '~common/action.types';
import type { OpenSimpleAddMeetingModal } from '~modules/forms/addMeeting/addMeeting.types';

type OpenSimpleAddMeetingModalDispatch = OpenSimpleAddMeetingModal & {
    type: string;
};

export const openSimpleAddMeetingModal = ({
    dateStart,
    workspaceId,
    categoryData,
    isAddingMeetingToIntro,
    includeWithExistingMeetingId,
    eventData,
    createdSourceLocation,
    meetingType,
    isAddingWorkspace,
    isMergingEvent,
    isAddingEventAsNew,
    mergeWithExistingMeetingId,
}: OpenSimpleAddMeetingModal): OpenSimpleAddMeetingModalDispatch => ({
    type: MODAL_ADD_MEETING_SIMPLE_OPEN,
    dateStart,
    workspaceId,
    categoryData,
    isAddingMeetingToIntro,
    includeWithExistingMeetingId,
    eventData,
    createdSourceLocation,
    meetingType,
    isAddingWorkspace,
    isMergingEvent,
    isAddingEventAsNew,
    mergeWithExistingMeetingId,
});
