import React from 'react';
import { Loader as SemanticLoader } from 'semantic-ui-react';
import tw, { styled, theme } from 'twin.macro';

const StyledLoader = styled(SemanticLoader, {
    shouldForwardProp: (prop) => !['isInverted'].includes(prop),
})`
    .ui.inverted.dimmer &.ui.loader {
        ${({ isInverted }) => (isInverted ? tw`text-white` : tw`text-body`)}
    }
    &.ui.loader:after {
        border-color: ${({ isInverted }) =>
                isInverted ? 'white' : theme`colors.primary.DEFAULT`}
            transparent transparent !important;
    }
`;

const Loader = (props) => <StyledLoader {...props} />;

export default Loader;
