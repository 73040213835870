import _isEqual from 'lodash/isEqual.js';
import _omit from 'lodash/omit.js';
import React, { Component, forwardRef } from 'react';
import {
    Input as InputComponent,
    FormField,
    Label,
} from '~modules/common/form-elements';
import { PropTypes } from 'prop-types';
import tw, { styled } from 'twin.macro';

const Error = tw.span`text-error-icon text-sm`;
const Wrap = styled.label`
    .ui.form .field > & {
        ${tw`m-0`}
    }
    .ui.form & input {
        ${tw`text-sm`}
    }
`;

const InputFieldComponent = forwardRef(
    (
        {
            input,
            label,
            required,
            width,
            inline,
            disabled,
            meta: { touched, dirty, error, submitError },
            ...custom
        },
        ref
    ) => (
        <FormField
            error={!!(touched && error) || !!submitError}
            {...{ required, width, inline }}
        >
            <Wrap>
                {label && (
                    <Label {...{ touched, error, submitError }}>{label}</Label>
                )}
                <InputComponent
                    ref={ref}
                    as="div"
                    hasBorder
                    isDisabled={disabled}
                    {...{ required }}
                    {...input}
                    {...custom}
                />
            </Wrap>
            {touched && dirty && error && error !== 'Required' && (
                <Error>{error}</Error>
            )}
            {submitError && <Error>{submitError}</Error>}
        </FormField>
    )
);

InputComponent.propTypes = {
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    required: PropTypes.bool,
    width: PropTypes.number,
    inline: PropTypes.bool,
    meta: PropTypes.object,
};

export default class InputField extends Component {
    shouldComponentUpdate(nextProps) {
        return !_isEqual(this.props, nextProps);
    }

    render() {
        return (
            <InputFieldComponent
                ref={this.props.innerRef || this.props.ref}
                {..._omit(this.props, ['innerRef', 'ref'])}
            />
        );
    }
}
