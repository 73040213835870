import React from 'react';

const HELP_ID = 'shape-help';

const HelpIconInner = ({ iconThick }: { iconThick?: boolean }): JSX.Element => (
    <>
        <rect
            x="2"
            y="2"
            width="28"
            height="28"
            rx="14"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m16 20c0-6.3125 4-4.0436 4-8.0374 0-2.2-1.8-3.9625-4-3.9625s-4 1.9-4 4.025"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m17.25 23.75c0 0.6904-0.5596 1.25-1.25 1.25s-1.25-0.5596-1.25-1.25 0.5596-1.25 1.25-1.25 1.25 0.5596 1.25 1.25z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
        />
    </>
);

const HelpIcon = ({
    iconThick = false,
    isSymbolRef = false,
    title = '',
    ...rest
}): JSX.Element => (
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        {isSymbolRef ? (
            <use href={`#${HELP_ID}`} xlinkHref={`#${HELP_ID}`} />
        ) : (
            <HelpIconInner iconThick={iconThick} />
        )}
    </svg>
);

export { HelpIcon as default, HelpIconInner, HELP_ID };
