import { useEffect, useState } from 'react';
import { getNewCommentCountForMeetingSincePreviousOccurrence } from '~modules/comment/comment.api';
import { useUser } from '~hooks';
import airbrake from '~common/airbrake';

const useMeetingHasActionItemComments = (occurrence) => {
    const user = useUser();
    const [meetingHasActionItemComments, setMeetingHasActionItemComments] =
        useState(false);
    const { previousActionItemReview } = occurrence;

    useEffect(() => {
        if (!previousActionItemReview) return;
        (async () => {
            try {
                const commentsAddedSinceLast =
                    await getNewCommentCountForMeetingSincePreviousOccurrence(
                        occurrence.id
                    );

                // Write boolean result to state to be returned by hook call
                setMeetingHasActionItemComments(
                    commentsAddedSinceLast.commentCount > 0
                );
            } catch (error) {
                // If error set to true and log to airbrake
                setMeetingHasActionItemComments(true);
                airbrake.notify({
                    error,
                    session: user,
                });
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Run once on mount

    return meetingHasActionItemComments;
};

export { useMeetingHasActionItemComments };
