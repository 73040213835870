import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import tw, { styled, css } from 'twin.macro';
import Icon from '~icons';

const SelectWrap = styled.div(({ formFieldHeight }) => [
    tw`flex flex-col space-y-2 w-full`,
    css`
        .ui.selection.dropdown .visible.menu.transition {
            ${tw`m-0 p-0 rounded-t-none left-[-1px]`}
            .item {
                ${tw`border-none`}
            }
        }
        .ui.dropdown .menu .active.selected.item {
            ${tw`max-w-full`}
        }
        .ui.dropdown,
        .ui.selection.dropdown {
            ${tw`w-full bg-transparent hocus:border-line px-4 py-3`};
            height: ${formFieldHeight};
            .text {
                ${tw`leading-6`}
            }
        }
        // Borders
        .ui.selection.dropdown,
        .ui.selection.dropdown.active,
        .ui.selection.dropdown.active .menu,
        .ui.selection.dropdown .menu {
            ${tw`border-line`}
        }
        .ui.selection.dropdown:hover,
        .ui.selection.dropdown.active:hover,
        .ui.selection.dropdown:hover .menu,
        .ui.selection.dropdown.active:hover .menu {
            ${tw`border-line-dark`}
        }
        // Option
        .ui.dropdown .menu .selected.item {
            ${tw`bg-transparent`}
        }
        .ui.dropdown .menu > .item:hover {
            ${tw`bg-solid`}
        }
    `,
]);
const IconWrap = styled.div(({ formFieldHeight }) => [
    tw`text-icon hover:text-icon-dark text-xs absolute
    p-4 cursor-pointer z-10 top-0 right-0 flex items-center`,
    css`
        height: ${formFieldHeight};
    `,
]);

const SelectDropdown = ({ options, formFieldHeight, ...rest }) => (
    <SelectWrap {...{ formFieldHeight }}>
        <Dropdown
            fluid
            selection
            icon={
                <IconWrap {...{ formFieldHeight }}>
                    <Icon name="downArrow" />
                </IconWrap>
            }
            {...{ options }}
            {...rest}
        />
    </SelectWrap>
);

export default SelectDropdown;
