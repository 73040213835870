import _get from 'lodash/get.js';
import moment from 'moment-timezone';

export function pageViewModel(pageView) {
    if (!pageView) {
        return;
    }

    const lastOpenedAt = moment(pageView.lastOpenedAt);
    const firstOpenedAt = moment(pageView.firstOpenedAt);
    const startDate = _get(pageView, 'occurrenceMeta.startDate')
        ? moment(pageView.occurrenceMeta.startDate)
        : null;
    const endDate = _get(pageView, 'occurrenceMeta.endDate')
        ? moment(pageView.occurrenceMeta.endDate)
        : null;
    const startDateActual = _get(pageView, 'occurrenceMeta.startDateActual')
        ? moment(pageView.occurrenceMeta.startDateActual)
        : null;
    const endDateActual = _get(pageView, 'occurrenceMeta.endDateActual')
        ? moment(pageView.occurrenceMeta.endDateActual)
        : null;

    return {
        ...pageView,
        lastOpenedAt,
        firstOpenedAt,
        occurrenceMeta: {
            ...pageView.occurrenceMeta,
            startDate,
            endDate,
            startDateActual,
            endDateActual,
        },
    };
}

export function pageViewModelList(pageViews = []) {
    return pageViews.map(pageViewModel);
}
