import React from 'react';

export default ({ title = 'Whereby', ...rest }): JSX.Element => (
    <svg viewBox="0 0 32 28" xmlns="http://www.w3.org/2000/svg" {...rest}>
        {title && <title>{title}</title>}
        <path
            d="m27.372 0.16279c-2.4419 0-5.0698 1.8837-7.1628 6.3023l-2.6977-6.4651h-0.1628c-0.4418 1.0233-1.8372 1.5814-3.1395 1.5814h-14.209v0.27907c1.1395 0.48837 2.1395 2.1395 3.0698 4.3721l8.8372 21.326h0.279l5.7442-13.884 5.7442 13.884h0.2791l4.907-12.186c1.9069-4.7907 3.1395-7.4186 3.1395-9.814-0.0465-3.1628-1.814-5.3954-4.6279-5.3954zm-13.256 20-5.721-13.907c-0.93023-2.2791-0.06977-3.8837 2.279-3.8837 2.0698 0 3.093 1.3721 4.1396 3.8605l2.5348 6.1162-3.2325 7.814zm13.721-5.2093-2 5.0698-5.3023-12.721c1.3023-1.8837 2.9767-2.5814 4.9302-2.5814 2.3023 0 3.6977 1.5814 3.6977 4.4419 0 1.5581-0.4186 3.5349-1.3256 5.7907z"
            fill="#000"
        />
    </svg>
);
