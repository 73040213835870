import { createSelectorCreator, defaultMemoize } from 'reselect';
import _isEqual from 'lodash/isEqual.js';
import _isString from 'lodash/isString.js';
import moment from 'moment-timezone';

export const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    _isEqual
);

export const getUserIdSelector = (_, props) => props?.user?.id || props.user;

export const getOccurrenceIdSelector = (_, props) =>
    props?.occurrence?.id || props.occurrence;

export const getMeetingIdSelector = (_, props) =>
    props?.meeting?.id || props?.meeting || props?.occurrence?.meeting;

export const getWorkspaceIdSelector = (_, props) =>
    props?.workspace?.id || props?.workspace || props;

export const getGroupIdSelector = (_, props) => {
    return (
        props?.group?.id ||
        (_isString(props?.group)
            ? props.group
            : _isString(props)
            ? props
            : undefined)
    );
};

export const getToday = () => moment().formatForUser('LL');

export const getTimeNow = () => moment().formatForUser('LT');

export const sortByCreatedAt = (a, b) =>
    b.createdAt.valueOf() - a.createdAt.valueOf();
