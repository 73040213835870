import _isEqual from 'lodash/isEqual.js';
import {
    useEffect,
    useState,
    useRef,
    useCallback,
    useLayoutEffect,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getBrowserVisibilityProp, getIsDocumentVisible } from '~common/utils';
import { IS_PRODUCTION } from '~common/constants';
import { useMinScreen } from '~common/minScreens';
import { useUI, useIsSidebarPinned } from '~common/ui';
import { useIsModal } from '~modules/modals/components/isModal';

/**
 * If you find yourself adding a lot of event listeners using useEffect you might consider moving that logic to a custom hook
 * https://usehooks.com/useEventListener/
 */
const useEventListener = (eventName, handler, element) => {
    // Create a ref that stores handler
    const savedHandler = useRef();
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener = (event) => savedHandler.current(event);

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
};

/**
 * Call a function if clicked outside of an element
 */
const useClickOutside = (ref, cb) => {
    const handleClickOutside = ({ target }) => {
        // If multiple references are passed then check them all
        if (typeof ref[Symbol.iterator] === 'function') {
            const clickCheckArray = ref.map(
                ({ current }) => current && !current.contains(target)
            );
            if (!clickCheckArray.every((item) => item)) {
                return;
            }
            return cb();
        }
        // Check a single reference
        if (ref.current && !ref.current.contains(target)) {
            return cb();
        }
    };
    useEventListener('mouseup', handleClickOutside);
};

/**
 * Detect whether the mouse is hovering an element
 * https://usehooks.com/useHover/
 */
const useHover = () => {
    const [value, setValue] = useState(false);

    const ref = useRef(null);

    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);

    useEffect(
        () => {
            const node = ref.current;
            if (node) {
                node.addEventListener('mouseover', handleMouseOver);
                node.addEventListener('mouseout', handleMouseOut);

                return () => {
                    node.removeEventListener('mouseover', handleMouseOver);
                    node.removeEventListener('mouseout', handleMouseOut);
                };
            }
        },
        [] // Recall only if ref changes
    );

    return [ref, value];
};

/**
 * This hook makes it easy to see which prop changes are causing a component to re-render
 * https://usehooks.com/useWhyDidYouUpdate/
 */
const useWhyDidYouUpdate = (name, props) => {
    // Get a mutable ref object where we can store props ...
    // ... for comparison next time this hook runs.
    const previousProps = useRef();

    useEffect(() => {
        if (!IS_PRODUCTION && previousProps.current) {
            // Get all keys from previous and current props
            const allKeys = Object.keys({ ...previousProps.current, ...props });
            // Use this object to keep track of changed props
            const changesObj = {};
            // Iterate through keys
            allKeys.forEach((key) => {
                // If previous is different from current
                if (previousProps.current[key] !== props[key]) {
                    // Add to changesObj
                    changesObj[key] = {
                        from: previousProps.current[key],
                        to: props[key],
                    };
                }
            });

            // If changesObj not empty then output to console
            if (Object.keys(changesObj).length) {
                // eslint-disable-next-line no-console
                console.log('[why-did-you-update]', name, changesObj);
            }
        }

        // Finally update previousProps with current props for next hook call
        previousProps.current = props;
    });
};

/**
 * This hook makes it easy to detect when the user is pressing a specific key on their keyboard and return true/false.
 * See also the useKeyboardEvent hook.
 * https://usehooks.com/useKeyPress/
 */
const useKeyPress = (targetKey) => {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    // Add event listeners
    useEffect(() => {
        // If pressed key is our target key then set to true
        function downHandler({ key }) {
            if (key === targetKey) {
                setKeyPressed(true);
            }
        }

        // If released key is our target key then set to false
        const upHandler = ({ key }) => {
            if (key === targetKey) {
                setKeyPressed(false);
            }
        };

        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [targetKey]); // Empty array ensures that effect is only run on mount and unmount

    return keyPressed;
};

/**
 * Identify when a key is pressed and trigger a callback function.
 * The key press listening can be bound to a specific element.
 * See also the useKeyPress hook.
 * @param {string} key callback will be triggered when this key is pressed. E.g. 'Enter'.
 * @param {Ref} [source=window] Ref to react element if you wish to restrict the event listening to a specific element. Otherwise will be 'window'.
 * @param {string} [subRefProp] reference to any sub-ref prop that allows you to get the right DOM element.
 *                            This is particularly relevant for Semantic UI that makes inner elements available via another ref.
 * @param {function} callback function to execute when key is pressed.
 */
function useKeyboardEvent() {
    let key, source, subRefProp, callback;
    switch (arguments.length) {
        case 4:
            [key, source, subRefProp, callback] = arguments;
            break;
        case 3:
            [key, source, callback] = arguments;
            break;
        case 2:
            [key, callback] = arguments;
            source = {};
    }

    useEffect(() => {
        const initialNode = source.current
            ? subRefProp
                ? source.current[subRefProp].current
                : source.current
            : window;
        const handler = function (event) {
            if (event.key === key) {
                callback(event);
            }
        };
        initialNode.addEventListener('keydown', handler);
        return () => {
            initialNode.removeEventListener('keydown', handler);
        };
    }, [callback, key, source, subRefProp]);
}

/**
 * Use a prop from a previous render
 */
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

/**
 * Use a prop from a previous render by value, rather than by reference.
 * This means, if a deep compare says the objects are the same, return the previous version.
 */
const usePreviousByValue = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = _isEqual(ref.current, value) ? ref.current : value;
    });
    return ref.current;
};

function getDimensionObject(node) {
    const rect = node.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        top: 'x' in rect ? rect.x : rect.top,
        left: 'y' in rect ? rect.y : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
    };
}

/**
 * A React Hook to measure DOM nodes on resize/scroll
 * https://github.com/Swizec/useDimensions/blob/master/src/index.ts
 */
const useDimensions = (liveMeasure = true, measureOnScroll = false) => {
    const [dimensions, setDimensions] = useState({});
    const [node, setNode] = useState(null);

    const ref = useCallback((node) => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                window.requestAnimationFrame(() =>
                    setDimensions(getDimensionObject(node))
                );
            measure();

            if (liveMeasure) {
                window.addEventListener('resize', measure);
                if (measureOnScroll) window.addEventListener('scroll', measure);

                return () => {
                    window.removeEventListener('resize', measure);
                    if (measureOnScroll)
                        window.removeEventListener('scroll', measure);
                };
            }
        }
    }, [liveMeasure, measureOnScroll, node]);

    return [ref, dimensions, node];
};

/**
 * Usage:
 * const pushHistory = usePushHistory();
 * pushHistory(`url to push`)
 */
const usePushHistory = () => {
    const history = useHistory();
    return (pushLocation) => {
        history.push(pushLocation); // TODO... maybe this should use connected-react-router to stop console warnings about bad setState
    };
};

/**
 * Determine if the MinuteMe web page is visible... i.e. if the browser tab has focus, and is in the foreground.
 * Returns true or false when the visibility changes, which would trigger component updates.
 */
const usePageVisibility = () => {
    const [isVisible, setIsVisible] = useState(getIsDocumentVisible());
    const onVisibilityChange = () => setIsVisible(getIsDocumentVisible());

    useEffect(() => {
        const visibilityChange = getBrowserVisibilityProp();
        window.addEventListener(visibilityChange, onVisibilityChange, false);
        return () => {
            window.removeEventListener(
                visibilityChange,
                onVisibilityChange,
                false
            );
        };
    }, []);

    return isVisible;
};

const useBodyScrollLock = (hideBodyOverflow) => {
    const [original] = useState(
        window.getComputedStyle(document.body).overflow
    );

    useLayoutEffect(() => {
        document.body.style.overflow = hideBodyOverflow ? 'hidden' : original;

        return () => {
            document.body.style.overflow = original;
        };
    }, [original, hideBodyOverflow]);
};

export {
    // Note: Give hooks their own file - no new exports here
    useEventListener,
    useClickOutside,
    useHover,
    useWhyDidYouUpdate,
    useKeyPress,
    useKeyboardEvent,
    usePrevious,
    usePreviousByValue,
    useDimensions,
    usePushHistory,
    usePageVisibility,
    useMinScreen,
    useUI,
    useIsSidebarPinned,
    useIsModal,
    useBodyScrollLock,
    // Vendor hooks
    useHistory,
    useLocation,
};
