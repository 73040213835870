import React from 'react';
import tw, { styled } from 'twin.macro';

const Container = tw.div`relative`;
const Unread = styled.div`
    top: -7px;
    right: -5px;
    ${tw`bg-warning-icon rounded-full w-3 h-3 absolute`}
`;

export default ({
    iconThick = false,
    title = 'Notification center',
    hasUnreadNotifications = false,
}) => (
    <Container>
        {hasUnreadNotifications && <Unread />}
        <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            {title && <title>{title}</title>}
            <path
                d={
                    !hasUnreadNotifications
                        ? 'm26.982 19.734 0.587 0.6463c0.1762 0.2068 0.3131 0.362 0.411 0.4654 0.0978 0.1034 0.2201 0.2412 0.3669 0.4136 0.1468 0.1723 0.2495 0.3102 0.3082 0.4136s0.1272 0.2284 0.2055 0.3749c0.0782 0.1465 0.1223 0.2714 0.1321 0.3748 0.0097 0.1034 0.0097 0.2198 0 0.349-0.0098 0.1293-0.0539 0.2542-0.1321 0.3749-0.2936 0.5687-1.0861 0.8531-2.3776 0.8531h-20.899c-1.2915 0-2.1036-0.2844-2.4363-0.8531 0 0-0.13577-0.3249-0.14677-0.5429-0.01-0.1984 0.07339-0.5041 0.07339-0.5041l0.3082-0.5688 0.41094-0.5687 0.52835-0.6592c0.0587-0.0517 0.27395-0.293 0.64576-0.7239 0.3718-0.4308 0.62618-0.7368 0.76316-0.9177 0.13698-0.181 0.33267-0.4567 0.58706-0.8273 0.25439-0.3705 0.43051-0.7152 0.52835-1.0341 0.09784-0.3188 0.14676-0.6333 0.14676-0.9436v-5.403c0-1.5339 0.39137-2.9471 1.1741-4.2397 0.78274-1.2926 1.8639-2.3181 3.2435-3.0764 1.3796-0.75833 2.8912-1.1375 4.535-1.1375 1.2524 0 2.4363 0.22405 3.5517 0.67215s2.0693 1.047 2.8618 1.7967c0.7926 0.74971 1.4237 1.6459 1.8933 2.6886 0.4696 1.0427 0.7045 2.1414 0.7045 3.2961v5.403l0.1174 0.8144 0.3375 0.8531 0.4403 0.7755 0.5577 0.7627 0.5724 0.6721z'
                        : 'm26.064 17.034 0.7792 0.4771c0.2377 0.1561 0.4208 0.2718 0.5494 0.347 0.1285 0.0751 0.2919 0.1778 0.4899 0.3079 0.1981 0.1302 0.3419 0.2387 0.4315 0.3255 0.0897 0.0869 0.1956 0.1926 0.3177 0.3171s0.2037 0.2375 0.2447 0.339c0.0409 0.1015 0.0761 0.219 0.1054 0.3524 0.0294 0.1335 0.0234 0.2728-0.018 0.418-0.1201 0.6621-0.8223 1.1863-2.1068 1.5725l-20.785 6.25c-1.2845 0.3863-2.1781 0.342-2.6808-0.1328 0 0-0.23322-0.2875-0.31005-0.5044-0.0699-0.1973-0.07938-0.5309-0.07938-0.5309l0.13462-0.6665 0.2368-0.6972 0.32621-0.8237c0.04276-0.0697 0.18391-0.3778 0.42346-0.924 0.23955-0.5463 0.40008-0.9313 0.48162-1.155s0.19281-0.5606 0.33382-1.0109c0.141-0.4502 0.21198-0.851 0.21292-1.2022s-0.04547-0.6834-0.13923-0.9967l-1.6331-5.4559c-0.46361-1.5488-0.50152-3.0929-0.11373-4.6322 0.38779-1.5393 1.1531-2.8981 2.296-4.0764 1.1428-1.1783 2.5317-2.0132 4.1665-2.5048 1.2455-0.37453 2.4907-0.50234 3.7354-0.38343 1.2448 0.11892 2.3746 0.43838 3.3894 0.95841 1.0147 0.52003 1.9133 1.2363 2.6955 2.1487 0.7822 0.91243 1.3478 1.9516 1.6969 3.1177l1.633 5.4558 0.3629 0.7872 0.5936 0.7605 0.6723 0.6515 0.7851 0.6033 0.7724 0.5075z'
                }
                stroke="currentColor"
                strokeWidth={iconThick ? '2' : '1.5'}
                vectorEffect="non-scaling-stroke"
            />
            <path
                d={
                    !hasUnreadNotifications
                        ? 'm21 25c0 0.6566-0.1293 1.3068-0.3806 1.9134s-0.6196 1.1578-1.0839 1.6221-1.0155 0.8326-1.6221 1.0839-1.2568 0.3806-1.9134 0.3806-1.3068-0.1293-1.9134-0.3806-1.1578-0.6196-1.6221-1.0839-0.8326-1.0155-1.0839-1.6221-0.3806-1.2568-0.3806-1.9134'
                        : 'm16.827 24c0.164 0.5761 0.2122 1.1828 0.1418 1.7852-0.0703 0.6024-0.2578 1.1889-0.5517 1.7259-0.2939 0.5371-0.6885 1.0141-1.1612 1.404-0.4727 0.3898-1.0143 0.6848-1.5939 0.8682-0.5796 0.1833-1.1858 0.2513-1.784 0.2002s-1.1766-0.2204-1.7024-0.4981c-0.52574-0.2778-0.98845-0.6586-1.3617-1.1208-0.37326-0.4621-0.64975-0.9965-0.81369-1.5726'
                }
                stroke="currentColor"
                strokeWidth={iconThick ? '2' : '1.5'}
                vectorEffect="non-scaling-stroke"
            />
        </svg>
    </Container>
);
