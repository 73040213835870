// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _set from 'lodash';
import {
    get,
    patch,
    deleteRequest,
    post,
    put,
    postForm,
} from '~client/api/helpers';
import { workspaceInvoiceList } from './workspace.mappers.js';

export function getMembersForWorkspace(id) {
    const url = `/api/workspace/${id}/members`;
    return get(url);
}

export const getMemberWorkspacePermission = (workspaceId, userId) =>
    get(`/api/workspace/${workspaceId}/member/${userId}`);

export function getMemberMeetingsAndPermissions(workspaceId, userId) {
    return get(`/api/workspace/${workspaceId}/member/${userId}/meetings`);
}

export const getRecommendations = () => get(`/api/workspace/recommendations`);

export const getInvoicesForWorkspace = (workspaceId) =>
    get(`/api/workspace/${workspaceId}/invoices`).then(workspaceInvoiceList);

export function updateWorkspacePermission(workspaceId, permissionId, payload) {
    const url = `/api/workspace/${workspaceId}/permission/${permissionId}`;
    return patch(url, payload);
}

export function deleteWorkspace(workspaceId) {
    const url = `/api/workspace/${workspaceId}`;
    return deleteRequest(url);
}

export function deleteWorkspacePermission(workspaceId, permissionId) {
    const url = `/api/workspace/${workspaceId}/permission/${permissionId}`;
    return deleteRequest(url);
}

export function deleteWorkspacePermissionAndReassignMeetings(
    workspaceId,
    permissionId,
    payload
) {
    const url = `/api/workspace/${workspaceId}/permission/${permissionId}`;
    return post(url, payload);
}

export function addWorkspacePermission(workspaceId, payload) {
    const url = `/api/workspace/${workspaceId}/member/add`;
    return post(url, payload);
}

export function addWorkspacePermissionRequest(payload) {
    const url = '/api/workspace/member/request';
    return post(url, payload);
}

export function sendWorkspaceAddMeetingAttemptEmail(workspaceId) {
    const url = `/api/workspace/${workspaceId}/limit-hit`;
    return post(url);
}

export function leaveWorkspace(workspaceId) {
    const url = `/api/workspace/${workspaceId}/member/leave`;
    return post(url);
}

export function cancelWorkspaceRequest(workspaceId) {
    const url = `/api/workspace/${workspaceId}/request/cancel`;
    return post(url);
}

export function addDomainToWorkspace(workspaceId, payload) {
    const url = `/api/workspace/${workspaceId}/domain/add`;
    return patch(url, payload);
}

export function removeDomainFromWorkspace(workspaceId, payload) {
    const url = `/api/workspace/${workspaceId}/domain/delete`;
    return patch(url, payload);
}

export function renameWorkspace(workspaceId, payload) {
    const url = `/api/workspace/${workspaceId}/rename`;
    return patch(url, payload);
}

export function startWorkspaceFeature(workspaceId, featureName) {
    const url = `/api/workspace/${workspaceId}/start-feature/${featureName}`;
    return patch(url);
}

export function addWorkspace(payload) {
    return put('/api/workspace', payload);
}

export const moveMeetingToAnotherWorkspace = (meetingId, payload) =>
    post(`/api/workspace/meeting/${meetingId}/move`, payload);

export const upgradeWorkspace = (workspaceId, payload) =>
    post(`/api/workspace/${workspaceId}/upgrade`, payload);

export const downgradeWorkspace = (workspaceId, payload) =>
    post(`/api/workspace/${workspaceId}/downgrade`, payload);

export const updatePaymentWorkspace = (workspaceId, payload) =>
    patch(`/api/workspace/${workspaceId}/paymentDetails`, payload);

export const getSecretForWorkspace = (workspaceId) =>
    get(`/api/workspace/${workspaceId}/secret`, workspaceId);

export const getCouponDetails = (workspaceId, couponId, currency) =>
    get(
        `/api/workspace/${workspaceId}/coupon/${couponId}/currency/${currency}`
    );

export const hideWorkspace = (workspaceId) =>
    patch(`/api/workspace/${workspaceId}/hide`);

export const unhideWorkspace = (workspaceId) =>
    patch(`/api/workspace/${workspaceId}/unhide`);

export async function uploadWorkspaceLogo(workspaceId, file, workspaceTitle) {
    const formData = new FormData();
    const filename = `${workspaceTitle
        ?.toLowerCase()
        .replace(/\s/g, '-')
        .replace("'", '')}-logo`;
    formData.append('file', file, filename);

    const url = `/api/workspace/${workspaceId}/logo`;

    const workspaceModel = await postForm(url, formData);
    // Hack: cloudfront seems to have some sort of replication delay
    // So we use a object URL for the blob we uploaded
    _set(workspaceModel, 'logo.path', window.URL.createObjectURL(file));
    return workspaceModel;
}

export function removeWorkspaceLogo(workspaceId) {
    const url = `/api/workspace/${workspaceId}/logo`;

    return deleteRequest(url);
}

export function updateMemberDisplayName(workspaceId, userId, displayName) {
    const url = `/api/workspace/${workspaceId}/member/${userId}/update-name`;

    return patch(url, { displayName });
}
