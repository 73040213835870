import React from 'react';
import tw, { styled } from 'twin.macro';
import Icon from '~icons';
import { Loader } from '~modules/common/components';

export const commonClasses = {
    base: tw`outline-none leading-tight select-none flex items-center justify-center`,
    hover: tw`hover:text-body-dark`,
    focus: tw`focus:outline-none`,
    active: tw``,
    disabled: tw`shadow-none hover:shadow-none cursor-not-allowed`,
};

export const primaryClasses = {
    base: tw`rounded text-white bg-primary px-6 md:px-8 py-2.5 shadow-sm`,
    hover: tw`hover:(text-white bg-primary-light shadow)`,
    active: tw`active:(bg-primary-dark shadow-none)`,
    disabled: tw`shadow-none text-white bg-primary-disabled hover:bg-primary-disabled active:bg-primary-disabled`,
};

export const secondaryClasses = {
    base: tw`rounded bg-transparent border border-line px-6 md:px-8 py-2.5 shadow-sm`,
    hover: tw`hover:(text-body-dark border-line-dark shadow)`,
    active: tw`active:(bg-solid-light shadow-none)`,
    disabled: tw`shadow-none text-body-disabled hover:(text-body-disabled border-line) active:bg-transparent`,
};

export const tealClasses = {
    base: tw`rounded bg-teal px-6 md:px-8 py-2.5 shadow-sm`,
    hover: tw`hover:(text-white bg-teal-light shadow)`,
    active: tw`active:(bg-teal-light shadow-none)`,
    disabled: tw`shadow-none bg-teal-light opacity-50 hover:text-white`,
};

const warningClasses = {
    base: tw`rounded text-white bg-error-icon px-6 md:px-8 py-2.5 shadow-sm`,
    hover: tw`hover:(shadow text-white)`,
    active: tw`active:shadow-none`,
    disabled: tw`shadow-none text-white opacity-50`,
};

const dashedClasses = {
    base: tw`rounded-b bg-transparent border border-line border-dashed px-6 py-2`,
    hover: tw`hover:(text-body-dark border-line-dark shadow-sm)`,
    active: tw`active:(bg-solid-light shadow-none)`,
    disabled: tw`shadow-none text-body-disabled hover:(text-body-disabled border-line)`,
    noTopBorder: tw`-mt-px`,
};

const linedClasses = {
    base: tw`rounded px-6 py-2`,
    hover: tw`hover:text-body-dark`,
    active: tw`active:text-body`,
    disabled: tw`text-body-disabled hocus:text-body-disabled active:text-body-disabled`,
};

const Button = styled.button`
    ${({ isDisabled }) => ({
        ...commonClasses.base,
        ...commonClasses.hover,
        ...commonClasses.focus,
        ...commonClasses.active,
        ...(isDisabled && commonClasses.disabled),
    })}

    ${({ isPrimary, isDisabled }) =>
        isPrimary && {
            ...primaryClasses.base,
            ...primaryClasses.hover,
            ...primaryClasses.active,
            ...(isDisabled && primaryClasses.disabled),
        }}
        ${({ isPrimary, isSmall }) => isSmall && isPrimary && tw`px-5 py-2`}

    ${({ isSecondary, disabled }) =>
        isSecondary && {
            ...secondaryClasses.base,
            ...secondaryClasses.hover,
            ...secondaryClasses.active,
            ...(disabled && secondaryClasses.disabled),
        }}
        ${({ isSecondary, isSmall }) => isSmall && isSecondary && tw`px-5 py-2`}

    ${({ isTeal, disabled }) =>
        isTeal && {
            ...tealClasses.base,
            ...tealClasses.hover,
            ...tealClasses.active,
            ...(disabled && tealClasses.disabled),
        }}
        ${({ isTeal, isSmall }) => isSmall && isTeal && tw`px-5 py-2`}

    ${({ isWarning, disabled }) =>
        isWarning && {
            ...warningClasses.base,
            ...warningClasses.hover,
            ...warningClasses.active,
            ...(disabled && warningClasses.disabled),
        }}
        ${({ isWarning, isSmall }) => isSmall && isWarning && tw`px-5 py-2`}

    ${({ isDashed, isDisabled, hasNoTopBorder }) =>
        isDashed && {
            ...dashedClasses.base,
            ...dashedClasses.hover,
            ...dashedClasses.active,
            ...(isDisabled && dashedClasses.disabled),
            ...(hasNoTopBorder && dashedClasses.noTopBorder),
        }}

    ${({ isLined, isDisabled }) =>
        isLined && {
            ...linedClasses.base,
            ...linedClasses.hover,
            ...linedClasses.active,
            ...(isDisabled && linedClasses.disabled),
        }}
    ${({ isLined, isSmall }) => isSmall && isLined && tw`px-5 py-2`}
    ${({ hasLightBackground, isSecondary }) =>
        isSecondary && hasLightBackground && tw`bg-white`}

    ${({ isSmall }) => isSmall && tw`text-sm hover:shadow-sm`}
    ${({ isSmall, isDisabled }) =>
        isSmall && isDisabled && tw`hover:shadow-none`}
    ${({ isFull }) => isFull && tw`w-full`}
    ${({ isIconOnly }) => isIconOnly && tw`px-2 py-1`}
`;

const Children = styled.div`
    ${tw`flex items-center justify-center`}
    ${({ isLined }) => isLined && tw`whitespace-nowrap md:px-4`}
    ${({ isLined, isSmall }) => isLined && isSmall && tw`mx-0`}
    ${({ noWrap }) => noWrap && tw`whitespace-nowrap`}
`;

const IconWrap = styled.div`
    ${tw`ml-2 shrink-0`}
    ${({ iconDirection }) =>
        (iconDirection === 'down' || iconDirection === 'up') && tw`mt-1`}
  ${({ iconDirection }) => iconDirection === 'up' && tw`-scale-100`}
  ${({ isSmall }) => (isSmall ? tw`text-xs` : tw`text-sm`)}
  ${({ isIconOnly }) => isIconOnly && tw`m-0 text-lg`}
`;

const Line = styled.div`
    ${tw`select-none border-b border-line w-full top-1/2 -translate-y-1/2`}
    ${Button}:hover & {
        ${tw`border-line-dark`}
    }
    ${Button}:active & {
        ${tw`border-line`}
    }
`;

const LoaderWrap = styled.span(({ isLoading }) => [
    isLoading ? tw`relative mr-4` : tw`hidden`,
]);

const Underline = tw.span`border-b`;

export default ({
    children,
    iconDirection,
    isLined,
    isDisabled,
    noWrap,
    isSmall,
    hasLightBackground,
    isIconOnly,
    isLoading,
    isUnderlined,
    ...rest
}) => (
    <Button
        disabled={isDisabled}
        {...{ isLined, isDisabled, isSmall, hasLightBackground, isIconOnly }}
        {...rest}
    >
        {isLined && <Line />}
        <Children {...{ isLined, isSmall, noWrap }}>
            {/* Loader is present in default state to avoid a height jump */}
            <LoaderWrap {...{ isLoading }}>
                <Loader active={isLoading} size="tiny" />
            </LoaderWrap>
            {isUnderlined ? <Underline>{children}</Underline> : children}
            {iconDirection &&
                (iconDirection === 'down' || iconDirection === 'up') && (
                    <IconWrap {...{ iconDirection, isSmall, isIconOnly }}>
                        <Icon name="downArrow" />
                    </IconWrap>
                )}
        </Children>
        {isLined && <Line />}
    </Button>
);
