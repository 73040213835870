import _uniqBy from 'lodash/uniqBy.js';
import {
    CRM_CUSTOMER_DATA_LOADING,
    CRM_CUSTOMER_DATA_LOADED,
    CRM_CUSTOMER_DELETED,
    INTEGRATION_CRM_LINKS_LOADED,
    INTEGRATION_CRM_LINKS_DELETED,
    WORKSPACE_ACCESS_REMOVED,
    WORKSPACE_CRM_ACCESS_REMOVED,
    MODAL_LINK_TOPIC_TO_CRM_OPEN,
    MODAL_LINK_TOPIC_TO_CRM_CLOSE,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
} from '~common/action.types';
import { sortByStringField } from '~common/utils';

const initialState = {
    customers: {},
    links: [],
    linkTopicToCRMModal: {
        isOpen: false,
        topic: {},
    },
};

// customers will have a separate property for each authSource.workspaceId combination:
// ['authSource.workspaceId']: { isLoading, data }

export const getCustomerDataKey = ({ authSource, workspaceId }) =>
    `${authSource}.${workspaceId}`;

const crmReducer = (state = initialState, action) => {
    switch (action.type) {
        case CRM_CUSTOMER_DATA_LOADED: {
            const dataKey = getCustomerDataKey(action);
            return {
                ...state,
                customers: {
                    ...state.customers,
                    [dataKey]: {
                        isLoading: false,
                        data: sortByStringField(
                            action?.customers || [],
                            'displayTitle'
                        ),
                    },
                },
            };
        }
        case CRM_CUSTOMER_DELETED: {
            const dataKey = getCustomerDataKey(action);
            return {
                ...state,
                customers: {
                    ...state.customers,
                    [dataKey]: {
                        isLoading: false,
                        data: (state.customers[dataKey]?.data || []).filter(
                            (c) => c.remoteId !== action.customerId
                        ),
                    },
                },
            };
        }

        case WORKSPACE_CRM_ACCESS_REMOVED:
        case WORKSPACE_ACCESS_REMOVED: {
            const { workspace } = action;
            return {
                ...state,
                customers: Object.fromEntries(
                    Object.entries(state.customers).filter(
                        ([key]) => !key.includes(workspace.id)
                    )
                ),
                links: state.links.filter(
                    (link) => link.workspaceId !== workspace.id
                ),
            };
        }
        case CRM_CUSTOMER_DATA_LOADING: {
            const dataKey = getCustomerDataKey(action);
            return {
                ...state,
                customers: {
                    ...state.customers,
                    [dataKey]: {
                        ...state.customers[dataKey],
                        isLoading: true,
                        data: state.customers[dataKey]?.data || [],
                    },
                },
            };
        }

        case INTEGRATION_CRM_LINKS_LOADED: {
            return {
                ...state,
                links: _uniqBy(
                    [...(action?.links || []), ...state.links],
                    'id'
                ),
            };
        }

        case INTEGRATION_CRM_LINKS_DELETED: {
            const { links } = action;
            if (!links.length) return state;

            const deletedLinkIds = links.map((link) => link.id);

            return {
                ...state,
                links: state.links.filter(
                    (link) => !deletedLinkIds.includes(link.id)
                ),
            };
        }

        case MODAL_LINK_TOPIC_TO_CRM_OPEN: {
            return {
                ...state,
                linkTopicToCRMModal: {
                    isOpen: true,
                    ...action,
                },
            };
        }

        case MODAL_LINK_TOPIC_TO_CRM_CLOSE: {
            return {
                ...state,
                linkTopicToCRMModal: {
                    isOpen: false,
                },
            };
        }

        case MOVE_OCCURRENCE_DELETE: {
            if ((action.integrationLinkIds?.length || 0) === 0) return state;
            if (
                !state.links.some((link) =>
                    action.integrationLinkIds.includes(link.id)
                )
            )
                return state;

            return {
                ...state,
                links: state.links.filter(
                    (link) => !action.integrationLinkIds.includes(link.id)
                ),
            };
        }

        case MOVE_OCCURRENCE_UPDATE: {
            if ((action.integrationLinkIds?.length || 0) === 0) return state;
            if (
                !state.links.some((link) =>
                    action.integrationLinkIds.includes(link.id)
                )
            )
                return state;

            const links = state.links.map((link) => {
                if (!action.links.includes(link.id)) return link;

                return { ...link, meeting: action.meeting };
            });

            return {
                ...state,
                links,
            };
        }

        default:
            return state;
    }
};

export default crmReducer;
