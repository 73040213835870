import _isDate from 'lodash/isDate.js';
import moment from 'moment-timezone';
import React, { useRef, useCallback } from 'react';
import DayPicker from 'react-day-picker';
import tw, { styled } from 'twin.macro';
import { Popup } from '~modules/common/form-elements';
import { Tooltip } from '~modules/common/components';
import { useSelector } from 'react-redux';
import { getUserSelector } from '~modules/user/user.selectors';
import { dateStartOfDay, dateEndOfDay } from '~common/time.utils';
import Icon from '~icons';
import 'react-day-picker/lib/style.css';

const ContentContainer = tw.div`py-4 px-5`;
const DateWrap = tw.div`flex items-center whitespace-nowrap h-full`;
const DateDeleteWrap = tw.button`ml-1 hocus:svg:text-icon-dark`;

const NavBar = styled.div`
    ${tw`flex absolute right-0 pr-2 text-icon`}
    top: 1rem;
    button {
        ${tw`hover:text-icon-dark px-4 -mr-4 focus:outline-none active:text-black`}
    }
`;

const Navbar = ({ onPreviousClick, onNextClick, previousMonth }) => (
    <NavBar>
        {previousMonth && (
            <button type="button" onClick={() => onPreviousClick()}>
                <Icon name="leftArrow" />
            </button>
        )}
        <button type="button" onClick={() => onNextClick()}>
            <Icon name="rightArrow" />
        </button>
    </NavBar>
);

// `props => ...` avoids an strange issue with Jest
// https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const FormattedDayPicker = styled((props) => <DayPicker {...props} />)`
    & * {
        outline: none;
    }
    .DayPicker-wrapper {
        ${tw`pb-0 pt-3 bg-transparent text-sm`}
    }
    .DayPicker-Caption {
        ${tw`text-body-dark mb-4`}
        & div {
            ${tw`font-bold`}
        }
    }
    .DayPicker-Day {
        ${tw`text-body-dark`}
        padding: 0.5em 0.6em; // Stop selected background going oval
        &--today {
            ${tw`relative text-body-dark bg-transparent`}
            &:before {
                ${tw`absolute top-0 left-0 border-2 border-dotted border-primary rounded-full pointer-events-none`}
                content: '';
                width: 2.2rem;
                height: 2.2rem;
                z-index: 1;
            }
            &:hover:before {
                ${tw`border-primary-dark`}
            }
        }
        &--today.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):before {
            ${tw`hidden`}
        }
        &--disabled {
            ${tw`text-icon cursor-not-allowed`}
        }
        &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--today) {
            ${tw`text-white bg-primary`}
            &:hover {
                ${tw`bg-primary-dark`}
            }
        }
        &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            ${tw`bg-primary text-white`}
            &:before {
                ${tw`border-white`}
            }
            &:hover {
                ${tw`bg-primary`}
            }
        }
        &--outside:not(.DayPicker-Day--disabled) {
            ${tw`text-body`}
            &:hover {
                ${tw`cursor-pointer bg-line`}
            }
            &:active {
                ${tw`bg-line-dark`}
            }
        }
    }
    // Main hover bg
    div&:not(&--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        ${tw`bg-line`};
    }
    div&:not(&--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):active {
        ${tw`bg-line-dark`};
    }
    .DayPicker-Weekday {
        ${tw`text-body`}
    }
    .DayPicker-Month {
        ${tw`m-0`}
    }
    .DayPicker-Footer {
        ${tw`flex justify-center pt-1 pl-2 text-base`}
    }
    .DayPicker-TodayButton {
        ${tw`text-body hover:text-body-dark pt-2 focus:outline-none`}
        ${({ isMeetingsHeader }) => isMeetingsHeader && tw`block 2xl:hidden`}
    }
`;

export default function DatePicker({
    children,
    onDateChange,
    onNoDateChange,
    activeDate,
    canClearDate,
    atStartOfDay = true,
    atEndOfDay = false,
    atMiddleOfDay = false,
    isMeetingsHeader,
    dayPickerTooltipContent = '',
    deleteTooltipContent = '',
    offset = [-14, 1],
    ...rest
}) {
    const popupRef = useRef();
    const user = useSelector(getUserSelector);

    const handleDueDateClick = useCallback(
        (day, modifiers = {}, event) => {
            event?.stopPropagation();

            const close = () => {
                popupRef?.current?.handleClose();
            };

            if (modifiers.disabled) {
                return;
            }

            let chosenDate;
            if (_isDate(day)) {
                chosenDate = atMiddleOfDay
                    ? day
                    : atStartOfDay && !atEndOfDay
                    ? dateStartOfDay(day)
                    : dateEndOfDay(day);
            }

            // Check if date selection has changed
            if (
                (!day && !activeDate) ||
                (chosenDate &&
                    activeDate &&
                    chosenDate.valueOf() === activeDate.valueOf())
            ) {
                // GOTCHA: empty string is required to set the date back to "null" as undefined/null is not sent via API
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                onNoDateChange &&
                    onNoDateChange(chosenDate ? moment(chosenDate) : '');
                close();
                return;
            }

            // GOTCHA: empty string is required to set the date back to "null" as undefined/null is not sent via API
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
            onDateChange && onDateChange(chosenDate ? moment(chosenDate) : '');

            close();
        },
        [
            activeDate,
            onDateChange,
            onNoDateChange,
            atStartOfDay,
            atEndOfDay,
            atMiddleOfDay,
        ]
    );

    const PopupContent = () => {
        const date = _isDate(activeDate)
            ? activeDate
            : moment.isMoment(activeDate)
            ? activeDate.toDate()
            : new Date();

        return (
            <ContentContainer>
                <FormattedDayPicker
                    locale={user.locale}
                    selectedDays={date}
                    initialMonth={date}
                    todayButton="Go to today"
                    showOutsideDays={true}
                    onTodayButtonClick={handleDueDateClick}
                    onDayClick={handleDueDateClick}
                    navbarElement={Navbar}
                    {...{ isMeetingsHeader }}
                    {...rest}
                />
            </ContentContainer>
        );
    };

    return (
        <DateWrap>
            <Tooltip
                position="bottom left"
                content={dayPickerTooltipContent}
                enableTooltip={dayPickerTooltipContent.length > 0}
                wide
                {...{ offset }}
            >
                <div>
                    <Popup
                        position="bottom right"
                        trigger={children}
                        ref={popupRef}
                        offset={[0, 4]}
                    >
                        <PopupContent />
                    </Popup>
                </div>
            </Tooltip>
            {activeDate && canClearDate && (
                <Tooltip
                    position="bottom left"
                    offset={[-14, 6]}
                    content={deleteTooltipContent}
                    enableTooltip={deleteTooltipContent.length > 0}
                    wide
                >
                    <DateDeleteWrap>
                        <Icon name="delete" onClick={handleDueDateClick} />
                    </DateDeleteWrap>
                </Tooltip>
            )}
        </DateWrap>
    );
}
