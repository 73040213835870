import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import tw from 'twin.macro';
import RightArrow from '~icons/rightArrow';

const Container = tw.nav`relative text-body flex flex-wrap items-center select-none`;

const WrapNavLink = tw(NavLink)`hover:text-body-dark`;
const IconWrap = tw.div`text-icon text-2xs px-1`;

const Breadcrumbs = ({ crumbs, hasContainer, isSmall }) => {
    const items = Array.isArray(crumbs) ? crumbs : [crumbs];
    return (
        <Container aria-label="breadcrumbs" {...{ hasContainer }}>
            {items.map((crumb, index) => (
                <Fragment key={index}>
                    {crumb.to ? (
                        <WrapNavLink to={crumb.to}>{crumb.title}</WrapNavLink>
                    ) : (
                        crumb.title
                    )}
                    {index !== crumbs.length - 1 && crumbs.length > 1 && (
                        <IconWrap aria-hidden="true" {...{ isSmall }}>
                            <RightArrow />
                        </IconWrap>
                    )}
                </Fragment>
            ))}
        </Container>
    );
};

export default Breadcrumbs;
