import React from 'react';
import moment from 'moment-timezone';
import ObjectID from 'bson-objectid';
import {
    ADD_ACTION_ITEM_REQUEST,
    ADD_ACTION_ITEM_ERROR,
    DELETE_ACTION_ITEM_REQUEST,
    DELETE_ACTION_ITEM_ERROR,
    UPDATE_ACTION_ITEM_REQUEST,
    UPDATE_ACTION_ITEM_ERROR,
    ADD_ACTION_ITEM_SUCCESS,
    DELETE_ACTION_ITEM_SUCCESS,
    UPDATE_ACTION_ITEM_SUCCESS,
    MODAL_LINKACTIONITEM_OPEN,
    MODAL_LINKACTIONITEM_CLOSE,
    LINK_ACTION_ITEM_SUCCESS,
    UNLINK_ACTION_ITEM_SUCCESS,
} from '~common/action.types';
import {
    addActionItem,
    deleteActionItem,
    updateActionItem,
    updateActionItemNoWS,
    linkActionItem,
    unlinkActionItem,
} from '~client/api/actionItem.api';
import {
    ACTION_ITEM_CREATED_MINUTES,
    ACTION_ITEM_CREATED_OCCURRENCE,
    MODAL_TYPE_YESNO,
} from '~common/constants';
import { onMinuteItemBulkSave } from '~modules/minutes/minutes.actions';
import { getActionItemFromStoreById } from './actionItem.selectors.js';
import {
    formSubmissionServerError,
    processBodyErrors,
} from '~components/formvalidation/formvalidation.helper';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import { alertError } from '~modules/alert/alert.actions';
import Icon from '~modules/common/icons';
import { stripHtml } from '~shared/utils/html-sanitizer';

export function openLinkActionItemModal(actionItem) {
    return {
        type: MODAL_LINKACTIONITEM_OPEN,
        actionItem,
    };
}

export function closeLinkActionItemModal() {
    return {
        type: MODAL_LINKACTIONITEM_CLOSE,
    };
}

function addActionItemRequest(occurrenceId, actionItem) {
    return {
        type: ADD_ACTION_ITEM_REQUEST,
        occurrenceId,
        actionItem,
    };
}

function addActionItemError(actionItem, error) {
    return {
        type: ADD_ACTION_ITEM_ERROR,
        actionItem,
        error,
    };
}

function deleteActionItemRequest(occurrenceId, actionItem) {
    return {
        type: DELETE_ACTION_ITEM_REQUEST,
        actionItem,
        occurrenceId,
    };
}

function deleteActionItemError(actionItem, error) {
    return {
        type: DELETE_ACTION_ITEM_ERROR,
        actionItem,
        error,
    };
}

function updateActionItemRequest(actionItem) {
    return {
        type: UPDATE_ACTION_ITEM_REQUEST,
        actionItem,
    };
}

function updateActionItemError(actionItem, error) {
    return {
        type: UPDATE_ACTION_ITEM_ERROR,
        actionItem,
        error,
    };
}

export function addActionItemSuccess(occurrenceId, actionItem) {
    return {
        type: ADD_ACTION_ITEM_SUCCESS,
        occurrenceId,
        actionItem,
    };
}

export function deleteActionItemSuccess(occurrenceId, actionItem) {
    return {
        type: DELETE_ACTION_ITEM_SUCCESS,
        occurrenceId,
        actionItem,
    };
}

export function updateActionItemSuccess(occurrenceId, actionItem) {
    return {
        type: UPDATE_ACTION_ITEM_SUCCESS,
        occurrenceId,
        actionItem,
    };
}

function linkActionItemSuccess(actionItem) {
    return {
        type: LINK_ACTION_ITEM_SUCCESS,
        actionItem,
    };
}

function unlinkActionItemSuccess(actionItem) {
    return {
        type: UNLINK_ACTION_ITEM_SUCCESS,
        actionItem,
    };
}

export function onActionItemCreate(
    params,
    allTopics,
    topicsAreSavedToDB = true,
    { sendToApi = true } = {}
) {
    return (dispatch, getState) => {
        const { user } = getState();

        const occurrenceId = params.occurrence;
        const addParams = {
            id: String(new ObjectID()),
            createdAt: moment(),
            isCompleted: false,
            createdBy: user.id,
            deleted: false,
            ...params,
            source:
                params.source ||
                (params.minuteItem
                    ? ACTION_ITEM_CREATED_MINUTES
                    : ACTION_ITEM_CREATED_OCCURRENCE),
            title: stripHtml(params.title),
        };

        dispatch(addActionItemRequest(occurrenceId, addParams));

        sendToApi &&
            dispatch(
                actionItemCreateBackground({
                    addParams,
                    occurrenceId,
                    topicsAreSavedToDB,
                    allTopics,
                })
            );

        return addParams;
    };
}

const actionItemCreateBackground =
    ({ addParams, occurrenceId, topicsAreSavedToDB, allTopics }) =>
    async (dispatch) => {
        try {
            const actionItem = await addActionItem(addParams);

            dispatch(addActionItemSuccess(occurrenceId, actionItem));

            if (!topicsAreSavedToDB) {
                dispatch(onMinuteItemBulkSave(occurrenceId, allTopics));
            }
        } catch (error) {
            dispatch(addActionItemError(addParams, error));

            dispatch(
                alertError({
                    id: `onActionItemCreate-${occurrenceId}`,
                    title: 'Action item could not be created',
                    error,
                })
            );

            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );
            return newError;
        }
    };

export function onActionItemDelete(actionItem, askUserConfirmation = true) {
    return (dispatch) => {
        const promise = async () => {
            const occurrenceId = actionItem.occurrence;

            dispatch(deleteActionItemRequest(occurrenceId, actionItem));

            try {
                await deleteActionItem(actionItem);
                dispatch(deleteActionItemSuccess(occurrenceId, actionItem));
            } catch (error) {
                dispatch(deleteActionItemError(actionItem, error));

                dispatch(
                    alertError({
                        id: `onActionItemDelete-${actionItem.id}`,
                        title: 'Action item could not be deleted',
                        error,
                    })
                );
            }
        };

        if (askUserConfirmation) {
            dispatch(
                openConfirm({
                    header: 'Delete Action item',
                    content: 'This will be permanently deleted, are you sure?',
                    promise,
                })
            );
        } else {
            dispatch(promise);
        }
    };
}

export function onActionItemUpdate(actionItem) {
    return async (dispatch, getState) => {
        const prevActionItem = getActionItemFromStoreById(
            getState(),
            actionItem
        );
        dispatch(updateActionItemRequest(actionItem));

        try {
            await updateActionItem(actionItem.id, actionItem);
        } catch (error) {
            //GOTCHA Adding action from RTE will debounce an Update. If Add fails,
            //       will still get a subsequent Update, and that update needs to remove
            //       the actionItem added to the store due to optimistic Update.
            if (prevActionItem) {
                dispatch(updateActionItemError(prevActionItem, error));

                dispatch(
                    alertError({
                        id: `onActionItemUpdate-${actionItem.id}`,
                        title: 'Action item could not be updated',
                        error,
                    })
                );
            } else {
                dispatch(addActionItemError(actionItem, error));

                dispatch(
                    alertError({
                        id: `onActionItemUpdate-${actionItem.id}`,
                        title: 'Action item could not be created',
                        error,
                    })
                );
            }
        }
    };
}

export const onActionItemUpdateAssignees =
    ({ id, assignees }) =>
    (dispatch) =>
        dispatch(onActionItemUpdateSuppressingWS({ id, assignees }));

export const onActionItemUpdateTitle =
    ({ id, title }) =>
    (dispatch) =>
        dispatch(onActionItemUpdateSuppressingWS({ id, title }));

export const onActionItemUpdatePriority =
    ({ id, priority }) =>
    (dispatch) =>
        dispatch(onActionItemUpdateSuppressingWS({ id, priority }));

export const onActionItemUpdateDueDate =
    ({ id, dueDate }) =>
    (dispatch) =>
        dispatch(onActionItemUpdateSuppressingWS({ id, dueDate }));

/**
 * This sends an action item update to the api but will suppress a websocket response from the server.
 * This function also triggers an optimistic update.
 * So if you don't need the second update from the api, use this instead of onActionItemUpdate
 * @param {Object} actionItem action item object containing any of the valid fields of an action item
 * @param {String} actionItem.id id must be provided
 */
export function onActionItemUpdateSuppressingWS(actionItem) {
    return async (dispatch, getState) => {
        const prevActionItem = getActionItemFromStoreById(
            getState(),
            actionItem
        );
        dispatch(updateActionItemRequest(actionItem));

        try {
            await updateActionItemNoWS(actionItem.id, actionItem);
        } catch (error) {
            if (prevActionItem) {
                dispatch(updateActionItemError(prevActionItem, error));

                dispatch(
                    alertError({
                        id: `onActionItemUpdateSuppressingWS-${actionItem.id}`,
                        title: 'Action item could not be updated',
                        error,
                    })
                );
            } else {
                dispatch(addActionItemError(actionItem, error));

                dispatch(
                    alertError({
                        id: `onActionItemUpdateSuppressingWS-${actionItem.id}`,
                        title: 'Action item could not be created',
                        error,
                    })
                );
            }
        }
    };
}

export function activesyncInlineActionTitle(actionItem) {
    return (dispatch) => dispatch(updateActionItemRequest(actionItem));
}

export function openUnlinkActionItemModal(actionItem, content) {
    return (dispatch) => {
        const promise = async () => {
            const item = await unlinkActionItem(actionItem);

            dispatch(unlinkActionItemSuccess(item));
        };

        dispatch(
            openConfirm({
                header: 'Unlink action item',
                headerIcon: <Icon name="unlink" />,
                content,
                promise,
                modalType: MODAL_TYPE_YESNO,
            })
        );
    };
}

export function onActionItemUnlink(actionItem) {
    return async (dispatch) => {
        try {
            const item = await unlinkActionItem(actionItem);

            dispatch(unlinkActionItemSuccess(item));
        } catch (error) {
            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );

            alertError({
                id: `onActionItemUnlink-${actionItem.id}`,
                title: 'Action item link could not be removed',
                error,
            });

            return newError;
        }
    };
}

export function onActionItemLink(actionItem, params) {
    return async (dispatch) => {
        try {
            const item = await linkActionItem(actionItem, params);

            dispatch(linkActionItemSuccess(item));
        } catch (error) {
            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );

            alertError({
                id: `onActionItemLink-${actionItem.id}`,
                title: 'Action item link could not be created',
                error,
            });

            return newError;
        }
    };
}
