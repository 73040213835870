import tw, { styled, theme } from 'twin.macro';
import DayPicker from 'react-day-picker';
import type { DayPickerProps, NavbarElementProps } from 'react-day-picker';

const mediumViewCustomQuery = `@media (min-width: ${theme(
    'screens.lg'
)}) and (max-width: 1155px)`;

const Calendar = styled((props: DayPickerProps) => <DayPicker {...props} />)`
    & * {
        outline: none;
    }
    .DayPicker-wrapper {
        ${tw`p-0 text-sm`}
    }
    .DayPicker-Weekday {
        ${tw`pt-4 text-body`}
    }
    .DayPicker-Month {
        ${tw`m-0`}
    }
    .DayPicker-Day {
        ${tw`text-body-dark relative rounded p-[0.5em 0.67em]`} // Padding to stop selected background going oval, 

        // Media query stops calendar from going wider than the sidebar
        ${mediumViewCustomQuery} {
            ${tw`p-[0.39em 0.55em]`}
        }

        &--disabled {
            ${tw`text-icon cursor-not-allowed`}
        }
        &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--today) {
            ${tw`text-primary bg-solid-blue-lightest`}
        }
        &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            ${tw`bg-solid-bright text-white`}
        }
        &--outside:not(.DayPicker-Day--disabled) {
            ${tw`text-body`}
            &:hover {
                ${tw`cursor-pointer bg-line`}
            }
            &:active {
                ${tw`text-solid-blue bg-solid-blue-lightest`}
            }
        }
        &--today,
        &--today:hover {
            ${tw`(bg-primary text-white rounded-full)!`}
        }
    }
    // Main hover bg
    div&:not(&--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        ${tw`rounded bg-line`}
    }
    div&:not(&--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):active {
        ${tw`text-solid-blue bg-solid-blue-lightest`};
    }
`;

const NavBar = styled.div([
    tw`absolute right-0 pr-2.5 text-icon top-0.5`,
    {
        button: {
            ...tw`inline-block hover:text-icon-dark focus:outline-none active:text-black (px-[.7rem] last:-mr-3)`,
            [mediumViewCustomQuery]: tw`px-[.6rem]`,
        },
    },
]);

const ArrowIcon = ({ ...props }): JSX.Element => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m1 17 15-16 15 16"
            strokeLinejoin="miter"
            stroke="currentColor"
            strokeWidth="1.5"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m16 31v-29"
            stroke="currentColor"
            strokeWidth="1.5"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);

const Nav = ({
    onPreviousClick,
    onNextClick,
    previousMonth,
}: NavbarElementProps): JSX.Element => (
    <NavBar>
        {Boolean(previousMonth) && (
            <button
                type="button"
                onClick={(): void => {
                    onPreviousClick();
                }}
            >
                <ArrowIcon />
            </button>
        )}
        <button
            type="button"
            onClick={(): void => {
                onNextClick();
            }}
        >
            <ArrowIcon tw="rotate-180" />
        </button>
    </NavBar>
);

export { Calendar as default, Nav };
