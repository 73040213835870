import _find from 'lodash/find.js';
import _fpcompose from 'lodash/fp/compose';
import _fpfilter from 'lodash/fp/filter';
import _fpmap from 'lodash/fp/map';
import _fpreject from 'lodash/fp/reject';
import _fpsortBy from 'lodash/fp/sortBy';
import _has from 'lodash/has.js';
import _isNull from 'lodash/isNull.js';
import { createSelector } from 'reselect';
import { getMeetingAccessLevel } from '~modules/permission/permission.helpers';
import {
    getMeetingIdSelector,
    getOccurrenceIdSelector,
    getGroupIdSelector,
    getWorkspaceIdSelector,
} from '~common/selector.helpers';
import {
    GROUP_TYPE_TAG,
    GROUP_TYPE_MEETING,
    GROUP_TYPE_WORKSPACE,
} from '~common/constants';
import { meetingsSelector } from '~modules/meeting/meeting.selectors';
import { getUserSelector } from '~modules/user/user.selectors';
import { getStore } from '~common/store';

export const getGroups = (state) => state.groups.groups;

const removeInvalidMeetings = (group, user) => {
    return {
        ...group,
        meetings:
            group?.meetings?.filter(({ meetingId }) => {
                const { canView } = getMeetingAccessLevel(meetingId, user.id);
                return canView;
            }) || [],
    };
};

export const getMeetingGroupsSelector = createSelector(
    getGroups,
    getUserSelector,
    (state) => state.permission, //this is required to force selector to re-execute if permissions are updated... e.g. user accepts a meeting
    (_, props) =>
        _has(props, 'includeOnlyAccessibleMeetings')
            ? props.includeOnlyAccessibleMeetings
            : true,
    (groups, user, permissions, includeOnlyAccessibleMeetings) =>
        _fpcompose(
            _fpmap((group) =>
                includeOnlyAccessibleMeetings
                    ? removeInvalidMeetings(group, user)
                    : group
            ),
            _fpsortBy('displayOrder'),
            _fpfilter((g) => g?.type === GROUP_TYPE_MEETING)
        )(groups)
);

export const getMeetingGroupsForWorkspace = createSelector(
    getMeetingGroupsSelector,
    (_, props) => props.workspace,
    (meetingGroups, workspace) =>
        meetingGroups.filter(
            (group) => group.workspaceId === (workspace?.id || workspace)
        )
);

export const getWorkspaceGroupsSelector = createSelector(
    (state) => state.permission, //this is required to force selector to re-execute if permissions are updated... e.g. user accepts a meeting
    getGroups,
    (permissions, groups) =>
        groups.filter((group) => group?.type === GROUP_TYPE_WORKSPACE)
);

/**
 * Returns a workspace from the store, by searching the store.
 * @param {Object} [state=getStore().getState] Redux state object. If ommitted, use the default redux store
 * @param {string | Object} id workspace or workspace.id.
 */
export function getWorkspaceFromStoreById() {
    let state, props;
    switch (arguments.length) {
        case 2:
            [state, props] = arguments;
            break;
        case 1:
            [props] = arguments;
            state = getStore().getState();
            break;
    }
    const id = getWorkspaceIdSelector(state, props);
    const group = getGroupFromStoreById(state, id);
    return group;
}

export const getTagGroupsSelector = createSelector(getGroups, (groups) =>
    _fpcompose(
        _fpsortBy('displayOrder'),
        _fpfilter((g) => g?.type === GROUP_TYPE_TAG)
    )(groups)
);

/**
 * Memoized selector to get a group from the Store.
 * This is used from the dashboard, so be careful not to break the memoizer. In other situations, use getGroupFromStoreById.
 * @param {Object} state Redux state object.
 * @param {props} props
 * @param {(Object|string)} props.group Group object or group.id.
 * @param {bool} [props.includeOnlyAccessibleMeetings=true] to make sure the meetings included in the group are only those the user has permission to access.
 */
export const getGroupSelector = createSelector(
    getGroupIdSelector,
    getGroups,
    (state) => state.permission, //this is required to force selector to re-execute if permissions are updated... e.g. user accepts a meeting
    getUserSelector,
    (_, props) =>
        _has(props, 'includeOnlyAccessibleMeetings')
            ? props.includeOnlyAccessibleMeetings
            : true,
    (groupId, groups, user, _, includeOnlyAccessibleMeetings) => {
        const group = groups.find((g) => g?.id === groupId);

        if (!group) {
            return null;
        }

        return includeOnlyAccessibleMeetings
            ? removeInvalidMeetings(group, user)
            : group;
    }
);

/**
 * Returns a group from the store, by searching the store.
 * @param {Object} [state=getStore().getState] Redux state object. If ommitted, use the default redux store
 * @param {string} id group.id.
 */
export function getGroupFromStoreById() {
    let state, props;
    switch (arguments.length) {
        case 2:
            [state, props] = arguments;
            break;
        case 1:
            [props] = arguments;
            state = getStore().getState();
            break;
    }
    const id = getGroupIdSelector(state, props);
    const group = _find(state.groups.groups, { id });
    return group;
}

export const getMeetingsForGroup = createSelector(
    getGroupSelector,
    meetingsSelector,
    (group, meetings) => {
        if (!group) {
            return [];
        }

        return _fpcompose(
            _fpsortBy((o) => o.title.toLowerCase()),
            _fpreject(_isNull),
            _fpmap(({ meetingId, starred, hasMultipleOccurrences }) => {
                const match = _find(meetings, { id: meetingId });

                if (match) {
                    return {
                        ...match,
                        starred,
                        hasMultipleOccurrences,
                        myAccessLevel: getMeetingAccessLevel(match),
                    };
                }

                return null;
            })
        )(group.meetings);
    }
);

export const getGroupListForDropdown = createSelector(
    getGroups,
    (_, props) => props.workspace,
    (groups, workspace) => {
        const filterQuery = {
            type: GROUP_TYPE_MEETING,
        };

        const workspaceId = workspace?.id || workspace;
        if (workspaceId) {
            filterQuery.workspaceId = workspaceId;
        }

        return _fpcompose(
            _fpsortBy((o) => o.text.toLowerCase()),
            _fpmap((g) => {
                return {
                    key: g.id,
                    value: g.id,
                    text: g.title,
                };
            }),
            _fpfilter(filterQuery)
        )(groups);
    }
);

/**
 * Find the Workspace group that contains a specified meeting.
 * @param {Object} [groups = state.groups.groups] List of groups to search. If not provided, list of workspace groups will be selected from the Redux store.
 * @param {Object} props Object containing the parameters to lookup.
 * @param {(Object|String)} props.meeting Can be a meeting object or a meeting.id.
 * @returns {Object} Workspace group that contains the requested meeting.
 */
export function getWorkspaceGroupByMeetingId() {
    let groups, props;
    switch (arguments.length) {
        case 2:
            [groups, props] = arguments;
            break;
        case 1:
            [props] = arguments;
            groups = getWorkspaceGroupsSelector(getStore().getState());
            break;
    }

    return findGroupForMeeting(groups, props);
}

/**
 * The workspace has a list of all meetings created in the workspace, including meetings you don't have access to.
 * Filter the list, and return a list of meetingIds you can access
 */
export function getAccessibleMeetingsIdsForWorkspace() {
    let state, workspace;
    switch (arguments.length) {
        case 2:
            [state, workspace] = arguments;
            break;
        case 1:
            [workspace] = arguments;
            state = getStore().getState();
            break;
    }

    workspace =
        typeof workspace === 'string'
            ? getGroupFromStoreById(state, workspace)
            : workspace;

    return (
        removeInvalidMeetings(workspace, state.user)?.meetings?.map(
            ({ meetingId }) => meetingId
        ) || 0
    );
}

export function getGroupByMeetingId(state, meeting) {
    // Find the Group to get the group name for display on the meeting detail page.
    // NOTE: the meeting will only be in one group for this user
    const groups = getMeetingGroupsSelector(state);

    return findGroupForMeeting(groups, { meeting });
}

export function findGroupForOccurrence(groups, props) {
    // NOTE: props must contain a meeting id, either via
    // occurrence.meeting or meeting.id or just meeting
    // It cannot just be an occurrence Id.
    return findGroupForMeeting(groups, props);
}

/**
 * Find the Group that contains a specified meeting.
 * @param {Object} [groups = state.groups.groups] List of groups to search. If not provided, list of meeting groups will be selected from the Redux store.
 * @param {Object} props Object containing the parameters to lookup.
 * @param {(Object|String)} props.meeting Can be a meeting object or a meeting.id.
 * @returns {Object} Group that contains the requested meeting.
 */
export function findGroupForMeeting() {
    let groups, props;
    switch (arguments.length) {
        case 2:
            [groups, props] = arguments;
            break;
        case 1:
            [props] = arguments;
            groups = getMeetingGroupsSelector(getStore().getState());
            break;
    }

    const meetingId = getMeetingIdSelector(null, props);

    let group = {};
    groups.forEach((g) => {
        if (_find(g.meetings, { meetingId })) {
            group = g;
            return false;
        }
    });
    return group;
}

export const getMeetingGroupsForOccurrence = createSelector(
    getMeetingGroupsSelector,
    getWorkspaceGroupsSelector,
    getMeetingIdSelector,
    (groups, workspaces, meetingId) => {
        const workspace = getWorkspaceGroupByMeetingId(workspaces, {
            meeting: meetingId,
        });
        return groups
            .filter((group) => group.workspaceId === workspace.id)
            .map((group) => ({
                ...group,
                isSelected: !!_find(group.meetings, { meetingId }),
            }));
    }
);

export const getTagGroupsForOccurrence = createSelector(
    getTagGroupsSelector,
    getOccurrenceIdSelector,
    (groups, occurrenceId) => {
        return groups.map((group) => ({
            ...group,
            isSelected: !!_find(group.occurrences, { occurrenceId }),
        }));
    }
);
