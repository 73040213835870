import { get, post } from '../../api/helpers.js';

export function getPermissionsForOccurrence(id) {
    const url = `/api/permissions/occurrence/${id}`;
    return get(url);
}

export function getPermissionsForMeeting(id) {
    const url = `/api/permissions/meeting/${id}`;
    return get(url);
}

export function acceptPermission(id) {
    const url = `/api/permission/${id}/accept`;
    return post(url);
}

export function rejectPermission(id) {
    const url = `/api/permission/${id}/reject`;
    return post(url);
}

export function resendPermission(id, { activeOccurrenceId } = {}) {
    const url = `/api/permission/${id}/resend${
        activeOccurrenceId ? `/occurrence/${activeOccurrenceId}` : ''
    }`;
    return post(url);
}

export function leaveMeeting(meetingId) {
    const url = `/api/permissions/meeting/${meetingId}/leave`;
    return post(url);
}
