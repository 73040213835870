import React from 'react';

export default (props) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m21.596 9.865c-0.09 0.15-0.19 0.265-0.3 0.345-0.1 0.07-0.225 0.105-0.375 0.105-0.17 0-0.37-0.085-0.6-0.255s-0.52-0.355-0.87-0.555c-0.34-0.21-0.755-0.4-1.245-0.57-0.48-0.17-1.065-0.255-1.755-0.255-0.65 0-1.225 0.09-1.725 0.27-0.49 0.17-0.905 0.405-1.245 0.705-0.33 0.3-0.58 0.655-0.75 1.065-0.17 0.4-0.255 0.835-0.255 1.305 0 0.6 0.145 1.1 0.435 1.5 0.3 0.39 0.69 0.725 1.17 1.005 0.49 0.28 1.04 0.525 1.65 0.735 0.62 0.2 1.25 0.41 1.89 0.63 0.65 0.22 1.28 0.47 1.89 0.75 0.62 0.27 1.17 0.615 1.65 1.035 0.49 0.42 0.88 0.935 1.17 1.545 0.3 0.61 0.45 1.36 0.45 2.25 0 0.94-0.16 1.825-0.48 2.655-0.32 0.82-0.79 1.535-1.41 2.145-0.61 0.61-1.365 1.09-2.265 1.44-0.89 0.35-1.905 0.525-3.045 0.525-1.4 0-2.67-0.25-3.81-0.75-1.14-0.51-2.115-1.195-2.925-2.055l0.84-1.38c0.08-0.11 0.175-0.2 0.285-0.27 0.12003-0.08 0.25003-0.12 0.39003-0.12 0.13 0 0.275 0.055 0.435 0.165 0.17 0.1 0.36 0.23 0.57 0.39s0.45 0.335 0.72 0.525 0.575 0.365 0.915 0.525c0.35 0.16 0.745 0.295 1.185 0.405 0.44 0.1 0.935 0.15 1.485 0.15 0.69 0 1.305-0.095 1.845-0.285s0.995-0.455 1.365-0.795c0.38-0.35 0.67-0.765 0.87-1.245s0.3-1.015 0.3-1.605c0-0.65-0.15-1.18-0.45-1.59-0.29-0.42-0.675-0.77-1.155-1.05s-1.03-0.515-1.65-0.705c-0.62-0.2-1.25-0.4-1.89-0.6-0.64-0.21-1.27-0.45-1.89-0.72s-1.17-0.62-1.65-1.05-0.87-0.965-1.17-1.605c-0.29003-0.65-0.43503-1.45-0.43503-2.4 0-0.76 0.145-1.495 0.43503-2.205 0.3-0.71 0.73-1.34 1.29-1.89 0.57-0.55 1.265-0.99 2.085-1.32 0.83-0.33 1.78-0.495 2.85-0.495 1.2 0 2.29 0.19 3.27 0.57 0.99 0.38 1.86 0.93 2.61 1.65l-0.705 1.38z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
        />
        <path
            d="m5 17h23"
            vectorEffect="non-scaling-stroke"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
        />
        <path
            d="m5 17h23"
            vectorEffect="non-scaling-stroke"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.75"
        />
    </svg>
);
