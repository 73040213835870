import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M28.47 25.58h.003c1.206.01 2.196-.976 2.21-2.205V8.218a2.26 2.26 0 0 0-.657-1.578A2.17 2.17 0 0 0 28.47 6H3.21a2.17 2.17 0 0 0-1.554.64A2.26 2.26 0 0 0 1 8.216V23.36a2.26 2.26 0 0 0 .657 1.578c.415.415.974.645 1.557.64H28.47zm-.001.63h0z"
            fill="#fff"
            stroke="#000"
            strokeOpacity=".2"
            strokeWidth=".5"
        />
        <path
            d="M16.616 25.895H28.47A2.51 2.51 0 0 0 31 23.407v-2.98a48.93 48.93 0 0 1-14.384 5.467z"
            fill="#f27712"
        />
        <path
            d="M29.955 17.427h-1.077l-1.213-1.6h-.115v1.6h-.878v-3.972h1.296c1.014 0 1.6.418 1.6 1.17 0 .617-.366 1.014-1.024 1.14l1.41 1.662zm-1.296-2.77c0-.387-.293-.585-.836-.585h-.272v1.202h.25c.564 0 .857-.21.857-.617zm-5.06-1.202h2.488v.67h-1.6v.89h1.547v.68h-1.547v1.077h1.6v.67H23.6v-3.983zm-2.823 4.077l-1.903-4.087h.962l1.202 2.676 1.213-2.676h.94l-1.923 4.087h-.49zm-7.955-.01c-1.338 0-2.383-.91-2.383-2.09 0-1.15 1.066-2.08 2.404-2.08.376 0 .7.073 1.077.24v.92a1.57 1.57 0 0 0-1.098-.45c-.836 0-1.474.606-1.474 1.37 0 .805.627 1.38 1.516 1.38.397 0 .7-.126 1.056-.44v.92a2.69 2.69 0 0 1-1.098.23zm-2.655-1.307c0 .774-.638 1.307-1.558 1.307-.67 0-1.15-.23-1.558-.753l.575-.49c.2.355.533.533.95.533.397 0 .68-.24.68-.554 0-.178-.084-.314-.26-.418a3.64 3.64 0 0 0-.606-.23c-.826-.26-1.108-.544-1.108-1.098 0-.648.606-1.14 1.4-1.14a2.12 2.12 0 0 1 1.328.449l-.46.523a.96.96 0 0 0-.711-.314c-.376 0-.648.188-.648.44 0 .21.157.324.68.502 1.004.314 1.296.606 1.296 1.255v-.01zm-4.317-2.76h.878v3.983H5.85v-3.983zm-2.822 3.983H1.73v-3.983h1.296c1.422 0 2.404.815 2.404 1.986 0 .596-.293 1.16-.805 1.537-.44.314-.93.46-1.6.46h.01zm1.024-3c-.293-.23-.627-.314-1.202-.314h-.24v2.634h.24c.565 0 .92-.105 1.202-.314.303-.25.48-.627.48-1.014a1.27 1.27 0 0 0-.481-.993z"
            fill="#000"
        />
        <path
            d="M16.365 13.35c-1.15 0-2.09.92-2.09 2.06 0 1.213.9 2.122 2.09 2.122a2.07 2.07 0 0 0 2.091-2.091 2.06 2.06 0 0 0-2.091-2.091z"
            fill="#f27712"
        />
    </svg>
);
