import _isEmpty from 'lodash/isEmpty.js';
import _pick from 'lodash/pick.js';
import moment from 'moment-timezone';
import {
    DATE_FORMAT,
    DATE_FORMAT_WITH_OFFSET,
    RECURRENCE_NOT_SCHEDULED,
} from '~common/constants';

export function eventModel(event) {
    if (!event) {
        return;
    }

    //INFO: dates in an occurrence are stored in UTC
    const startDate = moment(event.startDate);
    const endDate = moment(event.endDate);

    return {
        ...event,
        startDate,
        endDate,
        meeting: meetingModel(event.meeting),
    };
}

export const eventModelList = (events) => (events || []).map(eventModel);

function meetingModel(meeting) {
    if (!meeting) {
        return;
    }

    //INFO: dates in the meeting are stored as strings, in the meeting timezone
    const startDate = moment.tz(meeting.startDate, meeting.timezone);
    const endDate = moment.tz(meeting.endDate, meeting.timezone);

    let range;
    if (meeting.range) {
        range = {
            ...meeting.range,
            startDate: moment.tz(
                meeting.range.startDate,
                meeting.range.recurrenceTimeZone
            ),
            endDate: !_isEmpty(meeting.range.endDate)
                ? moment.tz(
                      meeting.range.endDate,
                      meeting.range.recurrenceTimeZone
                  )
                : '',
        };
    }

    return {
        ...meeting,
        startDate,
        endDate,
        range,
    };
}

export const mapEventToOccurrenceForCreate = ({
    title,
    meeting,
    event,
    attendees,
    permissions,
    newUsers,
    createdSourceLocation,
    createdModal,
    isPrivate,
}) => {
    const occurrence = {
        ..._pick(event, ['title', 'location', 'locationOnline', 'timezone']),
        title: title ?? event.title,
        recurrence: RECURRENCE_NOT_SCHEDULED,
        startDate: event.startDate.format(DATE_FORMAT_WITH_OFFSET),
        endDate: event.endDate.format(DATE_FORMAT_WITH_OFFSET),
        meeting: meeting.id,
        newUsers,
        attendees,
        permissions,
        useMeetingAttendees: false,
        useMeetingAgenda: meeting.useMeetingAgenda ?? true,
        previousActionItemReview: meeting.previousActionItemReview,
        createdSourceLocation,
        createdModal,
        displayActionItemComments: meeting.displayActionItemComments ?? true,
    };
    if (isPrivate) {
        occurrence.isPrivate = isPrivate;
    }
    return occurrence;
};

export const mapEventToOccurrenceForUpdate = ({
    event,
    occurrence,
    newUsers,
    attendees,
    permissions,
    isPrivate,
    createdSourceLocation,
    createdModal,
}) => ({
    ..._pick(occurrence, ['id', 'meeting']),
    ..._pick(event, ['title', 'occurrenceId', 'isException']),
    // gotcha, need to set to blank to force it to go to the api, as undefined/null
    // will not get sent in json to api, and therefore won't reset it if needed
    location: event.location || '',
    locationOnline: event.locationOnline || '',
    newUsers,
    attendees,
    permissions,
    isPrivate,
    createdSourceLocation,
    createdModal,
});

export const mapSeriesToMeetingForCreate = ({
    series,
    attendees,
    permissions,
    newUsers,
    groupId,
    groupName,
    event,
    crmLinks,
    title,
    firstMeetingTitle,
    recurrence,
    createdSourceLocation,
    createdModal,
    isPrivate,
}) => {
    const meeting = {
        ..._pick(series, ['location', 'locationOnline', 'timezone', 'pattern']),
        title: title ?? series.title,
        recurrence: recurrence ?? series.recurrence,
        firstMeetingTitle: firstMeetingTitle ?? series.title,
        crmLinks,
        startDate: series.startDate.format(DATE_FORMAT),
        endDate: series.endDate.format(DATE_FORMAT),
        range: {
            ...series.range,
            // when we link, start no earlier than the start of the event the user clicked on
            startDate: event.startDate
                .clone()
                .tz(series.range.recurrenceTimeZone)
                .format('YYYY-MM-DD 00:00:00'),
            endDate: moment.isMoment(series?.range?.endDate)
                ? series.range.endDate.format(DATE_FORMAT)
                : '',
        },
        groupId,
        groupName,
        newUsers,
        attendees,
        permissions,
        previousActionItemReview: true,
        displayActionItemComments: true,
        createdSourceLocation,
        createdModal,
    };
    if (isPrivate) {
        meeting.isPrivate = isPrivate;
    }
    return meeting;
};

export const mapSeriesToMeetingForUpdate = ({
    series,
    meeting,
    attendees,
    permissions,
    isPrivate,
    newUsers,
    event,
    createdSourceLocation,
    createdModal,
}) => ({
    ..._pick(meeting, ['id']),
    ..._pick(series, ['title', 'pattern', 'timezone']),
    // gotcha, need to set to blank to force it to go to the api, as undefined/null
    // will not get sent in json to api, and therefore won't reset it if needed
    location: series.location || '',
    locationOnline: series.locationOnline || '',
    startDate: series.startDate.format(DATE_FORMAT),
    endDate: series.endDate.format(DATE_FORMAT),
    range: {
        ...series.range,
        // when we link, start no earlier than the start of the event the user clicked on
        startDate: event.startDate
            .clone()
            .tz(series.range.recurrenceTimeZone)
            .format('YYYY-MM-DD 00:00:00'),
        endDate: moment.isMoment(series?.range?.endDate)
            ? series.range.endDate.format(DATE_FORMAT)
            : '',
    },
    newUsers,
    attendees,
    permissions,
    isPrivate,
    createdSourceLocation,
    createdModal,
});

export const mapSeriesForApi = (series) => ({
    ...series,
    startDate: series.startDate.format(DATE_FORMAT),
    endDate: series.endDate.format(DATE_FORMAT),
    range: {
        ...series.range,
        startDate: series.range.startDate.format(DATE_FORMAT),
        endDate: moment.isMoment(series?.range?.endDate)
            ? series.range.endDate.format(DATE_FORMAT)
            : '',
    },
});
