import React, { Component } from 'react';
import { InputField } from '~modules/common/form-elements';

export default class InputFieldTitle extends Component {
    state = {
        focussed: false,
        value: null,
    };

    _onChange(e) {
        this.setState({
            value: e.target.value,
        });

        this.props.input.onChange(e);
    }

    _onBlur() {
        this.setState({
            focussed: false,
            value: null,
        });
    }

    _onFocus() {
        this.setState({
            focussed: true,
            value: this.props.input.value,
        });
    }

    render() {
        return (
            <InputField
                {...this.props}
                input={{
                    ...this.props.input,
                    value: this.state.value || this.props.input.value,
                }}
                onFocus={this._onFocus.bind(this)}
                onBlur={this._onBlur.bind(this)}
                onChange={this._onChange.bind(this)}
                hasBorder={false}
                hasTitlePadding
                isBold
                isLarge
            />
        );
    }
}
