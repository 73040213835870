import _reject from 'lodash/reject.js';
import _uniqBy from 'lodash/uniqBy.js';
import {
    COMMENT_MODAL_OPEN,
    COMMENT_MODAL_CLOSE,
    COMMENT_MODAL_UPDATE,
    // COMMENT_CREATE_ERROR,
    COMMENT_CREATE_SUCCESS,
    // COMMENT_DELETE_ERROR,
    COMMENT_DELETE_SUCCESS,
    DELETE_ACTION_ITEM_SUCCESS,
    LOGOUT_SUCCESS,
    COMMENT_DELETE_REQUEST,
    COMMENT_CREATE_REQUEST,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
} from '~common/action.types';
import { updateMeetingMeta } from '~client/reducers/helpers';

const initialState = {
    text: '',
    visible: false,
    loading: false,
    submitting: false,
    comments: [],
    actionItem: null,
    doc: null,
    errorMsg: null,
};

const commentReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMMENT_MODAL_UPDATE: {
            const { loading = false, comments = [], errorMsg } = action;

            return {
                ...state,
                comments,
                loading,
                errorMsg,
            };
        }

        case COMMENT_MODAL_OPEN: {
            const { actionItem, doc, comments, loading } = action;

            return {
                ...state,
                actionItem,
                doc,
                comments,
                loading,
                visible: true,
                errorMsg: '',
            };
        }

        case LOGOUT_SUCCESS:
        case COMMENT_MODAL_CLOSE:
            return {
                ...initialState,
            };

        case COMMENT_CREATE_SUCCESS: {
            if (
                (!state.actionItem ||
                    action.comment.actionItem !== state.actionItem.id) &&
                (!state.doc || action.comment.document !== state.doc.id)
            ) {
                return state;
            }

            return {
                ...state,
                submitting: false,
                text: '',
                comments: _uniqBy([...state.comments, action.comment], 'id'),
            };
        }

        case COMMENT_DELETE_SUCCESS: {
            if (
                (!state.actionItem ||
                    action.comment.actionItem !== state.actionItem.id) &&
                (!state.doc || action.comment.document !== state.doc.id)
            ) {
                return state;
            }

            return {
                ...state,
                submitting: false,
                text: '',
                comments: _reject(state.comments, { id: action.comment.id }),
            };
        }

        case COMMENT_DELETE_REQUEST:
        case COMMENT_CREATE_REQUEST: {
            if (
                (!state.actionItem ||
                    action.comment.actionItem !== state.actionItem.id) &&
                (!state.doc || action.comment.document !== state.doc.id)
            ) {
                return state;
            }

            return {
                ...state,
                submitting: true,
            };
        }

        case DELETE_ACTION_ITEM_SUCCESS: {
            if (
                !state.actionItem ||
                action.actionItem.id !== state.actionItem.id
            ) {
                return state;
            }

            return initialState;
        }

        case MOVE_OCCURRENCE_DELETE: {
            if ((action.commentIds?.length || 0) === 0) return state;
            if (
                !state.comments.some((comment) =>
                    action.commentIds.includes(comment.id)
                )
            )
                return state;

            return {
                ...state,
                comments: state.comments.filter(
                    (comment) => !action.commentIds.includes(comment.id)
                ),
            };
        }

        case MOVE_OCCURRENCE_UPDATE: {
            if ((action.commentIds?.length || 0) === 0) return state;
            const comments = state.comments.map((comment) => {
                if (!action.commentIds.includes(comment.id)) return comment;

                return updateMeetingMeta(comment, action.meeting);
            });

            return {
                ...state,
                comments,
            };
        }

        default:
            return state;
    }
};

export default commentReducer;
