import _filter from 'lodash/filter.js';
import _omit from 'lodash/omit.js';
import _uniqBy from 'lodash/uniqBy.js';
import {
    FETCH_MEETINGS_SUCCESS,
    ADD_MEETING_WEBSOCKET,
    EDIT_MEETING_WEBSOCKET,
    EDIT_MEETING_REQUEST,
    EDIT_MEETING_ERROR,
    DELETE_MEETING_SUCCESS,
    ADD_MEETING_SUCCESS,
    EDIT_MEETING_SUCCESS,
    MODAL_MEETING_CLOSE,
    MODAL_MEETING_OPEN,
    ADD_OCCURRENCE_SUCCESS,
    EDIT_OCCURRENCE_SUCCESS,
    MODAL_SENDMINUTES_OPEN,
    MODAL_SENDMINUTES_CLOSE,
    SEND_MINUTES_SAVE_REQUEST,
    SEND_MINUTES_SAVE_ERROR,
    SEND_MINUTES_SAVE_SUCCESS,
    MEETING_ARCHIVE_STATUS,
    CHANGE_MEETING_DASHBOARD_LAYOUT,
    TOGGLE_SHOW_ARCHIVED,
    LOGOUT_SUCCESS,
} from '~common/action.types';
import { VIEW_LAYOUT_GRID } from '~common/constants';

const initialState = {
    dashboard: {
        layout: VIEW_LAYOUT_GRID,
        showArchived: false,
    },
    meetings: [],
    meetingModal: {
        isOpen: false,
    },
    sendMinutesModal: {
        isOpen: false,
    },
    agendaItems: [],
};

const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_SENDMINUTES_OPEN: {
            return {
                ...state,
                sendMinutesModal: {
                    isOpen: true,
                    sendAsAgenda: action.sendAsAgenda,
                    occurrence: action.occurrence,
                    documents: action.documents,
                },
            };
        }
        case SEND_MINUTES_SAVE_REQUEST: {
            return {
                ...state,
                sendMinutesModal: _omit(state.sendMinutesModal, 'error'),
            };
        }
        case SEND_MINUTES_SAVE_SUCCESS:
        case MODAL_SENDMINUTES_CLOSE: {
            return {
                ...state,
                sendMinutesModal: {
                    isOpen: false,
                },
            };
        }
        case SEND_MINUTES_SAVE_ERROR: {
            return {
                ...state,
                sendMinutesModal: {
                    ...state.sendMinutesModal,
                    error: action.error,
                },
            };
        }
        case MODAL_MEETING_CLOSE: {
            return {
                ...state,
                meetingModal: {
                    isOpen: false,
                },
            };
        }

        case MODAL_MEETING_OPEN: {
            return {
                ...state,
                meetingModal: {
                    ..._omit(action, 'type'),
                    isOpen: true,
                },
            };
        }

        case FETCH_MEETINGS_SUCCESS: {
            const {
                page: { docs },
            } = action;

            return {
                ...state,
                meetings: _uniqBy([...docs, ...state.meetings], 'id'),
            };
        }

        case EDIT_MEETING_ERROR:
        case MEETING_ARCHIVE_STATUS:
        case EDIT_MEETING_WEBSOCKET:
        case ADD_MEETING_WEBSOCKET: {
            const meeting = action.meeting;

            if (!meeting) return state;

            return {
                ...state,
                meetings: _uniqBy([meeting, ...state.meetings], 'id'),
            };
        }

        case EDIT_MEETING_REQUEST: {
            // action.meeting is only a partial meeting object
            if (!action?.meeting?.id) return state;

            return {
                ...state,
                meetings: state.meetings.map((meeting) =>
                    meeting.id === action.meeting.id
                        ? { ...meeting, ...action.meeting }
                        : meeting
                ),
            };
        }

        case DELETE_MEETING_SUCCESS: {
            const meeting = action.meeting;

            return {
                ...state,
                meetings: _filter(state.meetings, (m) => m.id !== meeting.id),
            };
        }

        case EDIT_MEETING_SUCCESS:
        case ADD_MEETING_SUCCESS: {
            const meeting = action.meeting;

            return {
                ...state,
                meetings: _uniqBy([meeting, ...state.meetings], 'id'),
                meetingModal: {
                    isOpen: false,
                },
            };
        }

        case ADD_OCCURRENCE_SUCCESS:
        case EDIT_OCCURRENCE_SUCCESS: {
            return {
                ...state,
                meetingModal: {
                    isOpen: false,
                },
            };
        }

        case CHANGE_MEETING_DASHBOARD_LAYOUT: {
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    layout: action.layout,
                },
            };
        }

        case TOGGLE_SHOW_ARCHIVED: {
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    showArchived: !state.dashboard.showArchived,
                },
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default meetingReducer;
