import { Client } from '@microsoft/microsoft-graph-client';
import { useCallback, useMemo, useRef } from 'react';
import { INTEGRATION_AUTHSOURCE_TO_FILES_MAP } from '~shared/constants';
import { useAuthIntegrationProfile, useGetToken, useUser } from '~hooks';
import { useScript } from '~hooks/useScript';
import { getFilesConfig } from '~modules/remoteFiles/remoteFiles.mappers';
import { hasIntegrationProfileGotScopes } from '~shared/integration/integration.helper';
import { locationOrigin } from '~common/utils';
import airbrake from '~common/airbrake';

/**
 * Determine if the logged in user can link up with remote files.
 * If they have already linked up with remote files, or the authSource they've logged in isn't
 * a supported remoteFiles provider, return false.
 * Otherwise return details to help provide a link to link up.
 * @returns {Object}
 */
const useCanLinkFiles = () => {
    const user = useUser();
    const authProfile = useAuthIntegrationProfile();

    return useMemo(() => {
        const authSource = authProfile?.authSource;
        const filesConfig = getFilesConfig({ authSource });
        const {
            loginAuthSource,
            emailDomainMeta: { hostedDomainType },
        } = user;

        const canLinkFilesWithCorrectLogin =
            Object.keys(INTEGRATION_AUTHSOURCE_TO_FILES_MAP).includes(
                hostedDomainType
            ) && loginAuthSource !== hostedDomainType;

        const hasLinkedFiles =
            !canLinkFilesWithCorrectLogin &&
            hasIntegrationProfileGotScopes(authProfile, filesConfig.scopes);

        const canLinkFiles =
            Object.keys(INTEGRATION_AUTHSOURCE_TO_FILES_MAP).includes(
                authSource || hostedDomainType
            ) &&
            !(
                filesConfig?.allowPersonalAccounts === false &&
                authProfile?.isPersonalAccount
            );

        return {
            canLinkFiles,
            authSource,
            hasLinkedFiles,
            canLinkFilesWithCorrectLogin,
            hostedDomainType,
            isPersonalAccount: authProfile.isPersonalAccount,
        };
    }, [authProfile, user]);
};

const useOpenOneDriveFilePicker = ({ onChange, link }) => {
    const authProfile = useAuthIntegrationProfile();
    const oneDriveScriptStatus = useScript(
        'https://js.live.net/v7.2/OneDrive.js'
    );

    const success = async (folders) => {
        const folder = folders?.value?.[0];
        if (!folder) return;

        const client = Client.init({
            authProvider: (authDone) => authDone(null, folders.accessToken),
        });
        const drive = await client
            .api(`/me/drives/${folder?.parentReference?.driveId}`)
            .select(['name', 'owner'])
            .get();

        const breadcrumbs = [
            drive?.owner?.group?.displayName,
            drive?.name,
            folder?.parentReference?.path?.slice(
                folder?.parentReference?.path?.indexOf(':') + 2
            ),
            folder?.name,
        ].filter(Boolean);

        return {
            remoteId: `${folder.parentReference.driveId}/${folder.id}`,
            displayPath: breadcrumbs.join(' > '),
            link: folder.webUrl,
            authSource: authProfile.authSource,
        };
    };

    const handleOneDriveSuccess = async (folders) => {
        const result = await success(folders);
        onChange?.(result);
    };

    const handleError = (error) => {
        airbrake.notify({
            error,
            params: { profile: authProfile },
        });
    };

    const openOneDriveFilePicker = () => {
        const odOptions = {
            clientId: window.__minuteme_appConfig.OAUTH_AZUREAD_CLIENTID,
            action: 'query',
            viewType: 'folders',
            accountSwitchEnabled: false,
            advanced: {
                redirectUri: `${locationOrigin()}/files/choose-location`,
                queryParameters: `select=id,name,parentReference,remoteItem,shared,specialFolder,folder,webUrl`,
                filter: 'folder',
                loginHint: authProfile.email,
                isConsumerAccount: authProfile.isPersonalAccount,
            },
            openInNewWindow: true,
            success: handleOneDriveSuccess,
            error: handleError,
        };
        if (link) {
            odOptions.advanced.navigation = {
                entryLocation: { sharePoint: { sitePath: link } },
            };
        }
        window.OneDrive.open(odOptions);
    };
    return {
        openOneDriveFilePicker,
        handleOneDriveSuccess,
        oneDriveScriptStatus,
    };
};

const useOpenGoogleDriveFilePicker = ({ isPersonalAccount, onChange }) => {
    const clientInited = useRef(false);
    const pickerInited = useRef(false);

    const authProfile = useAuthIntegrationProfile();
    const { data: token } = useGetToken(authProfile);
    const loggedInUser = useUser();

    const pickerLoaded = useCallback(() => {
        pickerInited.current = true;
    }, []);
    const clientLoaded = useCallback(async () => {
        await window.gapi.client.init({
            apiKey: window.__minuteme_appConfig.GOOGLE_API_KEY,
            discoveryDocs: [
                'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
            ],
        });
        clientInited.current = true;
    }, []);

    const gapiScriptLoaded = useCallback(() => {
        window.gapi.load('picker', pickerLoaded);
        window.gapi.load('client', clientLoaded);
    }, [pickerLoaded, clientLoaded]);

    const googleApiScriptStatus = useScript(
        'https://apis.google.com/js/api.js',
        { onload: gapiScriptLoaded }
    );

    const success = async (data) => {
        // https://developers.google.com/drive/picker/reference/results
        const { docs } = data;
        const folder = docs?.[0];
        if (!folder) return;

        window.gapi.client.setToken({
            access_token: token.accessToken,
        });

        const crumbs = [folder.name];
        let parentId = folder.parentId;
        let driveId = folder.driveId || '';
        let file;
        while (parentId) {
            let response;
            try {
                response = await window.gapi.client.drive.files.get({
                    fileId: parentId,
                    fields: 'id,name,parents,driveId',
                    supportsAllDrives: true,
                });
            } catch (err) {
                //eslint-disable-next-line no-console
                console.log(
                    { data, crumbs, parentId, driveId, file, response },
                    'Error retrieving file from Google Drive'
                );
                if (err?.status !== '404') {
                    handleError(err);
                    return;
                }
            }
            file = response?.result;
            file?.name && crumbs.unshift(file.name);
            !driveId && file?.driveId && (driveId = file.driveId);
            parentId = file?.parents?.[0];
        }

        return {
            remoteId: `${driveId}/${folder.id}`,
            displayPath: crumbs.join(' > '),
            link: folder.url,
            authSource: authProfile.authSource,
        };
    };

    const handleGoogleDriveSuccess = async (data) => {
        const { action } = data;
        if (action === window.google.picker.Action.PICKED) {
            const result = await success(data);
            onChange?.(result);
        }
    };

    const handleError = (error) => {
        //eslint-disable-next-line no-console
        console.error(
            { error, profile: authProfile },
            'Error processing Google Drive'
        );
        airbrake.notify({
            error: error?.result?.error?.message || error,
            params: { profile: authProfile, error },
        });
    };

    const openGoogleDriveFilePicker = () => {
        // const showPicker = () => {
        // https://developers.google.com/drive/picker/reference#view-id
        const myFolders = new window.google.picker.DocsView()
            .setParent('root')
            .setIncludeFolders(true)
            .setSelectFolderEnabled(true)
            .setMimeTypes('application/vnd.google-apps.folder');
        const sharedFolders = new window.google.picker.DocsView()
            .setOwnedByMe(false)
            .setSelectFolderEnabled(true)
            .setMimeTypes('application/vnd.google-apps.folder');
        const sharedDrives = new window.google.picker.DocsView()
            .setEnableDrives(true)
            .setIncludeFolders(true)
            .setSelectFolderEnabled(true)
            .setMimeTypes('application/vnd.google-apps.folder');

        const picker = new window.google.picker.PickerBuilder()
            .setOAuthToken(token.accessToken)
            .setDeveloperKey(window.__minuteme_appConfig.GOOGLE_API_KEY)
            .setLocale(loggedInUser.locale)
            .setMaxItems(1)
            .setTitle('Choose a location to store your MinuteMe documents')
            .setCallback(handleGoogleDriveSuccess);

        if (isPersonalAccount) {
            picker.addView(myFolders).addView(sharedFolders);
        } else {
            picker
                .addView(sharedDrives)
                .addView(myFolders)
                .addView(sharedFolders);
        }
        picker.build().setVisible(true);
    };
    return {
        openGoogleDriveFilePicker,
        handleGoogleDriveSuccess,
        googleApiScriptStatus,
    };
};

export {
    useCanLinkFiles,
    useOpenOneDriveFilePicker,
    useOpenGoogleDriveFilePicker,
};
