import React from 'react';
import tw, { styled, css } from 'twin.macro';
import Icon from '~icons';
import HelpLink from '~modules/help/HelpLink';
import { Spacer } from '~modules/common/components';
import type { SerializedStyles } from '@emotion/react';

const Container = styled.div(
    ({
        type,
        hasNoBorders,
        hasSmallText,
    }: {
        type: NotificationTypes;
        hasNoBorders?: boolean;
        hasSmallText?: boolean;
    }) => [
        tw`flex w-full px-4 sm:px-6 py-3 sm:py-4`,
        !hasNoBorders &&
            tw`rounded shadow-[inset 0 0 0 1px rgba(0, 0, 0, 0.03)]`,
        hasSmallText && tw`text-sm sm:(py-3 px-4) svg:text-xl`,
        type === 'warning' && tw`bg-warning-solid`,
        type === 'success' && tw`bg-success-solid`,
        type === 'error' && tw`bg-error-solid`,
        type === 'information' && tw`bg-information-solid`,
        type === 'help' && tw`bg-solid`,
    ]
);

const IconWrap = styled.span(({ type }: { type: NotificationTypes }) => [
    tw`flex text-2xl mr-3 flex-shrink-0`,
    type === 'warning' && tw`text-warning-icon`,
    type === 'success' && tw`text-success-icon`,
    type === 'error' && tw`text-error-icon`,
    (type === 'information' || type === 'help') && tw`text-information-icon`,
]);

const CloseButton = styled.button(
    ({ notificationType }: { notificationType: NotificationTypes }) => [
        notificationType === 'warning' &&
            tw`text-warning-icon hover:text-warning focus:text-warning`,
        notificationType === 'success' &&
            tw`text-success-icon hover:text-success focus:text-success`,
        notificationType === 'error' &&
            tw`text-error-icon hover:text-error focus:text-error`,
        (notificationType === 'information' || notificationType === 'help') &&
            tw`text-information-icon hover:text-information focus:text-information`,
    ]
);

const hoverStyles = (
    type: NotificationTypes
): (SerializedStyles | boolean)[] => [
    type === 'warning' &&
        css`
            ${tw`text-warning`}
            a,
    button {
                ${tw`text-warning-icon hover:text-warning focus:text-warning`};
                svg {
                    ${tw`text-warning-icon hover:text-warning focus:text-warning`};
                }
            }
        `,

    type === 'success' &&
        css`
            ${tw`text-success`}
            a,
    button {
                ${tw`text-success-icon hover:text-success focus:text-success`};
            }
        `,

    type === 'error' &&
        css`
            ${tw`text-error`}
            a,
    button {
                ${tw`text-error-icon hover:text-error focus:text-error`};
            }
        `,

    (type === 'information' || type === 'help') &&
        css`
            ${tw`text-information`}
            a,
    button {
                ${tw`text-information-icon hover:text-information`};
            }
        `,
];

const TextWrap = styled.span(
    ({ type, isTitle }: { type: NotificationTypes; isTitle?: boolean }) => [
        tw`w-full`,
        type && hoverStyles(type),
        isTitle && tw`font-bold`,
        css`
            /* Fix links/buttons moving to another line  */
            a,
            button {
                ${tw`text-left inline-flex`}
            }
        `,
    ]
);

const ButtonWrap = styled.span(({ type }: { type: NotificationTypes }) => [
    tw`ml-6 flex justify-end text-xl flex-shrink-0 mt-[0.1em]` /* (mt) Visual realignment */,
    type && hoverStyles(type),
]);

const getNotificationIcon = (type: NotificationTypes): string | null => {
    if (type === 'warning') return 'warning';
    if (type === 'success') return 'tick';
    if (type === 'error') return 'error';
    if (type === 'information') return 'information';
    if (type === 'help') return 'help';
    return null;
};

/**
 * NOTE: to implement small text in this component, you can call it with hasSmallText (which reduces padding and icon size as well) or if only needed for a one off component, as follows:
 *   <Notification tw="text:sm" ... />
 */
type NotificationProps = {
    iconName?: string;
    title?: string;
    description?: JSX.Element;
    helpPage?: string;
    type: NotificationTypes;
    hasNoBorders?: boolean;
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    hasSmallText?: boolean;
};

export type NotificationTypes =
    | 'warning'
    | 'success'
    | 'error'
    | 'information'
    | 'help';

const Notification = ({
    iconName = '',
    title = '',
    description = <></>,
    helpPage = '',
    type,
    hasNoBorders = false,
    setIsOpen,
    hasSmallText = false,
    ...rest
}: NotificationProps): JSX.Element => (
    <Container {...{ type, hasNoBorders, hasSmallText }} {...rest}>
        <IconWrap {...{ type }}>
            <Icon name={iconName || getNotificationIcon(type) || ''} />
        </IconWrap>
        <Spacer align="startCol" spaceY="xs">
            {title && (
                <TextWrap isTitle {...{ type }} {...rest}>
                    {title}
                </TextWrap>
            )}
            {description && (
                <TextWrap {...{ type }} {...rest}>
                    {description}
                </TextWrap>
            )}
        </Spacer>
        {helpPage && (
            <ButtonWrap {...{ type }}>
                <HelpLink {...{ type }} modal={helpPage} {...rest} />
            </ButtonWrap>
        )}
        {setIsOpen && (
            <div tw="pl-6">
                <CloseButton
                    aria-label="close"
                    type="button"
                    onClick={(isOpen): void => setIsOpen(!isOpen)}
                    notificationType={type}
                >
                    ✕
                </CloseButton>
            </div>
        )}
    </Container>
);

export default Notification;
