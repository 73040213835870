import _isEqual from 'lodash/isEqual.js';
import _uniqBy from 'lodash/uniqBy.js';
import {
    FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS,
    DELETE_MEETING_SUCCESS,
    ADD_OCCURRENCE_SUCCESS,
    ADD_OCCURRENCE_WEBSOCKET,
    ADD_OCCURRENCES_WEBSOCKET,
    ADD_OCCURRENCES_ON_MEETING_ADD,
    EDIT_OCCURRENCE_SUCCESS,
    EDIT_OCCURRENCE_REQUEST,
    EDIT_OCCURRENCE_ERROR,
    EDIT_OCCURRENCE_WEBSOCKET,
    EDIT_OCCURRENCES_WEBSOCKET,
    OCCURRENCE_ARCHIVE_STATUS,
    DELETE_OCCURRENCES_WEBSOCKET,
    UPDATE_OCCURRENCE_ATTENDANCE_REQUEST,
    UPDATE_OCCURRENCE_ATTENDANCE_ERROR,
    UPDATE_OCCURRENCE_ATTENDANCE_WEBSOCKET,
    BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS,
    MODAL_SELECTOCCURRENCE_OPEN,
    MODAL_SELECTOCCURRENCE_CLOSE,
    MODAL_SELECTOCCURRENCE_PROCEED_SUCCESS,
    MODAL_SELECTOCCURRENCE_LOADING,
    MODAL_SELECTOCCURRENCE_PROCEED_ERROR,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
    LOGOUT_SUCCESS,
} from '~common/action.types';
import { IS_PRODUCTION_ENVIRONMENT } from '~common/constants.js';

const initialState = {
    occurrences: [],
    selectOccurrenceModal: {
        isOpen: false,
        isLoading: false,
    },
};

const occurrenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_MEETING_SUCCESS: {
            return {
                ...state,
                occurrences: state.occurrences.filter(
                    (o) => o.meeting !== action.meeting.id
                ),
            };
        }

        case FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS: {
            const {
                page: { docs },
            } = action;
            return {
                ...state,
                occurrences: _uniqBy([...state.occurrences, ...docs], 'id'),
            };
        }

        case ADD_OCCURRENCES_ON_MEETING_ADD: {
            const occurrenceList = action.occurrences;

            const updated = {
                ...state,
                occurrences: _uniqBy(
                    [...state.occurrences, ...occurrenceList],
                    'id'
                ),
            };
            // prevent unnecessary updates to state where an action (from api response) already updated the store, that websocket might subsequently update
            return _isEqual(updated, state) ? state : updated;
        }

        case ADD_OCCURRENCE_SUCCESS:
        case ADD_OCCURRENCES_WEBSOCKET:
        case ADD_OCCURRENCE_WEBSOCKET: {
            let occurrenceList = [];
            if (action?.occurrence) {
                occurrenceList.push(action.occurrence);
            } else {
                occurrenceList = action.occurrences;
            }

            const updated = {
                ...state,
                occurrences: _uniqBy(
                    [...occurrenceList, ...state.occurrences],
                    'id'
                ),
            };
            // prevent unnecessary updates to state where an action (from api response) already updated the store, that websocket might subsequently update
            return _isEqual(updated, state) ? state : updated;
        }

        case MOVE_OCCURRENCE_UPDATE:
        case OCCURRENCE_ARCHIVE_STATUS:
        case EDIT_OCCURRENCE_REQUEST:
        case EDIT_OCCURRENCE_SUCCESS:
        case EDIT_OCCURRENCE_ERROR:
        case EDIT_OCCURRENCES_WEBSOCKET:
        case EDIT_OCCURRENCE_WEBSOCKET:
        case BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS: {
            const changedOccurrenceMap = action?.occurrence
                ? { [action.occurrence.id]: action.occurrence }
                : Object.fromEntries(
                      action.occurrences.map((occurrence) => [
                          occurrence.id,
                          occurrence,
                      ])
                  );

            let anyChanged = false;
            const occurrences = state.occurrences.map((o) => {
                const updatedOccurrence = changedOccurrenceMap[o.id];
                delete changedOccurrenceMap[o.id];

                if (!updatedOccurrence || _isEqual(updatedOccurrence, o)) {
                    return o;
                }

                // occurrence has changed
                anyChanged = true;
                return updatedOccurrence;
            });

            // if there are still any changed occurrences... why would there be? This would be an Add rather than an Update?
            if (
                !IS_PRODUCTION_ENVIRONMENT &&
                Object.keys(changedOccurrenceMap).length > 0
            ) {
                // eslint-disable-next-line no-console
                console.warn('New occurrences identified during update', {
                    changedOccurrenceMap,
                });
            }
            // prevent unnecessary state changes by returning the same state object if no items have changed.
            if (!anyChanged) return state;

            return {
                ...state,
                occurrences,
            };
        }

        case MOVE_OCCURRENCE_DELETE:
        case DELETE_OCCURRENCES_WEBSOCKET: {
            let occurrenceList = [];
            if (action?.occurrence) {
                occurrenceList.push(action.occurrence.id);
            } else {
                occurrenceList = action.occurrences.map((occ) => occ.id);
            }

            return {
                ...state,
                occurrences: state.occurrences.filter(
                    (o) => !occurrenceList.includes(o.id)
                ),
            };
        }

        case UPDATE_OCCURRENCE_ATTENDANCE_REQUEST:
        case UPDATE_OCCURRENCE_ATTENDANCE_WEBSOCKET:
        case UPDATE_OCCURRENCE_ATTENDANCE_ERROR: {
            return {
                ...state,
                occurrences: state.occurrences.map((o) => {
                    if (o.id !== action.occurrenceId) {
                        return o;
                    }
                    return {
                        ...o,
                        attendees: o.attendees.map((a) =>
                            a.userId === action.attendee.userId
                                ? action.attendee
                                : a
                        ),
                    };
                }),
            };
        }
        case MODAL_SELECTOCCURRENCE_OPEN: {
            return {
                ...state,
                selectOccurrenceModal: {
                    isOpen: true,
                    ...action.data,
                },
            };
        }

        case MODAL_SELECTOCCURRENCE_LOADING: {
            return {
                ...state,
                selectOccurrenceModal: {
                    ...state.selectOccurrenceModal,
                    isLoading: true,
                },
            };
        }

        case MODAL_SELECTOCCURRENCE_PROCEED_ERROR: {
            return {
                ...state,
                selectOccurrenceModal: {
                    ...state.selectOccurrenceModal,
                    isLoading: false,
                },
            };
        }

        case MODAL_SELECTOCCURRENCE_PROCEED_SUCCESS:
        case MODAL_SELECTOCCURRENCE_CLOSE: {
            return {
                ...state,
                selectOccurrenceModal: {
                    isOpen: false,
                },
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default occurrenceReducer;
