export const NOOP = 'NOOP';
export const RESUME_REDUX_UPDATES = 'RESUME_REDUX_UPDATES';
export const COMMENT_MODAL_OPEN = 'COMMENT_MODAL_OPEN';
export const COMMENT_MODAL_UPDATE = 'COMMENT_MODAL_UPDATE';
export const COMMENT_MODAL_CLOSE = 'COMMENT_MODAL_CLOSE';

export const COMMENT_CREATE_REQUEST = 'COMMENT_CREATE_REQUEST';
export const COMMENT_CREATE_SUCCESS = 'COMMENT_CREATE_SUCCESS';
export const COMMENT_CREATE_ERROR = 'COMMENT_CREATE_ERROR';

export const COMMENT_DELETE_REQUEST = 'COMMENT_DELETE_REQUEST';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_ERROR = 'COMMENT_DELETE_ERROR';

export const CONFIRM_MODAL_LOADING = 'CONFIRM_MODAL_LOADING';
export const CONFIRM_MODAL_OPEN = 'CONFIRM_MODAL_OPEN';
export const CONFIRM_MODAL_PROCEED_ERROR = 'CONFIRM_MODAL_PROCEED_ERROR';
export const CONFIRM_MODAL_PROCEED_SUCCESS = 'CONFIRM_MODAL_PROCEED_SUCCESS';
export const CONFIRM_MODAL_CANCEL = 'CONFIRM_MODAL_CANCEL';

export const DOCUMENTPREVIEW_MODAL_OPEN = 'DOCUMENTPREVIEW_MODAL_OPEN';
export const DOCUMENTPREVIEW_MODAL_UPDATE = 'DOCUMENTPREVIEW_MODAL_UPDATE';
export const DOCUMENTPREVIEW_MODAL_CANCEL = 'DOCUMENTPREVIEW_MODAL_CANCEL';

export const MANUAL_LOGIN_USER = 'MANUAL_LOGIN_USER';
export const LOGIN_SUCCESS_USER = 'LOGIN_SUCCESS_USER';
export const LOGIN_ERROR_USER = 'LOGIN_ERROR_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_SUCCESS_USER = 'SIGNUP_SUCCESS_USER';
export const SIGNUP_ERROR_USER = 'SIGNUP_ERROR_USER';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_SUCCESS_USER = 'REGISTER_SUCCESS_USER';
export const REGISTER_ERROR_USER = 'REGISTER_ERROR_USER';

// modal triggers
export const MODAL_ADD_MEETING_SIMPLE_OPEN = 'MODAL_ADD_MEETING_SIMPLE_OPEN';
export const MODAL_ADD_MEETING_SIMPLE_CLOSE = 'MODAL_ADD_MEETING_SIMPLE_CLOSE';
export const MODAL_MEETING_OPEN = 'MODAL_MEETING_OPEN';
export const MODAL_MEETING_CLOSE = 'MODAL_MEETING_CLOSE';

export const MODAL_MOVE_OCCURRENCE_OPEN = 'MODAL_MOVE_OCCURRENCE_OPEN';
export const MODAL_MOVE_OCCURRENCE_CLOSE = 'MODAL_MOVE_OCCURRENCE_CLOSE';
export const MODAL_MOVE_MEETING_OPEN = 'MODAL_MOVE_MEETING_OPEN';
export const MODAL_MOVE_MEETING_CLOSE = 'MODAL_MOVE_MEETING_CLOSE';
export const MODAL_ADD_REMOTE_CALENDAR_MEETING_OPEN =
    'MODAL_ADD_REMOTE_CALENDAR_MEETING_OPEN';
export const MODAL_ADD_REMOTE_CALENDAR_MEETING_CLOSE =
    'MODAL_ADD_REMOTE_CALENDAR_MEETING_CLOSE';
export const MODAL_MOVE_MEETING_CHOICE_OPEN = 'MODAL_MOVE_MEETING_CHOICE_OPEN';
export const MODAL_MOVE_MEETING_CHOICE_CLOSE =
    'MODAL_MOVE_MEETING_CHOICE_CLOSE';

export const MODAL_ARCHIVE_CHOICE_OPEN = 'MODAL_ARCHIVE_CHOICE_OPEN';
export const MODAL_ARCHIVE_CHOICE_CLOSE = 'MODAL_ARCHIVE_CHOICE_CLOSE';

export const MODAL_DELETE_CHOICE_OPEN = 'MODAL_DELETE_CHOICE_OPEN';
export const MODAL_DELETE_CHOICE_CLOSE = 'MODAL_DELETE_CHOICE_CLOSE';

export const MODAL_AGENDA_TEMPLATE_OPEN = 'MODAL_AGENDA_TEMPLATE_OPEN';
export const MODAL_AGENDA_TEMPLATE_CLOSE = 'MODAL_AGENDA_TEMPLATE_CLOSE';

export const MODAL_WORKSPACE_TEMPLATE_CHOICE_OPEN =
    'MODAL_WORKSPACE_TEMPLATE_CHOICE_OPEN';
export const MODAL_WORKSPACE_TEMPLATE_CHOICE_CLOSE =
    'MODAL_WORKSPACE_TEMPLATE_CHOICE_CLOSE';

export const MODAL_IMAGE_UPLOAD_OPEN = 'MODAL_IMAGE_UPLOAD_OPEN';
export const MODAL_IMAGE_UPLOAD_CLOSE = 'MODAL_IMAGE_UPLOAD_CLOSE';

export const MESSAGE_MODAL_OPEN = 'MESSAGE_MODAL_OPEN';
export const MESSAGE_MODAL_CLOSE = 'MESSAGE_MODAL_CLOSE';

export const MODAL_GROUP_OPEN = 'MODAL_GROUP_OPEN';
export const MODAL_GROUP_CLOSE = 'MODAL_GROUP_CLOSE';

export const MODAL_LOADING_OPEN = 'MODAL_LOADING_OPEN';
export const MODAL_LOADING_CLOSE = 'MODAL_LOADING_CLOSE';

export const MODAL_OCCURRENCE_DOCUMENT_OPEN = 'MODAL_OCCURRENCE_DOCUMENT_OPEN';
export const MODAL_SENDMINUTES_OPEN = 'MODAL_SENDMINUTES_OPEN';
export const MODAL_SENDMINUTES_CLOSE = 'MODAL_SENDMINUTES_CLOSE';
export const MODAL_LINKACTIONITEM_OPEN = 'MODAL_LINKACTIONITEM_OPEN';
export const MODAL_LINKACTIONITEM_CLOSE = 'MODAL_LINKACTIONITEM_CLOSE';
export const MODAL_SELECTOCCURRENCE_OPEN = 'MODAL_SELECTOCCURRENCE_OPEN';
export const MODAL_SELECTOCCURRENCE_CLOSE = 'MODAL_SELECTOCCURRENCE_CLOSE';
export const MODAL_SELECTOCCURRENCE_LOADING = 'MODAL_SELECTOCCURRENCE_LOADING';
export const MODAL_SELECTOCCURRENCE_PROCEED_SUCCESS =
    'MODAL_SELECTOCCURRENCE_PROCEED_SUCCESS';
export const MODAL_SELECTOCCURRENCE_PROCEED_ERROR =
    'MODAL_SELECTOCCURRENCE_PROCEED_ERROR';
export const MODAL_LINK_TOPIC_TO_CRM_OPEN = 'MODAL_LINK_TOPIC_TO_CRM_OPEN';
export const MODAL_LINK_TOPIC_TO_CRM_CLOSE = 'MODAL_LINK_TOPIC_TO_CRM_CLOSE';

// user.js
export const UPDATE_USERPROFILE_SUCCESS = 'UPDATE_USERPROFILE_SUCCESS';
export const UPDATE_USERPROFILE_WEBSOCKET = 'UPDATE_USERPROFILE_WEBSOCKET';
export const UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET =
    'UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET';
export const UPDATE_USERPROFILE_PREFERENCE_APP =
    'UPDATE_USERPROFILE_PREFERENCE_APP';
export const UPDATE_USERPROFILE_PREFERENCE_NOTIFICATION =
    'UPDATE_USERPROFILE_PREFERENCE_NOTIFICATION';

export const FETCH_USERPROFILE_SUCCESS = 'FETCH_USERPROFILE_SUCCESS';
export const FETCH_USERPERMISSIONS_SUCCESS = 'FETCH_USERPERMISSIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';

export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';

export const HELP_POPUP_VISIBILITY_TOGGLE = 'HELP_POPUP_VISIBILITY_TOGGLE';

export const PERMISSION_CREATE = 'PERMISSION_CREATE';
export const PERMISSION_DELETE = 'PERMISSION_DELETE';
export const PERMISSION_UPDATE = 'PERMISSION_UPDATE';

// document.js
export const FETCH_DOCUMENT_LIST_SUCCESS = 'FETCH_DOCUMENT_LIST_SUCCESS';

export const FETCH_AGENDA_ITEMS_SUCCESS = 'FETCH_AGENDA_ITEMS_SUCCESS';

export const UPLOAD_DOCUMENTS_SUCCESS = 'UPLOAD_DOCUMENTS_SUCCESS';

export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';

export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';

export const RENAME_DOCUMENT_REQUEST = 'RENAME_DOCUMENT_REQUEST';
export const RENAME_DOCUMENT_ERROR = 'RENAME_DOCUMENT_ERROR';

export const LINK_DOCUMENTS_WEBSOCKET = 'LINK_DOCUMENTS_WEBSOCKET';
export const UNLINK_DOCUMENTS_WEBSOCKET = 'UNLINK_DOCUMENTS_WEBSOCKET';

export const MEETING_PERMISSIONS_LOADED = 'MEETING_PERMISSIONS_LOADED';
export const OCCURRENCE_PERMISSIONS_LOADED = 'OCCURRENCE_PERMISSIONS_LOADED';
export const WORKSPACE_MEMBERS_LOADED = 'WORKSPACE_MEMBERS_LOADED';
export const WORKSPACE_MEMBER_ADDED = 'WORKSPACE_MEMBER_ADDED';
export const WORKSPACE_MEMBER_UPDATED = 'WORKSPACE_MEMBER_UPDATED';
export const WORKSPACE_MEMBER_DELETED = 'WORKSPACE_MEMBER_DELETED';
export const WORKSPACE_MEMBER_LOAD_FAILED = 'WORKSPACE_MEMBER_LOAD_FAILED';
export const WORKSPACE_MEMBER_LIST_RESET = 'WORKSPACE_MEMBER_LIST_RESET';

export const WORKSPACE_INVOICES_LOADED = 'WORKSPACE_INVOICES_LOADED';
export const WORKSPACE_INVOICES_LOAD_FAILED = 'WORKSPACE_INVOICES_LOAD_FAILED';
export const WORKSPACE_INVOICE_CREATED = 'WORKSPACE_INVOICE_CREATED';
export const WORKSPACE_INVOICE_UPDATED = 'WORKSPACE_INVOICE_UPDATED';

export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_MEETINGS_SUCCESS = 'FETCH_MEETINGS_SUCCESS';
export const FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS =
    'FETCH_OCCURRENCE_LIST_FOR_DASHBOARD_SUCCESS';

export const ADD_MEETING_TO_GROUP = 'ADD_MEETING_TO_GROUP';
export const VIEW_MEETING = 'VIEW_MEETING';
export const START_MEETING = 'START_MEETING';
export const ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_ERROR = 'ADD_GROUP_ERROR';
export const EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_ERROR = 'EDIT_GROUP_ERROR';
export const WORKSPACE_ADD = 'WORKSPACE_ADD';
export const WORKSPACE_EDIT = 'WORKSPACE_EDIT';
export const WORKSPACE_ACCESS_REMOVED = 'WORKSPACE_ACCESS_REMOVED';
export const WORKSPACE_TEMPLATE_ACCESS_REMOVED =
    'WORKSPACE_TEMPLATE_ACCESS_REMOVED';
export const WORKSPACE_CRM_ACCESS_REMOVED = 'WORKSPACE_CRM_ACCESS_REMOVED';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';
export const REORDER_GROUP = 'REORDER_GROUP';
export const MEETING_CHANGE_GROUP = 'MEETING_CHANGE_GROUP';
export const MEETING_CHANGE_GROUP_ERROR = 'MEETING_CHANGE_GROUP_ERROR';
export const OCCURRENCE_GROUP_ADD = 'OCCURRENCE_GROUP_ADD';
export const OCCURRENCE_GROUP_ADD_ERROR = 'OCCURRENCE_GROUP_ADD_ERROR';
export const OCCURRENCE_GROUP_REMOVE = 'OCCURRENCE_GROUP_REMOVE';
export const OCCURRENCE_GROUP_REMOVE_ERROR = 'OCCURRENCE_GROUP_REMOVE_ERROR';

export const EDIT_GROUP_WEBSOCKET = 'EDIT_GROUP_WEBSOCKET';
export const ADD_GROUP_WEBSOCKET = 'ADD_GROUP_WEBSOCKET';
export const DELETE_GROUP_WEBSOCKET = 'DELETE_GROUP_WEBSOCKET';

export const STAR_MEETING_TOGGLE_REQUEST = 'STAR_MEETING_TOGGLE_REQUEST';
export const STAR_MEETING_TOGGLE_SUCCESS = 'STAR_MEETING_TOGGLE_SUCCESS';
export const STAR_MEETING_TOGGLE_FAILURE = 'STAR_MEETING_TOGGLE_FAILURE';

export const CHANGE_MEETING_DASHBOARD_LAYOUT =
    'CHANGE_MEETING_DASHBOARD_LAYOUT';
export const TOGGLE_SHOW_ARCHIVED = 'TOGGLE_SHOW_ARCHIVED';

export const FETCH_ACTION_ITEMS_SUCCESS = 'FETCH_ACTION_ITEMS_SUCCESS';
export const FETCH_LINKED_ACTION_ITEMS_SUCCESS =
    'FETCH_LINKED_ACTION_ITEMS_SUCCESS';

export const MEETING_ARCHIVE_STATUS = 'MEETING_ARCHIVE_STATUS';
export const OCCURRENCE_ARCHIVE_STATUS = 'OCCURRENCE_ARCHIVE_STATUS';

export const EDIT_MEETING_WEBSOCKET = 'EDIT_MEETING_WEBSOCKET';
export const ADD_MEETING_WEBSOCKET = 'ADD_MEETING_WEBSOCKET';
export const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS';

export const EDIT_OCCURRENCE_WEBSOCKET = 'EDIT_OCCURRENCE_WEBSOCKET';
export const ADD_OCCURRENCE_WEBSOCKET = 'ADD_OCCURRENCE_WEBSOCKET';
export const ADD_OCCURRENCES_WEBSOCKET = 'ADD_OCCURRENCES_WEBSOCKET';
export const ADD_OCCURRENCES_ON_MEETING_ADD = 'ADD_OCCURRENCES_ON_MEETING_ADD';
export const DELETE_OCCURRENCES_WEBSOCKET = 'DELETE_OCCURRENCES_WEBSOCKET';
export const EDIT_OCCURRENCES_WEBSOCKET = 'EDIT_OCCURRENCES_WEBSOCKET';

export const EDIT_MEETING_REQUEST = 'EDIT_MEETING_REQUEST';
export const EDIT_MEETING_SUCCESS = 'EDIT_MEETING_SUCCESS';
export const EDIT_MEETING_ERROR = 'EDIT_MEETING_ERROR';
export const ADD_MEETING_SUCCESS = 'ADD_MEETING_SUCCESS';
export const ADD_MEETING_ERROR = 'ADD_MEETING_ERROR';
export const ADD_OCCURRENCE_SUCCESS = 'ADD_OCCURRENCE_SUCCESS';
export const ADD_OCCURRENCE_ERROR = 'ADD_OCCURRENCE_ERROR';
export const EDIT_OCCURRENCE_REQUEST = 'EDIT_OCCURRENCE_REQUEST';
export const EDIT_OCCURRENCE_SUCCESS = 'EDIT_OCCURRENCE_SUCCESS';
export const EDIT_OCCURRENCE_ERROR = 'EDIT_OCCURRENCE_ERROR';

export const UPDATE_OCCURRENCE_ATTENDANCE_REQUEST =
    'UPDATE_OCCURRENCE_ATTENDANCE_REQUEST';
export const UPDATE_OCCURRENCE_ATTENDANCE_ERROR =
    'UPDATE_OCCURRENCE_ATTENDANCE_REQUEST_ERROR';
export const UPDATE_OCCURRENCE_ATTENDANCE_WEBSOCKET =
    'UPDATE_OCCURRENCE_ATTENDANCE_WEBSOCKET';

export const FETCH_MINUTES_SUCCESS = 'FETCH_MINUTES_SUCCESS';
export const BULK_CREATE_OCCURRENCE_MINUTES_REQUEST =
    'BULK_CREATE_OCCURRENCE_MINUTES_REQUEST';
export const BULK_CREATE_OCCURRENCE_MINUTES_ERROR =
    'BULK_CREATE_OCCURRENCE_MINUTES_ERROR';
export const BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS =
    'BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS';
export const EDIT_OCCURRENCE_MINUTES_REQUEST =
    'EDIT_OCCURRENCE_MINUTES_REQUEST';
export const EDIT_OCCURRENCE_MINUTES_SUCCESS =
    'EDIT_OCCURRENCE_MINUTES_SUCCESS';

export const CREATE_OCCURRENCE_MINUTES_REQUEST =
    'CREATE_OCCURRENCE_MINUTES_REQUEST';
export const CREATE_OCCURRENCE_MINUTES_SUCCESS =
    'CREATE_OCCURRENCE_MINUTES_SUCCESS';
export const CREATE_OCCURRENCE_MINUTES_ERROR =
    'CREATE_OCCURRENCE_MINUTES_ERROR';

export const DELETE_OCCURRENCE_MINUTES_REQUEST =
    'DELETE_OCCURRENCE_MINUTES_REQUEST';
export const DELETE_OCCURRENCE_MINUTES_SUCCESS =
    'DELETE_OCCURRENCE_MINUTES_SUCCESS';
export const TOGGLE_OCCURRENCE_MINUTES_STATUS_WEBSOCKET =
    'TOGGLE_OCCURRENCE_MINUTES_STATUS_WEBSOCKET';
export const TOGGLE_OCCURRENCE_MINUTES_STATUS_REQUEST =
    'TOGGLE_OCCURRENCE_MINUTES_STATUS_REQUEST';
export const TOGGLE_OCCURRENCE_MINUTES_STATUS_SUCCESS =
    'TOGGLE_OCCURRENCE_MINUTES_STATUS_SUCCESS';
export const TOGGLE_OCCURRENCE_MINUTES_STATUS_ERROR =
    'TOGGLE_OCCURRENCE_MINUTES_STATUS_ERROR';

export const RENUMBER_OCCURRENCE_MINUTES_SUCCESS =
    'RENUMBER_OCCURRENCE_MINUTES_SUCCESS';
export const RENUMBER_OCCURRENCE_MINUTES_WEBSOCKET =
    'RENUMBER_OCCURRENCE_MINUTES_WEBSOCKET';

export const MOVE_OCCURRENCE_MINUTES_REQUEST =
    'MOVE_OCCURRENCE_MINUTES_REQUEST';
export const MOVE_OCCURRENCE_MINUTES_ERROR = 'MOVE_OCCURRENCE_MINUTES_ERROR';
export const COPY_OCCURRENCE_MINUTES_REQUEST =
    'COPY_OCCURRENCE_MINUTES_REQUEST';
export const COPY_OCCURRENCE_MINUTES_ERROR = 'COPY_OCCURRENCE_MINUTES_ERROR';

export const FETCH_ALL_MY_MEETING_USERS_SUCCESS =
    'FETCH_ALL_MY_MEETING_USERS_SUCCESS';

export const WEBSOCKET_SUBSCRIBE = 'WEBSOCKET_SUBSCRIBE';
export const WEBSOCKET_UNSUBSCRIBE = 'WEBSOCKET_UNSUBSCRIBE';

export const ADD_ACTION_ITEM_REQUEST = 'ADD_ACTION_ITEM_REQUEST';
export const ADD_ACTION_ITEM_SUCCESS = 'ADD_ACTION_ITEM_SUCCESS';
export const ADD_ACTION_ITEM_ERROR = 'ADD_ACTION_ITEM_ERROR';

export const UPDATE_ACTION_ITEM_REQUEST = 'UPDATE_ACTION_ITEM_REQUEST';
export const UPDATE_ACTION_ITEM_SUCCESS = 'UPDATE_ACTION_ITEM_SUCCESS';
export const UPDATE_ACTION_ITEM_ERROR = 'UPDATE_ACTION_ITEM_ERROR';

export const DELETE_ACTION_ITEM_REQUEST = 'DELETE_ACTION_ITEM_REQUEST';
export const DELETE_ACTION_ITEM_SUCCESS = 'DELETE_ACTION_ITEM_SUCCESS';
export const DELETE_ACTION_ITEM_ERROR = 'DELETE_ACTION_ITEM_ERROR';

export const LINK_ACTION_ITEM_SUCCESS = 'LINK_ACTION_ITEM_SUCCESS';
export const UNLINK_ACTION_ITEM_SUCCESS = 'UNLINK_ACTION_ITEM_SUCCESS';

export const RENUMBER_MEETING_AGENDAITEMS_WEBSOCKET =
    'RENUMBER_MEETING_AGENDAITEMS_WEBSOCKET';

export const BULK_CREATE_AGENDA_ITEM_REQUEST =
    'BULK_CREATE_AGENDA_ITEM_REQUEST';
export const BULK_CREATE_AGENDA_ITEM_ERROR = 'BULK_CREATE_AGENDA_ITEM_ERROR';

export const EDIT_AGENDA_ITEM_REQUEST = 'EDIT_AGENDA_ITEM_REQUEST';
export const EDIT_AGENDA_ITEM_SUCCESS = 'EDIT_AGENDA_ITEM_SUCCESS';

export const CREATE_AGENDA_ITEM_REQUEST = 'CREATE_AGENDA_ITEM_REQUEST';
export const CREATE_AGENDA_ITEM_SUCCESS = 'CREATE_AGENDA_ITEM_SUCCESS';
export const CREATE_AGENDA_ITEM_ERROR = 'CREATE_AGENDA_ITEM_ERROR';

export const DELETE_AGENDA_ITEM_REQUEST = 'DELETE_AGENDA_ITEM_REQUEST';
export const DELETE_AGENDA_ITEM_SUCCESS = 'DELETE_AGENDA_ITEM_SUCCESS';

export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const AGENDA_TYPE_TEMPLATE = 'AGENDA_TYPE_TEMPLATE';
export const ADD_TEMPLATE_REQUEST = 'ADD_TEMPLATE_REQUEST';
export const ADD_TEMPLATE_WEBSOCKET = 'ADD_TEMPLATE_WEBSOCKET';
export const ADD_TEMPLATE_ERROR = 'ADD_TEMPLATE_ERROR';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';
export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_WEBSOCKET = 'DELETE_TEMPLATE_WEBSOCKET';
export const UPDATE_TEMPLATE_WEBSOCKET = 'UPDATE_TEMPLATE_WEBSOCKET';

export const SEND_MINUTES_SAVE_REQUEST = 'SEND_MINUTES_SAVE_REQUEST';
export const SEND_MINUTES_SAVE_ERROR = 'SEND_MINUTES_SAVE_ERROR';
export const SEND_MINUTES_SAVE_SUCCESS = 'SEND_MINUTES_SAVE_SUCCESS';

export const FETCH_PRIVATE_NOTES_SUCCESS = 'FETCH_PRIVATE_NOTES_SUCCESS';
export const EDIT_PRIVATE_NOTES_SUCCESS = 'EDIT_PRIVATE_NOTES_SUCCESS';
export const CREATE_PRIVATE_NOTES_SUCCESS = 'CREATE_PRIVATE_NOTES_SUCCESS';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_CANCEL = 'SEARCH_CANCEL';

export const USER_SEARCH_CANCEL = 'USER_SEARCH_CANCEL';
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS';
export const USER_SEARCH_REQUEST = 'USER_SEARCH_REQUEST';
export const USER_SEARCH_ERROR = 'USER_SEARCH_ERROR';

export const HELP_SEARCH_CANCEL = 'HELP_SEARCH_CANCEL';
export const HELP_SEARCH_SUCCESS = 'HELP_SEARCH_SUCCESS';
export const HELP_SEARCH_REQUEST = 'HELP_SEARCH_REQUEST';
export const HELP_SEARCH_ERROR = 'HELP_SEARCH_ERROR';

export const PRELOAD_REQUEST = 'PRELOAD_REQUEST';
export const PRELOAD_SUCCESS = 'PRELOAD_SUCCESS';
export const PRELOAD_ERROR = 'PRELOAD_ERROR';
export const PRELOAD_COMPLETE = 'PRELOAD_COMPLETE';
export const PRELOAD_SWAP_LOAD_STATUS_MEETING =
    'PRELOAD_SWAP_LOAD_STATUS_MEETING';
export const CSRF_RETRIEVED = 'CSRF_RETRIEVED';

export const FETCH_SCHEDULED_EVENTS_SUCCESS = 'FETCH_SCHEDULED_EVENTS_SUCCESS';
export const ADD_SCHEDULED_EVENT_REQUEST = 'ADD_SCHEDULED_EVENT_REQUEST';
export const ADD_SCHEDULED_EVENT_SUCCESS = 'ADD_SCHEDULED_EVENT_SUCCESS';
export const ADD_SCHEDULED_EVENT_ERROR = 'ADD_SCHEDULED_EVENT_ERROR';
export const EDIT_SCHEDULED_EVENT_REQUEST = 'EDIT_SCHEDULED_EVENT_REQUEST';
export const EDIT_SCHEDULED_EVENT_SUCCESS = 'EDIT_SCHEDULED_EVENT_SUCCESS';
export const EDIT_SCHEDULED_EVENT_ERROR = 'EDIT_SCHEDULED_EVENT_ERROR';

export const FETCH_INTEGRATION_PROFILES_SUCCESS =
    'FETCH_INTEGRATION_PROFILES_SUCCESS';
export const INTEGRATION_PROFILE_DISCONNECTED =
    'INTEGRATION_PROFILE_DISCONNECTED';
export const INTEGRATION_PROFILE_CREATED = 'INTEGRATION_PROFILE_CREATED';
export const INTEGRATION_PROFILE_UPDATED = 'INTEGRATION_PROFILE_UPDATED';
export const INTEGRATION_PROFILE_LINKCOUNT = 'INTEGRATION_PROFILE_LINKCOUNT';
export const INTEGRATION_PROFILE_TASKLISTS = 'INTEGRATION_PROFILE_TASKLISTS';
export const INTEGRATION_PROFILE_CALENDAR = 'INTEGRATION_PROFILE_CALENDAR';
export const INTEGRATION_CALENDAR_LINKS_CREATED =
    'INTEGRATION_CALENDAR_LINKS_CREATED';
export const INTEGRATION_CALENDAR_LINKS_DELETED =
    'INTEGRATION_CALENDAR_LINKS_DELETED';
export const INTEGRATION_CALENDAR_LINKS_REQUEST =
    'INTEGRATION_CALENDAR_LINKS_REQUEST';
export const INTEGRATION_CALENDAR_LINKS_LOADED =
    'INTEGRATION_CALENDAR_LINKS_LOADED';
export const REMOTE_CALENDAR_EVENTS_UPDATED = 'REMOTE_CALENDAR_EVENTS_UPDATED';
export const REMOTE_CALENDAR_EVENTS_DELETED = 'REMOTE_CALENDAR_EVENTS_DELETED';
export const REMOTE_CALENDAR_EVENT_HIDDEN = 'REMOTE_CALENDAR_EVENT_HIDDEN';

export const USER_CLEAR_ACTIVE = 'USER_CLEAR_ACTIVE';
export const USER_JOIN_WEBSOCKET = 'USER_JOIN_WEBSOCKET';
export const USER_LEAVE_WEBSOCKET = 'USER_LEAVE_WEBSOCKET';
export const USER_TOPIC_CHANGE_WEBSOCKET = 'USER_TOPIC_CHANGE_WEBSOCKET';
export const USER_TYPING_WEBSOCKET = 'USER_TYPING_WEBSOCKET';
export const USER_ACTIVELOCATION_WEBSOCKET = 'USER_ACTIVELOCATION_WEBSOCKET';

export const WEBSOCKET_DISCONNECTED = 'WEBSOCKET_DISCONNECTED';

export const EVENT_REPLAY_START = 'EVENT_REPLAY_START';
export const EVENT_REPLAY_DONE = 'EVENT_REPLAY_DONE';

export const SCHEDULE_SCROLL_DATE = 'SCHEDULE_SCROLL_DATE';

export const FETCH_PAGEVIEWS_SUCCESS = 'FETCH_PAGEVIEWS_SUCCESS';
export const PAGEVIEW_CREATED = 'PAGEVIEW_CREATED';
export const PAGEVIEW_UPDATED = 'PAGEVIEW_UPDATED';
export const PAGEVIEW_META_UPDATED = 'PAGEVIEW_META_UPDATED';
export const PAGEVIEW_PAGESTATE_UPDATE = 'PAGEVIEW_PAGESTATE_UPDATE';
export const PAGEVIEW_DASHBOARDSTATE_UPDATE = 'PAGEVIEW_DASHBOARDSTATE_UPDATE';
export const PAGEVIEW_PAGESTATE_UPDATE_ROWCOUNT =
    'PAGEVIEW_PAGESTATE_UPDATE_ROWCOUNT';

export const REMOTE_CALENDAR_EVENTS_LOADING = 'REMOTE_CALENDAR_EVENTS_LOADING';
export const REMOTE_CALENDAR_EVENTS_LOADED = 'REMOTE_CALENDAR_EVENTS_LOADED';
export const REMOTE_CALENDAR_EVENTS_CLEAR = 'REMOTE_CALENDAR_EVENTS_CLEAR';

export const CRM_CUSTOMER_DATA_LOADING = 'CRM_CUSTOMER_DATA_LOADING';
export const CRM_CUSTOMER_DATA_LOADED = 'CRM_CUSTOMER_DATA_LOADED';
export const CRM_CUSTOMER_DELETED = 'CRM_CUSTOMER_DELETED';

export const INTEGRATION_CRM_LINKS_LOADED = 'INTEGRATION_CRM_LINKS_LOADED';
export const INTEGRATION_CRM_LINKS_DELETED = 'INTEGRATION_CRM_LINKS_DELETED';

export const MOVE_OCCURRENCE_DELETE = 'MOVE_OCCURRENCE_DELETE';
export const MOVE_OCCURRENCE_UPDATE = 'MOVE_OCCURRENCE_UPDATE';

export const OCCURRENCE_ACTIVITIES_LOADED = 'OCCURRENCE_ACTIVITIES_LOADED';
export const OCCURRENCE_ACTIVITY_LOADED = 'OCCURRENCE_ACTIVITY_LOADED';
export const OCCURRENCE_ACTIVITY_COUNT_LOADED =
    'OCCURRENCE_ACTIVITY_COUNT_LOADED';
