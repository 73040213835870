import React from 'react';
export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m23.273 6v4.4c0 1.1046 0.8955 2 2 2h3.7273m-5.7273-6.4h-8.2727c-2.2091 0-4 1.7909-4 4v16c0 2.2091 1.7909 4 4 4h10c2.2091 0 4-1.7909 4-4v-13.6m-5.7273-6.4 5.7273 6.4"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m21 5c0-1.6568-1.3431-3-3-3h-11c-2.2091 0-4 1.7909-4 4v15c0 2.2091 1.7909 4 4 4h4.5"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
