// TODO: Next time this file is changed, move out of generic api folder and
// into ~modules/actionItems/

import moment from 'moment-timezone';

export function actionItemModel(item) {
    if (!item) {
        return;
    }

    const createdAt = moment(item.createdAt);
    const completedAt = item.completedAt ? moment(item.completedAt) : null;
    const dueDate = item.dueDate ? moment(item.dueDate) : null;

    const startDate = moment(item.occurrenceMeta.startDate);

    return {
        ...item,
        createdAt,
        completedAt,
        dueDate,
        occurrenceMeta: {
            ...item.occurrenceMeta,
            startDate,
        },
    };
}

export function actionItemModelList(items = []) {
    return items.map(actionItemModel);
}
