import _fpcompose from 'lodash/fp/compose.js';
import _fpmap from 'lodash/fp/map.js';
import _fpreject from 'lodash/fp/reject.js';
import _fporderBy from 'lodash/fp/orderBy.js';
import _isNil from 'lodash/isNil.js';
import { createSelector } from 'reselect';
import { getStore } from '~common/store';
import {
    getAccessLevelByPermission,
    getOccurrenceAccessLevel,
    isAcceptedPermission,
    isLeftPermission,
} from '~modules/permission/permission.helpers';

import {
    getOccurrenceSelector,
    getOccurrenceFromStoreById,
} from '~modules/occurrence/occurrence.selectors';
import { getUserSelector } from '~modules/user/user.selectors';
import { getActiveMeetings } from '~modules/meeting/meeting.selectors';
import { getWorkspaceGroupByMeetingId } from '~modules/group/group.selectors';
import {
    getOccurrenceIdSelector,
    createDeepEqualSelector,
} from '~common/selector.helpers';
import {
    PERMISSION_STATUS_ACCEPTED,
    PERMISSION_TYPE_MEETING,
    PERMISSION_TYPE_WORKSPACE,
    PERMISSION_LEVEL_ADMIN,
} from '~common/constants';

export const getPermissionsSelector = (state) => state.permission;

export const getMeetingPermissions = createSelector(
    getPermissionsSelector,
    getUserSelector,
    (permissions, user) =>
        permissions.filter(
            (p) =>
                p.user === user.id &&
                p.targetType === PERMISSION_TYPE_MEETING &&
                p.status === PERMISSION_STATUS_ACCEPTED &&
                !p.scope
        )
);

export const getMeetingAdminPermissions = createSelector(
    getMeetingPermissions,
    (permissions) =>
        permissions.filter((p) => p.grants.includes(PERMISSION_LEVEL_ADMIN))
);

export const getPermissionStatusForTarget = (target) => {
    const state = getStore().getState();
    const permissions = getPermissionsSelector(state);
    const user = state.user;
    const targetPermissions = permissions.filter(
        (permission) =>
            permission.target === target && permission.user === user.id
    );
    return {
        hasLeft: targetPermissions.some(isLeftPermission),
        hasAccepted: targetPermissions.some(isAcceptedPermission),
    };
};

/**
 * Get the access level for an occurrence from the store. This is memoized for the occurrence.id.
 * If you are looking for the non-memoized version, use getOccurrenceAccessLevelNonMemoized.
 * @param {Object} state Redux store.
 * @param {Object} props
 * @param {(Object|string)} props.occurrence Occurrence object or occurrence.id.
 * @param {boolean} [props.bypassOccurrenceExistenceCheck = false] Determine whether to check for access even if occurrence doesn't exist in user's store.
 */
export const getOccurrenceAccessLevelSelector = createSelector(
    getPermissionsSelector,
    getUserSelector,
    getOccurrenceSelector,
    getOccurrenceIdSelector,
    (_, props) => props.bypassOccurrenceExistenceCheck || false,
    (
        permissions,
        user,
        occurrence,
        occurrenceId,
        bypassOccurrenceExistenceCheck
    ) => {
        return getOccurrenceAccessLevel(
            permissions,
            user,
            occurrence,
            occurrenceId,
            bypassOccurrenceExistenceCheck
        );
    }
);

/**
 * Get the access level for an occurrence from the store. This is not memoized, so can be used
 * to retrieve access level for any occurrence at any time.
 * If you are looking for the memoized version, use getOccurrenceAccessLevelSelector.
 * @param {Object} [state = getStore().getState()] Redux store. If not provided, the global store will be used.
 * @param {Object} props
 * @param {(Object|string)} props.occurrence Occurrence object or occurrence.id
 * @param {boolean} [props.bypassOccurrenceExistenceCheck = false] Determine whether to check for access even if occurrence doesn't exist in user's store.
 */
export function getOccurrenceAccessLevelNonMemoized() {
    let state, props;
    switch (arguments.length) {
        case 2:
            [state, props] = arguments;
            break;
        case 1:
            [props] = arguments;
            state = getStore().getState();
    }

    const permissions = getPermissionsSelector(state);
    const user = getUserSelector(state);
    const occurrenceId = getOccurrenceIdSelector(state, props);
    const occurrence = getOccurrenceFromStoreById(state, occurrenceId);
    const bypassOccurrenceExistenceCheck =
        props.bypassOccurrenceExistenceCheck || false;

    return getOccurrenceAccessLevel(
        permissions,
        user,
        occurrence,
        occurrenceId,
        bypassOccurrenceExistenceCheck
    );
}

/**
 * A list of meetings that a user has access to, formatted as "options" for a dropdown list.
 * The list of meetings are across all workspaces and exclude archived (and intro) meetings.
 * List is sorted by the meeting.title.
 * @param {Object} state Redux state object.
 * @param {Object} props
 * @param {boolean} [props.keepIntro=false] Avoid filtering out the intro meeting from the list.
 */
export const getMeetingListWithUsersAccessForDropdown = createSelector(
    getMeetingPermissions,
    getActiveMeetings,
    (_, props) => props?.keepIntro ?? false,
    (permissions, meetings, keepIntro) => {
        const makeOptionsList = (items) => {
            return _fpcompose(
                _fpreject(_isNil),
                _fpmap((listItem) => {
                    const permission = permissions.find(
                        (p) => p.target === listItem.id
                    );
                    const myAccessLevel =
                        getAccessLevelByPermission(permission);
                    if (!myAccessLevel.canView) return;

                    const workspace = getWorkspaceGroupByMeetingId({
                        meeting: listItem.id,
                    });
                    const workspaceMeeting = workspace?.meetings?.find(
                        (m) => m.meetingId === listItem.id
                    );

                    const meetingLimitHit =
                        workspace?.workspaceProps?.limit &&
                        workspace?.workspaceProps?.activeMeetingCount >=
                            workspace?.workspaceProps?.limit;

                    return {
                        key: listItem.id,
                        value: listItem.id,
                        text: listItem.title,
                        hasmultipleoccurrences:
                            workspaceMeeting?.hasMultipleOccurrences?.toString(),
                        meetingLimitHit,
                        myAccessLevel,
                        workspaceId: workspace?.id,
                        location: listItem.location,
                        locationOnline: listItem.locationOnline,
                        attendees: listItem.attendees,
                        isIntro: listItem.isIntro,
                        permissions,
                        hasRemoteCalendarLink:
                            listItem.calendarLinks?.length > 0,
                        calendarLinks: listItem.calendarLinks,
                    };
                }),
                // Sort by `title` and then by `createdAt`
                _fporderBy(
                    [(m) => m.title.toLowerCase(), 'createdAt'],
                    ['asc', 'asc']
                )
            )(items);
        };

        const meetingList = keepIntro
            ? meetings
            : meetings.filter((m) => !m.isIntro);

        return makeOptionsList(meetingList);
    }
);

export const getAllMyWorkspacePermissions = createDeepEqualSelector(
    getPermissionsSelector,
    (permissions) =>
        permissions.filter(
            (permission) => permission.targetType === PERMISSION_TYPE_WORKSPACE
        )
);
