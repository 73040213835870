import qs from 'querystring';
import { createDeepEqualSelector as createSelector } from '~common/selector.helpers';

/**
 * Parse a query string into a plain JavaScript object
 * Duplicate function to avoid circ dep errors:
 * From: "import { parseQueryString } from '~modules/navigation';"
 * @param {String} queryString
 * e. g. 'foo=bar&abc=xyz&abc=123'
 *   which becomes:
 *     {
 *       foo: 'bar',
 *       abc: ['xyz', '123']
 *     }
 */
const emptyQueryStringResult = {};
const parseQueryString = (queryString = '') => {
    // we return like this for where this may be used in a selector
    const queryStringAsObject = qs.parse(queryString.replace(/^\?/, ''));
    return Object.keys(queryStringAsObject).length > 0
        ? queryStringAsObject
        : emptyQueryStringResult;
};

export const getSearchParams = createSelector(
    (_, props) => props?.location?.search,
    (queryString) => parseQueryString(queryString)
);
