import _reject from 'lodash/reject.js';
import _uniqBy from 'lodash/uniqBy.js';

import {
    FETCH_USERPERMISSIONS_SUCCESS,
    PERMISSION_CREATE,
    PERMISSION_UPDATE,
    PERMISSION_DELETE,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
    LOGOUT_SUCCESS,
} from '../../common/action.types.js';

const initialState = [];

const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERPERMISSIONS_SUCCESS: {
            return [...action.permissions, ...state];
        }

        case PERMISSION_UPDATE:
        case PERMISSION_CREATE: {
            // For optimistic workspace creation, permission is added with the id of the new workspace.
            // so we remove that if real permission gets created
            return _uniqBy(
                [
                    action.permission,
                    ...state.filter(
                        (permission) =>
                            permission.id !== action.permission?.target
                    ),
                ],
                'id'
            );
        }

        case MOVE_OCCURRENCE_DELETE: {
            return state.filter(
                (permission) =>
                    permission.target !== action.occurrence.id &&
                    permission?.scope?.target !== action.occurrence.id
            );
        }

        case MOVE_OCCURRENCE_UPDATE: {
            if ((action.updatedPermissions?.length || 0) === 0) return state;
            const updatedPermissions = Object.fromEntries(
                action.updatedPermissions.map((permission) => [
                    permission.id,
                    permission,
                ])
            );
            return state.map((permission) =>
                permission.id in updatedPermissions
                    ? updatedPermissions[permission.id]
                    : permission
            );
        }

        case PERMISSION_DELETE: {
            return _reject(state, { id: action.permission.id });
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default permissionReducer;
