import { createSelector } from 'reselect';
import { normalizeWorkspace, orderWorkspaces } from './workspace.helpers.js';
import { getWorkspaceGroupsSelector } from '~modules/group/group.selectors';
import { getAllMyWorkspacePermissions } from '~modules/permission/permission.selectors';

/**
 * Get a list of workspaces for the current user
 */
const getWorkspacesSelector = createSelector(
    getWorkspaceGroupsSelector,
    getAllMyWorkspacePermissions, // should force reload if permissions change, as the permissions are used in the normalizeWorkspace function
    (workspaces) =>
        workspaces
            .filter((workspace) => !workspace.workspaceProps.isTemplateLibrary)
            .map(normalizeWorkspace)
            .sort(orderWorkspaces)
);

/**
 * Get a list of workspace template libraries for the current user
 */
const getTemplateLibraryWorkspacesSelector = createSelector(
    getWorkspaceGroupsSelector,
    getAllMyWorkspacePermissions, // should force reload if permissions change, as the permissions are used in the normalizeWorkspace function
    (workspaces) =>
        workspaces
            .filter((workspace) => workspace.workspaceProps.isTemplateLibrary)
            .map(normalizeWorkspace)
            .sort(orderWorkspaces)
);

/**
 * Get a list of workspaces and template libraries for the current user
 */
const getWorkspacesAndTemplateLibrariesSelector = createSelector(
    getWorkspaceGroupsSelector,
    getAllMyWorkspacePermissions, // should force reload if permissions change, as the permissions are used in the normalizeWorkspace function
    (workspaces) => workspaces.map(normalizeWorkspace).sort(orderWorkspaces)
);

/**
 * Get a list of workspaces and updateable (with minimum Admin access) template libraries for the current user
 */
const getWorkspacesAndUpdateableTemplateLibrariesSelector = createSelector(
    getWorkspacesAndTemplateLibrariesSelector,
    (workspaces) =>
        workspaces.filter(
            (workspace) =>
                !workspace.isTemplateLibrary || workspace.myAccessLevel.isAdmin
        )
);

/**
 * Get a list of workspaces for the current user that can be updated
 */
const getWorkspacesCanUpdate = createSelector(
    getWorkspacesSelector,
    (workspaces) =>
        workspaces.filter(
            (workspace) =>
                workspace.myAccessLevel.canUpdate && !workspace.isHidden
        )
);

/**
 * Get a list of workspaces where current user is an admin
 */
const getWorkspacesIsAdmin = createSelector(
    getWorkspacesSelector,
    (workspaces) =>
        workspaces.filter(
            (workspace) =>
                workspace.myAccessLevel.isAdmin && !workspace.isHidden
        )
);

/**
 * Get a list of workspaces and workspace template libraries where current user is and admin
 */
const getWorkspacesAndTemplateLibrariesIsAdmin = createSelector(
    getWorkspacesAndTemplateLibrariesSelector,
    (workspaces) =>
        workspaces.filter(
            (workspace) =>
                workspace.myAccessLevel.isAdmin && !workspace.isHidden
        )
);

export {
    getWorkspacesSelector,
    getTemplateLibraryWorkspacesSelector,
    getWorkspacesAndTemplateLibrariesSelector,
    getWorkspacesAndUpdateableTemplateLibrariesSelector,
    getWorkspacesCanUpdate,
    getWorkspacesIsAdmin,
    getWorkspacesAndTemplateLibrariesIsAdmin,
};
