import { createSelector } from 'reselect';
import {
    getMeetingGroupsSelector,
    findGroupForOccurrence,
} from '../group/group.selectors.js';
import { mapOccurrenceForListDisplay } from '~modules/occurrence/occurrence.selectors';
import {
    getOccurrenceIdSelector,
    getMeetingIdSelector,
    getWorkspaceIdSelector,
} from '~common/selector.helpers';
import { canViewOccurrence } from '~modules/permission/permission.helpers';
import { getUserSelector } from '~modules/user/user.selectors';
import { getStore } from '~common/store';

export const getPageViewsSelector = createSelector(
    (state) => state.pageView.pageViews,
    (state) => state.permission,
    getMeetingGroupsSelector,
    (state) => state.occurrences.occurrences,
    getUserSelector,
    (pageViews, permissions, groups, occurrences, user) =>
        pageViews
            .filter((pageView) => {
                const occurrence = {
                    id: pageView.occurrence,
                    ...pageView.occurrenceMeta,
                    meeting: pageView.meeting,
                };

                const occurrenceExists = occurrences.some(
                    (occurrence) => occurrence.id === pageView.occurrence
                );

                return (
                    occurrenceExists &&
                    canViewOccurrence(permissions, occurrence, user.id)
                );
            })
            .map((pageView) => {
                const {
                    occurrenceMeta,
                    meeting: meetingId,
                    meetingMeta,
                } = pageView;

                const occurrence = {
                    id: pageView.occurrence,
                    ...occurrenceMeta,
                    meeting: meetingId,
                    meetingMeta,
                };

                const meeting = {
                    id: meetingId,
                    ...meetingMeta,
                };

                const group = occurrence.id
                    ? findGroupForOccurrence(groups, {
                          occurrence,
                      })
                    : undefined;

                // INFO at this stage, code only supports displaying occurrence pageViews.
                // Whilst there is pageView.pageType, it's not used just yet. So we always pass back only occurrences.
                return mapOccurrenceForListDisplay({
                    occurrence,
                    meeting,
                    group,
                });
            })
);

export const getPageStateForOccurrence = (state, props) => {
    const id = getOccurrenceIdSelector(state, props);
    return getPageState(state, id);
};

export const getPageStateForMeeting = (state, props) => {
    const id = getMeetingIdSelector(state, props);
    return getPageState(state, id);
};

/**
 * Get the pageState of a page
 * @param {Object} [state=getStore().getState()] Redux store. If ommitted, will use the default Redux store.
 * @param {string} id A page id. Maybe be a meeting.id, occurrence.id for example.
 */
export function getPageState() {
    let state, id;
    switch (arguments.length) {
        case 2: {
            [state, id] = arguments;
            break;
        }
        case 1: {
            [id] = arguments;
            state = getStore().getState();
        }
    }

    return state.pageView.pageStates.find((pageState) => pageState.id === id);
}

export const getDashboardStateForWorkspace = (state, props) => {
    const id = `workspaces-${getWorkspaceIdSelector(state, props)}`;
    return state ? getDashboardState(state, id) : getDashboardState(id);
};

/**
 * Get the pageState of a dashboard page
 * @param {Object} [state=getStore().getState()] Redux store. If ommitted, will use the default Redux store.
 * @param {string} id A dashboard id. May be 'all-meetings', or a group.id for example.
 */
export function getDashboardState() {
    let state, id;
    switch (arguments.length) {
        case 2: {
            [state, id] = arguments;
            break;
        }
        case 1: {
            [id] = arguments;
            state = getStore().getState();
        }
    }

    return state?.pageView?.dashboardState?.[id];
}
