import React from 'react';

export default ({ iconThick = true, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m14.237 4.2909c0.754-1.4074 2.772-1.4074 3.526 0l11.66 21.765c0.7138 1.3323-0.2515 2.9444-1.7629 2.9444h-23.319c-1.5115 0-2.4767-1.6121-1.763-2.9444l11.66-21.765z"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            stroke="currentColor"
        />
        <path
            d="m16 12.5v7"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            stroke="currentColor"
        />
        <circle cx="16" cy="24" r="1" fill="currentColor" />
    </svg>
);
