import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m27 3h-22c-1.1046 0-2 0.89543-2 2v22c0 1.1046 0.89543 2 2 2h22c1.1046 0 2-0.8954 2-2v-22c0-1.1046-0.8954-2-2-2z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="M10.4444 17.2353L6 22H26L19.8889 13L13.2222 19.3529L10.4444 17.2353Z"
            fill="currentColor"
        />
        <circle cx="10.5" cy="12.5" r="1.5" fill="currentColor" />
    </svg>
);
