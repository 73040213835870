import React from 'react';
import { Popup as PopupElement } from '~modules/common/form-elements';
import { Spacer, H4 as PopupHeading } from '~modules/common/components';
import 'twin.macro';

/**
 * Usage:
 * <StandardPopup
 *    heading="My heading"
 *    content={<>Popup content</>}
 *    cta={<button>Okay</button>}
 * >Click me</StandardPopup>
 */

const PopupBase = ({ content, children, ...rest }) => (
    <PopupElement
        on="click"
        position="bottom center"
        trigger={
            typeof children === 'function'
                ? children
                : () => <div>{children}</div>
        }
        {...rest}
    >
        {content}
    </PopupElement>
);

const StandardPopup = ({ content, heading, cta, children, ...rest }) => {
    const PopupContent = (
        <Spacer p="md">
            <Spacer spaceY="md" p="sm">
                {heading && <PopupHeading>{heading}</PopupHeading>}
                <Spacer spaceY="sm" tw="text-body select-none">
                    {content}
                </Spacer>
                {cta && <div>{cta}</div>}
            </Spacer>
        </Spacer>
    );
    return (
        <PopupBase wide content={PopupContent} {...rest}>
            {children}
        </PopupBase>
    );
};

export { StandardPopup };
