import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import tw, { styled, css } from 'twin.macro';
import {
    Button,
    Spacer,
    SearchInput,
    Tooltip,
} from '~modules/common/components';
import { Popup } from '~modules/common/form-elements';
import Icon from '~modules/common/icons';
import { UserAvatar } from '~modules/user/UserAvatar';
import { customScrollbar } from '~common/ui';
import { useMinScreen } from '~hooks';

const TriggerButton = styled(Button)(({ isDisabled }) => [
    tw`py-2 pl-0 pr-4 focus:outline-none svg:(text-sm text-icon) hocus:svg:text-icon-dark`,
    isDisabled && tw`text-body-disabled svg:text-icon-disabled!`,
]);
const Label = styled.label(({ isDisabled }) => [
    tw`flex items-center space-x-2 cursor-pointer svg:(text-icon mt-[2px])`,
    isDisabled && tw`pointer-events-none cursor-not-allowed`,
]);
const LoaderWrap = tw.div`flex items-center text-2xl opacity-50`;
const Loader = styled.div(({ isDark }) => [
    tw`rounded-full relative overflow-hidden bg-white w-[26px] h-[26px] border border-white
    after:([content: ''] absolute top-0 right-0 bottom-0 left-0 animate-pulse bg-icon)`,
    isDark && tw`ml-[-4px]`,
    !isDark && tw`z-10 after:bg-opacity-50`,
]);
const AvatarWrap = tw.div`flex items-center text-2xl text-icon rounded-full hover:text-icon-dark focus:outline-none group-hocus:saturate-0 ml-0.5`;
const MultipleAvatarWrap = styled.span([
    tw`flex flex-row-reverse`,
    css({ '> *': tw`-ml-1` }),
]);
const MemberCount = tw.div`flex items-baseline justify-center -ml-1 rounded-full bg-icon-medium text-xs text-white leading-6 select-none group-hocus:(shadow-sm bg-icon-dark) height[26px] width[26px] border border-white`; // Magic numbers here to match avatar size & prevent screen jump when count renders on it's own

const PopupContentWrap = tw.div`width[300px]`;
const SearchWrap = tw.div`border-b mx-3.5 px-2 svg:right-4!`; // Align search close icon
const ContentWrap = styled.div(({ hasPadding }) => [
    tw`relative after:([content: ''] absolute left-0 right-0 bottom-0 h-6 pointer-events-none bg-gradient-to-t from-white)`,
    hasPadding && tw`pt-2`,
]);

const Content = styled.div([
    // TODO: find better solution for popup going off screen with long list
    tw`pb-2 [max-height:30vh]`,
    customScrollbar(),
    css`
        /* Force scrollbar to always be visible */
        ::-webkit-scrollbar-thumb {
            ${tw`bg-line`}
        }
    `,
]);
const UserItem = styled.button(({ isDisabled }) => [
    tw`w-full text-body focus:outline-none`,
    isDisabled && tw`pointer-events-none`,
]);
const NoResults = tw.div`px-4 py-2 text-sm text-body`;

const MultipleAvatarDisplay = ({
    memberCountDisplay,
    userList,
    isSize,
    enableTooltip = false,
    hasFullUserDetailTooltip = false,
    position,
    offset,
}) => {
    const getSlice =
        memberCountDisplay > 7 && isSize?.tiny
            ? 6
            : memberCountDisplay > 9
            ? 8
            : memberCountDisplay;
    return (
        <MultipleAvatarWrap>
            {memberCountDisplay === 0 ? (
                <MemberCount>{memberCountDisplay}</MemberCount>
            ) : (
                userList
                    .filter(({ displayUserAvatar }) => displayUserAvatar)
                    .slice(0, getSlice)
                    .reverse()
                    .map((u) => (
                        <UserAvatar
                            key={u?.user?.id || u.id}
                            user={u?.user || u.id}
                            size="smaller"
                            hasBorder
                            hasAdminIndicator={u.isMeetingAdmin}
                            {...{
                                enableTooltip,
                                hasFullUserDetailTooltip,
                                position,
                                offset,
                            }}
                        />
                    ))
            )}
        </MultipleAvatarWrap>
    );
};

const MemberSelectDropdown = ({
    label,
    userList,
    headerSlot,
    footerSlot,
    tooltipContent,
    memberCountDisplay,
    hasSearchBar,
    isDisabled,
    enableTooltip,
    displayMemberAvatars = true,
    noResultsMessage,
    offset,
    attendees,
}) => {
    const [searchText, setSearchText] = useState('');
    const [searchOptions, setSearchOptions] = useState(userList);
    const { min } = useMinScreen();
    const isSize = {
        tiny: !min('xs'),
    };

    useEffect(() => {
        searchText?.length > 0
            ? setSearchOptions([
                  ...new Set(
                      userList?.filter((user) =>
                          user?.username
                              ?.toLowerCase()
                              .includes(searchText?.toLowerCase())
                      )
                  ),
              ])
            : setSearchOptions(userList);
    }, [searchText, setSearchOptions, userList]);

    const isLoading = attendees?.length === 0 ? false : !userList?.length;

    const showEllips =
        memberCountDisplay > 9 || (memberCountDisplay > 7 && isSize.tiny);

    const PopupTrigger = () => (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div tw="inline-block" onClick={(e) => e.preventDefault()}>
            <TriggerButton isDisabled={isLoading}>
                <Tooltip
                    content={tooltipContent}
                    position="top left"
                    {...{ enableTooltip }}
                >
                    <Label isDisabled={isLoading}>
                        {displayMemberAvatars && (
                            <Spacer align="center">
                                {isLoading ? (
                                    <LoaderWrap>
                                        <Loader />
                                        {attendees?.length > 1 && (
                                            <Loader isDark />
                                        )}
                                    </LoaderWrap>
                                ) : (
                                    <AvatarWrap>
                                        <MultipleAvatarDisplay
                                            {...{
                                                memberCountDisplay,
                                                userList,
                                                isSize,
                                            }}
                                        />
                                        {showEllips && (
                                            <MemberCount>···</MemberCount>
                                        )}
                                    </AvatarWrap>
                                )}
                            </Spacer>
                        )}
                        <Spacer align="center" spaceX="xs">
                            <span>{label}</span>
                            <Icon name="downArrow" />
                        </Spacer>
                    </Label>
                </Tooltip>
            </TriggerButton>
        </div>
    );

    const hasActiveSearch = searchOptions?.length !== userList?.length;

    return (
        <Popup
            on="click"
            trigger={PopupTrigger}
            position="bottom left"
            disabled={isLoading}
            size="xl"
            {...{ offset }}
        >
            {({ setIsOpen }) => (
                <PopupContentWrap>
                    {hasSearchBar && (
                        <SearchWrap>
                            <SearchInput
                                focusOnMount
                                hasBorder={false}
                                {...{ setSearchText }}
                            />
                        </SearchWrap>
                    )}
                    {headerSlot && Boolean(searchOptions?.length) && headerSlot}
                    <ContentWrap hasPadding={!headerSlot}>
                        <Content>
                            {searchOptions?.length ? (
                                searchOptions.map((user) => (
                                    <Tooltip
                                        content={user.title}
                                        position="bottom left"
                                        offset={[6, 1]}
                                        key={
                                            user.key ||
                                            // Avoid an error while meeting data is catching up
                                            uuidv4()
                                        }
                                        {...{ enableTooltip }}
                                    >
                                        <UserItem
                                            onClick={user.onClick}
                                            {...{ isDisabled }}
                                        >
                                            {user.content}
                                        </UserItem>
                                    </Tooltip>
                                ))
                            ) : (
                                <NoResults>
                                    {noResultsMessage ||
                                        'No members marked as attending'}
                                </NoResults>
                            )}
                        </Content>
                    </ContentWrap>
                    {typeof footerSlot === 'function'
                        ? footerSlot({
                              setIsOpen,
                              hasActiveSearch,
                              memberCountDisplay,
                          })
                        : footerSlot}
                </PopupContentWrap>
            )}
        </Popup>
    );
};

export { MemberSelectDropdown as default, MultipleAvatarDisplay };
