// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import ObjectID from 'bson-objectid';
import moment from 'moment-timezone';
import { diff } from 'deep-object-diff';

import {
    DATE_FORMAT,
    DATE_FORMAT_WITH_OFFSET,
    ATTENDEE_ROLE_OWNER,
    ATTENDEE_ROLE_ATTENDEE,
    RECURRENCE_NOT_SCHEDULED,
    GRANT_LEVEL_NONE,
} from '../../common/constants.js';
import { grantLevelToGrants } from '~shared/permissions/permission.helper';
import { actionItemModelList } from '~client/api/actionItem.mappers';
import { getOnlineMeetingUrl } from '~modules/meeting/location.helpers';
import { minuteItemModelList } from '~modules/minutes/minutes.mappers';

export function occurrenceModel(occurrence) {
    if (!occurrence) {
        return;
    }

    // INFO: dates in an occurrence are stored in UTC
    const startDate = moment(occurrence.startDate);
    const endDate = moment(occurrence.endDate);

    const newOccurrence = {
        ...occurrence,
        startDate,
        endDate,
    };

    if (occurrence.startDateActual) {
        newOccurrence.startDateActual = moment(occurrence.startDateActual);
    }
    if (occurrence.endDateActual) {
        newOccurrence.endDateActual = moment(occurrence.endDateActual);
    }
    return newOccurrence;
}

export function occurrenceModelList(occurrences) {
    return (occurrences || []).map(occurrenceModel);
}

export function meetingModel(meeting) {
    if (!meeting) return;

    //INFO: dates in the meeting are stored as strings, in the meeting timezone
    const startDate = moment.tz(meeting.startDate, meeting.timezone);
    const endDate = moment.tz(meeting.endDate, meeting.timezone);

    const range = meeting.range;

    if (range) {
        range.startDate = moment.tz(
            meeting.range.startDate,
            meeting.range.recurrenceTimeZone
        );
        range.endDate = !_.isEmpty(meeting.range.endDate)
            ? moment.tz(meeting.range.endDate, meeting.range.recurrenceTimeZone)
            : '';
    }

    const mappedResult = { ...meeting, startDate, endDate, range };

    if (meeting?.__occurrences?.length > 0) {
        mappedResult.__occurrences = occurrenceModelList(meeting.__occurrences);
    }

    if (meeting?.__actionItems?.length > 0) {
        mappedResult.__actionItems = actionItemModelList(meeting.__actionItems);
    }

    if (meeting?.__minuteItems?.length > 0) {
        mappedResult.__minuteItems = minuteItemModelList(meeting.__minuteItems);
    }

    return mappedResult;
}

export function meetingModelList(meetings) {
    return (meetings || []).map(meetingModel);
}

export function mapNamedWeekdayKeysToArray(obj) {
    //weekdays are 0 == Sunday
    return _(obj)
        .keys()
        .filter((f) => f.slice(0, 3) === 'day' && f.length === 4)
        .map((d) => (obj[d] ? Number.parseInt(d.slice(-1)) : null))
        .reject(_.isNull)
        .sort((a, b) => a - b)
        .value();
}

export const mapAttendeesAndPermissionsSettings = (
    attendeesAndPermissions = []
) => {
    const attendees = [],
        permissions = [],
        newUsers = [];

    let usersWithAccess = 0;
    let usersWithoutAccess = 0;
    for (const setting of attendeesAndPermissions) {
        const { user, grantLevel, attendee } = setting;

        if (setting.isAttendee) {
            attendees.push({
                userId: user.id,
                role: setting.isOwner
                    ? ATTENDEE_ROLE_OWNER
                    : ATTENDEE_ROLE_ATTENDEE,
                ...attendee,
            });
        }

        if (grantLevel === GRANT_LEVEL_NONE) {
            usersWithoutAccess += 1;
        } else {
            usersWithAccess += 1;
        }
        const grants = grantLevelToGrants(grantLevel);

        if (grants.length) {
            permissions.push({
                grants,
                user: user.id,
                displayName: user.displayName,
            });
        }

        // Create list of new users
        // The list is based on their id: if it's an email then it's a brand new user
        if (!ObjectID.isValid(user.id)) {
            newUsers.push({ email: user.email, displayName: user.displayName });
        }
    }

    return {
        attendees,
        permissions,
        newUsers,
        isPrivate: usersWithAccess === 1 && usersWithoutAccess > 0,
    };
};

export function transformOccurrenceAddFormForPOST(values) {
    const {
        attendeesAndPermissions,
        recurrenceDetail: { endDate, startDate, timezone },
        title,
        location,
        locationOnline,
        newUsers,
        meeting,
        useMeetingAgenda,
        useMeetingAttendees,
        previousActionItemReview,
        displayActionItemComments,
        createdSourceLocation,
        createdModal,
        createdAuthSource,
        savePDFToCloud,
        meetingType,
    } = values;

    let attendees;
    let permissions;
    let isPrivate;

    if (!useMeetingAttendees) {
        ({ attendees, permissions, isPrivate } =
            mapAttendeesAndPermissionsSettings(attendeesAndPermissions));
    }

    const occurrence = {
        meeting,
        title,
        location,
        locationOnline: locationOnline && getOnlineMeetingUrl(locationOnline),
        timezone,
        startDate: startDate.format(DATE_FORMAT_WITH_OFFSET),
        endDate: endDate.format(DATE_FORMAT_WITH_OFFSET),
        // recurrence should always be 'adh' and not 'ooff'. 'ooff' is only when a new meeting is created rather than a new occurrence.
        recurrence: RECURRENCE_NOT_SCHEDULED,
        newUsers,
        useMeetingAttendees,
        attendees,
        permissions,
        useMeetingAgenda,
        previousActionItemReview,
        displayActionItemComments,
        createdSourceLocation,
        createdModal,
        createdAuthSource,
        savePDFToCloud,
        meetingType,
    };

    // only save isPrivate on the occurrence if it's true, no need to save if it's false
    if (isPrivate) {
        occurrence.isPrivate = isPrivate;
    }

    return occurrence;
}

export function transformMeetingAddFormForPOST(values) {
    const {
        attendeesAndPermissions,
        recurrenceDetail,
        recurrenceDetail: {
            occurs,
            interval,
            month,
            dayOfMonth,
            index,
            firstDayOfWeek,
            startDate,
            endDate,
            timezone,
            rangeStartDate,
            rangeEndDate,
            recurrenceTimeZone,
        },
        crmLinks,
        fileLink,
        title,
        location,
        locationOnline,
        groupId,
        groupName,
        isIntro,
        newUsers,
        recurrence,
        displayActionItemComments,
        previousActionItemReview,
        firstMeetingTitle,
        createdSourceLocation,
        createdModal,
        savePDFToCloud,
        createdAuthSource,
        meetingType,
    } = values;

    const { remoteId, link, authSource } = crmLinks || {};
    const {
        remoteId: fileRemoteId,
        link: fileUrl,
        authSource: fileAuthSource,
        userId,
        displayPath,
    } = fileLink || {};

    const { attendees, permissions, isPrivate } =
        mapAttendeesAndPermissionsSettings(attendeesAndPermissions);

    const meet = {
        title,
        crmLinks: {
            remoteId,
            link,
            authSource,
        },
        fileLink: {
            remoteId: fileRemoteId,
            link: fileUrl,
            authSource: fileAuthSource,
            userId,
            displayPath,
        },
        firstMeetingTitle,
        location,
        locationOnline: locationOnline && getOnlineMeetingUrl(locationOnline),
        groupId,
        groupName,
        isIntro,
        attendees,
        permissions,
        newUsers,
        startDate: startDate.format(DATE_FORMAT),
        endDate: endDate.format(DATE_FORMAT),
        timezone,
        recurrence,
        pattern: {
            occurs,
            interval,
            weekdays: mapNamedWeekdayKeysToArray(recurrenceDetail),
            month,
            dayOfMonth,
            index,
            firstDayOfWeek,
        },
        range: {
            startDate: rangeStartDate.format(DATE_FORMAT),
            endDate:
                rangeEndDate instanceof moment
                    ? rangeEndDate.format(DATE_FORMAT)
                    : '',
            recurrenceTimeZone,
        },
        displayActionItemComments,
        previousActionItemReview,
        createdSourceLocation,
        createdModal,
        createdAuthSource,
        savePDFToCloud,
        meetingType,
    };

    // only save isPrivate on the meeting if it's true, no need to save if it's false
    if (isPrivate) {
        meet.isPrivate = isPrivate;
    }

    return meet;
}

export function transformMeetingEditFormForPOST(values, prev) {
    const delta = diff(prev, values);

    const meet = {
        ...delta,
    };

    delete meet.newUser;
    delete meet.userSearch;

    const {
        attendeesAndPermissions = [],
        recurrenceDetail,
        fileLink,
        crmLinks,
    } = values;

    if (delta.fileLink) {
        meet.fileLink = fileLink;
    }
    if (delta.crmLinks) {
        meet.crmLinks = crmLinks;
    }
    if (delta.attendeesAndPermissions) {
        delete meet.attendeesAndPermissions;

        const { attendees, permissions, isPrivate } =
            mapAttendeesAndPermissionsSettings(attendeesAndPermissions);

        meet.attendees = attendees;
        meet.permissions = permissions;
        meet.isPrivate = isPrivate;
    }

    if (delta.recurrenceDetail) {
        delete meet.recurrenceDetail;

        const { startDate, endDate, timezone } = recurrenceDetail;
        if (delta.recurrenceDetail.startDate) {
            meet.startDate = startDate.format(DATE_FORMAT);
        }
        if (delta.recurrenceDetail.endDate) {
            meet.endDate = endDate.format(DATE_FORMAT);
        }
        if (delta.recurrenceDetail.timezone) {
            meet.timezone = timezone;
        }

        // separate pattern and range changes.
        // if any one field in pattern/range changes, include the whole pattern/range object.
        const rangeChange = _.find(
            Object.keys(delta.recurrenceDetail),
            (value) =>
                [
                    'rangeStartDate',
                    'rangeEndDate',
                    'recurrenceTimeZone',
                ].includes(value)
        );
        if (rangeChange) {
            const { rangeStartDate, rangeEndDate, recurrenceTimeZone } =
                recurrenceDetail;
            meet.range = {
                startDate: rangeStartDate.format(DATE_FORMAT),
                endDate:
                    rangeEndDate instanceof moment
                        ? rangeEndDate.format(DATE_FORMAT)
                        : '',
                recurrenceTimeZone,
            };
        }

        const patternChange = _.find(
            Object.keys(delta.recurrenceDetail),
            (value) =>
                [
                    'occurs',
                    'interval',
                    'month',
                    'dayOfMonth',
                    'index',
                    'firstDayOfWeek',
                    'day0',
                    'day1',
                    'day2',
                    'day3',
                    'day4',
                    'day5',
                    'day6',
                    'day7',
                ].includes(value)
        );

        if (patternChange) {
            meet.pattern = {
                ..._.pick(recurrenceDetail, [
                    'occurs',
                    'interval',
                    'month',
                    'dayOfMonth',
                    'index',
                    'firstDayOfWeek',
                ]),
                weekdays: mapNamedWeekdayKeysToArray(recurrenceDetail),
            };
        }
    }

    if (_.has(meet, 'location') && _.isUndefined(meet.location)) {
        meet.location = '';
    }
    if (_.has(meet, 'locationOnline')) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line unicorn/prefer-ternary
        if (_.isUndefined(meet.locationOnline)) {
            meet.locationOnline = '';
        } else {
            meet.locationOnline = getOnlineMeetingUrl(meet.locationOnline);
        }
    }
    if ('crmLinks' in meet && !meet.crmLinks?.remoteId) {
        meet.crmLinks = null;
    }

    return meet;
}

export function transformOccurrenceEditFormForPOST(values, prev) {
    const delta = diff(prev, values);

    const occurrence = {
        ...delta,
    };

    const { attendeesAndPermissions = [], recurrenceDetail, meeting } = values;
    const { startDate, endDate, timezone } = recurrenceDetail;

    if (values.useMeetingAttendees) {
        delete occurrence.attendeesAndPermissions;
        occurrence.attendees = null;
        occurrence.permissions = null;
    }

    if (
        !values.useMeetingAttendees &&
        (delta.attendeesAndPermissions || delta.useMeetingAttendees === false)
    ) {
        delete occurrence.attendeesAndPermissions;

        const { attendees, permissions, isPrivate } =
            mapAttendeesAndPermissionsSettings(attendeesAndPermissions);

        occurrence.attendees = attendees;
        occurrence.permissions = permissions;
        occurrence.isPrivate = isPrivate;
    }

    if (delta.recurrenceDetail) {
        delete occurrence.recurrenceDetail;

        if (delta.recurrenceDetail.startDate) {
            occurrence.startDate = startDate.format(DATE_FORMAT_WITH_OFFSET);
        }
        if (delta.recurrenceDetail.endDate) {
            occurrence.endDate = endDate.format(DATE_FORMAT_WITH_OFFSET);
        }
        if (delta.recurrenceDetail.timezone) {
            occurrence.timezone = timezone;
        }
    }

    if (delta.startDateActual) {
        occurrence.startDateActual =
            values.startDateActual.valueOf() === startDate.valueOf()
                ? null
                : values.startDateActual.format(DATE_FORMAT_WITH_OFFSET);
    }
    if (delta.endDateActual) {
        occurrence.endDateActual =
            values.endDateActual.valueOf() === endDate.valueOf()
                ? null
                : values.endDateActual.format(DATE_FORMAT_WITH_OFFSET);
    }

    if (_.has(occurrence, 'location') && _.isUndefined(occurrence.location)) {
        occurrence.location = '';
    }

    if (_.has(occurrence, 'locationOnline')) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line unicorn/prefer-ternary
        if (_.isUndefined(occurrence.locationOnline)) {
            occurrence.locationOnline = '';
        } else {
            occurrence.locationOnline = getOnlineMeetingUrl(
                occurrence.locationOnline
            );
        }
    }

    // GOTCHA... the `diff` function converts the array difference into an object
    if ('newUsers' in occurrence && !Array.isArray(occurrence.newUsers)) {
        occurrence.newUsers = Object.values(occurrence.newUsers);
    }

    occurrence.id = values.id;
    occurrence.meeting = meeting;
    return occurrence;
}
