import React from 'react';

const HIDE_ID = 'shape-hide';
const UNHIDE_ID = 'shape-unhide';

const HideIconInner = ({
    iconThick = false,
    hasSlash = true,
}: {
    iconThick?: boolean;
    hasSlash?: boolean;
}): JSX.Element => (
    <>
        <path
            d="m15.762 8c-7.2136 0-12.18 5.3332-13.762 8 1.582 2.6667 6.5492 8 13.762 8 8.068 0 12.339-5.0526 14.238-8-1.8983-2.9473-6.1695-8-14.238-8z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m20 16c0 2.2091-1.7909 4-4 4s-4-1.7909-4-4 1.7909-4 4-4 4 1.7909 4 4z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
            fill="currentColor"
        />
        {hasSlash && (
            <path
                d="m25.334 6-18.667 21"
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth={iconThick ? '2' : '1.5'}
                vectorEffect="non-scaling-stroke"
            />
        )}
    </>
);

const HideIcon = ({
    iconThick = false,
    hasSlash = true,
    isSymbolRef = false,
    title = '',
    ...rest
}): JSX.Element => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        {isSymbolRef ? (
            <use
                href={`#${hasSlash ? HIDE_ID : UNHIDE_ID}`}
                xlinkHref={`#${hasSlash ? HIDE_ID : UNHIDE_ID}`}
            />
        ) : (
            <HideIconInner {...{ iconThick, hasSlash }} />
        )}
    </svg>
);

export { HideIcon as default, HideIconInner, HIDE_ID, UNHIDE_ID };
