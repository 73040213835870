import _set from 'lodash/set.js';
import { post, patch, postForm, deleteRequest } from '~client/api/helpers';

import { userModel } from './user.mappers.js';

export function updateUserProfile(id, params) {
    const url = '/api/userprofile';
    return patch(url, params, {
        'x-request-user': id,
    }).then(userModel);
}

export function uploadUserAvatar(file) {
    const formData = new FormData();
    formData.append('file', file.blob, 'avatar.jpg');

    const url = '/api/userprofile/avatar';

    return postForm(url, formData).then((userModel) => {
        // Hack: cloudfront seems to have some sort of replication deplay
        // So we use a object URL for the blob we uploaded
        _set(userModel, 'avatar.path', window.URL.createObjectURL(file.blob));
        return userModel;
    });
}

export function removeUserAvatar() {
    const url = '/api/userprofile/avatar';
    return deleteRequest(url).then(userModel);
}

export function updateUserPreference(type, params) {
    const url = `/api/preferences/${type}`;
    return patch(url, params).then(userModel);
}

export const sendEmailDailyDigest = () => post(`/api/email/send/daily-digest`);
