import React, {
    useState,
    useLayoutEffect,
    createContext,
    useContext,
    useCallback,
} from 'react';
import { theme } from 'twin.macro';

/**
 * Screen provider and hook for Twin
 */
const defaultValue = {};

const ScreensContext = createContext(defaultValue);
const screens = theme`screens`;

const MinScreenProvider = ({ children }) => {
    const [queryMatch, setQueryMatch] = useState({});

    useLayoutEffect(() => {
        const mediaQueryLists = {};
        let isAttached = false;

        const mediaWidths = Object.entries(screens).map(([name, media]) => [
            name,
            `(min-width: ${media})`,
        ]);
        // 'md-h': Used on the dashboard sidebar for enabling the scrollable sidebar panels
        const mediaHeight = ['md-h', `(min-height: ${theme`screens.md`})`];
        const mediaData = [...mediaWidths, mediaHeight];

        const handleQueryListener = () => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line unicorn/prefer-object-from-entries
            const updatedMatches = mediaData.reduce(
                (acc, [name]) => ({
                    ...acc,
                    [name]: Boolean(
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                        mediaQueryLists[name] && mediaQueryLists[name].matches
                    ),
                }),
                {}
            );
            setQueryMatch(updatedMatches);
        };

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (window && window.matchMedia) {
            const matches = {};

            mediaData.forEach(([name, media]) => {
                if (typeof media !== 'string') {
                    matches[name] = false;
                    return;
                }
                mediaQueryLists[name] = window.matchMedia(media);
                matches[name] = mediaQueryLists[name].matches;
            });

            setQueryMatch(matches);
            isAttached = true;

            mediaData.forEach(([name, media]) => {
                if (typeof media !== 'string') return;
                mediaQueryLists[name].addListener(handleQueryListener);
            });
        }

        return () => {
            if (!isAttached) return;
            mediaData.forEach(([name, media]) => {
                if (typeof media !== 'string') return;
                mediaQueryLists[name].removeListener(handleQueryListener);
            });
        };
    }, []);

    return (
        <ScreensContext.Provider value={queryMatch}>
            {children}
        </ScreensContext.Provider>
    );
};

const useMinScreen = () => {
    const context = useContext(ScreensContext);
    if (context === defaultValue)
        throw new Error('useMinScreen must be used within a MinScreenProvider');

    const min = useCallback((size) => context[size], [context]);

    return { min };
};

export { MinScreenProvider, useMinScreen };
