import React from 'react';
import isFQDN from 'validator/lib/isFQDN';
import { getWorkspaceSlug } from '~modules/navigation/navigation.helpers';
import {
    getWorkspaceAccessLevel,
    getTemplateLibraryAccessLevel,
    getAccessLevelByPermission,
} from '~modules/permission/permission.helpers';
import { getUserDisplayName } from '~shared/user/user.helpers';
import { hasIntercom, showNewIntercomMessage } from '~modules/intercom';
import { Button } from '~modules/common/components';
import {
    PAYMENT_STATUS_ERRORTYPE_CARD_ERROR,
    PAYMENT_STATUS_ERROR,
    WORKSPACE_DEFAULT_MEETING_LIMIT,
    WORKSPACE_PLAN_CONFIG,
    GROUP_TYPE_WORKSPACE,
} from '~common/constants';
import {
    isOnBasicPlan,
    isOnPlusPlan,
} from '~shared/workspace/workspace.helpers';
import { getUserSelector } from '~modules/user/user.selectors';
import { getWorkspaceFromStoreById } from '~modules/group/group.selectors';

const normalizeWorkspaceMembers = (member) => ({
    ...member,
    hasNoDisplayName: !!member.displayName,
    displayName: getUserDisplayName(member),
    accessLevel: getAccessLevelByPermission(member.permission),
    groupOrder: { 4: 1, 3: 2 }[member?.permission?.grants?.length] || 3,
});

// Order members by grantLevel (Owner, Admin, Members & Guests)
// Grant Level is used in normalizeWorkspaceMembers to set the groupOrder
const orderByGrantLevel = (a, b) => {
    const aSortName = a.displayName.toLowerCase();
    const bSortName = b.displayName.toLowerCase();

    return (
        a.groupOrder - b.groupOrder ||
        // Sort alphabetically for each grantLevel
        (aSortName > bSortName ? 1 : bSortName > aSortName ? -1 : 0)
    );
};

const normalizeRecommendedWorkspace = (props) => {
    const { id, title, logo, memberCount, owners, domains } = props;
    const myAccessLevel = getWorkspaceAccessLevel(id);
    return {
        id,
        title,
        domains,
        myAccessLevel,
        memberCount,
        owners,
        type: GROUP_TYPE_WORKSPACE,
        logo,
        isRecommendedWorkspace: true,
    };
};

/**
 * Flatten the data structure for templates
 */
const normalizeWorkspace = (props) => {
    const {
        id,
        userId,
        title,
        workspaceProps,
        type,
        archived,
        createdAt,
        appPreferences,
    } = props;
    if (!workspaceProps) return {};
    const loggedInUser = getUserSelector();

    const {
        isTemplateLibrary,
        activeMeetingCount,
        domains,
        plan,
        owners,
        memberCount,
        limit,
        paymentMeta,
        logo,
    } = workspaceProps;
    const isOverBasicMeetingLimit =
        activeMeetingCount > (limit || WORKSPACE_DEFAULT_MEETING_LIMIT);
    const isAtOrOverBasicMeetingLimit = limit && activeMeetingCount >= limit;

    const hasPlusPlan = isOnPlusPlan({ plan });
    const hasBasicPlan = isOnBasicPlan({ plan });

    const myAccessLevel =
        getWorkspaceAccessLevel(id) || getTemplateLibraryAccessLevel(id);
    const isHidden = archived;
    const isPrimaryWorkspace =
        id === loggedInUser.appPreferences?.primaryWorkspaceId;

    return {
        id,
        title,
        userId,
        domains,
        plan: WORKSPACE_PLAN_CONFIG[plan] || '',
        isOnPlusPlan: hasPlusPlan,
        isOnBasicPlan: hasBasicPlan,
        slug: getWorkspaceSlug(id),
        myAccessLevel,
        memberCount,
        owners,
        type,
        limit,
        isOverBasicMeetingLimit,
        isAtOrOverBasicMeetingLimit,
        activeMeetingCount,
        paymentMeta,
        logo,
        isHidden,
        isPrimaryWorkspace,
        isTemplateLibrary,
        createdAt,
        appPreferences,
    };
};

/**
 * Workspace ordering
 */
const orderWorkspaces = (a, b) => {
    const aSortName = (a.title || '').toLowerCase();
    const bSortName = (b.title || '').toLowerCase();

    return aSortName > bSortName ? 1 : bSortName > aSortName ? -1 : 0;
};

const getWorkspacePaymentError = ({ workspace }) => {
    const status = workspace?.paymentMeta?.status;

    if (!status) return;
    const hasError = status.type === PAYMENT_STATUS_ERROR;
    if (!hasError) return;

    const improvedErrorMessage = {
        title: 'We were unable to process your credit card payment',
        description:
            status.errorType === PAYMENT_STATUS_ERRORTYPE_CARD_ERROR ? (
                status.message
            ) : (
                <>
                    Please make sure your card information is correct, or use
                    another card.
                    <br />
                    If you continue to get this message, please{' '}
                    {hasIntercom() ? (
                        <Button onClick={showNewIntercomMessage}>
                            contact our support team
                        </Button>
                    ) : (
                        'contact our support team'
                    )}
                    .
                </>
            ),
    };

    return { ...status, message: improvedErrorMessage };
};

const getWorkspaceAddedMessage = ({ addMeeting, remove, group }) => {
    const link = (
        <button
            type="button"
            onClick={() => (remove(), addMeeting({ workspaceId: group.id }))}
        >
            add a meeting
        </button>
    );
    return (
        <span>
            You can now {link} in the <strong>{group.title}</strong> workspace.
        </span>
    );
};

const hasMultipleOccurrences = (workspaceId, meetingId) => {
    const workspace = getWorkspaceFromStoreById(workspaceId);
    return workspace.meetings.some(
        (m) => m.meetingId === meetingId && !!m.hasMultipleOccurrences
    );
};

const getShouldAddDomain = (user) => {
    const domain = user.emailDomainMeta.domain?.toLowerCase();
    if (!domain) return false;

    if (user.emailDomainMeta.isPublicDomain) return false;

    const shouldAddDomain = isFQDN(domain);
    return shouldAddDomain;
};

const workspaceLimitToString = {
    [3]: {
        string: 'three',
    },
    [4]: {
        string: 'four',
    },
    [5]: {
        string: 'five',
    },
    [10]: {
        string: 'ten',
    },
};

const getWorkspaceLimitToString = (limit) => {
    const handler = workspaceLimitToString[limit] || '';
    // If workspaceLimit is an integer we haven't catered for, just return the integer
    if (!handler) return limit;

    return handler.string;
};

export {
    normalizeWorkspaceMembers,
    orderByGrantLevel,
    normalizeWorkspace,
    normalizeRecommendedWorkspace,
    orderWorkspaces,
    getWorkspacePaymentError,
    isOnPlusPlan,
    isOnBasicPlan,
    getWorkspaceAddedMessage,
    hasMultipleOccurrences,
    getShouldAddDomain,
    getWorkspaceLimitToString,
};
