import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Popper from '@mui/material/Popper';
import Dialog from '@mui/material/Dialog';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Icon from '~icons';
import { zIndex } from '~common/style.helpers';
import { customScrollbar } from '~common/ui';
import Button from './Button';

const Container = styled.div([
    tw`w-full flex-col items-center space-y-1.5 `,
    css``,
]);
const ActionBarWrap = tw.div`flex items-center justify-end space-x-2 col-start-2 row-start-3 p-2`;

// MUI style overrides to TimePicker
const StyledTimePicker = styled(TimePicker)([
    tw`w-full p-0`,
    css`
        .MuiInputBase-root {
            font-family: 'Lato', sans-serif;
            ${tw`w-full border-line !text-body hocus:outline-none text-sm`}
            &:hover,
            &:focus {
                .MuiOutlinedInput-root,
                .MuiOutlinedInput-notchedOutline,
                .Mui-focused {
                    ${tw`border-line-dark outline-none`}
                }
            }
            input {
                &::placeholder {
                    ${tw`text-body-light`}
                }
            }
            &.Mui-disabled {
                ${tw`cursor-not-allowed`}
                input {
                    ${tw`cursor-not-allowed`}
                }
            }
            // Input error state
            &.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
                ${tw`border-error-icon-dark`}
            }
            &.MuiOutlinedInput-root.Mui-error.Mui-focused
                .MuiOutlinedInput-notchedOutline {
                ${tw`border-error-icon-dark`}
            }
            // Focus state
            &.MuiOutlinedInput-root.Mui-focused
                .MuiOutlinedInput-notchedOutline {
                ${tw`border-line-dark`}
            }
        }
        .MuiOutlinedInput-root,
        .MuiOutlinedInput-notchedOutline {
            ${tw`border-line`}
            &::hover {
                ${tw`border-line-dark`}
            }
        }

        .MuiInputBase-input {
            ${tw`!py-0`}
        }
        .MuiIconButton-root {
            ${tw`text-icon p-2 -ml-2 hocus:(text-icon-dark bg-transparent)`}
        }
    `,
]);
// MUI style overrides to popup time selection
const StyledPopper = styled(Popper)([
    tw``,
    css`
        font-family: 'Lato', sans-serif;
        z-index: ${zIndex('TOP_LEVEL_POPUPS')};
        .Mui-selected,
        .Mui-focusVisible,
        .MuiMultiSectionDigitalClockSection-item.Mui-selected {
            ${tw`bg-primary hocus:bg-solid-dark`}
        }
        .MuiButton-root {
            ${tw`bg-primary text-white w-full hocus:(bg-primary text-white shadow)`}
        }
        .MuiMultiSectionDigitalClockSection-root {
            ${customScrollbar()}
        }
    `,
]);
const StyledDialog = styled(Dialog)([
    tw``,
    css`
        font-family: 'Lato', sans-serif;
        z-index: ${zIndex('TOP_LEVEL_POPUPS')};
        .MuiClock-root {
            .MuiClock-clock {
                .MuiClock-pin,
                .MuiClockPointer-root {
                    ${tw`bg-primary`}
                }
                .MuiClockPointer-thumb {
                    ${tw`border-primary`}
                }
            }
        }
        .MuiPickersLayout-actionBar {
            button {
                ${tw`text-primary`}
            }
        }
    `,
]);

const TimeSelect = ({
    input,
    label,
    minTime,
    maxTime,
    hasSetToNow,
    disabled,
    fieldDefaultValue,
    ...rest
}) => {
    // Set defaultValue as fields initialValue (actualDate) || fieldDefault (scheduledDate)
    const defaultValue = input.value || fieldDefaultValue;

    const CustomActionBar = ({ onAccept, onSetToday }) => {
        return (
            <ActionBarWrap>
                {hasSetToNow && (
                    <Button isSmall isSecondary onClick={onSetToday} tw="!px-2">
                        Set to now
                    </Button>
                )}
                <Button isSmall isPrimary onClick={onAccept} tw="!px-5">
                    OK
                </Button>
            </ActionBarWrap>
        );
    };
    return (
        <Container>
            {label && <span tw="select-none">{label}</span>}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StyledTimePicker
                    name={input.name}
                    onChange={(newValue) => {
                        input.onChange(
                            newValue
                                ? newValue.startOf('minute')
                                : fieldDefaultValue.clone()
                        );
                    }}
                    slots={{
                        // Allow component overrides
                        popper: StyledPopper,
                        dialog: StyledDialog,
                        openPickerIcon: () => (
                            <Icon name="dueDate" tw="text-lg" />
                        ),
                        actionBar: ({ onAccept, onSetToday }) => (
                            <CustomActionBar {...{ onAccept, onSetToday }} />
                        ),
                    }}
                    slotProps={{
                        field: {
                            clearable:
                                input.value.valueOf() !==
                                fieldDefaultValue.valueOf(),
                        },
                    }}
                    value={defaultValue}
                    desktopModeMediaQuery="@media (min-width: 768px)"
                    {...{
                        minTime,
                        maxTime,
                        disabled,
                    }}
                    {...rest}
                />
            </LocalizationProvider>
        </Container>
    );
};

export default TimeSelect;
