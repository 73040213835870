import { getCustomerList } from '~modules/remoteCRM/remoteCRM.api';
import { getCRMConfig } from '~shared/integration/integration.mapper.remoteCRM';
import {
    CRM_CUSTOMER_DATA_LOADING,
    CRM_CUSTOMER_DATA_LOADED,
    INTEGRATION_CRM_LINKS_LOADED,
    MODAL_LINK_TOPIC_TO_CRM_OPEN,
    MODAL_LINK_TOPIC_TO_CRM_CLOSE,
} from '~common/action.types';
import { displayError } from '~modules/alert/alert.helpers';
import { getCRMIntegrationLinks } from '~modules/integration/integration.api';
import { onMinuteItemSave } from '~modules/minutes/minutes.actions';

export const loadCRMInfo =
    ({ authSource, workspaceId }) =>
    async (dispatch) => {
        if (![authSource, workspaceId].every(Boolean)) return;

        dispatch({
            type: CRM_CUSTOMER_DATA_LOADING,
            authSource,
            workspaceId,
        });

        try {
            const customers = await getCustomerList({
                authSource,
                workspaceId,
            });
            dispatch({
                type: CRM_CUSTOMER_DATA_LOADED,
                authSource,
                workspaceId,
                customers,
            });
        } catch (error) {
            dispatch({
                type: CRM_CUSTOMER_DATA_LOADED,
                authSource,
                workspaceId,
                customers: [],
            });
            return displayError({
                title: `Data could not be loaded from ${
                    getCRMConfig({
                        authSource,
                    }).longName
                }`,
                error,
            });
        }
    };

export const loadCRMLinks = (workspaceId) => async (dispatch) => {
    try {
        const links = await getCRMIntegrationLinks();
        dispatch({ type: INTEGRATION_CRM_LINKS_LOADED, links });

        const linksToLoad = workspaceId
            ? links.filter((link) => link.workspaceId === workspaceId)
            : links;
        if (linksToLoad.length === 0) return;

        links.forEach((link) => dispatch(loadCRMInfo(link)));
    } catch {
        return displayError({
            title: 'CRM links could not be loaded',
            description: 'Please reload your browser.',
        });
    }
};

export function openLinkTopicToCRMModal(
    topic,
    crmConfig,
    instructionMessageText
) {
    return {
        type: MODAL_LINK_TOPIC_TO_CRM_OPEN,
        header: `Link topic to ${crmConfig.shortName} ${crmConfig.remoteDataAlias}`,
        topic,
        instructionMessageText,
    };
}

export function closeLinkTopicToCRMModal() {
    return {
        type: MODAL_LINK_TOPIC_TO_CRM_CLOSE,
    };
}

export const onLinkTopicToCRM =
    (topic, { crmLink }) =>
    (dispatch) => {
        const { authSource, link, remoteId, saveContentToCloud } = crmLink;

        dispatch(
            onMinuteItemSave(topic.occurrence, {
                id: topic.id,
                crmLink: {
                    authSource,
                    link,
                    remoteId,
                    saveContentToCloud,
                },
            })
        );
    };
