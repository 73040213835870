import React from 'react';
import tw from 'twin.macro';
import NamedIcon from '~icons';
import { LinkExternal, Tooltip } from '~modules/common/components';
import { getOnlineMeetingConfig } from '~shared/meeting/meeting.helpers';

const Icon = tw(NamedIcon)`text-lg ml-2`;
const Label = tw.div`(hidden sm:block) ml-1 (text-body [.group-joinbtn:hover &]:text-body-dark)`;

const Link = ({ locationOnline }) => (
    <LinkExternal
        tw="(inline-flex items-center) (text-icon hover:text-icon-dark)"
        href={locationOnline}
        className="group-joinbtn"
        onClick={(evt) => {
            evt.stopPropagation();
        }}
    >
        <Icon name={getOnlineMeetingConfig(locationOnline).iconName} />
        <Label>Join</Label>
    </LinkExternal>
);

const JoinOnlineButton = ({
    startDate,
    endDate,
    locationOnline,
    isObjectWrapped,
}) => {
    const now = new Date();
    const showJoinMeetingOnline =
        Boolean(locationOnline) &&
        now > startDate.clone().subtract(30, 'minutes') &&
        now <= endDate;
    if (!showJoinMeetingOnline) return null;

    const tooltipContent = `Join ${
        getOnlineMeetingConfig(locationOnline).isDefault
            ? getOnlineMeetingConfig(locationOnline).displayName.toLowerCase()
            : getOnlineMeetingConfig(locationOnline).displayName
    } meeting`;

    return (
        <Tooltip content={tooltipContent} position="bottom right">
            {isObjectWrapped ? (
                <object>
                    <Link {...{ locationOnline }} />
                </object>
            ) : (
                <div>
                    <Link {...{ locationOnline }} />
                </div>
            )}
        </Tooltip>
    );
};

export default JoinOnlineButton;
