import React from 'react';

export default ({ ...rest }) => (
    <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="m17.64 9.2044c0-0.63818-0.0573-1.2518-0.1636-1.8409h-8.4764v3.4814h4.8436c-0.2086 1.125-0.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.8741 2.6836-6.615z"
            clipRule="evenodd"
            fill="#4285F4"
            fillRule="evenodd"
        />
        <path
            d="m9 18c2.43 0 4.4673-0.8059 5.9564-2.1805l-2.9087-2.2582c-0.8059 0.54-1.8368 0.8591-3.0477 0.8591-2.3441 0-4.3282-1.5832-5.0359-3.7104h-3.0068v2.3318c1.4809 2.9413 4.5245 4.9582 8.0427 4.9582z"
            clipRule="evenodd"
            fill="#34A853"
            fillRule="evenodd"
        />
        <path
            d="m3.9641 10.71c-0.18-0.54-0.28227-1.1168-0.28227-1.71s0.10227-1.17 0.28227-1.71v-2.3318h-3.0068c-0.60955 1.215-0.95727 2.5896-0.95727 4.0418 0 1.4522 0.34773 2.8268 0.95727 4.0418l3.0068-2.3318z"
            clipRule="evenodd"
            fill="#FBBC05"
            fillRule="evenodd"
        />
        <path
            d="m9 3.5796c1.3214 0 2.5077 0.45409 3.4405 1.3459l2.5813-2.5814c-1.5586-1.4523-3.5959-2.3441-6.0218-2.3441-3.5182 0-6.5618 2.0168-8.0427 4.9582l3.0068 2.3318c0.70773-2.1273 2.6918-3.7104 5.0359-3.7104z"
            clipRule="evenodd"
            fill="#EA4335"
            fillRule="evenodd"
        />
    </svg>
);
