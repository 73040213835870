import _uniqBy from 'lodash/uniqBy.js';
import {
    FETCH_TEMPLATES_SUCCESS,
    ADD_TEMPLATE_WEBSOCKET,
    ADD_TEMPLATE_REQUEST,
    ADD_TEMPLATE_ERROR,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_WEBSOCKET,
    DELETE_TEMPLATE_WEBSOCKET,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_ERROR,
    WORKSPACE_ACCESS_REMOVED,
    WORKSPACE_TEMPLATE_ACCESS_REMOVED,
    LOGOUT_SUCCESS,
} from '../../common/action.types.js';

const initalState = {
    templates: [],
};

const templatesReducer = (state = initalState, action) => {
    switch (action.type) {
        case ADD_TEMPLATE_REQUEST:
        case ADD_TEMPLATE_WEBSOCKET:
        case FETCH_TEMPLATES_SUCCESS:
        case UPDATE_TEMPLATE_SUCCESS:
        case UPDATE_TEMPLATE_WEBSOCKET:
        case DELETE_TEMPLATE_ERROR: {
            if (!action.templates?.length && !action.template) return state;

            // remove the agendaItems array from any incoming data
            const actionTemplates = [
                action.template,
                ...(action.templates || []),
            ]
                .filter(Boolean)
                .map((template) => ({
                    ...template,
                    agendaItems: undefined,
                }));

            return {
                ...state,
                templates: _uniqBy(
                    [...actionTemplates, ...state.templates].filter(Boolean),
                    'id'
                ),
            };
        }
        case ADD_TEMPLATE_ERROR:
        case DELETE_TEMPLATE_REQUEST:
        case DELETE_TEMPLATE_WEBSOCKET: {
            const deletedTemplates = [
                action.template?.id,
                ...(action.templates || []).map(({ id }) => id),
            ].filter(Boolean);

            if (!deletedTemplates.length) return state;

            return {
                ...state,
                templates: state.templates.filter(
                    ({ id }) => !deletedTemplates.includes(id)
                ),
            };
        }

        case WORKSPACE_TEMPLATE_ACCESS_REMOVED:
        case WORKSPACE_ACCESS_REMOVED: {
            const templateIdsToRemove = state.templates
                .filter(
                    (template) => template.workspaceId === action.workspace.id
                )
                .map((template) => template.id);

            if (!templateIdsToRemove.length) return state;

            return {
                ...state,
                templates: state.templates.filter(
                    ({ id }) => !templateIdsToRemove.includes(id)
                ),
            };
        }

        case LOGOUT_SUCCESS:
            return initalState;

        default:
            return state;
    }
};

export default templatesReducer;
