import React from 'react';
import _uniqBy from 'lodash/uniqBy.js';
import ObjectID from 'bson-objectid';
import isEmail from 'validator/lib/isEmail.js';
import { replace } from 'connected-react-router';
import { pauseUpdates, resumeUpdates } from '~common/store';
import 'twin.macro';
import {
    WORKSPACE_INVOICES_LOADED,
    WORKSPACE_MEMBERS_LOADED,
    WORKSPACE_MEMBER_UPDATED,
    WORKSPACE_MEMBER_DELETED,
    WORKSPACE_MEMBER_ADDED,
    WORKSPACE_EDIT,
    PERMISSION_DELETE,
    PERMISSION_CREATE,
    ADD_GROUP_REQUEST,
    ADD_GROUP_ERROR,
    WORKSPACE_MEMBER_LOAD_FAILED,
    WORKSPACE_INVOICES_LOAD_FAILED,
    DELETE_GROUP_REQUEST,
    DELETE_GROUP_ERROR,
    UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
    WORKSPACE_ACCESS_REMOVED,
} from '~common/action.types';
import {
    getMembersForWorkspace,
    getMemberMeetingsAndPermissions,
    updateWorkspacePermission,
    deleteWorkspacePermission,
    addWorkspacePermission,
    sendWorkspaceAddMeetingAttemptEmail as sendWorkspaceAddMeetingAttemptEmailApi,
    addDomainToWorkspace,
    removeDomainFromWorkspace,
    addWorkspace,
    leaveWorkspace as apiLeaveWorkspace,
    cancelWorkspaceRequest as apiCancelWorkspaceRequest,
    hideWorkspace as apiHideWorkspace,
    renameWorkspace as apiRenameWorkspace,
    deleteWorkspace as apiDeleteWorkspace,
    startWorkspaceFeature as apiStartWorkspaceFeature,
    moveMeetingToAnotherWorkspace as moveMeetingToAnotherWorkspaceApi,
    deleteWorkspacePermissionAndReassignMeetings,
    upgradeWorkspace as upgradeWorkspaceApi,
    downgradeWorkspace as downgradeWorkspaceApi,
    updatePaymentWorkspace as updatePaymentWorkspaceApi,
    getInvoicesForWorkspace,
    getSecretForWorkspace as getSecretForWorkspaceApi,
    getCouponDetails as getCouponDetailsApi,
    uploadWorkspaceLogo as uploadWorkspaceLogoApi,
    removeWorkspaceLogo as removeWorkspaceLogoApi,
    updateMemberDisplayName as updateMemberDisplayNameApi,
    addWorkspacePermissionRequest,
} from '~modules/workspace/workspace.api';
import {
    getGroupFromStoreById,
    getAccessibleMeetingsIdsForWorkspace,
    getGroupByMeetingId,
    getWorkspaceGroupByMeetingId,
    getWorkspaceFromStoreById,
} from '~modules/group/group.selectors';
import {
    editGroupRequest,
    editGroupError,
    moveMeetingToAnotherGroupRequest,
    moveMeetingToAnotherGroupError,
    addGroupRequest,
    addGroupError,
} from '~modules/group/group.actions';
import {
    alertSuccess,
    alertError,
    alertErrorWithDispatch,
    removeAlert,
    alertPersistedErrorWithClose,
} from '~modules/alert/alert.actions';
import {
    GROUP_TYPE_WORKSPACE,
    GRANT_LEVEL_OWNER,
    GRANT_LEVEL_MEMBER,
    GRANT_LEVEL_GUEST,
    PERMISSION_STATUS_ACCEPTED,
    PERMISSION_STATUS_PENDING,
    PERMISSION_TYPE_WORKSPACE,
    MODAL_TYPE_VIEW_CANCEL,
    MODAL_TYPE_MOVE_CANCEL,
    MODAL_TYPE_LEAVE_CANCEL,
    MODAL_TYPE_DELETE_CANCEL,
    MODAL_TYPE_OK_NO_ACTION,
    PAYMENT_STATUS_ERROR,
} from '~common/constants';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import { openMoveMeetingModal } from '~modules/modals/moveMeetingModal.actions';
import { getMeetingSelector } from '~modules/meeting/meeting.selectors';
import { getUserSelector, getUser } from '~modules/user/user.selectors';
import { normalizeWorkspace } from '~modules/workspace/workspace.helpers';
import { isUserEmailTrustedByWorkspaceDomains } from '~shared/workspace/workspace.helpers';
import { grantLevelToGrants } from '~shared/permissions/permission.helper';
import { getUserDisplayName } from '~shared/user/user.helpers';
import {
    WORKSPACE_PLAN_CURRENCY_CONFIG,
    COUNTRY_CODE_US,
} from '~shared/constants';
import { pluralize } from '~common/utils';
import { getWorkspaceUpdateSlug } from '~modules/navigation/navigation.helpers';
import { replaceUrlToWorkspacesList } from '~modules/navigation/navigation.actions';
import {
    getDashboardSlug,
    getWorkspaceSlug,
    replaceTo,
    pushTo,
} from '~modules/navigation';
import HelpLink from '~modules/help/HelpLink';
import { hasIntercom, showNewIntercomMessage } from '~modules/intercom';
import {
    formSubmissionServerError,
    processBodyErrors,
} from '~components/formvalidation/formvalidation.helper';
import { clearMyHomepageIfContainsId } from '~modules/pageView/pageView.actions';
import airbrake from '~common/airbrake';

function updateWorkspaceProfileSuccess(group) {
    return {
        type: WORKSPACE_EDIT,
        group,
    };
}

export const loadWorkspacePermissions = (workspaceId) => async (dispatch) => {
    try {
        const members = await getMembersForWorkspace(workspaceId);

        dispatch({
            type: WORKSPACE_MEMBERS_LOADED,
            workspaceId,
            members,
        });
    } catch (error) {
        dispatch({
            type: WORKSPACE_MEMBER_LOAD_FAILED,
            workspaceId,
        });

        // May be if the authorisation fails, server is unavailable, version expired
        // errors are handled in the get function
        dispatch(
            alertError({
                id: 'loadWorkspacePermissions',
                title: 'Workspace permissions could not be loaded',
                error,
            })
        );
    }
};

export const loadWorkspaceInvoices = (workspaceId) => async (dispatch) => {
    try {
        const invoices = await getInvoicesForWorkspace(workspaceId);
        dispatch({
            type: WORKSPACE_INVOICES_LOADED,
            workspaceId,
            invoices,
        });
    } catch {
        dispatch(
            alertPersistedErrorWithClose({
                id: 'workspace-invoice-load-failed',
                title: 'Invoices could not be loaded',
                message: 'Please reload and try again.',
            }),
            {
                type: WORKSPACE_INVOICES_LOAD_FAILED,
                workspaceId,
                error: 'Invoices could not be loaded',
            }
        );
    }
};

export const loadMemberMeetingsAndPermissions = async (workspaceId, userId) => {
    try {
        const meetingsAndPermissions = await getMemberMeetingsAndPermissions(
            workspaceId,
            userId
        );
        return meetingsAndPermissions;
    } catch (error) {
        alertErrorWithDispatch({
            id: 'loadMemberMeetingsAndPermissions',
            title: 'Member meetings and permissions could not be loaded',
            error,
        });
    }
};

export const updateWorkspaceAccess =
    (workspaceId, member, grants) => async (dispatch) => {
        try {
            if (!member?.permission) {
                return;
            }

            dispatch({
                type: WORKSPACE_MEMBER_UPDATED,
                workspaceId,
                member: {
                    ...member,
                    permission: {
                        ...member.permission,
                        grants,
                    },
                },
            });

            await updateWorkspacePermission(workspaceId, member.permission.id, {
                grants,
            });

            dispatch(
                alertSuccess({
                    id: `updateWorkspaceAccess-${workspaceId}-${member.id}`,
                    title: `Access level for ${member.displayName} has been updated`,
                })
            );
        } catch (error) {
            dispatch({
                type: WORKSPACE_MEMBER_UPDATED,
                workspaceId,
                member,
            });

            dispatch(
                alertError({
                    id: 'updateWorkspaceAccess',
                    title: 'Workspace permission could not be updated',
                    error,
                })
            );
        }
    };

/**
 * Deleting a user from the workspace.
 * Update meeting permissions to assign a new meeting admin,
 * before deleting the user from the workspace and removing all their access
 * This can be called without replacementData.
 */
export const replaceThenDeleteWorkspaceAccess =
    (workspace, member, replacementData = {}) =>
    async (dispatch) => {
        try {
            if (!member?.permission?.id) {
                throw new Error('Workspace permission cannot be found');
            }

            const assignments = [
                ...(replacementData.singleMeetings || []),
                ...(replacementData.recurringMeetings || []),
            ];
            await deleteWorkspacePermissionAndReassignMeetings(
                workspace.id,
                member.permission.id,
                { assignments }
            );

            // user successfully reassigned and permission from workspace removed.
            // redirect to workspace detail page.
            replaceTo(getWorkspaceSlug(workspace));

            dispatch(
                alertSuccess({
                    id: `replaceThenDeleteWorkspaceAccess-${workspace.id}-${member.id}`,
                    title: `${member.displayName} was removed from ${workspace.title}`,
                })
            );
        } catch (error) {
            dispatch(
                alertError({
                    id: 'replaceThenDeleteWorkspaceAccess',
                    title: 'Workspace permission could not be deleted',
                    error,
                })
            );
        }
    };

export const deleteWorkspaceAccess =
    (workspace, member) => async (dispatch) => {
        try {
            if (!member?.permission) {
                return;
            }

            dispatch({
                type: WORKSPACE_MEMBER_DELETED,
                workspaceId: workspace.id,
                member,
            });

            await deleteWorkspacePermission(workspace.id, member.permission.id);

            dispatch(
                alertSuccess({
                    id: `deleteWorkspaceAccess-${workspace.id}-${member.id}`,
                    title: `${member.displayName} was removed from ${workspace.title}`,
                })
            );
        } catch (error) {
            dispatch({
                type: WORKSPACE_MEMBER_ADDED,
                workspaceId: workspace.id,
                member,
            });

            dispatch(
                alertError({
                    id: 'deleteWorkspaceAccess',
                    title: 'Workspace permission could not be deleted',
                    error,
                })
            );
        }
    };

export const addUserToWorkspace = (user, workspace) => async (dispatch) => {
    try {
        const isTrusted = isUserEmailTrustedByWorkspaceDomains(
            user.email,
            workspace
        );
        const permission = {
            user: user.id,
            grants: grantLevelToGrants(
                isTrusted ? GRANT_LEVEL_MEMBER : GRANT_LEVEL_GUEST
            ),
            status: isTrusted
                ? PERMISSION_STATUS_ACCEPTED
                : PERMISSION_STATUS_PENDING,
        };

        const member = {
            ...user,
            displayName: getUserDisplayName(user),
            permission,
        };

        // optimistic update
        dispatch({
            type: WORKSPACE_MEMBER_ADDED,
            workspaceId: workspace.id,
            member,
        });

        await addWorkspacePermission(workspace.id, {
            newUser: isEmail(user.id) ? user : undefined,
            permission,
        });

        dispatch(
            alertSuccess({
                id: `addUserToWorkspace-${user.id}`,
                title: `${member.displayName} was added to ${workspace.title}`,
            })
        );
    } catch (error) {
        // reverse the optimistic update
        dispatch({
            type: WORKSPACE_MEMBER_DELETED,
            workspaceId: workspace.id,
            member: {
                ...user,
            },
        });
        dispatch(
            alertError({
                id: 'addUserToWorkspace',
                title: 'User could not be added to workspace',
                error,
            })
        );
    }
};

export const sendWorkspaceAddMeetingAttemptEmail =
    (workspaceId) => async (dispatch, getState) => {
        try {
            await sendWorkspaceAddMeetingAttemptEmailApi(workspaceId);
        } catch (error) {
            const user = getState().user;
            airbrake.notify({
                params: { errorType: 'sendLimitHitEmail' },
                error,
                session: user,
            });
        }
    };

export const addEmailDomainToWorkspace =
    (workspace, domain) => async (dispatch, getState) => {
        // GOTCHA the workspace from the component has been massaged in a selector, so it's not a direct copy of the group from the redux store.
        const group = getGroupFromStoreById(getState(), workspace.id);
        try {
            // optimistically add to workspace
            dispatch({
                type: WORKSPACE_EDIT,
                group: {
                    ...group,
                    workspaceProps: {
                        ...group.workspaceProps,
                        domains: _uniqBy(
                            [
                                ...(group.workspaceProps?.domains || []),
                                { domain, isVerified: false },
                            ],
                            'domain'
                        ),
                    },
                },
            });

            // dispatch group update via api
            await addDomainToWorkspace(workspace.id, { domain });
        } catch (error) {
            dispatch({
                type: WORKSPACE_EDIT,
                group,
            });

            error.name !== 'ValidationError' &&
                dispatch(
                    alertError({
                        id: 'addEmailDomainToWorkspace',
                        title: 'Email domain could not be added to workspace',
                        error,
                    })
                );

            // throw the error, so the component can catch it and decide not to clear out the input
            throw error;
        }
    };

export const deleteEmailDomainFromWorkspace =
    (workspace, domain) => async (dispatch, getState) => {
        // GOTCHA the workspace from the component has been massaged in a selector, so it's not a direct copy of the group from the redux store.
        const group = getGroupFromStoreById(getState(), workspace.id);
        try {
            // optimistically remove from workspace
            dispatch({
                type: WORKSPACE_EDIT,
                group: {
                    ...group,
                    workspaceProps: {
                        ...group.workspaceProps,
                        domains: (group.workspaceProps?.domains || []).filter(
                            (workspaceDomain) =>
                                workspaceDomain.domain !== domain
                        ),
                    },
                },
            });

            // dispatch group update via api
            await removeDomainFromWorkspace(workspace.id, { domain });

            dispatch(
                alertSuccess({
                    id: `deleteEmailDomainFromWorkspace-${workspace.id}`,
                    title: `Email domain ${domain} was removed from ${workspace.title}`,
                })
            );
        } catch (error) {
            dispatch({
                type: WORKSPACE_EDIT,
                group,
            });

            dispatch(
                alertError({
                    id: 'deleteEmailDomainFromWorkspace',
                    title: 'Email domain could not be removed from the workspace',
                    error,
                })
            );
        }
    };

export const createWorkspace = (title) => async (dispatch, getState) => {
    const state = getState();
    const user = getUserSelector(state);
    const currency =
        WORKSPACE_PLAN_CURRENCY_CONFIG[user.location?.country]?.value ||
        WORKSPACE_PLAN_CURRENCY_CONFIG[COUNTRY_CODE_US].value;

    const id = String(new ObjectID());
    const workspace = {
        id,
        title,
        type: GROUP_TYPE_WORKSPACE,
        workspaceProps: {
            domains: [],
            owners: [],
            paymentMeta: {
                currency,
            },
        },
    };
    const permission = {
        id,
        user: state.user.id,
        targetType: PERMISSION_TYPE_WORKSPACE,
        target: id,
        status: PERMISSION_STATUS_ACCEPTED,
        grants: grantLevelToGrants(GRANT_LEVEL_OWNER),
    };
    // optimistic update
    dispatch({
        type: PERMISSION_CREATE,
        permission,
    });
    dispatch({
        type: ADD_GROUP_REQUEST,
        group: workspace,
    });

    try {
        const result = await addWorkspace(workspace);
        return result;
    } catch (error) {
        // reverse the optimistic update
        dispatch({
            type: PERMISSION_DELETE,
            permission,
        });
        dispatch({
            type: ADD_GROUP_ERROR,
            group: workspace,
        });
        dispatch(
            alertError({
                id: 'createWorkspace',
                title: 'Workspace could not be created',
                error,
            })
        );

        // throw error back to the component so it can catch and apply component updates accordingly
        throw error;
    }
};

export const renameWorkspace = (id, title) => async (dispatch, getState) => {
    const prevWorkspace = getGroupFromStoreById(getState(), id);
    try {
        dispatch(editGroupRequest({ id, title }));
        await apiRenameWorkspace(id, { title });
    } catch (error) {
        dispatch(editGroupError(prevWorkspace, error));
        dispatch(
            alertError({
                id: `renameWorkspaceTitle-${id}`,
                title: `Workspace title could not be updated`,
                error,
            })
        );
    }
};

export const startWorkspaceFeature =
    (workspaceId, featureName) => async (dispatch) => {
        try {
            const updatedWorkspace = await apiStartWorkspaceFeature(
                workspaceId,
                featureName
            );
            dispatch(updateWorkspaceProfileSuccess(updatedWorkspace));
        } catch (error) {
            dispatch(
                alertError({
                    id: `startWorkspaceFeature-${featureName}`,
                    title: 'Feature could not be started',
                    error,
                })
            );
        }
    };

const deleteWorkspaceBackground = (workspace) => async (dispatch) => {
    try {
        await apiDeleteWorkspace(workspace.id);
        dispatch(
            alertSuccess({
                id: `workspace-deleted-${workspace.id}`,
                title: `The workspace "${workspace.title}" has been deleted`,
            })
        );
    } catch (error) {
        dispatch({
            type: DELETE_GROUP_ERROR,
            group: workspace,
            error,
        });
        dispatch(
            alertError({
                id: `onDeleteWorkspace-${workspace.id}`,
                title: 'Workspace could not be deleted',
                error,
            })
        );
    }
};

const handleDeleteWorkspace = ({ options, dispatch, workspace }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    const deletePromise = async () => {
        dispatch({ type: DELETE_GROUP_REQUEST, group: workspace });
        dispatch(deleteWorkspaceBackground(workspace));
        dispatch(replaceUrlToWorkspacesList());
    };

    if (!options.confirm) return deletePromise();

    dispatch(
        openConfirm({
            header: 'Delete workspace',
            content: (
                <>
                    Delete the workspace &quot;
                    <strong>{workspace.title}</strong>&quot;?
                </>
            ),
            modalType: MODAL_TYPE_DELETE_CANCEL,
            promise: deletePromise,
        })
    );
};

const downgradeMessage = (isOverBasicMeetingLimit) => {
    const content = isOverBasicMeetingLimit ? (
        <>
            Before you can delete this paid workspace, please{' '}
            {hasIntercom() ? (
                <button
                    type="button"
                    onClick={showNewIntercomMessage}
                    tw="text-link"
                >
                    contact us
                </button>
            ) : (
                'contact us'
            )}{' '}
            to arrange a downgrade.
        </>
    ) : (
        <>Please cancel the Plus plan before deleting this workspace.</>
    );
    return openConfirm({
        header: 'Cannot delete workspace',
        content,
        modalType: MODAL_TYPE_OK_NO_ACTION,
    });
};

const hasPaymentErrorMessage = (title, workspaceId) => {
    const content = `Before you can delete this workspace, please update your payment details to finalise payment for “${title}”.`;
    const customActions = [
        ({ modalClose }) => ({
            label: 'Update payment details',
            props: {
                isPrimary: true,
                onClick: () => (
                    pushTo(getWorkspaceUpdateSlug(workspaceId)), modalClose()
                ),
            },
        }),
    ];
    return openConfirm({
        header: 'Cannot delete workspace',
        content,
        customActions,
    });
};

const moveMeetingsMessage =
    ({ workspace }) =>
    (dispatch, getState) => {
        const meetingCount = workspace?.meetings?.length;

        const pluralizedMeetings = pluralize(
            'is a series',
            `are ${meetingCount} series`,
            meetingCount
        );
        const pluralizedMeetingMultiple = pluralize(
            'this series',
            'these series',
            meetingCount
        );

        let promise, modalType;

        if (meetingCount > 1) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line require-await
            promise = async () => {
                pushTo(
                    getDashboardSlug(workspace, {
                        dashboardType: 'series',
                    })
                );
            };
            modalType = MODAL_TYPE_VIEW_CANCEL;
        } else {
            const { meetingId } = workspace.meetings[0];
            const meeting = getMeetingSelector(getState(), {
                meeting: meetingId,
            });
            const group = getGroupByMeetingId(getState(), meeting);
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line require-await
            promise = async () =>
                dispatch(openMoveMeetingModal({ workspace, meeting, group }));
            modalType = MODAL_TYPE_MOVE_CANCEL;
        }

        dispatch(
            openConfirm({
                header: 'Can’t delete workspace',
                helpLink: 'cant-delete-workspace',
                content: (
                    <>
                        <div>
                            There {pluralizedMeetings} within &ldquo;
                            {workspace.title}&rdquo;.
                            <br />
                            Move {pluralizedMeetingMultiple} to another
                            workspace and try again.
                        </div>
                        {meetingCount > 1 && (
                            <>
                                <br />
                                Learn{' '}
                                <HelpLink
                                    modal="move-series"
                                    linkText="how to move a series to another workspace"
                                    hasHover
                                />
                                .
                            </>
                        )}
                    </>
                ),
                promise,
                modalType,
            })
        );
    };
export const deleteWorkspace =
    (workspaceId, workspaceOptions) =>
    async (
        dispatch,
        getState
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line require-await
    ) => {
        const options = { confirm: true, ...workspaceOptions };
        const workspace = getGroupFromStoreById(getState(), workspaceId);

        if (!workspace) {
            return dispatch(
                alertError({
                    id: `onDeleteWorkspace-${workspace.id}`,
                    title: 'Workspace was not found',
                    error: 'Workspace was not found',
                })
            );
        }

        const workspaceData = normalizeWorkspace(workspace);
        const { isOnPlusPlan, isOverBasicMeetingLimit, title } = workspaceData;
        const hasPaymentError =
            workspaceData?.paymentMeta?.status?.type === PAYMENT_STATUS_ERROR;

        if (hasPaymentError) {
            return dispatch(hasPaymentErrorMessage(title, workspaceId));
        }

        if (isOnPlusPlan) {
            return dispatch(downgradeMessage(isOverBasicMeetingLimit));
        }

        if (workspace?.meetings?.length > 0) {
            return dispatch(moveMeetingsMessage({ workspace }));
        }

        handleDeleteWorkspace({ options, dispatch, workspace });
    };

export const hideWorkspace = (workspaceId) => async (dispatch) => {
    try {
        dispatch(clearMyHomepageIfContainsId(workspaceId));
        await apiHideWorkspace(workspaceId);
    } catch (error) {
        dispatch(
            alertError({
                id: `hideWorkspace-${workspaceId}`,
                title: `Workspace could not be hidden`,
                error,
            })
        );
    }
};

export const leaveWorkspace =
    (workspaceId, workspaceTitle, canLeave) =>
    async (
        dispatch,
        getState
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line require-await
    ) => {
        const workspace = getGroupFromStoreById(getState(), workspaceId);
        const workspaceTitleForUser = workspaceTitle || workspace.title;

        if (!workspace) {
            return dispatch(
                alertError({
                    id: `onLeaveWorkspace-${workspace.id}`,
                    title: 'Workspace was not found',
                    error: 'Workspace was not found',
                })
            );
        }

        const leavePromise = async () => {
            try {
                // Redirect first to avoid a flash of the "not found" page
                dispatch(replaceUrlToWorkspacesList());
                await apiLeaveWorkspace(workspace.id);
                dispatch(
                    alertSuccess({
                        id: `member-left-workspace-${workspace.id}`,
                        title: `You have left the "${workspaceTitleForUser}" workspace`,
                        message: `The meetings from the workspace will soon be removed`,
                    })
                );
            } catch (error) {
                // Redirect to the workspace detail page
                dispatch(replace(getWorkspaceSlug(workspace)));
                dispatch(
                    alertError({
                        id: `member-left-workspace-error-${workspace.id}`,
                        title: 'Error leaving workspace',
                        error,
                    })
                );
            }
        };

        const meetingCount = getAccessibleMeetingsIdsForWorkspace(
            getState(),
            workspace
        ).length;

        // Owner instruction if Owner count is < 2
        if (!canLeave) {
            return dispatch(
                openConfirm({
                    header: 'Cannot leave workspace',
                    content: (
                        <>
                            As you are the only user with Owner access, you
                            cannot leave &quot;
                            <strong>{workspaceTitleForUser}</strong>
                            &quot;.&nbsp; Please ensure another user&rsquo;s
                            access level is set to Owner first then try again.
                            {meetingCount === 0 && (
                                <>
                                    <br />
                                    <br />
                                    Alternatively, select delete from the
                                    options menu to remove it.
                                </>
                            )}
                        </>
                    ),
                    modalType: MODAL_TYPE_OK_NO_ACTION,
                })
            );
        }

        // No meetings in the workspace so open confirm modal with warning
        if (meetingCount === 0) {
            return dispatch(
                openConfirm({
                    header: 'Leave workspace',
                    content: (
                        <>
                            If you select leave, you will no longer have any
                            access to, or receive any further correspondence for
                            &quot;
                            <strong>{workspaceTitleForUser}</strong>&quot;.
                        </>
                    ),
                    modalType: MODAL_TYPE_LEAVE_CANCEL,
                    promise: leavePromise,
                })
            );
        }

        // Open a modal if there are meetings in the workspace

        dispatch(
            openConfirm({
                header: 'Leave workspace',
                content: (
                    <>
                        Leaving &quot;
                        <strong>{workspaceTitleForUser}</strong>
                        &quot; will also leave the{' '}
                        {`${meetingCount} attached ${pluralize(
                            'meeting',
                            `meetings`,
                            meetingCount
                        )}`}
                        .
                    </>
                ),
                modalType: MODAL_TYPE_LEAVE_CANCEL,
                promise: leavePromise,
            })
        );
    };

export const moveMeetingToAnotherWorkspace =
    (meetingId, payload) => async (dispatch, getState) => {
        const state = getState();
        const sourceCategoryBefore = getGroupByMeetingId(state, meetingId);
        const sourceWorkspaceBefore = getWorkspaceGroupByMeetingId(meetingId);
        const targetCategoryBefore = getGroupFromStoreById(
            state,
            payload.toGroupId
        );
        const targetWorkspaceBefore = getGroupFromStoreById(
            state,
            payload.toWorkspaceId
        );
        const alertId = `moveMeetingToAnotherWorkspace-${meetingId}`;

        try {
            pauseUpdates(); // while we remove and re-add alerts
            // alert's preventDuplicates won't work here (either because of JSX in alert title, or because title is different depending on the workspace title),
            // so we need to make sure we remove any previous alert first.
            // only an issue if user is moving meeting between multiple workspaces and alert doesn't automatically disappear.
            dispatch(removeAlert({ id: alertId }));

            targetCategoryBefore &&
                dispatch(
                    moveMeetingToAnotherGroupRequest(
                        meetingId,
                        sourceCategoryBefore,
                        targetCategoryBefore
                    )
                );
            dispatch(
                moveMeetingToAnotherGroupRequest(
                    meetingId,
                    sourceWorkspaceBefore,
                    targetWorkspaceBefore
                )
            );
            resumeUpdates(); // to show latest alert

            pauseUpdates(); // to prevent websocket events causing the move modal to re-render while api still processing

            await moveMeetingToAnotherWorkspaceApi(meetingId, payload);

            dispatch(
                alertSuccess({
                    id: alertId,
                    title: (
                        <>
                            Series has been moved to “
                            {targetWorkspaceBefore.title}”
                        </>
                    ),
                })
            );

            resumeUpdates();
        } catch (error) {
            resumeUpdates();

            dispatch(
                alertError({
                    id: alertId,
                    title: (
                        <>
                            Series could not be moved to “
                            {targetWorkspaceBefore.title}”
                        </>
                    ),
                    error,
                })
            );

            pauseUpdates();
            targetCategoryBefore &&
                dispatch(
                    moveMeetingToAnotherGroupError(
                        meetingId,
                        sourceCategoryBefore,
                        targetCategoryBefore
                    )
                );
            dispatch(
                moveMeetingToAnotherGroupError(
                    meetingId,
                    sourceWorkspaceBefore,
                    targetWorkspaceBefore
                )
            );
            resumeUpdates();
        }
    };

export function uploadWorkspaceLogo(workspaceId, data) {
    return async (dispatch) => {
        try {
            const workspaceProfile = await uploadWorkspaceLogoApi(
                workspaceId,
                data.blob,
                data.title
            );

            dispatch(
                alertSuccess(
                    {
                        id: `workspace-logo-${workspaceId}`,
                        title: 'Workspace logo',
                        message: 'Workspace logo uploaded successfully.',
                    },
                    {
                        closeOnToastrClick: true,
                        timeOut: 10000,
                        progressBar: true,
                    }
                )
            );
            dispatch(updateWorkspaceProfileSuccess(workspaceProfile));
        } catch (error) {
            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );
            dispatch(
                alertError({
                    id: `uploadWorkspaceLogo`,
                    title: 'Workspace logo could not be updated',
                    error,
                })
            );
            throw newError;
        }
    };
}

export function removeWorkspaceLogo(workspaceId) {
    return async (dispatch) => {
        try {
            const workspaceProfile = await removeWorkspaceLogoApi(workspaceId);
            dispatch(updateWorkspaceProfileSuccess(workspaceProfile));
        } catch (error) {
            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );
            dispatch(
                alertError({
                    id: `removeWorkspaceLogo`,
                    title: 'Workspace logo could not be removed',
                    error,
                })
            );
            throw newError;
        }
    };
}

export const upgradeWorkspace =
    (...props) =>
    () =>
        upgradeWorkspaceApi(...props);

export const downgradeWorkspace =
    (...props) =>
    () =>
        downgradeWorkspaceApi(...props);

export const updatePaymentWorkspace =
    (...props) =>
    () =>
        updatePaymentWorkspaceApi(...props);

export const getSecretForWorkspace =
    (...props) =>
    () =>
        getSecretForWorkspaceApi(...props);

export const getCouponDetails =
    (...props) =>
    () =>
        getCouponDetailsApi(...props);

export const updateMemberDisplayName = (workspaceId, userId, displayName) => {
    return async (dispatch) => {
        const userBeforeUpdate = getUser(userId);
        try {
            dispatch({
                type: UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
                userProfile: {
                    id: userId,
                    email: userBeforeUpdate.email,
                    displayName,
                },
            });
            const updated = await updateMemberDisplayNameApi(
                workspaceId,
                userId,
                displayName
            );
            return updated;
        } catch (error) {
            dispatch({
                type: UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
                userProfile: {
                    id: userId,
                    email: userBeforeUpdate.email,
                    displayName: userBeforeUpdate.displayName,
                },
            });
            dispatch(
                alertError({
                    id: 'updateMemberDisplayName',
                    title: 'Display name could not be updated',
                    error,
                })
            );
            throw error;
        }
    };
};

export const workspaceAccessRemoved =
    (workspaceOrId, type = WORKSPACE_ACCESS_REMOVED) =>
    (dispatch, getState) => {
        const state = getState();
        const workspace = (typeof workspaceOrId === 'string'
            ? getWorkspaceFromStoreById(state, workspaceOrId)
            : workspaceOrId) || {
            id: workspaceOrId,
        };

        dispatch({
            type,
            workspace,
        });
    };

export const sendJoinWorkspaceRequest =
    (recommendedWorkspaces) => async (dispatch) => {
        const workspaceIds = recommendedWorkspaces.map((w) => w.id);
        try {
            recommendedWorkspaces.forEach((w) => {
                const workspace = {
                    ...w,
                    // workspaceProps req'd to pass through normalizeWorkspace function
                    workspaceProps: {},
                };

                dispatch(addGroupRequest(workspace));
            });
            await addWorkspacePermissionRequest({ workspaceIds });
        } catch {
            recommendedWorkspaces.map((w) => dispatch(addGroupError(w)));
        }
    };

export const cancelWorkspaceRequest = (workspaceId) => async (dispatch) => {
    try {
        await apiCancelWorkspaceRequest(workspaceId);
    } catch (error) {
        dispatch(
            alertError({
                id: `cancelWorkspaceRequest-${workspaceId}`,
                title: `Workspace request could not be canceled`,
                error,
            })
        );
    }
};
