import { createSelector } from 'reselect';
import _find from 'lodash/find.js';
import _get from 'lodash/get.js';
import _map from 'lodash/map.js';
import _memoize from 'lodash/memoize.js';
import _omit from 'lodash/omit.js';
import _sortBy from 'lodash/sortBy.js';
import _uniq from 'lodash/uniq.js';
import moment from 'moment-timezone';
import ObjectID from 'bson-objectid';
import { getMeetingIdSelector } from '~common/selector.helpers';
import { getStore } from '~common/store';
import { makeDateTime } from '~common/time.utils';
import {
    GRANT_LEVEL_ADMIN,
    RECURRENCE_NEVER,
    RECURRENCE_WEEKLY,
    RECURRENCE_MONTHLY_ABSOLUTE,
    RECURRENCE_MONTHLY_RELATIVE,
    RECURRENCE_YEARLY_ABSOLUTE,
    RECURRENCE_YEARLY_RELATIVE,
    STATUS_MINUTES_COMPLETED,
    STATUS_MINUTES_STARTED,
    STATUS_MINUTES_SENT,
    STATUS_AGENDA_SENT,
    ATTENDEE_ROLE_ABSENT_SUFFIX,
    RECURRENCE_NOT_SCHEDULED,
} from '~common/constants';
import { getUserDisplayName } from '~shared/user/user.helpers';
import { grantsToGrantLevel } from '~shared/permissions/permission.helper';

export const meetingsSelector = (state) => state.meetings.meetings;
export const meetingsAsObjectListSelector = createSelector(
    meetingsSelector,
    (meetings) =>
        Object.fromEntries(meetings.map((meeting) => [meeting.id, meeting]))
);

/**
 * Get a list of meetings that aren't archived
 */
export const getActiveMeetings = createSelector(meetingsSelector, (meetings) =>
    meetings.filter((meeting) => !meeting.archived)
);

/**
 * Returns a meeting from the store, by searching the store. Returns empty object {} if not found.
 * @param {Object} [state=getStore().getState()] Redux store. If ommitted, will use the default Redux store.
 * @param {Object} props search object. see below for detail.
 * @param {(Object|string)} props.meeting Meeting object or meeting.id.
 */
export function getMeetingSelector() {
    let state, props;
    switch (arguments.length) {
        case 2:
            [state, props] = arguments;
            break;
        default:
            [props] = arguments;
            state = getStore().getState();
    }

    const id = getMeetingIdSelector(state, props);
    return _find(state.meetings.meetings, { id }) || {};
}

/**
 * Returns a meeting from the store, by searching the store.
 * @param {Object} [state=getStore().getState()] Redux store. If ommitted, will use the default Redux store.
 * @param {string} id meeting.id
 */
export function getMeetingFromStoreById() {
    let state, id;
    switch (arguments.length) {
        case 2:
            [state, id] = arguments;
            break;
        default:
            [id] = arguments;
            state = getStore().getState();
    }
    return state.meetings.meetings.find((m) => m.id === id);
}

export const getDefaultPermissionsForLoggedInUser = (user) => ({
    isAttendee: true,
    isOwner: true,
    grantLevel: GRANT_LEVEL_ADMIN,
    user,
});

/**
 * Get the attendees and permissions for an occurrence.
 * If you are looking for the memoized version, use occurrence.selectors.getAttendeesAndPermissionsSelector.
 * @param {Object} loggedInUser the user object of the logged in user
 * @param {Object} meetingOrOccurrence This could actually be a meeting or an occurrence object
 * @param {Object[]} permissions filtered permissions for the meeting or occurrence
 * @param {string[]} newUserIds list of ids (probably an email address) of new users to flag them as a new user
 */
export function getAttendeesAndPermissions(
    loggedInUser,
    { id, attendees } = {},
    permissions = [],
    newUserIds = []
) {
    const userIds = _uniq(
        _map(attendees, (a) => a.userId).concat(
            _map(
                permissions.filter((p) => !p.scope),
                (p) => p.user.id
            )
        )
    );

    const attendeesAndPermissions = _sortBy(
        _map(userIds, (id) => {
            const attendee = _find(attendees, { userId: id });
            const isAttendee = !!attendee;
            // GOTCHA: attendees from remote calendars don't have a role
            const isAbsent =
                isAttendee &&
                attendee?.role?.match(ATTENDEE_ROLE_ABSENT_SUFFIX);
            const permission = _find(
                permissions.filter((p) => !p.scope),
                {
                    user: { id },
                }
            );

            if (!permission) {
                return;
            }

            const grants = permission?.grants || [];

            const grantLevel = grantsToGrantLevel(grants);

            const newUser = newUserIds.includes(id);
            const invitationDate = permission.createdAt;

            const status = _get(permission, 'status');
            const targetType = _get(permission, 'targetType');

            return {
                id: permission.id,
                user: permission.user,
                derivedDisplayName: getUserDisplayName(permission.user),
                status,
                grantLevel,
                grants,
                isAttendee,
                isAbsent,
                attendee,
                newUser,
                invitationDate,
                targetType,
            };
        }).filter(Boolean),
        'derivedDisplayName'
    );

    if (
        !id &&
        !attendeesAndPermissions.some((ap) => ap.user.id === loggedInUser.id)
    ) {
        attendeesAndPermissions.push(
            getDefaultPermissionsForLoggedInUser(loggedInUser)
        );
    }

    return attendeesAndPermissions;
}

export function clearCacheMeetingAddEditFormInitialValues() {
    getMeetingAddEditFormInitialValues.cache.clear();
}

export const getMeetingAddEditFormInitialValues = _memoize(
    (meetingId, user, group = {}, meeting, date) => {
        let recurrenceDetail;

        if (meeting?.id) {
            const { startDate, endDate, timezone, range, pattern } = meeting;

            const startDateInMeetingTZ = startDate.clone().tz(timezone);
            const endDateInMeetingTZ = endDate.clone().tz(timezone);

            const rangeStartDateInMeetingTZ = range.startDate
                .clone()
                .tz(range.recurrenceTimeZone);
            const rangeEndDateInMeetingTZ =
                range.endDate instanceof moment
                    ? range.endDate.clone().tz(range.recurrenceTimeZone)
                    : '';

            const occurs =
                (meeting.recurrence === RECURRENCE_NOT_SCHEDULED &&
                    RECURRENCE_NOT_SCHEDULED) ||
                pattern.occurs ||
                RECURRENCE_NEVER;

            recurrenceDetail = {
                startDate: startDateInMeetingTZ,
                startTime: startDateInMeetingTZ.format('HH:mm'),
                endDate: endDateInMeetingTZ,
                endTime: endDateInMeetingTZ.format('HH:mm'),
                timezone,
                ...pattern,
                ..._omit(range, ['startDate', 'endDate']),
                rangeStartDate: rangeStartDateInMeetingTZ,
                rangeEndDate: rangeEndDateInMeetingTZ,
                noRangeEndDate: !range.endDate,
                occurs,
            };

            pattern?.weekdays?.forEach?.((w) => {
                recurrenceDetail[`day${w}`] = true;
            });

            delete recurrenceDetail.weekdays;
        } else {
            const { timezone } = user;
            const start = makeDateTime(
                moment(date).tz(timezone),
                moment.tz(timezone).format('HH:mm')
            );
            if (start.minutes() < 30) {
                start.startOf('hour').add(30, 'minutes');
            } else {
                start.startOf('hour').add(1, 'hour');
            }
            const end = start.clone().add(1, 'hour');

            recurrenceDetail = {
                occurs: RECURRENCE_NEVER,
                startDate: start,
                startTime: start.formatForUser('HH:mm'),
                endDate: end,
                endTime: end.formatForUser('HH:mm'),
                timezone,
                rangeStartDate: start.clone().startOf('day'),
                rangeEndDate: '',
                recurrenceTimeZone: timezone,
                ..._get(meeting, 'recurrenceDetail'),
                noRangeEndDate: true,
            };
        }

        const initialValues = {
            displayActionItemComments: true,
            previousActionItemReview: true,
            ..._omit(meeting, [
                'id',
                'timezone',
                'startDate',
                'endDate',
                'pattern',
                'range',
            ]),
            recurrenceDetail,
        };
        ObjectID.isValid(group?.id) && (initialValues.groupId = group.id);
        ObjectID.isValid(meeting?.id) && (initialValues.id = meeting.id);

        return initialValues;
    },
    (meetingId, user, group = {}, meeting, date) =>
        `${meetingId}.${group.id}${date ? `.${date.toString()}` : ''}`
);

export const getDefaultRangeEndDate = (recurrence, rangeStartDate, endTime) => {
    const addPeriod = 9;
    const endDate = makeDateTime(rangeStartDate, endTime);
    let key = 'd';
    switch (recurrence) {
        case RECURRENCE_WEEKLY: {
            key = 'w';
            break;
        }
        case RECURRENCE_MONTHLY_ABSOLUTE:
        case RECURRENCE_MONTHLY_RELATIVE: {
            key = 'M';
            break;
        }
        case RECURRENCE_YEARLY_ABSOLUTE:
        case RECURRENCE_YEARLY_RELATIVE: {
            key = 'y';
            break;
        }
    }
    return endDate.add(addPeriod, key);
};

export const getAgendaStatus = (status) => {
    let isAgendaSent = false;
    const sendAgendaButton = 'Send agenda';
    switch (status) {
        case STATUS_AGENDA_SENT:
            isAgendaSent = true;
            break;
    }
    return {
        isAgendaSent,
        sendAgendaButton,
    };
};
export const getMinutesStatus = (status) => {
    let isCompleted = false,
        isStarted = false,
        isSent = false;
    const sendMinutesButton = 'Send minutes';
    switch (status) {
        case STATUS_MINUTES_COMPLETED:
            isCompleted = true;
            break;
        case STATUS_MINUTES_STARTED:
            isStarted = true;
            break;
        case STATUS_MINUTES_SENT:
            isSent = true;
            break;
    }
    const isNotStarted = !status;
    return {
        isNotStarted,
        isCompleted,
        isNotComplete: isNotStarted || isStarted,
        isStarted,
        isSent,
        sendMinutesButton,
    };
};
