import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m19.5 2c0.319 0 0.6238 0.13204 0.842 0.36477l6.2932 6.7128c0.2344 0.24997 0.3648 0.57977 0.3648 0.92242m-7.5-8h-10.5c-2.2091 0-4 1.7909-4 4v20c0 2.2091 1.7909 4 4 4h14c2.2091 0 4-1.7909 4-4v-16m-7.5-8v4c0 2.2091 1.7909 4 4 4h3.5"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m14.512 14.636c0 0.0613-0.0107 0.124-0.032 0.188-0.0187 0.0613-0.0467 0.1187-0.084 0.172l-2.864 4.064h2.9v0.94h-4.44v-0.468c0-0.056 0.00934-0.1107 0.02804-0.164 0.0186-0.056 0.0453-0.1067 0.08-0.152l2.872-4.08h-2.776v-0.94h4.316v0.44zm2.0153 5.364h-1.208v-5.804h1.208v5.804zm3.1739-2.916c0.376 0 0.6507-0.0907 0.824-0.272 0.176-0.1813 0.264-0.4293 0.264-0.744 0-0.144-0.0227-0.2747-0.068-0.392-0.0427-0.12-0.1093-0.2213-0.2-0.304-0.088-0.0853-0.2-0.1507-0.336-0.196s-0.2973-0.068-0.484-0.068h-0.76v1.976h0.76zm0-2.888c0.3973 0 0.74 0.0467 1.028 0.14 0.2907 0.0933 0.5293 0.224 0.716 0.392 0.1867 0.1653 0.324 0.3627 0.412 0.592 0.0907 0.2293 0.136 0.4787 0.136 0.748 0 0.2853-0.0467 0.5467-0.14 0.784s-0.2347 0.4413-0.424 0.612-0.428 0.304-0.716 0.4c-0.288 0.0933-0.6253 0.14-1.012 0.14h-0.76v1.996h-1.2v-5.804h1.96z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
