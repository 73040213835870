import {
    MODAL_AGENDA_TEMPLATE_OPEN,
    MODAL_AGENDA_TEMPLATE_CLOSE,
} from '~common/action.types';

export const closeAgendaTemplateModal = () => ({
    type: MODAL_AGENDA_TEMPLATE_CLOSE,
});

export const openAgendaTemplateModal = (args) => ({
    type: MODAL_AGENDA_TEMPLATE_OPEN,
    ...args,
});
