import React from 'react';
export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 24 30"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m23 27v-24c0-1.1046-0.8954-2-2-2h-18c-1.1046 0-2 0.89543-2 2v24c0 1.1046 0.89543 2 2 2h18c1.1046 0 2-0.8954 2-2z"
            strokeLinecap="round"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m16 11-3 2v-12h6v12l-3-2z"
            strokeLinecap="round"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
