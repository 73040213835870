import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    getUserSelector,
    getUserListSelector,
} from '~modules/user/user.selectors';
import { getUserDisplayName } from '~shared/user/user.helpers';
import { sendEmailDailyDigest } from '~modules/user/user.api';
import { useMeetings, useTemplates } from '~hooks';

// Get the current user including display name
const useUser = () => {
    const user = useSelector(getUserSelector);
    const userName = getUserDisplayName(user, 'member');

    return useMemo(() => ({ ...user, userName }), [user, userName]);
};

const useUserHasCreatedMeetingsBefore = () => {
    const user = useSelector(getUserSelector);
    const meetings = useMeetings();
    return meetings.some((m) => m.createdBy === user.id);
};

const useUserHasCreatedTemplatesBefore = () => {
    const user = useSelector(getUserSelector);
    const templates = useTemplates();
    return templates.some((t) => t.createdBy === user.id);
};

const useUserCanAccessWelcomeScreen = () => {
    const user = useSelector(getUserSelector);
    return user.signinCount === 1;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line unicorn/consistent-function-scoping
const useSendEmailDailyDigest = () => () => sendEmailDailyDigest();

const useUserList = () => useSelector(getUserListSelector);

export {
    useUser,
    useUserHasCreatedMeetingsBefore,
    useUserHasCreatedTemplatesBefore,
    useUserCanAccessWelcomeScreen,
    useSendEmailDailyDigest,
    useUserList,
};
