import React from 'react';
import tw from 'twin.macro';
import Icon from '~icons';
import { Link } from 'react-router-dom';

const Container = tw(
    Link
)`hover:text-body-dark active:text-body-dark mb-4 inline-flex items-center`;
const IconStyled = tw(Icon)`mr-2 text-xs`;

const ReturnLink = ({ to, children }) => (
    <Container {...{ to }}>
        <IconStyled name="leftArrow" />
        {children}
    </Container>
);

export default ReturnLink;
