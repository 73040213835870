import React from 'react';
import tw, { styled } from 'twin.macro';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '~shared/navigation';
import { Spacer } from '~modules/common/components';

// Keep syncronised with ConnectionModal@PaddedContainer
const PaddedContainer = (props) => (
    <Spacer spaceY="lg" tw="mb-1.5 (p-8 lg:spacer-p-lg)" {...props} />
);

const Row = tw.div`flex w-full sm:space-x-8`;
const Left = tw.div`hidden sm:(flex w-full)`;
const Right = tw.div`flex-col space-y-3 w-full sm:(w-3/4 flex-shrink-0)`;

const LoadingItem = styled.div(({ isLarge, isMed, isSmall }) => [
    tw`rounded bg-line w-full animate-pulse after:content-['\\a0']`,
    isLarge && tw`sm:w-5/6`,
    isMed && tw`sm:w-3/4`,
    isSmall && tw`w-1/2`,
]);

const ConnectionModalLoader = () => (
    <PaddedContainer>
        <Spacer spaceY="lg">
            <Row tw="items-center">
                <Left tw="aspect-ratio[1 / 1]">
                    <LoadingItem />
                </Left>
                <Right>
                    <LoadingItem isSmall />
                    <LoadingItem isMed />
                    <LoadingItem isSmall />
                </Right>
            </Row>
            <Row>
                <Left />
                <Right>
                    <LoadingItem />
                    <LoadingItem isLarge />
                </Right>
            </Row>
            <Row>
                <Left />
                <Right>
                    <LoadingItem />
                    <LoadingItem isMed />
                </Right>
            </Row>
        </Spacer>
    </PaddedContainer>
);

const HideRemoteCalendarCtaMessage = () => (
    <>
        The integration was successfully hidden.
        <br />
        Re-enable it on the{' '}
        <NavLink to={ROUTES.integration}>integration settings</NavLink> page.
    </>
);

export { ConnectionModalLoader, HideRemoteCalendarCtaMessage };
