import _omit from 'lodash/omit.js';
import { MODAL_LOADING_OPEN, MODAL_LOADING_CLOSE } from '~common/action.types';

const initialState = { visible: false };

const loadingModalReducer = (
    state = initialState,
    action: { type: typeof MODAL_LOADING_OPEN | typeof MODAL_LOADING_CLOSE }
): {} => {
    switch (action.type) {
        case MODAL_LOADING_OPEN:
            return { ...state, ..._omit(action, 'type'), visible: true };

        case MODAL_LOADING_CLOSE:
            return initialState;

        default:
            return state;
    }
};

export default loadingModalReducer;
