import React from 'react';
import tw from 'twin.macro';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { TabMenu, NavLinkCount } from '~modules/common/form-elements';
import { getPageStateForOccurrence } from '~modules/pageView/pageView.selectors';
import { useWorkspaceById } from '~hooks';
import {
    getFeatureTrialExpiry,
    isFeatureEnabled,
} from '~modules/feature/feature.helpers';
import HelpLink from '~modules/help/HelpLink';
import FeatureLabel from '~modules/feature/FeatureLabel';
import { WORKSPACE_FEATURE_TRIAL_ACTIVITY } from '~shared/constants';
import { OccurrenceCountdownTimer } from '~modules/occurrence/components';

const emptyObject = {};

const CountDownTimerWrap = tw.div`flex items-center ml-2`;

const TopTabMenu = ({
    occurrence,
    actionItems,
    documents,
    match,
    workspaceId,
    isIntro,
    displayCountdownTimerForTabMenu,
}) => {
    const pageState =
        useSelector((state) =>
            getPageStateForOccurrence(state, { occurrence })
        ) || emptyObject;
    const workspace = useWorkspaceById(workspaceId);
    const canUpdateWorkspace = workspace?.myAccessLevel?.canUpdate;
    const featureTrialDetails = getFeatureTrialExpiry(
        WORKSPACE_FEATURE_TRIAL_ACTIVITY,
        workspace,
        {
            isIntro,
        }
    );
    const isTrialFeature = workspace?.isOnBasicPlan ?? false;
    const trialFeatureHasStarted = featureTrialDetails?.hasStarted;
    const trialFeatureHasExpired = featureTrialDetails?.hasExpired;
    const daysRemainingForPlusFeature = featureTrialDetails?.daysRemaining;

    const displayActivityTab =
        isFeatureEnabled('SEND_EMAIL_ACTIVITY') &&
        // Always display for workspace members
        (canUpdateWorkspace ||
            // Only display for guests once trial period has commenced
            (isTrialFeature && trialFeatureHasStarted) ||
            // Always display for Plus plan workspaces
            workspace?.isOnPlusPlan ||
            isIntro);

    const featureDescription = (
        <>
            View agenda, minutes and invitation email history for this
            meeting.&nbsp;
            <HelpLink
                modal="view-meeting-email-activity"
                linkText="Read more"
                hasHover
                iconName="externalLink"
                handleClick={(evt) => evt.stopPropagation()}
            />
        </>
    );

    return (
        <TabMenu>
            <NavLink
                className="item"
                to={{
                    pathname: pageState.minutes || `${match.url}/minutes`,
                    state: { avoidScrollTop: true },
                }}
                replace
            >
                Topics
            </NavLink>

            <NavLink
                className="item"
                to={{
                    pathname:
                        pageState.actionItems || `${match.url}/action-items`,
                    state: { avoidScrollTop: true },
                }}
                replace
            >
                Actions
                {(actionItems || []).length > 0 && (
                    <NavLinkCount>{(actionItems || []).length}</NavLinkCount>
                )}
            </NavLink>

            <NavLink
                exact
                className="item"
                to={{
                    pathname: pageState.documents || `${match.url}/documents`,
                    state: { avoidScrollTop: true },
                }}
                replace
            >
                Documents
                {(documents || []).length > 0 && (
                    <NavLinkCount>{(documents || []).length}</NavLinkCount>
                )}
            </NavLink>

            {displayActivityTab && (
                <NavLink
                    exact
                    className="item"
                    to={{
                        pathname: `${match.url}/activity`,
                        state: { avoidScrollTop: true },
                    }}
                    replace
                >
                    Activity
                    {isTrialFeature ? (
                        <span tw="ml-2">
                            <FeatureLabel
                                {...{
                                    isTrialFeature,
                                    trialFeatureHasStarted,
                                    trialFeatureHasExpired,
                                    daysRemainingForPlusFeature,
                                    workspace,
                                    featureDescription,
                                }}
                            />
                        </span>
                    ) : (
                        pageState.activityCount > 0 && (
                            <NavLinkCount>
                                {pageState.activityCount}
                            </NavLinkCount>
                        )
                    )}
                </NavLink>
            )}
            {displayCountdownTimerForTabMenu && (
                <CountDownTimerWrap>
                    <OccurrenceCountdownTimer {...{ occurrence }} />
                </CountDownTimerWrap>
            )}
        </TabMenu>
    );
};

export default TopTabMenu;
