import { replace } from 'connected-react-router';
import { getDashboardPathParams } from '~modules/navigation';
import { getBaseAppHomepagePath } from './index.js';
import {
    getAgendaTemplatesSlug,
    getWorkspaceSlug,
} from '~modules/navigation/navigation.helpers';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line unicorn/consistent-function-scoping, require-await
const replaceUrlToDashboard = () => async (dispatch) =>
    dispatch(replace(getBaseAppHomepagePath()));

function replaceUrlToDashboardOnGroupDelete(pathname, workspace) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    return async (dispatch) => {
        const [, dashboardType] = getDashboardPathParams(pathname);
        return dispatch(replace(`/${dashboardType}/${workspace}`));
    };
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line unicorn/consistent-function-scoping, require-await
const replaceUrlToWorkspacesList = () => async (dispatch) =>
    dispatch(replace(getWorkspaceSlug()));

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line unicorn/consistent-function-scoping, require-await
const replaceUrlToTemplatesList = () => async (dispatch) =>
    dispatch(replace(getAgendaTemplatesSlug()));

export {
    replaceUrlToDashboard,
    replaceUrlToDashboardOnGroupDelete,
    replaceUrlToWorkspacesList,
    replaceUrlToTemplatesList,
};
