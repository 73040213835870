// TODO: Rename this file to richtext.helpers.js

import { IS_PRODUCTION, IS_TEST } from '~common/constants';

// =============================================================================
// Helpers > Utilities
// =============================================================================

/**
 * Convert a string to HTML nodes
 * @param {String} htmlString The html string to convert into nodes
 */
const stringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body;
};

const kebabize = (string) =>
    string.replace(/([\da-z]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();

const convertCssObjectToString = (cssObject) => {
    if (!cssObject) return;

    return Object.entries(cssObject)
        .map(([k, v]) =>
            typeof v === 'string'
                ? `${kebabize(k)}: ${v};`
                : `${k} {
${convertCssObjectToString(v)}
        }`
        )
        .join('\n');
};

const classNames = (arr) => arr.filter(Boolean).join(' ');

/**
 * Log the editor messages out to the console, for debugging
 * @param {String} [message] Message to display in the console to go along with the object.
 * @param {Object} [params] Arbitrary object composed of other objects to log out to the console
 * @param {String} [logLevel='log'] to execute in console.[logLevel].
 */
function logDocumentToConsole() {
    if (IS_PRODUCTION || IS_TEST) return;

    let message = '',
        params,
        logLevel = 'log';
    switch (arguments.length) {
        case 3: {
            [message, params, logLevel] = arguments;
            break;
        }
        case 2: {
            // could be:
            //    (message, params) - string / object
            //    (message, logLevel) - string / string
            //    (params, logLevel) - object / string
            const [one, two] = arguments;
            if (typeof one === 'string') {
                if (typeof two === 'string') {
                    [message, logLevel] = arguments;
                } else {
                    [message, params] = arguments;
                }
                break;
            }
            [params, logLevel] = arguments;

            break;
        }
        case 1: {
            // could be:
            //   (message)
            //   (params)
            const [one] = arguments;
            if (typeof one === 'string') {
                message = one;
                break;
            }
            params = one;
        }
    }

    console[logLevel](`[rte] ${message}`, params); //eslint-disable-line no-console
}

const parseIndentValue = function (value) {
    const number = Number.parseInt(value, 10);
    return Number.isNaN(number) ? 0 : number;
};

// Indent/outdent an element
// `editor.execCommand("indent")` is available but doesn’t let us specify an element to indent, only an indent at the point of the cursor so this feature adds that functionality
const indentElement = ({ editor, command, element }) => {
    const unit = 'px';
    const indentStyleName = 'margin-left';
    const indentation = editor.options.get('indentation') || '40px';
    const value = Number.parseInt(indentation, 10);
    const existingValue = parseIndentValue(element.style[indentStyleName]);

    if (command === 'outdent') {
        const styleValue = Math.max(0, existingValue - value);
        editor.dom.setStyle(
            element,
            indentStyleName,
            styleValue ? styleValue + unit : ''
        );
        editor.dispatch('change'); // trigger the editor to update so minutes content is updated
        return;
    }

    const styleValue = existingValue + value + unit;
    editor.dom.setStyle(element, indentStyleName, styleValue);
    editor.dispatch('change'); // trigger the editor to update so minutes content is updated
};

const getOuterParagraphNode = (node, editor) =>
    node && editor.dom.getParent(node, (n) => n.localName === 'p');

export {
    stringToHTML,
    convertCssObjectToString,
    classNames,
    logDocumentToConsole,
    indentElement,
    getOuterParagraphNode,
};
