// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import { get, getRaw } from '../../api/helpers.js';
import {
    meetingModelList,
    occurrenceModelList,
} from '../meeting/meeting.mappers.js';
import { userModel } from '~modules/user/user.mappers';
import { minuteItemModelList } from '~modules/minutes/minutes.mappers';
import { getStore } from '~common/store';
import { actionItemModelList } from '../../api/actionItem.mappers.js';
import { notificationModelList } from '../../modules/notification/notification.mappers.js';
import { pageViewModelList } from '../pageView/pageView.mappers.js';
import { API_URL } from '~common/constants';
import { makeQueryStringFromObject } from '~shared/navigation';

const getPreloadRequestIdHeader = () => {
    const preloadRequestId = getStore().getState().preload.preloadRequestId;
    if (!preloadRequestId) return {};

    return {
        ['x-request-id']: preloadRequestId,
    };
};

export async function getUserProfile(params) {
    const response = await getRaw(
        `/api/userprofile${
            params ? `?${makeQueryStringFromObject(params)}` : ''
        }`
    );
    const requestId = response.headers.get('x-request-id');
    const body = await response.json();
    return {
        requestId,
        userProfile: userModel(body),
    };
}

export const getLocationData = () =>
    API_URL
        ? get(`${API_URL}/location`, undefined, undefined, {
              credentials: undefined,
          }).then((location) =>
              Object.fromEntries(
                  Object.entries(location)
                      .map(([key, value]) => value && [key, value])
                      .filter(Boolean)
              )
          )
        : null;

export function getUserPermissions() {
    return get('/api/permissions/mine', null, getPreloadRequestIdHeader());
}

export function getNotifications(params) {
    return get(
        '/api/preload/notifications',
        params,
        getPreloadRequestIdHeader()
    ).then((page) => {
        const docs = notificationModelList(page.docs);

        return {
            page: _.omit(page, 'docs'),
            docs,
        };
    });
}

export function getGroups() {
    return get('/api/groups', null, getPreloadRequestIdHeader());
}

export function getScheduledEvents() {
    return get('/api/scheduled-events', null, getPreloadRequestIdHeader());
}

export function getMeetings(params) {
    return get(
        `/api/preload/meetings`,
        params,
        getPreloadRequestIdHeader()
    ).then((page) => {
        const docs = meetingModelList(page.docs);

        return {
            page: _.omit(page, 'docs'),
            docs,
        };
    });
}

export function getUserList(params) {
    return get(
        `/api/preload/userlist`,
        params,
        getPreloadRequestIdHeader()
    ).then((page) => {
        const docs = page.docs;
        return {
            page: _.omit(page, 'docs'),
            docs,
        };
    });
}

export function getOccurrences(params) {
    return get(
        `/api/preload/occurrences`,
        params,
        getPreloadRequestIdHeader()
    ).then((payload) => {
        // if a specific meeting was requested, and the meeting was not found, then the payload could be null rather than a 'page'
        const page = payload || { docs: [] };

        const docs = occurrenceModelList(page.docs);

        return {
            page: _.omit(page, 'docs'),
            docs,
        };
    });
}

export function getActionItems(params) {
    return get(
        `/api/preload/action-items`,
        params,
        getPreloadRequestIdHeader()
    ).then((payload) => {
        // if a specific meeting was requested, and the meeting was not found, then the payload could be null rather than a 'page'
        const page = payload || { docs: [] };

        const docs = actionItemModelList(page.docs);

        return {
            page: _.omit(page, 'docs'),
            docs,
        };
    });
}

export function getLinkedActionItems(params) {
    return get(
        `/api/preload/action-items/linked`,
        params,
        getPreloadRequestIdHeader()
    ).then((payload) => {
        // if a specific meeting was requested, and the meeting was not found, then the payload could be null rather than a list
        const actionItemsList = payload || [];

        const actionItems = actionItemModelList(actionItemsList);

        return actionItems;
    });
}

export function getDocumentList(params) {
    return get(
        `/api/preload/documents`,
        params,
        getPreloadRequestIdHeader()
    ).then((payload) => {
        // if a specific meeting was requested, and the meeting was not found, then the payload could be null rather than a 'page'
        const page = payload || { docs: [] };

        const docs = page.docs;

        return {
            page: _.omit(page, 'docs'),
            docs,
        };
    });
}

export const getTemplates = () =>
    get(`/api/preload/templates`, null, getPreloadRequestIdHeader());

export function getAgendaItems(params) {
    return get('/api/preload/agenda', params, getPreloadRequestIdHeader());
}

export function getMinutes(params) {
    return get(
        `/api/preload/minutes`,
        params,
        getPreloadRequestIdHeader()
    ).then((payload) => {
        // if a specific meeting was requested, and the meeting was not found, then the payload could be null rather than a 'page'
        const page = payload || { docs: [] };

        const docs = minuteItemModelList(page.docs);

        return {
            page: _.omit(page, 'docs'),
            docs,
        };
    });
}

export function getPrivateNotes() {
    return get('/api/preload/privatenotes', null, getPreloadRequestIdHeader());
}

export function getIntegrationProfiles() {
    return get(
        '/api/preload/integrationprofiles',
        null,
        getPreloadRequestIdHeader()
    );
}

export function getPageViews(params) {
    return get(
        `/api/preload/pageviews`,
        params,
        getPreloadRequestIdHeader()
    ).then((page) => {
        const docs = pageViewModelList(page.docs);

        return {
            page: _.omit(page, 'docs'),
            docs,
        };
    });
}
