import React from 'react';
import tw, { styled } from 'twin.macro';
import { Tooltip, Button } from '~modules/common/components';
import {
    STATUS_TYPE_NEW_USER,
    STATUS_TYPE_NEW_USER_NO_ACCESS,
    PERMISSION_STATUS_PENDING,
    PERMISSION_STATUS_REJECTED,
    PERMISSION_STATUS_REQUESTED,
    STATUS_TYPE_WORKSPACE_REJECTED,
    PERMISSION_STATUS_LEFT,
    STATUS_TYPE_WORKSPACE_LEFT,
    STATUS_TYPE_REVOKED,
    PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED,
} from '~client/common/constants';
import HelpLink from '~modules/help/HelpLink';

/* Styles */
const Container = styled.div(({ hasHover, type, isDisabled }) => [
    tw`flex items-center justify-center select-none text-body space-x-2`,
    type === 'status-heading' &&
        tw`flex-row-reverse sm:flex-row space-x-reverse sm:space-x-2 mt-1 sm:mt-0`,
    [
        PERMISSION_STATUS_REJECTED,
        STATUS_TYPE_NEW_USER_NO_ACCESS,
        STATUS_TYPE_REVOKED,
        PERMISSION_STATUS_LEFT,
        STATUS_TYPE_WORKSPACE_LEFT,
        isDisabled,
    ].includes(type) && tw`text-body-light`,
    hasHover && tw`hocus:(text-body-dark cursor-pointer)`,
]);
const Wrap = tw.div`relative flex items-center justify-center`;

const Indicator = styled.div(({ type }) => [
    ![
        STATUS_TYPE_NEW_USER,
        PERMISSION_STATUS_PENDING,
        'status-heading',
    ].includes(type) && tw`indicator-icon`,

    [STATUS_TYPE_NEW_USER, 'status-heading'].includes(type) &&
        tw`indicator-primary`,

    type === PERMISSION_STATUS_PENDING && tw`indicator-warning`,
]);
const Text = tw.div`text-body leading-normal`;
/* End styles */

const getStatusContent = ({
    type,
    invitationDate,
    statusHeading,
    isSending,
    isSent,
}) => {
    switch (type) {
        case STATUS_TYPE_NEW_USER: {
            return {
                label: 'New',
                title: 'New user',
                text: 'This person will receive an invitation to this meeting.',
                hasHover: true,
                enableTooltip: true,
            };
        }
        case STATUS_TYPE_NEW_USER_NO_ACCESS: {
            return {
                label: 'New',
                title: 'No access',
                text: 'This person’s access is set to none so they will not receive an invitation.',
                hasHover: true,
                enableTooltip: true,
            };
        }
        case PERMISSION_STATUS_PENDING: {
            return {
                label: 'Pending',
                title: 'Pending invitation',
                text: `This member was first invited on ${invitationDate} and has not accepted the invitation yet.`,
                hasButton: true,
                hasHover: true,
                hoverable: true,
                cta: isSending
                    ? 'Resending invitation'
                    : isSent
                    ? 'Invitation re-sent'
                    : 'Resend invitation',
            };
        }
        case PERMISSION_STATUS_REJECTED: {
            return {
                label: 'Rejected',
                title: 'Rejected invitation',
                text: (
                    <>
                        This person declined the meeting invitation.{' '}
                        <HelpLink
                            modal="reinvite-rejected"
                            linkText="Read more about re-inviting rejected members"
                            hasHover
                        />
                    </>
                ),
                hasHover: true,
                hoverable: true,
            };
        }
        case PERMISSION_STATUS_REQUESTED: {
            return {
                label: 'Requested',
                title: 'Requested workspace access',
                text: 'This person requested access to this workspace.',
                hasButton: true,
                hasHover: true,
                hoverable: true,
                cta: 'View request',
            };
        }
        case STATUS_TYPE_WORKSPACE_REJECTED: {
            return {
                label: 'Rejected',
                title: 'Rejected invitation',
                text: 'This person declined the invitation to this workspace.',
                hasHover: true,
                enableTooltip: true,
            };
        }
        case STATUS_TYPE_REVOKED: {
            return {
                label: 'Revoked',
                title: 'Removed from workspace',
                text: (
                    <>
                        This person has been removed and no longer has access to
                        meetings in this workspace.{' '}
                        <HelpLink
                            modal="reinvite-revoked"
                            linkText="Read more about re-inviting revoked members"
                            hasHover
                        />
                    </>
                ),
                hasHover: true,
                hoverable: true,
            };
        }
        case PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED: {
            return {
                label: 'Denied',
                title: 'Denied workspace access',
                text: 'An owner denied this user’s request to join this workspace.',
                hasHover: true,
                enableTooltip: true,
            };
        }
        case PERMISSION_STATUS_LEFT: {
            return {
                label: 'Left',
                title: 'Left meeting',
                text: (
                    <>
                        This person chose to leave and no longer has access to
                        this meeting.{' '}
                        <HelpLink
                            modal="reinvite-left"
                            linkText="Read more about re-inviting left members"
                            hasHover
                        />
                    </>
                ),
                hasHover: true,
                hoverable: true,
            };
        }
        case STATUS_TYPE_WORKSPACE_LEFT: {
            return {
                label: 'Left',
                title: 'Left workspace',
                text: (
                    <>
                        This person chose to leave and no longer has access to
                        meetings in this workspace.{' '}
                        <HelpLink
                            modal="reinvite-left"
                            linkText="Read more about re-inviting left members"
                            hasHover
                        />
                    </>
                ),
                hasHover: true,
                hoverable: true,
            };
        }
        case 'status-heading': {
            return {
                label: statusHeading,
                enableTooltip: false,
            };
        }
        default: {
            return {};
        }
    }
};

const StatusIndicator = ({
    type = '',
    invitationDate = '',
    statusHeading = '',
    onClick = () => {},
    isDisabled = false,
    isSending = false,
    isSent = false,
}) => {
    const { label, enableTooltip, hasHover, hoverable } = getStatusContent({
        type,
        statusHeading,
        isSending,
        isSent,
    });

    const statusLabel = (
        <Container {...{ type, hasHover, isDisabled }}>
            <Wrap>
                <Indicator {...{ type }} />
            </Wrap>
            <div>{label}</div>
        </Container>
    );
    const statusContent = () => {
        const { title, text, hasButton, cta } = getStatusContent({
            type,
            invitationDate,
        });
        return (
            <>
                <strong>{title}</strong>
                {/* stopPropagation allows helpLinks to be clicked */}
                <Text onClick={(e) => e.stopPropagation()}>{text}</Text>
                {hasButton && (
                    <Button
                        isPrimary
                        isSmall
                        isFull
                        isDisabled={isSending || isSent}
                        isLoading={isSending}
                        onClick={(evt) => {
                            onClick?.(evt);
                        }}
                    >
                        {cta}
                    </Button>
                )}
            </>
        );
    };

    return (
        <Container>
            <Tooltip
                content={statusContent}
                position="bottom right"
                offset={[0, 0]}
                {...{ enableTooltip, hoverable }}
            >
                {statusLabel}
            </Tooltip>
        </Container>
    );
};

export default StatusIndicator;
