import { useMemo } from 'react';
import _capitalize from 'lodash/capitalize.js';
import { useUser, useMeetingById } from '~hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateWorkspaceAccess,
    deleteWorkspaceAccess,
} from '~modules/workspace/workspace.actions';
import {
    grantLevelToGrants,
    grantsToGrantLevel,
} from '~shared/permissions/permission.helper';
import {
    getPermissionsSelector,
    getOccurrenceAccessLevelNonMemoized,
} from '~modules/permission/permission.selectors';
import {
    getMeetingAccessLevel,
    getWorkspaceAccessLevel,
} from '~modules/permission/permission.helpers';
import { getTargetType } from '~modules/notification/notification.helpers';
import {
    GRANT_LEVEL_ADMIN,
    GRANT_LEVEL_MEMBER,
    GRANT_LEVEL_GUEST,
    PERMISSION_TYPE_WORKSPACE,
    PERMISSION_TYPE_MEETING,
} from '~common/constants';

/**
 * Determine if a user has the ability to edit a workspace user
 */
const useHasWorkspaceUserEditPermissions = ({
    grantLevel,
    member,
    workspace,
}) => {
    // Users can't change their own permissions
    const loggedInUser = useUser();
    const isCurrentUser = loggedInUser.id === member.id;
    if (isCurrentUser) return false;

    const { isOwner, isAdmin } = workspace.myAccessLevel;
    // Owner can change anyone
    if (isOwner) return true;
    // Admin can change guest/member/admin
    if (
        isAdmin &&
        [GRANT_LEVEL_ADMIN, GRANT_LEVEL_MEMBER, GRANT_LEVEL_GUEST].includes(
            grantLevel
        )
    ) {
        return true;
    }
    return false;
};

/**
 * Edit the permissions of a workspace user
 */
const useWorkspaceEditPermissions = () => {
    const dispatch = useDispatch();
    return ({ newValue, workspace, member }) => {
        const grants = grantLevelToGrants(newValue);
        dispatch(updateWorkspaceAccess(workspace.id, member, grants));
    };
};

/**
 * Remove a user from a workspace
 */
const useWorkspaceDeleteUser = () => {
    const dispatch = useDispatch();
    return (workspace, member, replacementData) =>
        dispatch(deleteWorkspaceAccess(workspace, member, replacementData));
};

/**
 * Get the current users meeting access level
 */
const useLoggedInUserMeetingAccessLevel = (meetingId) => {
    // returns a memoized version of the logged-in user's access to a meeting
    // only if permissions change, recalculate the meeting access level
    // This is important because getMeetingAccessLevel creates a new object each time it is called
    const permissions = useSelector(getPermissionsSelector);

    return useMemo(
        () => (permissions.length ? getMeetingAccessLevel(meetingId) : []),
        [permissions, meetingId]
    );
};

/**
 * Get the current users workspace access level
 */
const useLoggedInUserWorkspaceAccessLevel = (workspaceId) => {
    // returns a memoized version of the logged-in user's access to a workspace
    // only if permissions change, recalculate the meeting access level
    // This is important because getWorkspaceAccessLevel creates a new object each time it is called
    const permissions = useSelector(getPermissionsSelector);

    return useMemo(
        () => (permissions.length ? getWorkspaceAccessLevel(workspaceId) : []),
        [permissions, workspaceId]
    );
};

/**
 * Get a single permission by its id
 */
const usePermissionById = (id) => {
    const permissions = useSelector(getPermissionsSelector);
    const permission = permissions.find((o) => o.id === id);
    return permission;
};

/**
 * Get a single permission by its meeting id
 * Returns a scoped occurrence permission if available with fallback to meeting
 */
const useOccurrenceOrMeetingPermissionByMeetingId = (meetingId) => {
    const { nonScopedView } = getMeetingAccessLevel(meetingId);
    const permissions = useSelector(getPermissionsSelector);

    if (nonScopedView) {
        return permissions.find((p) => p.target === meetingId && !p.scope);
    }
    // If a user has been given None access to an occurrence, they still have a scoped meeting permission. It's likely they have a single occurrence permission, but if they have multiple bring back the first one with a 'canView' permission for use in the invite modal.
    const occurrenceIds = permissions
        .filter((p) => p.target === meetingId && p.scope)
        .map((p) => p.scope.target);
    const occurrenceWithView = occurrenceIds.find((occurrenceId) => {
        const { canView } = getOccurrenceAccessLevelNonMemoized({
            occurrence: occurrenceId,
        });
        return canView;
    });
    return permissions.find((p) => p.target === occurrenceWithView);
};

/**
 * Get the current users role from a notification
 */
const useRoleFromNotification = (notification) => {
    const type = getTargetType(notification);

    const workspacePermission = usePermissionById(
        notification?.meta?.permissionId
    );
    const meetingPermission = useOccurrenceOrMeetingPermissionByMeetingId(
        notification?.meta?.meeting
    );

    if (type === 'workspace') {
        if (!workspacePermission) return;
        return _capitalize(
            grantsToGrantLevel(workspacePermission.grants, true)
        );
    }

    if (type === 'meeting') {
        if (!meetingPermission) return;
        return _capitalize(grantsToGrantLevel(meetingPermission.grants));
    }
};

/**
 * Get the title of the meeting/workspace from a notification (the meeting isn't contained in the notification meta)
 */
const useTargetTitlesFromNotification = (notification) => {
    const type = getTargetType(notification);
    const meeting = useMeetingById(notification?.meta?.meeting);
    if (![PERMISSION_TYPE_WORKSPACE, PERMISSION_TYPE_MEETING].includes(type))
        return;

    return {
        ...(type === 'meeting' && { titleMeeting: meeting?.title }), // Meeting / Occurrence
        titleWorkspace: notification?.meta?.targetTitle,
    };
};

export {
    useHasWorkspaceUserEditPermissions,
    useWorkspaceEditPermissions,
    useWorkspaceDeleteUser,
    useLoggedInUserMeetingAccessLevel,
    useLoggedInUserWorkspaceAccessLevel,
    useRoleFromNotification,
    useTargetTitlesFromNotification,
};
