import React from 'react';
import ReduxToastr, { actions, reducer } from 'react-redux-toastr';
import tw, { styled, css } from 'twin.macro';
import { zIndex } from '~common/style.helpers';
import { LG_UP } from '~common/media.helpers';

const animationStyles = css`
    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    @keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
    .toastr.fadeIn {
        animation-name: fadeIn;
        animation-duration: 0.7s;
    }
    .toastr.fadeOut {
        animation-name: fadeOut;
        animation-duration: 0.3s;
    }
`;

const colorStyles = css`
    .toastr.error {
        ${tw`text-error bg-error-solid`}
        a,
        button {
            ${tw`text-error-icon hover:text-error focus:(text-error outline-none)`};
        }
        .close-toastr,
        .rrt-holder {
            ${tw`text-error-icon`}
        }
        .close-toastr {
            ${tw`hocus:(text-black outline-none)`}
        }
        .rrt-progressbar {
            ${tw`bg-error-icon`}
        }
    }
    .toastr.success {
        ${tw`text-success bg-success-solid`}
        a,
        button {
            ${tw`text-success-icon hover:text-success focus:(text-success outline-none)`};
        }
        .close-toastr,
        .rrt-holder {
            ${tw`text-success-icon`}
        }
        .close-toastr {
            ${tw`hocus:(text-black outline-none)`}
        }
        .rrt-progressbar {
            ${tw`bg-success-icon`}
        }
    }
    .toastr.information {
        ${tw`text-information bg-information-solid`}
        a,
        button {
            ${tw`text-information-icon hover:text-information focus:(text-information outline-none)`};
        }
        .close-toastr,
        .rrt-holder {
            ${tw`text-information-icon`}
        }
        .close-toastr {
            ${tw`hocus:(text-black outline-none)`}
        }
        .rrt-progressbar {
            ${tw`bg-information-icon`}
        }
    }
`;

const layoutStyles = css`
    width: 354px;
    z-index: ${zIndex('TOASTS')};
    ${tw`fixed select-none`}
    left: 1rem;
    bottom: calc(var(--footer-horizontal-height, 0px) + 1rem);
    @media (${LG_UP}) {
        left: calc(var(--nav-vertical-width, 0px) + 1rem);
        bottom: 2rem;
    }
    .toastr {
        ${tw`mt-2 rounded focus:outline-none`}
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

        &[role='button']:hover,
        &[role='button']:focus {
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15),
                0 4px 6px -1px rgba(0, 0, 0, 0.1),
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
        }
    }
    .toastr > div {
        ${tw`flex relative`}
    }
    .rrt-title {
        ${tw`font-bold`}
    }
    .rrt-left-container,
    .rrt-middle-container,
    .rrt-right-container {
        ${tw`my-3 z-10`}
    }
    .rrt-left-container {
        ${tw`px-4 text-2xl`}
        flex-shrink: 0;
    }
    .rrt-middle-container {
        ${tw`flex flex-col w-full`}
    }
    .rrt-right-container {
        ${tw`px-2`}
        flex-shrink: 0;
    }
    .toastr-status {
        ${tw`hidden`}
    }
    .close-toastr {
        ${tw`cursor-pointer p-2 -mt-2`}
    }
    .rrt-progress-container {
        ${tw`top-0 left-0 right-0 absolute opacity-25`}
        height: 3px;
        .rrt-progressbar {
            ${tw`h-full rounded-t`}
        }
    }
`;

const Container = styled.div`
    ${layoutStyles}
    ${animationStyles}
    ${colorStyles}
`;

const Alert = () => (
    <Container id="toaster-messages">
        <ReduxToastr
            newestOnTop
            preventDuplicates
            getState={(state) => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
        />
    </Container>
);

export { Alert as default, actions, reducer };
