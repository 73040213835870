import _difference from 'lodash/difference.js';
import _slice from 'lodash/slice.js';
import { createBrowserHistory as createHistory } from 'history';

const history = createHistory();

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line unicorn/better-regex
const REG = /\/([_\-\w]+)/gi;

export function scrollMiddleware(store) {
    return function (next) {
        return function (action) {
            let needsScroll = false;

            if (action.type === '@@router/LOCATION_CHANGE') {
                const oldP = store.getState().router.location.pathname;
                const newP = action.payload.location.pathname;
                const oldPath = _slice(oldP.match(REG), 0, 2);
                const newPath = _slice(newP.match(REG), 0, 2);

                const diff = _difference(oldPath, newPath);

                if (diff.length) {
                    needsScroll = true;
                } else if (
                    oldPath[0] === 'occurrence' &&
                    newPath[0] === 'occurrence' &&
                    oldPath[2] !== newPath[2]
                ) {
                    needsScroll = true;
                }
            }

            next(action);

            try {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line unicorn/prefer-query-selector
                const scrollTarget = document.getElementById(
                    'js-top-content-panel-element'
                );

                if (needsScroll && scrollTarget) {
                    // console.warn('setting scrollTop in scrollMiddleware');
                    scrollTarget.scrollTop = 0;
                }
            } catch {
                // noop
            }
        };
    };
}

export default history;
export {
    getBaseAppHomepagePath,
    isViewingBaseAppHomepage,
    isViewingHomepage,
    getDashboardPath,
    getDashboardParams,
    getDashboardPathParams,
    getMeetingPathParams,
    getTemplatePathParams,
    getTemplateSlug,
    getWorkspacePathParams,
    getWorkspaceUsageSlug,
    getWorkspaceUpdateSlug,
    isDetailPage,
    isViewingOccurrence,
    isViewingMeeting,
    pageViewDetails,
    parseQueryString,
    addParamToSearch,
    removeParamFromSearch,
    updateParamInSearch,
    getMeetingSlug,
    getOccurrenceSlug,
    getDashboardSlug,
    getWorkspaceSlug,
    getWorkspaceMemberSlug,
    isAnyDashboardPage,
    isDashboardAllPage,
    isDashboardSchedulePage,
    isIntegrationCalendarSettingsPage,
    pushTo,
    replaceTo,
} from './navigation.helpers.js';
