import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageVisibility, usePrevious } from '~hooks';
import { removeAlert } from './alert.actions.js';

const HideAlerts = () => {
    const dispatch = useDispatch();
    const isVisible = usePageVisibility();
    const wasVisible = usePrevious(isVisible);

    const toasts = useSelector((state) => state.toastr.toastrs);

    useEffect(() => {
        // we only want to run this effect if the visibility of the app changes
        if (wasVisible === isVisible) return;

        if (isVisible) {
            // App has now become visible, from being in the background
            // If a toast has a timeout value on it, hide it as timeout has likely passed, or message is no longer relevant
            // This is to cater for toast messages that do not automatically close when they should.
            const toastsToHide = toasts.filter(
                (toast) => toast?.options?.timeOut > 0
            );
            toastsToHide.forEach((toast) =>
                dispatch(removeAlert({ id: toast.id }))
            );
        }
    }, [dispatch, wasVisible, isVisible, toasts]);

    return null;
};

export default HideAlerts;
