import React from 'react';
import tw, { styled } from 'twin.macro';
import { pluralize } from '~shared/utils';
import {
    getUpgradeLink,
    getUserCantUpgradeMessage,
} from '~modules/feature/feature.helpers';
import { PLUS_FEATURE_TRIAL_PERIOD_DAYS } from '~common/constants';
import { Tooltip } from '~modules/common/components';
import PrintPreviewFeatureLabel from '~modules/feature/PrintPreviewFeatureLabel';

const Label = styled.div(({ isTrial, trialFeatureHasExpired }) => [
    tw`relative rounded-sm mt-0.5 p-1 text-2xs uppercase leading-none select-none cursor-pointer tracking-wide align-middle`,
    // :before element used to prevent glitchy behaviour on hover
    tw` before:(absolute -top-0.5 -right-0.5 -bottom-0.5 -left-0.5)`,
    isTrial
        ? trialFeatureHasExpired
            ? tw`bg-information-solid text-information-icon`
            : tw`bg-information-icon text-white`
        : tw`bg-secondary-light text-secondary`,
]);

const FeatureLabel = ({
    isTrialFeature,
    trialFeatureHasStarted,
    trialFeatureHasExpired,
    daysRemainingForPlusFeature,
    workspace,
    featureDescription,
    tooltipProps,
    close,
}) => {
    const upgradeLink = getUpgradeLink({ workspace, close });
    const isWorkspaceOwner = workspace?.myAccessLevel?.isOwner;

    const trialTooltipContent =
        trialFeatureHasStarted || trialFeatureHasExpired ? (
            <div>
                This <strong>Plus plan</strong> feature trial{' '}
                {trialFeatureHasExpired ? (
                    'has expired.'
                ) : (
                    <>
                        expires in{' '}
                        <strong>{daysRemainingForPlusFeature}</strong>{' '}
                        {pluralize('day', 'days', daysRemainingForPlusFeature)}.
                    </>
                )}{' '}
                {isWorkspaceOwner ? (
                    upgradeLink
                ) : (
                    <>
                        {getUserCantUpgradeMessage(workspace)}{' '}
                        <strong>Upgrade to Plus</strong>
                    </>
                )}{' '}
                for ongoing access.
            </div>
        ) : (
            <div tw="space-y-2">
                {featureDescription}
                <div>
                    Trial this <strong>Plus plan</strong> feature free for{' '}
                    {PLUS_FEATURE_TRIAL_PERIOD_DAYS}&nbsp;days.
                </div>
            </div>
        );

    const label = (
        <Label isTrial={isTrialFeature} {...{ trialFeatureHasExpired }}>
            {isTrialFeature
                ? trialFeatureHasExpired
                    ? 'plus'
                    : 'trial'
                : 'new'}
        </Label>
    );

    if (tooltipProps?.isPrintPreview) {
        return (
            <PrintPreviewFeatureLabel content={tooltipProps?.content}>
                {label}
            </PrintPreviewFeatureLabel>
        );
    }

    return (
        <Tooltip
            offset={[0, 1]}
            content={trialTooltipContent} // Default content
            position="bottom right"
            hoverable={isWorkspaceOwner}
            {...tooltipProps}
        >
            {label}
        </Tooltip>
    );
};

export default FeatureLabel;
