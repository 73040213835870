import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m4.2791 21.868-2.2791 8.1319 8.2049-2.7106m-5.9258-5.4213s12.052-11.945 17.432-17.157m-17.432 17.157s1.8233 0.1952 3.6466 1.8169c1.8233 1.6216 2.2791 3.6044 2.2791 3.6044m0 0s11.573-11.607 16.866-16.899m-5.3592-5.6802c0.9844-0.95378 1.7454-1.6822 2.1684-2.0708 2.735-2.5128 7.9487 3.0206 5.47 5.4774-0.4132 0.40951-1.2205 1.2152-2.2792 2.2736m-5.3592-5.6802s1.7344 0 3.5359 1.8071c2.0372 2.0435 1.8233 3.8731 1.8233 3.8731"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.2'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
