import _get from 'lodash/get.js';
import _isEqual from 'lodash/isEqual.js';
import _pull from 'lodash/pull.js';
import _unset from 'lodash/unset.js';
import qs from 'querystring';

/**
 * Parse a query string into a plain JavaScript object
 * @param {String} queryString
 * e. g. 'foo=bar&abc=xyz&abc=123'
 *   which becomes:
 *     {
 *       foo: 'bar',
 *       abc: ['xyz', '123']
 *     }
 */
const parseQueryString = (queryString = '') =>
    qs.parse(queryString.replace(/^\?/, ''));

export { parseQueryString };

/**
 * Make a query string from plain object
 * @param {Object} obj Plain JavaScript object whose key/value pairs will be converted into a queryString
 */
export const makeQueryStringFromObject = (obj) => qs.stringify(obj);

/**
 * Remove parameters from the Query String in a react-router location object
 * @param {Location} location react-router location object
 * @param {Object} params Plain JavaScript object whose key/value pairs will be removed from the query string in the location parameter
 * e.g. { abc: 'xyz' }
 * e.g. { abc: [ 'xyz', '123' ]}
 */
export function removeFromQueryString(location, params) {
    const queryString = location?.search;
    // queryString: e. g. 'foo=bar&abc=xyz&abc=123'
    // queryString: e. g. '?foo=bar&abc=xyz&abc=123'
    const qs = parseQueryString(queryString);

    const removeItem = (key, value) => {
        if (Array.isArray(qs[key])) {
            // pull the item from the array
            _pull(qs[key], value);

            // remove an empty array
            if (!qs[key].length) {
                _unset(qs, key);
            }
        } else {
            // remove the key
            _unset(qs, key);
        }
    };

    for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
            value.forEach((v) => removeItem(key, v));
        } else {
            removeItem(key, value);
        }
    }

    const prefix = queryString.charAt(0) === '?' ? '?' : '';
    return `${prefix}${makeQueryStringFromObject(qs)}`;
}

/**
 * Add parameters to the Query String in a react-router location object
 * @param {Location} location react-router location object
 * @param {Object} params Plain JavaScript object whose key/value pairs will be added to the query string in the location parameter
 * e.g. { abc: 'xyz' }
 * e.g. { abc: [ 'xyz', '123' ]}
 */
export function addToQueryString(location, params) {
    const queryString = _get(location, 'search');
    // queryString: e. g. 'foo=bar&abc=xyz&abc=123'
    // queryString: e. g. '?foo=bar&abc=xyz&abc=123'
    const qs = parseQueryString(queryString);

    const addItem = (key, value) => {
        if (!qs[key]) {
            qs[key] = value;
            return;
        }
        if (Array.isArray(qs[key])) {
            // push the new item onto the array
            qs[key].push(value);
        } else {
            // convert to an array and add the new key
            qs[key] = [qs[key], value];
        }
    };

    for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
            value.forEach((v) => addItem(key, v));
        } else {
            addItem(key, value);
        }
    }

    const prefix = queryString.charAt(0) === '?' ? '?' : '';
    return `${prefix}${makeQueryStringFromObject(qs)}`;
}

/**
 * Update parameters in the query string. If they are not there, add them.
 * @param {Location} location react-router location object
 * @param {Object} params Plain JavaScript object whose key/value pairs will be updated or added to the query string in the location parameter
 * e.g. { abc: 'xyz' }
 * e.g. { abc: [ 'xyz', '123' ]}
 */
export function updateInQueryString(location, params) {
    const queryString = _get(location, 'search');
    // queryString: e. g. 'foo=bar&abc=xyz&abc=123'
    // queryString: e. g. '?foo=bar&abc=xyz&abc=123'
    const qs = parseQueryString(queryString);

    const updateOrAddItem = (key, value) => {
        qs[key] = value;
    };

    for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
            value.forEach((v) => updateOrAddItem(key, v));
        } else {
            updateOrAddItem(key, value);
        }
    }

    const prefix = queryString.charAt(0) === '?' ? '?' : '';
    return `${prefix}${makeQueryStringFromObject(qs)}`;
}

/**
 * Add parameters to the Query String in a react-router location object
 * @param {Location} location react-router location object
 * @param {*} params Plain JavaScript object whose key/value pairs will be added to the query string in the location parameter
 * e.g. { abc: 'xyz' }
 * e.g. { abc: [ 'xyz', '123' ]}
 */
export function addParamToSearch(location, params) {
    const qs = addToQueryString(location, params);
    return updateQueryString(location, qs);
}

/**
 * Remove parameters from the Query String in a react-router location object
 * @param {Location} location react-router location object
 * @param {Object} params Plain JavaScript object whose key/value pairs will be added to the query string in the location parameter
 * e.g. { abc: 'xyz' }
 * e.g. { abc: [ 'xyz', '123' ]}
 */
export function removeParamFromSearch() {
    let loc, params;
    switch (arguments.length) {
        case 2:
            [loc, params] = arguments;
            break;
        default:
            [params] = arguments;
            loc = location;
    }

    const prevQs = loc?.search;
    const qs = removeFromQueryString(loc, params);
    if (_isEqual(prevQs, qs)) return loc;
    return updateQueryString(loc, qs);
}

/**
 *
 * @param {*} location
 * @param {*} params
 */
export function updateParamInSearch(location, params) {
    const qs = updateInQueryString(location, params);
    return updateQueryString(location, qs);
}

/**
 * Update a react-router Location object with a new query string
 * @param {Location} location react-router location object
 * @param {String} search New query string
 */
export const updateQueryString = (location, search) => ({
    ...location,
    search,
});
