import _reject from 'lodash/reject.js';
import _uniqBy from 'lodash/uniqBy.js';
import {
    ADD_GROUP_REQUEST,
    ADD_GROUP_WEBSOCKET,
    EDIT_GROUP_WEBSOCKET,
    DELETE_GROUP_WEBSOCKET,
    ADD_GROUP_SUCCESS,
    ADD_GROUP_ERROR,
    EDIT_GROUP_REQUEST,
    EDIT_GROUP_ERROR,
    EDIT_GROUP_SUCCESS,
    DELETE_GROUP_REQUEST,
    DELETE_GROUP_ERROR,
    FETCH_GROUPS_SUCCESS,
    STAR_MEETING_TOGGLE_SUCCESS,
    MODAL_GROUP_OPEN,
    MODAL_GROUP_CLOSE,
    MEETING_CHANGE_GROUP,
    MEETING_CHANGE_GROUP_ERROR,
    OCCURRENCE_GROUP_ADD,
    OCCURRENCE_GROUP_ADD_ERROR,
    OCCURRENCE_GROUP_REMOVE,
    OCCURRENCE_GROUP_REMOVE_ERROR,
    WORKSPACE_ADD,
    WORKSPACE_EDIT,
    WORKSPACE_ACCESS_REMOVED,
    LOGOUT_SUCCESS,
} from '~common/action.types';

const initialState = {
    groups: [],
    modal: {
        isOpen: false,
    },
};

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case STAR_MEETING_TOGGLE_SUCCESS: {
            const group = {
                ...action.group,
            };
            const groups = [...state.groups].map((g) =>
                g.id === group.id ? group : g
            );

            return {
                ...state,
                groups,
            };
        }

        case FETCH_GROUPS_SUCCESS: {
            return {
                ...state,
                groups: [...action.groups],
            };
        }

        case EDIT_GROUP_SUCCESS: {
            const modal = {
                isOpen: false,
            };

            return {
                ...state,
                modal,
            };
        }

        case WORKSPACE_ADD:
        case WORKSPACE_EDIT:
        case EDIT_GROUP_REQUEST:
        case EDIT_GROUP_WEBSOCKET: {
            const actionGroup = action.group || action.workspace;

            // group may already exist, but not in the user's store.
            // This can happen with a workspace group when a user first accepts permission to it
            const existing = state.groups.find(
                (group) => group.id === actionGroup.id
            );

            const groups = existing
                ? _uniqBy(
                      [{ ...existing, ...actionGroup }, ...state.groups],
                      'id'
                  )
                : [...state.groups, actionGroup];

            return { ...state, groups };
        }

        case ADD_GROUP_SUCCESS: {
            return {
                ...state,
                modal: { isOpen: false },
            };
        }

        case DELETE_GROUP_ERROR:
        case ADD_GROUP_REQUEST:
        case ADD_GROUP_WEBSOCKET: {
            // GOTCHA: groups are optimistically added and meetings moved into them.
            // Therefore group may already exist in store, and we want to keep the meetings/occurrences already in that group.
            const group = state.groups.find((g) => g.id === action.group.id);
            if (group) {
                const newGroup = {
                    ...group,
                    ...action.group,
                    meetings: group.meetings,
                    occurrences: group.occurrences,
                };
                return {
                    ...state,
                    groups: _uniqBy([newGroup, ...state.groups], 'id'),
                };
            }

            return {
                ...state,
                groups: _uniqBy([action.group, ...state.groups], 'id'),
            };
        }

        case WORKSPACE_ACCESS_REMOVED: {
            // workspace has been deleted / removed. delete any and all groups associated with this workspace.
            // this includes the workspace itself, and any meetingGroups associated with it.
            return {
                ...state,
                groups: state.groups.filter(
                    (group) =>
                        group.id !== action.workspace?.id &&
                        group.workspaceId !== action.workspace?.id
                ),
            };
        }

        case DELETE_GROUP_WEBSOCKET:
        case DELETE_GROUP_REQUEST:
        case ADD_GROUP_ERROR: {
            const group = state.groups.find((g) => g.id === action.group.id);
            if (!group) {
                return state;
            }
            return {
                ...state,
                groups: _reject(state.groups, { id: action.group.id }),
            };
        }

        case EDIT_GROUP_ERROR: {
            if (!action.group) return state;
            return {
                ...state,
                groups: [
                    action.group,
                    ..._reject(state.groups, { id: action.group.id }),
                ],
            };
        }

        case MODAL_GROUP_CLOSE: {
            return {
                ...state,
                modal: {
                    isOpen: false,
                },
            };
        }

        case MODAL_GROUP_OPEN: {
            const { group } = action;
            return {
                ...state,
                modal: {
                    isOpen: true,
                    group,
                },
            };
        }

        case MEETING_CHANGE_GROUP: {
            // This is optimistic update of Store, to move a meeting to another group
            const { meetingId, fromGroup, toGroup } = action;
            return {
                ...state,
                groups: state.groups.map((g) => {
                    if (g.id === fromGroup?.id) {
                        // remove meeting from this group
                        return {
                            ...g,
                            meetings: _reject(g.meetings, { meetingId }),
                        };
                    } else if (g.id === toGroup.id) {
                        // add meeting to this group
                        return {
                            ...g,
                            meetings: _uniqBy(
                                [...g.meetings, { meetingId }],
                                'meetingId'
                            ),
                        };
                    } else {
                        return g;
                    }
                }),
            };
        }
        case MEETING_CHANGE_GROUP_ERROR: {
            // There has been an error in the moving of the meeting in a group.
            // So reverse the optimistic update
            const { meetingId, fromGroup, toGroup } = action;
            return {
                ...state,
                groups: state.groups.map((g) => {
                    if (g.id === fromGroup?.id) {
                        // add meeting to this group
                        return {
                            ...g,
                            meetings: _uniqBy(
                                [...g.meetings, { meetingId }],
                                'meetingId'
                            ),
                        };
                    } else if (g.id === toGroup.id) {
                        // remove meeting from this group
                        return {
                            ...g,
                            meetings: _reject(g.meetings, { meetingId }),
                        };
                    } else {
                        return g;
                    }
                }),
            };
        }

        case OCCURRENCE_GROUP_REMOVE_ERROR:
        case OCCURRENCE_GROUP_ADD: {
            // add occurrence to group's occurrences
            // action.group, action.occurrenceId
            const { occurrenceId, group } = action;
            return {
                ...state,
                groups: state.groups.map((g) => {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line unicorn/prefer-ternary
                    if (g.id === group.id) {
                        // add meeting to this group
                        return {
                            ...g,
                            occurrences: _uniqBy(
                                [...g.occurrences, { occurrenceId }],
                                'occurrenceId'
                            ),
                        };
                    } else {
                        return g;
                    }
                }),
            };
        }

        case OCCURRENCE_GROUP_REMOVE:
        case OCCURRENCE_GROUP_ADD_ERROR: {
            // remove occurrence from group's occurrences
            // action.group, action.occurrenceId
            const { occurrenceId, group } = action;
            return {
                ...state,
                groups: state.groups.map((g) => {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line unicorn/prefer-ternary
                    if (g.id === group.id) {
                        // remove meeting from this group
                        return {
                            ...g,
                            occurrences: _reject(g.occurrences, {
                                occurrenceId,
                            }),
                        };
                    } else {
                        return g;
                    }
                }),
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default groupReducer;
