import sanitizeHtml from 'sanitize-html';

const stripHtml = (value) =>
    sanitizeHtml(value, {
        allowedTags: [],
        allowedAttributes: {},
        textFilter: function (value) {
            // Prevent html entities being displayed
            return value
                .replace(/&amp;/g, '&')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<');
        },
    });

const color = [
    /^#(0x)?[\da-f]+$/i,
    /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
    /^rgba\((\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*|(var\(--.+\))),\s*((var\(--.+\))|1|0.\d+)\s*\)$/, // Css Variables / Tailwind color / Color with opacity 100%
    /^(?:[a-z]|-)+$/, // allows named colors such as transparent, green, silver etc...
]; // hex or rgb
const VALIDATOR_CSS_UNIT = [
    /(([+-]?[\d.]+)(em|ex|px|in|cm|mm|pt|pc|%)?)|0|auto/,
];
const VALIDATOR_ANYTHING = [/.*/];
const VALIDATOR_CSS_TEXT_ALIGN = [
    /^left$/,
    /^right$/,
    /^center$/,
    /^justify$/,
    /^justify-all$/,
    /^start$/,
    /^end$/,
];
const VALIDATOR_CSS_FLOAT_ALIGN = [/^left$/, /^right$/];
const VALIDATOR_CSS_VERTICAL_ALIGN = [
    /^top$/,
    /^middle$/,
    /^bottom$/,
    /^baseline$/,
];
const VALIDATOR_CSS_WHITESPACE = [
    /^normal$/,
    /^nowrap$/,
    /^pre$/,
    /^pre-wrap$/,
    /^pre-line$/,
    /^break-spaces$/,
];

const tableBorders = {
    border: VALIDATOR_ANYTHING,
    'border-top': VALIDATOR_ANYTHING,
    'border-right': VALIDATOR_ANYTHING,
    'border-bottom': VALIDATOR_ANYTHING,
    'border-left': VALIDATOR_ANYTHING,
    'border-width': VALIDATOR_ANYTHING,
    'border-top-width': VALIDATOR_ANYTHING,
    'border-right-width': VALIDATOR_ANYTHING,
    'border-bottom-width': VALIDATOR_ANYTHING,
    'border-left-width': VALIDATOR_ANYTHING,
    'border-color': VALIDATOR_ANYTHING,
    'border-top-color': VALIDATOR_ANYTHING,
    'border-right-color': VALIDATOR_ANYTHING,
    'border-bottom-color': VALIDATOR_ANYTHING,
    'border-left-color': VALIDATOR_ANYTHING,
    'border-style': VALIDATOR_ANYTHING,
    'border-top-style': VALIDATOR_ANYTHING,
    'border-right-style': VALIDATOR_ANYTHING,
    'border-bottom-style': VALIDATOR_ANYTHING,
    'border-left-style': VALIDATOR_ANYTHING,
};

const padding = {
    padding: VALIDATOR_ANYTHING,
    'padding-left': VALIDATOR_CSS_UNIT,
    'padding-right': VALIDATOR_CSS_UNIT,
    'padding-top': VALIDATOR_CSS_UNIT,
    'padding-bottom': VALIDATOR_CSS_UNIT,
};

const allowedStyles = {
    ...Object.fromEntries(
        ['h1', 'h2', 'h3', 'h4', 'h5'].map((heading) => [
            heading,
            { 'text-align': VALIDATOR_CSS_TEXT_ALIGN, color },
        ])
    ),
    blockquote: {
        'margin-left': VALIDATOR_CSS_UNIT,
    },
    code: { color },
    img: {
        display: [/^block$/],
        float: [/^left|right$/],
        'margin-left': [/^auto$/],
        'margin-right': [/^auto$/],
    },
    li: {
        'list-style-type': [/^none$/],
        'text-align': VALIDATOR_CSS_TEXT_ALIGN,
        color,
    },
    ol: {
        'list-style-type': [/^decimal|lower-alpha|lower-roman$/],
    },
    ul: {
        'list-style-type': [/^disc|circle|square$/],
    },
    p: {
        'margin-left': VALIDATOR_CSS_UNIT,
        'text-align': VALIDATOR_CSS_TEXT_ALIGN,
    },
    span: {
        'background-color': color,
        background: color,
        color,
        'text-decoration': VALIDATOR_ANYTHING,
        'font-weight': VALIDATOR_ANYTHING,
        'font-style': VALIDATOR_ANYTHING,
    },
    table: {
        background: VALIDATOR_ANYTHING,
        'background-color': color,
        'box-shadow': VALIDATOR_ANYTHING,
        'border-collapse': [/^collapse$/, /^separate$/],
        'border-spacing': VALIDATOR_ANYTHING,
        color: VALIDATOR_ANYTHING,
        float: VALIDATOR_CSS_FLOAT_ALIGN,
        margin: VALIDATOR_ANYTHING,
        'margin-left': VALIDATOR_CSS_UNIT,
        'margin-right': VALIDATOR_CSS_UNIT,
        'margin-top': VALIDATOR_CSS_UNIT,
        'margin-bottom': VALIDATOR_CSS_UNIT,
        ...padding,
        ...tableBorders,
        'table-layout': [/^auto$/, /^fixed$/],
        'text-align': VALIDATOR_CSS_TEXT_ALIGN,
        'white-space': VALIDATOR_CSS_WHITESPACE,
        width: VALIDATOR_CSS_UNIT,
        height: VALIDATOR_CSS_UNIT,
    },
    tbody: {
        ...tableBorders,
    },
    tfoot: {
        ...tableBorders,
    },
    col: {
        width: VALIDATOR_CSS_UNIT,
    },
    td: {
        background: VALIDATOR_ANYTHING,
        'background-color': color,
        color: VALIDATOR_ANYTHING,
        'font-weight': VALIDATOR_ANYTHING,
        height: VALIDATOR_CSS_UNIT,
        'min-width': VALIDATOR_CSS_UNIT,
        ...padding,
        'margin-left': VALIDATOR_CSS_UNIT,
        ...tableBorders,
        'text-align': VALIDATOR_CSS_TEXT_ALIGN,
        'vertical-align': VALIDATOR_CSS_VERTICAL_ALIGN,
        'white-space': VALIDATOR_CSS_WHITESPACE,
        width: VALIDATOR_CSS_UNIT,
    },
    th: {
        'background-color': color,
        color: VALIDATOR_ANYTHING,
        'font-weight': VALIDATOR_ANYTHING,
        height: VALIDATOR_CSS_UNIT,
        ...padding,
        ...tableBorders,
        'text-align': VALIDATOR_CSS_TEXT_ALIGN,
        // 'text-shadow': VALIDATOR_ANYTHING,
        'vertical-align': VALIDATOR_CSS_VERTICAL_ALIGN,
        width: VALIDATOR_CSS_UNIT,
    },
    thead: {
        background: VALIDATOR_ANYTHING,
        ...tableBorders,
    },
    tr: {
        background: VALIDATOR_ANYTHING,
        'background-color': color,
        height: VALIDATOR_CSS_UNIT,
        ...padding,
        'text-align': VALIDATOR_CSS_TEXT_ALIGN,
        ...tableBorders,
        'vertical-align': VALIDATOR_CSS_VERTICAL_ALIGN,
    },
    pre: {
        'margin-left': VALIDATOR_CSS_UNIT,
        'text-align': VALIDATOR_CSS_TEXT_ALIGN,
    },
    iframe: {
        width: VALIDATOR_CSS_UNIT,
        height: VALIDATOR_CSS_UNIT,
        border: VALIDATOR_ANYTHING,
    },
    div: {
        width: VALIDATOR_CSS_UNIT,
        height: VALIDATOR_CSS_UNIT,
        'padding-bottom': VALIDATOR_CSS_UNIT,
        'margin-left': VALIDATOR_CSS_UNIT,
    },
};

const HTML_SANITIZE_CONFIG = {
    parser: {
        decodeEntities: false,
    },
    selfClosing: [],
    //https://github.com/apostrophecms/sanitize-html#default-options
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'ins',
        'img',
        'iframe',
    ]),
    allowedAttributes: {
        ...Object.fromEntries(
            ['h1', 'h2', 'h3', 'h4', 'h5'].map((heading) => [
                heading,
                ['style'],
            ])
        ),
        iframe: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'title',
            'src',
            'width',
            'height',
            'style',
            'scrolling',
            'class',
        ],
        blockquote: ['style'],
        code: ['style'],
        table: [
            'border',
            'cellpadding',
            'cellspacing',
            'data-snooker-col-series', // Table plugin feature attribute
            'data-snooker-locked-cols', // Table plugin feature attribute
            'height',
            'style',
            'width',
        ],
        tbody: ['style'],
        thead: ['style'],
        tfoot: ['style'],
        td: [
            'align',
            'colspan',
            'contenteditable',
            'height',
            'rowspan',
            'scope',
            'style',
            'width',
        ],
        tr: ['style'],
        th: ['style', 'colspan', 'rowspan', 'scope', 'width'],
        colgroup: ['style', 'span', 'align', 'width'],
        col: ['style', 'span', 'align', 'width'],
        ol: ['start', 'style'],
        ul: ['style'],
        li: ['style'],
        div: ['data-actionitem', 'class', 'style'],
        a: ['href', 'name', 'target', 'rel'],
        span: ['style'],
        p: ['dir', 'style'],
        pre: ['style'],
        img: ['src', 'width', 'height', 'alt', 'style'],
    },
    allowedStyles,
    allowedClasses: {
        div: ['action-item-block'],
        span: ['action-item-assignee'],
    },
    allowedSchemes: ['http', 'https', 'mailto', 'mongodb'],
    allowedSchemesAppliedToAttributes: ['href', 'src'],
};

export { stripHtml, allowedStyles, HTML_SANITIZE_CONFIG };
