import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m21.625 8.0315-11.678 10.338c-1.7123 1.4083-2.1503 3.7052-0.97824 5.1303 1.1721 1.4251 3.5104 1.4387 5.2227 0.0304l13.77-11.868c0.9518-0.923 1.5699-2.3876 1.6296-3.776 0.0596-1.3884-0.4851-2.7459-1.5143-3.7737s-2.4586-1.6418-3.9738-1.7069c-1.5151-0.06509-3.1883 0.66468-4.3019 1.6004l-14.531 12.656c-2.9772 2.9771-3.0001 7.7811-0.05126 10.73 2.9488 2.9489 7.4996 3.6072 11.25 0.326l2.8125-2.3438"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
