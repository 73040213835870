import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m19 3h-5c-1.1046 0-2 0.89543-2 2v6.125 0.375m7-8.5h5c1.1046 0 2 0.89543 2 2v6.125 0.375m-7-8.5v26h5c1.1046 0 2-0.8954 2-2v-6.5m-7 8.5h-5c-1.1046 0-2-0.8954-2-2v-6.5m0-9v8.2917 0.7083m0-9h14v8.2917 0.7083m-14 0h14"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m6 3v26"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
