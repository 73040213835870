import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M30 17.1552H2M2 17.1552L6.97778 12M2 17.1552L6.97778 22.3276"
            stroke="#A0AEC0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
