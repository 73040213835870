import { updateMeetingMeta } from '~client/reducers/helpers';
import {
    FETCH_PRIVATE_NOTES_SUCCESS,
    EDIT_PRIVATE_NOTES_SUCCESS,
    CREATE_PRIVATE_NOTES_SUCCESS,
    LOGOUT_SUCCESS,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
} from '~common/action.types';

const initialState = {
    privateNotes: [],
};

const privateNotesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRIVATE_NOTES_SUCCESS:
        case CREATE_PRIVATE_NOTES_SUCCESS: {
            return {
                ...state,
                privateNotes: [
                    ...state.privateNotes,
                    action.privateNotes,
                    ...(action.privateNotesList || []),
                ].filter(Boolean),
            };
        }

        case EDIT_PRIVATE_NOTES_SUCCESS: {
            return {
                ...state,
                privateNotes: state.privateNotes.map((n) => {
                    return n.id === action.privateNotes.id
                        ? action.privateNotes
                        : n;
                }),
            };
        }

        case MOVE_OCCURRENCE_DELETE: {
            if ((action.privateNoteIds?.length || 0) === 0) return state;
            if (
                !state.privateNotes.some((note) =>
                    action.privateNoteIds.includes(note.id)
                )
            )
                return state;

            return {
                ...state,
                privateNotes: state.privateNotes.filter(
                    (note) => !action.privateNoteIds.includes(note.id)
                ),
            };
        }

        case MOVE_OCCURRENCE_UPDATE: {
            if ((action.privateNoteIds?.length || 0) === 0) return state;
            if (
                !state.privateNotes.some((note) =>
                    action.privateNoteIds.includes(note.id)
                )
            )
                return state;

            const privateNotes = state.privateNotes.map((privateNote) => {
                if (!action.privateNoteIds.includes(privateNote.id))
                    return privateNote;

                return updateMeetingMeta(privateNote, action.meeting);
            });

            return {
                ...state,
                privateNotes,
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default privateNotesReducer;
