import _filter from 'lodash/filter.js';
import _findIndex from 'lodash/findIndex.js';
import _findLastIndex from 'lodash/findLastIndex.js';
import _find from 'lodash/find.js';
import _flatMap from 'lodash/flatMap.js';
import _fpcompose from 'lodash/fp/compose';
import _fpfilter from 'lodash/fp/filter';
import _fpflatMap from 'lodash/fp/flatMap';
import _fpmap from 'lodash/fp/map';
import _fpsortBy from 'lodash/fp/sortBy';
import _fpuniq from 'lodash/fp/uniq';
import _get from 'lodash/get.js';
import _last from 'lodash/last.js';
import _slice from 'lodash/slice.js';
import _remove from 'lodash/remove.js';
import _without from 'lodash/without.js';
import _uniq from 'lodash/uniq.js';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import ObjectID from 'bson-objectid';
import {
    getToday,
    getTimeNow,
    getGroupIdSelector,
} from '~common/selector.helpers';
import { dateEndOfDay, dateStartOfDay } from '~common/time.utils';
import {
    getOccurrencesSelector,
    mapOccurrenceForListDisplay,
    getOccurrenceAttendees,
} from '~modules/occurrence/occurrence.selectors';
import {
    meetingsSelector,
    meetingsAsObjectListSelector,
} from '~modules/meeting/meeting.selectors';
import { getWorkspacesSelector } from '../workspace/workspace.selectors.js';
import {
    getGroupSelector,
    getMeetingGroupsSelector,
    findGroupForMeeting,
    findGroupForOccurrence,
    getTagGroupsSelector,
} from '~modules/group/group.selectors';
import { getSearchParams } from '~modules/navigation/navigation.selectors';
import { isViewingBaseAppHomepage } from '~modules/navigation/navigation.helpers';
import {
    MEETING_TYPE_ARCHIVED,
    MEETING_TYPE_SINGLE,
    MEETING_TYPE_REPEATING,
    RECURRENCE_SCHEDULED,
    RECURRENCE_NOT_SCHEDULED,
    RECURRENCE_ONE_OFF,
    SEARCH_PARAM_FILTER_MEETINGS,
    SEARCH_PARAM_FILTER_EVENTS,
    EVENT_TYPE_HIDDEN,
    EVENT_TYPE_LINKED,
    SEARCH_PARAM_FILTER_TAGS,
    SEARCH_PARAM_FILTER_MEETING_TYPES,
    SEARCH_PARAM_PREV_MEETINGS_COUNT,
    SEARCH_PARAM_MORE_MEETINGS_COUNT,
    SEARCH_PARAM_MUST_INCLUDE_DATE,
    TODAY_PLACEHOLDER,
    RECURRENCE_NEVER,
    TODAY_ARCHIVED_PLACEHOLDER,
    ATTENDEE_ROLE_ATTENDEE,
    ATTENDEE_ROLE_OWNER,
} from '~common/constants';
import { getOccurrenceAccessLevel } from '~modules/permission/permission.helpers';
import {
    getEventsFromRemoteCalendars,
    getLinksForRemoteCalendarEvents,
    getUsersFromRemoteCalendars,
} from '~modules/remoteCalendar/remoteCalendar.selectors';

const specialDashboards = {
    '/meetings': {
        id: 'meetings',
        title: 'All meetings',
        hasColorPicker: false,
        hasEllipsisMenu: true,
        hasEditableTitle: false,
        isSyntheticGroup: true,
        addMeetingButton: true,
    },
    '/series': {
        id: 'series',
        title: 'All series',
        hasColorPicker: false,
        hasEllipsisMenu: true,
        hasEditableTitle: false,
        isSyntheticGroup: true,
        addMeetingButton: true,
    },
    '/templates': {
        id: 'templates',
        title: 'All templates',
        hasColorPicker: false,
        hasEllipsisMenu: false,
        hasEditableTitle: false,
        addMeetingButton: true,
    },
    '/workspaces': {
        id: 'workspaces',
        title: 'All workspaces',
        hasColorPicker: false,
        hasEllipsisMenu: false,
        hasEditableTitle: false,
        addMeetingButton: false,
    },
    '/search': {
        id: 'search',
        title: 'All meetings',
    },
    '/action-items': {
        id: 'action-items',
        title: 'My action items',
    },
    '/action-items/all': {
        id: 'all-action-items',
        title: 'All action items',
    },
};

export const getSpecialDashboard = (id) => {
    if (!id) return;
    for (const pathname in specialDashboards) {
        const dashLookup = specialDashboards[pathname];
        if (dashLookup?.id.replace('/', '') === id.replace('/', '')) {
            return {
                pathname,
                isActive: location?.pathname === pathname,
                ...dashLookup,
            };
        }
    }
};

export const getDashboardMeetings = (state, props) => {
    const group = getGroupSelector(state, props);
    const groupId = getGroupIdSelector(state, props);
    if (group || !groupId) {
        return getDashboardMeetingsForGroup(state, props);
    }
    return {};
};

/**
 * Get a list of meetings within a group (or all meetings if no group specified).
 * There is no filtering of archived / active meetings applied in this function.
 * @param {Object} state Redux state object.
 * @param {Object} props
 * @param {(Object|string)} [props.group] Group object or group.id. If not provided, will search meetings across all groups.
 * @param {boolean} [props.includeOnlyAccessibleMeetings=true] Only look at the accessible meetings within a Group. Required to ensure the getGroupSelector memoizes.
 * @returns {Object} groupMeetings: List of all meetings in the requested Group (or all groups), unfiltered.
 */
const getDashboardMeetingsFiltered = createSelector(
    meetingsSelector,
    getGroupSelector,
    (meetings, group) => {
        // If there is a group, then filter by the meetings in that group...
        // unless there are specific meetings selected in dashboardConfig filters.

        const _filterByMeetings = [];
        if (group) {
            // user is viewing a group/category, however no meetings are selected for filtering.
            // Therefore, show all meetings in the group
            _filterByMeetings.push(...group.meetings.map((g) => g.meetingId));
        }

        const groupMeetings = _filter(
            meetings,
            (meeting) => !group || _filterByMeetings.includes(meeting.id)
        );

        return {
            groupMeetings,
        };
    }
);

const getDashboardMeetingsForGroup = createSelector(
    getDashboardMeetingsFiltered,
    getOccurrencesSelector,
    getMeetingGroupsSelector,
    getTimeNow,
    getSearchParams,
    getWorkspacesSelector,
    (meetings, occurrences, meetingGroups, today, searchParams, workspaces) => {
        // meetings are a list of either all meetings (you have access to), or all meetings in the group
        const { [SEARCH_PARAM_FILTER_MEETING_TYPES]: filterByTypes } =
            searchParams;

        const hasArchivedFilter = (filterByTypes || []).includes(
            MEETING_TYPE_ARCHIVED
        );

        const now = new Date();
        const endOfToday = dateEndOfDay();
        const startOfToday = dateStartOfDay();

        const hasArchivedMeetings = meetings.groupMeetings.some(
            (meeting) => meeting.archived
        );

        const validMeetings = meetings.groupMeetings.filter(
            (meeting) =>
                (hasArchivedFilter && meeting.archived) ||
                (!hasArchivedFilter && !meeting.archived)
        );
        const validMeetingIds = validMeetings.map((meeting) => meeting.id);

        const sortedOccurrences = [...occurrences]
            .filter(
                (occurrence) =>
                    validMeetingIds.includes(occurrence.meeting) &&
                    !occurrence.archived
            )
            .sort(
                (a, b) =>
                    (a.meeting > b.meeting
                        ? 1
                        : b.meeting > a.meeting
                        ? -1
                        : 0) || a.startDate.valueOf() - b.startDate.valueOf()
            );

        const maxIndex = sortedOccurrences.length - 1;

        // aim is to keep only one row per meeting:
        // return true to keep this occurrence;
        // return false to discard the occurrence
        const groupOccurrences = sortedOccurrences.filter(
            (occurrence, index, occurrences) => {
                const nextOccurrenceOfThisMeeting =
                    index < maxIndex
                        ? occurrences[index + 1].meeting === occurrence.meeting
                            ? occurrences[index + 1]
                            : null
                        : null;
                const prevOccurrenceOfThisMeeting =
                    index > 0
                        ? occurrences[index - 1].meeting === occurrence.meeting
                            ? occurrences[index - 1]
                            : null
                        : null;

                // occurrence starts today or in the past
                if (occurrence.startDate <= endOfToday) {
                    if (
                        occurrence.endDate < startOfToday &&
                        nextOccurrenceOfThisMeeting
                    ) {
                        return false;
                    }
                    // if multiple today, ignore if this one has ended and the next one starts today
                    if (
                        occurrence.startDate >= startOfToday &&
                        nextOccurrenceOfThisMeeting &&
                        nextOccurrenceOfThisMeeting.startDate <= endOfToday &&
                        occurrence.endDate < now
                    ) {
                        return false;
                    }
                    // if multiple today, ignore if the previous one hasn't ended
                    if (
                        occurrence.startDate >= startOfToday &&
                        prevOccurrenceOfThisMeeting &&
                        prevOccurrenceOfThisMeeting.endDate >= startOfToday &&
                        prevOccurrenceOfThisMeeting.endDate > now
                    ) {
                        return false;
                    }

                    return true;
                }

                // occurrence starts after today
                return (
                    !prevOccurrenceOfThisMeeting ||
                    prevOccurrenceOfThisMeeting.startDate < endOfToday
                );
            }
        );

        const items = validMeetings
            .map((item) => {
                const group = findGroupForMeeting(meetingGroups, {
                    meeting: item,
                });
                const occurrence = _find(groupOccurrences, {
                    meeting: item.id,
                });

                if (!occurrence || !group.id) {
                    return null;
                }
                const workspace = workspaces.find(
                    (workspace) => workspace.id === group.workspaceId
                );

                return {
                    meeting: {
                        ...item,
                        isArchived: item.archived,
                        isRecurring: ![
                            RECURRENCE_NEVER,
                            RECURRENCE_NOT_SCHEDULED,
                        ].includes(item.pattern.occurs),
                        groupTitle: _get(group, 'title'),
                        accentColor: _get(group, 'accentColor'),
                    },
                    occurrence: mapOccurrenceForListDisplay({
                        meeting: item,
                        occurrence,
                        group,
                    }),
                    group,
                    workspace,
                    workspaceOrder: 1,
                };
            })
            .filter(Boolean)
            // sort by workspace (others alphabetically, then My workspace, then Shared with Me), then group (by its display order), then meeting title
            .sort((a, b) => {
                const aWorkspaceTitle = a.workspace?.title.toLowerCase();
                const bWorkspaceTitle = b.workspace?.title.toLowerCase();
                const workspaceTitleSort =
                    aWorkspaceTitle > bWorkspaceTitle
                        ? 1
                        : bWorkspaceTitle > aWorkspaceTitle
                        ? -1
                        : 0;
                const aCategoryTitle = a.group.title.toLowerCase();
                const bCategoryTitle = b.group.title.toLowerCase();
                const groupTitleSort =
                    aCategoryTitle > bCategoryTitle
                        ? 1
                        : bCategoryTitle > aCategoryTitle
                        ? -1
                        : 0;
                const aMeetingTitle = a.meeting.title.toLowerCase();
                const bMeetingTitle = b.meeting.title.toLowerCase();
                const meetingTitleSort =
                    aMeetingTitle > bMeetingTitle
                        ? 1
                        : bMeetingTitle > aMeetingTitle
                        ? -1
                        : 0;

                return (
                    a.workspaceOrder - b.workspaceOrder ||
                    workspaceTitleSort ||
                    a.group.displayOrder - b.group.displayOrder ||
                    groupTitleSort ||
                    meetingTitleSort
                );
            });

        const itemsByGroup = [];
        // create an array by group, with an 'items' property of all the meetings in that group.
        items.forEach((item, index, items) => {
            if (index === 0 || item.group.id !== items[index - 1].group.id) {
                itemsByGroup.push({
                    ...item.group,
                    items: [item],
                    group: item.group,
                    workspace: item.workspace,
                });
            } else {
                itemsByGroup[itemsByGroup.length - 1].items.push(item);
            }
        });

        return {
            items,
            itemsByGroup, //: itemsByGroup.length > 1 && itemsByGroup, // only return this to the underlying component if there is more than 1 group.
            hasArchivedFilter,
            hasArchivedMeetings,
            hasNoMeetings: !items.length,
        };
    }
);

export const filterOccurrences = ({
    occurrences,
    group,
    groups,
    filterByMeetings,
    filterByTags,
    filterByTypes,
}) => {
    const _filterByMeetings = filterByMeetings || [],
        _filterByTags = filterByTags || [],
        _filterByTypes =
            typeof filterByTypes === 'string'
                ? [filterByTypes]
                : filterByTypes || [];

    if (group && !_filterByMeetings.length) {
        // user is viewing a group/category, however no meetings are selected for filtering.
        // Therefore, show all meetings in the group
        _filterByMeetings.push(...group.meetings.map((g) => g.meetingId));
    }

    // get a list of all occurrences with the tags specified in the filter
    const occurrencesWithTags = _fpcompose(
        _fpflatMap((g) => _flatMap(g.occurrences, (o) => o.occurrenceId)),
        _fpfilter((g) => _filterByTags.includes(g.id))
    )(groups);

    const isFilterByType = _filterByTypes.filter((type) =>
        [MEETING_TYPE_SINGLE, MEETING_TYPE_REPEATING].includes(type)
    );
    const isFilterByBothTypes =
        !isFilterByType.length || isFilterByType.length === 2;

    const groupOccurrencesFiltered = _filter(occurrences, (occurrence) => {
        // meeting is valid if there are no meeting filters, or it is specifically selected as a filtered meeting
        const isValidByMeetingFilter =
            !group || _filterByMeetings.includes(occurrence.meeting);

        if (!isValidByMeetingFilter) return;

        // meeting is valid if there are no tag filters, or it contains one of the specific tag(s) selected
        const isValidByTagFilter =
            !_filterByTags.length ||
            occurrencesWithTags.includes(occurrence.id);

        if (!isValidByTagFilter) return;

        // meeting is valid if Single is selected and this is a non-repeating occurrence,
        // or if Repeating is selected and this is a repeating occurrence,
        // or if both are selected
        // or if neither are selected

        if (isFilterByBothTypes) return true;

        const isValidBySingleFilter =
            _filterByTypes.includes(MEETING_TYPE_SINGLE) &&
            [RECURRENCE_ONE_OFF, RECURRENCE_NOT_SCHEDULED].includes(
                occurrence.recurrence
            );
        const isValidByRepeatingFilter =
            _filterByTypes.includes(MEETING_TYPE_REPEATING) &&
            occurrence.recurrence === RECURRENCE_SCHEDULED;

        return isValidBySingleFilter || isValidByRepeatingFilter;
    });

    return groupOccurrencesFiltered;
};

const deDupeOccurrencesAndEvents = (items) =>
    items
        .sort((a, b) => {
            const sortByStartDate =
                a.startDate.valueOf() - b.startDate.valueOf();
            const sortByOccurrencesFirst =
                (b.calendarType ?? '') > (a.calendarType ?? '')
                    ? -1
                    : (a.calendarType ?? '') > (b.calendarType ?? '')
                    ? 1
                    : 0;
            return sortByStartDate || sortByOccurrencesFirst;
        })
        .filter((item, index, items) => {
            // This is about excluding Outlook calendar events that are already linked, so we don't show the Outlook Calendar event as a separate entry
            const isOccurrence = !('calendarType' in item);
            if (isOccurrence || index === 0) return true;
            // loop through previous items (because all Occurrences are sorted before external calendar events) on the same date,
            // to find out if any have the same uid in a link
            let prevItemIdx = index - 1;
            while (prevItemIdx >= 0) {
                const prevItem = items[prevItemIdx];
                if (prevItem.startDate.valueOf() !== item.startDate.valueOf())
                    break;
                if (
                    prevItem?.calendarLinks?.some?.(
                        (calendarLink) => calendarLink.uid === item.uid
                    )
                )
                    return false;
                prevItemIdx -= 1;
            }
            return true;
        });

const preFilterOccurrencesAndEvents = ({
    occurrences,
    events,
    remoteLinks,
    filterByEvents,
}) => {
    if (!isViewingBaseAppHomepage() || events.length === 0) return occurrences;

    const isHiddenActive = filterByEvents?.includes(EVENT_TYPE_HIDDEN);
    const isLinkedActive = filterByEvents?.includes(EVENT_TYPE_LINKED);

    // Default view: No event filters active
    if (!isHiddenActive && !isLinkedActive)
        return deDupeOccurrencesAndEvents([
            ...events.filter((o) => !o.isHidden),
            ...occurrences,
        ]);

    // When 'linked' is active, set the filtered occurrences
    let occurrencesFiltered = occurrences;
    if (isLinkedActive) {
        const remoteLinkIds = _uniq(Object.keys(remoteLinks));
        occurrencesFiltered = occurrences.filter((o) =>
            remoteLinkIds.includes(o.id)
        );
    }

    // Both filters active: show 'hidden' events and 'linked' events
    if (isHiddenActive && isLinkedActive)
        return [...events.filter((o) => o.isHidden), ...occurrencesFiltered];

    // Only 'hidden' active: show only hidden events
    if (isHiddenActive) return events.filter((o) => o.isHidden);

    // Only 'linked' active: show only linked events
    // TODO: do we show occurrences linked by any users, or only those linked by you?
    if (isLinkedActive) return occurrencesFiltered;
};

/**
 * Get a list of occurrences within a group (or all occurrences if no group specified).
 * The list can be filtered based on the query string provided in the location object.
 * There is no filtering of archived / active meetings applied in this function.
 * @param {Object} state Redux state object.
 * @param {Object} props
 * @param {(Object|string)} [props.group] Group object or group.id. If not provided, will search meetings across all groups.
 * @param {Object} [props.location] react-router location object. Used for getSearchParams selector.
 * @param {string} [props.location.search] query string used to filter the list. Allows for filtering by meeting type, tags. Used for getSearchParams selector.
 * @param {boolean} [props.includeOnlyAccessibleMeetings=true] Only look at the accessible meetings within a Group. Required to ensure the getGroupSelector memoizes.
 * @returns {Object} groupOccurrences: List of all occurrences in the requested Group (or all groups), unfiltered. groupOccurrencesFiltered List of occurrences filtered by the query string criteria.
 */
const getDashboardOccurrencesFiltered = createSelector(
    getOccurrencesSelector,
    getGroupSelector,
    getMeetingGroupsSelector,
    getTagGroupsSelector,
    getSearchParams,
    getEventsFromRemoteCalendars,
    getLinksForRemoteCalendarEvents,
    (
        occurrences,
        group,
        meetingGroups,
        tagGroups,
        searchParams,
        events,
        remoteLinks
    ) => {
        const {
            [SEARCH_PARAM_FILTER_TAGS]: filterByTags,
            [SEARCH_PARAM_FILTER_MEETING_TYPES]: filterByTypes,
            [SEARCH_PARAM_FILTER_MEETINGS]: filterByMeetings,
            [SEARCH_PARAM_FILTER_EVENTS]: filterByEvents,
        } = searchParams;

        const preFilteredOccurrencesAndEvents = preFilterOccurrencesAndEvents({
            occurrences,
            events,
            remoteLinks,
            filterByEvents,
        });

        const commonFilterData = {
            groups: [...meetingGroups, ...tagGroups],
            group,
        };

        const groupOccurrences = filterOccurrences({
            ...commonFilterData,
            occurrences: preFilteredOccurrencesAndEvents,
        });

        const groupOccurrencesFiltered = filterOccurrences({
            ...commonFilterData,
            occurrences: groupOccurrences,
            filterByMeetings,
            filterByTags,
            filterByTypes,
        });

        return { groupOccurrences, groupOccurrencesFiltered };
    }
);

const toArray = (properties) =>
    Array.isArray(properties) ? properties : [properties];

const getDashboardOccurrences = createSelector(
    getDashboardOccurrencesFiltered,
    getUsersFromRemoteCalendars,
    meetingsAsObjectListSelector,
    getMeetingGroupsSelector,
    getToday,
    getSearchParams,
    (state) => state.permission,
    (state) => state.user,
    (
        occurrences,
        remoteUsers,
        meetingsById,
        meetingGroups,
        today,
        searchParams,
        permissions,
        user
    ) => {
        const {
            [SEARCH_PARAM_FILTER_TAGS]: filterByTags,
            [SEARCH_PARAM_FILTER_MEETING_TYPES]: filterByTypes,
            [SEARCH_PARAM_FILTER_MEETINGS]: filterByMeetings,
            [SEARCH_PARAM_FILTER_EVENTS]: filterByEvents,
            [SEARCH_PARAM_PREV_MEETINGS_COUNT]: previousMeetingsCount = 0,
            [SEARCH_PARAM_MORE_MEETINGS_COUNT]: moreMeetingsCount = 0,
            [SEARCH_PARAM_MUST_INCLUDE_DATE]: mustIncludeDate,
        } = searchParams;

        const hasArchivedFilter = (filterByTypes || []).includes(
            MEETING_TYPE_ARCHIVED
        );

        const { groupOccurrences, groupOccurrencesFiltered } = occurrences;
        const groupOccurrencesArchived = groupOccurrences.filter(
            (occurrence) =>
                occurrence.archived || _get(occurrence, 'meetingMeta.archived')
        );
        // groupOccurrences contain all active and archived occurrences for the group (or 'all occurrences' if on all meetings group)
        // groupOccurrencesArchived contains all archived occurrences for the group (or 'all occurrences' if on all meetings group)
        // groupOccurrencesFiltered includes active and archived occurrences filtered by meeting, type, tag

        const countTotalActive =
            groupOccurrences.length - groupOccurrencesArchived.length;
        const countTotalArchived = groupOccurrencesArchived.length;

        const groupOccurrencesFilteredActive = [],
            groupOccurrencesFilteredArchived = [];
        groupOccurrencesFiltered.forEach((occurrence) => {
            const meeting = meetingsById[occurrence.meeting];
            const attendees = getOccurrenceAttendees(occurrence, meeting);

            // Fill in the missing attendees for remote calendar events
            const isUnlinkedEvent =
                !meeting && Boolean(occurrence.calendarType);
            if (isUnlinkedEvent) {
                // For an event, the occurrence.meeting is an object not a meetingId
                const meetingId = occurrence.meeting.id;
                const eventOccurrence = {
                    ...occurrence,
                    meeting: meetingId,
                    attendees: attendees?.map?.(({ email }) => {
                        const permission = occurrence.permissions.find(
                            (permission) => permission.email === email
                        );
                        return {
                            userId: remoteUsers[email]?.id || email,
                            role: permission?.isOwner
                                ? ATTENDEE_ROLE_OWNER
                                : ATTENDEE_ROLE_ATTENDEE,
                        };
                    }),
                    isUnlinkedEvent,
                };
                groupOccurrencesFilteredActive.push(eventOccurrence);
                meetingId in meetingsById === false &&
                    (meetingsById[meetingId] = occurrence.meeting);
                return;
            }

            // remove occurrences that you don't attend
            if (!attendees?.some(({ userId }) => userId === user.id)) {
                return;
            }

            if (
                occurrence.archived ||
                _get(occurrence, 'meetingMeta.archived')
            ) {
                groupOccurrencesFilteredArchived.push(occurrence);
            } else {
                groupOccurrencesFilteredActive.push(occurrence);
            }
        });

        // groupOccurrencesFilteredActive includes filtered (by meeting, type, tag), active occurrences
        // groupOccurrencesFilteredArchived includes filtered, archived occurrences

        // Total occurrences for the group. Look at either Active or Archived depending on filter applied
        let countTotal, countTotalFiltered, groupOccurrencesForDisplay;
        if (!hasArchivedFilter) {
            countTotal = countTotalActive;
            countTotalFiltered = groupOccurrencesFilteredActive.length;
            groupOccurrencesForDisplay = groupOccurrencesFilteredActive;
        } else {
            countTotal = countTotalArchived;
            countTotalFiltered = groupOccurrencesFilteredArchived.length;
            groupOccurrencesForDisplay = groupOccurrencesFilteredArchived;
        }

        const filterByTypesWithoutArchived = _without(
            toArray(filterByTypes || []),
            MEETING_TYPE_ARCHIVED
        );

        const hasActiveFilters = !!(
            filterByMeetings ||
            filterByTags ||
            filterByEvents ||
            filterByTypesWithoutArchived.length
        );

        const hasOnlyArchivedFilter = !hasActiveFilters && hasArchivedFilter;

        const hasOccurrenceToday = groupOccurrencesForDisplay.find(
            (o) =>
                moment(o.startDate).startOf('day').formatForUser('LL') === today
        );

        if (!hasOccurrenceToday) {
            groupOccurrencesForDisplay.push({
                id: hasArchivedFilter
                    ? TODAY_ARCHIVED_PLACEHOLDER
                    : TODAY_PLACEHOLDER,
                startDate: moment().startOf('day'),
            });
        }

        const {
            items: itemsToDisplay,
            sortedOccurrences,
            firstOccurrenceToInclude,
            lastOccurrenceToInclude,
        } = sliceListOfOccurrencesToDisplay({
            groupOccurrencesForDisplay,
            previousMeetingsCount,
            moreMeetingsCount,
            mustIncludeDate,
        });

        const items = itemsToDisplay.map((item) => {
            // Avoid processing non-occurrence items such as 'today-placeholder'
            if (!ObjectID.isValid(item.id) && !item.calendarType) {
                return item;
            }

            const group = findGroupForOccurrence(meetingGroups, {
                occurrence: item,
            });
            const meeting = meetingsById[item.meeting] || {};
            const myAccessLevel = getOccurrenceAccessLevel(
                permissions,
                user,
                item,
                item.id
            );
            return mapOccurrenceForListDisplay({
                occurrence: item,
                meeting,
                group,
                myAccessLevel,
            });
        });

        if (hasArchivedFilter && !hasOccurrenceToday && items.length > 1) {
            _remove(items, (item) => item.id === TODAY_ARCHIVED_PLACEHOLDER);
        }

        const countItemsDisplayed = hasOccurrenceToday
            ? items.length
            : items.length - 1;

        return {
            items,
            allOccurrences: sortedOccurrences,
            hasPrevRows: firstOccurrenceToInclude > 0,
            hasPrevCount: firstOccurrenceToInclude,
            hasMoreRows: lastOccurrenceToInclude < sortedOccurrences.length - 1,
            hasMoreCount:
                sortedOccurrences.length - 1 - lastOccurrenceToInclude,
            hasActiveFilters,
            hasArchivedFilter,
            hasOnlyArchivedFilter,
            countTotal,
            countTotalFiltered,
            countItemsDisplayed,
            countTotalActive,
            countTotalArchived,
        };
    }
);

const ONE_DAY_IN_MILLISECONDS = 60 * 1000 * 60 * 24; // 60 seconds * 1000 ms * 60 minutes * 24 hours
const ONE_WEEK_IN_MILLISECONDS = ONE_DAY_IN_MILLISECONDS * 8; // to get to end of day in one week

const sliceListOfOccurrencesToDisplay = ({
    groupOccurrencesForDisplay,
    previousMeetingsCount,
    moreMeetingsCount,
    mustIncludeDate: mustIncludeDateString,
}) => {
    const sortedOccurrences = groupOccurrencesForDisplay.sort(
        (a, b) => a.startDate - b.startDate
    );

    // Find first occurrence that starts 1 week earlier than the start of today, or the first occurrence if there aren't any.
    let firstOccurrenceToInclude = Math.max(
        _findIndex(
            sortedOccurrences,
            (o) =>
                o.startDate.valueOf() >=
                moment().startOf('day').add(-1, 'week').valueOf()
        ),
        0
    );

    // Find the first occurrence that starts 4 weeks after the start of today, or the last occurrence if there aren't any.
    let lastOccurrenceToInclude = _findIndex(
        sortedOccurrences,
        (o) =>
            o.startDate.valueOf() >
            moment().startOf('day').add(5, 'weeks').valueOf(),
        firstOccurrenceToInclude
    );
    if (lastOccurrenceToInclude === -1) {
        lastOccurrenceToInclude = sortedOccurrences.length - 1;
    }

    // If there are previous meetings to show because user has hit "Previous meetings", include those
    if (firstOccurrenceToInclude && previousMeetingsCount) {
        const minDate =
            sortedOccurrences[
                Math.max(
                    firstOccurrenceToInclude - Number(previousMeetingsCount),
                    0
                )
            ].startDate.formatForUser('L');

        // There may be multiple meetings on the specific date, so ensure all meetings on that date are included
        firstOccurrenceToInclude = _findIndex(
            sortedOccurrences,
            (o) => o.startDate.formatForUser('L') === minDate
        );
    }

    // If there are more meetings to show because user has hit "More meetings", include those
    if (
        lastOccurrenceToInclude < sortedOccurrences.length - 1 &&
        moreMeetingsCount
    ) {
        const maxDate =
            sortedOccurrences[
                Math.min(
                    lastOccurrenceToInclude + Number(moreMeetingsCount),
                    sortedOccurrences.length - 1
                )
            ].startDate.formatForUser('L');

        // There may be multiple meetings on the specific date, so ensure all meetings on that date are included
        lastOccurrenceToInclude = _findLastIndex(
            sortedOccurrences,
            (o) => o.startDate.formatForUser('L') === maxDate
        );
    }

    // If there is a specific date that must be included (e.g. because user used Jump To to go to a specific date),
    // we want to ensure all meetings on this date are included
    // mustIncludeDate is the start of "today" in the user's timezone
    if (mustIncludeDateString) {
        const mustIncludeDate = Number(mustIncludeDateString);
        // if it is before the first date, find the index of the first meeting on that date
        // if it is after the last date, find the index of the last meeting on that date
        const firstDate = sortedOccurrences[firstOccurrenceToInclude].startDate
            .clone()
            .startOf('day')
            .valueOf();
        const lastDate = sortedOccurrences[lastOccurrenceToInclude].startDate
            .clone()
            .startOf('day')
            .valueOf();

        if (mustIncludeDate < firstDate) {
            const first = _findIndex(
                sortedOccurrences,
                (o) => o.startDate >= mustIncludeDate
            );
            firstOccurrenceToInclude = first === -1 ? 0 : first;
        }
        if (mustIncludeDate > lastDate) {
            const mustIncludeEndOfDay =
                mustIncludeDate + ONE_WEEK_IN_MILLISECONDS;
            const last = _findLastIndex(
                sortedOccurrences,
                (o) => o.startDate <= mustIncludeEndOfDay
            );
            lastOccurrenceToInclude =
                last === -1 ? sortedOccurrences.length - 1 : last;
        }
    }

    // Now cut the list of meetings down based on how many records we want to show.
    const items = _slice(
        sortedOccurrences,
        firstOccurrenceToInclude,
        lastOccurrenceToInclude + 1
    );

    return {
        items,
        sortedOccurrences,
        firstOccurrenceToInclude,
        lastOccurrenceToInclude,
    };
};

export const getDashboardOccurrenceRows = createSelector(
    getDashboardOccurrences,
    (occurrences) => {
        const {
            items,
            hasActiveFilters,
            hasArchivedFilter,
            countTotalActive,
            countTotalArchived,
            hasPrevRows,
            hasMoreRows,
        } = occurrences;

        const hasActiveMeetings = Boolean(countTotalActive);
        const hasArchivedMeetings = Boolean(countTotalArchived);

        if (
            (occurrences.hasActiveFilters || occurrences.hasArchivedFilter) &&
            occurrences.countTotalFiltered === 0
        ) {
            return {
                items: [
                    {
                        type: 'no-meetings-found',
                        date: moment().startOf('day').toDate(),
                    },
                ],
                hasActiveFilters,
                hasArchivedFilter,
                hasActiveMeetings,
                hasArchivedMeetings,
            };
        }

        if (
            countTotalArchived > 0 &&
            countTotalActive === 0 &&
            !hasActiveFilters &&
            !hasArchivedFilter
        ) {
            return {
                items: [],
                hasActiveFilters,
                hasArchivedFilter,
                hasActiveMeetings,
                hasArchivedMeetings,
            };
        }

        const out = [];

        if (hasPrevRows) {
            // list the 'previous-meetings' row as the date of the first occurrence.
            // This helps when using 'jump to' on the first date in the visible list of meetings,
            // to make sure we include the button, so user doesn't have to scroll up.
            const startMoment = moment(items[0].startDate).startOf('day');

            out.push({
                type: 'previous-meetings',
                date: startMoment.toDate(),
            });
        }

        Array.from(items).forEach((data, index) => {
            const lastItem = _last(out);
            const startMoment = moment(data.startDate).startOf('day');
            const date = startMoment.toDate();
            const day = startMoment.formatForUser('LL');

            const insertHeadline =
                (index === 0 || (lastItem.day && lastItem.day !== day)) &&
                data.id !== TODAY_ARCHIVED_PLACEHOLDER;

            const commonData = {
                date,
                day,
                data,
            };

            if (insertHeadline) {
                out.push({
                    ...commonData,
                    type: 'header',
                    date,
                    id: startMoment.formatForUser('YYYYMMDD'),
                });
            }

            if (
                data.id === TODAY_PLACEHOLDER ||
                data.id === TODAY_ARCHIVED_PLACEHOLDER
            ) {
                out.push({
                    ...commonData,
                    type: data.id,
                });
            } else {
                out.push({
                    ...commonData,
                    type: 'occurrence',
                });
            }
        });

        out.push({
            type: hasMoreRows ? 'more-meetings' : 'end-of-results',
        });

        return {
            hasPrevRows,
            hasMoreRows,
            items: out,
            hasNoMeetings: countTotalActive + countTotalArchived === 0,
            hasActiveFilters,
            hasArchivedFilter,
            hasActiveMeetings,
            hasArchivedMeetings,
        };
    }
);

export const getDashboardOccurrenceDates = createSelector(
    getDashboardOccurrences,
    getToday,
    (occurrences, todaysDate) => {
        const dates = _fpcompose(
            _fpsortBy((o) => o),
            _fpuniq,
            _fpmap((o) => o.startDate.clone().startOf('day').toDate())
        )(occurrences.allOccurrences);

        return {
            dates,
            todaysDate,
        };
    }
);

/**
 * Get a list of tags, related to the occurrences within a group (or all occurrences if no group specified).
 * The list of occurrences is determined based on getting all occurrences within a group and filtering it by query string criteria, which includes filter by meeting, filter by tag, filter by type (single, repeating), filter by archived.
 * @param {Object} state Redux state object.
 * @param {Object} props
 * @param {(Object|string)} [props.group] Group object or group.id. If not provided, will search meetings across all groups.
 * @param {Object} [props.location] react-router location object. Used for getDashboardOccurrencesFiltered.getSearchParams selector.
 * @param {string} [props.location.search] query string used to filter the list. Allows for filtering by meeting type, archived yes/no, tags. Used for getSearchParams selector.
 * @param {boolean} [props.includeOnlyAccessibleMeetings=true] Only look at the accessible meetings within a Group. Required to ensure the getGroupSelector memoizes.
 * @returns {Object[]} List of "group" objects based on the list of occurrences.
 */
export const getTagsForGroup = createSelector(
    getDashboardOccurrencesFiltered,
    getTagGroupsSelector,
    (occurrences, groups) => {
        const { groupOccurrences } = occurrences;

        const occurrenceIdList = groupOccurrences.map((o) => o.id);

        const tags = groups.filter((g) =>
            g.occurrences.find((o) => occurrenceIdList.includes(o.occurrenceId))
        );

        return tags;
    }
);

export const getPageMeta = createSelector(
    getGroupSelector,
    (_, { pathname }) => pathname,
    (group, pathname) => {
        return pathname in specialDashboards
            ? specialDashboards[pathname]
            : group
            ? {
                  id: group.id,
                  userId: group.userId,
                  title: group.title,
                  type: group.type,
                  accentColor: group.accentColor,
                  hasColorPicker: true,
                  hasEllipsisMenu: true,
                  hasMeetings: group.meetings.length > 0,
                  hasEditableTitle: true,
              }
            : null;
    }
);
