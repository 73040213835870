import React from 'react';
import { theme } from 'twin.macro';

export default ({ iconThick = true, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m15.833 7.5998c-6.3333 0-10.694 5.4888-12.083 8.2334 1.3889 2.7444 5.75 8.2333 12.083 8.2333 7.0834 0 10.833-5.2 12.5-8.2333-1.6666-3.0333-5.4166-8.2334-12.5-8.2334z"
            stroke={theme`colors.line.DEFAULT`}
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m21.334 15.9c0 2.7614-2.2386 5-5 5s-5-2.2386-5-5 2.2386-5 5-5 5 2.2386 5 5z"
            fill={theme`colors.line.DEFAULT`}
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <line
            transform="matrix(.77104 .63679 -.60688 .79479 .8335 5)"
            x1="1"
            x2="35.747"
            y1="-1"
            y2="-1"
            stroke={theme`colors.line.DEFAULT`}
            strokeLinecap="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
