import React from 'react';
import ObjectID from 'bson-objectid';
import { pauseUpdates, resumeUpdates } from '~common/store';
import {
    ADD_TEMPLATE_REQUEST,
    ADD_TEMPLATE_ERROR,
    DELETE_TEMPLATE_ERROR,
    DELETE_TEMPLATE_REQUEST,
    UPDATE_TEMPLATE_SUCCESS,
    CREATE_AGENDA_ITEM_SUCCESS,
    DELETE_AGENDA_ITEM_SUCCESS,
    FETCH_TEMPLATES_SUCCESS,
    FETCH_AGENDA_ITEMS_SUCCESS,
} from '~common/action.types';
import {
    getWorkspaceTemplatesAndItems,
    addTemplate,
    deleteTemplate as apiDeleteTemplate,
    updateTemplate as apiUpdateTemplate,
    setTemplatePublished as apiSetTemplatePublished,
    setTemplateEditing as apiSetTemplateEditing,
    setTemplateCancelEditing as apiSetTemplateCancelEditing,
} from '~modules/template/template.api';
import { alertSuccess, alertError } from '~modules/alert/alert.actions';
import {
    MODAL_TYPE_DELETE_CANCEL,
    AGENDA_CONTENT_SOURCE_BLANK,
    RTE_TINYMCE,
} from '~common/constants';
import {
    TEMPLATE_LIBRARY_TYPE_PRIVATE,
    TEMPLATE_LIBRARY_TYPE_WORKSPACE,
} from '~shared/constants';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import { getUser } from '~modules/user/user.selectors';
import { isDetailPage } from '~modules/navigation';

export const loadWorkspaceTemplateAndItems =
    (workspaceId) => async (dispatch) => {
        try {
            const { templates = [], agendaItems = [] } =
                await getWorkspaceTemplatesAndItems(workspaceId);

            pauseUpdates();
            dispatch({
                type: FETCH_TEMPLATES_SUCCESS,
                templates,
            });
            dispatch({
                type: FETCH_AGENDA_ITEMS_SUCCESS,
                agendaItems,
            });
            resumeUpdates();
        } catch (error) {
            dispatch(
                alertError({
                    id: 'loadWorkspaceTemplates',
                    title: `Library templates could not be loaded`,
                    error,
                })
            );
        }
    };

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line require-await
export const createTemplate = (libraryId) => async (dispatch, getState) => {
    const state = getState();
    const id = ObjectID().toString();

    let workspaceId, userId, templateLibraryId, type;
    if (libraryId === TEMPLATE_LIBRARY_TYPE_PRIVATE) {
        userId = state.user.id;
        templateLibraryId = userId;
        type = TEMPLATE_LIBRARY_TYPE_PRIVATE;
    } else {
        workspaceId = libraryId;
        templateLibraryId = libraryId;
        type = TEMPLATE_LIBRARY_TYPE_WORKSPACE;
    }

    const template = {
        id,
        workspaceId,
        userId,
        isPublished: false,
        isEditing: true,
        hasActionReview: true,
        agendaItems: [
            {
                id: ObjectID().toString(),
                itemNbr: '1',
                templateId: id,
                contentSource: AGENDA_CONTENT_SOURCE_BLANK,
                documents: [],
                rte: RTE_TINYMCE,
                isDraft: true,
            },
        ],
    };

    dispatch({
        type: ADD_TEMPLATE_REQUEST,
        template,
    });

    dispatch(createTemplateBackground(type, templateLibraryId, template));

    return template;
};

const createTemplateBackground =
    (
        type,
        libraryId,
        template,
        existingMeetingAgendaItems = [],
        successAlert
    ) =>
    async (dispatch) => {
        try {
            const result = await addTemplate(type, libraryId, template);
            successAlert && dispatch(alertSuccess(successAlert));
            return result;
        } catch (error) {
            // reverse the optimistic update
            dispatch({
                type: ADD_TEMPLATE_ERROR,
                template,
                existingMeetingAgendaItems,
            });
            dispatch(
                alertError({
                    id: 'createTemplate',
                    title: 'Template could not be created',
                    error,
                })
            );

            // throw error back to the component so it can catch and apply component updates accordingly
            throw error;
        }
    };

export const updateTemplate = (id, params) => async (dispatch) => {
    try {
        const result = await apiUpdateTemplate(id, params);
        return result;
    } catch (error) {
        dispatch(
            alertError({
                id: `updateTemplate-${id}`,
                title: `Template could not be updated`,
                error,
            })
        );
    }
};

export const publishTemplate = (id) => async (dispatch) => {
    try {
        const template = await apiSetTemplatePublished(id);

        dispatch({
            type: UPDATE_TEMPLATE_SUCCESS,
            template,
        });

        return template;
    } catch (error) {
        dispatch(
            alertError({
                id: `publishTemplate-${id}`,
                title: `Template could not be published`,
                error,
            })
        );
    }
};

export const editTemplate = (id) => async (dispatch) => {
    try {
        const { agendaItems, ...template } = await apiSetTemplateEditing(id);
        // agendaItems contains the new draft agendaItems

        // prevent jumpy behaviour for the user by pausing updates, and resuming when template is updated
        pauseUpdates();
        dispatch({
            type: CREATE_AGENDA_ITEM_SUCCESS,
            agendaItems,
        });
        dispatch({
            type: UPDATE_TEMPLATE_SUCCESS,
            template,
        });
        resumeUpdates();

        return template;
    } catch (error) {
        dispatch(
            alertError({
                id: `editTemplate-${id}`,
                title: `Template could not be set to editing`,
                error,
            })
        );
    }
};

export const cancelEditTemplate = (id) => async (dispatch) => {
    try {
        const { agendaItems, ...template } = await apiSetTemplateCancelEditing(
            id
        );
        // agendaItems contains the deleted draft agendaItems

        // prevent jumpy behaviour for the user by pausing updates, and resuming when template is updated
        pauseUpdates();
        dispatch({
            type: DELETE_AGENDA_ITEM_SUCCESS,
            agendaItems,
        });
        dispatch({
            type: UPDATE_TEMPLATE_SUCCESS,
            template,
        });
        resumeUpdates();

        return template;
    } catch (error) {
        dispatch(
            alertError({
                id: `cancelEditTemplate-${id}`,
                title: `Template editing could not be cancelled`,
                error,
            })
        );
    }
};

const deleteTemplateBackground =
    ({ template, meeting, occurrenceId }) =>
    async (dispatch) => {
        try {
            const title = meeting?.id
                ? `The template for "${meeting.title}" has been removed`
                : !template.title
                ? 'Template has been deleted'
                : `The template "${template.title}" has been deleted`;

            await apiDeleteTemplate(template.id, occurrenceId);
            dispatch(
                alertSuccess({
                    id: `template-deleted-${template.id}`,
                    title,
                })
            );
        } catch (error) {
            dispatch({
                type: DELETE_TEMPLATE_ERROR,
                template,
                error,
            });
            dispatch(
                alertError({
                    id: `onDeleteTemplate-${template.id}`,
                    title: 'Template could not be deleted',
                    error,
                })
            );
        }
    };

export const deleteTemplate =
    ({ template, meeting, closeHandler }) =>
    (dispatch) => {
        if (!template) {
            return dispatch(
                alertError({
                    id: `onDeleteTemplate`,
                    title: 'Template was not found',
                    error: 'Template was not found',
                })
            );
        }

        const deleteTemplateParams = { template, meeting };
        if (meeting) {
            // provide the occurrenceId of the page being viewed, so that "this and all future occurrences" that use the agenda template can be updated
            const { id: occurrenceId } = isDetailPage(location.pathname) || {};

            deleteTemplateParams.occurrenceId = occurrenceId;
        }

        const deletePromise = () =>
            new Promise((resolve) => {
                dispatch({ type: DELETE_TEMPLATE_REQUEST, template });
                dispatch(deleteTemplateBackground(deleteTemplateParams));
                closeHandler?.();
                resolve();
            });

        let header, content, helpLink;
        if (meeting) {
            header = 'Remove template';
            helpLink = 'clear-template';
            content = (
                <>
                    All topics in this template for &quot;
                    <strong>{meeting.title}</strong>&quot; will be cleared and
                    the link with &quot;<strong>{template.title}</strong>&quot;
                    removed.
                    <br />
                    <br />
                    Past meetings in this series will not be affected.
                </>
            );
        } else {
            header = 'Delete template';
            helpLink = 'delete-template';
            content = (
                <>
                    {template.title ? (
                        <>
                            The template &quot;
                            <strong>{template.title}</strong>&quot;
                        </>
                    ) : (
                        'This template'
                    )}{' '}
                    will be permanently deleted and it will no longer be
                    available for future use.
                    <br />
                    <br /> Any meetings that have previously selected this
                    template will not be affected.
                </>
            );
        }

        dispatch(
            openConfirm({
                header,
                content,
                modalType: MODAL_TYPE_DELETE_CANCEL,
                helpLink,
                promise: deletePromise,
            })
        );
    };

/**
 * For cloning a template (not including a series template)
 * @param {[object]} sourceAgendaItems array of agenda items in the template to be copied
 * @param {object} sourceTemplate the template to be copied
 * @param {string} libraryId the id of the target workspace, or the string 'private'
 */
export const cloneTemplate =
    ({
        sourceAgendaItems,
        sourceTemplate = {},
        sourceMeeting = {},
        libraryId,
    }) =>
    (dispatch, getState) => {
        const state = getState();
        const id = ObjectID().toString();

        let workspaceId, userId, templateLibraryId, type;
        if (libraryId === TEMPLATE_LIBRARY_TYPE_PRIVATE) {
            userId = state.user.id;
            templateLibraryId = userId;
            type = TEMPLATE_LIBRARY_TYPE_PRIVATE;
        } else {
            workspaceId = libraryId;
            templateLibraryId = libraryId;
            type = TEMPLATE_LIBRARY_TYPE_WORKSPACE;
        }

        const agendaItems = sourceAgendaItems.map((agendaItem) => ({
            ...agendaItem,
            assignee: getUser().id,
            meeting: undefined,
            id: ObjectID().toString(),
            templateId: id,
            isDraft: true,
        }));

        const template = {
            ...sourceTemplate,
            id,
            workspaceId,
            userId,
            meetingId: undefined,
            isPublished: false,
            isEditing: true,
            acknowledgedHashKey: undefined,
            titleDraft: sourceTemplate?.title,
            clonedFromTemplateId: sourceTemplate?.id,
            clonedFromMeetingId: sourceMeeting?.id,
            agendaItems,
        };

        dispatch({
            type: ADD_TEMPLATE_REQUEST,
            template,
        });

        dispatch(
            createTemplateBackground(
                type,
                templateLibraryId,
                template,
                undefined,
                {
                    id: `cloneTemplate-${id}`,
                    title: <>Template has been cloned</>,
                }
            )
        );

        return template;
    };
