import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m19.5 2c0.319 0 0.6238 0.13204 0.842 0.36477l6.2932 6.7128c0.2344 0.24997 0.3648 0.57977 0.3648 0.92242m-7.5-8h-10.5c-2.2091 0-4 1.7909-4 4v20c0 2.2091 1.7909 4 4 4h14c2.2091 0 4-1.7909 4-4v-16m-7.5-8v4c0 2.2091 1.7909 4 4 4h3.5"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
