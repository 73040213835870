import {
    EDIT_PRIVATE_NOTES_SUCCESS,
    CREATE_PRIVATE_NOTES_SUCCESS,
} from '~common/action.types';
import { createPrivateNotes, editPrivateNotes } from './privateNotes.api.js';
import {
    formSubmissionServerError,
    processBodyErrors,
} from '~client/components/formvalidation/formvalidation.helper';
import {
    alertError,
    alertPersistedErrorWithClose,
} from '~modules/alert/alert.actions';

export function onPrivateNotesEditSuccess(occurrenceId, privateNotes) {
    return {
        type: EDIT_PRIVATE_NOTES_SUCCESS,
        occurrenceId,
        privateNotes,
    };
}

export function onPrivateNotesCreateSuccess(occurrenceId, privateNotes) {
    return {
        type: CREATE_PRIVATE_NOTES_SUCCESS,
        occurrenceId,
        privateNotes,
    };
}

export function onPrivateNotesSave(occurrenceId, params) {
    return async (dispatch) => {
        try {
            const privateNotes = await editPrivateNotes(params.id, params);
            dispatch(
                onPrivateNotesEditSuccess(occurrenceId, {
                    ...privateNotes,
                    isLocalUpdate: true,
                })
            );
        } catch (error) {
            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );
            dispatch(
                alertPersistedErrorWithClose({
                    id: `onPrivateNotesSave-${occurrenceId}`,
                    title: 'Private Notes could not be saved',
                    error,
                })
            );
            return newError;
        }
    };
}

export function onPrivateNotesCreate(occurrenceId, params) {
    return async (dispatch) => {
        try {
            const privateNotes = await createPrivateNotes(occurrenceId, params);
            dispatch(
                onPrivateNotesCreateSuccess(occurrenceId, {
                    ...privateNotes,
                    isLocalUpdate: true,
                })
            );
        } catch (error) {
            const newError = await formSubmissionServerError(
                error,
                processBodyErrors
            );
            dispatch(
                alertError({
                    id: `onPrivateNotesCreate-${occurrenceId}`,
                    title: 'Private Notes could not be added',
                    error,
                })
            );
            return newError;
        }
    };
}
