import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m19.5 2c0.319 0 0.6238 0.13204 0.842 0.36477l6.2932 6.7128c0.2344 0.24997 0.3648 0.57977 0.3648 0.92242m-7.5-8h-10.5c-2.2091 0-4 1.7909-4 4v20c0 2.2091 1.7909 4 4 4h14c2.2091 0 4-1.7909 4-4v-16m-7.5-8v4c0 2.2091 1.7909 4 4 4h3.5"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m20.656 23h-1.876c-0.1307 0-0.2357-0.0327-0.315-0.098-0.0747-0.0653-0.1353-0.14-0.182-0.224l-2.345-3.885c-0.0373 0.1167-0.0817 0.217-0.133 0.301l-2.247 3.584c-0.056 0.0793-0.1213 0.154-0.196 0.224-0.07 0.0653-0.1633 0.098-0.28 0.098h-1.757l3.36-5.208-3.227-4.914h1.876c0.1307 0 0.224 0.0187 0.28 0.056 0.0607 0.0327 0.1167 0.0887 0.168 0.168l2.303 3.71c0.0467-0.1167 0.1027-0.2333 0.168-0.35l2.114-3.325c0.0513-0.0887 0.1073-0.154 0.168-0.196s0.1377-0.063 0.231-0.063h1.799l-3.255 4.837 3.346 5.285z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
