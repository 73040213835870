import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from '~modules/alert/Alert';

import preloadReducer from '~modules/preload/preload.reducer';
import userReducer, { userListReducer } from '~modules/user/user.reducer';
import userSearchReducer from '~modules/userSearch/userSearch.reducer';
import meetingReducer from '~modules/meeting/meeting.reducer';
import documentReducer from '~modules/documents/document.reducer';
import commentReducer from '~modules/comment/comment.reducer';
import actionItemReducer from '~modules/actionItems/actionItem.reducer';
import occurrenceReducer from '~modules/occurrence/occurrence.reducer';
import minuteReducer from '~modules/minutes/minutes.reducer';
import agendaReducer from '~modules/agenda/agenda.reducer';
import privateNotesReducer from '~modules/privatenotes/privatenotes.reducer';
import scheduledEventsReducer from '~modules/scheduledEvents/scheduledEvents.reducer';
import searchReducer from '~modules/search/search.reducer';
import permissionReducer from '~modules/permission/permission.reducer';
import integrationReducer from '~modules/integration/integration.reducer';
import notificationReducer from '~modules/notification/notification.reducer';
import dashboardReducer from '~modules/dashboard/dashboard.reducer';
import groupReducer from '~modules/group/group.reducer';
import pageViewReducer from '~modules/pageView/pageView.reducer';

import documentPreviewReducer from '~modules/modals/documentPreviewModal.reducer';
import agendaTemplateModalReducer from '~modules/template/agendaTemplateModal.reducer';
import simpleAddMeetingModalReducer from '~modules/modals/simpleAddMeeting/simpleAddMeeting.reducer';
import loadingModalReducer from '~modules/modals/loadingModal.reducer';
import workspaceTemplateChoiceReducer from '~modules/template/workspaceTemplateChoiceModal.reducer';
import imageUploadReducer from '~modules/modals/imageUploadModal.reducer';
import moveMeetingModalReducer from '~modules/modals/moveMeetingModal.reducer';
import moveOccurrenceReducer from '~modules/modals/moveOccurrenceModal.reducer';
import confirmReducer from '~modules/modals/confirmModal.reducer';
import archiveChoiceReducer from '~modules/modals/archiveChoiceModal.reducer';
import moveMeetingChoiceReducer from '~modules/modals/moveMeetingChoiceModal.reducer';
import deleteChoiceReducer from '~modules/modals/deleteChoiceModal.reducer';
import messageReducer from '~modules/modals/messageModal.reducer';
import remoteCalendarReducer from '~modules/remoteCalendar/remoteCalendar.reducer';
import remoteCRMReducer from '~modules/remoteCRM/remoteCRM.reducer';
import eventReplayReducer from './eventReplay.js';
import pauseReducer from './pause.js';
import activeUsersReducer from '~modules/user/activeUsers.reducer';
import templatesReducer from '~modules/template/template.reducer';

const store = {
    toastr: toastrReducer,
    eventReplay: eventReplayReducer,
    pause: pauseReducer,
    activeUsers: activeUsersReducer,
    preload: preloadReducer,
    documents: documentReducer,
    documentPreview: documentPreviewReducer,
    archiveChoiceModal: archiveChoiceReducer,
    moveMeetingChoiceModal: moveMeetingChoiceReducer,
    deleteChoiceModal: deleteChoiceReducer,
    agendaTemplateModal: agendaTemplateModalReducer,
    simpleAddMeetingModal: simpleAddMeetingModalReducer,
    loadingModal: loadingModalReducer,
    workspaceTemplateChoiceModal: workspaceTemplateChoiceReducer,
    imageUploadModal: imageUploadReducer,
    moveOccurrenceModal: moveOccurrenceReducer,
    moveMeetingModal: moveMeetingModalReducer,
    remoteCalendars: remoteCalendarReducer,
    remoteCRM: remoteCRMReducer,
    user: userReducer,
    userList: userListReducer,
    userSearch: userSearchReducer,
    groups: groupReducer,
    meetings: meetingReducer,
    templates: templatesReducer,
    agendaItems: agendaReducer,
    occurrences: occurrenceReducer,
    privateNotes: privateNotesReducer,
    minutes: minuteReducer,
    pageView: pageViewReducer,
    confirm: confirmReducer,
    message: messageReducer,
    comment: commentReducer,
    actionItems: actionItemReducer,
    search: searchReducer,
    scheduledEvents: scheduledEventsReducer,
    permission: permissionReducer,
    integration: integrationReducer,
    notifications: notificationReducer,
    dashboard: dashboardReducer,
};

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        ...store,
    });
