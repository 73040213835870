import React from 'react';

export default ({ title = 'GBP', ...rest }) => (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...rest}>
        {title && <title>{title}</title>}
        <path d="M1 5H31V27.5H1V5Z" fill="#012169" />
        <path
            d="M4.51562 5L15.9531 13.4844L27.3438 5H31V7.90625L19.75 16.2969L31 24.6406V27.5H27.25L16 19.1094L4.79688 27.5H1V24.6875L12.2031 16.3438L1 8V5H4.51562Z"
            fill="#fff"
        />
        <path
            d="M20.875 18.1719L31 25.625V27.5L18.2969 18.1719H20.875ZM12.25 19.1094L12.5312 20.75L3.53125 27.5H1L12.25 19.1094ZM31 5V5.14062L19.3281 13.9531L19.4219 11.8906L28.6562 5H31ZM1 5L12.2031 13.25H9.39062L1 6.96875V5Z"
            fill="#C8102E"
        />
        <path
            d="M12.2969 5V27.5H19.7969V5H12.2969ZM1 12.5V20H31V12.5H1Z"
            fill="#fff"
        />
        <path
            d="m1 14.047v4.5h30v-4.5h-30zm12.797-9.0469v22.5h4.5v-22.5h-4.5z"
            fill="#C8102E"
        />
    </svg>
);
