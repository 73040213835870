import React, { useState, forwardRef } from 'react';
import tw, { styled, css } from 'twin.macro';
import { Popup as SemanticPopup } from 'semantic-ui-react';
import { zIndex } from '~common/style.helpers';
import 'semantic-ui-css/components/popup.css';
import { BORDER_COLOR } from '~common/tailwind.helpers';
import { DROP_WIDTH } from '~modules/common/components/ButtonDrop';
import { useDimensions } from '~hooks';

const Popup = styled(SemanticPopup, {
    shouldForwardProp: (prop) =>
        ![
            'hasLeftBottomPosition',
            'isSmallButtonDrop',
            'isLargeButtonDrop',
            'size',
            'isSize',
            'triggerDimensions',
            'isNewFeature',
            'isTrialFeature',
            'trialFeatureHasExpired',
            'isPrintPreview',
            'isRightAligned',
            'isDisabled',
            'style',
        ].includes(prop),
})(
    ({
        hasLeftBottomPosition,
        isSmallButtonDrop,
        isLargeButtonDrop,
        isSize,
        triggerDimensions,
        style,
    }) => [
        css`
            &.ui.popup {
                max-width: 300px;
                ${tw`p-0 antialiased`}
                z-index: ${zIndex('TOP_LEVEL_POPUPS')};
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
                    0 1px 2px 0 rgba(0, 0, 0, 0.06);
                border: 1px solid ${BORDER_COLOR};
                &:before {
                    box-shadow: 1px 1px 0px 0px ${BORDER_COLOR};
                }
                &.bottom:before {
                    box-shadow: -1px -1px 0px 0px ${BORDER_COLOR};
                }
                &.right:before {
                    box-shadow: -1px -1px 0px 0px ${BORDER_COLOR};
                }
                /* Place children above arrow */
                > * {
                    ${tw`relative`}
                    z-index: ${zIndex('POPUPS') + 2};
                }
                &.ui.top.right.popup {
                    &:before {
                        box-shadow: 1px 1px 0px 0px ${BORDER_COLOR};
                    }
                }
                &.ui.right.center.popup {
                    &:before {
                        box-shadow: -1px 1px 0px 0px ${BORDER_COLOR};
                    }
                }
                &.ui.bottom.right.popup {
                    &:before {
                        box-shadow: -1px -1px 0px 0px ${BORDER_COLOR};
                    }
                }
                &.ui.bottom.center.popup,
                &.ui.bottom.left.popup {
                    &:before {
                        box-shadow: -1px -1px 0px 0px ${BORDER_COLOR};
                    }
                }
                &.ui.bottom.right.popup:before,
                &.ui.top.right.popup:before {
                    right: ${triggerDimensions?.width
                        ? `calc((${triggerDimensions.width}px / 2) - .45em)`
                        : '0.6em'};
                }
                ${hasLeftBottomPosition &&
                css`
                    &.ui.right.center.popup:before {
                        top: auto;
                        bottom: 2.7em; /* Issue: Arrow is only correct for the user details nav item when it's at the bottom of the viewport. The required positioning "left bottom" is unsupported by the library. */
                    }
                `}
                ${style}; // Allows overwrite of styles from component
            }
        `,
        css({
            '&.ui.popup.button-drop-menu': {
                ...(isSmallButtonDrop && { width: DROP_WIDTH.SMALL }),
                ...(isLargeButtonDrop && { width: DROP_WIDTH.LARGE }),
                ...(!isSmallButtonDrop &&
                    !isLargeButtonDrop && {
                        width: DROP_WIDTH.BASE,
                    }),
                ...(isSize?.tiny && {
                    width: 'calc(100vw - (var(--content-padding-x) * 2))',
                    maxWidth: 'none',
                }),
                ...tw`mt-0 border-t-0 rounded-t-none`,
                '&.small button': tw`pr-0`,
            },
        }),
    ]
);

const PopupPortal = forwardRef(function PopupPortal(
    {
        trigger,
        triggerProps,
        children,
        hasArrow = true,
        position,
        onClose,
        ...rest
    },
    ref
) {
    const [triggerRef, triggerDimensions] = useDimensions(false);
    const [isOpen, setIsOpen] = useState(false);
    const hasLeftBottomPosition = position === 'left bottom';

    return (
        <Popup
            ref={ref}
            on="click"
            open={isOpen}
            onClose={(...props) => (
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                onClose && onClose(...props), setIsOpen(false)
            )}
            onOpen={() => setIsOpen(true)}
            trigger={trigger({
                ...(triggerProps || null),
                ref: triggerRef,
                isOpen,
            })}
            basic={!hasArrow}
            // Obey position in scrollable containers
            popperModifiers={[
                {
                    enabled: true,
                    name: 'preventOverflow',
                    options: {
                        boundariesElement: 'viewport',
                    },
                },
            ]}
            {...{ hasLeftBottomPosition, triggerDimensions }}
            position={hasLeftBottomPosition ? 'left center' : position}
            {...rest}
        >
            {typeof children === 'function'
                ? children({ setIsOpen, isOpen })
                : children}
        </Popup>
    );
});

export default PopupPortal;
