import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        viewBox="0 0 40 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m39.113 40.756c-0.5583 0.7555-37.073 1.5828-38.382 0-1.3088-1.5828-0.6181-32.001 0.19079-33.583 0.8089-1.5819 37.304-2.0835 38.191-0.67196 0.8871 1.4115 0.6978 33.31 0 34.255z"
            fill="#E2E8F0"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m28.271 7c-0.4915 0-0.2048-4.6667 0-7h2.4572c0.4915 0 0.2048 4.6667 0 7h-2.4572z"
            fill="#E2E8F0"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m9.2714 7c-0.49146 0-0.20477-4.6667 0-7h2.4572c0.4915 0 0.2048 4.6667 0 7h-2.4572z"
            fill="#E2E8F0"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m3.3092 12.565c0.69582 0 33.382-1.2719 33.382 0s0.695 23.516 0 24.152c-0.695 0.6363-33.382 0-33.382 0s-0.69582-24.152 0-24.152z"
            fill="#fff"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m3.3092 12.588c0.69582 0 33.382-1.3228 33.382 0s0.695 24.456 0 25.118-33.382 0-33.382 0-0.69582-25.118 0-25.118z"
            fill="#fff"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m14.74 24.327c-0.3715-0.4086-1.0039-0.4387-1.4126-0.0672-0.4086 0.3715-0.4387 1.0039-0.0672 1.4126l1.4798-1.3454zm4.2601 6.1727-0.7399 0.6727c0.1962 0.2158 0.4769 0.3352 0.7685 0.3269 0.2917-0.0084 0.5651-0.1437 0.7486-0.3704l-0.7772-0.6292zm9.2772-9.8708c0.3475-0.4293 0.2813-1.0589-0.148-1.4064s-1.0589-0.2813-1.4064 0.148l1.5544 1.2584zm-15.017 5.0435 5 5.5 1.4798-1.3454-5-5.5-1.4798 1.3454zm6.5171 5.4565 8.5-10.5-1.5544-1.2584-8.5 10.5 1.5544 1.2584z"
            fill="#2D94D6"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
