import React, { useEffect, useRef, useState } from 'react';
import { Spacer } from '~modules/common/components';
import { Popup } from '~modules/common/form-elements';
import 'twin.macro';

// NOTE: when using the "offset" prop for positioning of popper, y value needs to be a positive value that ensures the popup is positioned outside of it's parent container to avoid the flicker bug

const Tooltip = ({
    content,
    children,
    hasMorePadding = false,
    position = 'bottom center',
    enableTooltip = true,
    offset = [0, 1],
    ...rest
}) => {
    if (!enableTooltip) return <>{children}</>;
    return (
        <Popup
            on={rest.onClick ? 'hover' : ['click', 'hover']}
            position={position}
            trigger={typeof children === 'function' ? children : () => children}
            mouseLeaveDelay={0}
            {...{ offset }}
            {...rest}
        >
            {({ setIsOpen }) => (
                <Spacer
                    px={hasMorePadding ? 'md' : 'sm'}
                    py={hasMorePadding ? 'md' : 'xs'}
                    spaceY="xs"
                    tw="text-body"
                    className="group"
                >
                    {typeof content === 'function'
                        ? content({ setIsOpen })
                        : content}
                </Spacer>
            )}
        </Popup>
    );
};

// Use this component to truncate text and display a tooltip ONLY when truncated
const OverflowToolTip = ({ children, enableTooltip = true }) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const textElementRef = useRef();
    useEffect(() => {
        setIsOverflow(
            textElementRef.current.scrollWidth >
                textElementRef.current.clientWidth
        );
    }, []);
    return (
        <Tooltip
            // 'break-all' to accommodate long email addresses causing overflow issues
            content={<span tw="break-all">{children}</span>}
            enableTooltip={enableTooltip && isOverflowed}
            position="bottom left"
            offset={[-16, 0]}
        >
            <div tw="truncate text-body font-normal" ref={textElementRef}>
                {children}
            </div>
        </Tooltip>
    );
};

export { Tooltip as default, OverflowToolTip };
