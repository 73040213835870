import { get } from '~client/api/helpers.js';
import { CSRF_RETRIEVED } from './action.types.js';

export const retrieveToken = async (dispatch) => {
    const csrf = await get('/getxcsrf');
    dispatch({
        type: CSRF_RETRIEVED,
        token: csrf.token,
    });
};
