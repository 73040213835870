import {
    SCHEDULE_SCROLL_DATE,
    LOGOUT_SUCCESS,
} from '../../common/action.types.js';

const initialState = {
    date: null,
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case SCHEDULE_SCROLL_DATE: {
            return {
                ...state,
                date: action.date,
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default dashboardReducer;
