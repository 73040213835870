import _startCase from 'lodash/startCase.js';
import 'twin.macro';
import moment from 'moment';
import { PLUS_FEATURE_TRIAL_PERIOD_DAYS } from '~common/constants';
import { getUser } from '~modules/user/user.selectors';
import { pluralize } from '~shared/utils';
import { isOnBasicPlan } from '~shared/workspace/workspace.helpers';
import { pushTo } from '~modules/navigation';
import { getWorkspaceUpgradeSlug } from '~modules/navigation/navigation.helpers';

const getUserCantUpgradeMessage = (workspace) =>
    workspace?.myAccessLevel?.canUpdate
        ? `${pluralize('Ask ', 'Ask a ', workspace?.owners?.length)}
workspace owner (${
              workspace?.owners?.length > 1
                  ? `${workspace?.owners?.[0].displayName} or ${workspace?.owners[1].displayName}`
                  : workspace?.owners?.[0]?.displayName || ''
          }) to`
        : 'Ask a workspace owner to';

const getUpgradeLink = ({ close, workspace }) => {
    const handleUpgradeClick = () => {
        close?.();
        pushTo(getWorkspaceUpgradeSlug(workspace));
    };
    return (
        <button
            type="button"
            tw="appearance-none text-body-dark hocus:text-primary"
            onClick={handleUpgradeClick}
        >
            Upgrade to Plus
        </button>
    );
};

const isFeatureEnabled = (feature) => {
    if (!feature) return false;
    const loggedInUser = getUser();
    // feature enabled for all users (by env value) or for current user (by appPreferences)
    const featureKey = `hasFeature${_startCase(
        feature.toLowerCase()
    ).replaceAll(' ', '')}`;

    if (
        window.__minuteme_appConfig[`FEATURE_${feature.toUpperCase()}`] ===
            'true' ||
        loggedInUser?.appPreferences?.[featureKey]
    )
        return true;

    // feature beta enabled (by env value), and current user has beta feature (by appPreferences)
    if (
        window.__minuteme_appConfig[`FEATURE_${feature.toUpperCase()}_BETA`] ===
            'true' &&
        loggedInUser?.appPreferences?.[`${featureKey}Beta`]
    )
        return true;

    // feature not enabled

    return false;
};

const getFeatureTrialExpiry = (feature, workspace, { isIntro } = {}) => {
    if (isIntro)
        return {
            daysRemaining: -1,
            hasStarted: true,
            hasExpired: false,
        };
    const startDate =
        workspace?.appPreferences?.[
            `Feature${_startCase(feature?.toLowerCase?.())}StartDate`
        ];

    if (!startDate) return { hasStarted: false };

    // return a countdown display for days remaining for feature
    const daysRemaining =
        PLUS_FEATURE_TRIAL_PERIOD_DAYS +
        moment(startDate).diff(Date.now(), 'days');

    const hasStarted = Boolean(startDate);
    const hasExpired =
        // cater for a workspace that has not been through normalizeWorkspace
        (workspace.isOnBasicPlan ||
            isOnBasicPlan({
                plan: workspace.workspaceProps?.plan,
            })) &&
        daysRemaining <= 0;

    return {
        daysRemaining,
        hasStarted,
        hasExpired,
    };
};

export {
    isFeatureEnabled,
    getFeatureTrialExpiry,
    getUserCantUpgradeMessage,
    getUpgradeLink,
};
