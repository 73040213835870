import 'twin.macro';
import ExternalLinkIcon from '~icons/externalLink';

const LinkExternal = ({ href, children, isDisabled, ...rest }) => {
    if (isDisabled)
        return <div tw="cursor-not-allowed text-body-disabled">{children}</div>;
    return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
            {children}
        </a>
    );
};

const LinkExternalBright = ({ href, children, ...rest }) => (
    <LinkExternal
        href={href}
        tw="text-primary-dark hocus:(text-body-dark svg:text-icon-dark)"
        {...rest}
    >
        {children} <ExternalLinkIcon tw="inline-block text-sm ml-0.5 -mt-0.5" />
    </LinkExternal>
);

export { LinkExternal as default, LinkExternalBright };
