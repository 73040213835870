import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m19.238 16.104c0.9908 0.9755 2.5781 0.9854 3.581 0.0223l5.727-5.4992c2.031-1.9501 2.0822-5.1818 0.1142-7.1954-1.956-2.0013-5.1643-2.0383-7.1659-0.0826l-5.2682 5.1473c-1.251 1.2223-1.2778 3.2261-0.0599 4.4814"
            stroke="currentColor"
            strokeWidth="1.8"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m16.146 19.357c0.9642 1.0018 0.9558 2.5889-0.0189 3.5805l-5.5657 5.6623c-1.9737 2.0081-5.2062 2.0221-7.1973 0.0313-1.9789-1.9787-1.9789-5.1867 0-7.1653l5.2085-5.2077c1.2368-1.2366 3.2411-1.2403 4.4825-0.0082"
            stroke="currentColor"
            strokeWidth="1.8"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m22.097 9.9062-12.658 12.656"
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
