import { post } from '../../api/helpers.js';

import { pageViewModel } from './pageView.mappers.js';

export function viewOccurrence(occurrenceId) {
    const url = `/api/pageview/occurrence/${occurrenceId}`;
    return post(url).then(pageViewModel);
}

export function viewVideo(videoId) {
    const url = `/api/pageview/video/${videoId}`;
    return post(url).then(pageViewModel);
}
