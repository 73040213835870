import {
    INTEGRATION_PROFILE_DISCONNECTED,
    INTEGRATION_PROFILE_LINKCOUNT,
    INTEGRATION_PROFILE_TASKLISTS,
    INTEGRATION_PROFILE_CALENDAR,
    REMOTE_CALENDAR_EVENT_HIDDEN,
} from '~common/action.types';
import {
    disconnectIntegration as apiDisconnectIntegration,
    getTaskLists as apiGetTaskLists,
    getCalendars as apiGetCalendarList,
    getLinkCount as apiGetLinkCount,
    saveIntegrationProfile as apiSaveIntegrationProfile,
    saveIntegrationLink as apiSaveIntegrationLink,
    toggleHideRemoteSeries as apiToggleHideRemoteSeries,
} from '~modules/integration/integration.api';
import { getIntegrationMeta } from '~modules/integration/integration.helpers';
import { displayError, displaySuccess } from '~modules/alert/alert.helpers';
import { removeAlert } from '~modules/alert/alert.actions';
import { formSubmissionServerError } from '~components/formvalidation/formvalidation.helper';
import { processBodyErrors } from '~components/formvalidation/settings.formvalidation';
import {
    HideEventMessage,
    UnhideEventMessage,
} from '~modules/dashboard/meetings/components/ToggleHideEvent';

export function disconnectIntegration(profile) {
    return async (dispatch) => {
        const updated = await apiDisconnectIntegration(profile.id);

        dispatch({
            type: INTEGRATION_PROFILE_DISCONNECTED,
            profile: updated,
        });
    };
}

export function disconnectIntegrationWithFeedback(profile) {
    const integrationMeta = getIntegrationMeta(profile.authSource);
    if (!integrationMeta) return;

    return async (dispatch) => {
        try {
            const updated = await apiDisconnectIntegration(profile.id);
            dispatch({
                type: INTEGRATION_PROFILE_DISCONNECTED,
                profile: updated,
            });

            displaySuccess({
                title: `${integrationMeta.title} integration has been disconnected`,
            });
        } catch (err) {
            displayError({
                title: `${integrationMeta.title} integration could not be disconnected`,
                message: err.message,
            });
        }
    };
}

export function getCalendars(profile) {
    return async (dispatch) => {
        const calendarList = await apiGetCalendarList(profile);

        dispatch({
            type: INTEGRATION_PROFILE_CALENDAR,
            profile,
            calendarList,
        });
    };
}

export function getTaskLists(profile) {
    return async (dispatch) => {
        const taskLists = await apiGetTaskLists(profile);

        dispatch({
            type: INTEGRATION_PROFILE_TASKLISTS,
            profile,
            taskLists,
        });
    };
}

export function getLinkCount(profile) {
    return async (dispatch) => {
        if (!profile.id) {
            return;
        }

        const response = await apiGetLinkCount(profile);
        const { activeCount, completedCount } = response;

        dispatch({
            type: INTEGRATION_PROFILE_LINKCOUNT,
            profile,
            linkCount: {
                total: activeCount + completedCount,
                activeCount,
                completedCount,
            },
        });
    };
}

export function saveIntegrationProfile(values) {
    return async () => {
        try {
            const {
                id,
                defaultTaskList = '',
                overrides,
                remoteResources,
                pastWeeks,
                futureWeeks,
            } = values;

            const params = {
                defaultTaskList,
                overrides,
                remoteResources,
                pastWeeks,
                futureWeeks,
            };

            await apiSaveIntegrationProfile(id, params);
        } catch (err) {
            const newError = await formSubmissionServerError(
                err,
                processBodyErrors
            );

            return newError;
        }
    };
}

export function saveIntegrationLink(values) {
    return async () => {
        try {
            const { profileId, linkedWorkspaceId } = values;
            await apiSaveIntegrationLink(profileId, linkedWorkspaceId);
        } catch (err) {
            const newError = await formSubmissionServerError(
                err,
                processBodyErrors
            );

            return newError;
        }
    };
}

const ALERT_SUCCESS = 'remote-calendar-event-toggle-success';

export const toggleHideRemoteSeries =
    (integrationId, event) => async (dispatch) => {
        const isHidingEvent = event.isHidden ? false : true;

        const commonDispatchData = {
            type: REMOTE_CALENDAR_EVENT_HIDDEN,
            calendarType: event.calendarType,
            isHidden: isHidingEvent,
            id: event?.meeting?.id,
        };

        // The 'series' (event.meeting) we retrieve for remove events during preload is only quite skinny and so it doesn't have all the elements we need to hide it.
        // However, the additional details we need are on the event.
        const series = {
            id: event.meeting.id,
            calendar: event.calendar,
            uid: event.uid,
        };

        // optimistically update
        try {
            dispatch(commonDispatchData);
            await apiToggleHideRemoteSeries(integrationId, {
                series,
                setToHidden: isHidingEvent,
            });
            dispatch(removeAlert({ id: ALERT_SUCCESS }));
            displaySuccess({
                id: ALERT_SUCCESS,
                ...(isHidingEvent
                    ? {
                          title: 'Your event has been hidden',
                          message: <HideEventMessage />,
                      }
                    : {
                          title: 'Your event has been unhidden',
                          message: <UnhideEventMessage />,
                      }),
            });
        } catch (error) {
            dispatch({ ...commonDispatchData, isHidingEvent });
            displayError({
                title: 'Event couldn’t be hidden',
                message: error.message,
            });
        }
    };
