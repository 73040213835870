import React, { forwardRef } from 'react';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import tw, { styled, css } from 'twin.macro';
import { zIndex } from '~common/style.helpers';
import 'semantic-ui-css/components/dropdown.css';

const resetSemanticStyles = css`
    .ui.dropdown {
        ${tw`inline-flex`}
    }
    .ui.dropdown.selected,
    .ui.dropdown .menu .selected.item {
        background-color: transparent;
    }

    .ui.dropdown .menu {
        z-index: ${zIndex('POPUPS')};
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }

    .ui.dropdown .menu .active.item {
        font-weight: inherit;
        ${tw`bg-solid text-body-dark`}
        /* Prevent rte dropdown width overflow */
        max-width: 15rem;
    }
`;

const Container = styled.div`
    ${resetSemanticStyles}

    ${({ hasHiddenTrigger }) =>
        hasHiddenTrigger &&
        css`
            height: 0;
            .ui.dropdown > .text,
            .ui.dropdown > .dropdown.icon {
                display: none;
            }
        `}

    .ui.dropdown .menu {
        ${tw`text-body py-2 focus:outline-none`}
    }
    .ui.dropdown .menu > .item {
        ${tw`pl-2 pr-8 py-2 block text-body`}
        padding-left: 1rem !important;
        padding-right: 2rem !important;
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
    }
    .ui.top.pointing.dropdown > .menu {
        margin-top: 0;
    }
    .ui.dropdown .menu > .item:hover {
        ${tw`bg-solid`}
        text-shadow: 0 1px 0 white;
    }
`;

const Drop = forwardRef((props, ref) => (
    <SemanticDropdown ref={ref} pointing="top left" {...props} />
));

/**
 * Dropdown
 * @param {*} hasHiddenTrigger = Removes the dropdown trigger leaving just the popup remaining. Used in the editor.
 */
const Dropdown = forwardRef(({ hasHiddenTrigger, ...rest }, ref) => {
    return (
        <Container {...{ hasHiddenTrigger }}>
            <Drop ref={ref} {...rest} />
        </Container>
    );
});

Dropdown.Menu = SemanticDropdown.Menu;
Dropdown.Item = SemanticDropdown.Item;

export default Dropdown;
