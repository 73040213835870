import React from 'react';
import { Form } from 'semantic-ui-react';
import tw, { styled, css } from 'twin.macro';

const SemanticField = styled(Form.Field, {
    shouldForwardProp: (prop) => !['hasAutoWidth'].includes(prop),
})(({ hasAutoWidth }) => [
    tw`w-full focus:outline-none text-base`,
    hasAutoWidth && tw`w-auto`,
    // Hide asterisk
    css`
        .ui.form &.required.field > label:after {
            ${tw`hidden`}
        }
    `,
]);

const FormField = (props) => <SemanticField {...props} />;

export default FormField;
