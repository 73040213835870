import React from 'react';

export default ({ title = 'AUD', ...rest }) => (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...rest}>
        {title && <title>{title}</title>}

        <path d="M1 5H31V27.5H1V5Z" fill="#006" />
        <path
            d="m15.053 23.398-2.0484 0.1781 0.2813 2.0344-1.411-1.486-1.4109 1.486 0.2812-2.0344-2.0484-0.1781 1.7672-1.0453-1.1391-1.711 1.9219 0.7266 0.6281-1.9547 0.6328 1.9547 1.9219-0.7266-1.1391 1.711m10.519 2.9203-0.4969-0.7407 0.8344 0.3141 0.2719-0.8484 0.2718 0.8484 0.8344-0.3141-0.4922 0.7407 0.7688 0.4546-0.8906 0.0797 0.1218 0.886-0.6093-0.6516-0.6188 0.6516 0.1219-0.886-0.8906-0.075m0.7687-13.683-0.4922-0.7406 0.8344 0.3141 0.2719-0.8485 0.2718 0.8485 0.8344-0.3141-0.4922 0.7406 0.7688 0.4594-0.8906 0.075 0.1218 0.8859-0.6093-0.6468-0.6188 0.6421 0.1219-0.8812-0.8906-0.075m-4.186 4.9125-0.4922-0.7406 0.8344 0.314 0.2719-0.8484 0.2765 0.8484 0.8344-0.314-0.4969 0.7406 0.7688 0.4547-0.8906 0.0797 0.1265 0.8859-0.6187-0.6516-0.6094 0.6516 0.1172-0.8859-0.8906-0.075m10.139-1.7813-0.5015-0.7406 0.8343 0.3141 0.2719-0.8485 0.2766 0.8485 0.8344-0.3141-0.4922 0.7406 0.764 0.4547-0.8906 0.0797 0.1219 0.8812-0.6094-0.6468-0.6187 0.6468 0.1218-0.8812-0.8906-0.0797m-1.0359 3.4453-0.4828 0.3047 0.1359-0.5578-0.4359-0.3656 0.5671-0.0469 0.2157-0.525 0.2203 0.5297 0.5672 0.0422-0.436 0.3656 0.136 0.5578"
            clipRule="evenodd"
            fill="#fff"
            fillRule="evenodd"
        />
        <path d="M1 5H16V16.25H1V5Z" fill="#006" />
        <path
            d="M2.75781 5L8.47656 9.24219L14.1719 5H16V6.45312L10.375 10.6484L16 14.8203V16.25H14.125L8.5 12.0547L2.89844 16.25H1V14.8438L6.60156 10.6719L1 6.5V5H2.75781Z"
            fill="#fff"
        />
        <path
            d="M10.9375 11.5859L16 15.3125V16.25L9.64844 11.5859H10.9375ZM6.625 12.0547L6.76562 12.875L2.26562 16.25H1L6.625 12.0547ZM16 5V5.07031L10.1641 9.47656L10.2109 8.44531L14.8281 5H16ZM1 5L6.60156 9.125H5.19531L1 5.98438V5Z"
            fill="#C8102E"
        />
        <path
            d="M6.64844 5V16.25H10.3984V5H6.64844ZM1 8.75V12.5H16V8.75H1Z"
            fill="#fff"
        />
        <path
            d="m1 9.5234v2.25h15v-2.25h-15zm6.3984-4.5234v11.25h2.25v-11.25h-2.25z"
            fill="#C8102E"
        />
    </svg>
);
