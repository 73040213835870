import React from 'react';
import type { CSSInterpolation } from '@emotion/serialize';

import ActionitemLists from './actionItemLists.js';
import Add from './add';
import AddCircle from './addCircle.js';
import AddDash from './addDash.js';
import AlignCenter from './alignCenter.js';
import AlignLeft from './alignLeft.js';
import AlignRight from './alignRight.js';
import AmazonChime from './amazonChime';
import AmazonEvents from './amazonEvents';
import Amex from './amex.js';
import Archive from './archive';
import Asana from './asana';
import Attendees from './attendees.js';
import Bank from './bank.js';
import BasicPlan from './basicPlan.js';
import BulletPoint from './bulletPoint.js';
import Chevron from './chevron.js';
import Clip from './clip.js';
import Close from './close.js';
import CloseCircle from './closeCircle.js';
import Cloud from './cloud.js';
import Code from './code.js';
import Comment from './comment.js';
import CopyTopic from './copyTopic.js';
import Dashboard from './dashboard.js';
import DefaultCard from './defaultCard.js';
import Delete from './delete.js';
import DinersClub from './dinersClub.js';
import Discover from './discover.js';
import Document from './document.js';
import Dotburger from './dotburger.js';
import DownArrow from './downArrow.js';
import DueDate from './dueDate.js';
import Edit from './edit.js';
import EditPencil from './editPencil.js';
import Erase from './erase.js';
import Error from './error.js';
import ExcelFile from './excelFile.js';
import ExternalLink from './externalLink.js';
import FlagAU from './flagAU.js';
import FlagGB from './flagGB.js';
import FlagNZ from './flagNZ.js';
import FlagUS from './flagUS.js';
import Filter from './filter.js';
import Forwards from './forwards.js';
import Global from './global.js';
import GoToWebinar from './goToWebinar';
import GoogleCalendar from './googleCalendar';
import GoogleHangouts from './googleHangouts.js';
import GoogleLogo from './googleLogo.js';
import GoogleMeet from './googleMeet.js';
import GroupSchedule from './groupSchedule.js';
import Guide from './guide.js';
import Help from './help';
import Hide from './hide';
import Highlight from './highlight.js';
import Home from './home.js';
import HorizontalDotburger from './horizontalDotburger.js';
import Hyperlink from './hyperlink.js';
import ImageFile from './imageFile.js';
import Information from './information.js';
import InsertAbove from './insertAbove.js';
import InsertBelow from './insertBelow.js';
import InsertImage from './insertImage.js';
import InsertLeft from './insertLeft.js';
import InsertRight from './insertRight.js';
import Jcb from './jcb.js';
import JpgFile from './jpgFile.js';
import Leave from './leave.js';
import LeftArrow from './leftArrow.js';
import ListView from './listView.js';
import Location from './location.js';
import MasterCard from './mastercard.js';
import Meeting from './meeting.js';
import MeetingSeries from './meetingSeries.js';
import MeetingSingle from './meetingSingle.js';
import Menu from './menu.js';
import Minutes from './minutes.js';
import MmIcon from './mmIcon.js';
import Move from './move.js';
import MoveTopic from './moveTopic.js';
import MsLogo from './msLogo.js';
import Notification from './notification.js';
import NotificationUnread from './notificationUnread.js';
import NumberList from './numberList.js';
import OnewaySync from './onewaySync.js';
import Outlook from './outlook';
import Padlock from './padlock.js';
import PdfFile from './pdfFile.js';
import Play from './play.js';
import PlusPlan from './plusPlan.js';
import PngFile from './pngFile.js';
import PowerpointFile from './powerpointFile.js';
import Preformatted from './preformatted.js';
import Present from './present.js';
import Previous from './previous.js';
import Print from './print.js';
import Priority from './priority.js';
import Private from './private';
import PrivateTemplates from './privateTemplates.js';
import QuickSwitch from './quickSwitch.js';
import Recurring from './recurring';
import RightArrow from './rightArrow.js';
import RteBold from './rteBold.js';
import RteItalic from './rteItalic.js';
import SaveFailure from './saveFailure.js';
import Saving from './saving.js';
import ScheduleList from './scheduleList.js';
import Search from './search.js';
import SendEmail from './sendEmail.js';
import Slack from './slack';
import Settings from './settings.js';
import Smokeball from './smokeball';
import Snooze from './snooze.js';
import Starred from './starred.js';
import Strikethrough from './strikethrough.js';
import Subscript from './subscript.js';
import Success from './success.js';
import Superscript from './superscript.js';
import Sync from './sync.js';
import Table from './table.js';
import Tag from './tag.js';
import Teams from './teams.js';
import Template from './template.js';
import TextColor from './textColor.js';
import Tick from './tick.js';
import TopicTemplate from './topicTemplate.js';
import Underline from './underline.js';
import UnionPay from './unionPay.js';
import Unlink from './unlink.js';
import User from './user.js';
import UserSolid from './userSolid.js';
import Video from './video.js';
import View from './view.js';
import Visa from './visa.js';
import Warning from './warning.js';
import Whereby from './whereby';
import WordFile from './wordFile.js';
import Workspace from './workspace.js';
import WorkspaceLined from './workspaceLined.js';
import ZipFile from './zipFile.js';
import Zoom from './zoom.js';

type IconProps = {
    name: string;
    title?: string;
    iconThick?: boolean;
    hasUnreadNotifications?: boolean;
    highlightColor?: string;
    unread?: number;
    css?: CSSInterpolation;
};

export default function Icon(props: IconProps): JSX.Element {
    switch (props.name) {
        case 'success':
            return <Success {...props} />;
        case 'dashboard':
            return <Dashboard {...props} />;
        case 'defaultCard':
            return <DefaultCard {...props} />;
        case 'delete':
            return <Delete {...props} />;
        case 'dinersClub':
            return <DinersClub {...props} />;
        case 'discover':
            return <Discover {...props} />;
        case 'document':
            return <Document {...props} />;
        case 'downArrow':
            return <DownArrow {...props} />;
        case 'notification':
            return props.unread && props.unread > 0 ? (
                <NotificationUnread {...props} />
            ) : (
                <Notification {...props} />
            );
        case 'notificationUnread':
            return <NotificationUnread {...props} />;
        case 'quickSwitch':
            return <QuickSwitch {...props} />;
        case 'search':
            return <Search {...props} />;
        case 'settings':
            return <Settings {...props} />;
        case 'leftArrow':
            return <LeftArrow {...props} />;
        case 'rightArrow':
            return <RightArrow {...props} />;
        case 'hide':
            return <Hide {...props} />;
        case 'privateTemplates':
            return <PrivateTemplates {...props} />;
        case 'actionItemLists':
            return <ActionitemLists {...props} />;
        case 'add':
            return <Add {...props} />;
        case 'addCircle':
            return <AddCircle {...props} />;
        case 'addDash':
            return <AddDash {...props} />;
        case 'alignCenter':
            return <AlignCenter {...props} />;
        case 'alignLeft':
            return <AlignLeft {...props} />;
        case 'alignRight':
            return <AlignRight {...props} />;
        case 'amazonChime':
            return <AmazonChime {...props} />;
        case 'amazonEvents':
            return <AmazonEvents {...props} />;
        case 'amex':
            return <Amex {...props} />;
        case 'archive':
            return <Archive {...props} />;
        case 'asana':
            return <Asana {...props} />;
        case 'attendees':
            return <Attendees {...props} />;
        case 'bank':
            return <Bank {...props} />;
        case 'basicPlan':
            return <BasicPlan {...props} />;
        case 'bulletPoint':
            return <BulletPoint {...props} />;
        case 'chevron':
            return <Chevron {...props} />;
        case 'clip':
            return <Clip {...props} />;
        case 'close':
            return <Close {...props} />;
        case 'closeCircle':
            return <CloseCircle {...props} />;
        case 'code':
            return <Code {...props} />;
        case 'comment':
            return <Comment {...props} />;
        case 'copyTopic':
            return <CopyTopic {...props} />;
        case 'dotburger':
            return <Dotburger {...props} />;
        case 'dueDate':
            return <DueDate {...props} />;
        case 'edit':
            return <Edit {...props} />;
        case 'editPencil':
            return <EditPencil {...props} />;
        case 'erase':
            return <Erase {...props} />;
        case 'error':
            return <Error {...props} />;
        case 'excelFile':
            return <ExcelFile {...props} />;
        case 'externalLink':
            return <ExternalLink {...props} />;
        case 'flagAU':
            return <FlagAU {...props} />;
        case 'flagGB':
            return <FlagGB {...props} />;
        case 'flagNZ':
            return <FlagNZ {...props} />;
        case 'flagUS':
            return <FlagUS {...props} />;
        case 'filter':
            return <Filter {...props} />;
        case 'forwards':
            return <Forwards {...props} />;
        case 'global':
            return <Global {...props} />;
        case 'goToWebinar':
            return <GoToWebinar {...props} />;
        case 'googleCalendar':
            return <GoogleCalendar {...props} />;
        case 'googleHangouts':
            return <GoogleHangouts {...props} />;
        case 'googleLogo':
            return <GoogleLogo {...props} />;
        case 'googleMeet':
            return <GoogleMeet {...props} />;
        case 'groupSchedule':
            return <GroupSchedule {...props} />;
        case 'guide':
            return <Guide {...props} />;
        case 'wordFile':
            return <WordFile {...props} />;
        case 'zipFile':
            return <ZipFile {...props} />;
        case 'help':
            return <Help {...props} />;
        case 'highlight':
            return <Highlight {...props} />;
        case 'home':
            return <Home {...props} />;
        case 'horizontalDotburger':
            return <HorizontalDotburger {...props} />;
        case 'hyperlink':
            return <Hyperlink {...props} />;
        case 'imageFile':
            return <ImageFile {...props} />;
        case 'information':
            return <Information {...props} />;
        case 'insertAbove':
            return <InsertAbove {...props} />;
        case 'insertBelow':
            return <InsertBelow {...props} />;
        case 'insertImage':
            return <InsertImage {...props} />;
        case 'insertLeft':
            return <InsertLeft {...props} />;
        case 'insertRight':
            return <InsertRight {...props} />;
        case 'jcb':
            return <Jcb {...props} />;
        case 'jpgFile':
            return <JpgFile {...props} />;
        case 'leave':
            return <Leave {...props} />;
        case 'listView':
            return <ListView {...props} />;
        case 'location':
            return <Location {...props} />;
        case 'mastercard':
            return <MasterCard {...props} />;
        case 'meeting':
            return <Meeting {...props} />;
        case 'meetingSeries':
            return <MeetingSeries {...props} />;
        case 'meetingSingle':
            return <MeetingSingle {...props} />;
        case 'menu':
            return <Menu {...props} />;
        case 'minutes':
            return <Minutes {...props} />;
        case 'mmIcon':
            return <MmIcon {...props} />;
        case 'move':
            return <Move {...props} />;
        case 'moveTopic':
            return <MoveTopic {...props} />;
        case 'msLogo':
            return <MsLogo {...props} />;
        case 'numberList':
            return <NumberList {...props} />;
        case 'outlook':
            return <Outlook {...props} />;
        case 'padlock':
            return <Padlock {...props} />;
        case 'pdfFile':
            return <PdfFile {...props} />;
        case 'play':
            return <Play {...props} />;
        case 'plusPlan':
            return <PlusPlan {...props} />;
        case 'pngFile':
            return <PngFile {...props} />;
        case 'powerpointFile':
            return <PowerpointFile {...props} />;
        case 'preformatted':
            return <Preformatted {...props} />;
        case 'previous':
            return <Previous {...props} />;
        case 'print':
            return <Print {...props} />;
        case 'priority':
            return <Priority {...props} />;
        case 'private':
            return <Private {...props} />;
        case 'rteBold':
            return <RteBold {...props} />;
        case 'rteItalic':
            return <RteItalic {...props} />;
        case 'warning':
            return <Warning {...props} />;
        case 'whereby':
            return <Whereby {...props} />;
        case 'onewaySync':
            return <OnewaySync {...props} />;
        case 'cloud':
            return <Cloud {...props} />;
        case 'present':
            return <Present {...props} />;
        case 'recurring':
            return <Recurring {...props} />;
        case 'saveFailure':
            return <SaveFailure {...props} />;
        case 'saving':
            return <Saving {...props} />;
        case 'scheduleList':
            return <ScheduleList {...props} />;
        case 'sendEmail':
            return <SendEmail {...props} />;
        case 'slack':
            return <Slack {...props} />;
        case 'smokeball':
            return <Smokeball {...props} />;
        case 'snooze':
            return <Snooze {...props} />;
        case 'starred':
            return <Starred {...props} />;
        case 'strikethrough':
            return <Strikethrough {...props} />;
        case 'subscript':
            return <Subscript {...props} />;
        case 'superscript':
            return <Superscript {...props} />;
        case 'sync':
            return <Sync {...props} />;
        case 'table':
            return <Table {...props} />;
        case 'tag':
            return <Tag {...props} />;
        case 'textColor':
            return <TextColor {...props} />;
        case 'teams':
            return <Teams {...props} />;
        case 'template':
            return <Template {...props} />;
        case 'tick':
            return <Tick {...props} />;
        case 'topicTemplate':
            return <TopicTemplate {...props} />;
        case 'underline':
            return <Underline {...props} />;
        case 'unionPay':
            return <UnionPay {...props} />;
        case 'unlink':
            return <Unlink {...props} />;
        case 'user':
            return <User {...props} />;
        case 'userSolid':
            return <UserSolid {...props} />;
        case 'video':
            return <Video {...props} />;
        case 'view':
            return <View {...props} />;
        case 'visa':
            return <Visa {...props} />;
        case 'workspace':
            return <Workspace {...props} />;
        case 'workspaceLined':
            return <WorkspaceLined {...props} />;
        case 'zoom':
            return <Zoom {...props} />;
        default:
            return <></>;
    }
}
