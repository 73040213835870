import React from 'react';

export default ({ iconThick = false, title = 'Priority', ...rest }) => (
    <svg
        fill="currentColor"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="M23 2H9C6.79086 2 5 3.79086 5 6V30L16 19.193L27 30V6C27 3.79086 25.2091 2 23 2Z"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
