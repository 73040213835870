import { post, postSuppressingWS } from '../../api/helpers.js';

export function markAsRead(id) {
    const url = `/api/notification/${id}/mark-read`;
    return postSuppressingWS(url);
}

export function markMultipleRead(payload) {
    const url = `/api/notifications/mark-read`;
    return post(url, payload);
}

export function markAsAccepted(id) {
    const url = `/api/notification/${id}/mark-read/accepted`;
    return post(url);
}

export function markAsRejected(id) {
    const url = `/api/notification/${id}/mark-read/rejected`;
    return post(url);
}
