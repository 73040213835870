import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        style={{ fontSize: '90%' }}
        {...rest}
    >
        <path
            d="m26.008 5.3331c-2.3406-1.9117-5.2502-3.0636-8.313-3.2914-3.0628-0.22772-6.1222 0.48046-8.7411 2.0234-2.6189 1.5429-4.6632 3.8416-5.8409 6.5677-1.1777 2.7261-1.4286 5.74-0.71672 8.6113s2.35 5.4529 4.6803 7.3761c2.3304 1.9232 5.2338 3.0895 8.2954 3.3323 3.0616 0.2429 6.1247-0.4502 8.7518-1.9802 2.627-1.5299 4.6836-3.8185 5.8759-6.5388"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m22.835 6.0112c-0.5448 0.08237-0.9124 0.59182-0.8212 1.1379 0.0912 0.54606 0.6067 0.92195 1.1514 0.83958l-0.3302-1.9775zm4.2217 0.3753 0.1651 0.98874c0.5366-0.08114 0.9029-0.57729 0.8246-1.1167l-0.9897 0.12799zm0.3524-4.5164c-0.0795-0.5477-0.5871-0.9344-1.1337-0.86371-0.5465 0.07069-0.9251 0.57199-0.8456 1.1197l1.9793-0.25598zm-4.2439 6.1185 4.0566-0.61343-0.3302-1.9775-4.0566 0.61343 0.3302 1.9775zm4.8812-1.7302-0.6373-4.3884-1.9793 0.25598 0.6373 4.3884 1.9793-0.25598z"
            fill="currentColor"
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m11.64 10.972h5.0915m-5.0915 5.5545h11.88m-11.88 5.5545h11.88"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
