import React from 'react';
import { styled } from 'twin.macro';

const HighlightPath = styled.path`
    button[disabled] & {
        stroke: #aaa;
    }
`;
const LowlightPath = styled.path`
    button[disabled] & {
        display: none;
    }
`;

export default ({ highlightColor = '', ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m9.8991 14.5-1.0052-1 11.56-11.5c3.0156 3 9.0467 9 9.0467 9-3.8532 3.8333-11.56 11.5-11.56 11.5l-1.0052-1m-7.0363-7-3.0156 3 1.5078 1.5m1.5078-4.5 7.0363 7m0 0-3.0155 3-1.0052-1m-4.5234-4.5-5.5285 6h8.5441l1.5078-1.5m-4.5234-4.5 4.5234 4.5"
            stroke="currentColor"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            strokeWidth="1.7"
        />
        <HighlightPath
            d="m3 31h26"
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
            stroke={highlightColor}
            strokeWidth="2.25"
        />
        {highlightColor && (
            <LowlightPath
                d="m3 31h26"
                strokeLinecap="round"
                vectorEffect="non-scaling-stroke"
                strokeWidth="2.25"
                stroke="black"
                opacity="0.15"
            />
        )}
    </svg>
);
