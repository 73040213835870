import React from 'react';

export default ({ iconThick = false, title = 'Category', ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="m3 12v-7c0-1.1046 0.89543-2 2-2h7c1.1046 0 2 0.89543 2 2v7c0 1.1046-0.8954 2-2 2h-7c-1.1046 0-2-0.8954-2-2z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m18 12v-7c0-1.1046 0.8954-2 2-2h7c1.1046 0 2 0.89543 2 2v7c0 1.1046-0.8954 2-2 2h-7c-1.1046 0-2-0.8954-2-2z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m18 27v-7c0-1.1046 0.8954-2 2-2h7c1.1046 0 2 0.8954 2 2v7c0 1.1046-0.8954 2-2 2h-7c-1.1046 0-2-0.8954-2-2z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m3 27v-7c0-1.1046 0.89543-2 2-2h7c1.1046 0 2 0.8954 2 2v7c0 1.1046-0.8954 2-2 2h-7c-1.1046 0-2-0.8954-2-2z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
