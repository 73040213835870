import { getPageState } from '~modules/pageView/pageView.selectors';
import { isBeforeStartOfToday, dateStartOfDay } from '~common/time.utils';
import { getMinutesStatus } from '~modules/meeting/meeting.selectors';
import {
    getOccurrenceSelector,
    getNextOccurrenceFromStoreByDate,
} from '~modules/occurrence/occurrence.pure.selectors';
import { isDetailPage } from '~modules/navigation';
import { getStore } from '~common/store';

/**
 * Check if occurrence edit should be prevented for a meeting in the past,
 * and where the user hasn't told us they'd like to edit it.
 * @param {Object} occurrence Occurrence object
 * @param {{ isIntro: boolean, pageState: {} }} PageState: object from the redux store. If ommitted, will get the page State based on the occurrence.id
 */
function isLockedDueToPastMeetingCheck(occurrence, { isIntro, pageState }) {
    if (isIntro || occurrence.isPrivate) return false;

    if (!pageState) pageState = getPageState(occurrence.id);

    return (
        pageState?.isDisabledDueToPastMeeting ??
        getDefaultLockedStatus(occurrence)
    );
}

function getDefaultLockedStatus(occurrence) {
    const isPriorToToday = isBeforeStartOfToday(occurrence.endDate);
    const { isNotStarted } = getMinutesStatus(occurrence.status);

    return isPriorToToday && !isNotStarted;
}

/**
 * For the occurrence currently being viewed... identify the first occurrence in that meeting that has a startDate after the start of today.
 * @returns String occurrenceId
 */
const getFirstOccurrenceAfterStartOfToday = () => {
    const state = getStore().getState();

    // provide the occurrenceId of the page being viewed, so that "all future occurrences" that use the agenda template can be updated
    // NOTE: we don't need to update "this" occurrence, as the new item has already come from this occurrence.
    const detailPage = isDetailPage(location.pathname) || {};

    // get all occurrences whose startDate is after the start of today.

    const occurrence = getOccurrenceSelector(state, {
        occurrence: detailPage.id,
    });
    const firstOccurrenceAfterStartOfToday = getNextOccurrenceFromStoreByDate(
        state,
        occurrence.meeting,
        dateStartOfDay()
    );

    return firstOccurrenceAfterStartOfToday;
};

const getIsPriorToMeetingStartTime = (startDate) =>
    Date.now() < startDate.valueOf();

const getIsAfterMeetingEndTime = (endDate) => Date.now() > endDate.valueOf();

const getIsPriorToMeetingStartTimeMinusThirtyMinutes = (startDate) => {
    return Date.now() < startDate.clone().subtract(30, 'minutes').valueOf();
};

const getIsOneHourPastScheduledMeetingEnd = (endDate) =>
    endDate.clone().add(1, 'hours') < Date.now();

export {
    isLockedDueToPastMeetingCheck,
    getDefaultLockedStatus,
    getFirstOccurrenceAfterStartOfToday,
    getIsPriorToMeetingStartTime,
    getIsAfterMeetingEndTime,
    getIsPriorToMeetingStartTimeMinusThirtyMinutes,
    getIsOneHourPastScheduledMeetingEnd,
};
