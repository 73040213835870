import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { onApplyTemplateToMeeting } from '~modules/agenda/agenda.actions';
import { onApplyTemplateToOccurrence } from '~modules/minutes/minutes.actions';
import { getTemplateSlug, pushTo, replaceTo } from '~modules/navigation';
import { replaceUrlToTemplatesList } from '~modules/navigation/navigation.actions';
import {
    createTemplate,
    updateTemplate,
    deleteTemplate,
    publishTemplate,
    editTemplate,
    cancelEditTemplate,
    cloneTemplate,
} from '~modules/template/template.actions';
import { openAgendaTemplateModal } from '~modules/template/agendaTemplateModal.actions';
import { openWorkspaceTemplateChoiceModal } from '~modules/template/workspaceTemplateChoiceModal.actions';
import {
    agendaItemsSelector,
    getAgendaItemsForMeetingSelector,
    getAgendaItemsForTemplateSelector,
} from '~modules/agenda/agenda.selectors';
import { getUserSelector } from '~modules/user/user.selectors';
import {
    getTemplateFromStoreById,
    libraryTemplatesSelector,
    templatesSelector,
} from '~modules/template/template.selectors';
import { useWorkspaceById } from '~modules/common/hooks';
import { isLibraryTemplate } from './template.helpers.js';

const pushToTemplate = (template, activeAgendaItem) =>
    pushTo(
        getTemplateSlug(template.userId || template.workspaceId, template.id, {
            activeAgendaItem:
                activeAgendaItem || template?.agendaItems?.[0]?.id,
        })
    );
const replaceToTemplate = (template, activeAgendaItem) =>
    replaceTo(
        getTemplateSlug(template.userId || template.workspaceId, template.id, {
            activeAgendaItem:
                activeAgendaItem || template?.agendaItems?.[0]?.id,
        })
    );

// Get a libraries templates from a supplied id
const useTemplatesByGroupId = (id) => {
    const user = useSelector(getUserSelector);
    const workspace = useWorkspaceById(id);
    const templates = useTemplates();

    const isSelectedWorkspace = workspace?.id === id;
    const isPrivateTemplates = user.id === id;

    const templatesByGroup = isSelectedWorkspace
        ? templates.filter((item) => item.workspaceId === id && item)
        : isPrivateTemplates
        ? templates.filter((item) => item.userId === id && item)
        : templates;
    return templatesByGroup;
};

// Grab a single template with supplied id
const useTemplateById = (id) => {
    const template = useSelector((state) =>
        getTemplateFromStoreById(state, id)
    );
    if (!template) return;
    return template;
};

// Grab a list of all templates
const useTemplates = () => {
    const templates = useSelector(templatesSelector);
    return templates;
};

// Grab a list of all library templates
const useLibraryTemplates = () => {
    const templates = useSelector(libraryTemplatesSelector);
    return templates;
};

// Grab a list of all published library templates
const usePublishedLibraryTemplates = () => {
    const templates = useSelector(libraryTemplatesSelector).filter(
        (template) => template.isPublished
    );
    return templates;
};

// Grab all agendaItems for the current template
const useTemplateAgendaItems = (template) =>
    useSelector((state) =>
        getAgendaItemsForTemplateSelector(state, { template })
    );

// Grab all agendaItems for the current meeting
const useAgendaItems = (meetingId) =>
    // GOTCHA because meetingId may not be provided, we don't want to break the caching in getAgendaItemsForMeetingSelector
    useSelector((state) =>
        meetingId
            ? getAgendaItemsForMeetingSelector(state, {
                  meeting: meetingId,
              })
            : agendaItemsSelector(state)
    );

// Create a library template
const useCreateTemplate = () => {
    const dispatch = useDispatch();
    return async (id) => {
        const template = await dispatch(createTemplate(id));
        pushToTemplate(template);
    };
};

// Edit a template
const useEditTemplate = () => {
    const dispatch = useDispatch();
    return (id) => dispatch(editTemplate(id));
};
// Publish a template
const usePublishTemplate = () => {
    const dispatch = useDispatch();
    return (id) => dispatch(publishTemplate(id));
};
// Edit a template
const useCancelEditTemplate = () => {
    const dispatch = useDispatch();
    return (id) => dispatch(cancelEditTemplate(id));
};

// Clone a template
const useCloneTemplate = () => {
    const dispatch = useDispatch();
    return ({
        sourceAgendaItems,
        sourceTemplate,
        sourceMeeting,
        libraryId,
    }) => {
        const template = dispatch(
            cloneTemplate({
                sourceAgendaItems,
                sourceTemplate,
                sourceMeeting,
                libraryId,
            })
        );
        template && pushToTemplate(template);
    };
};

// Rename a template title
const useRenameTemplate = () => {
    const dispatch = useDispatch();
    return (templateId, titleDraft) =>
        dispatch(updateTemplate(templateId, { titleDraft }));
};

// Rename a template description
const useRenameTemplateDescription = () => {
    const dispatch = useDispatch();
    return (templateId, descriptionDraft) =>
        dispatch(updateTemplate(templateId, { descriptionDraft }));
};

// Update templateUpdate 'read' status
const useUpdateReadStatus = () => {
    const dispatch = useDispatch();
    return (templateId, acknowledgedHashKey) =>
        dispatch(updateTemplate(templateId, { acknowledgedHashKey }));
};

// Delete a template
const useDeleteTemplate = () => {
    const dispatch = useDispatch();
    const closeHandler = () => dispatch(replaceUrlToTemplatesList());
    return ({ template }) =>
        dispatch(deleteTemplate({ template, closeHandler }));
};

const useRemoveTemplateFromMeeting = () => {
    const dispatch = useDispatch();
    return ({ template, meeting, closeHandler }) =>
        template?.id &&
        dispatch(deleteTemplate({ template, meeting, closeHandler }));
};

// Action to clone a template's agendaItems to an occurrence
const useApplyTemplateToMeeting = () => {
    const dispatch = useDispatch();
    return (template, occurrence) =>
        dispatch(onApplyTemplateToOccurrence(template, occurrence));
};

const useApplyTemplateToMeetingFromSidebar = () => {
    const applyTemplateToMeeting = useApplyTemplateToMeeting();
    const closeSidebar = useCloseSeriesTemplateSidebar();

    return (template, occurrence) => {
        applyTemplateToMeeting(template, occurrence);
        // close the slideout
        closeSidebar();
    };
};

// Action to clone template to series
const useApplyTemplateToSeries = () => {
    const dispatch = useDispatch();
    return (template, meeting, occurrence) =>
        dispatch(
            onApplyTemplateToMeeting({
                template,
                meeting,
                occurrence,
            })
        );
};

// Open the agenda template modal
const useOpenAgendaTemplateLibrary = () => {
    const dispatch = useDispatch();
    return (props) => dispatch(openAgendaTemplateModal(props));
};

// Open the 'choose a workspace' modal
const useOpenWorkspaceTemplateChoose = () => {
    const dispatch = useDispatch();
    return (props) => dispatch(openWorkspaceTemplateChoiceModal(props));
};

// open the series template sidebar by setting a location.state property.
// This triggers changes in ui.js, which opens the sidebar
const useOpenSeriesTemplateSidebar = () => {
    const { pathname, state } = useLocation();
    const history = useHistory();
    return useCallback(
        ({ activeAgendaItem, avoidScrollTop, replace = true } = {}) => {
            if (
                state?.isSeriesTemplateOpen &&
                state?.activeAgendaItem === activeAgendaItem
            )
                return;

            if (replace) {
                return history.replace(pathname, {
                    activeAgendaItem,
                    avoidScrollTop,
                    isSeriesTemplateOpen: true,
                });
            }
            return history.push(pathname, {
                activeAgendaItem,
                avoidScrollTop,
                isSeriesTemplateOpen: true,
            });
        },
        [
            history,
            pathname,
            state?.isSeriesTemplateOpen,
            state?.activeAgendaItem,
        ]
    );
};

// close the series template sidebar by removing a location.state property.
// This triggers changes in ui.js, which closes the sidebar
const useCloseSeriesTemplateSidebar = () => {
    const { pathname, state } = useLocation();
    const history = useHistory();
    return useCallback(
        ({ replace = false } = {}) => {
            const { isSeriesTemplateOpen, ...stateRest } = state || {};
            if (!isSeriesTemplateOpen) return;

            if (replace) {
                return history.replace(pathname, stateRest);
            }
            return history.push(pathname, stateRest);
        },
        [history, pathname, state]
    );
};

// navigates to the library template.
const useOpenLibraryTemplate = () => {
    return useCallback(
        ({ template, activeAgendaItem, replace = true } = {}) => {
            if (replace) {
                return replaceToTemplate(template, activeAgendaItem);
            }
            return pushToTemplate(template, activeAgendaItem);
        },
        []
    );
};

/**
 * This could be used to navigate to a series template topic, or an agenda template topic
 * if there is a libraryId and templateId, navigate to the page in manage templates
 * otherwise navigate to the series template sidebar
 */
const useNavigateToTemplateTopic = () => {
    const toSeriesTemplateTopic = useOpenSeriesTemplateSidebar();
    const toLibraryTemplateTopic = useOpenLibraryTemplate();

    return useCallback(
        ({ template, activeAgendaItem, avoidScrollTop, replace = true }) => {
            if (isLibraryTemplate(template)) {
                return toLibraryTemplateTopic({
                    template,
                    activeAgendaItem,
                    replace,
                });
            }
            return toSeriesTemplateTopic({
                activeAgendaItem,
                avoidScrollTop,
                replace,
            });
        },
        [toSeriesTemplateTopic, toLibraryTemplateTopic]
    );
};

export {
    useTemplatesByGroupId,
    useTemplates,
    useLibraryTemplates,
    usePublishedLibraryTemplates,
    useTemplateById,
    useTemplateAgendaItems,
    useAgendaItems,
    usePublishTemplate,
    useCancelEditTemplate,
    useCreateTemplate,
    useCloneTemplate,
    useEditTemplate,
    useRenameTemplate,
    useRenameTemplateDescription,
    useUpdateReadStatus,
    useDeleteTemplate,
    useRemoveTemplateFromMeeting,
    useApplyTemplateToMeeting,
    useApplyTemplateToMeetingFromSidebar,
    useApplyTemplateToSeries,
    useNavigateToTemplateTopic,
    useOpenAgendaTemplateLibrary,
    useOpenWorkspaceTemplateChoose,
    useOpenSeriesTemplateSidebar,
    useCloseSeriesTemplateSidebar,
};
