export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m28.445 15.635-24 13.856v-27.713l24 13.856z"
            fill="currentColor"
        />
    </svg>
);
