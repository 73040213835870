import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m10 6h4.8276m4.3448 0h-4.3448m0 0-4.8275 19m-4.6548 0h9.6552"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="2.3"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
