/**
 * pure selectors are those that only refer to the occurrence part of the redux store and do not select from/use any other part of the redux store.
 * There should be no imports from other selectors in this file.
 * This is important to make sure that there are no round-robin imports.
 */
import _find from 'lodash/find.js';
import _get from 'lodash/get.js';
import _isDate from 'lodash/isDate.js';
import { createSelector } from 'reselect';
import { getStore } from '~common/store';
import { RECURRENCE_SCHEDULED } from '~common/constants';
import { getOccurrenceIdSelector } from '~common/selector.helpers';

const emptyObject = {};

/**
 * Memoized selector to get an occurrence from the Store.
 * This should only be used for getting the occurrence for the current occurrence.
 * Otherwise you will break the memoizer. In other situations, use getOccurrenceFromStoreById.
 * If wanting to memoize a list of occurrences, use makeGetOccurrenceSelector.
 * @param {Object} state Redux store.
 * @param {props} props
 * @param {(Object|string)} props.occurrence Occurrence object or occurrence.id
 */
export const getOccurrenceSelector = createSelector(
    (state) => state.occurrences.occurrences,
    getOccurrenceIdSelector,
    (occurrences, id) => {
        return _find(occurrences, { id }) || emptyObject;
    }
);

/**
 * Find the next non-archived occurrence of a meeting, from a specific date.
 * @param {Object} [state=getStore().getState()] Redux store. If ommitted, will use the default Redux store.
 * @param {(Object|string)} meeting Meeting object or meeting.id.
 * @param {date} [date=new Date()] Base date, from which to find the next occurrence.
 */
export function getNextOccurrenceFromStoreByDate() {
    let state, meeting, date;
    switch (arguments.length) {
        case 3: {
            [state, meeting, date] = arguments;
            break;
        }
        case 2: {
            // could be:
            // state, meeting
            // state, meeting.id
            // meeting, date
            // meeting.id, date
            const [first, second] = arguments;
            if (_isDate(second)) {
                meeting = first;
                date = second;
            } else {
                state = first;
                meeting = second;
            }
            break;
        }
        case 1: {
            [meeting] = arguments;
        }
    }

    if (!date) {
        date = new Date();
    }
    if (!state) {
        state = getStore().getState();
    }

    const occurrences = getOccurrencesFromStoreByMeeting(state, meeting)
        .filter((o) => !o.archived)
        .sort(
            (a, b) =>
                a.startDate.valueOf() - b.startDate.valueOf() ||
                new Date(a.createdAt) - new Date(b.createdAt)
        );

    const next = _find(
        occurrences,
        (o) => o.startDate.valueOf() > date.valueOf()
    );

    return next;
}
/**
 * Find the next non-archived scheduled occurrence of a meeting, from a specific date.
 * @param {Object} [state=getStore().getState()] Redux store. If ommitted, will use the default Redux store.
 * @param {(Object)} meeting Meeting object.
 * @param {date} [date=new Date()] Base date, from which to find the next occurrence.
 */
export function getNextScheduledOccurrenceFromStoreByDate() {
    let state, meeting, date;
    switch (arguments.length) {
        case 3: {
            [state, meeting, date] = arguments;
            break;
        }
        case 2: {
            // could be:
            // state, meeting
            // state, meeting.id
            // meeting, date
            // meeting.id, date
            const [first, second] = arguments;
            if (_isDate(second)) {
                meeting = first;
                date = second;
            } else {
                state = first;
                meeting = second;
            }
            break;
        }
        case 1: {
            [meeting] = arguments;
        }
    }

    if (!date) {
        date = new Date();
    }
    if (!state) {
        state = getStore().getState();
    }

    const occurrences = getOccurrencesFromStoreByMeeting(state, meeting)
        .filter((o) => !o.archived)
        .sort(
            (a, b) =>
                a.startDate.valueOf() - b.startDate.valueOf() ||
                new Date(a.createdAt) - new Date(b.createdAt)
        );

    const next = occurrences.find(
        (o) =>
            o.recurrence === RECURRENCE_SCHEDULED &&
            o.startDate.valueOf() > date.valueOf()
    );

    return next;
}

export const getOccurrencesFromStoreByMeeting = (state, meeting) => {
    const id = _get(meeting, 'id') || meeting;
    return state.occurrences.occurrences.filter((o) => o.meeting === id);
};
