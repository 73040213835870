import _has from 'lodash/has.js';
import moment from 'moment-timezone';
import { getStore } from '~common/store';
import {
    PERMISSION_STATUS_ACCEPTED,
    PERMISSION_STATUS_PENDING,
    PERMISSION_STATUS_REJECTED,
    PERMISSION_STATUS_LEFT,
    PERMISSION_STATUS_REQUESTED,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_ACCEPTED,
    PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING,
    PERMISSION_STATUS_WORKSPACE_LEFT_WAS_ACCEPTED,
    PERMISSION_STATUS_WORKSPACE_LEFT_WAS_PENDING,
    PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED,
    PERMISSION_TYPE_OCCURRENCE,
    PERMISSION_TYPE_MEETING,
    PERMISSION_TYPE_WORKSPACE,
    PERMISSION_TYPE_TEMPLATE_LIBRARY,
    PERMISSION_LEVEL_VIEW,
    PERMISSION_LEVEL_UPDATE,
    PERMISSION_LEVEL_ADMIN,
    PERMISSION_LEVEL_OWNER,
    GRANT_LEVEL_OWNER,
    GRANT_LEVEL_ADMIN,
    GRANT_LEVEL_MEMBER,
    GRANT_LEVEL_GUEST,
    GRANT_LEVEL_NONE,
    workspaceGrantOptions,
} from '~common/constants';

export const isPendingPermission = (permission) =>
    permission?.status === PERMISSION_STATUS_PENDING;
export const isAcceptedPermission = (permission) =>
    permission?.status === PERMISSION_STATUS_ACCEPTED;
export const isRejectedPermission = (permission) =>
    permission?.status === PERMISSION_STATUS_REJECTED;
export const isLeftPermission = (permission) =>
    permission?.status === PERMISSION_STATUS_LEFT;
export const isRequestedWorkspacePermission = (permission) =>
    permission?.targetType === PERMISSION_TYPE_WORKSPACE &&
    permission?.status === PERMISSION_STATUS_REQUESTED;
export const isRequestRejectedWorkspacePermission = (permission) =>
    permission?.targetType === PERMISSION_TYPE_WORKSPACE &&
    permission?.status === PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED;

export const isAdmin = (permission) =>
    isAcceptedPermission(permission) &&
    !!permission?.grants?.includes(PERMISSION_LEVEL_ADMIN);

// this may be useful if the person has left the workspace
export const isInactiveAdmin = (permission) =>
    !isAcceptedPermission(permission) &&
    !!permission.grants.includes(PERMISSION_LEVEL_ADMIN);

export function checkAccessForTypeAndLevel(
    permissions,
    target = {},
    user,
    targetType,
    grantLevel,
    scopeFilter
) {
    user = user?.id || user;
    target = target?.id || target;

    const match = permissions.some(
        (p) =>
            p.target === target &&
            p.targetType === targetType &&
            p.status === PERMISSION_STATUS_ACCEPTED &&
            (scopeFilter?.(p) ?? true) &&
            p.grants.includes(grantLevel)
    );

    return match;
}

const checkOccurrencePermission = (permissions, occurrence, user, level) =>
    checkAccessForTypeAndLevel(
        permissions,
        occurrence.id,
        user,
        PERMISSION_TYPE_OCCURRENCE,
        level
    ) ||
    (occurrence.useMeetingAttendees &&
        checkAccessForTypeAndLevel(
            permissions,
            occurrence.meeting,
            user,
            PERMISSION_TYPE_MEETING,
            level,
            (p) => !p.scope
        )) ||
    false;

export const canViewOccurrence = (permissions, occurrence, user) =>
    checkOccurrencePermission(
        permissions,
        occurrence,
        user,
        PERMISSION_LEVEL_VIEW
    );

export const canUpdateOccurrence = (permissions, occurrence, user) =>
    checkOccurrencePermission(
        permissions,
        occurrence,
        user,
        PERMISSION_LEVEL_UPDATE
    );

export const isOccurrenceAdmin = (permissions, occurrence, user) =>
    checkOccurrencePermission(
        permissions,
        occurrence,
        user,
        PERMISSION_LEVEL_ADMIN
    );

export const canViewMeeting = (permissions, meeting, user) =>
    checkAccessForTypeAndLevel(
        permissions,
        meeting,
        user,
        PERMISSION_TYPE_MEETING,
        PERMISSION_LEVEL_VIEW
    );

export const canUpdateMeeting = (permissions, meeting, user) =>
    checkAccessForTypeAndLevel(
        permissions,
        meeting,
        user,
        PERMISSION_TYPE_MEETING,
        PERMISSION_LEVEL_UPDATE
    );

export const isMeetingAdmin = (permissions, meeting, user) =>
    checkAccessForTypeAndLevel(
        permissions,
        meeting,
        user,
        PERMISSION_TYPE_MEETING,
        PERMISSION_LEVEL_ADMIN
    );

/**
 * Get the access level for a meeting from the store. This is not memoized, so can be used
 * to retrieve access level for any meeting at any time.
 * @param {Object} [state = getStore().getState()] Redux store. If not provided, the global store will be used.
 * @param {(Object|string)} target meeting.id to find access.
 * @param {string} [user=state.user] User for which to see if they have access. Defaults to the logged in user.
 */
export function getMeetingAccessLevel() {
    let state, target, user;
    switch (arguments.length) {
        case 3: {
            [state, target, user] = arguments;
            break;
        }
        case 2: {
            // could be:
            // state, meeting
            // state, meeting.id
            // meeting, user (or user.id)
            // meeting.id, user (or user.id)
            const [first, second] = arguments;
            if (_has(first, 'permission')) {
                state = first;
                target = second;
            } else {
                target = first;
                user = second;
            }
            break;
        }
        case 1: {
            [target] = arguments;
        }
    }

    if (!state) {
        state = getStore().getState();
    }
    if (!user) {
        user = state.user;
    }

    const meetingId = target?.id || target;
    const userId = user?.id || user;

    const permissions = state.permission.filter(
        (permission) =>
            permission.user === userId && permission.target === meetingId
    );

    const canUpdate = canUpdateMeeting(permissions, meetingId, userId);
    const canView = canViewMeeting(permissions, meetingId, userId);
    const isAdmin = isMeetingAdmin(permissions, meetingId, userId);
    const nonScopedView = checkAccessForTypeAndLevel(
        permissions,
        meetingId,
        user,
        PERMISSION_TYPE_MEETING,
        PERMISSION_LEVEL_VIEW,
        (p) => !p.scope
    );

    return {
        meeting: meetingId,
        canUpdate,
        canView,
        isAdmin,
        nonScopedView,
    };
}

export const getOccurrenceAccessLevel = (
    permissions,
    user,
    occurrenceParam,
    occurrenceId,
    bypassOccurrenceExistenceCheck
) => {
    const target = occurrenceId;

    let occurrence = occurrenceParam;
    if (!bypassOccurrenceExistenceCheck) {
        if (!occurrence) {
            return {
                occurrence: target,
                canUpdate: false,
                canView: false,
                isAdmin: false,
            };
        }
    } else if (!occurrence) {
        // occurrence may not be found/provided if it is not in the store.
        // This would be where user has just accepted a permission invitation, however
        // occurrence isn't yet in their redux store.
        occurrence = {
            id: occurrenceId,
        };
    }

    const canUpdate = canUpdateOccurrence(permissions, occurrence, user);
    const canView = canViewOccurrence(permissions, occurrence, user);
    const isAdmin = isOccurrenceAdmin(permissions, occurrence, user);

    return {
        occurrence: target,
        meeting: occurrence.meeting,
        canUpdate,
        canView,
        isAdmin,
    };
};

/**
 * Get the access level for a workspace from the store. This is not memoized, so can be used
 * to retrieve access level for any workspace at any time.
 * @param {Object} [permissions] Array of permissions, for example from the redux store.
 * @param {(Object|string)} target meeting.id to find access.
 * @param {string} [user=state.user] User for which to see if they have access. Defaults to the logged in user.
 */
export function getWorkspaceAccessLevel() {
    return getWorkspaceAccessLevelByType(
        PERMISSION_TYPE_WORKSPACE,
        ...arguments
    );
}

export function getTemplateLibraryAccessLevel() {
    return getWorkspaceAccessLevelByType(
        PERMISSION_TYPE_TEMPLATE_LIBRARY,
        ...arguments
    );
}

function getWorkspaceAccessLevelByType() {
    let targetType, permissions, target, user;
    switch (arguments.length) {
        case 4: {
            [targetType, permissions, target, user] = arguments;
            break;
        }
        case 3: {
            // could be:
            // permissions, workspace
            // permissions, workspace.id
            // workspace, user
            // workspace.id, user
            targetType = arguments[0];
            const [, first, second] = arguments;
            if (Array.isArray(first)) {
                permissions = first;
                target = second;
            } else {
                target = first;
                user = second;
            }
            break;
        }
        case 2: {
            [targetType, target] = arguments;
        }
    }

    const state = getStore().getState();
    if (!user) {
        user = state.user;
    }

    const targetId = target?.id || target;
    const userId = user?.id || user;

    if (!permissions) {
        permissions = state.permission;
    }

    const permission = permissions.find(
        (permission) =>
            permission.user === userId &&
            permission.target === targetId &&
            permission.targetType === targetType
    );

    return {
        workspaceId: target,
        createdAt: permission?.createdAt ? moment(permission.createdAt) : null,
        ...getAccessLevelByPermission(permission),
    };
}

export const getAccessLevelByPermission = (
    permission,
    { allowPending = false } = {}
) => {
    const status = getUserPermissionsByStatus(permission?.status);

    const canUpdate =
        (status.isActive || (allowPending && status.isPending)) &&
        Boolean(permission?.grants?.includes(PERMISSION_LEVEL_UPDATE));
    const canView =
        (status.isActive || (allowPending && status.isPending)) &&
        Boolean(permission?.grants?.includes(PERMISSION_LEVEL_VIEW));
    const isAdmin =
        (status.isActive || (allowPending && status.isPending)) &&
        Boolean(permission?.grants?.includes(PERMISSION_LEVEL_ADMIN));
    const isOwner =
        (status.isActive || (allowPending && status.isPending)) &&
        Boolean(permission?.grants?.includes(PERMISSION_LEVEL_OWNER));

    const label =
        (isOwner && GRANT_LEVEL_OWNER) ||
        (isAdmin && GRANT_LEVEL_ADMIN) ||
        (canView && canUpdate && GRANT_LEVEL_MEMBER) ||
        (canView && GRANT_LEVEL_GUEST) ||
        GRANT_LEVEL_NONE;

    return {
        canUpdate,
        canView,
        isAdmin,
        isOwner,
        label,
        ...status,
    };
};

export const getUserPermissionsByStatus = (status) => {
    const statuses = {
        isRequested: status === PERMISSION_STATUS_REQUESTED,
        isPending: status === PERMISSION_STATUS_PENDING,
        isRejected: status === PERMISSION_STATUS_REJECTED,
        isRevoked: [
            PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_ACCEPTED,
            PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING,
        ].includes(status),
        hasRequestRejected:
            status === PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED,
        hasLeftMeeting: status === PERMISSION_STATUS_LEFT,
        hasLeftWorkspace: [
            PERMISSION_STATUS_WORKSPACE_LEFT_WAS_ACCEPTED,
            PERMISSION_STATUS_WORKSPACE_LEFT_WAS_PENDING,
        ].includes(status),
    };
    return {
        ...statuses,
        isActive: status === PERMISSION_STATUS_ACCEPTED,
        // Return true if at least one status is true
        isInactive: Object.values(statuses).some(Boolean),
    };
};

export const hasUserDeletePermissions = ({ grantLevel, workspace }) => {
    const { isOwner, isAdmin } = workspace.myAccessLevel;
    // Owner can delete anyone
    if (isOwner) return true;
    // Admin can delete guest/member
    if (
        isAdmin &&
        [GRANT_LEVEL_MEMBER, GRANT_LEVEL_GUEST].includes(grantLevel)
    ) {
        return true;
    }
    return false;
};

export const getUserGrantOptions = (isOwner) =>
    isOwner
        ? workspaceGrantOptions
        : workspaceGrantOptions.filter(({ value }) =>
              [GRANT_LEVEL_MEMBER, GRANT_LEVEL_GUEST].includes(value)
          );
