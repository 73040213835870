import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    NOTIFICATION_TYPE_MESSAGEMODAL,
    NOTIFICATION_STATUS_UNREAD,
    NOTIFICATION_MODAL_PAYMENT_FAILED,
    NOTIFICATION_MODAL_UPGRADED_PRIMARY,
    NOTIFICATION_MODAL_UPGRADED_SECONDARY,
    NOTIFICATION_MODAL_TERMS_PRIVACY_UPDATED,
    NOTIFICATION_MODAL_FEATURE_TRIAL_EXPIRY,
} from '~common/constants';
import { sortByCreatedAt } from '~common/selector.helpers';
import { useIsPreloading } from '~hooks';
import {
    openPaymentErrorModal,
    openTrialExpiryModal,
    openUpgradeSuccessPrimaryModal,
    openUpgradeSuccessSecondaryModal,
    openUpdatedTermsAndPrivacy,
} from '~modules/modals/messageModal.actions';

const notificationTypeToMessageModalMapping = {
    [NOTIFICATION_MODAL_PAYMENT_FAILED]: openPaymentErrorModal,
    [NOTIFICATION_MODAL_FEATURE_TRIAL_EXPIRY]: openTrialExpiryModal,
    [NOTIFICATION_MODAL_UPGRADED_PRIMARY]: openUpgradeSuccessPrimaryModal,
    [NOTIFICATION_MODAL_UPGRADED_SECONDARY]: openUpgradeSuccessSecondaryModal,
    [NOTIFICATION_MODAL_TERMS_PRIVACY_UPDATED]: openUpdatedTermsAndPrivacy,
};

export default function useModalNotifications() {
    const dispatch = useDispatch();
    const notifications = useSelector(
        (state) => state.notifications.notifications
    );
    const isPreLoading = useIsPreloading();

    useEffect(() => {
        if (isPreLoading) return;
        const modalMessage = notifications
            .filter(
                (notification) =>
                    notification.type === NOTIFICATION_TYPE_MESSAGEMODAL &&
                    notification.status === NOTIFICATION_STATUS_UNREAD &&
                    Object.keys(notificationTypeToMessageModalMapping).includes(
                        notification?.meta?.modalType
                    )
            )
            .sort(sortByCreatedAt);

        if (!modalMessage.length) return;

        const notification = modalMessage[0];
        const modalAction =
            notificationTypeToMessageModalMapping[
                notification?.meta?.modalType
            ];

        const action = modalAction?.(notification);
        // have a modal message notification, now display the MessageModal
        action && dispatch(action);
    }, [dispatch, notifications, isPreLoading]);
}
