import {
    CONFIRM_MODAL_OPEN,
    CONFIRM_MODAL_LOADING,
    CONFIRM_MODAL_PROCEED_SUCCESS,
    CONFIRM_MODAL_PROCEED_ERROR,
    CONFIRM_MODAL_CANCEL,
} from '~common/action.types';
import { MODAL_TYPE_YESNO } from '~common/constants';

export function onClose() {
    return {
        type: CONFIRM_MODAL_CANCEL,
    };
}

function onOpen({ modalType = MODAL_TYPE_YESNO, ...rest }) {
    return {
        type: CONFIRM_MODAL_OPEN,
        modalType,
        ...rest,
    };
}

function onSuccess() {
    return {
        type: CONFIRM_MODAL_PROCEED_SUCCESS,
    };
}

function onLoading() {
    return {
        type: CONFIRM_MODAL_LOADING,
    };
}

// TODO: handle this!
function onError() {
    return {
        type: CONFIRM_MODAL_PROCEED_ERROR,
    };
}

export function proceedConfirm(promise) {
    return async (dispatch) => {
        dispatch(onLoading());

        try {
            await promise();
            dispatch(onSuccess());
        } catch (err) {
            dispatch(onError(err));
        }
    };
}

export function cancelConfirm() {
    return (dispatch) => {
        dispatch(onClose());
    };
}

export function openConfirm(args) {
    return (dispatch) => {
        dispatch(onOpen(args));
    };
}
