// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';

import {
    DELETE_MEETING_SUCCESS,
    FETCH_MINUTES_SUCCESS,
    EDIT_OCCURRENCE_MINUTES_REQUEST,
    EDIT_OCCURRENCE_MINUTES_SUCCESS,
    TOGGLE_OCCURRENCE_MINUTES_STATUS_REQUEST,
    TOGGLE_OCCURRENCE_MINUTES_STATUS_ERROR,
    TOGGLE_OCCURRENCE_MINUTES_STATUS_WEBSOCKET,
    RENUMBER_OCCURRENCE_MINUTES_WEBSOCKET,
    DELETE_OCCURRENCE_MINUTES_REQUEST,
    DELETE_OCCURRENCE_MINUTES_SUCCESS,
    CREATE_OCCURRENCE_MINUTES_REQUEST,
    BULK_CREATE_OCCURRENCE_MINUTES_REQUEST,
    BULK_CREATE_OCCURRENCE_MINUTES_ERROR,
    BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS,
    CREATE_OCCURRENCE_MINUTES_SUCCESS,
    CREATE_OCCURRENCE_MINUTES_ERROR,
    LINK_DOCUMENTS_WEBSOCKET,
    UNLINK_DOCUMENTS_WEBSOCKET,
    CREATE_AGENDA_ITEM_ERROR,
    MOVE_OCCURRENCE_MINUTES_REQUEST,
    MOVE_OCCURRENCE_MINUTES_ERROR,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
    LOGOUT_SUCCESS,
} from '~common/action.types';

import { isPrimaryItemNbr } from '~common/list.helpers';

const updateMinuteItems = (state, newItem) => {
    return state.minutes.map((m) => {
        let updatedMinuteItemProps;
        if (Array.isArray(newItem)) {
            updatedMinuteItemProps = newItem.find(
                (updated) => updated.id === m.id
            );
        } else if (m.id === newItem.id) {
            updatedMinuteItemProps = newItem;
        }

        if (!updatedMinuteItemProps) return m;
        return {
            ...m,
            isLocalUpdate: false,
            ...updatedMinuteItemProps,
        };
    });
};

const initialState = {
    minutes: [],
};

const minuteReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_MEETING_SUCCESS: {
            return {
                ...state,
                minutes: state.minutes.filter(
                    (o) => o.meeting !== action.meeting.id
                ),
            };
        }

        case FETCH_MINUTES_SUCCESS: {
            const {
                page: { docs },
            } = action;

            return {
                ...state,
                minutes: _.uniqBy([...state.minutes, ...docs], 'id'),
            };
        }

        case TOGGLE_OCCURRENCE_MINUTES_STATUS_REQUEST:
        case TOGGLE_OCCURRENCE_MINUTES_STATUS_ERROR: {
            const { id, status } = action;
            return {
                ...state,
                minutes: updateMinuteItems(state, { id, status }),
            };
        }

        case CREATE_OCCURRENCE_MINUTES_SUCCESS:
        case CREATE_OCCURRENCE_MINUTES_REQUEST:
        case BULK_CREATE_OCCURRENCE_MINUTES_REQUEST: {
            return {
                ...state,
                minutes: _.uniqBy(
                    [
                        action.minuteItem,
                        ...(action.minuteItems || []),
                        ...state.minutes,
                    ].filter(Boolean),
                    'id'
                ),
            };
        }

        case BULK_CREATE_OCCURRENCE_MINUTES_ERROR: {
            return {
                ...state,
                minutes: _.reject(
                    state.minutes,
                    (a) => a.occurrence === action.occurrenceId
                ),
            };
        }

        case BULK_REPLACE_OCCURRENCE_MINUTES_SUCCESS: {
            const { occurrence, minuteItems } = action;
            return {
                ...state,
                minutes: [
                    ...minuteItems,
                    ...state.minutes.filter(
                        (minuteItem) => minuteItem.occurrence !== occurrence.id
                    ),
                ],
            };
        }

        case CREATE_OCCURRENCE_MINUTES_ERROR: {
            return {
                ...state,
                minutes: _.uniqBy(
                    _.without(
                        [...(action.minuteItems || []), ...state.minutes],
                        action.minuteItem
                    ),
                    'id'
                ),
            };
        }

        case EDIT_OCCURRENCE_MINUTES_REQUEST:
        case EDIT_OCCURRENCE_MINUTES_SUCCESS:
        case LINK_DOCUMENTS_WEBSOCKET:
        case UNLINK_DOCUMENTS_WEBSOCKET:
        case TOGGLE_OCCURRENCE_MINUTES_STATUS_WEBSOCKET:
        case RENUMBER_OCCURRENCE_MINUTES_WEBSOCKET: {
            return {
                ...state,
                minutes: updateMinuteItems(
                    state,
                    action.minuteItem || action.minuteItems
                ),
            };
        }

        case DELETE_OCCURRENCE_MINUTES_REQUEST: {
            const isPrimary = isPrimaryItemNbr(
                _.get(action, 'minuteItem.itemNbr')
            );
            const minutes = _.reject(state.minutes, (m) =>
                m.occurrence !== action.minuteItem.occurrence
                    ? false
                    : isPrimary
                    ? Math.trunc(m.itemNbr) ===
                      Math.trunc(action.minuteItem.itemNbr)
                    : m.itemNbr === action.minuteItem.itemNbr
            );
            return {
                ...state,
                minutes: updateMinuteItems({ minutes }, action.minuteItems),
            };
        }

        case DELETE_OCCURRENCE_MINUTES_SUCCESS: {
            const minutes = _.reject(state.minutes, {
                id: action.minuteItem.id,
            });

            return {
                ...state,
                minutes,
            };
        }

        case CREATE_AGENDA_ITEM_ERROR: {
            const minuteItem = _.find(state.minutes, {
                agendaItem: action.agendaItem.id,
            });

            if (!minuteItem) {
                return state;
            }

            const updatedMinuteItem = _.omit(minuteItem, 'agendaItem');
            return {
                ...state,
                minutes: updateMinuteItems(state, updatedMinuteItem),
            };
        }

        case MOVE_OCCURRENCE_MINUTES_REQUEST: {
            const minuteItem = _.find(state.minutes, {
                id: action.minuteItem.id,
            });

            if (!minuteItem) {
                return state;
            }

            const updatedMinuteItem = {
                ...minuteItem,
                occurrence: action.toOccurrence,
                meeting: action.toMeeting,
            };
            return {
                ...state,
                minutes: updateMinuteItems(state, updatedMinuteItem),
            };
        }
        case MOVE_OCCURRENCE_MINUTES_ERROR: {
            const minuteItem = _.find(state.minutes, {
                id: action.minuteItem.id,
            });

            if (!minuteItem) {
                return state;
            }

            const updatedMinuteItem = {
                ...minuteItem,
                occurrence: action.fromOccurrence,
                meeting: action.fromMeeting,
            };
            return {
                ...state,
                minutes: updateMinuteItems(state, updatedMinuteItem),
            };
        }

        case MOVE_OCCURRENCE_DELETE: {
            if ((action.minuteItemIds?.length || 0) === 0) return state;

            return {
                ...state,
                minutes: state.minutes.filter(
                    (minuteItem) =>
                        !action.minuteItemIds.includes(minuteItem.id)
                ),
            };
        }

        case MOVE_OCCURRENCE_UPDATE: {
            if ((action.minuteItems?.length || 0) === 0) return state;
            const updatedMinuteItems = Object.fromEntries(
                action.minuteItems.map((minuteItem) => [
                    minuteItem.id,
                    minuteItem,
                ])
            );

            const minutes = state.minutes.map((minuteItem) => {
                if (minuteItem.id in updatedMinuteItems)
                    return updatedMinuteItems[minuteItem.id];

                return minuteItem;
            });

            return {
                ...state,
                minutes,
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default minuteReducer;
