import React from 'react';
import tw from 'twin.macro';
import { replace } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import { serializeError } from 'serialize-error';
import { LOGOUT_REQUEST, LOGOUT_SUCCESS } from '~common/action.types';
import { pauseUpdates, resumeUpdates } from '~common/store';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import { logout as logoutApi } from '~client/api/session.api';
import { MODAL_TYPE_NONE, MODAL_TYPE_RELOAD } from '~common/constants';
import { getUserDisplayName } from '~shared/user/user.helpers';
import { unsubscribe } from '~client/websocket/actions';
import HelpLink from '~modules/help/HelpLink';

const Container = tw.div`flex flex-col space-y-3`;

let sessionId;

const VERSION_EXPIRED_REMINDER_MS = 1000 * 60 * 60 * 24; // 1 day
// const VERSION_EXPIRED_REMINDER_MS = 1000 * 5; // 5 seconds for testing

export function setSessionId() {
    sessionId = uuidv4();
}

export function getSessionId() {
    return sessionId;
}

function logoutRequest() {
    return {
        type: LOGOUT_REQUEST,
    };
}

function logoutSuccess() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

export function logout(error) {
    return async (dispatch) => {
        dispatch(
            openConfirm({
                header: 'Logging out…',
                content: 'You are being signed out of your session…',
                promise: null,
                modalType: MODAL_TYPE_NONE,
                closeIcon: false,
            })
        );

        dispatch(logoutRequest());

        let logoutResponse;
        try {
            logoutResponse = await logoutApi(serializeError(error));
        } catch {
            // noop
        }

        if (window.Intercom) {
            window.Intercom('shutdown');
            window.Intercom('boot');
        }

        pauseUpdates();

        dispatch(logoutSuccess());

        if (logoutResponse?.destroySessionUrl) {
            window.location =
                logoutResponse?.autoRedirect === false
                    ? `/logout?${btoa(JSON.stringify(logoutResponse))}`
                    : logoutResponse.destroySessionUrl;
            return;
        } else {
            dispatch(replace(`/logout`));
        }

        resumeUpdates();
    };
}

const displayMessageThenReloadBrowser = (
    { header, content, helpLink },
    { shutdownIntercom = true, pauseUpdates } = {}
) => {
    return (dispatch) => {
        if (window.Intercom && shutdownIntercom) {
            window.Intercom('shutdown');
        }

        dispatch(unsubscribe());

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line require-await
        const promise = async () => {
            // prevent screen refresh updates happening when the modal closes, until browser refresh
            pauseUpdates?.();
            window.location.reload();
        };

        dispatch(
            openConfirm({
                header,
                helpLink,
                content,
                promise,
                modalType: MODAL_TYPE_RELOAD,
                closeIcon: false,
            })
        );
    };
};

export function onInvalidSessionError() {
    const header = 'Invalid session';
    const helpLink = 'sign-in-again';
    const content = (
        <Container>
            <span>
                There has been an issue with your connection to MinuteMe. The
                web page will be automatically&nbsp;reloaded.
            </span>
            <span>
                If the problem recurs, please close and re-open all browser
                windows and log in to MinuteMe&nbsp;again.
            </span>
        </Container>
    );
    return displayMessageThenReloadBrowser({ header, helpLink, content });
}

export function onPreloadError() {
    const header = `We haven't been able to load your meetings`;
    const content = (
        <Container>
            <span>
                There has been an issue with your connection to MinuteMe. The
                web page will be automatically&nbsp;reloaded.
            </span>
            <span>
                If the problem recurs, please confirm you are connected to the
                network, close and re-open all browser windows and log in to
                MinuteMe&nbsp;again.
            </span>
        </Container>
    );

    return displayMessageThenReloadBrowser(
        {
            header,
            content,
        },
        {
            shutdownIntercom: false,
        }
    );
}

export function onUserMismatchError(user) {
    const header = 'The logged in user has changed';
    const helpLink = 'sign-in-again';
    const content = (
        <Container>
            <span>
                You were previously logged in as
                <strong>{` ${getUserDisplayName(user)} (${
                    user.email
                }) `}</strong>
                however this session is no longer connected. The webpage will be
                automatically&nbsp;reloaded.
            </span>
            <span>
                If the problem recurs, please close and re-open all browser
                windows and log in to MinuteMe&nbsp;again.
            </span>
        </Container>
    );

    return displayMessageThenReloadBrowser({ header, helpLink, content });
}

const VERSION_REGEX = /^[bv](?<majorminor>\d*\.\d*)\.(?<patch>\d*)$/;

export const checkClientVersion =
    ({ serverVersion, mustRefresh, hasHelpsiteFeature }) =>
    (dispatch) => {
        const clientVersion = window?.__minuteme_appConfig?.VERSION;

        const { majorminor: clientMinorVersion } =
            clientVersion.match(VERSION_REGEX)?.groups || {};
        const { majorminor: serverMinorVersion } =
            serverVersion.match(VERSION_REGEX)?.groups || {};

        if (clientMinorVersion !== serverMinorVersion) {
            dispatch(
                onClientVersionExpiredError(mustRefresh, hasHelpsiteFeature)
            );
        }
    };

export function onClientVersionExpiredError(
    forceRefresh = true,
    hasHelpsiteFeature = false
) {
    return (dispatch) => {
        const header = 'New features are available';
        const helpLink = 'new-features';
        const content = (
            <Container>
                <span>
                    We have released some new features for MinuteMe to make your
                    life even&nbsp;easier.
                </span>
                {hasHelpsiteFeature && (
                    <span>
                        Open{' '}
                        <HelpLink
                            modal="new-features"
                            linkText="What’s new in MinuteMe"
                            hasHover
                            iconName="externalLink"
                        />{' '}
                        in a separate browser tab to review the changes when you
                        have a spare&nbsp;minute.
                    </span>
                )}
                {!forceRefresh && (
                    <span>
                        To use these features immediately choose{' '}
                        <strong>Reload</strong> to reload this
                        browser&nbsp;page.
                    </span>
                )}
            </Container>
        );

        const customActions = [
            !forceRefresh &&
                (({ modalClose }) => ({
                    label: 'Remind me later',
                    isLined: true,
                    onClick: () => (
                        setTimeout(
                            () => dispatch(onClientVersionExpiredError(false)),
                            VERSION_EXPIRED_REMINDER_MS
                        ),
                        modalClose()
                    ),
                })),
            () => ({
                label: 'Reload',
                isPrimary: true,
                onClick: () => {
                    window.Intercom?.('shutdown');

                    dispatch(unsubscribe());

                    // prevent screen refresh updates happening when the modal closes, until browser refresh
                    pauseUpdates?.();
                    window.location.reload();
                },
            }),
        ].filter(Boolean);

        if (forceRefresh) {
            window.Intercom?.('shutdown');

            dispatch(unsubscribe());
        }

        dispatch(
            openConfirm({
                header,
                helpLink,
                content,
                customActions,
                closeIcon: false,
            })
        );
    };
}

export function onUserDetailsUpdatedNotification() {
    const header = 'Please sign in again';
    const helpLink = 'sign-in-again';
    const content = (
        <Container>
            <span>
                You were signed out of your account. Please select ‘Reload’ to
                sign in to MinuteMe&nbsp;again.
            </span>
        </Container>
    );

    return displayMessageThenReloadBrowser(
        { header, helpLink, content },
        { pauseUpdates }
    );
}

export const reloadBrowser = () => {
    const isLoginPage = location.pathname.match(/^\/login/);
    if (isLoginPage) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line unicorn/better-regex
        const redirect = location.search.match(/redirect=([/\-_\w]+)/);
        if (redirect) {
            return location.replace(redirect[1]);
        }
        return location.replace('/');
    }
    location.reload(true);
};
