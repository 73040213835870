import React from 'react';

export default ({ iconThick = true, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m17.13 6.8936h-9.3179c-2.2091 0-4 1.7909-4 4v14.656c0 2.2091 1.7909 4 4 4h15.562c2.2091 0 4-1.7909 4-4v-8.5418"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="m21.031 3.8125h8.1563v8.1563"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="m29.188 3.8125-14.047 14.047"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
