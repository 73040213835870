import { useDispatch, useSelector } from 'react-redux';
import {
    confirm,
    reject,
    markAsRead,
    markAllAsRead,
    removeNewNotificationToast,
    showNewNotificationToast,
} from './notification.actions.js';
import {
    getNotifications,
    getUnreadNotifications,
    getPendingWorkspaceNotifications,
    notificationsSelector,
} from '~modules/notification/notification.selectors';

const useNotifications = () => useSelector(getNotifications);

const useNotificationById = (id) => {
    const notifications = useSelector(notificationsSelector);
    return notifications.find((n) => n.id === id);
};

const useMarkRead = () => {
    const dispatch = useDispatch();
    return (notification) => dispatch(markAsRead(notification));
};

const useMarkAllRead = () => {
    const dispatch = useDispatch();
    return (pendingMessages) => dispatch(markAllAsRead(pendingMessages));
};

const useReject = () => {
    const dispatch = useDispatch();
    return (notification) => dispatch(reject(notification));
};

const useConfirm = () => {
    const dispatch = useDispatch();
    return (notification) => dispatch(confirm(notification));
};

const useHasUnreadNotifications = () => {
    const unreadNotifications = useSelector(getUnreadNotifications);
    const unreadNotificationsNumber = unreadNotifications.length;
    return {
        hasUnreadNotifications: unreadNotificationsNumber > 0,
        unreadNotificationsNumber,
    };
};

const useShowNotificationToast = () => {
    const dispatch = useDispatch();

    return (shouldOpen = true) => {
        if (shouldOpen) return dispatch(showNewNotificationToast());

        dispatch(removeNewNotificationToast());
    };
};

const usePendingWorkspaceNotifications = () =>
    useSelector(getPendingWorkspaceNotifications);

export {
    useMarkRead,
    useReject,
    useConfirm,
    useNotifications,
    useMarkAllRead,
    useHasUnreadNotifications,
    useShowNotificationToast,
    useNotificationById,
    usePendingWorkspaceNotifications,
};
