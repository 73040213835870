/**
 * pure selectors are those that only refer to the minutes part of the redux store and do not select from/use any other part of the redux store.
 * There should be no imports from other selectors in this file.
 * This is important to make sure that there are no round-robin imports.
 */
import _filter from 'lodash/filter.js';
import _find from 'lodash/find.js';
import _pick from 'lodash/pick.js';
import ObjectID from 'bson-objectid';
import { createSelector } from 'reselect';
import { getStore } from '~common/store';
import {
    getMeetingIdSelector,
    getOccurrenceIdSelector,
} from '~common/selector.helpers';
import {
    isPrimaryItemNbr,
    newItemNbr,
    sortByItemNbr,
} from '~common/list.helpers';
import {
    AGENDA_CONTENT_SOURCE_AGENDAITEM,
    AGENDA_CONTENT_SOURCE_BLANK,
    RTE_TINYMCE,
} from '~common/constants';
import { createHashForString } from '~shared/utils';
import { isEmptyRTEContent } from '~shared/minutes';

export const getMinutesSelector = (state) => state.minutes.minutes;

export function calculateMinuteItemNbr({
    itemNbr = 0,
    above,
    prevItemNbr,
    toplevel,
}) {
    const isTopLevel = isPrimaryItemNbr(itemNbr);
    let newNbr = newItemNbr(itemNbr, toplevel || isTopLevel);

    if (toplevel) {
        if (isTopLevel && above) {
            newNbr = itemNbr;
        }
    } else {
        if (!above) {
            newNbr = newItemNbr(itemNbr, false);
        } else if (isTopLevel && Number(itemNbr) === 1) {
            newNbr = '1';
        } else if (isTopLevel) {
            newNbr = newItemNbr(prevItemNbr, false);
        } else {
            newNbr = itemNbr;
        }
    }

    return newNbr;
}

/**
 * Get a MinuteItem from the store by it's minute item Id.
 * @param {Object} [state] Redux store. If not provided, the global store will be used.
 * @param {Object|string} minuteItem minuteItem object or minuteItem.id
 */
export function getMinuteItemFromStoreById(state, minuteItem) {
    const _state = arguments.length === 2 ? state : getStore().getState();
    const _minuteItem = arguments.length === 2 ? minuteItem : arguments[0];

    if (!_minuteItem) return;

    // TODO: Convert next line to use "getId()"
    const id = typeof _minuteItem === 'string' ? _minuteItem : _minuteItem.id;
    return _find(getMinutesSelector(_state), { id });
}

/**
 * Get a MinuteItem from the store by it's occurrence and agendaItemId.
 * @param {Object} [state] Redux store. If not provided, the global store will be used.
 * @param {Object|string} occurrence occurrence object or occurrence.id
 * @param {Object|string} agendaItem agendaItem object or agendaItem.id
 */
export function getMinuteItemFromStoreByOccurrenceAndAgendaItemId() {
    let state, occurrence, agendaItem;
    switch (arguments.length) {
        case 3:
            [state, occurrence, agendaItem] = arguments;
            break;
        case 2:
            [occurrence, agendaItem] = arguments;
            state = getStore().getState();
    }

    if (!occurrence || !agendaItem) return;

    // TODO: Convert next line to use "getId()"
    const occurrenceId =
        typeof occurrence === 'string' ? occurrence : occurrence.id;
    // TODO: Convert next line to use "getId()"
    const agendaItemId =
        typeof agendaItem === 'string' ? agendaItem : agendaItem.id;
    return getMinutesSelector(state).find(
        (minuteItem) =>
            minuteItem.occurrence === occurrenceId &&
            minuteItem.agendaItem === agendaItemId
    );
}

/**
 * Memoized selector to get minute items for an occurrence from the Store.
 * This should only be used for getting the occurrence for the current occurrence.
 * Otherwise you will break the memoizer. In other situations, use getMinutesFromStoreByOccurrenceId.
 * @param {Object} state Redux store.
 * @param {props} props
 * @param {(Object|string)} props.occurrence Occurrence object or occurrence.id
 */
export const getMinutesForOccurrenceSelector = createSelector(
    getMinutesSelector,
    getOccurrenceIdSelector,
    (minutes, occurrence) => {
        const filtered = _filter(minutes, { occurrence });
        return filtered.sort(sortByItemNbr);
    }
);

/**
 * Returns minute items for an occurrence from the store, by searching the store.
 * This is the non-memoized version.
 * For the memoized version, use getMinutesForOccurrenceSelector
 * @param {Object} [state=getStore().getState()] Redux store. If ommitted, will use the default Redux store.
 * @param {string} id occurrence.id
 */
export function getMinutesFromStoreByOccurrenceId() {
    let state, id;
    switch (arguments.length) {
        case 2:
            [state, id] = arguments;
            break;
        default:
            [id] = arguments;
            state = getStore().getState();
    }
    return _filter(
        state.minutes.minutes,
        (minuteItem) => minuteItem.occurrence === id
    );
}

export const getMinutesByMeetingSelector = createSelector(
    getMinutesSelector,
    getMeetingIdSelector,
    (minutes, meetingId) =>
        minutes.filter((minuteItem) => minuteItem.meeting === meetingId)
);

export const getMinuteItemsDefaultContent = (
    occurrence,
    agendaItems,
    previousOccurrencesMinuteItems
) =>
    agendaItems.map((i) => {
        const item = _pick(i, [
            'itemTitle',
            'itemNbr',
            'itemAllocatedTime',
            'assignee',
            'content',
            'meeting',
            'rte',
        ]);

        let content,
            minuteItem,
            rte = RTE_TINYMCE,
            documents = [];
        const isBlankSource = i.contentSource === AGENDA_CONTENT_SOURCE_BLANK;
        const isAgendaItemSource =
            i.contentSource === AGENDA_CONTENT_SOURCE_AGENDAITEM;
        if (i.copyContentForward === true) {
            // look up the previous minute item if it has the same agendaItem id
            const prevMeetingMinuteItem = previousOccurrencesMinuteItems.find(
                (prev) => prev.agendaItem === i.id
            );
            const hasPreviousMinuteItemContent = !isEmptyRTEContent(
                prevMeetingMinuteItem?.content
            );
            if (hasPreviousMinuteItemContent) {
                content = prevMeetingMinuteItem.content;
                minuteItem = prevMeetingMinuteItem.id;
                rte = prevMeetingMinuteItem.rte;
                documents = [...(prevMeetingMinuteItem.documents || [])];
            } else {
                if (isBlankSource) {
                    content = undefined;
                } else if (isAgendaItemSource) {
                    content = i.content;
                    rte = i.rte;
                }
            }
        } else {
            if (isBlankSource) {
                content = undefined;
            } else if (isAgendaItemSource) {
                content = i.content;
                rte = i.rte;
            }
        }

        return {
            id: ObjectID().toString(),
            ...item,
            agendaItem: i.id,
            agendaItemContentHashKey: createHashForString(content),
            occurrence: occurrence.id,
            documents,
            minuteItem,
            content,
            rte,
        };
    });
