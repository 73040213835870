import React from 'react';

export default ({ title = 'Zoom meeting', ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        {title && <title>{title}</title>}
        <path
            d="M16 31C24.2842 31 31 24.2842 31 16C31 7.71575 24.2842 1 16 1C7.71575 1 1 7.71575 1 16C1 24.2842 7.71575 31 16 31Z"
            fill="#2D8CFF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.356 11.1696L21.1695 14.1337V17.8777L25.356 20.8418C25.6522 21.0614 26 21.1293 26 20.5298V11.4816C26 10.9471 25.7209 10.8799 25.356 11.1696ZM7 18.2501V11.5002C7 11.2241 7.23275 11 7.51986 11H17.6643C19.2438 11 20.5255 12.2312 20.5255 13.7499V20.4998C20.5255 20.7759 20.2927 21 20.0056 21H9.86117C8.28161 21 7 19.7688 7 18.2501Z"
            fill="white"
        />
    </svg>
);
