/**
 * Routes
 */
export const ROUTES = {
    welcome: '/welcome',
    actionItems: '/action-items',
    dashboardSchedule: '/meetings',
    dashboardSeries: '/series',
    search: '/search',
    templates: '/templates',
    workspaces: '/workspaces',
    notificationsOpen: '/meetings/notifications',
    integration: '/settings/integration',
    hiddenEvents: '/meetings?e=hidden',
};

/**
 * Here we capture a list of all top level authenticated web routes that are handled by React.
 * This is where the user may nativate directly to this route (e.g. from an external link, favourite etc...)
 * and we want to make sure the user only chooses valid routes for express router.get('*', ...) to handle. Otherwise it will be an invalid 404.
 * NOTE: some routes (such as /files/choose-location are handled before the '*' router and so are not required to be handled here)
 *       However, if routes that are processed before the '*' router have a next() in them, and need to be processed in the client, (e.g. /invite, /request) they should be added here.
 */

const DASHBOARD_ROUTES = [
    'action-items',
    'schedule', // the original route for the meetings dashboard, and still catered for in front-end code in case users have old favourites or emails
    'meetings',
    'series',
    'search',
    'templates',
    'workspaces',
];
const INVITE_ROUTES = ['invite', 'request', 'welcome'];
const LOGIN_ROUTES = ['login', 'resetpassword', 'logout', 'signup'];
const USER_PROFILE_ROUTES = ['settings'];
const MEETING_DETAIL_ROUTES = ['occurrence', 'meeting'];
const MISC_ROUTES = ['help', 'connect'];
export const TOP_LEVEL_WEB_ROUTES_REG = new RegExp(
    `^/(?:$|${DASHBOARD_ROUTES.join('|')}|${INVITE_ROUTES.join(
        '|'
    )}|${LOGIN_ROUTES.join('|')}|${USER_PROFILE_ROUTES.join(
        '|'
    )}|${MEETING_DETAIL_ROUTES.join('|')}|${MISC_ROUTES.join('|')})`,
    'i'
);
