import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <rect
            x="10"
            y="2"
            width="12"
            height="12"
            rx="2"
            fill="#E2E8F0"
            stroke="#E2E8F0"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m2 20c0-1.1046 0.89543-2 2-2h8c1.1046 0 2 0.8954 2 2v8c0 1.1046-0.8954 2-2 2h-8c-1.1046 0-2-0.8954-2-2v-8z"
            fill="#E2E8F0"
            stroke="#E2E8F0"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
        <path
            d="m18 20c0-1.1046 0.8954-2 2-2h8c1.1046 0 2 0.8954 2 2v8c0 1.1046-0.8954 2-2 2h-8c-1.1046 0-2-0.8954-2-2v-8z"
            fill="#E2E8F0"
            stroke="#E2E8F0"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
