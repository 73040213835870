import _get from 'lodash/get.js';
import _map from 'lodash/map.js';
export * from './date.helpers.js';
export * from './delayedAction.js';
export * from './hash.js';
export * from './list.helpers.js';
export * from './pluralize.js';
export * from './email.helpers.js';

export const removeWhiteSpaces = (string = '') => string.replace(/\s/g, '');

/**
 * Sorting helpers
 * Using _get allows fieldName to be sub-keys such as 'occurrenceMeta.startDate'
 */
export const sortByField = (data, fieldName) =>
    data.sort(function (a, b) {
        return _get(a, fieldName) - _get(b, fieldName);
    });

export const sortByStringField = (
    data,
    fieldName,
    { caseSensitive = false } = {}
) =>
    data.sort(function (a, b) {
        const aField = caseSensitive
            ? a?.[fieldName]
            : a?.[fieldName]?.toLowerCase?.();
        const bField = caseSensitive
            ? b?.[fieldName]
            : b?.[fieldName]?.toLowerCase?.();
        return aField > bField ? 1 : bField > aField ? -1 : 0;
    });

export const sortByDateField = (data, fieldName) =>
    data.sort(function (a, b) {
        return _get(a, fieldName).valueOf() - _get(b, fieldName).valueOf();
    });

export const sortByDateFieldDesc = (data, fieldName) =>
    data.sort(function (a, b) {
        return _get(b, fieldName).valueOf() - _get(a, fieldName).valueOf();
    });

export const sortByFieldDesc = (data, fieldName) =>
    data.sort(function (a, b) {
        return _get(b, fieldName) - _get(a, fieldName);
    });

export const sortWith = (arr, customFn) => _map(arr).sort(customFn);

export function formatInputText(inputText = '') {
    const isAlreadyHTML = inputText.match(/^<[\S\s]*>$/);
    if (isAlreadyHTML) return inputText;

    const text = inputText
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\r\n?|\n/g, '<br>');

    return text;
}

export const isBase64 = (str = '') => {
    if (str === '' || str.trim() === '') {
        return false;
    }
    try {
        return btoa(atob(str)) == str;
    } catch {
        return false;
    }
};
