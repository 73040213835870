import _filter from 'lodash/filter.js';
import _uniqBy from 'lodash/uniqBy.js';
import {
    FETCH_NOTIFICATIONS_SUCCESS,
    NOTIFICATION_CREATE,
    NOTIFICATION_UPDATE,
    NOTIFICATION_DELETE,
    UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
    UPDATE_USERPROFILE_WEBSOCKET,
    LOGOUT_SUCCESS,
} from '../../common/action.types.js';

const initialState = {
    isOpen: false,
    notifications: [],
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_SUCCESS: {
            const {
                page: { docs },
            } = action;

            return {
                ...state,
                notifications: _uniqBy(
                    [...docs, ...state.notifications],
                    'id'
                ).sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf()),
            };
        }

        case NOTIFICATION_UPDATE:
        case NOTIFICATION_CREATE: {
            return {
                ...state,
                notifications: _uniqBy(
                    [
                        action.notification,
                        ...(action.notifications || []),
                        ...state.notifications,
                    ].filter(Boolean),
                    'id'
                ).sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf()),
            };
        }

        case NOTIFICATION_DELETE: {
            return {
                ...state,
                notifications: _filter(
                    state.notifications,
                    (n) => n.id !== action.notification.id
                ),
            };
        }

        case UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET:
        case UPDATE_USERPROFILE_WEBSOCKET: {
            if (
                !state.notifications.some(
                    (notification) =>
                        notification.createdBy?.id === action.userProfile.id
                )
            )
                return state;

            return {
                notifications: state.notifications.map((notification) =>
                    notification.createdBy?.id !== action.userProfile.id
                        ? notification
                        : {
                              ...notification,
                              createdBy: action.userProfile,
                          }
                ),
            };
        }

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

export default notificationReducer;
