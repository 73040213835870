import React, { useState } from 'react';
import tw from 'twin.macro';
import { Tooltip, Spacer } from '~modules/common/components';
import { Button, Input } from '~modules/common/form-elements';
import Icon from '~icons';

const TooltipText = tw.div`text-body-dark leading-normal`;

const NewUserTooltip = ({
    allowDisplayNameUpdate,
    hasDisplayName,
    displayName,
    children,
    handleAddDisplayName,
}) => {
    const [updatedDisplayName, setUpdatedDisplayName] = useState(displayName);
    const newUserTooltipContent = ({ setIsOpen }) => (
        <Spacer spaceY="sm">
            <TooltipText>
                {hasDisplayName ? (
                    <>
                        Update the display name for{' '}
                        <strong>{displayName}</strong>
                    </>
                ) : (
                    'Set a display name for this member'
                )}{' '}
                as they have not yet joined MinuteMe:
            </TooltipText>
            <Input
                focusOnMount
                icon={<Icon name="add" iconThick />}
                placeholder={`${
                    hasDisplayName ? 'Update' : 'Add'
                } display name`}
                onChange={(evt) => {
                    setUpdatedDisplayName(evt.target.value.trim());
                }}
                defaultValue={displayName}
                onEnter={(input) => {
                    handleAddDisplayName(input.value.trim());
                    setIsOpen(false);
                }}
            />
            <Button
                isPrimary
                isSmall
                isFull
                onClick={() => {
                    handleAddDisplayName(updatedDisplayName);
                    setIsOpen(false);
                }}
            >
                Save
            </Button>
        </Spacer>
    );
    return allowDisplayNameUpdate ? (
        <Tooltip
            content={newUserTooltipContent}
            hoverable
            position="bottom left"
            offset={[-11, 1]}
        >
            {children}
        </Tooltip>
    ) : (
        children
    );
};

export default NewUserTooltip;
