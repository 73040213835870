import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M30 2L1.5 14L13.5 17.5M30 2L13.5 17.5M30 2L24 16.5L18 30.5L13.5 17.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
            strokeWidth={iconThick ? '2' : '1.5'}
        />
    </svg>
);
