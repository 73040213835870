import React from 'react';
import { useDrop } from 'react-dnd';
import { NavLink } from 'react-router-dom';
import tw, { styled, css } from 'twin.macro';
import {
    getFormattedMeetingDuration,
    getMeetingLocationLabel,
} from '~modules/meeting/meeting.helpers';
import { useIsLockedDueToPastMeeting } from '~hooks';
import DateDisplay from '~modules/common/components/DateDisplay.js';
import { ITEM_TYPE_MINUTEITEM } from '~common/constants';
import {
    onMinuteItemCopyViaDrop,
    onMinuteItemMoveViaDrop,
} from '~modules/minutes/minutes.actions';
import { dateFullShort } from '~common/time.utils';
import { OccurrenceArchiveMessage } from '~modules/occurrence/OccurrenceArchiveMessage';
import { JoinOnlineButton } from '~modules/dashboard/meetings/components';
import { OccurrenceCountdownTimer } from '~modules/occurrence/components';
import {
    IconContainer,
    RepeatingIcon,
    ArchivedIcon,
    PrivateIcon,
    RemoteCalendarIcon,
    RemoteCRMIcon,
} from '~modules/common/components/MeetingIcons';

const NavLinkStyled = tw(
    NavLink
)`text-body block hover:text-body-dark focus:text-body-dark`;
const Container = tw.div`flex justify-between`;

const activeContainerStyles = (accentColor) => css`
    ${tw`w-full relative bg-white shadow-active border-line border-r-0 xl:(border-r border-dashed)`}
    &:before {
        content: '';
        ${tw`absolute left-0 top-0 bottom-0`}
        width: 3px;
        ${accentColor && `background-color: ${accentColor};`}
    }
`;

const Row = styled('div', {
    shouldForwardProp: (prop) =>
        !['hasActive', 'isActive', 'accentColor', 'isOver', 'canDrop'].includes(
            prop
        ),
})`
    ${tw`flex bg-transparent text-left leading-tight`}
    ${({ hasActive, isActive, accentColor, isOver, canDrop }) => [
        hasActive &&
            css`
                ${tw`w-full py-3 overflow-hidden pl-2 pr-4 border-r border-transparent rounded-l focus:outline-none`}
                a.active & {
                    ${activeContainerStyles(accentColor)}
                }
            `,
        isActive && activeContainerStyles(accentColor),
        isOver && canDrop && tw`bg-teal/20`,
    ]}
`;
const Content = styled.div`
    ${tw`flex flex-col`}
    ${({ isLarge }) => !isLarge && tw`text-sm`}
`;
const TitleWrap = styled.div`
    ${tw`leading-snug flex items-start`}
    ${({ hasSecondaryTitle }) =>
        hasSecondaryTitle ? tw`text-body` : tw`text-body-dark`}
`;
const Time = styled.div`
    ${({ hasActive }) =>
        hasActive &&
        css`
            a.active & {
                ${tw`text-body-dark`}
            }
        `}
`;

const DateWrap = styled.div`
    /* Visually align to text */
    margin-top: 0.15rem;
    ${tw`pr-3`};
`;

const DetailWrap = styled.div`
    margin-top: 0.15rem;
    ${tw`flex items-baseline`};
`;

const Detail = tw.span`hidden sm:block`;

const Divider = tw.span`hidden sm:block px-2 text-icon`;

const Item = ({
    occurrenceId,
    meetingId,
    meetingName,
    date,
    toDate,
    dateActual,
    toDateActual,
    isActive,
    accentColor,
    isRecurring,
    isArchived,
    isPrivate,
    isAdmin,
    isNotStarted,
    hasActive,
    hasSecondaryTitle,
    isLarge,
    hasLocation,
    location,
    locationOnline,
    disabled,
    isOver,
    canDrop,
}) => {
    const locationLabel = getMeetingLocationLabel(location, locationOnline);
    const occurrenceDate = dateActual || date;
    const occurrenceEndDate = toDateActual || toDate;

    const meetingDuration = getFormattedMeetingDuration(
        occurrenceDate,
        occurrenceEndDate
    );

    const occurrenceIsToday = occurrenceDate.isSame(new Date(), 'day');

    const timeFormattedForDisplay = occurrenceIsToday
        ? `${occurrenceDate.formatCalendarTimeForRange()} (${meetingDuration})`
        : occurrenceDate.formatCalendarTimeForRange();

    const icons = (
        <IconContainer tw="mt-[0.2em]">
            {isRecurring && <RepeatingIcon />}
            {isArchived && <ArchivedIcon />}
            <RemoteCalendarIcon id={occurrenceId} />
            <RemoteCRMIcon meetingId={meetingId} />
            {isPrivate && <PrivateIcon />}
        </IconContainer>
    );

    // Build partial occurrence to pass to countdown timer
    const partialOccurrence = {
        id: occurrenceId,
        startDate: date,
        endDate: toDate,
        startDateActual: dateActual,
        endDateActual: toDateActual,
        meeting: meetingId,
    };

    return (
        <Container>
            <Row {...{ hasActive, isActive, accentColor, isOver, canDrop }}>
                <DateWrap {...{ isLarge }}>
                    <DateDisplay
                        {...{
                            date,
                            isActive,
                            hasActive,
                            accentColor,
                            disabled,
                        }}
                        size={isLarge ? 'medium' : 'small'}
                    />
                </DateWrap>
                <Content {...{ isLarge }}>
                    <TitleWrap {...{ hasSecondaryTitle }}>
                        <div>{meetingName}</div>
                        {icons}
                    </TitleWrap>
                    <DetailWrap>
                        <Time
                            {...{
                                isActive,
                                date,
                                toDate,
                                dateActual,
                                toDateActual,
                            }}
                        >
                            <OccurrenceArchiveMessage
                                {...{
                                    occurrenceId,
                                    date,
                                    isAdmin,
                                    isArchived,
                                    isNotStarted,
                                }}
                            >
                                <OccurrenceCountdownTimer
                                    occurrence={partialOccurrence}
                                    isSimple
                                    displayWhenTimerNotActive={
                                        timeFormattedForDisplay
                                    }
                                />
                            </OccurrenceArchiveMessage>
                        </Time>
                        {hasLocation && (
                            <>
                                <Divider>&middot;</Divider>
                                <Detail>{locationLabel}</Detail>
                            </>
                        )}
                    </DetailWrap>
                </Content>
            </Row>
            <JoinOnlineButton
                startDate={date}
                endDate={toDate}
                isObjectWrapped
                {...{ locationOnline }}
            />
        </Container>
    );
};

const MeetingItem = ({ to, handleOnClick, ...rest }) =>
    to || handleOnClick ? (
        <NavLinkStyled {...{ to }} onClick={() => handleOnClick?.()}>
            <Item {...rest} hasActive />
        </NavLinkStyled>
    ) : (
        <Item {...rest} />
    );

const MeetingItemDropTarget = ({ to, handleOnClick, isCopyTopic, ...rest }) => {
    const {
        meetingName,
        occurrenceId: targetOccurrence,
        date,
        toDate,
        status,
    } = rest;

    const partialOccurrence = {
        id: targetOccurrence,
        startDate: date,
        endDate: toDate,
        status,
    };
    const isLockedDueToPastMeeting =
        useIsLockedDueToPastMeeting(partialOccurrence);

    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: ITEM_TYPE_MINUTEITEM,
            canDrop: (props) => {
                const { occurrenceId, isPersistedTopic } = props;
                // prevent dropping if the topic you're trying to drag is part of an agenda template that hasn't been persisted yet
                const onSameMeeting = targetOccurrence === occurrenceId;
                if (onSameMeeting || !isPersistedTopic) return false;
                // prevent dropping if the target occurrence is locked due to being in the past
                return !isLockedDueToPastMeeting;
            },
            drop: (props, monitor) => {
                const item = monitor.getItem();
                const isCopy = isCopyTopic();
                isCopy
                    ? onMinuteItemCopyViaDrop(item.id, targetOccurrence, {
                          to: `${meetingName} on ${dateFullShort(date)}`,
                      })
                    : onMinuteItemMoveViaDrop(item.id, targetOccurrence, {
                          to: `${meetingName} on ${dateFullShort(date)}`,
                      });
                // prevent the drop processing in MinutesListTopicRow.endDrag
                return {
                    isCopyTopic: isCopy,
                    isHandled: true,
                };
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [isLockedDueToPastMeeting]
    );
    return (
        <div ref={drop}>
            {to || handleOnClick ? (
                <NavLinkStyled {...{ to }} onClick={() => handleOnClick?.()}>
                    <Item hasActive {...{ isOver, canDrop }} {...rest} />
                </NavLinkStyled>
            ) : (
                <Item {...rest} {...{ isOver }} />
            )}
        </div>
    );
};

export { MeetingItem as default, MeetingItemDropTarget };
