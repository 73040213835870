import moment from 'moment-timezone';

export function workspaceInvoice(item) {
    if (!item) return;

    const moments = {
        createdAt: formatDate(item.createdAt),
        endDate: formatDate(item.endDate),
        isPast: new Date(item.endDate) <= new Date(),
        finalUsageReportedAt: formatDate(item.finalUsageReportedAt),
        lastEventDate: formatDate(item.lastEventDate),
        previousUsageReportedAt: formatDate(item.previousUsageReportedAt),
        startDate: formatDate(item.startDate),
        updatedAt: formatDate(item.updatedAt),
        paymentMeta: {
            ...item.paymentMeta,
            nextPaymentAttemptDate: formatDate(
                item.paymentMeta.nextPaymentAttemptDate
            ),
        },
        members: item.members.map((member) => ({
            ...member,
            lastEventDate: formatDate(member.lastEventDate, 'D MMM YYYY'),
        })),
        sortDate: moment(item.endDate).valueOf(),
    };

    const isCurrentCycle = !item.finalUsageReportedAt;

    return { ...item, ...moments, isCurrentCycle };
}

const formatDate = (jsDate, format) =>
    jsDate
        ? format
            ? moment(jsDate).format(format)
            : moment(jsDate).formatForUserYearShortL('L')
        : jsDate;

export const workspaceInvoiceList = (items = []) => items.map(workspaceInvoice);
