import { get, put, post, deleteRequest } from '../../api/helpers.js';

export function getGroup(groupId) {
    return get(`/api/group/${groupId}`);
}

export function addGroup(group) {
    return put('/api/group', group);
}

export function deleteGroup(group) {
    return deleteRequest(`/api/group/${group.id}`);
}

export function editGroup(group) {
    return post(`/api/group/${group.id}`, group);
}

export function starGroupMeeting(group, meeting) {
    return post(`/api/group/${group.id}/meeting/${meeting.id}/star`);
}

export function unstarGroupMeeting(group, meeting) {
    return deleteRequest(`/api/group/${group.id}/meeting/${meeting.id}/star`);
}

export function moveMeetingToAnotherGroup(id, data) {
    const url = `/api/group/meeting/${id}/move`;
    return post(url, data);
}

export function addOccurrenceToGroup(group, id) {
    const url = `/api/group/${group.id}/occurrence/${id}`;
    return put(url, group);
}

export function removeOccurrenceFromGroup(group, id) {
    const url = `/api/group/${group.id}/occurrence/${id}`;
    return deleteRequest(url);
}
