import React from 'react';
import tw, { styled } from 'twin.macro';

const Container = styled.div`
    ${tw`select-none`}
    & + & {
        ${tw`mt-8`}
    }
`;
const Title = styled.div`
    ${tw`font-semibold text-sm tracking-wider leading-tight uppercase`}
`;
const List = styled.ul`
    ${Title} + & {
        ${tw`mt-3`}
    }
`;
const ListItem = styled.li`
    ${Title} + & {
        ${tw`mt-3`}
    }
`;

const Child = (item, index) => <ListItem key={index}>{item}</ListItem>;

export default ({ children, title }) => (
    <Container>
        {title && <Title>{title}</Title>}
        {children && (
            <List>
                {Array.isArray(children)
                    ? children.map(Child)
                    : Child(children, 0)}
            </List>
        )}
    </Container>
);
