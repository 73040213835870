import React from 'react';

// This component conditionally applies stopPropagation and preventDefault. e.g to allow popups to render within a link component
const PreventBubble = ({ renderWrap, children }) =>
    renderWrap ? (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {children}
        </div>
    ) : (
        children
    );

export default PreventBubble;
