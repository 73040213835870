// INFO: Refer to https://medium.com/making-internets/why-using-chain-is-a-mistake-9bc1f80d51ba for discussion about lodash _() /  _.chain methods
import _memoize from 'lodash/memoize.js';
import _map from 'lodash/map.js';
import _matches from 'lodash/matches.js';
// TODO: Find out why _.mixin can't be cherry pick imported
import _ from 'lodash';

const BaseCache = _memoize.Cache;

class ExtendedMapCache extends BaseCache {
    deleteByQuery(query) {
        const it = this.__data__.map.keys();

        let result = it.next();
        const deletionKeys = [];

        while (!result.done) {
            if (_matches(result.value, query)) {
                deletionKeys.push(result.value);
            }

            result = it.next();
        }

        for (const key of deletionKeys) {
            this.delete(key);
        }
    }
}

_.mixin({
    sortWith: function(arr, customFn) {
        return _map(arr).sort(customFn);
    }
});

_memoize.Cache = ExtendedMapCache;
