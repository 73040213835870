import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { motion } from 'framer-motion';
import Icon from '~icons';
import { Global } from '@emotion/react';

const variantsContent = {
    closed: {
        opacity: 0,
        transition: { duration: 0.15, ease: 'easeIn' },
    },
    open: {
        opacity: 1,
        transition: { duration: 0.25, ease: 'easeOut', delay: 0.2 },
    },
};

const variantsUnderlay = {
    closed: {
        opacity: 0,
        transition: { duration: 0.3, ease: 'easeOut', delay: 0.3 },
    },
    open: { opacity: 0.45, transition: { duration: 0.3, ease: 'easeOut' } },
};

const variantsBackground = {
    closed: {
        scaleY: 0,
        transition: { duration: 0.25, ease: 'easeIn', delay: 0.1 },
    },
    open: { scaleY: 1, transition: { duration: 0.25, ease: 'easeOut' } },
};

const variantsButtonClose = {
    closed: {
        translateY: 100,
        transition: { duration: 0.25 },
    },
    open: {
        translateY: 0,
        transition: { duration: 0.25 },
    },
};

const modalVariantsButtonClose = {
    closed: {
        translateY: 100,
        transition: { duration: 0.25 },
        display: 'none',
    },
    open: {
        translateY: 0,
        transition: { duration: 0.4 },
        display: 'block',
    },
};

const animationProps = (isOpen) => ({
    initial: isOpen ? 'open' : 'closed',
    animate: isOpen ? 'open' : 'closed',
});

const ButtonTopBarToggle = styled.button(({ isOpen, isModal }) => [
    tw`z-20 bg-solid fixed left-0 right-0 w-full pointer-events-auto top-header-horizontal-height h-sidebar-horizontal-height`,
    tw`focus:(outline-none bg-solid) active:bg-white`,
    tw`border-b border-solid`,
    isOpen && tw`border-transparent`,
    !isOpen && tw`border-line`,
    isModal && tw`absolute top-0`,
]);

const Content = styled(motion.div, {
    shouldForwardProp: (prop) => !['isModal', 'isOpen'].includes(prop),
})(({ isOpen, isModal }) => [
    tw`fixed w-full origin-top z-10`,
    css({
        height: `calc(100vh - var(--header-horizontal-height) - var(--sidebar-horizontal-height) - var(--footer-horizontal-height))`,
        padding: '0 var(--content-padding-x)',
    }),
    !isOpen && [
        tw`pointer-events-none`,
        css({ '*': tw`pointer-events-none!` }),
    ], // Make sure no collapsed elements are clickable
    isModal && [
        tw`static bg-solid height[calc(100% - var(--footer-horizontal-height))]`,
        //TODO: convert this calc back to tw once Ben provides fix for + in twin calc functions
        css({
            paddingTop: `calc((var(--content-padding-y) / 2) + var(--sidebar-horizontal-height))`,
        }),
    ],
]);

const Underlay = styled(motion.div, {
    shouldForwardProp: (prop) => !['isModal'].includes(prop),
})(({ isModal }) => [
    tw`fixed inset-0 bg-transparent`,
    !isModal && tw`bg-black`,
    isModal && tw`static`,
]);

const Background = styled(motion.div, {
    shouldForwardProp: (prop) => !['isModal'].includes(prop),
})(({ isModal }) => [
    tw`inset-0 fixed bg-solid border-b border-line border-solid origin-top`,
    isModal && tw`absolute top-0 zIndex[-1]`,
]);

const ButtonClose = styled(motion.button, {
    shouldForwardProp: (prop) => !['isModal'].includes(prop),
})(({ isModal }) => [
    tw`z-10 fixed bottom-0 inset-x-0 w-full bg-solid border border-t border-line text-center h-footer-horizontal-height`,
    tw`focus:(outline-none bg-solid) active:bg-white`,
    css({ boxShadow: '0 -2px 5px 0 rgba(0, 0, 0, 0.05)' }),
    isModal && tw`absolute`,
]);

const Label = tw.div`text-left truncate w-full`;
const ToggleInner = styled.div([
    tw`flex w-full items-center justify-between`,
    css({
        paddingLeft: 'var(--content-padding-x)',
        paddingRight: `calc(var(--content-padding-x) + .5rem)`,
    }),
]);

const TopBarMenu = ({ isOpen, label, children, onClick, isModal }) => (
    <div tw="h-full">
        <Global
            styles={{
                body: {
                    '--sidebar-horizontal-height': '50px',
                },
            }}
        />
        <Underlay
            {...animationProps(isOpen)}
            variants={variantsUnderlay}
            {...{ isModal }}
        />
        <Background
            {...animationProps(isOpen)}
            variants={variantsBackground}
            {...{ isModal }}
        />

        <Content
            {...animationProps(isOpen)}
            variants={variantsContent}
            {...{ isOpen, isModal }}
        >
            {children}
        </Content>

        <ButtonTopBarToggle type="button" {...{ isOpen, onClick, isModal }}>
            <ToggleInner>
                <Label>{label}</Label>
                <Icon
                    name="downArrow"
                    css={[
                        tw`flex-shrink-0`,
                        isOpen && tw`transform transition-transform -scale-100`,
                    ]}
                />
            </ToggleInner>
        </ButtonTopBarToggle>

        <ButtonClose
            type="button"
            variants={isModal ? modalVariantsButtonClose : variantsButtonClose}
            {...animationProps(isOpen)}
            {...{ onClick, isModal }}
        >
            Close
        </ButtonClose>
    </div>
);

export { TopBarMenu };
