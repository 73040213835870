import {
    CONFIRM_MODAL_OPEN,
    CONFIRM_MODAL_LOADING,
    CONFIRM_MODAL_PROCEED_SUCCESS,
    CONFIRM_MODAL_CANCEL,
    LOGOUT_SUCCESS,
} from '~common/action.types';

const initialState = {
    visible: false,
    header: null,
    content: null,
    loading: false,
    promise: null,
};

const confirmReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONFIRM_MODAL_OPEN:
            return {
                ...state,
                ...action,
                visible: true,
            };

        case CONFIRM_MODAL_LOADING:
            return {
                ...state,
                loading: true,
            };

        case LOGOUT_SUCCESS:
        case CONFIRM_MODAL_PROCEED_SUCCESS:
        case CONFIRM_MODAL_CANCEL:
            return initialState;

        default:
            return state;
    }
};

export default confirmReducer;
