import { NavLink } from 'react-router-dom';
import {
    NOTIFICATION_OUTCOME_ACCEPTED,
    PERMISSION_STATUS_REQUESTED,
} from '~common/constants';
import { stringOrMomentToDate, fullDateTime } from '~common/time.utils';
import { getCurrentOrNextOccurrenceInfoFromStoreByMeeting } from '~modules/occurrence/occurrenceInfo.selectors';
import { getOccurrenceSlug, getDashboardSlug } from '~modules/navigation';

const notificationRedirects = {
    meeting: ({ notification }) => {
        const meetingId =
            notification?.meta?.meeting || notification?.meta?.target;
        const occurrences = getCurrentOrNextOccurrenceInfoFromStoreByMeeting({
            meeting: meetingId,
        });
        const id =
            occurrences?.occurrence?.id ||
            occurrences?.prev?.id ||
            occurrences?.meetingId;
        if (!id) return;
        return getOccurrenceSlug({ id });
    },
    workspace: ({ notification }) => {
        const workspaceId =
            notification?.meta?.workspace || notification?.meta?.target;
        const params = { dashboardType: 'meetings' };
        return getDashboardSlug({ id: workspaceId }, params);
    },
    occurrence: ({ notification }) => {
        const meetingId = notification?.meta?.target;
        return getOccurrenceSlug({ id: meetingId });
    },
};

const getNotificationRedirect = (params) => {
    const type = params.type || getTargetType(params.notification);
    const redirectType =
        notificationRedirects[type] || notificationRedirects['meeting'];
    return redirectType(params);
};

const isAccepted = (notification) =>
    // For notifications
    notification.meta.permissionStatus === NOTIFICATION_OUTCOME_ACCEPTED ||
    // For invitations
    notification.outcome === NOTIFICATION_OUTCOME_ACCEPTED;

const isRequested = (notification) =>
    notification.meta.permissionStatus === PERMISSION_STATUS_REQUESTED;

const titleComponents = (type) =>
    ({
        workspace: ({ value, notification, key }) => {
            const to = getNotificationRedirect({
                type: 'workspace',
                notification,
            });
            if (!to) return titleComponents('default')({ key, value });

            return (
                <NavLink to={to} key={key}>
                    {value}
                </NavLink>
            );
        },
        meeting: ({ value, notification, key }) => {
            const to = getNotificationRedirect({
                type: 'meeting',
                notification,
            });
            if (!to) return titleComponents('default')({ key, value });

            return (
                <NavLink to={to} key={key}>
                    {value}
                </NavLink>
            );
        },
        occurrence: ({ value, notification, key }) => {
            const to = getNotificationRedirect({
                type: 'occurrence',
                notification,
            });
            if (!to) return titleComponents('default')({ key, value });

            return (
                <NavLink to={to} key={key}>
                    {value}
                </NavLink>
            );
        },
        default: ({ key, value }) => <em key={key}>{value}</em>,
    }[type]);

const getMeetingPlaceholderType = (value) =>
    (value === 'targetTitle' && 'meeting') ||
    (value === 'workspaceTitle' && 'workspace') ||
    'default';

const getWorkspacePlaceholderType = (value) =>
    (value === 'targetTitle' && 'workspace') || 'default';

const getOccurrencePlaceholderType = (value) =>
    (value === 'targetTitle' && 'occurrence') ||
    (value === 'workspaceTitle' && 'workspace') ||
    'default';

const getTitleComponentType = ({ placeholder, notification }) => {
    const targetType = notification?.meta?.targetType;

    return (
        (!isAccepted(notification) &&
            !isRequested(notification) &&
            'default') ||
        (targetType === 'meeting' && getMeetingPlaceholderType(placeholder)) ||
        (targetType === 'workspace' &&
            getWorkspacePlaceholderType(placeholder)) ||
        (targetType === 'occurrence' &&
            getOccurrencePlaceholderType(placeholder)) ||
        'default'
    );
};

const getNotificationTitle = ({ placeholder, notification, value, index }) => {
    // Date
    const parameterDate = stringOrMomentToDate(value);
    if (parameterDate)
        return titleComponents('default')({
            value: fullDateTime(parameterDate),
            key: `${notification.id}${index}`,
        });

    // Meeting / Workspace / Other
    const type = getTitleComponentType({ placeholder, notification });
    return titleComponents(type)({
        value,
        key: `${notification.id}${index}`,
        notification,
    });
};

const getTargetType = (notification) => {
    if (!notification) return;
    const { meeting, targetType } = notification?.meta || {};
    // Redirect to meeting detail if there's workspace meeting data
    return targetType === 'workspace' && meeting ? 'meeting' : targetType;
};

export { getNotificationTitle, getNotificationRedirect, getTargetType };
