import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSearchParams } from '~modules/navigation/navigation.selectors';
import {
    SEARCH_PARAM_FILTER_MEETINGS,
    SEARCH_PARAM_FILTER_TAGS,
    SEARCH_PARAM_FILTER_MEETING_TYPES,
    SEARCH_PARAM_FILTER_ACTIONS,
    SEARCH_PARAM_FILTER_ACTIONS_BY_ASSIGNEE,
    SEARCH_PARAM_FILTER_EVENTS,
    SEARCH_PARAM_FILTER_ACTIONS_BY_PRIORITY,
} from '~common/constants';
import {
    getPageMeta,
    getDashboardOccurrenceDates,
} from '~modules/dashboard/dashboard.selectors';
import { getGroupSelector } from '~modules/group/group.selectors';
import {
    getDashboardPathParams,
    getDashboardParams,
} from '~modules/navigation';
import { useGroupCurrent, useWorkspaces } from '~hooks';
import { getDashboardState } from '~modules/pageView/pageView.selectors';
import { updateDashboardState } from '~modules/pageView/pageView.actions';

export const useSearchMeetingData = ({ items, itemsByGroup, searchText }) => {
    const filteredOptions = useMemo(
        () =>
            searchText?.length > 0
                ? items?.filter((item) =>
                      item?.meeting?.title
                          ?.toLowerCase()
                          .includes(searchText?.toLowerCase())
                  )
                : items,
        [items, searchText]
    );

    const filteredOptionsByGroup = useMemo(
        () =>
            searchText?.length > 0
                ? itemsByGroup
                      ?.map((group) => {
                          const items = group.items.filter((item) =>
                              item?.meeting?.title
                                  ?.toLowerCase()
                                  .includes(searchText?.toLowerCase())
                          );
                          if (!items.length) return;
                          return { ...group, items };
                      })
                      .filter(Boolean)
                : itemsByGroup,
        [itemsByGroup, searchText]
    );

    return { filteredOptionsByGroup, filteredOptions };
};

export const useSearchParams = () => {
    const history = useHistory();
    const { location } = history;
    const searchParamsRaw = useSelector((state) =>
        getSearchParams(state, { location })
    );

    const permittedParams = [
        SEARCH_PARAM_FILTER_MEETINGS,
        SEARCH_PARAM_FILTER_TAGS,
        SEARCH_PARAM_FILTER_MEETING_TYPES,
        SEARCH_PARAM_FILTER_EVENTS,
        SEARCH_PARAM_FILTER_ACTIONS,
        SEARCH_PARAM_FILTER_ACTIONS_BY_ASSIGNEE,
        SEARCH_PARAM_FILTER_ACTIONS_BY_PRIORITY,
    ];

    const searchParams = Object.entries(searchParamsRaw).reduce(
        (result, [k, v]) =>
            permittedParams.includes(k) ? { ...result, [k]: v } : result,
        {}
    );

    return searchParams;
};

export const useDashboardMeta = () => {
    const history = useHistory();
    const { pathname } = history?.location || {};

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
    const [, , groupId] = getDashboardPathParams(pathname);
    const groupFromStore = useSelector((state) =>
        getGroupSelector(state, { group: groupId })
    );
    const pageMeta = useSelector((state) =>
        getPageMeta(state, { pathname, group: groupFromStore })
    );

    return pageMeta;
};

/**
 * Grab a list of workspaces to display in the Sidebar, based on the current URL being viewed
 */
export const useDashboardWorkspacesCurrent = ({
    excludeRequested = false,
} = {}) => {
    const groupCurrent = useGroupCurrent(); // could be a category, workspace or an empty object if on a special dashboard
    const workspaces = useWorkspaces();
    return useMemo(() => {
        const filteredWorkspaces = workspaces.filter(
            // Hide if hidden but not if you're viewing the workspace
            ({ isHidden, id, myAccessLevel }) =>
                !excludeRequested
                    ? !isHidden || id === groupCurrent.id
                    : !myAccessLevel.isRequested &&
                      (!isHidden || id === groupCurrent.id)
        );
        // If there's no workspaces after filtering then ignore the filter
        const visibleWorkspaces =
            !excludeRequested && filteredWorkspaces.length === 0
                ? workspaces
                : filteredWorkspaces;

        return { visibleWorkspaces };
    }, [groupCurrent, workspaces, excludeRequested]);
};

export const useDashboardOccurrenceDates = () => {
    const history = useHistory();
    const [, dashId] = useDashboardId();

    const dates = useSelector((state) =>
        getDashboardOccurrenceDates(state, {
            location: history?.location,
            group: dashId,
        })
    );

    return dates;
};

export const useDashboardState = () => {
    const [dashboardId] = useDashboardId();

    const dashboardState = useSelector((state) =>
        getDashboardState(state, dashboardId)
    );

    return dashboardState;
};

export const useDashboardId = () => {
    const pageMeta = useDashboardMeta();
    const history = useHistory();
    const { dashName, dashId } = getDashboardParams(history?.location.pathname);
    const dashboardId = dashName ? `${dashName}-${dashId}` : pageMeta?.id;

    return [dashboardId, dashId];
};

export const useJumpToDate = () => {
    const dispatch = useDispatch();
    return (date, dashboardId) =>
        dispatch(updateDashboardState(dashboardId, { jumpToDate: date }));
};
