import _omit from 'lodash/omit.js';
import _uniqBy from 'lodash/uniqBy.js';
import {
    MODAL_ADD_REMOTE_CALENDAR_MEETING_OPEN,
    MODAL_ADD_REMOTE_CALENDAR_MEETING_CLOSE,
    REMOTE_CALENDAR_EVENTS_LOADING,
    REMOTE_CALENDAR_EVENTS_LOADED,
    REMOTE_CALENDAR_EVENTS_CLEAR,
    REMOTE_CALENDAR_EVENTS_UPDATED,
    REMOTE_CALENDAR_EVENTS_DELETED,
    REMOTE_CALENDAR_EVENT_HIDDEN,
    INTEGRATION_CALENDAR_LINKS_CREATED,
    INTEGRATION_CALENDAR_LINKS_DELETED,
    INTEGRATION_CALENDAR_LINKS_REQUEST,
    INTEGRATION_CALENDAR_LINKS_LOADED,
    UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
    MOVE_OCCURRENCE_DELETE,
    LOGOUT_SUCCESS,
    MOVE_OCCURRENCE_UPDATE,
} from '~common/action.types';
import {
    AUTHSOURCE_OUTLOOK_CALENDAR,
    AUTHSOURCE_GOOGLE_CALENDAR,
} from '~common/constants';
import { isNonRepeatingMeeting } from '~modules/meeting/meeting.helpers';

export const remoteCalendarModalMapping = {
    [AUTHSOURCE_OUTLOOK_CALENDAR]: 'remoteCalendarAddMeetingModal',
    [AUTHSOURCE_GOOGLE_CALENDAR]: 'remoteCalendarAddMeetingModal',
};

const initialState = {
    remoteCalendarAddMeetingModal: {
        visible: false,
    },
    [AUTHSOURCE_OUTLOOK_CALENDAR]: {
        isLoading: false,
        events: [],
        users: {},
    },
    [AUTHSOURCE_GOOGLE_CALENDAR]: {
        isLoading: false,
        events: [],
        users: {},
    },
    links: {
        data: {},
        isLoading: false,
        error: null,
    },
};

const remoteCalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_ADD_REMOTE_CALENDAR_MEETING_OPEN:
            return {
                ...state,
                remoteCalendarAddMeetingModal: {
                    ...state.remoteCalendarAddMeetingModal,
                    ..._omit(action, 'type'),
                    visible: true,
                },
            };

        case MODAL_ADD_REMOTE_CALENDAR_MEETING_CLOSE:
            return {
                ...state,
                remoteCalendarAddMeetingModal:
                    initialState.remoteCalendarAddMeetingModal,
            };

        case REMOTE_CALENDAR_EVENTS_LOADING: {
            return {
                ...state,
                [action.authSource]: {
                    ...state[action.authSource],
                    isLoading: true,
                },
            };
        }
        case REMOTE_CALENDAR_EVENTS_LOADED: {
            // NOTE: receipt of new events will add to existing events
            // Because what if user has multiple calendars they subscribe to
            return {
                ...state,
                [action.authSource]: {
                    ...state[action.authSource],
                    isLoading: false,
                    events: _uniqBy(
                        [...action.events, ...state[action.authSource].events],
                        'id'
                    ),
                    users: {
                        ...state[action.authSource].users,
                        ...action.users,
                    },
                },
            };
        }

        case REMOTE_CALENDAR_EVENTS_UPDATED: {
            const {
                profile,
                uid,
                events: actionEvents,
                users: actionUsers,
            } = action;

            // if no uid is provided, clear out all events
            // if there are only recurring events, delete all by this uid first.
            const hasOneOffEvents = actionEvents.some(isNonRepeatingMeeting);
            const events = !uid
                ? []
                : hasOneOffEvents
                ? state[profile.authSource]?.events || []
                : (state[profile.authSource]?.events || []).filter(
                      (event) => event.uid !== uid
                  );

            return {
                ...state,
                [profile.authSource]: {
                    ...state[profile.authSource],
                    isLoading: false,
                    events: _uniqBy([...actionEvents, ...events], 'id'),
                    users: {
                        ...state[profile.authSource].users,
                        ...actionUsers,
                    },
                },
            };
        }

        case REMOTE_CALENDAR_EVENTS_DELETED: {
            const { profile, eventIdList } = action;

            // prevent unnecessary state updates.
            // deleting a calendar item may be for an item outside of the 5 week range we retrieve for the user.
            // but because we don't have the calendar info any more, we don't know if this is the case or not.
            // so we will get every delete event from the user's calendar.
            if (
                !state[profile.authSource]?.events?.some(
                    (event) =>
                        eventIdList.includes(event.id) ||
                        eventIdList.includes(event?.meeting?.id)
                )
            ) {
                return state;
            }

            const events = (state[profile.authSource]?.events || []).filter(
                (event) =>
                    !eventIdList.includes(event.id) &&
                    !eventIdList.includes(event?.meeting?.id)
            );
            return {
                ...state,
                [profile.authSource]: {
                    ...state[profile.authSource],
                    isLoading: false,
                    events,
                },
            };
        }

        case REMOTE_CALENDAR_EVENTS_CLEAR: {
            return {
                ...state,
                [action.authSource]: {
                    ...state[action.authSource],
                    isLoading: false,
                    events: action.calendar
                        ? state[action.authSource].events.filter(
                              (event) => event.calendar !== action.calendar
                          )
                        : [],
                    users: action.calendar
                        ? state[action.authSource].users
                        : {},
                },
            };
        }

        case INTEGRATION_CALENDAR_LINKS_REQUEST: {
            return {
                ...state,
                links: {
                    ...state.links,
                    isLoading: true,
                    error: null,
                },
            };
        }
        case INTEGRATION_CALENDAR_LINKS_LOADED:
        case INTEGRATION_CALENDAR_LINKS_CREATED: {
            const { links } = action;
            if (!links.length) return state;

            const data = { ...state.links.data };
            links
                .filter(
                    (link) =>
                        link.occurrence || link.meeting || 'isHidden' in link
                )
                .forEach((link) => {
                    const occurrenceOrMeetingId =
                        link.occurrence || link.meeting;
                    occurrenceOrMeetingId &&
                        (data[occurrenceOrMeetingId] = link);
                    data[link.remoteId || link.id] = link;
                });

            return {
                ...state,
                links: {
                    ...state.links,
                    data,
                    isLoading: false,
                },
            };
        }

        case INTEGRATION_CALENDAR_LINKS_DELETED: {
            const { links } = action;
            if (!links.length) return state;

            const data = { ...state.links.data };
            links
                .filter(
                    (link) =>
                        link.occurrence || link.meeting || 'isHidden' in link
                )
                .forEach((link) => {
                    const occurrenceOrMeetingId =
                        link.occurrence || link.meeting;
                    occurrenceOrMeetingId && delete data[occurrenceOrMeetingId];
                    delete data[link.remoteId || link.id];
                });

            return {
                ...state,
                links: {
                    ...state.links,
                    data,
                },
            };
        }

        case UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET: {
            return {
                ...state,
                [AUTHSOURCE_OUTLOOK_CALENDAR]: {
                    ...state[AUTHSOURCE_OUTLOOK_CALENDAR],
                    users: {
                        ...state[AUTHSOURCE_OUTLOOK_CALENDAR].users,
                        [action.userProfile.email]: {
                            ...state[AUTHSOURCE_OUTLOOK_CALENDAR].users[
                                action.userProfile.email
                            ],
                            ...action.userProfile,
                        },
                    },
                },
                [AUTHSOURCE_GOOGLE_CALENDAR]: {
                    ...state[AUTHSOURCE_GOOGLE_CALENDAR],
                    users: {
                        ...state[AUTHSOURCE_GOOGLE_CALENDAR].users,
                        [action.userProfile.email]: {
                            ...state[AUTHSOURCE_GOOGLE_CALENDAR].users[
                                action.userProfile.email
                            ],
                            ...action.userProfile,
                        },
                    },
                },
            };
        }

        case REMOTE_CALENDAR_EVENT_HIDDEN: {
            if (!action?.id) return state;

            const staleEvents = state[action.calendarType].events;
            const events = staleEvents.map((event) => {
                if (event.meeting.id !== action.id) return event;
                return { ...event, isHidden: action.isHidden };
            });

            return {
                ...state,
                [action.calendarType]: {
                    ...state[action.calendarType],
                    events,
                },
            };
        }

        case MOVE_OCCURRENCE_DELETE: {
            if ((action.integrationLinkIds?.length || 0) === 0) return state;
            if (
                !action.integrationLinkIds.some(
                    (linkId) => state.links.data[linkId]
                )
            )
                return state;

            const data = { ...state.links.data };
            action.integrationLinkIds.forEach((linkId) => delete data[linkId]);
            return {
                ...state,
                links: {
                    ...state.links,
                    data,
                },
            };
        }

        case MOVE_OCCURRENCE_UPDATE: {
            if ((action.integrationLinkIds?.length || 0) === 0) return state;
            if (
                !action.integrationLinkIds.some(
                    (linkId) => state.links.data[linkId]
                )
            )
                return state;

            const data = { ...state.links.data };
            action.integrationLinkIds.forEach(
                (linkId) =>
                    (data[linkId] = {
                        ...data[linkId],
                        meeting: action.meeting,
                    })
            );
            return {
                ...state,
                links: {
                    ...state.links,
                    data,
                },
            };
        }

        case LOGOUT_SUCCESS:
        default:
            return state;
    }
};
export default remoteCalendarReducer;
