import _omit from 'lodash/omit.js';
import {
    MODAL_IMAGE_UPLOAD_OPEN,
    MODAL_IMAGE_UPLOAD_CLOSE,
} from '~common/action.types';

const initialState = {
    visible: false,
};

const imageUploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_IMAGE_UPLOAD_OPEN:
            return {
                ...state,
                ..._omit(action, 'type'),
                visible: true,
            };

        case MODAL_IMAGE_UPLOAD_CLOSE:
            return initialState;

        default:
            return state;
    }
};

export default imageUploadReducer;
