import { useDispatch, useSelector } from 'react-redux';
import {
    openAddMeetingModal,
    closeMeetingModal,
    updateUserDisplayName,
    updateUserEmail,
} from '~modules/meeting/meeting.actions';
import {
    meetingsSelector,
    getActiveMeetings,
} from '~modules/meeting/meeting.selectors';
import { reactSetStateWarningFix } from '~common/utils';

const useAddMeeting = () => {
    const dispatch = useDispatch();
    return ({ date, group }) => {
        dispatch(openAddMeetingModal({ group: group || {}, date }));
    };
};

const useMeetings = () => useSelector(meetingsSelector);

const useMeetingById = (id) => {
    const meetings = useMeetings();
    const meeting = meetings.find((m) => m.id === id);
    return meeting;
};

const useActiveMeetings = () => useSelector(getActiveMeetings);

const useCloseMeetingModal = () => {
    const dispatch = useDispatch();
    return () => reactSetStateWarningFix(() => dispatch(closeMeetingModal()));
};

const useUpdateUserDisplayName = () => {
    const dispatch = useDispatch();
    return (meetingId, userId, displayName) =>
        dispatch(updateUserDisplayName(meetingId, userId, displayName));
};

const useUpdateUserEmail = () => {
    const dispatch = useDispatch();
    return (meetingId, userId, email) =>
        dispatch(updateUserEmail(meetingId, userId, email));
};

export {
    useAddMeeting,
    useMeetings,
    useMeetingById,
    useActiveMeetings,
    useCloseMeetingModal,
    useUpdateUserDisplayName,
    useUpdateUserEmail,
};
