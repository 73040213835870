import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { actions as toastr } from '~modules/alert/Alert';
import Icon from '~icons';
import { getStore } from '~common/store';

const TIMEOUT = 4000;
export const TIMEOUT_SHORT = 2000;

const getRandomId = () => uuidv4();

export const alertErrorWithDispatch = (alert) =>
    getStore().dispatch(alertError(alert));

export const alertError = ({ id, title, error, message = '' }) =>
    toastr.add({
        id: id || getRandomId(),
        title,
        type: 'light',
        attention: true,
        message: message || error?.message,
        options: {
            className: 'error',
            timeOut: TIMEOUT,
            progressBar: true,
            status: 'error',
            icon: <Icon name="error" />,
            preventDuplicates: true,
            showCloseButton: true,
        },
    });

export const alertPersistedError = ({ id, title, error, message }, options) =>
    toastr.add({
        id: id || getRandomId(),
        title,
        type: 'light',
        attention: true,
        message: message || error?.message,
        options: {
            className: 'error',
            timeOut: 0,
            progressBar: false,
            closeOnToastrClick: false,
            status: 'error',
            icon: <Icon name="error" />,
            ...options,
        },
    });

export const alertPersistedErrorWithClose = (params) =>
    alertPersistedError(params, {
        showCloseButton: true,
        closeOnToastrClick: true,
    });

export const alertPersistedErrorWithCloseWithDispatch = (alert) =>
    getStore().dispatch(alertPersistedErrorWithClose(alert));

export const alertInfoWithDispatch = (params) =>
    getStore().dispatch(alertInfo(params));
export const alertInfo = ({ id, title, message }) =>
    toastr.add({
        id: id || getRandomId(),
        title,
        type: 'light',
        message,
        options: {
            className: 'information',
            timeOut: TIMEOUT,
            progressBar: true,
            status: 'info',
            icon: <Icon name="information" />,
            preventDuplicates: true,
            showCloseButton: true,
        },
    });

export const alertPersistedInfo = ({ id, title, message }, options) =>
    toastr.add({
        id: id || getRandomId(),
        title,
        type: 'light',
        message,
        options: {
            className: 'information',
            timeOut: 0,
            progressBar: false,
            preventDuplicates: true,
            closeOnToastrClick: false,
            status: 'info',
            icon: <Icon name="information" />,
            ...options,
        },
    });

export const alertPersistedInfoWithCloseWithDispatch = (params, options) =>
    getStore().dispatch(alertPersistedInfoWithClose(params, options));
export const alertPersistedInfoWithClose = (params, options) =>
    alertPersistedInfo(params, {
        showCloseButton: true,
        closeOnToastrClick: true,
        ...options,
    });

export const alertSuccessWithDispatch = (params, options) =>
    getStore().dispatch(alertSuccess(params, options));
export const alertSuccess = ({ id, title, message }, options) =>
    toastr.add({
        id: id || getRandomId(),
        title,
        type: 'light',
        message,
        options: {
            className: 'success',
            timeOut: TIMEOUT,
            progressBar: true,
            status: 'success',
            icon: <Icon name="success" />,
            preventDuplicates: true,
            showCloseButton: true,
            ...options,
        },
    });

export const removeAlertWithDispatch = (params) =>
    getStore().dispatch(removeAlert(params));
export const removeAlert = ({ id }) => toastr.remove(id);
