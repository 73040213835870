import {
    DOCUMENTPREVIEW_MODAL_OPEN,
    DOCUMENTPREVIEW_MODAL_UPDATE,
    DOCUMENTPREVIEW_MODAL_CANCEL,
} from '~common/action.types';

export const onDocumentPreviewModalClose = () => ({
    type: DOCUMENTPREVIEW_MODAL_CANCEL,
});

export const onDocumentPreviewModalOpen = (props) => ({
    type: DOCUMENTPREVIEW_MODAL_OPEN,
    isLoading: true,
    ...props,
});

export const onDocumentPreviewModalLoading = (isLoading) => ({
    type: DOCUMENTPREVIEW_MODAL_UPDATE,
    isLoading,
});

export const onDocumentLoadTimeout = () => ({
    type: DOCUMENTPREVIEW_MODAL_UPDATE,
    isLoading: false,
    hasTimedOut: true,
});

export const onMinutesPreviewCreated =
    ({ occurrence, filename, src }) =>
    (dispatch, getState) => {
        const { isVisible } = getState().documentPreview;

        isVisible &&
            dispatch({
                type: DOCUMENTPREVIEW_MODAL_UPDATE,
                isLoading: false,
                hasTimedOut: false,
                occurrence,
                src,
                filename,
                doc: {
                    file: {
                        filename,
                    },
                },
            });
    };
