/**
 * Constant values for use on the server and client side
 */

// Permissions
export const GRANT_LEVEL_NONE = 'none';
export const GRANT_LEVEL_GUEST = 'guest';
export const GRANT_LEVEL_MEMBER = 'member';
export const GRANT_LEVEL_ADMIN = 'admin';
export const GRANT_LEVEL_OWNER = 'owner';
export const GRANT_LEVEL_READ = 'read';
export const GRANT_LEVEL_WRITE = 'write';
export const WORKSPACE_GRANT_LEVELS = [
    GRANT_LEVEL_GUEST,
    GRANT_LEVEL_MEMBER,
    GRANT_LEVEL_ADMIN,
    GRANT_LEVEL_OWNER,
];

export const PERMISSION_TYPE_MEETING = 'meeting';
export const PERMISSION_TYPE_OCCURRENCE = 'occurrence';
export const PERMISSION_TYPE_ACCOUNT = 'account';
export const PERMISSION_TYPE_WORKSPACE = 'workspace';
export const PERMISSION_TYPE_TEMPLATE_LIBRARY = 'template-library';
export const PERMISSION_LEVEL_OWNER = 'owner';
export const PERMISSION_LEVEL_ADMIN = 'admin';
export const PERMISSION_LEVEL_VIEW = 'read';
export const PERMISSION_LEVEL_UPDATE = 'write';
export const PERMISSION_LEVEL_NONE = 'none';

export const PERMISSION_STATUS_REJECTED = 'rejected';
export const PERMISSION_STATUS_PENDING = 'pending';
export const PERMISSION_STATUS_ACCEPTED = 'accepted';
export const PERMISSION_STATUS_REQUESTED = 'requested';
export const PERMISSION_STATUS_LEFT = 'left';
export const PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_PENDING =
    'workspace-revoked-was-pending';
export const PERMISSION_STATUS_WORKSPACE_REVOKED_WAS_ACCEPTED =
    'workspace-revoked-was-accepted';
export const PERMISSION_STATUS_WORKSPACE_LEFT_WAS_ACCEPTED =
    'workspace-left-was-accepted';
export const PERMISSION_STATUS_WORKSPACE_LEFT_WAS_PENDING =
    'workspace-left-was-pending';
export const PERMISSION_STATUS_WORKSPACE_REJECTED_WAS_REQUESTED =
    'workspace-rejected-was-requested';

// Authentication
export const AUTHSOURCE_AZUREAD = 'azure-ad';
export const AUTHSOURCE_AZUREAD_MOBILE = 'azure-ad-mobile';
export const AUTHSOURCE_AZUREAD_TASKS = 'azure-ad-tasks';
export const AUTHSOURCE_AZUREAD_CONTACTS = 'azure-ad-contacts';
export const AUTHSOURCE_AZUREAD_FILES = 'azure-ad-files';
export const AUTHSOURCE_OUTLOOK_CALENDAR = 'outlook-calendar';
export const AUTHSOURCE_MINUTEME = 'minuteme';
export const AUTHSOURCE_MINUTEME_MOBILE = 'minuteme-mobile';
export const AUTHSOURCE_GOOGLE = 'google';
export const AUTHSOURCE_GOOGLE_DRIVE = 'google-drive';
export const AUTHSOURCE_GOOGLE_MOBILE = 'google-mobile';
export const AUTHSOURCE_GOOGLE_TASKS = 'google-tasks';
export const AUTHSOURCE_GOOGLE_CONTACTS = 'google-contacts';
export const AUTHSOURCE_ASANA = 'asana';
export const AUTH_CONSENT_NOT_PROVIDED_ERROR = 'consentNotProvided';
export const AUTHSOURCE_GOOGLE_CALENDAR = 'google-calendar';
export const AUTHSOURCE_SMOKEBALL_AU = 'smokeball-au';
export const AUTHSOURCE_CALENDARS = [
    AUTHSOURCE_OUTLOOK_CALENDAR,
    AUTHSOURCE_GOOGLE_CALENDAR,
];
export const AUTHSOURCE_FILES = [AUTHSOURCE_AZUREAD, AUTHSOURCE_GOOGLE];
export const AUTHSOURCE_CRM = [AUTHSOURCE_SMOKEBALL_AU, AUTHSOURCE_ASANA];
export const AUTHSOURCE_CRM_WITH_TASKS = [
    AUTHSOURCE_SMOKEBALL_AU,
    AUTHSOURCE_ASANA,
];
export const AUTHSOURCE_CRM_WITH_CALENDAR_META = [AUTHSOURCE_SMOKEBALL_AU];
export const AUTHSOURCE_TASKS = [
    AUTHSOURCE_AZUREAD_TASKS,
    AUTHSOURCE_GOOGLE_TASKS,
];
export const AUTHSOURCE_TASKS_WITH_WEBHOOK = [AUTHSOURCE_AZUREAD_TASKS];
export const AUTHSOURCE_TO_HOSTTYPE_MAPPING = {
    [AUTHSOURCE_AZUREAD]: 'Microsoft',
    [AUTHSOURCE_MINUTEME]: 'Email',
    [AUTHSOURCE_GOOGLE]: 'Google',
};
export const STATUS_LOGGED_OUT = 'loggedOut';

// Integrations
export const INTEGRATION_TYPE_TASKS = 'tasks';
export const INTEGRATION_TYPE_CALENDARS = 'calendars';
export const INTEGRATION_TYPE_AUTHENTICATION = 'auth';
export const INTEGRATION_TYPE_CONTACTS = 'contacts';
export const INTEGRATION_TYPE_CRM = 'crm';
export const INTEGRATION_STATUS_ACTIVE = 'active';
export const INTEGRATION_STATUS_INACTIVE = 'inactive';
export const INTEGRATION_STATUS_RECONNECT = 'reconnect';
export const INTEGRATION_STATUS_HIDDEN = 'hidden';
export const INTEGRATION_CALENDAR_PAST_WEEKS_DEFAULT = 2;
export const INTEGRATION_CALENDAR_FUTURE_WEEKS_DEFAULT = 5;
export const INTEGRATION_CALENDAR_MAX_WEEKS = 26;
export const INTEGRATION_CALENDAR_FUTURE_WEEKS_ONEOFF = 44; // approx 10 months

// Templates
export const TEMPLATE_LIBRARY_TYPE_PRIVATE = 'private';
export const TEMPLATE_LIBRARY_TYPE_WORKSPACE = 'workspace';
export const TEMPLATE_INTRO_MEETING = 'intro-meeting';

export const WORKSPACE_FEATURE_TRIAL_ACTIVITY = 'activity';
export const WORKSPACE_FEATURE_TRIAL_BRANDING = 'logo';
export const WORKSPACE_FEATURE_TRIAL_DISPLAY_DOCUMENTS_INLINE =
    'displaydocumentsinline';
export const WORKSPACE_FEATURE_TRIAL_ASANA = 'asana';
export const WORKSPACE_FEATURE_TRIAL_ONEDRIVE = 'onedrive';
export const WORKSPACE_FEATURE_TRIAL_GOOGLE_DRIVE = 'googledrive';

export const INTEGRATION_TYPE_FILES = 'files';

const getAll = (obj) =>
    Array.from(
        new Set(
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line unicorn/prefer-array-flat
            Object.values(obj).reduce(
                (all, byAuthSource) => [...all, ...byAuthSource],
                []
            )
        )
    );
export const INTEGRATION_SCOPES_AUTH = {
    [AUTHSOURCE_AZUREAD]: [
        'openid',
        'offline_access',
        'profile',
        'email',
        'User.Read',
    ],
    [AUTHSOURCE_GOOGLE]: [
        'openid',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
    ],
};
export const INTEGRATION_SCOPES_AUTH_ALL = getAll(INTEGRATION_SCOPES_AUTH);

export const INTEGRATION_SCOPES_CONTACTS = {
    [AUTHSOURCE_AZUREAD_CONTACTS]: [
        'openid',
        'offline_access',
        'email',
        'profile',
        'People.Read',
    ],
    [AUTHSOURCE_GOOGLE_CONTACTS]: [
        'openid',
        'email',
        'https://www.googleapis.com/auth/userinfo.profile',
        'profile',
        'https://www.googleapis.com/auth/contacts.readonly',
    ],
};

export const INTEGRATION_AUTHSOURCE_TO_CONTACTS_MAP = {
    [AUTHSOURCE_AZUREAD]: AUTHSOURCE_AZUREAD_CONTACTS,
    [AUTHSOURCE_GOOGLE]: AUTHSOURCE_GOOGLE_CONTACTS,
};

export const INTEGRATION_SCOPES_CONTACTS_ALL = getAll(
    INTEGRATION_SCOPES_CONTACTS
);

export const INTEGRATION_SCOPES_FILES = {
    [AUTHSOURCE_AZUREAD_FILES]: [
        'offline_access',
        'email',
        'profile',
        'Files.ReadWrite.All',
    ],
    [AUTHSOURCE_GOOGLE_DRIVE]: [
        'openid',
        'email',
        'profile',
        'https://www.googleapis.com/auth/drive',
    ],
};

export const INTEGRATION_AUTHSOURCE_TO_FILES_MAP = {
    [AUTHSOURCE_AZUREAD]: AUTHSOURCE_AZUREAD_FILES,
    [AUTHSOURCE_GOOGLE]: AUTHSOURCE_GOOGLE_DRIVE,
};
export const INTEGRATION_AUTHSOURCE_TO_FILES_FEATURE_MAP = {
    [AUTHSOURCE_AZUREAD]: WORKSPACE_FEATURE_TRIAL_ONEDRIVE,
    [AUTHSOURCE_GOOGLE]: WORKSPACE_FEATURE_TRIAL_GOOGLE_DRIVE,
};

export const INTEGRATION_SCOPES_FILES_ALL = getAll(INTEGRATION_SCOPES_FILES);

export const INTEGRATION_SCOPES_TASKS = {
    [AUTHSOURCE_AZUREAD_TASKS]: [
        'openid',
        'offline_access',
        'profile',
        'email',
        'Tasks.ReadWrite',
    ],
    [AUTHSOURCE_GOOGLE_TASKS]: [
        'openid',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/tasks',
    ],
};
export const INTEGRATION_SCOPES_TASKS_ALL = getAll(INTEGRATION_SCOPES_TASKS);

export const INTEGRATION_SCOPES_CALENDAR = {
    [AUTHSOURCE_OUTLOOK_CALENDAR]: [
        'openid',
        'offline_access',
        'profile',
        'email',
        'Calendars.Read',
        'Calendars.Read.Shared',
    ],
    [AUTHSOURCE_GOOGLE_CALENDAR]: [
        'openid',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/calendar.readonly',
    ],
};
export const INTEGRATION_SCOPES_CALENDAR_ALL = getAll(
    INTEGRATION_SCOPES_CALENDAR
);

export const INTEGRATION_SCOPES_CRM = {
    [AUTHSOURCE_SMOKEBALL_AU]: [
        'openid',
        'email',
        'profile',
        // 'aws.cognito.signin.user.admin',
    ],
};

export const INTEGRATION_SCOPES_CRM_ALL = getAll(INTEGRATION_SCOPES_CRM);

export const ACTIVITY_TYPE_EMAIL = 'email';
export const ACTIVITY_TYPE_DELETE_MEETING = 'delete-meeting';
export const ACTIVITY_TYPE_RESTORE_MEETING = 'restore-meeting';
export const ACTIVITY_TYPE_DELETE_OCCURRENCE = 'delete-occurrence';
export const ACTIVITY_TYPE_MOVE_OCCURRENCE = 'move-occurrence';

export const WORKSPACE_PLAN_BASIC = 'basic';
export const WORKSPACE_PLAN_PLUS = 'plus';
export const WORKSPACE_DEFAULT_MEETING_LIMIT = 3;
export const WORKSPACE_PLAN_PER_USER_COST = 'per-user-cost';
export const PLUS_FEATURE_TRIAL_PERIOD_DAYS = 55;

export const COUNTRY_CODE_US = 'US';
export const COUNTRY_CODE_AU = 'AU';
export const COUNTRY_CODE_GB = 'GB';

export const COUNTRY_CURRENCY_VALUE_US = 'USD';
export const COUNTRY_CURRENCY_VALUE_AU = 'AUD';
export const COUNTRY_CURRENCY_VALUE_GB = 'GBP';

export const WORKSPACE_PLAN_CURRENCY_CONFIG = {
    [COUNTRY_CODE_US]: {
        code: 'US',
        value: 'USD',
        flag: '🇺🇸',
        [WORKSPACE_PLAN_PER_USER_COST]: 9,
    },
    [COUNTRY_CODE_AU]: {
        code: 'AU',
        value: 'AUD',
        flag: '🇦🇺',
        [WORKSPACE_PLAN_PER_USER_COST]: 13.5,
    },
    [COUNTRY_CODE_GB]: {
        code: 'GB',
        value: 'GBP',
        flag: '🇬🇧',
        [WORKSPACE_PLAN_PER_USER_COST]: 7.5,
    },
};

export const WORKSPACE_PLAN_CONFIG = {
    [WORKSPACE_PLAN_BASIC]: {
        id: WORKSPACE_PLAN_BASIC,
        label: 'Basic plan',
        icon: 'basicPlan',
    },
    [WORKSPACE_PLAN_PLUS]: {
        id: WORKSPACE_PLAN_PLUS,
        label: 'Plus plan',
        icon: 'plusPlan',
        image: 'upgradeSuccess',
    },
};

// NOTE: changes to this should also be applied to devops/dbscripts-node/disable_user_email_preference.js
export const NOTIFICATION_PREFERENCE_DEFAULTS = {
    global: true,
    agendaEmails: true,
    minutesEmails: true,
    meetingInvites: true,
    dailyDigestEmails: true,
};

export const DOCUMENT_VISIBILITY_VISIBLE = 'visible';
export const DOCUMENT_VISIBILITY_HIDDEN = 'hidden';

export const MEETING_CREATED_SOURCE_WORKSPACE = 'workspace-detail';
export const MEETING_CREATED_SOURCE_NEW_WORKSPACE_TOAST = 'new-workspace-toast';
export const MEETING_CREATED_SOURCE_ADD_PATH = 'add-path';
export const MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD = 'calendar-dashboard';
export const MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_DAY =
    'calendar-dashboard-day';
export const MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_PLACEHOLDER =
    'calendar-dashboard-placeholder';
export const MEETING_CREATED_SOURCE_SERIES_DASHBOARD = 'series-dashboard';
export const MEETING_CREATED_SOURCE_SERIES_DASHBOARD_PLACEHOLDER =
    'series-dashboard-placeholder';
export const MEETING_CREATED_SOURCE_SERIES_SIDEBAR = 'series-sidebar';
export const MEETING_CREATED_SOURCES = [
    MEETING_CREATED_SOURCE_WORKSPACE,
    MEETING_CREATED_SOURCE_NEW_WORKSPACE_TOAST,
    MEETING_CREATED_SOURCE_ADD_PATH,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_DAY,
    MEETING_CREATED_SOURCE_CALENDAR_DASHBOARD_PLACEHOLDER,
    MEETING_CREATED_SOURCE_SERIES_DASHBOARD,
    MEETING_CREATED_SOURCE_SERIES_DASHBOARD_PLACEHOLDER,
    MEETING_CREATED_SOURCE_SERIES_SIDEBAR,
];

export const MEETING_CREATED_MODAL_SIMPLE = 'simple-add-modal';
export const MEETING_CREATED_MODAL_ADVANCED = 'advanced-add-modal';
export const MEETING_CREATED_MODAL_EVENT_SIMPLE = 'simple-event-modal';
export const MEETING_CREATED_MODAL_EVENT_MATCH = 'match-event-modal';

export const MEETING_TYPE_11_121 = '1:1-1:2:1';
export const MEETING_TYPE_TEAM = 'team';
export const MEETING_TYPE_STAFF = 'staff';
export const MEETING_TYPE_PROJECT = 'project';
export const MEETING_TYPE_EXECUTIVE = 'executive';
export const MEETING_TYPE_BOARD = 'board';
export const MEETING_TYPE_COMMITTEE = 'committee';
export const MEETING_TYPE_HR = 'hr';
export const MEETING_TYPE_CLIENT = 'client';
export const MEETING_TYPE_OTHER = 'other';
export const MEETING_TYPE_MAP = {
    [MEETING_TYPE_11_121]: '1:1 / 1:2:1',
    [MEETING_TYPE_TEAM]: 'Team meeting',
    [MEETING_TYPE_STAFF]: 'Staff meeting',
    [MEETING_TYPE_PROJECT]: 'Project meeting',
    [MEETING_TYPE_EXECUTIVE]: 'Executive meeting',
    [MEETING_TYPE_BOARD]: 'Board meeting',
    [MEETING_TYPE_COMMITTEE]: 'Committee meeting',
    [MEETING_TYPE_HR]: 'HR meeting',
    [MEETING_TYPE_CLIENT]: 'Client meeting',
    [MEETING_TYPE_OTHER]: 'Other',
};
export const MEETING_TYPE_LIST = Object.keys(MEETING_TYPE_MAP);
