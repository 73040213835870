import tw from 'twin.macro';
import { useUI, useShowNotificationToast, useCloseMeetingModal } from '~hooks';
import { useCloseSimpleAddMeetingModal } from '~modules/modals/simpleAddMeeting/simpleAddMeeting.hooks';

const Button = tw.div`appearance-none underline inline text-primary hocus:(cursor-pointer  text-body-dark) focus:outline-none`;

const NotificationToastButton = () => {
    const { setIsNotificationsOpen } = useUI();
    const showNotificationToast = useShowNotificationToast();
    const closeMeetingModal = useCloseMeetingModal();
    const closeSimpleAddMeetingModal = useCloseSimpleAddMeetingModal();

    return (
        <Button
            type="button"
            onClick={() => (
                setIsNotificationsOpen(true),
                closeMeetingModal(),
                closeSimpleAddMeetingModal(),
                showNotificationToast(false)
            )}
        >
            Open the notification centre
        </Button>
    );
};

const CustomNotificationToastButton = ({ handleClick, label }) => (
    <Button type="button" onClick={() => handleClick?.()}>
        {label}
    </Button>
);

export { NotificationToastButton as default, CustomNotificationToastButton };
