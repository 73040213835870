import _omit from 'lodash/omit.js';
import _uniqBy from 'lodash/uniqBy.js';
import { createSelector } from 'reselect';
import { getStore } from '~common/store';
import {
    AUTHSOURCE_CALENDARS,
    GRANT_LEVEL_ADMIN,
    GRANT_LEVEL_MEMBER,
} from '~common/constants';
import {
    getUserListSelector,
    getUserSelector,
} from '~modules/user/user.selectors';
import { grantLevelToGrants } from '~shared/permissions/permission.helper';
import { occurrencesSelector } from '~modules/occurrence/occurrence.selectors';
import { meetingsSelector } from '~modules/meeting/meeting.selectors';
import {
    isNonRepeatingMeeting,
    isRepeatingMeeting,
} from '~modules/meeting/meeting.helpers';
import { getWorkspaceGroupByMeetingId } from '~modules/group/group.selectors';
import { getOccurrenceAccessLevelNonMemoized } from '~modules/permission/permission.selectors';
import { getMeetingAccessLevel } from '~modules/permission/permission.helpers';

export const getEventsFromRemoteCalendars = createSelector(
    (state) => state.remoteCalendars,
    (remoteCalendars) =>
        AUTHSOURCE_CALENDARS.flatMap((c) => remoteCalendars[c]?.events) || []
);

const noLinks = {};

export const getLinksForRemoteCalendarEvents = (state) =>
    state.remoteCalendars.links?.data || noLinks;

export const getUsersFromRemoteCalendars = (state) =>
    Object.assign(
        ...AUTHSOURCE_CALENDARS.map(
            (c) => state.remoteCalendars[c]?.users || {}
        )
    );

// regardless of whether the selected meeting is recurring or not,
// we take a mashup of all permissions from the:
//  * remote series
//  * remote event
//  * local occurrence
//  * local meeting
// Priority of permissions taken from meeting, occurrence, series, event
export const getDefaultAttendeesAndPermissions = (
    { event, series },
    { meeting, occurrence } = {}
) => {
    const pageStates = getStore().getState().pageView.pageStates;

    const occurrencePermissions = occurrence?.id
        ? pageStates.find(({ id }) => id === occurrence.id)
        : { permissions: [] };
    const meetingPermissions = meeting?.id
        ? pageStates.find(({ id }) => id === meeting.id)
        : { permissions: [] };

    const existingPermissions = [
        ...(meetingPermissions?.permissions?.filter?.(({ scope }) => !scope) ||
            []),
        ...(occurrencePermissions?.permissions || []),
    ];

    const allPermissions = [
        ...existingPermissions,
        ...(series?.permissions || []),
        ...(event?.permissions || []),
    ];

    const allAttendees = [
        ...(meeting?.attendees || []),
        ...(occurrence?.attendees || []),
        ...(series?.attendees || []),
        ...(event?.attendees || []),
    ];

    const uniqPermissions = _uniqBy(allPermissions, 'user.id');
    const uniqAttendees = _uniqBy(allAttendees, 'userId');

    const newUserIds = uniqPermissions
        .filter(
            (permission) =>
                !existingPermissions.some(
                    (p) => p.user.id === permission.user.id
                )
        )
        .map((permission) => permission.user.id);

    return [{ attendees: uniqAttendees }, uniqPermissions, newUserIds];
};

const mapCalendarAttendees = (users, attendees = []) =>
    attendees.map((attendee) => {
        const knownUser = users.find((user) => user.email === attendee.email);

        return {
            ...attendee,
            userId: knownUser?.id || attendee.email,
        };
    });

const mapCalendarPermissions = (users, loggedInUser, attendees = []) =>
    attendees.map((attendee) => {
        const knownUser =
            users.find((user) => user.email === attendee.email) || {};
        const grants = grantLevelToGrants(
            knownUser?.id === loggedInUser.id || attendee.isOwner
                ? GRANT_LEVEL_ADMIN
                : GRANT_LEVEL_MEMBER
        );

        return {
            user: {
                ...attendee,
                ...knownUser,
                id: knownUser?.id || attendee.email,
            },
            grants,
        };
    });

export const getRemoteCalendarEventForModal = (
    state,
    { eventRaw: event, profile }
) => {
    const loggedInUser = getUserSelector(state);
    const users = getUserListSelector(state);

    const data = {
        profile,
        event: {
            ..._omit(event, ['meeting', 'attendees', 'permissions']),
            attendees: mapCalendarAttendees(
                users,
                event.attendees,
                event.permissions
            ),
            permissions: mapCalendarPermissions(
                users,
                loggedInUser,
                event.permissions
            ),
            pattern: event.meeting?.pattern,
            range: event.meeting?.range,
        },
    };

    if (event.meeting) {
        data.series = {
            ...event.meeting,
            attendees: mapCalendarAttendees(
                users,
                event.meeting?.attendees,
                event.meeting?.permissions
            ),
            permissions: mapCalendarPermissions(
                users,
                loggedInUser,
                event.meeting?.permissions
            ),
        };
    }

    return data;
};

export const getCalendarMatches = (state, { calendarData }) => {
    const occurrences = occurrencesSelector(state);
    const meetings = meetingsSelector(state);

    const calendarMatches = occurrences.filter(
        (item) =>
            !item.archived &&
            !item?.meetingMeta?.archived &&
            item.startDate.valueOf() === calendarData?.startDate.valueOf() &&
            // Ensure an item already linked, is not able to be linked again.
            (!item.calendarLinks || item.calendarLinks.length === 0) &&
            // only match occurrences that are a recurrence match...
            // i.e. event and occurrence are both scheduled, or event and occurrence are both not scheduled
            ([item, calendarData].every(isRepeatingMeeting) ||
                [item, calendarData].every(isNonRepeatingMeeting))
    );
    const matches = calendarMatches.map((occurrence) => {
        const meeting = meetings.find((m) => m.id === occurrence.meeting);
        const workspace = getWorkspaceGroupByMeetingId({
            meeting: meeting.id,
        });

        return {
            occurrence: {
                ...occurrence,
                myAccessLevel: getOccurrenceAccessLevelNonMemoized({
                    occurrence,
                }),
            },
            meeting: {
                ...meeting,
                myAccessLevel: getMeetingAccessLevel(meeting),
            },
            workspace,
        };
    });

    return matches;
};
