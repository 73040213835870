import _each from 'lodash/each.js';
import _get from 'lodash/get.js';
import _omit from 'lodash/omit.js';
import _pick from 'lodash/pick.js';
import React from 'react';
import { push, replace } from 'connected-react-router';
import { FORM_ERROR } from 'final-form';
import {
    EDIT_OCCURRENCE_WEBSOCKET,
    UPDATE_OCCURRENCE_ATTENDANCE_REQUEST,
    UPDATE_OCCURRENCE_ATTENDANCE_ERROR,
    MODAL_SENDMINUTES_OPEN,
    MODAL_SENDMINUTES_CLOSE,
    SEND_MINUTES_SAVE_REQUEST,
    SEND_MINUTES_SAVE_ERROR,
    SEND_MINUTES_SAVE_SUCCESS,
    MODAL_MEETING_OPEN,
    ADD_OCCURRENCE_SUCCESS,
    ADD_OCCURRENCE_ERROR,
    EDIT_OCCURRENCE_REQUEST,
    EDIT_OCCURRENCE_SUCCESS,
    EDIT_OCCURRENCE_ERROR,
    DELETE_OCCURRENCES_WEBSOCKET,
    OCCURRENCE_PERMISSIONS_LOADED,
    OCCURRENCE_ARCHIVE_STATUS,
    OCCURRENCE_ACTIVITIES_LOADED,
    OCCURRENCE_ACTIVITY_LOADED,
    OCCURRENCE_ACTIVITY_COUNT_LOADED,
} from '~common/action.types';
import {
    ATTENDEE_ROLE_ABSENT_SUFFIX,
    MODAL_TYPE_ARCHIVE_CANCEL,
    RECURRENCE_SCHEDULED,
} from '~common/constants';
import { fullDateTime } from '~common/time.utils';
import {
    formSubmissionServerError,
    processBodyErrors,
} from '~components/formvalidation/formvalidation.helper';
import {
    isViewingOccurrence,
    getOccurrenceSlug,
    getMeetingSlug,
    pushTo,
} from '~modules/navigation';
import { updatePageViewState } from '~modules/pageView/pageView.actions';
import { fetchMeetingUsers } from '~modules/preload/preload.actions';
import { openConfirm } from '~modules/modals/confirmModal.actions';
import {
    getMeetingFromStoreById,
    getMinutesStatus,
} from '~modules/meeting/meeting.selectors';
import { getDocumentsForOccurrenceSelector } from '~modules/documents/document.selectors';
import {
    clearCacheOccurrenceAddEditFormInitialValues,
    getOccurrenceFromStoreById,
    getNextScheduledOccurrenceFromStoreByDate,
} from './occurrence.selectors';
import { getMinutesForOccurrenceSelector } from '~modules/minutes/minutes.pure.selectors';
import { getOccurrenceAccessLevelSelector } from '~modules/permission/permission.selectors';
import { onPermissionCreateSuccess } from '~modules/permission/permission.actions';
import {
    getOccurrence,
    updateOccurrenceAttendance,
    completeOccurrence,
    addOccurrence,
    editOccurrence,
    moveOccurrence as apiMoveOccurrence,
    archiveOccurrence as apiArchiveOccurrence,
    deleteOccurrence as apiDeleteOccurrence,
    restoreOccurrence as apiRestoreOccurrence,
} from '~modules/meeting/meeting.api';
import { sendMinutes } from '~modules/minutes/minutes.api';
import {
    occurrenceModel,
    transformOccurrenceAddFormForPOST,
    transformOccurrenceEditFormForPOST,
} from '~modules/meeting/meeting.mappers';
import { getPermissionsForOccurrence } from '~modules/permission/permission.api';
import {
    getActivitiesForOccurrence,
    getActivity,
    getActivityCountForOccurrence,
} from '~modules/activity/activity.api';
import {
    alertError,
    alertSuccess,
    removeAlertWithDispatch,
} from '~modules/alert/alert.actions';
import { loadMeetingPermissions } from '~modules/meeting/meeting.actions';
import { displaySuccess } from '~modules/alert/alert.helpers';
import { getCRMConfig } from '~shared/integration/integration.mapper.remoteCRM';
import { pauseUpdates, resumeUpdates } from '~client/common/store';
import { pluralize } from '~shared/utils';
import airbrake from '~common/airbrake';
import { getFilesConfig } from '~modules/remoteFiles/remoteFiles.mappers';
import { LinkExternal } from '~modules/common/components';
import { isFeatureEnabled } from '~modules/feature/feature.helpers';
import { CustomNotificationToastButton } from '~modules/notification/NotificationToastButton';
import { getCurrentOrNextOccurrenceInfoFromStoreByMeeting } from './occurrenceInfo.selectors.js';

export const openSendMinutesModal = (sendAsAgenda, occurrence, documents) => ({
    type: MODAL_SENDMINUTES_OPEN,
    sendAsAgenda,
    occurrence,
    documents,
});

export const closeSendMinutesModal = () => ({
    type: MODAL_SENDMINUTES_CLOSE,
});

const onSendMinutesSaveRequest = () => ({
    type: SEND_MINUTES_SAVE_REQUEST,
});

const onSendMinutesSaveError = (error) => ({
    type: SEND_MINUTES_SAVE_ERROR,
    error,
});

const onSendMinutesSaveSuccess = () => ({
    type: SEND_MINUTES_SAVE_SUCCESS,
});

const archiveStatusSuccess = (occurrence) => ({
    type: OCCURRENCE_ARCHIVE_STATUS,
    occurrence: occurrence,
    occurrenceId: occurrence.id,
});

export const openArchiveModal = (occurrence, content) => (dispatch) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line require-await
    const promise = async () => dispatch(archiveOccurrence(occurrence));

    dispatch(
        openConfirm({
            header: 'Archive meeting',
            modalType: MODAL_TYPE_ARCHIVE_CANCEL,
            content,
            promise,
        })
    );
};

export const moveOccurrence = (occurrenceId, params) => async (dispatch) => {
    const targetMeeting = getMeetingFromStoreById(params.toMeetingId);
    try {
        pauseUpdates();
        const occurrenceMoveResult = await apiMoveOccurrence(
            occurrenceId,
            params
        );

        dispatch(
            editOccurrenceSuccess(
                occurrenceModel(occurrenceMoveResult.occurrence)
            )
        );

        // Try to redirect to the most recent past (or future) occurrence.
        const occurrences = getCurrentOrNextOccurrenceInfoFromStoreByMeeting({
            meeting: params.fromMeetingId,
        });
        const occurrenceIdForRedirect =
            occurrences?.occurrence?.id || occurrences?.prev?.id;
        if (occurrenceIdForRedirect) {
            pushTo(
                getOccurrenceSlug(occurrences?.occurrence || occurrences?.prev)
            );
        }

        dispatch(
            alertSuccess(
                {
                    id: `moveOccurrence-${occurrenceId}`,
                    title: `Meeting has been moved to ${targetMeeting.title}`,
                    message: occurrenceIdForRedirect ? (
                        <CustomNotificationToastButton
                            label="View meeting"
                            handleClick={() => {
                                pushTo(getOccurrenceSlug({ id: occurrenceId }));
                                removeAlertWithDispatch({
                                    id: `moveOccurrence-${occurrenceId}`,
                                });
                            }}
                        />
                    ) : null,
                },
                {
                    closeOnToastrClick: false,
                    timeOut: 10000,
                }
            )
        );
        resumeUpdates();
    } catch (error) {
        resumeUpdates();
        dispatch(
            alertError({
                id: 'moveOccurrence',
                title: `Meeting could not be moved to “${targetMeeting.title}”`,
                error,
            })
        );
    }
};

export const archiveOccurrence = (occurrence) => async (dispatch) => {
    try {
        const archivedOccurrence = await apiArchiveOccurrence(occurrence);
        return dispatch(archiveStatusSuccess(archivedOccurrence));
    } catch (error) {
        dispatch(
            alertError({
                id: 'archiveOccurrence',
                title: 'Meeting could not be archived',
                error,
            })
        );
        // throw error to get handled in confirm modal.
        throw error;
    }
};

export const deleteOccurrence = (occurrence) => async (dispatch) => {
    try {
        // because there are lots of awaits on the server, we will get websocket events before the api returns the delete operation.
        // Therefore pause websocket updates so user doesn't get a jerky screen experience while topics etc... disappear off the screen
        pauseUpdates();
        const deletedOccurrence = await apiDeleteOccurrence(occurrence);
        return dispatch(onOccurrencesDelete([deletedOccurrence]));
    } catch (error) {
        dispatch(
            alertError({
                id: 'deleteOccurrence',
                title: 'Meeting could not be deleted',
                error,
            })
        );
        // throw error to get handled in confirm modal.
        throw error;
    } finally {
        resumeUpdates();
    }
};

export const restoreOccurrence = (occurrence) => (dispatch) => {
    apiRestoreOccurrence(occurrence).then((newOccurrence) => {
        dispatch(archiveStatusSuccess(newOccurrence));
    });
};

export const openOccurrenceModal = ({
    meeting,
    occurrence,
    group,
    remoteLink,
    isAddingSingleToExisting,
    editKey,
}) => ({
    type: MODAL_MEETING_OPEN,
    mode: 'meeting',
    meeting,
    group,
    occurrence,
    remoteLink,
    isAddingSingleToExisting,
    editKey,
});

const loadingPermissions = new Set();

export const loadOccurrencePermissions =
    (occurrence) => (dispatch, getState) => {
        // may be that user is trying to view an occurrence they don't have access to, or permissions have been removed.
        // therefore don't load permissions.
        if (!occurrence?.id) return;

        const state = getState();

        if (occurrence.useMeetingAttendees) {
            const pageStates = getState().pageView.pageStates;
            return (
                !pageStates.some(({ id }) => id === occurrence.meeting) &&
                dispatch(loadMeetingPermissions({ id: occurrence.meeting }))
            );
        }

        if (loadingPermissions.has(occurrence.id)) return; // permissions are already loading for this occurrence

        const { isAdmin } = getOccurrenceAccessLevelSelector(state, {
            occurrence,
        });

        loadingPermissions.add(occurrence.id);
        return getPermissionsForOccurrence(occurrence.id)
            .then((permissionsFromAPI) => {
                //GOTCHA user email is only returned if user is Admin. Therefore
                //       merge with already known list of users to get the proper user profile.
                const permissions = isAdmin
                    ? permissionsFromAPI.filter((p) => !p.scope)
                    : permissionsFromAPI
                          .filter((p) => !p.scope)
                          .map((permission) => {
                              const user =
                                  state.userList.users.find(
                                      (user) => user.id === permission.user.id
                                  ) || permission.user;
                              return {
                                  ...permission,
                                  user,
                              };
                          });

                return dispatch({
                    type: OCCURRENCE_PERMISSIONS_LOADED,
                    occurrenceId: occurrence.id,
                    permissions,
                });
            })
            .catch((error) => {
                // May be if the authorisation fails, server is unavailable, version expired
                // errors are handled in the get function
                dispatch(
                    alertError({
                        id: 'loadOccurrencePermissions',
                        title: 'Meeting permissions could not be loaded',
                        error,
                    })
                );
            })
            .finally(() => loadingPermissions.delete(occurrence.id));
    };

const addOccurrenceSuccess = (occurrence) => ({
    type: ADD_OCCURRENCE_SUCCESS,
    occurrence,
});

const addOccurrenceError = (error) => ({
    type: ADD_OCCURRENCE_ERROR,
    error,
});

const editOccurrenceRequest = (occurrence) => ({
    type: EDIT_OCCURRENCE_REQUEST,
    occurrence,
});

const editOccurrenceSuccess = (occurrence) => ({
    type: EDIT_OCCURRENCE_SUCCESS,
    occurrence,
});

const editOccurrenceError = (occurrence) => ({
    type: EDIT_OCCURRENCE_ERROR,
    occurrence,
});

export const printPreview = (values) => async (dispatch, getState) => {
    const {
        occurrence,
        showSendAgendaOption,
        displayTopicContent = true,
        displayActionItemComments,
        displayTopicDocumentsInline,
        displayWorkspaceLogo,
    } = values;

    try {
        const state = getState();

        const documentAttachments = getDocumentsForOccurrenceSelector(state, {
            occurrence,
        })
            .filter((d) => !d.file.minutes && !d.file.agenda)
            .map((d) => d.id);

        const nextOccurrence =
            occurrence.recurrence === RECURRENCE_SCHEDULED
                ? getNextScheduledOccurrenceFromStoreByDate(
                      state,
                      occurrence.meeting,
                      occurrence.startDate.toDate()
                  )
                : null;

        const nextOccurrenceId = _get(nextOccurrence, 'id');

        await sendMinutes(occurrence.id, {
            previewOnly: true,
            attachPDFToMeeting: false,
            displayTopicContent,
            displayActionItemComments:
                displayActionItemComments ??
                occurrence.displayActionItemComments,
            displayTopicDocumentsInline,
            displayWorkspaceLogo,
            savePDFToCloud: false,
            sendAsAgenda: showSendAgendaOption ? true : false,
            sendNow: true,
            attachPDFToEmail: false,
            recipients: [],
            documentAttachments,
            nextOccurrenceId,
            showNextOccurrenceDetail: true,
        });
    } catch (error) {
        dispatch(
            alertError({
                id: `printPreview-${
                    showSendAgendaOption ? 'agenda' : 'minutes'
                }`,
                title: `${
                    showSendAgendaOption ? 'Agenda' : 'Minutes'
                } cannot be previewed`,
                error,
            })
        );
        return error;
    }
};

export const onSendMinutesSave = (values) => async (dispatch) => {
    try {
        const { occurrence, sendNow, sendAsAgenda, attachPDFToMeeting } =
            values;
        let recipients = [];
        if (sendNow) {
            recipients = Object.keys(values)
                .filter((r) => r.match(/recipient/g) && values[r] === true)
                .map((r) => r.replace('recipient-', ''));
        }
        dispatch(onSendMinutesSaveRequest());
        const params = {
            ..._pick(values, [
                'attachPDFToMeeting',
                'displayActionItemComments',
                'displayTopicContent',
                'displayTopicDocumentsInline',
                'displayWorkspaceLogo',
                'savePDFToCloud',
                'attachPDFToEmail',
                'includeMinutesInEmailBody',
                'sendAsAgenda',
                'incomplete',
                'nextOccurrenceId',
                'showNextOccurrenceDetail',
                'message',
                'meeting',
            ]),
            recipients,
            sendNow: recipients.length ? sendNow : false,
        };
        if (values.includeAttachments) {
            params.documentAttachments = values.documentAttachments;
        }

        const { isCompleted, isSent } = getMinutesStatus(
            _get(occurrence, 'status')
        );
        sendAsAgenda || isCompleted || isSent
            ? await sendMinutes(occurrence.id, params)
            : await completeOccurrence(occurrence.id, params);

        const { authSource } = params.meeting.crmLinks || {};
        const crm = getCRMConfig({ authSource });
        const { authSource: filesAuthSource, link } =
            params.meeting.fileLink || {};
        const filesConfig = getFilesConfig({
            authSource: filesAuthSource,
        });

        const saveToCloud = Boolean(
            params.savePDFToCloud && filesConfig.shortName
        );
        const saveToCrmCloud = Boolean(params.savePDFToCloud && crm.shortName);

        const cloudSaveDisplay = (
            <>
                {!attachPDFToMeeting ? ', and will be available in ' : ' and '}
                {saveToCloud ? (
                    <LinkExternal href={link}>
                        {filesConfig.shortName}
                    </LinkExternal>
                ) : (
                    crm.shortName
                )}{' '}
            </>
        );

        const showCloudSaveFeedback = saveToCloud || saveToCrmCloud;

        const message = (
            <>
                {sendAsAgenda ? 'It' : 'They'} will be emailed to{' '}
                {recipients.length} {pluralize('recipient', recipients.length)}
                {attachPDFToMeeting &&
                    `, and will be available
                in “Documents” `}
                {showCloudSaveFeedback && cloudSaveDisplay} shortly.
            </>
        );

        const options = {
            timeOut: 15000,
        };

        saveToCloud && (options.closeOnToastrClick = false);

        displaySuccess(
            {
                title: [
                    sendAsAgenda ? 'Agenda' : 'Minutes',
                    recipients.length > 0 ? 'sent' : 'saved',
                ].join(' '),
                message,
            },
            options
        );

        dispatch(onSendMinutesSaveSuccess());
    } catch (error) {
        const newError = await formSubmissionServerError(
            error,
            processBodyErrors
        );
        dispatch(onSendMinutesSaveError(newError));
        dispatch(
            alertError({
                id: 'onSendMinutesSave',
                title: 'Unable to send minutes',
                error,
            })
        );
        return newError;
    }
};

export const onOccurrenceUpdateSuccess =
    (occurrence, prevOccurrence) =>
    async (
        dispatch,
        getState
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line require-await
    ) => {
        dispatch({
            type: EDIT_OCCURRENCE_WEBSOCKET,
            occurrence,
        });
        clearCacheOccurrenceAddEditFormInitialValues();

        // Reset the topicsAreSavedToDB so that the minutes copied from the previous meeting
        // message is displayed when useMeetingAgenda is reset to true.
        if (
            _get(occurrence, 'useMeetingAgenda') &&
            !_get(prevOccurrence, 'useMeetingAgenda')
        ) {
            const topicsAreSavedToDB =
                getMinutesForOccurrenceSelector(getState(), { occurrence })
                    .length > 0;
            dispatch(
                updatePageViewState({
                    id: occurrence.id,
                    topicsAreSavedToDB,
                })
            );
        }
    };

export const changeOccurrenceAttendance =
    (occurrence, attendees, attendee, isAbsent) => async (dispatch) => {
        const newAttendeeDetails = {
            ...attendee,
            role: isAbsent
                ? `${attendee.role}${ATTENDEE_ROLE_ABSENT_SUFFIX}`
                : attendee.role.replace(ATTENDEE_ROLE_ABSENT_SUFFIX, ''),
        };

        if (_get(occurrence, 'attendees.length')) {
            try {
                dispatch({
                    type: UPDATE_OCCURRENCE_ATTENDANCE_REQUEST,
                    occurrenceId: occurrence.id,
                    attendee: newAttendeeDetails,
                });
                await updateOccurrenceAttendance(
                    occurrence.id,
                    attendee.userId,
                    isAbsent ? 'absent' : 'present'
                );
            } catch (error) {
                dispatch({
                    type: UPDATE_OCCURRENCE_ATTENDANCE_ERROR,
                    occurrenceId: occurrence.id,
                    attendee,
                });
                dispatch(
                    alertError({
                        id: 'changeOccurrenceAttendance',
                        title: 'Attendance could not be updated',
                        error,
                    })
                );
            }
        } else {
            const occurrenceToSave = {
                attendees: attendees.map((a) =>
                    a.userId === newAttendeeDetails.userId
                        ? newAttendeeDetails
                        : a
                ),
                id: occurrence.id,
                meeting: occurrence.meeting,
            };

            try {
                await dispatch(onUpdateOccurrence(occurrenceToSave));
            } catch (error) {
                dispatch(
                    alertError({
                        id: 'changeOccurrenceAttendance-no-attendees',
                        title: 'Attendance could not be updated',
                        error,
                    })
                );
                return error;
            }
        }
    };

export const onOccurrenceSave = (values, formApi) => async (dispatch) => {
    try {
        let result;
        if (typeof values.id === 'undefined') {
            result = await dispatch(onAddOccurrence(values));
            dispatch(push(getOccurrenceSlug(result)));
        } else {
            result = await dispatch(onEditOccurrence(values, formApi));
        }
    } catch (error) {
        const formData = {
            ...values,
            endDate: values.recurrenceDetail?.endDate?.toDate?.(),
            startDate: values.recurrenceDetail?.startDate?.toDate?.(),
            group: {
                ...values?.group,
                meetings: undefined,
            },
            recurrenceDetail: {
                ...values.recurrenceDetail,
                endDate: values.recurrenceDetail?.endDate?.toDate?.(),
                startDate: values.recurrenceDetail?.startDate?.toDate?.(),
            },
        };
        airbrake.notify({
            error: new Error(error?.[FORM_ERROR] || 'Error saving occurrence'),
            params: { formData, errorDetail: error },
        });

        // return as error object (for final-form) rather than throwing the error
        return error;
    }
};

export const processAddedOccurrence =
    (createParams, addedOccurrence) => (dispatch) => {
        const permissions = addedOccurrence.__permissions;

        _each(permissions, (p) => dispatch(onPermissionCreateSuccess(p)));

        const occurrence = _omit(addedOccurrence, ['__permissions']);

        dispatch(addOccurrenceSuccess(occurrence));
        clearCacheOccurrenceAddEditFormInitialValues();

        createParams?.newUsers?.length && dispatch(fetchMeetingUsers());

        return occurrence;
    };

const onAddOccurrence = (values) => async (dispatch) => {
    const params = transformOccurrenceAddFormForPOST(values);

    try {
        const addedOccurrence = await addOccurrence(params);

        const occurrence = dispatch(
            processAddedOccurrence(params, addedOccurrence)
        );

        return occurrence;
    } catch (error) {
        // TODO: check processBodyErrors to make sure it works for occurrences
        const newError = await formSubmissionServerError(
            error,
            processBodyErrors
        );
        dispatch(addOccurrenceError(newError));
        dispatch(
            alertError({
                id: 'onAddOccurrence',
                title: 'Meeting could not be created',
                error,
            })
        );

        throw newError;
    }
};

const onEditOccurrence = (values, formApi) => async (dispatch, getState) => {
    const { initialValues } = formApi.getState();
    const occurrence = transformOccurrenceEditFormForPOST(
        values,
        initialValues
    );

    const prevOccurrence = getOccurrenceFromStoreById(
        getState(),
        occurrence.id
    );
    try {
        // dates are sent to the api as a string, however redux store requires all dates as moments.
        // Therefore rever the startDate and endDate to their valid moment dates for the optimistic update to the store.
        const tempOccurrence = {
            ...prevOccurrence,
            ...occurrence,
        };
        if (occurrence.startDate) {
            tempOccurrence.startDate = values.recurrenceDetail.startDate;
        }
        if (occurrence.endDate) {
            tempOccurrence.endDate = values.recurrenceDetail.endDate;
        }
        if (occurrence.startDateActual) {
            tempOccurrence.startDateActual = values.startDateActual;
        }
        if (occurrence.endDateActual) {
            tempOccurrence.endDateActual = values.endDateActual;
        }
        // Optimistic update
        dispatch(editOccurrenceRequest(tempOccurrence));
        // API call
        const updatedOccurrence = await editOccurrence(
            occurrence.id,
            occurrence
        );

        dispatch(editOccurrenceSuccess(updatedOccurrence));
        clearCacheOccurrenceAddEditFormInitialValues();

        if (_get(occurrence, 'newUsers.length', 0) > 0) {
            dispatch(fetchMeetingUsers());
        }
        return updatedOccurrence;
    } catch (error) {
        // re-shape error for use by final-form
        const newError = await formSubmissionServerError(
            error,
            processBodyErrors
        );
        dispatch(editOccurrenceError(prevOccurrence));
        throw newError;
    }
};

export const onUpdateOccurrence =
    (occurrence) =>
    async (
        dispatch,
        getState
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line require-await
    ) => {
        const prevOccurrence = getOccurrenceFromStoreById(
            getState(),
            occurrence.id
        );

        const tempOccurrence = {
            ...prevOccurrence,
            ...occurrence,
        };
        dispatch(editOccurrenceRequest(tempOccurrence));

        dispatch(onUpdateOccurrenceBackground(occurrence, prevOccurrence));

        return tempOccurrence;
    };

const onUpdateOccurrenceBackground =
    (occurrence, prevOccurrence) => async (dispatch) => {
        try {
            const updatedOccurrence = await editOccurrence(
                occurrence.id,
                occurrence
            );

            dispatch(editOccurrenceSuccess(updatedOccurrence));

            if (_get(occurrence, 'newUsers.length', 0) > 0) {
                dispatch(fetchMeetingUsers());
            }
            return updatedOccurrence;
        } catch (error) {
            dispatch(editOccurrenceError(prevOccurrence));
            dispatch(
                alertError({
                    id: 'onUpdateOccurrenceBackground',
                    title: 'Meeting could not be updated',
                    error,
                })
            );
        }
    };

export const onOccurrencesDelete =
    (occurrences, isUpdateEvent) => (dispatch) => {
        occurrences.some((occurrence) => {
            if (isViewingOccurrence(occurrence)) {
                const dateTime = occurrence?.startDate
                    ? `on
            ${fullDateTime(occurrence.startDate)}`
                    : '';
                !isUpdateEvent &&
                    displaySuccess({
                        id: `deleted-occurrence-success-${occurrence.id}`,
                        title: `The “${occurrence.title}” meeting ${dateTime} was deleted`,
                    });
                dispatch(replace(getMeetingSlug(occurrence.meeting)));
                return true;
            }
        });
        dispatch({ type: DELETE_OCCURRENCES_WEBSOCKET, occurrences });
    };

export const loadOccurrence = (id) => async (dispatch) => {
    const payload = await getOccurrence(id);
    dispatch(addOccurrenceSuccess(payload));
    return payload;
};

export const loadOccurrenceActivities = (occurrence) => async (dispatch) => {
    if (!occurrence?.id || !isFeatureEnabled('SEND_EMAIL_ACTIVITY')) return;

    try {
        const activities = await getActivitiesForOccurrence(occurrence.id);

        dispatch({
            type: OCCURRENCE_ACTIVITIES_LOADED,
            occurrenceId: occurrence.id,
            activities,
        });
    } catch (error) {
        // May be if the authorisation fails, server is unavailable, version expired
        // errors are handled in the get function
        dispatch(
            alertError({
                id: 'loadOccurrenceActivities',
                title: 'Meeting activities could not be loaded',
                error,
            })
        );
    }
};

export const loadOccurrenceActivity =
    ({ activityId, occurrenceId }) =>
    async (dispatch) => {
        if (!occurrenceId || !activityId) return;

        try {
            const activity = await getActivity(activityId);

            dispatch({
                type: OCCURRENCE_ACTIVITY_LOADED,
                occurrenceId: occurrenceId,
                activity,
            });
        } catch (error) {
            // May be if the authorisation fails, server is unavailable, version expired
            // errors are handled in the get function
            dispatch(
                alertError({
                    id: 'loadOccurrenceActivity',
                    title: 'Meeting activity could not be loaded',
                    error,
                })
            );
        }
    };

export const loadActivityCount = (occurrence) => async (dispatch) => {
    if (!occurrence?.id || !isFeatureEnabled('SEND_EMAIL_ACTIVITY')) return;

    try {
        const { activityCount } = await getActivityCountForOccurrence(
            occurrence.id
        );
        dispatch({
            type: OCCURRENCE_ACTIVITY_COUNT_LOADED,
            occurrenceId: occurrence.id,
            activityCount,
        });
    } catch (error) {
        // May be if the authorisation fails, server is unavailable, version expired
        // errors are handled in the get function
        dispatch(
            alertError({
                id: 'loadOccurrenceActivityCount',
                title: 'Meeting activity count could not be loaded',
                error,
            })
        );
    }
};
