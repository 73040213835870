import _pick from 'lodash/pick.js';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RTE_TINYMCE } from '~common/constants';
import { getMeetingIdSelector } from '~common/selector.helpers';
import { onAgendaItemSave } from '~modules/agenda/agenda.actions';
import { logDocumentToConsole } from '~modules/richtext/helpers';

export const useUpdateAgendaItem = (agendaItem) => {
    const dispatch = useDispatch();
    const [requiredSaveFields] = useState({
        ..._pick(agendaItem, ['meeting', 'templateId']),
    });
    const meetingId = getMeetingIdSelector(null, agendaItem);

    return useCallback(
        (content) => {
            // in Slate, html content was stored without newline markers.
            // Tiny inserts newline markers. So for legacy comparison, we ignore any newline markers.
            const before = (agendaItem?.content || '').replace(/\n/g, '');
            const after = (content || '').replace(/\n/g, '');
            if (after !== before) {
                logDocumentToConsole(
                    'useUpdateAgendaItem: ',
                    {
                        before,
                        after,
                    },
                    'debug'
                );

                dispatch(
                    onAgendaItemSave(meetingId, {
                        id: agendaItem.id,
                        ...requiredSaveFields,
                        content: content || null, // to allow content to be set back to blank
                        rte: RTE_TINYMCE,
                    })
                );
            }
        },
        [
            dispatch,
            meetingId,
            requiredSaveFields,
            agendaItem?.id,
            agendaItem?.content,
        ]
    );
};

export const useDoesMeetingHaveAgendaItems = (meetingId) => {
    const hasAgendaItems = useSelector((state) =>
        state.agendaItems.agendaItems.some((a) => a.meeting === meetingId)
    );
    return hasAgendaItems;
};
