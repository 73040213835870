import React from 'react';
export default ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        {...rest}
    >
        <path
            stroke="currentColor"
            strokeWidth="1.8"
            d="M20.072 15.126v0a2.592 2.592 0 0 0 3.596.039l4.815-4.541A5.064 5.064 0 0 0 28.63 3.4v0a5.066 5.066 0 0 0-7.133-.112l-4.419 4.248A3.149 3.149 0 0 0 17 12v0M15.091 20.107v0a2.609 2.609 0 0 1 .01 3.608l-4.544 4.772a5.065 5.065 0 0 1-7.249.089v0a5.063 5.063 0 0 1-.053-7.107l4.296-4.427A3.128 3.128 0 0 1 12 17v0"
            vectorEffect="non-scaling-stroke"
        />
        <path
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
            d="M8 8l16 16"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
