import React from 'react';
export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m16 2v3.4667c0 2.2091 1.7909 4 4 4h3m-7-7.4667h-9c-2.2091 0-4 1.7909-4 4v20c0 2.2091 1.7909 4 4 4h12c2.2091 0 4-1.7909 4-4m-7-24 7 7.4667v5.5333"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m18 20.5h12m0 0-3.6923-4.5m3.6923 4.5-3.6923 4.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
