import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m22.124 25h-2.565c-0.22 0-0.395-0.055-0.525-0.165-0.12-0.12-0.22-0.25-0.3-0.39l-3.825-5.955c-0.04 0.14-0.085 0.28-0.135 0.42-0.04 0.13-0.1 0.25-0.18 0.36l-3.54 5.175c-0.1 0.14-0.21 0.27-0.33 0.39-0.11 0.11-0.265 0.165-0.465 0.165h-2.385l5.325-7.8-5.115-7.395h2.565c0.22 0 0.38 0.035 0.48 0.105s0.19 0.17 0.27 0.3l3.735 5.7c0.08-0.29 0.205-0.57 0.375-0.84l3.27-4.8c0.09-0.14 0.185-0.25 0.285-0.33 0.11-0.09 0.24-0.135 0.39-0.135h2.46l-5.115 7.26 5.325 7.935z"
            fill="currentColor"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
        />
        <path
            d="m27.505 11.85c0.1333 0 0.2383 0.0383 0.315 0.115 0.08 0.0733 0.12 0.1717 0.12 0.295v0.74h-4.96v-0.41c0-0.0833 0.0167-0.17 0.05-0.26 0.0367-0.0933 0.0933-0.1767 0.17-0.25l2.195-2.2c0.1833-0.18667 0.3467-0.365 0.49-0.535 0.1467-0.17 0.2683-0.33833 0.365-0.505s0.17-0.335 0.22-0.505c0.0533-0.17333 0.08-0.355 0.08-0.545 0-0.17333-0.025-0.325-0.075-0.455-0.05-0.13333-0.1217-0.245-0.215-0.335-0.09-0.09-0.2-0.15667-0.33-0.2-0.1267-0.04667-0.27-0.07-0.43-0.07-0.1467 0-0.2833 0.02167-0.41 0.065-0.1233 0.04-0.2333 0.09833-0.33 0.175-0.0967 0.07333-0.1783 0.16-0.245 0.26s-0.1167 0.21-0.15 0.33c-0.0567 0.15333-0.13 0.255-0.22 0.305s-0.22 0.06167-0.39 0.035l-0.65-0.115c0.05-0.34667 0.1467-0.65 0.29-0.91 0.1433-0.26333 0.3217-0.48167 0.535-0.655 0.2167-0.17667 0.4633-0.30833 0.74-0.395 0.28-0.09 0.58-0.135 0.9-0.135 0.3333 0 0.6383 0.05 0.915 0.15 0.2767 0.09667 0.5133 0.235 0.71 0.415s0.35 0.39667 0.46 0.65 0.165 0.535 0.165 0.845c0 0.26667-0.04 0.51333-0.12 0.74-0.0767 0.22667-0.1817 0.44333-0.315 0.65-0.13 0.20667-0.2833 0.40667-0.46 0.6s-0.3617 0.39-0.555 0.59l-1.63 1.665c0.1567-0.0467 0.3117-0.0817 0.465-0.105 0.1567-0.0267 0.305-0.04 0.445-0.04h1.855z"
            fill="currentColor"
            stroke="currentColor"
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.2"
        />
    </svg>
);
