import React from 'react';

export default ({ ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m21.308 22.346 7.6923-6.25-7.6923-6.25"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            strokeWidth="2"
        />
        <path
            d="m11.692 22.346-7.6923-6.25 7.6923-6.25"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            strokeWidth="2"
        />
        <path
            d="m18.423 6-3.8462 20.192"
            stroke="currentColor"
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
            strokeWidth="2"
        />
    </svg>
);
