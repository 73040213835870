import { useMemo } from 'react';
import { useAuthIntegrationProfile, useUser } from '~hooks';
import { getContactsConfig } from '~modules/remoteContacts/remoteContacts.mappers';
import { hasIntegrationProfileGotScopes } from '~shared/integration/integration.helper';

/**
 * Determine if the logged in user can link up with remote contacts.
 * If they have already linked up with remote contacts, or the authSource they've logged in isn't
 * a supported remoteContacts provider, return false.
 * Otherwise return details to help provide a link to link up.
 * @returns {Object}
 */
const useCanLinkContacts = () => {
    const authProfile = useAuthIntegrationProfile();
    const {
        emailDomainMeta: { hostedDomain, hostedDomainType },
    } = useUser();
    return useMemo(() => {
        const authSource = authProfile?.authSource;
        const contactConfig = getContactsConfig({ authSource });
        const hostedDomainContactConfig = getContactsConfig({
            authSource: hostedDomainType,
        });

        if (
            (!contactConfig?.scopes?.length &&
                !hostedDomainContactConfig?.scopes?.length) ||
            !authProfile
        )
            return {
                canLinkContacts: false,
            };

        const hasLinkedContacts = hasIntegrationProfileGotScopes(
            authProfile,
            contactConfig.scopes
        );

        const canLinkContactsWithCorrectLogin =
            Object.keys(contactConfig).length === 0 &&
            Object.keys(hostedDomainContactConfig).length > 0;

        return {
            canLinkContacts: !hasLinkedContacts,
            authSource,
            canLinkContactsWithCorrectLogin,
            hostedDomain,
        };
    }, [authProfile, hostedDomain, hostedDomainType]);
};

export { useCanLinkContacts };
