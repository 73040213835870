import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m19.5 2c0.319 0 0.6238 0.13204 0.842 0.36477l6.2932 6.7128c0.2344 0.24997 0.3648 0.57977 0.3648 0.92242m-7.5-8h-10.5c-2.2091 0-4 1.7909-4 4v20c0 2.2091 1.7909 4 4 4h14c2.2091 0 4-1.7909 4-4v-16m-7.5-8v4c0 2.2091 1.7909 4 4 4h3.5"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m15.583 16.967c0.3453 0 0.6463-0.042 0.903-0.126 0.2567-0.0887 0.469-0.2123 0.637-0.371 0.1727-0.1633 0.301-0.3593 0.385-0.588 0.084-0.2333 0.126-0.4923 0.126-0.777 0-0.2707-0.042-0.5157-0.126-0.735s-0.21-0.406-0.378-0.56-0.3803-0.2707-0.637-0.35c-0.2567-0.084-0.56-0.126-0.91-0.126h-1.407v3.633h1.407zm0-5.089c0.6767 0 1.2623 0.0793 1.757 0.238s0.903 0.3803 1.225 0.665 0.56 0.6253 0.714 1.022c0.1587 0.3967 0.238 0.8307 0.238 1.302 0 0.49-0.0817 0.9403-0.245 1.351-0.1633 0.406-0.4083 0.756-0.735 1.05s-0.7373 0.5227-1.232 0.686c-0.49 0.1633-1.064 0.245-1.722 0.245h-1.407v3.563h-1.883v-10.122h3.29z"
            fill="currentColor"
        />
    </svg>
);
