// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import {
    FETCH_INTEGRATION_PROFILES_SUCCESS,
    INTEGRATION_PROFILE_DISCONNECTED,
    INTEGRATION_PROFILE_CREATED,
    INTEGRATION_PROFILE_UPDATED,
    INTEGRATION_PROFILE_LINKCOUNT,
    INTEGRATION_PROFILE_TASKLISTS,
    INTEGRATION_PROFILE_CALENDAR,
} from '../../common/action.types.js';

const initialState = {
    taskLists: {},
    linkCounts: {},
    profiles: [],
};

const confirmReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INTEGRATION_PROFILES_SUCCESS: {
            return {
                ...state,
                profiles: _.uniqBy(
                    [...action.integrationProfilesList, ...state.profiles],
                    'id'
                ),
            };
        }

        case INTEGRATION_PROFILE_CREATED:
        case INTEGRATION_PROFILE_UPDATED:
        case INTEGRATION_PROFILE_DISCONNECTED: {
            return {
                ...state,
                profiles: _.uniqBy([action.profile, ...state.profiles], 'id'),
            };
        }

        case INTEGRATION_PROFILE_LINKCOUNT: {
            return {
                ...state,
                linkCounts: {
                    ...state.linkCounts,
                    [action.profile.id]: action.linkCount,
                },
            };
        }

        case INTEGRATION_PROFILE_TASKLISTS: {
            return {
                ...state,
                taskLists: {
                    ...state.taskLists,
                    [action.profile.id]: action.taskLists,
                },
            };
        }

        case INTEGRATION_PROFILE_CALENDAR: {
            return {
                ...state,
                calendarList: {
                    ...state.calendarList,
                    [action.profile.id]: action.calendarList,
                },
            };
        }

        default:
            return state;
    }
};

export default confirmReducer;
