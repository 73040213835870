import _isEqual from 'lodash/isEqual.js';
import React from 'react';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import tw, { styled, theme } from 'twin.macro';
import { NavLink } from 'react-router-dom';
import { getOccurrenceSlug, getDashboardPathParams } from '~modules/navigation';
import { formatAsTimeRange } from '~common/time.utils';
import { labelDisplay } from '~modules/common/components/MeetingDetail/Header/utils';
import { getUserListFromAttendees } from '~modules/user/user.selectors';
import { TIME_FORMAT_FOR_DISPLAY } from '~common/constants';
import { openAddRemoteCalendarMeetingModal } from '~modules/remoteCalendar/remoteCalendar.actions';
import { useMeetingById, useRemoteCalendarMeetingLink } from '~hooks';
import {
    getMeetingLocationLabel,
    getFormattedMeetingDuration,
} from '~modules/meeting/meeting.helpers';
import { Tooltip } from '~modules/common/components';
import {
    JoinOnlineButton,
    ToggleHideEvent,
} from '~modules/dashboard/meetings/components';
import {
    IconContainer,
    RepeatingIcon,
    ArchivedIcon,
    PrivateIcon,
    RemoteCalendarIcon,
    RemoteCRMIcon,
} from '~modules/common/components/MeetingIcons';
import OccurrenceCountdownTimer from '~modules/occurrence/components/OccurrenceCountdownTimer';
import {
    getIsAfterMeetingEndTime,
    getIsOneHourPastScheduledMeetingEnd,
    getIsPriorToMeetingStartTime,
    getIsPriorToMeetingStartTimeMinusThirtyMinutes,
} from '~modules/occurrence/occurrence.helpers';

const Container = tw.div`flex`;
const Content = styled(NavLink, {
    shouldForwardProp: (prop) => !['isPast'].includes(prop),
})(({ isPast }) => [
    tw`flex w-full leading-tight focus:outline-none`,
    tw`focus:outline-none hover:text-body-dark focus:text-body-dark`,
    isPast && tw`opacity-75 hover:opacity-100 focus:opacity-100`,
]);

const Time = tw.div`text-sm pr-0  w-20 sm:(text-base pr-4 w-[7.5rem]) shrink-0 space-y-1`;
const TimerWrap = styled.div(({ timerIsActive }) => [
    tw`flex`,
    timerIsActive && tw`-ml-[7px]`,
]);
const ColorWrap = tw.div`flex items-start justify-center px-2 h-5`;
const CategoryColor = styled.span(({ accentColor, isPast }) => [
    tw`mt-[2px] w-1 h-4`,
    isPast && tw`opacity-50 group-hocus:opacity-100`,
    accentColor
        ? [
              { backgroundColor: accentColor },
              tw`rounded [transition:transform 0.15s ease-out] origin-center group-hocus:[transform:scaleY(1.2)]`,
          ]
        : {
              background: `linear-gradient(to bottom, ${theme`colors.line.dark`}, ${theme`colors.line.dark`} 50%, transparent 50%, transparent)`,
              backgroundSize: '100% 4px',
              backgroundPosition: 'center',
          },
]);
const Details = tw.div`relative w-full ml-2 text-sm sm:text-base min-w-0`;
const Heading = tw.div`flex items-start text-body-dark text-left`;
const Line = tw.div`flex flex-wrap mt-1`;
const Detail = tw.span`truncate`;
const Divider = tw.span`px-2 text-icon`;
const ActionsWrap = tw.div`px-3 text-right sm:mt-[-.1em]`;

const MeetingCard = (data) => {
    const {
        title,
        meetingTitle,
        groupTitle,
        attendees,
        accentColor,
        location,
        locationOnline,
        isRecurring,
        isPrivate,
        recurrenceText,
        isArchived,
        onClick,
        calendarType,
        isUnlinkedEvent,
        isSize,
    } = data;
    const meeting = useMeetingById(data.meeting);

    const dispatch = useDispatch();
    const remoteLink = useRemoteCalendarMeetingLink(data.id);

    const slugObject = remoteLink ? { id: remoteLink.occurrence } : data;

    const [, secondLevelDashboard, , firstLevelDashboard] =
        getDashboardPathParams();

    const dashboardType =
        secondLevelDashboard === 'series' || firstLevelDashboard === 'series'
            ? 'series'
            : 'meetings';

    const slug = getOccurrenceSlug(slugObject, {
        state: { dashboardType },
    });

    const startTime = data.startDate.formatForUser(TIME_FORMAT_FOR_DISPLAY);
    const { startDate, endDate } = data;

    const meetingDuration = getFormattedMeetingDuration(startDate, endDate);
    const timeRange = formatAsTimeRange(startDate, endDate);

    const attendeesAsUsers = getUserListFromAttendees({ attendees });
    const isPast = data.endDate.isBefore(moment());

    const hasLocation = Boolean(location || locationOnline);
    const locationLabel = getMeetingLocationLabel(location, locationOnline);

    const contentProps = {
        to: isUnlinkedEvent ? {} : slug,
        isPast,
        className: 'group',
        onClick: (evt) => {
            onClick?.(evt);
            // Prevent the NavLink from navigating if it's an Unlinked event, as the `to` key of this `contentProps` is an empty object {}
            isUnlinkedEvent &&
                (evt.preventDefault(),
                dispatch(
                    openAddRemoteCalendarMeetingModal({
                        eventId: data.id,
                        seriesMasterId: data.meeting,
                        recurrence: data.recurrence,
                        calendarId: data.calendar,
                        calendarType,
                    })
                ));
        },
    };

    const blip = (
        <ColorWrap>
            <CategoryColor {...{ accentColor, isPast }} />
        </ColorWrap>
    );

    const icons = (
        <IconContainer tw="mt-0.5">
            {isRecurring && <RepeatingIcon {...{ recurrenceText }} />}
            {isArchived && <ArchivedIcon />}
            <RemoteCalendarIcon id={data.id} />
            <RemoteCRMIcon meetingId={data.meeting} />
            {isPrivate && <PrivateIcon />}
        </IconContainer>
    );

    // Display timer props
    const occurrenceDate = data?.startDateActual || startDate;
    const occurrenceEndDate = data?.endDateActual || endDate;

    const isPriorToMeetingStartTime =
        getIsPriorToMeetingStartTime(occurrenceDate);

    const isAfterMeetingEndTime = getIsAfterMeetingEndTime(occurrenceEndDate);

    const isPriorToMeetingStartTimeMinusThirtyMinutes =
        getIsPriorToMeetingStartTimeMinusThirtyMinutes(occurrenceDate);

    const isMeetingUnderway =
        !isPriorToMeetingStartTime && !isAfterMeetingEndTime;

    const occurrenceIsToday = occurrenceDate.isSame(new Date(), 'day');

    const isOneHourPastScheduledMeetingEnd =
        getIsOneHourPastScheduledMeetingEnd(occurrenceEndDate);

    const timerIsActive =
        !meeting?.isIntro &&
        occurrenceIsToday &&
        (!isPriorToMeetingStartTimeMinusThirtyMinutes || isMeetingUnderway) &&
        !isOneHourPastScheduledMeetingEnd;

    const timeDisplay = <div tw="text-body-dark">{startTime}</div>;

    return (
        <Container className="group">
            <Content {...contentProps}>
                <Tooltip
                    content={`From ${timeRange}`}
                    position="bottom left"
                    enableTooltip={!timerIsActive}
                    offset={[-16, 0]}
                >
                    <Time>
                        <TimerWrap {...{ timerIsActive }}>
                            <OccurrenceCountdownTimer
                                isSimple
                                occurrence={data}
                                hasRestrictedWidth={isSize.tiny}
                                displayWhenTimerNotActive={timeDisplay}
                            />
                        </TimerWrap>

                        <div>{meetingDuration}</div>
                    </Time>
                </Tooltip>
                {groupTitle ? (
                    <Tooltip content={`Category “${groupTitle}”`}>
                        {blip}
                    </Tooltip>
                ) : (
                    blip
                )}
                <Details {...{ accentColor }}>
                    <Heading>
                        <div>
                            {title}
                            {!_isEqual(title, meetingTitle) &&
                                Boolean(meetingTitle) && (
                                    <span>&nbsp;({meetingTitle})</span>
                                )}
                        </div>
                        {icons}
                    </Heading>

                    {(attendeesAsUsers.length || location) && (
                        <Line>
                            {!isSize.small && attendeesAsUsers.length > 0 && (
                                <Detail>
                                    {labelDisplay({
                                        data: attendeesAsUsers,
                                        fieldName: 'givenName',
                                        maxDisplay: 3,
                                    })}
                                </Detail>
                            )}
                            {!isSize.small &&
                                attendeesAsUsers.length > 0 &&
                                hasLocation && <Divider>&middot;</Divider>}
                            {hasLocation && <Detail>{locationLabel}</Detail>}
                        </Line>
                    )}
                </Details>
            </Content>
            <ActionsWrap>
                <JoinOnlineButton {...{ startDate, endDate, locationOnline }} />
                {isUnlinkedEvent && (
                    <ToggleHideEvent id={data.id} {...{ calendarType }} />
                )}
            </ActionsWrap>
        </Container>
    );
};

export default MeetingCard;
