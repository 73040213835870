import React from 'react';
import tw, { styled, css } from 'twin.macro';
import CheckRadioBase from '~modules/common/form-elements/CheckRadioBase';

const Wrap = styled.span`
    ${tw`relative`}
    ${({ isWhite }) => (isWhite ? tw`text-white` : tw`text-current`)}

    ${({ hasLabel }) =>
        hasLabel &&
        css`
            &:before {
                ${tw`absolute cursor-pointer`}
                content: '';
                top: -0.5em;
                right: -0.5em;
                bottom: -0.5em;
                left: -0.5em;
            }
        `}
    ${({ disabled }) =>
        disabled &&
        css`
            &:before {
                ${tw`cursor-not-allowed`}
            }
        `}
`;

const Checkbox = styled(CheckRadioBase)`
    & + div {
        ${({ isWhite }) => isWhite && tw`border-white`}
        ${({ disabled, isWhite }) => disabled && isWhite && tw`opacity-50`}
    }
    &:checked + div {
        ${({ disabled, isWhite }) =>
            !disabled &&
            !isWhite &&
            tw`bg-primary border-primary text-white hover:text-white`}
        ${({ isWhite }) =>
            isWhite && tw`bg-white text-primary hover:text-primary`}
    }
    &:active + div {
        ${({ checked }) => !checked && tw`text-primary bg-solid`}
    }
`;

// requires: checked, onChange
export default ({
    isWhite = false,
    hasLabel = false,
    disabled = false,
    title = '',
    ...rest
}) => (
    <Wrap
        {...{ isWhite, hasLabel, disabled, title }}
        as={hasLabel ? 'label' : null}
    >
        <Checkbox type="checkbox" {...{ isWhite, disabled }} {...rest} />
    </Wrap>
);
