import React from 'react';
import moment from 'moment-timezone';
import tw from 'twin.macro';

const MessageWrap = tw.div`flex items-center`;

export function canRenderArchivedMessage({
    date,
    isAdmin,
    isArchived,
    isNotStarted,
}) {
    const isOlderThan1Week = date.diff(moment(), 'days', true) < -7;

    return isAdmin && !isArchived && isOlderThan1Week && isNotStarted;
}

export const OccurrenceArchiveMessage = ({
    date,
    isAdmin,
    isArchived,
    isNotStarted,
    children,
}) => {
    return canRenderArchivedMessage({
        date,
        isAdmin,
        isArchived,
        isNotStarted,
    }) ? (
        <MessageWrap>Meeting missed?</MessageWrap>
    ) : (
        children
    );
};
