import { operation as retryOperation } from 'retry';
import { StatusCodes } from 'http-status-codes';

import { MaxRetriesReachedError } from '~client/common/errors';

/*  Different apis that use this may return different shaped error objects
    https://vitaly-t.github.io/pg-promise/errors.QueryResultError.html
        code
    https://docs.microsoft.com/en-us/graph/errors
        status
        error: {
            code
            message
            innerError
        }
*/

export function faultTolerantResolve(
    actionDescription,
    action,
    options = {
        retries: 5,
        factor: 2,
        minTimeout: 1 * 1000,
        maxTimeout: 60 * 1000,
    }
) {
    /* Note retries use exponential backoff.
       With factor 2 and 5 retries:
       Try 1
       Retry 1 at 1 second later
       Retry 2 at 2 seconds later (3 seconds elapsed)
       Retry 3 at 4 seconds later (7 seconds elapsed)
       Retry 4 at 8 seconds later (15 seconds elapsed)
       Retry 5 at 16 seconds later (31 seconds elapsed)
    */

    const operation = retryOperation(options);

    let isStopped = false;

    const cancel = () => {
        isStopped = true;
        operation.stop();
    };

    return {
        promise: new Promise((resolve, reject) => {
            operation.attempt(async (currentAttempt) => {
                try {
                    const result = await action();
                    if (!isStopped) {
                        return resolve(result);
                    }
                    return;
                } catch (err) {
                    const status = err?.statusCode || err?.status || 523; // if no error provided, then it is likely a Fetch network error
                    if (
                        status >= 400 &&
                        status <= 501 &&
                        status != StatusCodes.TOO_MANY_REQUESTS
                    ) {
                        return reject(err);
                    }

                    currentAttempt > 1 &&
                        //eslint-disable-next-line no-console
                        console.log(
                            `[retry.helper (${currentAttempt})] ${new Date()} ${actionDescription}: ${
                                err.message
                            }`
                        );

                    if (!operation.retry(err)) {
                        // this is called if no error is given, or maximum amount of retries has been reached, or operation has been stopped.
                        if (isStopped) return;

                        if (operation.attempts() > options.retries) {
                            //eslint-disable-next-line no-console
                            console.error(
                                `[retry.helper (${operation.attempts()})] ${new Date()} ${actionDescription}: Max retries completed. Event not processed.`
                            );
                            return reject(new MaxRetriesReachedError(err));
                        }
                    }
                }
            });
        }),
        cancel,
    };
}
