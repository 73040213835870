import { Menu as SemanticMenu } from 'semantic-ui-react';
import tw, { styled, css } from 'twin.macro';
import 'semantic-ui-css/components/menu.css';
import { customScrollbar } from '~common/ui';

const NavLinkCount = tw.div`ml-1 text-center text-xs text-body bg-line opacity-75 rounded leading-normal h-4 pl-1 pr-1`;

const Menu = styled(SemanticMenu, {
    shouldForwardProp: (prop) => !['isFullWidth'].includes(prop),
})`
    &.ui.tabular.menu.top {
        ${customScrollbar({ showWhenScrollable: true })}
        ${tw`border-line flex-nowrap overflow-x-auto overflow-y-hidden after:hidden`}
        a.item {
            ${tw`px-3 md:px-3.5 first-of-type:pl-0 py-0 text-inherit border-0 bg-inherit hocus:text-body-dark [line-height:var(--tab-menu-height)]`}

            &:after {
                height: 4px;
                bottom: -1px;
                box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.5);
                ${tw`rounded-t mx-3 bg-primary absolute left-0 right-0 pointer-events-none`}
            }

            &:first-of-type:after {
                ${tw`ml-0`}
            }

            &.active:after {
                content: '';
            }

            &.active {
                ${tw`text-body-dark mb-0 font-normal`}
            }
        }
        ${({ isFullWidth }) =>
            isFullWidth &&
            // Allow TabMenu to span full width of its container
            css`
                a.item {
                    ${tw`!px-3.5 lg:!px-1.5 xl:!px-3.5`}
                    &:first-of-type {
                        ${tw`!pl-0 !ml-5`}
                    }
                }
            `}
    }
`;

const TabMenu = ({ children, ...rest }) => (
    <Menu attached="top" tabular {...rest}>
        {children}
    </Menu>
);

export { TabMenu as default, NavLinkCount };
