import React from 'react';

export default ({ iconThick = false, commentCount = 0, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m8.9136 3.5052c2.1691-1.0819 4.5312-1.6229 7.0864-1.6229 0.6434 0 1.2959 0.04202 1.9577 0.12605 3.5294 0.42017 6.4614 1.7962 8.7959 4.1282 2.2427 2.2689 3.364 4.8845 3.364 7.8467 0 2.1848-0.6296 4.2069-1.8888 6.0661-1.2592 1.8593-2.9733 3.3299-5.1424 4.4118s-4.5313 1.6229-7.0864 1.6229h-0.7445c-0.5515 0-1.0662-0.042-1.5441-0.126-0.239 0.3361-0.6664 0.7773-1.2822 1.3235s-1.4108 1.1134-2.3851 1.7017c-0.97428 0.5882-1.8842 0.9664-2.7298 1.1344h-0.22059c-0.22059 0-0.40901-0.0893-0.56526-0.2678-0.15625-0.1786-0.23437-0.3939-0.23437-0.646 0-0.1261 0.01838-0.2416 0.05515-0.3467 0.9375-1.8487 1.4062-3.4559 1.4062-4.8214 0-0.021 0.00459-0.0368 0.01378-0.0473s0.01379-0.0262 0.01379-0.0472c-1.875-1.0925-3.3272-2.5053-4.3566-4.2385s-1.5441-3.6397-1.5441-5.7195c0-2.1849 0.6296-4.207 1.8888-6.0662 1.2592-1.8592 2.9734-3.3298 5.1425-4.4118z"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        {commentCount > 0 && (
            <text
                x="48%"
                y="47%"
                className="comment-number"
                dominantBaseline="middle"
                textAnchor="middle"
                fill="currentColor"
                style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    letterSpacing: `-.06em`,
                    userSelect: 'none',
                }}
            >
                {commentCount}
            </text>
        )}
    </svg>
);
