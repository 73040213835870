import filenamify from 'filenamify';

const WORD_REG = /\.(doc|docx|docm|dotm|dotx)$/i;
const EXCEL_REG = /\.(xlsx|xlsb|xls|xlsm)$/i;
const PPT_REG = /\.(pptx|ppsx|ppt|pps|pptm|potm|ppam|potx|ppsm)$/i;
const OFFICE_DOC_REG =
    /\.(doc|docx|docm|dotm|dotx|xlsx|xlsb|xls|xlsm|pptx|ppsx|ppt|pps|pptm|potm|ppam|potx|ppsm)$/i;
const PDF_REG = /\.pdf$/i;
const IMAGE_REG = /\.(jpeg|jpg|gif|png|bmp|svg)$/i;
const JPG_OR_PNG = /\.(jpeg|jpg|png)$/i;

// Examples:
// option 1:
//- iPhone: 58AF4932-E0B1-4638-817B-FF794629427B.jpg
//- android: image-338273c6-141a-4a72-8653-e5311624287a9156783241182509722.jpg
// option 2:
//- android: 20190713_195918.jpg
//
// option 3: Screenshot:
//- android: Screenshot.png
//-          Screen Shot
//
// option 4: Photos from OfficeLens:
//2019_07_30 09_15 Office Lens.jpg
//
// option 5: Photo naming on iPhones / cameras: https://en.wikipedia.org/wiki/Design_rule_for_Camera_File_system
// files with names such as "ABCD1234.JPG" that consist of four alphanumeric characters (often "DSC_", "DSC0", "DSCF", "IMG_"/"MOV_", or "P000"), followed by a number.

// TODO: Fix this the next time the file is edited.

const renamableImageFilename =
    /({){0,1}[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}(}){0,1}|^[\d,_-]+\.|scre{2}n *shot|of{2}ice *lens|^(\w{4})(\d{4}).jpg$/i;

export function isImage(filename) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-regexp-test
    return !!String(filename).match(IMAGE_REG);
}

export function isJPEGOrPNG(filename) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-regexp-test
    return !!String(filename).match(JPG_OR_PNG);
}

export function isRenamableImage(filename) {
    return isJPEGOrPNG(filename) && renamableImageFilename.test(filename);
}

export function isPdf(filename) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-regexp-test
    return !!String(filename).match(PDF_REG);
}

export function isOfficeDoc(filename) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-regexp-test
    return !!String(filename).match(OFFICE_DOC_REG);
}

export function isWord(filename) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-regexp-test
    return !!String(filename).match(WORD_REG);
}

export function isExcel(filename) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-regexp-test
    return !!String(filename).match(EXCEL_REG);
}

export function isPowerpoint(filename) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/prefer-regexp-test
    return !!String(filename).match(PPT_REG);
}

export function isViewable(f) {
    return isImage(f) || isPdf(f) || isOfficeDoc(f);
}

export function getFileExtension(f, withDot = false) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line unicorn/better-regex
    const match = (f || '').match(/\.([a-z0-9]+)$/i);
    if (match && match.length > 1) {
        return withDot ? match[0].toLowerCase() : match[1].toLowerCase();
    }
}

export const makeFilename = (filename = '', extension = '') =>
    filenamify(`${filename.trim().replace(extension, '')}${extension.trim()}`);
