import {
    get,
    post,
    deleteRequest,
    postSuppressingWS,
} from '../../api/helpers.js';
import { minuteItemModelList } from '../minutes/minutes.mappers.js';
import { occurrenceModel } from '../meeting/meeting.mappers.js';

const fromOccurrence = (occurrenceId) =>
    occurrenceId ? `/start-from/${occurrenceId}` : '';
const afterOccurrence = (occurrenceId) =>
    occurrenceId ? `/start-after/${occurrenceId}` : '';

export function getAgendaForMeeting(id) {
    return get(`/api/agenda/meeting/${id}`);
}

export function createAgendaItemsBulk(id, params) {
    return post(`/api/agenda/items`, params);
}

export function createAgendaItem(source, params, occurrenceId) {
    return post(
        `/api/agenda/item/create/${source}${fromOccurrence(occurrenceId)}`,
        params
    );
}
export function createAgendaItemUpdateAfter(source, params, occurrenceId) {
    return post(
        `/api/agenda/item/create/${source}${afterOccurrence(occurrenceId)}`,
        params
    );
}

export function editAgendaItem(id, params, occurrenceId) {
    return postSuppressingWS(
        `/api/agenda/item/${id}${fromOccurrence(occurrenceId)}`,
        params
    );
}

export function renumberAgendaItem(id, params, occurrenceId) {
    return post(
        `/api/agenda/item/${id}/renumber${fromOccurrence(occurrenceId)}`,
        params
    );
}

export function deleteAgendaItem(id, occurrenceId) {
    return deleteRequest(
        `/api/agenda/item/${id}${fromOccurrence(occurrenceId)}`
    );
}

export const applyTemplateToMeetingFromOccurrence = (
    meetingId,
    templateId,
    occurrenceId
) =>
    postSuppressingWS(
        `/api/agenda/items/${meetingId}/apply-template/${templateId}/start-from/${occurrenceId}`
    ).then((result) => ({
        ...result,
        occurrences: result?.occurrences?.map?.(
            ({ occurrence, minuteItems }) => ({
                occurrence: occurrenceModel(occurrence),
                minuteItems: minuteItemModelList(minuteItems),
            })
        ),
    }));
