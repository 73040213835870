import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m10.69 24h-2.8966c-3.1994 0-5.7931-2.4624-5.7931-5.5s1.6281-5.5 4.8276-5.5c0-4.4183 3.6743-8 8.2069-8 3.4442 0 6.3929 2.0682 7.6103 5 3.9993 0 7.3552 3.134 7.3552 7s-3.2421 7-7.2414 7h-1.4483"
            strokeLinecap="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m16 16v11m0-11-4 3.5m4-3.5 4 3.5"
            strokeLinecap="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
