import _camelCase from 'lodash/camelCase.js';
import { push, replace } from 'connected-react-router';
import { getStore } from '~common/store';
import {
    ACTION_ITEM_REVIEW,
    BASE_APP_HOMEPAGE,
    BASE_APP_PATH,
} from '~common/constants';
import { getSpecialDashboard } from '~modules/dashboard/dashboard.selectors';
import { getOccurrenceFromStoreById } from '~modules/occurrence/occurrence.selectors';
import { getUser } from '~modules/user/user.selectors';
import { ROUTES, makeQueryStringFromObject } from '~shared/navigation';

const VIEW_PAGE_REG = /^\/(occurrence|meeting)\//;
const MEETING_ID_REG = /^\/(?:occurrence|meeting)\/([\da-f]{24}|intro)\/?$/;
const MEETING_AND_TOPIC_REG =
    /^\/(?:occurrence|meeting)\/([\da-f]{24})\/minutes\/topic\/([\da-f]{24}|[\w-]*)\/?$/;
const MEETING_ANYTHING_ELSE = /^\/(?:occurrence|meeting)\/([\da-f]{24})(\/.*)$/;
const DASHBOARD_PAGE_REG =
    /^\/(?<dashName>meetings|series|search|action-items)\/(?<dashId>[\da-f]{24}|[\w-]+)\/?|^\/(?<dashNameAlt>meetings|series|documents|action-items|search|settings|templates)/;
const TEMPLATE_PAGE_REG =
    /^\/(templates)\/([\da-f]{24}|[\w-]+)\/?([\w-]+)?|^\/(templates)/;
const WORKSPACE_PAGE_REG =
    /^\/(workspaces)\/([\da-f]{24}|[\w-]+)\/?([\w-]+)?|^\/(workspaces)/;
const WORKSPACE_LIST_PAGE_REG = /^\/(workspaces)\/?/;
const DASHBOARD_SHARED_REG = /^\/(meetings|series)\/(shared)$/;
const DASHBOARD_ALL_REG = /^\/(meetings|series)$/;
const DASHBOARD_SCHEDULE_REG = /^\/(meetings)\/?/;
const SUBPAGE_REG = /^\/(minutes|action-items|documents)\/?/;
const INTEGRATION_SETTINGS_REMOTE_CALENDAR_REG =
    /^\/settings\/integration\/calendar\/(outlook-calendar|google-calendar)$/;
const HELP_CENTER_VIDEO_REG = /^\/help\/video\/(?<videoId>[\da-f]{24})$/;

export const getVideoIdFromPath = () => {
    return location.pathname.match(HELP_CENTER_VIDEO_REG)?.groups?.videoId;
};
export const getBaseAppHomepagePath = () => {
    const user = getUser();
    return user?.appPreferences?.myHomepage || BASE_APP_HOMEPAGE;
};

export const isViewingBaseAppHomepage = (dashboardType = BASE_APP_HOMEPAGE) =>
    String(dashboardType) === location.pathname;

export const isViewingHomepage = () => {
    const myHomepage = getBaseAppHomepagePath();
    return myHomepage === location.pathname;
};
export const getDashboardPath = (dashboardState, dashboardName) => {
    if (!dashboardState?.lastViewedPath)
        return getDashboardSlug({ id: dashboardName });
    return `${dashboardState.lastViewedPath}${dashboardState.search || ''}`;
};

export const getDashboardParams = (pathname = location.pathname) =>
    pathname.match(DASHBOARD_PAGE_REG)?.groups || {};

export const getDashboardPathParams = (pathname = location.pathname) =>
    pathname.match(DASHBOARD_PAGE_REG) || [];

export const getTemplatePathParams = (pathname = location.pathname) =>
    pathname.match(TEMPLATE_PAGE_REG) || [];

export const getWorkspacePathParams = (pathname = location.pathname) =>
    pathname.match(WORKSPACE_PAGE_REG) || [];

/**
 * Identify whether the current page is a meeting or occurrence detail page
 * @param {String} pathname URI
 */
export function isDetailPage(pathname = '') {
    return pathname.match(VIEW_PAGE_REG) && getMeetingPathParams(pathname);
}

export function getMeetingPathParams(pathname) {
    const meetingOnly = pathname.match(MEETING_ID_REG);

    if (meetingOnly) {
        return {
            id: meetingOnly[1],
        };
    }
    const meetingAnythingElse = pathname.match(MEETING_ANYTHING_ELSE);

    const subpage = (meetingAnythingElse?.[2] || '').match(SUBPAGE_REG);
    const params = {
        id: meetingAnythingElse?.[1],
        path: meetingAnythingElse?.[2],
    };

    if (subpage) {
        params[_camelCase(subpage[1])] = meetingAnythingElse[0];
    }

    const minutes = pathname.match(MEETING_AND_TOPIC_REG);
    if (minutes) {
        params.minuteItemId = minutes[2];
    }

    return params;
}

export function isViewingMeeting(meeting) {
    if (!meeting) {
        return false;
    }

    // use global location object
    const detailPage = isDetailPage(location.pathname);
    if (!detailPage?.id) return false;

    // if viewing agenda template
    if (detailPage.id === meeting.id) return true;

    // if viewing an occurrence for the meeting
    const occurrence = getOccurrenceFromStoreById(detailPage.id);

    // if occurrence cannot be found, then likely user is viewing it but already been deleted.
    return !occurrence || Boolean(occurrence?.meeting === meeting.id);
}

export function isViewingOccurrence(occurrence) {
    if (!occurrence) {
        return false;
    }

    // use global location object
    const detailPage = isDetailPage(location.pathname);
    return detailPage?.id === occurrence.id;
}

export const getMeetingSlug = (meetingId) => ({
    pathname: `/meeting/${meetingId}`,
});

/**
 * Create a slug for an occurrence. Retrieves the last known path viewed for that occurrence.
 * @param {Object} occurrence occurrence object to navigate to
 * @param {Object} [options] such as subpage and activeMinuteItem (id)
 * @param {string} options.subpage the subpage/path to navigate to. E.g. "minutes", "action-items", "documents"
 * @param {string} [options.activeMinuteItem] the topic to navigate to
 */
export function getOccurrenceSlug(occurrence, options) {
    const path = `/meeting/${occurrence.id}`;
    let addToURI = '',
        itemSuffix = '';
    const state = getStore().getState();
    if (options && (options.subpage || options.activeMinuteItem)) {
        const { subpage = 'minutes', activeMinuteItem = '' } = options;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line unicorn/better-regex
        addToURI = subpage ? `/${subpage}`.match(/\/[\w\d-#?/]+/) : '';
        itemSuffix = activeMinuteItem ? `/topic/${activeMinuteItem}` : '';
    } else {
        const pageState = state.pageView.pageStates.find(
            (page) => page.id === occurrence.id
        );

        addToURI = pageState
            ? pageState.path || ''
            : `/minutes${
                  occurrence.previousActionItemReview
                      ? `/topic/${ACTION_ITEM_REVIEW}`
                      : ''
              }`;
    }

    const stateParam = options?.state;
    const meeting =
        occurrence?.meeting ||
        getOccurrenceFromStoreById(state, occurrence.id)?.meeting;

    return {
        pathname: `${path}${addToURI}${itemSuffix}`,
        state: {
            occurrence: occurrence.id,
            meeting,
            ...stateParam,
        },
    };
}

export const pageViewDetails = (loc = location, previousLocation) => {
    const { pathname } = loc;
    const dashboardPage = getDashboardParams(pathname);
    const workspacePage = getWorkspacePathParams(pathname);
    const detailPage = isDetailPage(pathname);
    const previousDetailPage = isDetailPage(previousLocation?.pathname);
    const templatePage = getTemplatePathParams(pathname);

    return {
        dashboardPage,
        workspacePage,
        detailPage,
        templatePage,
        previousDetailPage,
        isDashboardPage: Object.keys(dashboardPage).length > 0,
        isActionsDashboardPage: [
            dashboardPage.dashName,
            dashboardPage.dashNameAlt,
        ].includes('action-items'),
        isMeetingDetailPage: !!detailPage,
        isWorkspacePage: workspacePage.length > 0,
        isTemplatePage: templatePage.length > 0,
    };
};

export function getTemplateSlug(libraryId, templateId, options) {
    const path = `${ROUTES.templates}/${libraryId}/${templateId}`;
    let itemSuffix = '',
        state;
    if (options) {
        const { activeAgendaItem = '' } = options;
        ({ state } = options);

        itemSuffix = activeAgendaItem ? `/topic/${activeAgendaItem}` : '';
    }
    return {
        pathname: `${path}${itemSuffix}`,
        state: {
            workspace: libraryId,
            ...state,
        },
    };
}

export const getAgendaTemplatesSlug = (workspaceId) => ({
    pathname: workspaceId
        ? `${ROUTES.templates}/${workspaceId}`
        : `${ROUTES.templates}`,
});

export const getDocumentSlug = (documentId) => ({
    pathname: `${`/api/document/${documentId}/open`}`,
});

export const getEmailAttachmentSlug = (params) => ({
    pathname: `${`/api/document/open-attachment?${makeQueryStringFromObject(
        params
    )}`}`,
});

export const getWorkspaceSlug = (workspace) => ({
    pathname: workspace
        ? `/workspaces/${workspace?.id || workspace}`
        : `/workspaces`,
});

export const getWorkspaceMemberSlug = (workspace, member) => ({
    pathname: `/workspaces/${workspace?.id || workspace}/${member.id}`,
});

export const getWorkspaceUsageSlug = (item) => ({
    pathname: `/workspaces/${item.workspaceId}/billing/${item.id}`,
});

export const getWorkspaceUpdateSlug = (workspace) =>
    `${BASE_APP_PATH}workspaces/${workspace?.id || workspace}/update`;

export const getWorkspaceUpgradeSlug = (workspace) => ({
    pathname: `/workspaces/${workspace?.id || workspace}/upgrade`,
});

/**
 * Create a slug for a dashboard.
 * If options is provided, this will be an object that gets converted to a query string to provide immediate
 * filtering on the dashboard navigated to.
 * If options are not provided, the last filter options used when that dashboard was last viewed, will be used.
 * @param {Object} group group's dashboard to navigate to.
 * @param {Object} options additional options. See below.
 * @param {Object} [options.search] An object that will be converted into query string parameters.
 * @param {Object} [options.state] An object that will be added to the react-router location state
 * @param {string} [options.dashboardType='meetings'] Dashboard type as 'series', 'meetings'.
 */
export function getDashboardSlug(group, options = {}) {
    const dashboardStates = getStore().getState().pageView.dashboardState;
    const { state: stateParam, search: searchParam } = options;
    const search = searchParam
        ? { search: makeQueryStringFromObject(searchParam) }
        : {};
    const specialDashboard = getSpecialDashboard(group?.id);
    const dashboardId = specialDashboard
        ? specialDashboard.id
        : `${options.dashboardType || 'meetings'}-${group.id}`;

    const dashboardState = {
        state: stateParam,
        ...dashboardStates[dashboardId],
    };

    return specialDashboard
        ? { pathname: specialDashboard.pathname, ...dashboardState, ...search }
        : {
              pathname: `/${options.dashboardType || 'meetings'}/${group.id}`,
              ...dashboardState,
              ...search,
          };
}

export const isDashboardSharedPage = (pathname = location.pathname) =>
    Boolean(DASHBOARD_SHARED_REG.test(pathname));

export const isAnyDashboardPage = (pathname = location.pathname) =>
    Boolean(DASHBOARD_PAGE_REG.test(pathname));

export const isDashboardAllPage = (pathname = location.pathname) =>
    Boolean(DASHBOARD_ALL_REG.test(pathname));

export const isDashboardSchedulePage = (pathname = location.pathname) =>
    Boolean(DASHBOARD_SCHEDULE_REG.test(pathname));

export const isIntegrationCalendarSettingsPage = (
    pathname = location.pathname
) => Boolean(INTEGRATION_SETTINGS_REMOTE_CALENDAR_REG.test(pathname));

export const isWorkspaceListPage = (pathname = location.pathname) =>
    Boolean(WORKSPACE_LIST_PAGE_REG.test(pathname));

/**
 * Url navigation helpers
 */
export const pushTo = (location) => getStore().dispatch(push(location));
export const replaceTo = (location) => getStore().dispatch(replace(location));
export * from '../../../shared/navigation/index.js';
