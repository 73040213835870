import { displayError } from '~modules/alert/alert.helpers.js';
import { getMeetingSelector } from '~modules/meeting/meeting.selectors.js';
import { updatePageViewState } from '~modules/pageView/pageView.actions.js';
import { getPageStateForMeeting } from '~modules/pageView/pageView.selectors.js';
import { hasItemAccess } from './remoteFiles.api.js';

export const verifyFileLinkForMeeting =
    (meeting) => async (dispatch, getState) => {
        const state = getState();
        const meetingObj =
            Object.keys(meeting).length > 1
                ? meeting
                : getMeetingSelector(state, { meeting });
        const { fileLink } = meetingObj;

        if (!fileLink?.remoteId) return;

        try {
            const hasAccessRequest = hasItemAccess(fileLink);
            const pageState = getPageStateForMeeting(state, {
                meeting: meetingObj,
            });

            const accessLookup = await hasAccessRequest;

            if (pageState?.hasAccessToFileLink === accessLookup?.hasAccess)
                return;

            dispatch(
                updatePageViewState({
                    id: meetingObj.id,
                    hasAccessToFileLink: accessLookup?.hasAccess,
                })
            );
        } catch {
            return displayError({
                title: `Could not determine if you have access to ${fileLink.displayPath}.`,
            });
        }
    };
