import React from 'react';

export default ({ iconThick = false, ...rest }) => (
    <svg
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="m13 3h5c1.1046 0 2 0.89543 2 2v6.125 0.375m-7-8.5h-5c-1.1046 0-2 0.89543-2 2v6.125 0.375m7-8.5v26h-5c-1.1046 0-2-0.8954-2-2v-6.5m7 8.5h5c1.1046 0 2-0.8954 2-2v-6.5m0-9v8.2917 0.7083m0-9h-14v8.2917 0.7083m14 0h-14"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
        <path
            d="m26 3v26"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="currentColor"
            strokeWidth={iconThick ? '2' : '1.5'}
            vectorEffect="non-scaling-stroke"
        />
    </svg>
);
